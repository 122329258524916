import React, { createContext, FC, useContext, useState } from 'react'
import PageTitle from '../../../utils/PageTitle'
import { Tab, Tabs } from '@blueprintjs/core'
import { PlanFinancementPage } from './PlanFinancement'
import styled from 'styled-components'
import { PlanFinancementRatiosPageWrapper } from './PlanFinancementRatiosPage'
import { PlanFinancement, PlanFinancementRatios, Pret, Prime } from '@fa-metier/types'
import { useGetPlanFinancement, useGetPlanFinancementRatios } from './PlanFinancementQueries'
import { NoteContext } from '../../NotePage'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { PeriodModel } from '../PeriodModel'
import { useGetPeriods } from '../FinanciaryQueries'
import { useGetRessources } from '../Prets/PretsQueries'

const VUE_ANALYTIQUE = 'vue-analytique'
const VUE_COMPTABLE = 'vue-comptable'

const TitleAndTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .bp3-tabs {
    margin-top: 0;
  }

  .bp3-tab-list .bp3-tab {
    padding: 5px 40px;
  }
`

const PFContainer = styled.div`
  width: fit-content;
  height: 100%;
  min-width: 1280px;
`

interface PFContextI {
  planFinancement: PlanFinancement
  previsionelPeriods: PeriodModel[]
  anterieurPeriods: PeriodModel[]
  prets: Pret[]
  primes: Prime[]
  ratios: PlanFinancementRatios
}

export const PFContext = createContext<PFContextI>({} as PFContextI)

export const PlanFinancementContainer: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading, data } = useGetPlanFinancement(noteId)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)
  const { loading: ressourcesLoading, data: ressources } = useGetRessources(noteId)
  const { loading: ratiosLoading, data: ratios } = useGetPlanFinancementRatios(noteId)

  const [selectedTab, setSelectedTab] = useState(VUE_COMPTABLE)

  if (loading || periodLoading || ressourcesLoading || ratiosLoading) {
    return <YodaSpinnerPage />
  }

  return (
    <PFContext.Provider
      value={{
        planFinancement: data!!,
        previsionelPeriods: periodsData.previsionnelles,
        anterieurPeriods: periodsData.anterieurs,
        prets: ressources!!.prets,
        primes: ressources!!.primes,
        ratios: ratios!!,
      }}
    >
      <PFContainer>
        <TitleAndTabs>
          <PageTitle>Plan de financement</PageTitle>
          <Tabs selectedTabId={selectedTab} onChange={(id: string) => setSelectedTab(id)}>
            <Tab id={'vue-comptable'} title={'Vue comptable'} />
            <Tab id={'vue-analytique'} title={'Vue analytique'} />
          </Tabs>
        </TitleAndTabs>
        {selectedTab === VUE_COMPTABLE && <PlanFinancementPage />}
        {selectedTab === VUE_ANALYTIQUE && <PlanFinancementRatiosPageWrapper />}
      </PFContainer>
    </PFContext.Provider>
  )
}
