import {
  MasseSalarialeDocument,
  useDeleteMasseSalarialeFonctionMutation,
  useMasseSalarialeQuery,
  useUpdateMasseSalarialeFonctionMutation,
  useUpdateMasseSalarialeMutation,
} from '../../../generated/graphql'
import {
  MasseSalariale,
  MasseSalarialeFonction,
  MasseSalarialeFonctionInput,
  MasseSalarialeFonctionPeriod,
  MasseSalarialeInput,
  MasseSalarialeFonctionPeriodInput,
  NumericData,
  NumericDataInput,
} from '@fa-metier/types'

export const useGetMasseSalariale = (noteId: string) => {
  const { data, loading } = useMasseSalarialeQuery({ variables: { noteId } })
  return {
    loading,
    data: data ? data.masseSalariale : undefined,
  }
}

export const useUpdateMasseSalariale = (noteId: string) => {
  const [masseSalarialeMutation] = useUpdateMasseSalarialeMutation({
    update: (cache) => {
      cache.evictQueries([
        {
          query: MasseSalarialeDocument,
          variables: { noteId },
        },
      ])
    },
  })
  return (update: MasseSalarialeInput) => {
    return new Promise<MasseSalariale>((resolve, reject) => {
      masseSalarialeMutation({
        variables: { update },
      })
        .then((response) => {
          const result = response.data && response.data.masseSalariale.update
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const useUpdateFonctionMasseSalariale = (noteId: string) => {
  const [masseSalarialeMutation] = useUpdateMasseSalarialeFonctionMutation({
    update: (cache) => {
      cache.evictQueries([
        {
          query: MasseSalarialeDocument,
          variables: { noteId },
        },
      ])
    },
  })
  return (update: MasseSalarialeFonctionInput) => {
    return new Promise<MasseSalariale>((resolve, reject) => {
      masseSalarialeMutation({
        variables: { update, noteId },
      })
        .then((response) => {
          const result = response.data && response.data.masseSalariale.updateLine
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const useDeleteMasseSalarialeFonction = (noteId: string) => {
  const [fonctionMutation] = useDeleteMasseSalarialeFonctionMutation({
    update: (cache) => {
      cache.evictQueries([
        {
          query: MasseSalarialeDocument,
          variables: { noteId },
        },
      ])
    },
  })
  return (id: number) => {
    return new Promise<Boolean>((resolve, reject) => {
      fonctionMutation({
        variables: { id },
      })
        .then((response) => (response ? resolve(true) : reject()))
        .catch((error) => reject(error))
    })
  }
}

export const toMasseSalarialeInput: (masseSalariale: MasseSalariale) => MasseSalarialeInput = (
  masseSalariale
) => {
  return {
    noteId: masseSalariale.noteId,
    croissanceAnnuelleSalaires: masseSalariale.croissanceAnnuelleSalaires,
    fonctions: masseSalariale.fonctions.map((value) => toMasseSalarialeFonctionInput(value)),
  }
}

const toMasseSalarialeFonctionInput: (
  masseSalarialeFonction: MasseSalarialeFonction
) => MasseSalarialeFonctionInput = (masseSalarialeFonction) => {
  return {
    id: masseSalarialeFonction.id,
    fonction: masseSalarialeFonction.fonction,
    salaireBrutMoyen: toNumericDataInput(masseSalarialeFonction.salaireBrutMoyen),
    tauxChargesSalariales: masseSalarialeFonction.tauxChargesSalariales,
    tauxChargesPatronales: masseSalarialeFonction.tauxChargesPatronales,
    periods: masseSalarialeFonction.periods.map((value) => toPeriodsInput(value)),
  }
}

const toPeriodsInput: (periods: MasseSalarialeFonctionPeriod) => MasseSalarialeFonctionPeriodInput =
  (periods) => {
    return {
      periodId: periods.periodId,
      effectifs: periods.effectifs,
      moisPayes: periods.moisPayes,
    }
  }

const toNumericDataInput: (numericData: NumericData | undefined | null) => NumericDataInput | null =
  (numericData) => {
    return numericData
      ? {
          detail: numericData.detail,
          kiloEuros: numericData.kiloEuros,
          percentageReference: numericData.percentageReference,
          value: numericData.value,
        }
      : null
  }
