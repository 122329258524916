import { useContext, useState } from "react";
import { NoteContext } from "../Project/NotePage";

export const useSessionState = (key: string, defaultValue: any) => {
  const {noteId} = useContext(NoteContext)

  const completeKey = `${noteId}_${key}`

  const getSessionOrDefault = (defaultValue: any) => {
    const stored = sessionStorage.getItem(completeKey)
    if (stored == null) {
      return defaultValue
    }
    return JSON.parse(stored)
  }
  const setState = useState(getSessionOrDefault(defaultValue))[1]

  const updateSession = (value: any) => {
    const updatedValue = JSON.stringify(value);
    sessionStorage.setItem(completeKey, updatedValue)
    setState(updatedValue)
  }

  return [getSessionOrDefault(defaultValue), updateSession]
}
