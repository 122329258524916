import { Card } from '@blueprintjs/core'
import styled from 'styled-components'

export const PretCard = styled(Card)`
  width: 1280px;
  box-shadow: none;
  margin-bottom: 10px;
  margin-top: 10px;
`

export const PretSubsectionTitle = styled.span`
  font-size: 20px;
  line-height: 26px;
  color: var(--main-black-1);
  font-weight: var(--bold);
  margin-bottom: 5px;
`
