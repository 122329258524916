import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import PageTitle from '../../../utils/PageTitle'
import { Dots, FinancialTable, LabelSeparator } from '../FinancialTable'
import { formatDayjs } from '../../../utils/DayjsFormatter'
import { useDebounce } from 'react-use'
import { PeriodModel } from '../PeriodModel'
import { AmountReference, PeriodsContext } from '../Utils/PeriodsContext'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import { toBenchMarkInput, useGetBenchMark, useUpdateBenchMark } from './BenchMarkQueries'
import {
  BenchMark,
  BenchMarkPeriod,
  CalculatedData,
  NoteType,
  NumericData,
  PeriodType,
  Stylable,
} from '@fa-metier/types'
import { getField } from '@fa-metier/commons'
import {
  CommentContainer,
  CommentTitle,
  Editor,
  makeOutputData,
  NumberText,
} from '@fa-metier/components'
import { AppMetierNumberInput } from '../Utils/AppMetierInput/AppMetierNumberInput'
import styled from 'styled-components'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { eurosSuffix, Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { NoteContext } from '../../NotePage'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { useGetPeriods } from '../FinanciaryQueries'
import { FormulaPopoverContent } from '../Formulas/FormulaPopover'
import { FormulaDescriptionPopover } from '../Formulas/FormulaDescriptionPopover'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { saveShortcutListener, withFieldsGuard } from '../../../utils/utils'
import { ParametersDiv } from '../../../utils/ParametersDiv'
import { PageSaveButton } from '../../../utils/PageSaveButton'
import _ from 'lodash'
import { useUserDebounce } from '../../../Settings/Hooks'

const BenchMarkLine: FC<{
  benchMark: BenchMark
  label: string
  field: string
  numData?: NumericData | null
  suffix: Suffixes
  updateBenchMarkInput: (field: string, value: NumericData) => any
  updateBenchMarkEffectif: (value: number, periodId: string) => any
  calculated?: boolean
}> = ({
  benchMark,
  label,
  field,
  numData,
  suffix,
  updateBenchMarkInput,
  updateBenchMarkEffectif,
  calculated = true,
}) => {
  const { showComputations, showAnterieurs } = useContext(PeriodsContext)
  const { computationInProgress } = useContext(BenchMarkContext)

  const benchmarkPeriodsPrevisionnels =
    benchMark?.periods?.filter((p) => p.periodType === PeriodType.Previsionnel) ?? []
  const benchmarkPeriodsAnterieurs =
    benchMark?.periods?.filter((p) => p.periodType === PeriodType.Anterieur) ?? []

  const benchmarkSituationIntermediaire =
    benchMark?.periods?.filter((p) => p.periodType === PeriodType.Intermediaire) ?? undefined

  return (
    <>
      <tr className={'hoverable'}>
        <th style={{ display: 'flex', alignItems: 'baseline' }}>
          <div>{label}</div>
          <Dots />
        </th>
        {showAnterieurs && (
          <InnerBenchMarkLine
            fieldPath={field}
            suffix={suffix}
            periodsBenchMark={benchmarkPeriodsAnterieurs}
            onChange={(value, periodId) => updateBenchMarkEffectif(value, periodId)}
            calculated={calculated}
          />
        )}
        {field === 'effectifs' && <td />}
        {field !== 'effectifs' && (
          <td key={`field-${benchMark?.noteId}-${field}${showComputations}`}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <NumericDataInput
                numericData={numData}
                onChange={(value) => {
                  if (value) {
                    updateBenchMarkInput(field, value)
                  }
                }}
                showComputations={showComputations}
                isCalculated={computationInProgress}
                isEuro={suffix === Suffixes.EUROS || suffix === Suffixes.KILO_EUROS}
                isPourcentage={suffix === Suffixes.POURCENTAGE}
              />
            </div>
          </td>
        )}
        {benchmarkSituationIntermediaire && (
          <InnerBenchMarkLine
            fieldPath={field}
            suffix={suffix}
            periodsBenchMark={benchmarkSituationIntermediaire}
            onChange={(value, periodId) => updateBenchMarkEffectif(value, periodId)}
            calculated={calculated}
          />
        )}
        <InnerBenchMarkLine
          fieldPath={field}
          suffix={suffix}
          periodsBenchMark={benchmarkPeriodsPrevisionnels}
          onChange={(value, periodId) => updateBenchMarkEffectif(value, periodId)}
          calculated={calculated}
        />
      </tr>
    </>
  )
}

const EmptyBenchmarkLine = () => {
  const { showAnterieurs } = useContext(PeriodsContext)
  const { previsionelPeriods, anterieurPeriods, situationIntermediaire } =
    useContext(BenchMarkContext)
  return (
    <tr>
      <th />
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p, i) => {
          return <td key={i} style={{ height: 'inherit' }} className={'anterieur smallHeight'} />
        })}
      <td style={{ height: 'inherit' }} className={'smallHeight'} />
      {situationIntermediaire && (
        <td
          key={Math.random()}
          style={{ height: 'inherit' }}
          className={'smallHeight intermediaire'}
        />
      )}
      {previsionelPeriods.map((p, i) => (
        <td key={i} style={{ height: 'inherit' }} className={'smallHeight'} />
      ))}
    </tr>
  )
}

interface InnerBenchMarkLineProps {
  fieldPath: string
  suffix: Suffixes
  periodsBenchMark: Array<BenchMarkPeriod>
  onChange: (value: number, periodId: string) => any
  calculated: boolean
}

const InnerBenchMarkLine: FC<InnerBenchMarkLineProps & Stylable> = ({
  fieldPath,
  periodsBenchMark,
  suffix,
  onChange,
  calculated,
}) => {
  return (
    <>
      {periodsBenchMark.map((p, index) => (
        <BenchmarkCell
          key={p.periodId}
          fieldPath={fieldPath}
          p={p}
          periods={periodsBenchMark}
          suffix={suffix}
          index={index}
          onChange={onChange}
          calculated={calculated}
        />
      ))}
    </>
  )
}

const BenchmarkCell: FC<{
  fieldPath: string
  p: BenchMarkPeriod
  periods: BenchMarkPeriod[]
  suffix: Suffixes
  index: number
  onChange: (value: number, periodId: string) => any
  calculated: boolean
}> = ({ fieldPath, p, suffix, periods, index, onChange, calculated }) => {
  return (
    <td
      key={p.periodId}
      style={{ textAlign: 'end' }}
      className={
        p.periodType === PeriodType.Anterieur
          ? 'anterieur'
          : p.periodType === PeriodType.Intermediaire
          ? 'intermediaire'
          : ''
      }
    >
      <div>
        {fieldPath === 'effectifs' && <EffectifsCell period={periods[index]} onChange={onChange} />}
        {fieldPath !== 'effectifs' && (
          <BenchmarkValueCell
            fieldPath={fieldPath}
            period={p}
            suffix={suffix}
            calculated={calculated}
          />
        )}
      </div>
    </td>
  )
}

const EffectifsCell: FC<{
  period: BenchMarkPeriod
  onChange: (value: number, periodId: string) => any
}> = ({ period, onChange }) => {
  const [effectifs, setEffectifs] = useState(getField<number>('effectifs', period))
  const { computationInProgress } = useContext(BenchMarkContext)

  return (
    <AppMetierNumberInput
      value={effectifs ?? 0}
      onChange={(value) => {
        if (value || value === 0) {
          onChange(value, period.periodId)
          setEffectifs(value)
        }
      }}
      small={true}
      disabled={computationInProgress}
    />
  )
}

const BenchmarkValueCell: FC<{
  fieldPath: string
  period: BenchMarkPeriod
  suffix: Suffixes
  calculated: boolean
}> = ({ fieldPath, period, suffix, calculated }) => {
  const data = getField<CalculatedData>(fieldPath, period)
  const value = data.numericData.value ?? 0
  const finalValue = suffix === Suffixes.POURCENTAGE ? value * 100 : value

  if (calculated) {
    return (
      <FormulaDescriptionPopover
        content={
          <FormulaPopoverContent
            calculatedData={data}
            suffix={suffix}
            periodType={period.periodType}
          />
        }
        position={'bottom'}
      >
        <NumberText value={finalValue} suffix={suffix} />
      </FormulaDescriptionPopover>
    )
  }
  return <NumberText value={finalValue} suffix={suffix} />
}

const BenchmarkTable = styled(FinancialTable)`
  td {
    width: 195px;
  }
`

interface BenckMarkContextI {
  benchMark: BenchMark
  previsionelPeriods: PeriodModel[]
  anterieurPeriods: PeriodModel[]
  situationIntermediaire?: PeriodModel
  computationInProgress: boolean
  setComputationInProgress: Dispatch<SetStateAction<boolean>>
}

export const BenchMarkContext = React.createContext<BenckMarkContextI>({} as BenckMarkContextI)

export const BenchMarkFormWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading: benchMarkLoading, data: benchMark } = useGetBenchMark(noteId)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)
  const [computationInProgress, setComputationInProgress] = useState(false)

  if (benchMarkLoading || periodLoading) {
    return <YodaSpinnerPage />
  }
  return (
    <>
      {benchMark && (
        <BenchMarkContext.Provider
          value={{
            benchMark,
            computationInProgress,
            setComputationInProgress,
            previsionelPeriods: periodsData.previsionnelles,
            anterieurPeriods: periodsData.anterieurs,
            situationIntermediaire: periodsData.situationIntermediaire ?? undefined,
          }}
        >
          <BenchMarkForm />
        </BenchMarkContext.Provider>
      )}
    </>
  )
}

export const BenchMarkForm: FC = () => {
  const { noteId, noteSettings, note, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const {
    benchMark: benchMarkData,
    previsionelPeriods,
    anterieurPeriods,
    situationIntermediaire,
    computationInProgress,
    setComputationInProgress,
  } = useContext(BenchMarkContext)
  const [benchMark, setBenchMark] = useState(benchMarkData)
  const [showComputations, setShowComputations] = useState(false)

  const refs = useRef({
    benchMarkData,
    benchMark,
    unsavedChanges,
  })

  const updateBenchMark = useUpdateBenchMark(noteId)

  const [showAnterieurs, setShowAnterieurs] = useState(true)

  const updateBenchMarkInput = (field: string, value: NumericData) => {
    if (value) {
      setBenchMark((benchMark) => ({
        ...benchMark,
        [field]: value,
      }))
    }
  }

  const updateBenchMarkEffectif = (value: number, periodId: string) => {
    if (value || value === 0) {
      setBenchMark((benchmark) => ({
        ...benchmark,
        periods: benchmark.periods?.map((p) =>
          p.periodId === periodId ? { ...p, effectifs: value } : p
        ),
      }))
    }
  }

  const updateComment = (comment: any) =>
    setBenchMark((benchmark) => ({
      ...benchmark,
      comment,
    }))

  const userDebounce = useUserDebounce()

  const [, cancelDebounce] = useDebounce(
    async () => {
      if (!_.isEqual(benchMark, refs.current.benchMarkData)) {
        await withFieldsGuard(
          () => updateBenchMark(toBenchMarkInput(benchMark)),
          setComputationInProgress
        )
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [benchMark]
  )

  useEffect(() => {
    refs.current.benchMark = benchMark
    if (!_.isEqual(benchMark, refs.current.benchMarkData)) {
      setUnsavedChanges(true)
    }
  }, [benchMark, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.benchMarkData, benchMarkData)) {
      refs.current.benchMarkData = benchMarkData
      setBenchMark(benchMarkData)
      cancelDebounce()
    }
  }, [benchMarkData, cancelDebounce])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelDebounce()
      if (!_.isEqual(refs.current.benchMark, refs.current.benchMarkData)) {
        await updateBenchMark(toBenchMarkInput(refs.current.benchMark))
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dictionnary = useDictionnary()

  return (
    <>
      <PageTitle>Benchmark</PageTitle>
      <div>
        <ParametersDiv>
          <PageSaveButton
            buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
            buttonDisabled={computationInProgress || !unsavedChanges}
          />
          <AppMetierSwitch
            label={'Voir tous les calculs'}
            checked={showComputations}
            onChange={() => {
              setShowComputations(!showComputations)
            }}
          />
          {note.type === NoteType.Reprise && anterieurPeriods?.length !== 0 && (
            <div style={{ marginLeft: '20px' }}>
              <AppMetierSwitch
                label={'Afficher exercices du cédant'}
                checked={showAnterieurs}
                onChange={() => {
                  setShowAnterieurs(!showAnterieurs)
                }}
              />
            </div>
          )}
        </ParametersDiv>

        <PeriodsContext.Provider
          value={{
            showComputations,
            showAnterieurs,
            situationIntermediaire,
            anterieurPeriods: [],
            periods: previsionelPeriods,
            updatePeriods: () => {},
            reference: (noteSettings.referenceAmount ?? 'NONE') as AmountReference,
          }}
        >
          <BenchmarkTable
            lineBefore={false}
            lineAfter={false}
            className={'benchMark'}
            thead={
              <tr style={{ backgroundColor: 'var(--main-blue-2' }}>
                <th className={'section-title'} style={{ verticalAlign: 'bottom' }}>
                  <LabelSeparator height={625} />
                </th>
                {showAnterieurs &&
                  anterieurPeriods.map((p, i) => (
                    <td key={p.id}>
                      <div
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          textAlign: 'end',
                        }}
                      >
                        <div style={{ flexBasis: '50%' }}>
                          <div style={{ textAlign: 'left' }}>
                            <b>
                              {dictionnary.benchmarchAnterieurPeriodLabel}
                              <br />
                              {dictionnary.periodTitle(p, i)}
                            </b>
                          </div>
                        </div>
                      </div>
                    </td>
                  ))}
                <td key={'titleMoyenneSectorielle'}>
                  <div style={{ flexBasis: '50%' }}>
                    <div>
                      <b>
                        Moyenne <br />
                        sectorielle
                      </b>
                    </div>
                  </div>
                </td>
                {situationIntermediaire && (
                  <td key={situationIntermediaire.id}>
                    <div
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        textAlign: 'end',
                      }}
                    >
                      <div style={{ flexBasis: '50%' }}>
                        <div>
                          <b>
                            Situation
                            <br />
                          </b>
                        </div>
                        <b>au {formatDayjs(situationIntermediaire.endDate)}</b>
                      </div>
                    </div>
                  </td>
                )}
                {previsionelPeriods.map((p, i) => (
                  <td key={p.id}>
                    <div
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        textAlign: 'end',
                      }}
                    >
                      <div style={{ flexBasis: '50%' }}>
                        <div>
                          <b>
                            Prévisionnel <br />
                            {dictionnary.periodTitle(p, i)}
                          </b>
                        </div>
                        {note.type !== NoteType.StructureExistante && (
                          <div style={{ color: 'grey', fontSize: '0.8em' }}>
                            Du {formatDayjs(p.startDate)} <br />
                            au {formatDayjs(p.endDate)}
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            }
          >
            <EmptyBenchmarkLine />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={"Chiffre d'affaires"}
              field={'chiffreAffaire'}
              numData={benchMark?.chiffreAffaire}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              calculated={false}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={"Chiffre d'affaires par personne"}
              field={'chiffreAffaireParPersonne'}
              numData={benchMark?.chiffreAffaireParPersonne}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={'Marge brute / CA'}
              field={'margeBrut'}
              numData={benchMark?.margeBrut}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.POURCENTAGE}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={'Valeur ajoutée'}
              field={'valeurAjoutee'}
              numData={benchMark?.valeurAjoutee}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.POURCENTAGE}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={"Charges de personnel & cotisations de l'exploitant (hors prélèvement TNS)"}
              field={'chargePersonnel'}
              numData={benchMark?.chargePersonnel}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.POURCENTAGE}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={'EBE'}
              field={'ebe'}
              numData={benchMark?.ebe}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.POURCENTAGE}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={'Résultat financier'}
              field={'resultatFinancier'}
              numData={benchMark?.resultatFinancier}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.POURCENTAGE}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={'Résultat courant / CA'}
              field={'resultatCourant'}
              numData={benchMark?.resultatCourant}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.POURCENTAGE}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={'Point mort (activité minimum, avec subventions équivalentes)'}
              field={'pointMort'}
              numData={benchMark?.pointMort}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={'Marge de manoeuvre'}
              field={'margeManoeuvre'}
              numData={benchMark?.margeManoeuvre}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.POURCENTAGE}
              calculated={false}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={"Rotation des stocks (jours d'achat HT)"}
              field={'rotationStock'}
              numData={benchMark?.rotationStock}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.JOURS}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={'BFR (jours CA HT)'}
              field={'joursCa'}
              numData={benchMark?.joursCa}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.JOURS}
            />
            <BenchMarkLine
              benchMark={benchMarkData}
              label={'Effectif en ETP (dont TNS)'}
              field={'effectifs'}
              updateBenchMarkInput={(field, value) => updateBenchMarkInput(field, value)}
              updateBenchMarkEffectif={(value, periodId) =>
                updateBenchMarkEffectif(value, periodId)
              }
              suffix={Suffixes.NONE}
            />
            <EmptyBenchmarkLine />
          </BenchmarkTable>
          <div style={{ marginTop: '30px' }}>
            <CommentContainer>
              <CommentTitle>
                <span>Commentaire</span>
              </CommentTitle>
              <Editor
                id={'benchmarkComment'}
                placeHolder={'Vous pouvez saisir un commentaire'}
                initialValue={makeOutputData(benchMark.comment)}
                onChange={updateComment}
              />
            </CommentContainer>
            <div style={{ marginTop: '50px' }}>&nbsp;</div>
          </div>
        </PeriodsContext.Provider>
      </div>
    </>
  )
}
