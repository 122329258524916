import { DifferenceSaisies } from '../Utils/DifferencesDeSaisie'
import React, { FC, useContext } from 'react'
import { FinanciaryContext } from '../Financiary'
import { PeriodModel } from '../PeriodModel'
import { FinancialPeriodsSaveRequestMultipleInput } from '@fa-metier/types'
import { useUpdatePeriods } from '../FinanciaryQueries'
import { NoteContext } from '../../NotePage'
import { InvestmentsContext } from './InvestmentsContext'
import produce from 'immer'
import { removeTypeName } from '@fa-metier/commons'
import { incomeStatementToInput } from '../IncomeStatementMapper'
import { useDictionnary } from '../../dictionnary/dictionnary'

export const DifferencesSaisiesInvestissementsCR: FC = () => {
  const { previsionelPeriods } = useContext(FinanciaryContext)
  const { noteId } = useContext(NoteContext)
  const { investments } = useContext(InvestmentsContext)
  const updatePeriodsMutation = useUpdatePeriods(noteId)
  const { differencesSaisies } = useDictionnary()

  const updatePeriod = (period: PeriodModel, index: number) =>
    produce(period, (draft) => {
      const updateIncomeStatementDotationsAmortissementsProvisions =
        draft.incomeStatement.dotationsAmortissementsProvisions
      updateIncomeStatementDotationsAmortissementsProvisions.value =
        investments.total.sommesAmortissementsExercices[index].numericData.value
      updateIncomeStatementDotationsAmortissementsProvisions.detail = ''
    })

  const updateCR = () => {
    const updates = previsionelPeriods
      .map((p, index) => updatePeriod(p, index))
      .map(periodToMutationModel)
    return updatePeriodsMutation({ updates })
  }

  const intermediaryTableData = () => {
    const source = investments.total.sommesAmortissementsExercices.map(
      (value) => value.numericData.value ?? 0.0
    )
    return [
      {
        title: 'Total Amortissements',
        sourceValues: source,
        targetTitle: 'Données saisies dans compte de résultat',
        targetValues: previsionelPeriods.map(
          (p) => p.incomeStatement.dotationsAmortissementsProvisions.value ?? 0
        ),
      },
    ]
  }

  return (
    <DifferenceSaisies
      title={'Différences de saisies avec le compte de résultat'}
      updateFunction={updateCR}
      intermediaryTableData={intermediaryTableData()}
      hideDemarrage={true}
      explanation={differencesSaisies.crExplanation}
      buttonText={'Remplacer les données du Compte de résultat'}
      dialogBody={
        <>
          <p>
            Etes-vous sûr de vouloir remplacer les données du <b>Compte de résultat</b> ? <br />{' '}
            Cette action ne peut pas être inversée.
          </p>
        </>
      }
      successText={differencesSaisies.crSuccessText}
    />
  )
}

const periodToMutationModel = (period: PeriodModel): FinancialPeriodsSaveRequestMultipleInput => {
  return removeTypeName({
    financialPeriodId: period.id,
    input: {
      incomeStatement: incomeStatementToInput(period.incomeStatement),
    },
  })
}
