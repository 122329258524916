import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { InvestmentTypeTable } from './InvestmentTypeTable'
import {
  toInvestissementsInput,
  useGetInvestissements,
  useUpdateInvestissements,
} from './InvestissementsQueries'
import { useDebounce } from 'react-use'
import { NumberText } from '@fa-metier/components'
import styled from 'styled-components'
import PageTitle from '../../../utils/PageTitle'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { DifferencesSaisiesInvestissementsPlanFinancementWrapper } from './DifferencesSaisiesInvestissementsPlanFinancement'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { NoteContext } from '../../NotePage'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { useGetPeriods } from '../FinanciaryQueries'
import { DifferencesSaisiesInvestissementsCR } from './DifferencesSaisiesInvestissementsCR'
import { FormulaDescriptionPopover } from '../Formulas/FormulaDescriptionPopover'
import { FormulaPopoverContent } from '../Formulas/FormulaPopover'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { DEBOUNCE_CONFIG } from '../../../Settings/debounceConfig'
import { InvestmentsContext } from './InvestmentsContext'
import { EmptyInvestmentLine } from './InvestmentLine'
import { saveShortcutListener, withFieldsGuard } from '../../../utils/utils'
import { ParametersDiv } from '../../../utils/ParametersDiv'
import { PageSaveButton } from '../../../utils/PageSaveButton'
import _ from 'lodash'
import { useUserDebounce } from '../../../Settings/Hooks'

const StyledTable = styled.table`
  table-layout: fixed;
  background-color: white;
  padding: 5px;
  border-collapse: collapse;
  margin-bottom: 20px;
  color: var(--main-black-2);

  * td {
    padding: 5px 10px;

    input {
      line-height: 30px;
      height: 30px;
    }
  }

  * {
    white-space: nowrap;
  }

  * td.suggest {
    * span {
      width: 100%;
    }

    * .suggest {
      width: 100%;
    }

    input {
      max-width: unset;
    }
  }

  * input {
    min-width: 5em;
    max-width: 10em;
  }

  * .suggest-input {
    input {
      max-width: unset;
    }
  }

  * button {
    width: 100%;
  }

  * th.ttc {
    background-color: rgb(155, 205, 255, 0.2);
  }

  * td.ttc {
    background-color: rgb(155, 205, 255, 0.2);
  }

  thead {
    tr.bordered {
      border-bottom: 3px var(--main-blue-2) solid;
    }
  }

  td.select-cell {
    display: flex;
    justify-content: center;
  }

  * th {
    font-size: 14px;
    line-height: 30px;
    color: var(--main-black-1);
    font-weight: var(--bold);
  }

  * tr th:first-child {
    width: 280px;
  }

  * tr td:first-child {
    padding-left: 20px;
  }

  * tr th:not(:first-child):not(:last-child) {
    width: 6em;
  }

  * td.title {
    font-weight: var(--bold);
  }

  * th.transparent {
    background: var(--main-blue-2);
  }

  * tr.hoverable {
    :hover {
      background: linear-gradient(var(--main-green-2), var(--main-green-2)) no-repeat 15px 0;
    }
  }

  * th.action {
    width: 30px;
  }

  * td.action {
    padding: 0;
    text-align: center;
  }

  * tr {
    td:nth-child(2) {
      box-shadow: inset 7px 0 9px -9px rgba(0, 0, 0, 0.4);
    }
  }

  .subtotal {
    background: linear-gradient(var(--main-grey-3), var(--main-grey-3)) no-repeat 15px 0;
    font-weight: var(--bold);

    td:last-child {
      background: linear-gradient(white, white) no-repeat 40px 0;
    }
  }

  tfoot.total tr.dataline {
    background: linear-gradient(var(--main-blue-1), var(--main-blue-1)) no-repeat 15px 0;
    font-weight: var(--bold);
    color: white;

    td:last-child {
      background: linear-gradient(white, white) no-repeat 40px 0;
    }
  }
`

export const InvestmentsFormWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading, data } = useGetInvestissements(noteId)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)

  if (loading || periodLoading) {
    return <YodaSpinnerPage />
  }
  return (
    <InvestmentsContext.Provider
      value={{
        investments: data!!,
        previsionelPeriods: periodsData.previsionnelles,
      }}
    >
      <InvestmentsForm />
    </InvestmentsContext.Provider>
  )
}

export const InvestmentsForm: FC = () => {
  const { noteId, noteSettings, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const { investments: contextInvestments, previsionelPeriods } = useContext(InvestmentsContext)
  const updateInvestments = useUpdateInvestissements(noteId)
  const [showComputations, setShowComputations] = useState(false)
  const [editableInvestments, setEditableInvestments] = useState(contextInvestments)
  const [computationInProgress, setComputationInProgress] = useState(false)

  const refs = useRef({
    contextInvestments,
    editableInvestments,
    unsavedChanges,
  })

  const dictionary = useDictionnary()
  const userDebounce = useUserDebounce()

  const [, cancelDebounce] = useDebounce(
    async () => {
      if (!_.isEqual(editableInvestments, refs.current.contextInvestments)) {
        await withFieldsGuard(
          () => updateInvestments(toInvestissementsInput(editableInvestments)),
          setComputationInProgress
        )
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [editableInvestments]
  )

  useEffect(() => {
    refs.current.editableInvestments = editableInvestments
    if (!_.isEqual(editableInvestments, refs.current.contextInvestments)) {
      setUnsavedChanges(true)
    }
  }, [editableInvestments, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.contextInvestments, contextInvestments)) {
      refs.current.contextInvestments = contextInvestments
      setEditableInvestments(contextInvestments)
      cancelDebounce()
    }
  }, [contextInvestments, cancelDebounce])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelDebounce()
      if (!_.isEqual(refs.current.editableInvestments, contextInvestments)) {
        await updateInvestments(toInvestissementsInput(refs.current.editableInvestments))
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ paddingBottom: '3%' }}>
      {contextInvestments && (
        <div>
          <PageTitle>Investissements</PageTitle>

          <ParametersDiv>
            <PageSaveButton
              buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
              buttonDisabled={computationInProgress || !unsavedChanges}
            />
            <AppMetierSwitch
              label={'Voir tous les calculs'}
              checked={showComputations}
              onChange={() => {
                setShowComputations(!showComputations)
              }}
            />
          </ParametersDiv>

          <div>
            <StyledTable>
              <thead>
                <tr>
                  <th className={'transparent'} />
                  <th>Montant HT</th>
                  <th>Durée amortissement</th>
                  <th>Date d'achat</th>
                  <th colSpan={previsionelPeriods.length}>Amortissements</th>
                  <th>Taux TVA</th>
                  <th>Montant TVA</th>
                  <th className={'ttc'}>Montant TTC</th>
                  <th className={'action'}>&nbsp;</th>
                </tr>
                <tr className={'bordered'}>
                  <th className={'transparent'} />
                  <th />
                  <th />
                  <th />
                  {previsionelPeriods.map((p, index) => (
                    <th key={index}>{dictionary.periodTitle(p, index)}</th>
                  ))}
                  <th />
                  <th />
                  <th className={'ttc'} />
                  <th className={'action'}>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <InvestmentTypeTable
                  investissements={editableInvestments}
                  type={'Incorporel'}
                  setInvestissements={setEditableInvestments}
                  computationInProgress={computationInProgress}
                  showComputations={showComputations}
                  showInKiloEuros={noteSettings.kiloEuros}
                  resetDebounceTimers={cancelDebounce}
                  unsavedChanges={unsavedChanges}
                />
                <InvestmentTypeTable
                  investissements={editableInvestments}
                  type={'Corporel'}
                  setInvestissements={setEditableInvestments}
                  computationInProgress={computationInProgress}
                  showComputations={showComputations}
                  showInKiloEuros={noteSettings.kiloEuros}
                  resetDebounceTimers={cancelDebounce}
                  unsavedChanges={unsavedChanges}
                />
                <InvestmentTypeTable
                  investissements={editableInvestments}
                  type={'Financier'}
                  setInvestissements={setEditableInvestments}
                  computationInProgress={computationInProgress}
                  showComputations={showComputations}
                  showInKiloEuros={noteSettings.kiloEuros}
                  resetDebounceTimers={cancelDebounce}
                  unsavedChanges={unsavedChanges}
                />
              </tbody>
              <tfoot className={'total'}>
                <EmptyInvestmentLine periodsNumber={previsionelPeriods.length} />
                <EmptyInvestmentLine periodsNumber={previsionelPeriods.length} />
                <tr className={'dataline'}>
                  <td>Total investissements</td>
                  <td>
                    <FormulaDescriptionPopover
                      content={
                        <FormulaPopoverContent
                          calculatedData={contextInvestments.total.sommeMontants}
                          suffix={eurosSuffix(noteSettings.kiloEuros)}
                        />
                      }
                      position={'bottom'}
                    >
                      <div style={{ textAlign: 'end' }}>
                        <span>
                          <NumberText
                            value={contextInvestments.total.sommeMontants.numericData.value}
                            suffix={eurosSuffix(noteSettings.kiloEuros)}
                            decimalScale={0}
                          />
                        </span>
                      </div>
                    </FormulaDescriptionPopover>
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  {contextInvestments.total.sommesAmortissementsExercices.map((value, j) => (
                    <td key={`am-total-${j}`}>
                      <FormulaDescriptionPopover
                        content={
                          <FormulaPopoverContent
                            calculatedData={value}
                            suffix={eurosSuffix(noteSettings.kiloEuros)}
                          />
                        }
                        position={'bottom'}
                      >
                        <div style={{ textAlign: 'end' }}>
                          <span>
                            <NumberText
                              value={value.numericData.value}
                              suffix={eurosSuffix(noteSettings.kiloEuros)}
                              decimalScale={0}
                            />
                          </span>
                        </div>
                      </FormulaDescriptionPopover>
                    </td>
                  ))}
                  <td>&nbsp;</td>
                  <td>
                    <FormulaDescriptionPopover
                      content={
                        <FormulaPopoverContent
                          calculatedData={contextInvestments.total.sommeMontantsTva}
                          suffix={eurosSuffix(noteSettings.kiloEuros)}
                        />
                      }
                      position={'bottom'}
                    >
                      <div style={{ textAlign: 'end' }}>
                        <span>
                          <NumberText
                            value={contextInvestments.total.sommeMontantsTva.numericData.value}
                            suffix={eurosSuffix(noteSettings.kiloEuros)}
                            decimalScale={0}
                          />
                        </span>
                      </div>
                    </FormulaDescriptionPopover>
                  </td>
                  <td>
                    <FormulaDescriptionPopover
                      content={
                        <FormulaPopoverContent
                          calculatedData={contextInvestments.total.sommeMontantsTTC}
                          suffix={eurosSuffix(noteSettings.kiloEuros)}
                        />
                      }
                      position={'bottom'}
                    >
                      <div style={{ textAlign: 'end' }}>
                        <span>
                          <NumberText
                            value={contextInvestments.total.sommeMontantsTTC.numericData.value}
                            suffix={eurosSuffix(noteSettings.kiloEuros)}
                            decimalScale={0}
                          />
                        </span>
                      </div>
                    </FormulaDescriptionPopover>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <EmptyInvestmentLine periodsNumber={previsionelPeriods.length} />
                <EmptyInvestmentLine periodsNumber={previsionelPeriods.length} />
              </tfoot>
            </StyledTable>
          </div>
          <DifferencesSaisiesInvestissementsPlanFinancementWrapper />
          <DifferencesSaisiesInvestissementsCR />
        </div>
      )}
    </div>
  )
}
