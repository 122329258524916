import { NoteType } from '@fa-metier/types'
import { IconName } from '@blueprintjs/icons'
import { useDictionnary } from './dictionnary/dictionnary'
import { useContext } from 'react'
import { NoteContext } from './NotePage'
import { ProjectContext } from '../App'
import env from '../config/environment'

export interface MenuElement {
  text: string
  id?: string
  icon?: IconName
  subMenus?: MenuElement[] // when not empty and first level, renders a sublist of menus
  link?: string // when defined, renders a "Link" menu
  noteTypeConditions?: NoteType[] // If lifecycle conditions not empty, menu element is displayed only for these lifecycles
}

export const useMenuElements: () => MenuElement[] = () => {
  const { note } = useContext(NoteContext)
  const { projectId } = useContext(ProjectContext)
  const dictionnary = useDictionnary()
  const baseNotePath = note.noteId
    ? `/project/${projectId}/note/${note.noteId}`
    : `/project/${projectId}/note/default`

  return [
    {
      text: 'Présentation',
      link: `${baseNotePath}/presentation`,
      id: 'presentationMenuItem',
    },
    {
      text: 'Analyse',
      link: `${baseNotePath}/analyse`,
      id: 'analyseMenuItem',
    },
    {
      text: "Révélateur d'engagement",
      // icon: 'desktop',
      link: `${baseNotePath}/involvement`,
      id: 'revelateurEngagementMenuItem',
    },
    {
      text: 'Volet Financier',
      subMenus: [
        {
          text: dictionnary.bilan.ongletName,
          link: `${baseNotePath}/financiary/bilanCedant`,
          id: 'bilanCedantMenuItem',
          noteTypeConditions: [NoteType.Reprise, NoteType.StructureExistante],
        },
        {
          text: 'Plan de financement',
          link: `${baseNotePath}/financiary/planFinancement`,
          id: 'PFMenuItem',
        },
        {
          text: dictionnary.cr.ongletName,
          link: `${baseNotePath}/financiary/cr`,
          id: 'CRMenuItem',
        },
        {
          text: dictionnary.prets.ongletName,
          link: `${baseNotePath}/financiary/prets`,
          id: 'pretsMenuItem',
        },
        {
          text: dictionnary.pretsPasse.ongletName,
          link: `${baseNotePath}/financiary/pretspasses`,
          id: 'pretsPasseMenuItem',
          noteTypeConditions: [NoteType.StructureExistante],
        },
        {
          text: dictionnary.bfr.menuLabel,
          link: `${baseNotePath}/financiary/bfr`,
          id: 'BFRMenuItem',
        },
        {
          text: 'CAF',
          link: `${baseNotePath}/financiary/caf`,
          id: 'CAFMenuItem',
        },
        {
          text: 'Investissements',
          link: `${baseNotePath}/financiary/investissements`,
          id: 'investissementsMenuItem',
        },
        {
          text: 'Masse salariale',
          link: `${baseNotePath}/financiary/masseSalariale`,
          id: 'MSMenuItem',
        },
        {
          text: 'Benchmark',
          link: `${baseNotePath}/financiary/benchmark`,
          id: 'benchmarkMenuItem',
        },
        {
          text: 'Paramètres',
          link: `${baseNotePath}/financiary/settings`,
          id: 'paramsMenuItem',
        },
      ],
    },
  ]
    .concat(
      env.MEMENTO_ENABLED
        ? [
            {
              text: 'Memento',
              link: `${baseNotePath}/memento`,
              id: 'mentoMenuItem',
            },
          ]
        : []
    )
    .concat([
      {
        text: 'Historique',
        link: `${baseNotePath}/history`,
        id: 'historyMenuItem',
      },
    ])
}
