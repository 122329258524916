import React, { Dispatch, FC, SetStateAction, useContext } from 'react'
import { AmortissementLine, CalculatedData, Investissements } from '@fa-metier/types'
import { NumberText } from '@fa-metier/components'
import { formatDayjs } from '../../../utils/DayjsFormatter'
import dayjs, { Dayjs } from 'dayjs'
import { StrokedButton } from '@fa-metier/components/dist/Buttons/Buttons'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { FormulaDescriptionPopover } from '../Formulas/FormulaDescriptionPopover'
import { FormulaPopoverContent } from '../Formulas/FormulaPopover'
import { v4 as uuidv4 } from 'uuid'
import { InvestmentsContext } from './InvestmentsContext'
import { EmptyInvestmentLine, InvestmentLine } from './InvestmentLine'

const SERVER_DATE_FORMAT = 'YYYY-MM-DD'

const suggestedLabelsIncorporels = [
  "Frais d'établissements",
  'Droit au bail',
  'Part incorporelle du fonds de commerce',
  'Logiciels',
]
const suggestedLabelsCorporels = [
  'Terrains',
  'Constructions',
  'Part corporelle du fonds de commerce',
  'Matériel et outillage industriel',
  'Aménagements',
  'Matériel de transport',
  'Matériel de bureau et informatique',
  'Mobilier',
]
const suggestedLabelsFinanciers = [
  'Titres de participations',
  'Titres immobilisés',
  'Prêts',
  'Dépôts et cautions',
]

type InvestmentType = 'Corporel' | 'Incorporel' | 'Financier'

const retrieveSuggestedLabels = (type: InvestmentType) => {
  if (type === 'Incorporel') {
    return suggestedLabelsIncorporels
  }
  if (type === 'Corporel') {
    return suggestedLabelsCorporels
  }
  return suggestedLabelsFinanciers
}

const retrieveInvestissements = (investissements: Investissements, type: InvestmentType) => {
  if (type === 'Incorporel') {
    return investissements.investissementsIncorporels
  }
  if (type === 'Corporel') {
    return investissements.investissementsCorporels
  }
  return investissements.investissementsFinanciers
}

const newInvestmentLine: (
  label: string,
  defaultExercises: CalculatedData[],
  kiloEuros: boolean,
  dateAchat?: Dayjs
) => AmortissementLine = (label, defaultExercises, kiloEuros, dateAchat) => {
  const dateAchatFormatted = dateAchat ? formatDayjs(dateAchat, SERVER_DATE_FORMAT) : ''
  return {
    label,
    id: uuidv4(),
    dateAchat: dateAchatFormatted,
    montant: { kiloEuros, value: undefined, detail: '' },
    dureeAmortissement: 0,
    tvaRate: 0.0,
    amortissementsExercices: defaultExercises,
    montantTTC: { numericData: { kiloEuros, value: 0.0, detail: null }, detail: [] },
    montantTva: { numericData: { kiloEuros, value: 0.0, detail: null }, detail: [] },
  }
}

export const InvestmentTypeTable: FC<{
  investissements: Investissements
  type: InvestmentType
  setInvestissements: Dispatch<SetStateAction<Investissements>>
  computationInProgress: boolean
  resetDebounceTimers: () => void
  showComputations: boolean
  showInKiloEuros: boolean
  unsavedChanges: boolean
}> = ({
  investissements,
  type,
  setInvestissements,
  computationInProgress,
  showComputations,
  showInKiloEuros,
  resetDebounceTimers,
  unsavedChanges = false,
}) => {
  const { previsionelPeriods } = useContext(InvestmentsContext)

  const suggestedLabels = retrieveSuggestedLabels(type)
  const investissementsSection = retrieveInvestissements(investissements, type)

  const addInvestmentLine = () => {
    const newInvestment = newInvestmentLine(
      '',
      previsionelPeriods.map(() => ({
        numericData: { value: 0.0, detail: null, kiloEuros: showInKiloEuros },
        detail: [],
      })),
      showInKiloEuros,
      previsionelPeriods.length > 0 ? dayjs(previsionelPeriods[0].startDate) : undefined
    )
    setInvestissements((investments) => ({
      ...investments,
      ...(type === 'Incorporel' && {
        investissementsIncorporels: {
          ...investments.investissementsIncorporels,
          lines: [...investments.investissementsIncorporels.lines, ...[newInvestment]],
        },
      }),
      ...(type === 'Corporel' && {
        investissementsCorporels: {
          ...investments.investissementsCorporels,
          lines: [...investments.investissementsCorporels.lines, ...[newInvestment]],
        },
      }),
      ...(type === 'Financier' && {
        investissementsFinanciers: {
          ...investments.investissementsFinanciers,
          lines: [...investments.investissementsFinanciers.lines, ...[newInvestment]],
        },
      }),
    }))
  }

  const minDate =
    previsionelPeriods.length > 0 ? dayjs(previsionelPeriods[0].startDate).toDate() : undefined

  const maxDate =
    previsionelPeriods.length > 0
      ? dayjs(previsionelPeriods[previsionelPeriods.length - 1].endDate).toDate()
      : undefined

  return (
    <>
      <EmptyInvestmentLine periodsNumber={previsionelPeriods.length} />
      <tr>
        <td className={'title'}>Investissements {type.toLowerCase()}s</td>
        <td>&nbsp;</td>
        <td colSpan={4 + previsionelPeriods.length} />
        <td className={'ttc'} />
        <td />
      </tr>
      {investissementsSection &&
        investissementsSection.lines.map((line) => (
          <InvestmentLine
            key={`investissements_line_${line.id}`}
            line={line}
            maxDate={maxDate}
            minDate={minDate}
            setInvestissements={setInvestissements}
            computationInProgress={computationInProgress}
            resetDebounceTimers={resetDebounceTimers}
            suggestedLabels={suggestedLabels}
            type={type}
            deletable={true}
            showComputations={showComputations}
            showInKiloEuros={showInKiloEuros}
            unsavedChanges={unsavedChanges}
          />
        ))}
      {investissements && (
        <tr>
          <td>
            <StrokedButton
              text={`+ Ajouter investissement ${type.toLowerCase()}`}
              onClick={() => addInvestmentLine()}
              disabled={computationInProgress}
            />
          </td>
          <td>&nbsp;</td>
          <td colSpan={4 + previsionelPeriods.length} />
          <td className={'ttc'} />
        </tr>
      )}
      <EmptyInvestmentLine periodsNumber={previsionelPeriods.length} />
      <tr className={'subtotal'}>
        <td>Total investissements {type.toLowerCase()}s</td>
        <td>
          <FormulaDescriptionPopover
            content={
              <FormulaPopoverContent
                calculatedData={investissementsSection.total.sommeMontants}
                suffix={eurosSuffix(showInKiloEuros)}
              />
            }
            position={'bottom'}
          >
            <div style={{ textAlign: 'end' }}>
              <span>
                <NumberText
                  value={investissementsSection.total.sommeMontants.numericData.value}
                  suffix={eurosSuffix(showInKiloEuros)}
                  decimalScale={0}
                />
              </span>
            </div>
          </FormulaDescriptionPopover>
        </td>
        <td colSpan={2} />
        {investissementsSection.total.sommesAmortissementsExercices.map((value, j) => (
          <td key={`investissements_amortissement_${type}_total_${j}`}>
            <FormulaDescriptionPopover
              content={
                <FormulaPopoverContent
                  calculatedData={value}
                  suffix={eurosSuffix(showInKiloEuros)}
                />
              }
              position={'bottom'}
            >
              <div style={{ textAlign: 'end' }}>
                <span>
                  <NumberText
                    value={value.numericData.value}
                    suffix={eurosSuffix(showInKiloEuros)}
                    decimalScale={0}
                  />
                </span>
              </div>
            </FormulaDescriptionPopover>
          </td>
        ))}
        <td />
        <td>
          <FormulaDescriptionPopover
            content={
              <FormulaPopoverContent
                calculatedData={investissementsSection.total.sommeMontantsTva}
                suffix={eurosSuffix(showInKiloEuros)}
              />
            }
            position={'bottom'}
          >
            <div style={{ textAlign: 'end' }}>
              <span>
                <NumberText
                  value={investissementsSection.total.sommeMontantsTva.numericData.value}
                  suffix={eurosSuffix(showInKiloEuros)}
                  decimalScale={0}
                />
              </span>
            </div>
          </FormulaDescriptionPopover>
        </td>
        <td className={'ttc'}>
          <FormulaDescriptionPopover
            content={
              <FormulaPopoverContent
                calculatedData={investissementsSection.total.sommeMontantsTTC}
                suffix={eurosSuffix(showInKiloEuros)}
              />
            }
            position={'bottom'}
          >
            <div style={{ textAlign: 'end' }}>
              <span>
                <NumberText
                  value={investissementsSection.total.sommeMontantsTTC.numericData.value}
                  suffix={eurosSuffix(showInKiloEuros)}
                  decimalScale={0}
                />
              </span>
            </div>
          </FormulaDescriptionPopover>
        </td>
        <td />
      </tr>
      <EmptyInvestmentLine periodsNumber={previsionelPeriods.length} />
    </>
  )
}
