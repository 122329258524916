import React, { FC } from 'react'
import { SolidButton } from '@fa-metier/components/dist/Buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'

export const PageSaveButton: FC<{
  buttonOnClick: () => any
  buttonDisabled: boolean
}> = ({ buttonOnClick, buttonDisabled }) => (
  <SolidButton onClick={buttonOnClick} disabled={buttonDisabled}>
    <FontAwesomeIcon icon={faCloudArrowUp} fontSize={20} />
    <div className={'button-text-block'}>
      <div className={'button-title'}>Enregistrer</div>
    </div>
  </SolidButton>
)
