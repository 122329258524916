import React, { FC, useContext } from 'react'
import { CRLink, DifferenceSaisies } from '../Utils/DifferencesDeSaisie'
import { FinancialPeriodsSaveRequestMultipleInput, NoteType, Pret } from '@fa-metier/types'
import { PeriodModel } from '../PeriodModel'
import dayjs from 'dayjs'
import { dateIsBetween } from '../Utils/date-utils'
import { useUpdatePeriods } from '../FinanciaryQueries'
import { incomeStatementToInput } from '../IncomeStatementMapper'
import { NoteContext } from '../../NotePage'
import { PretsContext } from './PretTabs'
import produce from 'immer'
import { useDictionnary } from '../../dictionnary/dictionnary'

export const DifferencesSaisiesPrets: FC = () => {
  const { noteId, note } = useContext(NoteContext)
  const { previsionelPeriods, prets } = useContext(PretsContext)
  const updateMutation = useUpdatePeriods(noteId)
  const { differencesSaisies } = useDictionnary()
  const dictionnary = useDictionnary()

  const updateCR = () => {
    const updates = previsionelPeriods
      .map((period) => {
        const periodInterets = computeInterets(period, prets).toFixed(2)
        return produce(period, (draft) => {
          if (note.type === NoteType.StructureExistante) {
            draft.incomeStatement.resultatFinancier.chargesInteretsFuturs = {
              value: +periodInterets,
              detail: periodInterets,
              kiloEuros: false,
              percentageReference: 0,
            }
          } else {
            draft.incomeStatement.resultatFinancier.chargeInterets = {
              value: +periodInterets,
              detail: periodInterets,
              kiloEuros: false,
              percentageReference: 0,
            }
          }
        })
      })
      .map(periodToMutationModel)
    return updateMutation({ updates })
  }

  const computeInterets = (period: PeriodModel, prets: Pret[]) => {
    const interets = prets
      .filter((pret) => pret.computedPret)
      .flatMap((pret) => pret.computedPret!!.tableauAmortissement)
      .filter((a) => dateIsBetween(dayjs(a.echeance), period.startDate, period.endDate))
      .map((a) => a.interets)
      .reduce((a, b) => a + b, 0)
      .toFixed(2)
    return +interets
  }

  const sourceChargesInterets = previsionelPeriods.map((period) => computeInterets(period, prets))

  const targetChargesInterets = previsionelPeriods.map((period) => {
    return note.type === NoteType.StructureExistante
      ? period.incomeStatement.resultatFinancier.chargesInteretsFuturs?.value ?? 0
      : period.incomeStatement.resultatFinancier.chargeInterets?.value ?? 0
  })

  const intermediaryTableData = [
    {
      title: "Charges d'intérêts",
      sourceValues: sourceChargesInterets,
      targetTitle: dictionnary.differencesSaisies.targetTitle,
      targetValues: targetChargesInterets,
      sourceDataTitle: 'Données calculées dans cette page',
    },
  ]

  return (
    <DifferenceSaisies
      successText={differencesSaisies.crSuccessText}
      updateFunction={updateCR}
      buttonText={'Remplacer les données du Compte de résultat'}
      dialogBody={
        <>
          <p style={{ whiteSpace: 'nowrap' }}>
            Etes-vous sûr de vouloir remplacer les données du <b>Compte de résultat</b> ?
          </p>
          <p>Cette action ne peut pas être inversée.</p>
        </>
      }
      explanation={
        <>
          {differencesSaisies.crExplanation}
          <div>
            Cette opération effacera définitivement les Charges d'intérêts que vous avez saisies dans le <CRLink />.
          </div>
        </>
      }
      intermediaryTableData={intermediaryTableData}
      hideDemarrage={true}
    />
  )
}

const periodToMutationModel = (period: PeriodModel): FinancialPeriodsSaveRequestMultipleInput => {
  return {
    financialPeriodId: period.id,
    input: {
      incomeStatement: incomeStatementToInput(period.incomeStatement),
    },
  }
}
