import React, { FC, ReactNode, useContext } from 'react'
import styled from 'styled-components'
import { PeriodType, Stylable } from '@fa-metier/types'
import { FinanciaryContext, FinanciaryWrapperContext } from './Financiary'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PeriodsContext } from './Utils/PeriodsContext'

const Sizing = styled.div`
  .benchMark * td {
    min-width: 100px !important;
  }

  .bfr * td:not(.evolution) {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
`

export const Table = styled.table`
  padding: 5px;
  border-collapse: collapse;

  tbody {
    background-color: white;
  }

  &.tableSeparation {
    background-color: var(--main-blue-2);

    &.bfr * td {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
    }
  }

  * th {
    width: 499px;
    min-width: 499px;
    text-align: left;
    padding-left: 20px;
    :before {
      border-bottom: 2px dotted black;
    }
    position: relative;
    color: var(--main-black-2);

    span {
      font-weight: normal;
      span {
        font-weight: normal;
      }
    }

    &.outlined {
      padding: 10px 10px 11px 20px;
      > button {
        margin-top: -10px;
        margin-bottom: -10px;
        padding: 5px;
        align-self: center;
      }
    }

    &.section-title {
      font-size: 20px;
      line-height: 40px;
      color: var(--main-black-1);
      font-weight: var(--bold);
      padding-top: 15px;
      padding-bottom: 5px;
      background-color: var(--main-blue-2);

      &.noPadding {
        padding-top: 0;
        padding-bottom: 0;
        width: fit-content;
      }
    }

    &.detail-total {
      height: 41px;
      align-items: center;
    }
  }

  * td {
    border-right: 1px solid var(--main-grey-2);
    height: 41px;
    padding: 5px 20px;

    &:not(.evolution),
    &:not(.evolutionSeparation) {
      width: 260px;
      min-width: 260px;
      max-width: 260px;
    }

    &.smallHeight {
      height: 10px;
      padding: 0;
    }

    &.evolution.transparent {
      background-color: var(--main-blue-2);
    }

    &.evolution,
    &.intermediaire {
      background-color: var(--main-blue-3);

      &:not(.noViolet) {
        color: var(--main-violet);
      }
    }

    &.anterieur {
      background-color: var(--main-green-2);
    }

    &.anterieurSeparation {
      background-color: var(--main-blue-green);
    }

    &.evolutionSeparation,
    &.intermediaireSeparation {
      background-color: var(--main-blue-4);
    }

    &.evolutionSize,
    &.evolutionSeparation,
    &.evolution {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      text-align: right;
    }

    &.anterieur-cell,
    &.intermediaire-cell {
      position: relative;
    }
    &.anterieur-cell::before,
    &.intermediaire-cell::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &.anterieur-cell::before {
      background-color: var(--main-green-2);
    }

    &.intermediaire-cell::before {
      background-color: var(--main-blue-3);
    }
  }

  * tr {
    vertical-align: baseline;

    &.result {
      background: linear-gradient(var(--main-blue-1), var(--main-blue-1)) no-repeat 15px 0;
      color: white;
      > th {
        color: white;
        height: 39px;
      }
      > td {
        border-left: 1px solid white;
        height: 34px;
        &.hovered {
          background-color: var(--main-grey-1);
        }
      }
    }

    &.subtotal {
      background: linear-gradient(var(--main-grey-3), var(--main-grey-3)) no-repeat 15px 0;
      > td {
        height: 32px;
        &.hovered {
          background-color: var(--main-grey-2);
        }
      }
      > th {
        height: 40px;
      }
    }

    &.calculated {
      background-color: white;
    }

    &.hoverable {
      :hover {
        background: linear-gradient(var(--main-green-2), var(--main-green-2)) no-repeat 15px 0;

        td.anterieur {
          background-color: var(--main-green-3);
        }

        td.evolution,
        td.intermediaire {
          background-color: #b8d0fe;
        }
      }
    }

    th {
      font-weight: var(--bold);
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: baseline;
      padding: 5px 10px 5px 20px;
    }
  }

  * .bp3-input:disabled {
    background-color: var(--main-grey-3);
    box-shadow: 0 0 0 0 rgba(52, 63, 75, 0), 0 0 0 0 rgba(52, 63, 75, 0),
      inset 0 0 0 1px rgba(16, 22, 26, 0.15), inset 0 1px 1px rgba(16, 22, 26, 0.2);
    color: var(--main-black-1);
  }

  &.bfr * td {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
  }
`

const LabelSeparatorStyle = styled.div`
  border-left: 1px solid transparent;
  height: 1800px;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 1px 0 2px 0 var(--main-grey-1);
`

export const Dots = styled.div`
  border-bottom: 2px dotted var(--main-grey-3);
  flex-grow: 1;
  margin: 0 5px;
`

export const VerticalLine = styled.div`
  border-left: 2px solid var(--main-blue-2);
  height: 45px;
  position: absolute;
  left: 35px;
  top: -6px;
`

export const LabelSeparator: FC<{ height: number } & Stylable> = ({ height }) => {
  return <LabelSeparatorStyle className={'label-separator'} style={{ height: `${height}px` }} />
}

export const FinancialTable: FC<
  {
    thead?: ReactNode
    lineBefore?: boolean
    lineAfter?: boolean
    className?: string
    showAnterieur?: boolean
  } & Stylable
> = ({ thead, children, lineBefore = true, lineAfter = true, className = '' }) => {
  return (
    <>
      {lineBefore && <EmptyTable className={className} />}
      <Sizing>
        <Table style={{ tableLayout: 'fixed' }} className={className}>
          <thead>{thead}</thead>
          <tbody>{children}</tbody>
        </Table>
      </Sizing>
      {lineAfter && <EmptyTable className={className} />}
    </>
  )
}

export const TableSeparation: FC<{ extraClassName?: string }> = ({
  extraClassName = '',
  children,
}) => {
  return (
    <>
      <Table style={{ tableLayout: 'fixed' }} className={`${extraClassName} tableSeparation`}>
        <tbody>
          <tr
            style={{
              marginTop: '10px',
              backgroundColor: 'var(--main-blue-2)',
              width: 'fit-content',
            }}
          >
            {children}
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export const EvolutionOrIntermediaireCell: FC<{
  showEvolutionColumn: boolean
  situationIntermediaire: boolean
}> = ({ showEvolutionColumn, situationIntermediaire }) => {
  return (
    <>
      {(showEvolutionColumn || situationIntermediaire) && (
        <td className={showEvolutionColumn ? 'evolution transparent' : ''}>&nbsp;</td>
      )}
    </>
  )
}

export const EmptyTable: FC<{
  backgroundColor?: string
  className?: string
}> = ({ backgroundColor = 'white', className = '' }) => {
  return (
    <Table style={{ backgroundColor, tableLayout: 'fixed' }} className={className}>
      <tbody>
        <EmptyLine />
      </tbody>
    </Table>
  )
}

export const EmptyLine: FC<{
  additionalColumns?: number
}> = ({ additionalColumns }) => {
  const { previsionelPeriods, anterieurPeriods } = useContext(FinanciaryContext)
  const { showEvolutionColumn, showAnterieurs, situationIntermediaire } = useContext(PeriodsContext)

  return (
    <tr>
      <th />
      {showAnterieurs &&
        (anterieurPeriods ?? []).map(() => {
          return (
            <td
              key={Math.random()}
              style={{ height: 'inherit' }}
              className={'anterieur smallHeight'}
            />
          )
        })}
      {showEvolutionColumn && <td className={'evolution smallHeight'}>&nbsp;</td>}
      {situationIntermediaire && <td key={Math.random()} className={'intermediaire smallHeight'} />}
      {previsionelPeriods.map(() => (
        <td key={Math.random()} style={{ height: 'inherit' }} className={'smallHeight'} />
      ))}
      {[...Array(additionalColumns ?? 0).keys()].map(() => (
        <td key={Math.random()} style={{ height: 'inherit' }} className={'smallHeight'} />
      ))}
    </tr>
  )
}

export const EmptyLineWithLabel: FC<{
  label: string
  subtotalStyle?: boolean
  showAnterieur?: boolean
}> = ({ label, subtotalStyle = false, showAnterieur = false }) => {
  const { previsionelPeriods, anterieurPeriods } = useContext(FinanciaryContext)
  const { situationIntermediaire } = useContext(FinanciaryWrapperContext)
  const allPeriods = (showAnterieur && anterieurPeriods ? anterieurPeriods : [])
    .concat(situationIntermediaire ? situationIntermediaire : [])
    .concat(previsionelPeriods)

  return (
    <tr className={subtotalStyle ? 'outlined subtotal' : ''}>
      <th
        style={{ display: 'flex', alignItems: 'baseline' }}
        className={subtotalStyle ? 'outlined' : ''}
      >
        <div>{label}</div>
      </th>
      {allPeriods.map((p, i) => (
        <td
          key={i}
          style={{ height: 'inherit' }}
          className={p.type === PeriodType.Anterieur ? 'anterieur' : ''}
        />
      ))}
    </tr>
  )
}

export const EvolutionValue: FC<{ value?: number | null; color?: 'violet' | 'white' }> = ({
  value,
  color = 'violet',
}) => {
  if (value === undefined || value === null) {
    return <></>
  }

  const icon =
    value === 0 ? (
      <></>
    ) : value > 0 ? (
      <FontAwesomeIcon icon={['fas', 'arrow-up']} />
    ) : (
      <FontAwesomeIcon icon={['fas', 'arrow-down']} />
    )

  return (
    <span
      style={
        color === 'white'
          ? { fontWeight: 600, color: 'white' }
          : { fontWeight: 600, color: 'var(--main-violet)' }
      }
    >
      {icon} {Math.abs(value)} %{' '}
    </span>
  )
}
