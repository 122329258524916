import { IncomeStatement, IncomeStatementInput, NumericData } from '@fa-metier/types'
import produce from 'immer'
import { Dayjs } from 'dayjs'
import { removeTypeName } from '@fa-metier/commons'

export type NumericDataWithDetail = {
  total: NumericData
  hasDetail: boolean
}

function numericDataWithDetailToInput<I extends NumericDataWithDetail, R>(data: I): R {
  // @ts-ignore
  return produce(data, (draft) => {
    // @ts-ignore
    delete draft.hasDetail
  })
}

export function incomeStatementToInput(incomeStatement: IncomeStatement): IncomeStatementInput {
  return removeTypeName({
    chiffre_affaire: {
      total: incomeStatement.chiffre_affaire.total,
      detailLines: incomeStatement.chiffre_affaire.detailLines.lines,
    },
    subventions_exploitation: incomeStatement.subventions_exploitation,
    aides_postes: incomeStatement.aides_postes,
    autres_produits_exploitation: numericDataWithDetailToInput(
      incomeStatement.autres_produits_exploitation
    ),
    achat_marchandises: numericDataWithDetailToInput(incomeStatement.achat_marchandises),
    autres_achats: {
      total: incomeStatement.autres_achats.total,
      totalChargesVariables: incomeStatement.autres_achats.totalChargesVariables,
      charges_externes: incomeStatement.autres_achats.charges_externes.lines,
      fournitures_consommables: incomeStatement.autres_achats.fournitures_consommables.lines,
    },
    taxes: numericDataWithDetailToInput(incomeStatement.taxes),
    traitementsSalaires: incomeStatement.traitementsSalaires,
    cotisationsSocialesPatronales: incomeStatement.cotisationsSocialesPatronales,
    cotisationsSocialesDirigeants: incomeStatement.cotisationsSocialesDirigeants,
    autresChargesDePersonnel: incomeStatement.autresChargesDePersonnel,
    dotationsAmortissementsProvisions: incomeStatement.dotationsAmortissementsProvisions,
    autresChargesExploitation: incomeStatement.autresChargesExploitation,
    resultatFinancier: numericDataWithDetailToInput(incomeStatement.resultatFinancier),
    resultatExceptionnel: numericDataWithDetailToInput(incomeStatement.resultatExceptionnel),
    participationInteressement: incomeStatement.participationInteressement,
    reportRessourcesNonUtilisees: incomeStatement.reportRessourcesNonUtilisees,
    engagementARealiserRessourcesAffectees: incomeStatement.engagementARealiserRessourcesAffectees,
    impotsSurBeneficesInput: incomeStatement.impotsSurBeneficesInput,
  })
}

export function toIso(date: Dayjs) {
  return date.format('YYYY-MM-DD')
}
