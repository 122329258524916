import React, { FC, useContext } from 'react'
import { NumericData, PeriodType, Stylable } from '@fa-metier/types'
import { PeriodsContext } from '../Utils/PeriodsContext'
import { getField } from '@fa-metier/commons'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import { Dots } from '../FinancialTable'
import styled from 'styled-components'
import { PeriodModel } from '../PeriodModel'

interface InputOrDisplayProps {
  label: string
  fieldName: string
  overridingField: string
  isCalculate: boolean
}

const NumericDataInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const FinanciaryLine: FC<{
  fieldPath: string
  overridingField: string
  isOverriden: boolean
  isCalculate: boolean
}> &
  Stylable = ({ fieldPath, overridingField, isOverriden, isCalculate }) => {
  const {
    periods,
    updatePeriods,
    showAnterieurs,
    anterieurPeriods,
    updateAnterieurPeriods,
    situationIntermediaire,
    updateSituationIntermediaire,
  } = useContext(PeriodsContext)

  const updateField = (periodId: string, n?: NumericData) => {
    if (isOverriden) {
      updatePeriods([{ periodId, fieldPath: overridingField, change: n }])
    } else {
      updatePeriods([{ periodId, fieldPath, change: n }])
    }
  }

  const updateAnterieurField = (periodId: string, n?: NumericData) => {
    if (isOverriden) {
      updateAnterieurPeriods!!([{ periodId, fieldPath: overridingField, change: n }])
    } else {
      updateAnterieurPeriods!!([{ periodId, fieldPath, change: n }])
    }
  }

  const updateSituationField = (periodId: string, n?: NumericData) => {
    if (isOverriden) {
      updateSituationIntermediaire!!([{ periodId, fieldPath: overridingField, change: n }])
    } else {
      updateSituationIntermediaire!!([{ periodId, fieldPath, change: n }])
    }
  }

  return (
    <>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p) => (
          <Cell
            key={p.id}
            fieldPath={fieldPath}
            overridingField={overridingField}
            period={p}
            updateField={updateAnterieurField}
            isOverriden={isOverriden}
            isCalculate={isCalculate}
          />
        ))}
      {situationIntermediaire && (
        <Cell
          key={situationIntermediaire.id}
          fieldPath={fieldPath}
          overridingField={overridingField}
          period={situationIntermediaire}
          updateField={updateSituationField}
          isOverriden={isOverriden}
          isCalculate={isCalculate}
        />
      )}
      {periods.map((p) => (
        <Cell
          key={p.id}
          fieldPath={fieldPath}
          overridingField={overridingField}
          period={p}
          updateField={updateField}
          isOverriden={isOverriden}
          isCalculate={isCalculate}
        />
      ))}
    </>
  )
}

const Cell: FC<{
  fieldPath: string
  overridingField: string
  period: PeriodModel
  updateField: (periodId: string, n?: NumericData) => void
  isOverriden: boolean
  isCalculate: boolean
}> = ({ fieldPath, overridingField, period, updateField, isOverriden, isCalculate }) => {
  const { showComputations } = useContext(PeriodsContext)
  const numData = getField<NumericData>(fieldPath, period)
  const override = getField<NumericData>(overridingField, period)

  const className = () => {
    if (period.type === PeriodType.Anterieur) {
      return isOverriden ? 'anterieur' : 'anterieur-cell'
    }
    if (period.type === PeriodType.Intermediaire) {
      return isOverriden ? 'intermediaire' : 'intermediaire-cell'
    }
    return ''
  }

  return (
    <td key={`${period.id}${override ? overridingField : fieldPath}`} className={className()}>
      <NumericDataInputContainer>
        <NumericDataInput
          key={period.id}
          numericData={override ? override : numData}
          onChange={(n) => updateField(period.id, n)}
          showComputations={showComputations}
          isCalculated={isCalculate}
        />
      </NumericDataInputContainer>
    </td>
  )
}

export const InputOrDisplay: FC<InputOrDisplayProps> = ({
  label,
  fieldName,
  overridingField,
  isCalculate,
}) => {
  const { periods, anterieurPeriods, situationIntermediaire } = useContext(PeriodsContext)

  const override =
    periods.filter((p) => getField<NumericData>(overridingField, p)).length > 0 ||
    anterieurPeriods!!.filter((p) => getField<NumericData>(overridingField, p)).length > 0 ||
    (situationIntermediaire && getField<NumericData>(overridingField, situationIntermediaire))

  return (
    <tr className={override ? 'hoverable' : 'hoverable subtotal'}>
      <th className={override ? '' : 'outlined'}>
        <div>{label}</div>
        <Dots />
      </th>
      <FinanciaryLine
        fieldPath={fieldName}
        overridingField={overridingField}
        isOverriden={!!override}
        isCalculate={isCalculate}
      />
    </tr>
  )
}
