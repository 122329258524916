import { Investissements } from '@fa-metier/types'
import { PeriodModel } from '../PeriodModel'
import { createContext } from 'react'

interface InvestmentsContextI {
  investments: Investissements
  previsionelPeriods: PeriodModel[]
}

export const InvestmentsContext = createContext<InvestmentsContextI>({} as InvestmentsContextI)
