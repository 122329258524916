import React, { FC, useContext, useState } from 'react'
import { NoteType, PdfPreferences } from '@fa-metier/types'
import { NoteContext } from '../NotePage'
import { removeTypeName } from '@fa-metier/commons'
import { useDictionnary } from '../dictionnary/dictionnary'
import { useUpdateNote } from '../Financiary/NoteQueries'
import { useDebounce } from 'react-use'
import { ReactComponent as PdfDialogImg } from '../../assets/Scrum board-pana.svg'
import { AppMetierCheckbox } from '../Financiary/Utils/AppMetierCheckBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spinner } from '@blueprintjs/core'
import styled from 'styled-components'
import { retrievePercentageText } from '../Financiary/CR/CRForm'
import { DEBOUNCE_CONFIG } from '../../Settings/debounceConfig'
import { MuffinSynchronization } from '../../MuffinSynchronization/MuffinSynchronization'

const PdfRubriqueTitle = styled.div`
  font-weight: var(--bold);
  margin-top: 15px;
`

const PdfRubriqueSubCat = styled.div`
  margin-left: 40px;
  width: fit-content;

  > label.bp3-checkbox {
    margin-bottom: 0;
  }
`

const SmallCharSpan = styled.span`
  font-size: 12px;
`

const Choices = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const SynchronizationReminder = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--main-blue-2);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
`

export const PdfButton = styled(Button)`
  color: white !important;
  background-color: var(--main-violet) !important;
  border-radius: 30px;
  box-shadow: none !important;
  padding: 5px;
  background-image: none !important;
  font-weight: var(--bold);
  width: 230px;

  :disabled {
    background-color: var(--main-grey-2) !important;
  }
`

export const PdfDialogContent: FC<{ generateFct: (pdfOptions: PdfPreferences) => any }> = ({
  generateFct,
}) => {
  const { note, noteSettings, muffinSyncInProgress } = useContext(NoteContext)
  const [pdfPreferences, setPdfPreferences] = useState<PdfPreferences>(
    removeTypeName(note.pdfPreferences)
  )
  const [savePrefs, setSavePrefs] = useState<boolean>(true)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const dictionnary = useDictionnary()
  const noteId = note.noteId
  const updateNote = useUpdateNote(noteId)

  useDebounce(
    () => {
      if (savePrefs) {
        const savePdfReq = {
          caDetails: pdfPreferences.caDetails,
          chargesDetails: pdfPreferences.chargesDetails,
          sig: pdfPreferences.sig,
          rappelBFR: pdfPreferences.rappelBFR,
          indicateurs: pdfPreferences.indicateurs,
          investissements: pdfPreferences.investissements,
          benchmark: pdfPreferences.benchmark,
          allAnterieursPeriods: pdfPreferences.allAnterieursPeriods,
          bilanCedant:
            note.type === NoteType.StructureExistante ? true : pdfPreferences.bilanCedant,
          revelateurEngagement: pdfPreferences.revelateurEngagement,
          pourcentageCR: pdfPreferences.pourcentageCR,
        }
        updateNote({ noteId, pdfPreferences: savePdfReq })
      }
    },
    DEBOUNCE_CONFIG.delay.updateRequest,
    [pdfPreferences, savePrefs]
  )

  return (
    <div style={{ backgroundColor: 'white' }}>
      <div>
        <PdfDialogImg style={{ backgroundColor: 'var(--main-grey-3)' }} />
        <div
          style={{
            paddingTop: '20px',
            paddingLeft: '55px',
            paddingRight: '55px',
            color: 'var(--main-grey-1)',
            fontSize: '16px',
            lineHeight: '30px',
          }}
        >
          <SynchronizationReminder>
            <span>
              Vous semblez avoir terminé votre note, avez-vous synchronisé vos données avec Muffin ?
            </span>
            <MuffinSynchronization />
          </SynchronizationReminder>
          <div
            style={{
              color: 'var(--main-green-1)',
              fontSize: '20px',
              marginBottom: '5px',
              lineHeight: '26px',
            }}
          >
            Contenu de la note
          </div>
          <div>
            Certaines rubriques sont optionnelles, cochez celles que vous souhaitez intégrer à la
            note PDF :
          </div>
          <div style={{ padding: '20px 20px 0 20px' }}>
            <Choices>
              <div style={{ flexBasis: '100%', marginBottom: '10px' }}>
                <div>Présentation</div>
                <div>Analyse du projet</div>
                <PdfRubriqueSubCat>
                  <AppMetierCheckbox
                    large
                    checked={pdfPreferences.revelateurEngagement}
                    onChange={() =>
                      setPdfPreferences((pdfPreferences) => ({
                        ...pdfPreferences,
                        revelateurEngagement: !pdfPreferences.revelateurEngagement,
                      }))
                    }
                  >
                    Révélateur d'engagement
                  </AppMetierCheckbox>
                </PdfRubriqueSubCat>
              </div>
              <div>
                <PdfRubriqueTitle>1. Évaluation du projet</PdfRubriqueTitle>
                <div>Environnement</div>
                <div>Positionnement</div>
                <div>Moyens</div>
                <div>Equipe</div>
              </div>
              <div>
                <PdfRubriqueTitle>2. Analyse financière</PdfRubriqueTitle>
                <div>{dictionnary.cr.ongletName}</div>
                {note.type === NoteType.Reprise && pdfPreferences.showAnterieurPeriodsOption && (
                  <PdfRubriqueSubCat>
                    <AppMetierCheckbox
                      large
                      checked={pdfPreferences.allAnterieursPeriods}
                      onChange={() =>
                        setPdfPreferences((pdfPreferences) => ({
                          ...pdfPreferences,
                          allAnterieursPeriods: !pdfPreferences.allAnterieursPeriods,
                        }))
                      }
                    >
                      Afficher tous les exercices du cédant
                    </AppMetierCheckbox>
                  </PdfRubriqueSubCat>
                )}
                {pdfPreferences.showCaDetails && (
                  <PdfRubriqueSubCat>
                    <AppMetierCheckbox
                      large
                      checked={pdfPreferences.caDetails}
                      onChange={() =>
                        setPdfPreferences((pdfPreferences) => ({
                          ...pdfPreferences,
                          caDetails: !pdfPreferences.caDetails,
                        }))
                      }
                    >
                      Détail du CA
                    </AppMetierCheckbox>
                  </PdfRubriqueSubCat>
                )}
                <PdfRubriqueSubCat>
                  <AppMetierCheckbox
                    large
                    checked={pdfPreferences.sig}
                    onChange={() =>
                      setPdfPreferences((pdfPreferences) => ({
                        ...pdfPreferences,
                        sig: !pdfPreferences.sig,
                      }))
                    }
                  >
                    SIG
                  </AppMetierCheckbox>
                </PdfRubriqueSubCat>
                {pdfPreferences.showChargesDetails && (
                  <PdfRubriqueSubCat>
                    <AppMetierCheckbox
                      large
                      checked={pdfPreferences.chargesDetails}
                      onChange={() =>
                        setPdfPreferences((pdfPreferences) => ({
                          ...pdfPreferences,
                          chargesDetails: !pdfPreferences.chargesDetails,
                        }))
                      }
                    >
                      Détail des charges
                    </AppMetierCheckbox>
                  </PdfRubriqueSubCat>
                )}
                {noteSettings.referenceAmount !== 'NONE' && (
                  <PdfRubriqueSubCat>
                    <AppMetierCheckbox
                      large
                      checked={pdfPreferences.pourcentageCR}
                      onChange={() =>
                        setPdfPreferences((pdfPreferences) => ({
                          ...pdfPreferences,
                          pourcentageCR: !pdfPreferences.pourcentageCR,
                        }))
                      }
                    >
                      {retrievePercentageText(noteSettings.referenceAmount)}
                    </AppMetierCheckbox>
                  </PdfRubriqueSubCat>
                )}
                <div>Plan de financement</div>
                <PdfRubriqueSubCat>
                  <AppMetierCheckbox
                    large
                    checked={pdfPreferences.indicateurs}
                    onChange={() =>
                      setPdfPreferences((pdfPreferences) => ({
                        ...pdfPreferences,
                        indicateurs: !pdfPreferences.indicateurs,
                      }))
                    }
                  >
                    Indicateurs financiers <SmallCharSpan>(ratios)</SmallCharSpan>
                  </AppMetierCheckbox>
                </PdfRubriqueSubCat>
                <PdfRubriqueSubCat>
                  <AppMetierCheckbox
                    large
                    checked={pdfPreferences.rappelBFR}
                    onChange={() =>
                      setPdfPreferences((pdfPreferences) => ({
                        ...pdfPreferences,
                        rappelBFR: !pdfPreferences.rappelBFR,
                      }))
                    }
                  >
                    Rappel BFR <SmallCharSpan>(ratios)</SmallCharSpan>
                  </AppMetierCheckbox>
                </PdfRubriqueSubCat>
                <div>
                  <AppMetierCheckbox
                    large
                    label={'Benchmark'}
                    checked={pdfPreferences.benchmark}
                    onChange={() =>
                      setPdfPreferences((pdfPreferences) => ({
                        ...pdfPreferences,
                        benchmark: !pdfPreferences.benchmark,
                      }))
                    }
                  />
                </div>
                {pdfPreferences.showInvestissements && (
                  <div>
                    <AppMetierCheckbox
                      large
                      label={'Investissements'}
                      checked={pdfPreferences.investissements}
                      onChange={() =>
                        setPdfPreferences((pdfPreferences) => ({
                          ...pdfPreferences,
                          investissements: !pdfPreferences.investissements,
                        }))
                      }
                    />
                  </div>
                )}
                {pdfPreferences.showBilanCedant && (
                  <div>
                    {note.type === NoteType.StructureExistante ? (
                      dictionnary.bilan.ongletName
                    ) : (
                      <AppMetierCheckbox
                        large
                        label={dictionnary.bilan.ongletName}
                        checked={pdfPreferences.bilanCedant}
                        onChange={() =>
                          setPdfPreferences((pdfPreferences) => ({
                            ...pdfPreferences,
                            bilanCedant: !pdfPreferences.bilanCedant,
                          }))
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            </Choices>
          </div>
          <div
            style={{
              marginTop: '30px',
              fontWeight: 700,
              fontSize: '14px',
              fontStyle: 'italic',
              lineHeight: '23px',
              color: 'var(--main-black-2)',
            }}
          >
            <AppMetierCheckbox
              large
              label={'Conserver ces choix pour le prochain export PDF de cette note.'}
              checked={savePrefs}
              onChange={() => setSavePrefs(!savePrefs)}
            />
          </div>
        </div>
        <div style={{ marginTop: '30px', textAlign: 'center' }}>
          {!showSpinner && (
            <PdfButton
              onClick={() => {
                generateFct(pdfPreferences)
                setShowSpinner(true)
              }}
              large={true}
              disabled={muffinSyncInProgress}
            >
              <FontAwesomeIcon icon={['far', 'file-pdf']} /> Générer la note
            </PdfButton>
          )}
          {showSpinner && (
            <div>
              Veuillez patienter pendant la génération de la note pdf
              <br />
              <Spinner intent={'primary'} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
