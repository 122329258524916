import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { NumericData, Stylable } from '@fa-metier/types'
import { Classes, FormGroup, Icon, InputGroup, Intent } from '@blueprintjs/core'
import { CALCULATOR } from '@blueprintjs/icons/lib/esm/generated/iconNames'
import styled from 'styled-components'
import {
  computeValue,
  formatAsNumber,
  normalizeDecimalSeparator,
} from '@fa-metier/components/dist/formatNumber/FormatNumber'
import { handleUserKeyPress } from '../keyboard-navigation'

interface NumericDataInputProps {
  label?: string
  numericData?: NumericData | null
  /**
   *
   * @param numericData the updated NumericData
   * @param valid is true if the formula is valid, false otherwise
   */
  onChange: (numericData: NumericData) => any
  showComputations: boolean
  isCalculated: boolean
  isEuro?: boolean
  isPourcentage?: boolean
  id?: string
}

const FormGroupNumericDataInput = styled(FormGroup)`
  width: 100px;
  flex-grow: 2;
  border-radius: 3px;
  margin: 0;

  .action {
    background-color: white;
    border: none !important;
    box-shadow: none !important;

    :hover {
      background-color: white;
    }

    .bp3-icon {
      color: var(--main-grey-2);
    }
  }

  input {
    box-shadow: none !important;
  }
`

const InnerSuffix = styled.div`
  position: absolute !important;
  right: 15px !important;
`

const Unit = styled.div`
  position: absolute !important;
  right: 7px !important;
  top: 7px !important;
`

const isComputation = (numericData?: NumericData | null) => {
  if (!numericData || !numericData.detail || Number(numericData.detail) === numericData.value) {
    return false
  }

  const displayAsString = numericData.detail.toString()
  return (
    (displayAsString.indexOf('+') +
      displayAsString.indexOf('-') +
      displayAsString.indexOf('*') +
      displayAsString.indexOf('/')) /
      4 >
    -1
  )
}

const isValid = (numericData?: NumericData | null) =>
  !numericData ||
  !numericData.detail ||
  !isComputation(numericData) ||
  computeValue(numericData.detail) !== undefined

const getValue = (numericData: NumericData, isCalculated: Boolean) => {
  const result =
    numericData.value !== null && numericData.value !== undefined
      ? numericData.value === 0 && (numericData.detail === null || numericData.detail === '')
        ? isCalculated
          ? numericData.value.toString()
          : ''
        : numericData.value.toString()
      : ''
  return formatAsNumber(result, 2)
}

const getDetail = (numericData: NumericData) => {
  return numericData.detail ? numericData.detail : ''
}

/**
 *
 * @param numericData the NumericData to display
 * @param onChange called when the user leaves focus from the field
 * @param label field label
 * @param disable disable input
 * @param showComputations
 * @constructor
 */
export const NumericDataInput: FC<NumericDataInputProps & Stylable> = ({
  numericData,
  onChange,
  label,
  showComputations,
  isCalculated,
  isEuro = true,
  isPourcentage = false,
  style,
  id,
}) => {
  const [data, setData] = useState(numericData)
  const [isOperation, setIsOperation] = useState(isComputation(data))
  const inKiloEuros = !!data?.kiloEuros
  const [computationDisplayed, setComputationDisplayed] = useState(false)
  const [valid, setValid] = useState(isValid(data))
  const [display, setDisplay] = useState<string>(
    data ? (valid ? getValue(data, isCalculated) : getDetail(data)) : ''
  )

  const [leaveFocus, setLeaveFocus] = useState(true)

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [])

  const unit = () => (isEuro ? (inKiloEuros ? 'K€' : '€') : isPourcentage ? ' %' : 'j')

  const onFocus = () => {
    if (data && data.detail) {
      if (!data.value && data.value !== 0) {
        setDisplay('')
      } else {
        setDisplay(normalizeDecimalSeparator(data.detail))
      }
    }
    setLeaveFocus(false)
  }

  const getToBeDisplayed = (data?: NumericData | null) => {
    if (data) {
      const detail = getDetail(data)
      if (!isOperation || detail === undefined || detail === null || detail === '') {
        return getValue(data, false)
      }
      return detail
    }
    return ''
  }

  if (showComputations && !computationDisplayed) {
    const toBeDisplayed = getToBeDisplayed(data)
    setDisplay(toBeDisplayed)
    setComputationDisplayed(true)
  }
  if (!showComputations && computationDisplayed) {
    setDisplay(data ? getValue(data, isCalculated) : '')
    setComputationDisplayed(false)
  }

  const updateData = (value: string, kiloEuros: boolean) => {
    const sanitizedValue = value.replace(/,/g, '.').replace(/\s/g, '')
    const numericValue = computeValue(sanitizedValue)
    const newData: NumericData = {
      kiloEuros,
      value: numericValue,
      detail: sanitizedValue,
    }
    setData(newData)
    setIsOperation(isComputation(newData))
    setValid(isValid(newData))
    onChange(newData)
  }

  const onLeaveFocus = () => {
    setLeaveFocus(true)
    if (valid && data && !computationDisplayed) {
      setDisplay(getValue(data, isCalculated))
    }
  }

  const defaultStyle = {
    borderRadius: '5px',
    outline: 'none',
    height: '32px',
    border: 'var(--main-grey-2-lighter) 1px solid',
  } as React.CSSProperties

  const operationStyle = {
    focus: {
      ...defaultStyle,
      border: 'solid 1px var(--main-green-1)',
      color: 'var(--main-green-1)',
      boxShadow: 'none',
    } as React.CSSProperties,
    none: { ...defaultStyle } as React.CSSProperties,
  }

  return (
    <FormGroupNumericDataInput
      label={label}
      intent={valid ? Intent.NONE : Intent.DANGER}
      helperText={valid ? undefined : "La formule n'est pas valide"}
      style={style}
    >
      <div className={`${Classes.INPUT_GROUP}`}>
        <InputGroup
          intent={valid ? Intent.NONE : Intent.DANGER}
          className={isOperation ? 'calculus-input' : ''}
          style={leaveFocus ? operationStyle.none : operationStyle.focus}
          disabled={isCalculated}
          onFocus={onFocus}
          onBlur={onLeaveFocus}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const eventValue = normalizeDecimalSeparator(event.target.value)
            setDisplay(eventValue)
            if (!leaveFocus) {
              updateData(
                eventValue,
                isEuro ? (numericData?.kiloEuros ? numericData.kiloEuros : false) : false
              )
            }
          }}
          placeholder=""
          value={display === null ? undefined : display}
          id={id}
        />
        {!isOperation && display && (
          <Unit
            className={Classes.INPUT_ACTION}
            style={leaveFocus ? {} : { color: 'var(--main-green-1)' }}
          >
            <span>{unit()}</span>
          </Unit>
        )}
        {isOperation && (
          <>
            <InnerSuffix className={Classes.INPUT_ACTION} style={{ lineHeight: '30px' }}>
              <Icon
                icon={CALCULATOR}
                style={{ margin: '0 5px', color: leaveFocus ? 'gray' : 'var(--main-green-1)' }}
              />
            </InnerSuffix>
            <Unit
              className={Classes.INPUT_ACTION}
              style={leaveFocus ? {} : { color: 'var(--main-green-1)' }}
            >
              <span>{unit()}</span>
            </Unit>
          </>
        )}
      </div>
    </FormGroupNumericDataInput>
  )
}
