import React, { FC, useContext } from 'react'
import { PlanFinancementEntryType } from '@fa-metier/types'
import { FinanciaryContext } from '../Financiary'
import { useAddPlanFinancementEntry } from './PlanFinancementQueries'
import { StrokedButton } from '@fa-metier/components/dist/Buttons/Buttons'
import { EditableEntryLine } from './EditableEntryLine'
import { NoteContext } from '../../NotePage'
import { PFContext } from './PlanFinancementContainer'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { PlanFinancementContext } from './PlanFinancement'

interface RessourceLinesProps {
  type: PlanFinancementEntryType
  onlyDemarrage?: boolean
  showBlockTitle?: boolean
}

export const RessourceLines: FC<RessourceLinesProps> = ({
  type,
  onlyDemarrage = false,
  showBlockTitle = true,
}) => {
  const { noteId, noteSettings } = useContext(NoteContext)
  const { previsionelPeriods } = useContext(FinanciaryContext)
  const { planFinancement } = useContext(PFContext)
  const { computationInProgress } = useContext(PlanFinancementContext)
  const addNewEntry = useAddPlanFinancementEntry(noteId)
  const dictionnary = useDictionnary()

  return (
    <>
      {((onlyDemarrage && noteSettings.showDemarrage) || !onlyDemarrage) && (
        <>
          {showBlockTitle && (
            <tr>
              <td className={'title'}>
                {entryTypeLabel(type, dictionnary.pretTVADemarrage.label)}
              </td>
              {noteSettings.showDemarrage && <td className={'demarrage'} />}
              <td colSpan={previsionelPeriods.length} />
              <td className={'action'} />
            </tr>
          )}
          {planFinancement!!.editableEntries
            .filter((it) => it.type === type)
            .map((entry) => (
              <EditableEntryLine key={entry.entryId} entry={entry} onlyDemarrage={onlyDemarrage} />
            ))}
          <tr>
            <td>
              <StrokedButton
                text={`+ Ajouter ${entryTypeSingular(type, dictionnary.pretTVADemarrage.button)}`}
                onClick={() => addNewEntry(type)}
                disabled={computationInProgress}
              />
            </td>
            {noteSettings.showDemarrage && <td className={'demarrage'} />}
            <td colSpan={previsionelPeriods.length + 1} />
          </tr>
        </>
      )}
    </>
  )
}

const entryTypeLabel = (type: PlanFinancementEntryType, labelCreditTva: string) => {
  switch (type) {
    case PlanFinancementEntryType.Apports:
      return 'Apports'
    case PlanFinancementEntryType.Primes:
      return 'Primes'
    case PlanFinancementEntryType.SubventionsInvestissements:
      return 'Subventions Investissements'
    case PlanFinancementEntryType.PretsAt:
      return "Prêts gérés par l'AT"
    case PlanFinancementEntryType.PretsHorsAtGarantis:
      return 'Prêts externalisés garantis'
    case PlanFinancementEntryType.PretsHorsAtNonGarantis:
      return 'Prêts externalisés non garantis'
    case PlanFinancementEntryType.CreditRelaisTva:
      return labelCreditTva
  }
}

const entryTypeSingular = (type: PlanFinancementEntryType, labelCreditTva: string) => {
  switch (type) {
    case PlanFinancementEntryType.Apports:
      return 'un apport'
    case PlanFinancementEntryType.Primes:
      return 'une prime'
    case PlanFinancementEntryType.SubventionsInvestissements:
      return "une subvention d'investissement"
    case PlanFinancementEntryType.PretsAt:
      return "un prêt à l'AT"
    case PlanFinancementEntryType.PretsHorsAtGarantis:
      return 'un prêt externalisé garanti'
    case PlanFinancementEntryType.PretsHorsAtNonGarantis:
      return 'un prêt externalisé non garanti'
    case PlanFinancementEntryType.CreditRelaisTva:
      return labelCreditTva
  }
}
