import {
  ScoringDocument,
  useScoringQuery,
  useScoringCreationCheckQuery,
  useUpdateScoringCreationMutation,
  useUpdateScoringDeveloppementMutation,
  useScoringDeveloppementCheckQuery,
} from '../../generated/graphql'
import { Scoring } from '@fa-metier/types'

export const useGetScoring = (noteId: string) => {
  const { data, loading, error } = useScoringQuery({
    variables: { noteId },
  })
  return {
    loading,
    data: data ? data.scoring : undefined,
    error
  }
}

export const useUpdateScoringCreation = (noteId: string) => {
  const [updateScoringCreationMutation] = useUpdateScoringCreationMutation({
    variables: { noteId },
    update: (cache) => {
      cache.evictQueries([
        {
          query: ScoringDocument,
          variables: { noteId },
        },
      ])
    },
  })
  return () => {
    return new Promise<Scoring>((resolve, reject) => {
      updateScoringCreationMutation({
        variables: { noteId },
      })
        .then((response) => {
          const result =
            response.data && response.data.scoring.updateScoringCreation
              ? response.data.scoring.updateScoringCreation
              : null
          result !== null ? resolve(result) : reject('Error in scoring update')
        })
        .catch((error) => reject(error))
    })
  }
}

export const useUpdateScoringDeveloppement = (noteId: string) => {
  const [updateScoringCreationMutation] = useUpdateScoringDeveloppementMutation({
    variables: { noteId },
    update: (cache) => {
      cache.evictQueries([
        {
          query: ScoringDocument,
          variables: { noteId },
        },
      ])
    },
  })
  return () => {
    return new Promise<Scoring>((resolve, reject) => {
      updateScoringCreationMutation({
        variables: { noteId },
      })
        .then((response) => {
          const result =
            response.data && response.data.scoring.updateScoringDeveloppement
              ? response.data.scoring.updateScoringDeveloppement
              : null
          result !== null ? resolve(result) : reject('Error in scoring update')
        })
        .catch((error) => reject(error))
    })
  }
}

export const useGetScoringCreationCheck = (noteId: string) => {
  const { data, loading } = useScoringCreationCheckQuery({
    variables: { noteId },
  })
  return {
    loading,
    data: data ? data.scoringCreationCheck : undefined,
  }
}

export const useGetScoringDeveloppementCheck = (noteId: string) => {
  const { data, loading } = useScoringDeveloppementCheckQuery({
    variables: { noteId },
  })
  return {
    loading,
    data: data ? data.scoringDeveloppementCheck : undefined,
  }
}
