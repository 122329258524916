import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { PretPasse } from '@fa-metier/types'
import {
  toPretPasseInput,
  useDeletePretPasse,
  useUpdatePretPasse,
  validatePretPasse,
} from './PretsPassesQueries'
import { useDebounce } from 'react-use'
import { Button, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core'
import { DIALOG_BODY, DIALOG_FOOTER } from '@blueprintjs/core/lib/esm/common/classes'
import styled from 'styled-components'
import { EditIcon } from '@fa-metier/components'
import { PretCard } from '../PretStyle'
import { AppMetierNumberInput } from '../../Utils/AppMetierInput/AppMetierNumberInput'
import { AppMetierDateInput } from '../../Utils/AppMetierInput/AppMetierDateInput'
import { NumericDataInput } from '../../Utils/NumericDataInput/NumericDataInput'
import dayjs from 'dayjs'
import { getDayjsFormatter } from '../../../../utils/DayjsFormatter'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { AppMetierCheckbox } from '../../Utils/AppMetierCheckBox'
import { NoteContext } from '../../../NotePage'
import { PretsPassesContext } from './PretPasseTabs'
import { PretSynthese } from '../PretSynthese'
import { PlanAmortissementTable } from '../PretPage'
import { saveShortcutListener, withFieldsGuard } from '../../../../utils/utils'
import { PageSaveButton } from '../../../../utils/PageSaveButton'
import { ParametersDiv } from '../../../../utils/ParametersDiv'
import _ from 'lodash'
import { useUserDebounce } from '../../../../Settings/Hooks'

export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY'

export const FormGroup20 = styled(FormGroup)`
  width: 21%;
  margin-right: 20px;

  .bp3-label {
    font-weight: var(--bold);
  }

  * .bp3-input,
  .bp3-popover-target,
  .bp3-input-group {
    flex-grow: 1;
  }
`

const PretFormDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const CustomComputedField = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 20em;

  div.label {
    font-weight: var(--bold);
  }

  div.content {
    line-height: 38px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      padding-left: 5px;
    }
  }
`

interface PretPasseContextI {
  pretPasse: PretPasse
  showComputations: boolean
  setPretPasse: Dispatch<SetStateAction<PretPasse>>
}

const PretPasseContext = React.createContext({} as PretPasseContextI)

export const PretPasseForm: FC<{ inputPretPasse: PretPasse; onRemovePret: () => any }> = ({
  inputPretPasse,
  onRemovePret,
}) => {
  const { noteId, unsavedChanges, setUnsavedChanges, muffinSyncInProgress } =
    useContext(NoteContext)
  const { previsionelPeriods } = useContext(PretsPassesContext)
  const [pretPasse, setPretPasse] = useState(inputPretPasse)
  const [showEditTitlePopup, setShowEditTitlePopup] = useState<string | undefined>(undefined)
  const [totalDuration, setTotalDuration] = useState(pretPasse.duration + pretPasse.delayed)
  const updatePret = useUpdatePretPasse(noteId, pretPasse.id)
  const removePret = useDeletePretPasse(noteId, pretPasse.id)
  const [showPopupRemovePret, setShowPopupRemovePret] = useState(false)

  const [showComputations, setShowComputations] = useState(false)
  const [computationInProgress, setComputationInProgress] = useState(false)

  const refs = useRef({
    inputPretPasse,
    pretPasse,
    unsavedChanges,
  })

  const valid = () => validatePretPasse(refs.current.pretPasse)

  const maxDate =
    previsionelPeriods.length > 0
      ? dayjs(previsionelPeriods[previsionelPeriods.length - 1].endDate).toDate()
      : undefined

  const userDebounce = useUserDebounce()

  const [, cancelDebounce] = useDebounce(
    async () => {
      if (valid().all && !_.isEqual(pretPasse, refs.current.inputPretPasse)) {
        await withFieldsGuard(
          () => updatePret(toPretPasseInput(pretPasse)),
          setComputationInProgress
        )
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [pretPasse, toPretPasseInput]
  )

  useEffect(() => {
    refs.current.pretPasse = pretPasse
    if (valid().all && !_.isEqual(pretPasse, refs.current.inputPretPasse)) {
      setUnsavedChanges(true)
    }
  }, [pretPasse, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.inputPretPasse, inputPretPasse)) {
      refs.current.inputPretPasse = inputPretPasse
      setPretPasse(inputPretPasse)
      cancelDebounce()
    }
  }, [inputPretPasse, cancelDebounce])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelDebounce()
      if (valid().all && !_.isEqual(refs.current.pretPasse, refs.current.inputPretPasse)) {
        await updatePret(toPretPasseInput(refs.current.pretPasse))
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTotalDuration(pretPasse.inFine ? pretPasse.duration : pretPasse.duration + pretPasse.delayed)
  }, [pretPasse.inFine, pretPasse.delayed, pretPasse.duration])

  const calculPremierRemboursement = () => {
    return pretPasse.startingDate
      ? dayjs(pretPasse.startingDate).add(pretPasse.delayed, 'month').format(DISPLAY_DATE_FORMAT)
      : pretPasse.startingDate
  }

  return (
    <div>
      {pretPasse && (
        <PretPasseContext.Provider
          value={{
            pretPasse,
            setPretPasse,
            showComputations,
          }}
        >
          <ParametersDiv>
            <PageSaveButton
              buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
              buttonDisabled={computationInProgress || !unsavedChanges}
            />
            <AppMetierSwitch
              label={'Voir tous les calculs'}
              checked={showComputations}
              onChange={() => {
                setShowComputations(!showComputations)
              }}
            />
          </ParametersDiv>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ color: 'var(--main-green-1)' }}>{pretPasse.title}</h2>
            <Button
              style={{ width: '24px', height: '24px', marginLeft: '10px' }}
              minimal={true}
              text={<EditIcon width={18} height={18} color={'var(--main-green-1)'} />}
              onClick={() => setShowEditTitlePopup(pretPasse.title)}
            />
            <Button
              icon={'trash'}
              minimal
              large={false}
              onClick={() => setShowPopupRemovePret(true)}
            />
            <Dialog isOpen={showPopupRemovePret} title={'Attention'} isCloseButtonShown={false}>
              <div className={DIALOG_BODY}>
                <div>
                  Etes-vous sûr de vouloir supprimer le Prêt <b>{pretPasse.title}</b> ?
                </div>
                <div>Cette action ne peut pas être inversée.</div>
              </div>
              <div className={DIALOG_FOOTER}>
                <Button
                  style={{ marginRight: '0.5em' }}
                  onClick={() => setShowPopupRemovePret(false)}
                >
                  Annuler
                </Button>
                <Button
                  intent={'primary'}
                  onClick={() => {
                    removePret().then(() => {
                      setShowPopupRemovePret(false)
                      onRemovePret()
                    })
                  }}
                >
                  Valider
                </Button>
              </div>
            </Dialog>
            <Dialog
              isOpen={!!showEditTitlePopup}
              title={`Modifier le titre de ${pretPasse.title}`}
              isCloseButtonShown={false}
            >
              <div className={DIALOG_BODY}>
                <InputGroup
                  value={showEditTitlePopup}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                    setShowEditTitlePopup(ev.target.value)
                  }
                />
              </div>
              <div className={DIALOG_FOOTER}>
                <Button
                  style={{ marginRight: '0.5em' }}
                  onClick={() => setShowEditTitlePopup(undefined)}
                >
                  Annuler
                </Button>
                <Button
                  intent={'primary'}
                  onClick={() => {
                    setPretPasse((pretPasse) => ({ ...pretPasse, title: showEditTitlePopup!! }))
                    setShowEditTitlePopup(undefined)
                  }}
                >
                  Valider
                </Button>
              </div>
            </Dialog>
          </div>
          <PretCard>
            <AppMetierCheckbox
              label={'In fine'}
              checked={pretPasse.inFine}
              onChange={() => {
                setPretPasse((pretPasse) => ({
                  ...pretPasse,
                  inFine: !pretPasse.inFine,
                  delayed: !pretPasse.inFine ? 0 : pretPasse.delayed,
                }))
              }}
              style={{ width: 'fit-content' }}
              disabled={computationInProgress || muffinSyncInProgress}
            />
            <PretFormDiv>
              <div
                style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                key={'firstLineTr'}
              >
                <FormGroup20 label={'Montant du prêt'}>
                  <div>
                    <NumericDataInput
                      style={{ width: '100%' }}
                      numericData={pretPasse.capital}
                      onChange={(value) => {
                        if (value?.value) {
                          setPretPasse((pretPasse) => ({ ...pretPasse, capital: value }))
                        }
                      }}
                      showComputations={showComputations}
                      isCalculated={computationInProgress || muffinSyncInProgress}
                      isEuro={true}
                    />
                  </div>
                </FormGroup20>
                <FormGroup20 label={"Taux d'intérêt annuel"}>
                  <AppMetierNumberInput
                    value={pretPasse.annualRate ? +(pretPasse.annualRate * 100).toFixed(2) : 0}
                    onChange={(value) => {
                      if (value || value === 0) {
                        setPretPasse((pretPasse) => ({ ...pretPasse, annualRate: value / 100 }))
                      }
                    }}
                    suffix={Suffixes.POURCENTAGE}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
                <FormGroup20
                  label={"Nombre d'échéances par an"}
                  intent={valid().yearlyPaymentNb.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().yearlyPaymentNb.join(' ')}
                >
                  <AppMetierNumberInput
                    value={pretPasse.yearlyPaymentNb}
                    onChange={(valueAsNumber) => {
                      if (valueAsNumber || valueAsNumber === 0) {
                        setPretPasse((pretPasse) => ({
                          ...pretPasse,
                          yearlyPaymentNb: valueAsNumber,
                        }))
                      }
                    }}
                    intent={valid().yearlyPaymentNb ? Intent.NONE : Intent.DANGER}
                    defaultValue={12}
                    allowedValues={[0, 1, 2, 4, 12]}
                    onlyInteger={true}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
                <FormGroup20
                  label={'Date de décaissement'}
                  intent={valid().startingDate.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().startingDate.join(' ')}
                >
                  <AppMetierDateInput
                    placeholder={DISPLAY_DATE_FORMAT}
                    value={pretPasse.startingDate}
                    maxDate={maxDate}
                    onChange={(selectedDate, isUserChange) => {
                      if (isUserChange) {
                        setPretPasse((pretPasse) => ({ ...pretPasse, startingDate: selectedDate }))
                      }
                    }}
                    {...getDayjsFormatter(DISPLAY_DATE_FORMAT)}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                key={'secondLineTr'}
              >
                <FormGroup20
                  label={'Durée totale'}
                  intent={valid().duration.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().duration.join(' ')}
                >
                  <AppMetierNumberInput
                    value={totalDuration || 0}
                    onChange={(ev) => {
                      if (ev) {
                        if (pretPasse.duration + pretPasse.delayed !== ev) {
                          setTotalDuration(ev)
                          setPretPasse((pretPasse) => {
                            if (pretPasse.delayed !== undefined) {
                              return { ...pretPasse, duration: ev - pretPasse.delayed }
                            }
                            if (pretPasse.duration !== undefined) {
                              return { ...pretPasse, delayed: ev - pretPasse.duration }
                            }
                            return { ...pretPasse, delayed: 0, duration: ev }
                          })
                        }
                      }
                    }}
                    suffix={Suffixes.MOIS}
                    onlyInteger={true}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
                <FormGroup20
                  label={'Différé'}
                  intent={valid().delayed.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().delayed.join(' ')}
                >
                  <AppMetierNumberInput
                    key={`delay${pretPasse.inFine}${pretPasse.id}`}
                    value={
                      pretPasse.delayed !== undefined && pretPasse.delayed >= 0
                        ? pretPasse.inFine
                          ? 0
                          : pretPasse.delayed
                        : 0
                    }
                    onChange={(ev) => {
                      const delayed = ev ?? 0
                      setPretPasse((pretPasse) => ({ ...pretPasse, delayed }))
                      setTotalDuration(pretPasse.duration + delayed)
                    }}
                    suffix={Suffixes.MOIS}
                    disabled={pretPasse.inFine || computationInProgress || muffinSyncInProgress}
                    onlyInteger={true}
                  />
                </FormGroup20>
                <FormGroup20
                  label={'Durée du prêt hors différé'}
                  intent={valid().duration.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().duration.join(' ')}
                >
                  <AppMetierNumberInput
                    value={pretPasse.duration || 0}
                    onChange={(ev) => {
                      if (ev) {
                        setPretPasse((pretPasse) => ({ ...pretPasse, duration: ev }))
                        setTotalDuration(ev + pretPasse.delayed)
                      }
                    }}
                    suffix={Suffixes.MOIS}
                    onlyInteger={true}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
                <CustomComputedField>
                  <div className={'label'}>Date de 1er remboursement</div>
                  <div className={'content'}>
                    <div>{calculPremierRemboursement()}</div>
                  </div>
                </CustomComputedField>
              </div>
            </PretFormDiv>
          </PretCard>
          <br />
          {valid().all &&
            inputPretPasse.computedPret &&
            inputPretPasse.yearlyPaymentNb !== undefined &&
            inputPretPasse.computedPret.tableauAmortissement.length >= 0 && (
              <PretSynthese pret={inputPretPasse} />
            )}
          <br />
          {valid().all &&
            inputPretPasse.computedPret &&
            inputPretPasse.computedPret.tableauAmortissement.length > 0 && (
              <PlanAmortissementTable pret={inputPretPasse} />
            )}
        </PretPasseContext.Provider>
      )}
    </div>
  )
}
