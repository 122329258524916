import React, {
  createContext,
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { NoteContext } from '../../NotePage'
import { updateInternalPeriods, updateInternalSituationIntermediaire } from '../Financiary'
import { useGetPeriods, useUpdatePeriods } from '../FinanciaryQueries'
import { useDebounce } from 'react-use'
import { PeriodModel } from '../PeriodModel'
import {
  Bilan,
  BilanCedantInput,
  DettesFiscalesSocialesWithDetail,
  FinancialPeriodsSaveRequestMultipleInput,
  NoteType,
  NumericData,
} from '@fa-metier/types'
import { removeTypeName } from '@fa-metier/commons'
import { PeriodsContext } from '../Utils/PeriodsContext'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { AppMetierTable } from '@fa-metier/components/dist/Table/AppMetierTable'
import { Dots, VerticalLine } from '../FinancialTable'
import { CalculatedCellWithCalculDetail, PeriodFinanciaryCell } from '../Utils/FinanciaryLine'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { AppMetierSelect } from '../../../utils/AppMetierSelect'
import { modelToSelectItem, saveShortcutListener, withFieldsGuard } from '../../../utils/utils'
import produce from 'immer'
import {
  CommentContainer,
  CommentTitle,
  Editor,
  makeEmptyOutputData,
  makeOutputData,
  NumberText,
} from '@fa-metier/components'
import { NavLinkGreen } from '../CR/CRForm'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { useSessionState } from '../../../utils/SessionStorageHook'
import { Button, Dialog, Icon } from '@blueprintjs/core'
import styled from 'styled-components'
import { Tooltip2 } from '@blueprintjs/popover2'
import { DIALOG_BODY, DIALOG_FOOTER } from '@blueprintjs/core/lib/esm/common/classes'
import { useUpdateNote } from '../NoteQueries'
import { DEBOUNCE_CONFIG } from '../../../Settings/debounceConfig'
import { PageSaveButton } from '../../../utils/PageSaveButton'
import _ from 'lodash'
import PageTitle from '../../../utils/PageTitle'
import { ParametersDiv } from '../../../utils/ParametersDiv'
import { useUserDebounce } from '../../../Settings/Hooks'

const CreateRemoveDetails = styled.span`
  margin-right: 0.5em;
  color: var(--main-green-1);

  :hover {
    cursor: pointer;
  }

  width: fit-content;
  text-align: right;
`

const InlinedIcon = styled(Icon)`
  margin-left: 5px;
`

const AppMetierTableBilan = styled(AppMetierTable)`
  th:first-child {
    width: 425px !important;
    font-size: 14px;
  }

  button {
    width: unset;
  }
`

const periodToMutationModel = (period: PeriodModel): FinancialPeriodsSaveRequestMultipleInput => {
  return {
    financialPeriodId: period.id,
    input: {
      bilanCedant: period.bilanCedant ? bilanToInput(period.bilanCedant) : undefined,
    },
  }
}

export function bilanToInput(bilanCedant: Bilan): BilanCedantInput {
  return removeTypeName({
    immobilisationsIncorporelles: bilanCedant.immobilisationsIncorporelles,
    immobilisationsCorporelles: bilanCedant.immobilisationsCorporelles,
    immobilisationsFinancieres: bilanCedant.immobilisationsFinancieres,
    stocksEtEncours: bilanCedant.stocksEtEncours,
    avancesAccomptesFournisseurs: bilanCedant.avancesAccomptesFournisseurs,
    creancesClients: bilanCedant.creancesClients,
    subventionsARecevoir: bilanCedant.subventionsARecevoir,
    autresCreances: bilanCedant.autresCreances,
    disponibilitesEtVMP: bilanCedant.disponibilitesEtVMP,
    chargesConstatees: bilanCedant.chargesConstatees,
    capitalSocial: bilanCedant.capitalSocial,
    subventionsInvestissement: bilanCedant.subventionsInvestissement,
    reserves: bilanCedant.reserves,
    reportANouveau: bilanCedant.reportANouveau,
    resultat: bilanCedant.resultat,
    autresFondsPropres: bilanCedant.autresFondsPropres,
    provisionsRisques: bilanCedant.provisionsRisques,
    dettesFinanciereMoyenLongTerme: bilanCedant.dettesFinanciereMoyenLongTerme,
    dettesFinanciereCourtTerme: bilanCedant.dettesFinanciereCourtTerme,
    avancesAccomptesClients: bilanCedant.avancesAccomptesClients,
    dettesFournisseurs: bilanCedant.dettesFournisseurs,
    dettesFiscalesSociales: bilanCedant.dettesFiscalesSociales,
    dettesFiscalesSocialesWithDetail: dettesFiscalesToInput(
      bilanCedant.dettesFiscalesSocialesWithDetail
    ),
    autresDettes: bilanCedant.autresDettes,
    produitsConstates: bilanCedant.produitsConstates,
  })
}

const dettesFiscalesToInput = (d: DettesFiscalesSocialesWithDetail) => {
  return {
    dettesCotisationsSociales: d.dettesCotisationsSociales,
    dettesFiscalesIsDiverses: d.dettesFiscalesIsDiverses,
    dettesFiscalesTva: d.dettesFiscalesTva,
    dettesSalaires: d.dettesSalaires,
    total: d.total,
  }
}

interface BilanContextI {
  anterieurPeriods: PeriodModel[]
  situationIntermediaire?: PeriodModel
}

export const BilanContext = createContext<BilanContextI>({} as BilanContextI)

export const BilanFormWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)
  if (periodLoading) {
    return <YodaSpinnerPage />
  }
  return (
    <BilanContext.Provider
      value={{
        anterieurPeriods: periodsData.anterieurs,
        situationIntermediaire: periodsData.situationIntermediaire ?? undefined,
      }}
    >
      <BilanForm />
    </BilanContext.Provider>
  )
}

const BilanForm: FC = () => {
  const { note, noteId, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const { anterieurPeriods, situationIntermediaire } = useContext(BilanContext)
  const [internalPeriods, setInternalPeriods] = useState(anterieurPeriods)
  const [internalSituationIntermediaire, setInternalSituationIntermediaire] =
    useState(situationIntermediaire)
  const updatePeriods = useUpdatePeriods(noteId)
  const [showComputations, setShowComputations] = useState(false)
  const updateNote = useUpdateNote(noteId)
  const [commentBilanPasse, setCommentBilanPasse] = useState(makeOutputData(note.commentBilanPasse))
  const [computationInProgress, setComputationInProgress] = useState(false)

  const refs = useRef({
    anterieurPeriods,
    situationIntermediaire,
    internalPeriods,
    internalSituationIntermediaire,
    unsavedChanges,
  })

  useDebounce(
    async () => {
      if (commentBilanPasse !== note.commentBilanPasse) {
        await withFieldsGuard(
          () => updateNote({ noteId, commentBilanPasse }),
          setComputationInProgress
        )
      }
      setUnsavedChanges(false)
    },
    DEBOUNCE_CONFIG.delay.updateRequest,
    [commentBilanPasse]
  )

  const getPeriodUpdates = () => {
    const updates = refs.current.internalPeriods
      .filter(
        (p, index) => !_.isEqual(p.bilanCedant, refs.current.anterieurPeriods[index].bilanCedant)
      )
      .map(periodToMutationModel)
    if (
      !_.isEqual(
        refs.current.situationIntermediaire?.bilanCedant,
        refs.current.internalSituationIntermediaire?.bilanCedant
      )
    ) {
      updates.push(periodToMutationModel(refs.current.internalSituationIntermediaire!))
    }
    return updates
  }

  const userDebounce = useUserDebounce()

  const [, cancelDebounceUpdatePeriods] = useDebounce(
    async () => {
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await withFieldsGuard(() => updatePeriods({ updates }), setComputationInProgress)
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [internalPeriods, internalSituationIntermediaire]
  )

  useEffect(() => {
    refs.current.internalPeriods = internalPeriods
    refs.current.internalSituationIntermediaire = internalSituationIntermediaire
    const updates = getPeriodUpdates()
    if (updates.length > 0) {
      setUnsavedChanges(true)
    }
  }, [internalPeriods, internalSituationIntermediaire, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.anterieurPeriods, anterieurPeriods)) {
      refs.current.anterieurPeriods = anterieurPeriods
      setInternalPeriods(anterieurPeriods)
      cancelDebounceUpdatePeriods()
    }

    if (!_.isEqual(refs.current.situationIntermediaire, situationIntermediaire)) {
      refs.current.situationIntermediaire = situationIntermediaire
      setInternalSituationIntermediaire(situationIntermediaire)
      cancelDebounceUpdatePeriods()
    }
  }, [anterieurPeriods, situationIntermediaire, cancelDebounceUpdatePeriods])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelDebounceUpdatePeriods()
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await updatePeriods({ updates })
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    bilan: { pageTitle },
  } = useDictionnary()

  return (
    <div style={{ paddingBottom: '3%' }}>
      <PageTitle>{pageTitle}</PageTitle>
      {anterieurPeriods && anterieurPeriods.length > 0 ? (
        <PeriodsContext.Provider
          value={{
            showComputations,
            anterieurPeriods,
            situationIntermediaire,
            periods: [],
            updatePeriods: () => {},
            resetDebounceTimers: cancelDebounceUpdatePeriods,
            updateAnterieurPeriods: updateInternalPeriods(setInternalPeriods),
            updateSituationIntermediaire: internalSituationIntermediaire
              ? updateInternalSituationIntermediaire(setInternalSituationIntermediaire)
              : undefined,
          }}
        >
          <div style={{ width: 'fit-content', marginBottom: '100px' }}>
            <ParametersDiv>
              <PageSaveButton
                buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
                buttonDisabled={computationInProgress || !unsavedChanges}
              />
              <div style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>
                <div>
                  <AppMetierSwitch
                    label={'Voir tous les calculs'}
                    checked={showComputations}
                    onChange={() => {
                      setShowComputations(!showComputations)
                    }}
                  />
                </div>
              </div>
            </ParametersDiv>

            <AppMetierTableBilan>
              <BilanTitleLine title={'Actif'} />
              <tbody>
                <BlankTableLine />
                <BilanLine
                  fieldName={'bilanCedant.immobilisationsIncorporelles'}
                  label={'Immobilisations incorporelles'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.immobilisationsCorporelles'}
                  label={'Immobilisations corporelles'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.immobilisationsFinancieres'}
                  label={'Immobilisations financières'}
                  isCalculate={computationInProgress}
                />
                <BilanCalculatedLineWithDetail
                  label={'Total actif immobilisé'}
                  fieldName={'bilanCedant.totalActifImmobilise'}
                />
                <BilanLine
                  fieldName={'bilanCedant.stocksEtEncours'}
                  label={'Stocks et encours'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.avancesAccomptesFournisseurs'}
                  label={'Avances et acomptes fournisseurs'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.creancesClients'}
                  label={'Créances clients'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.subventionsARecevoir'}
                  label={'Subventions à recevoir'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.autresCreances'}
                  label={'Autres créances'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.disponibilitesEtVMP'}
                  label={'Disponibilités et VMP'}
                  isCalculate={computationInProgress}
                />
                <BilanCalculatedLineWithDetail
                  label={'Total actif circulant'}
                  fieldName={'bilanCedant.totalActifCirculant'}
                />
                <BilanLine
                  fieldName={'bilanCedant.chargesConstatees'}
                  label={"Charges constatées d'avance et à répartir"}
                  isCalculate={computationInProgress}
                />
                <BilanCalculatedLineWithDetail
                  label={'Total actif'}
                  fieldName={'bilanCedant.totalActif'}
                  isResult={true}
                />
              </tbody>
            </AppMetierTableBilan>

            <AppMetierTableBilan style={{ marginTop: '40px', marginBottom: '40px' }}>
              <BilanTitleLine title={'Passif'} />
              <tbody>
                <BlankTableLine />
                <BilanCapitalLine
                  setInternalPeriods={setInternalPeriods}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.subventionsInvestissement'}
                  label={"Subventions d'investissement"}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.reserves'}
                  label={'Réserves'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.reportANouveau'}
                  label={'Report à nouveau'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.resultat'}
                  label={'Résultat'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.autresFondsPropres'}
                  label={'Autres fonds propres'}
                  isCalculate={computationInProgress}
                />
                <BilanCalculatedLineWithDetail
                  label={'Total fonds propres'}
                  fieldName={'bilanCedant.totalFondsPropres'}
                />
                <BilanLine
                  fieldName={'bilanCedant.provisionsRisques'}
                  label={'Provisions pour risques et charges'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.dettesFinanciereMoyenLongTerme'}
                  label={'Dettes financières moyen/long terme'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.dettesFinanciereCourtTerme'}
                  label={'Dettes financières court terme'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.avancesAccomptesClients'}
                  label={'Avances et acomptes clients'}
                  isCalculate={computationInProgress}
                />
                <BilanLine
                  fieldName={'bilanCedant.dettesFournisseurs'}
                  label={'Dettes fournisseurs'}
                  isCalculate={computationInProgress}
                />
                {note.type !== NoteType.StructureExistante && (
                  <BilanLine
                    fieldName={'bilanCedant.dettesFiscalesSociales'}
                    label={'Dettes fiscales et sociales'}
                    isCalculate={computationInProgress}
                  />
                )}
                {note.type === NoteType.StructureExistante && (
                  <BilanDettesFiscalesBlock isCalculate={computationInProgress} />
                )}
                <BilanLine
                  fieldName={'bilanCedant.autresDettes'}
                  label={'Autres dettes'}
                  isCalculate={computationInProgress}
                />
                <BilanCalculatedLineWithDetail
                  label={'Total dettes circulantes'}
                  fieldName={'bilanCedant.totalDettesCirculantes'}
                />
                <BilanLine
                  fieldName={'bilanCedant.produitsConstates'}
                  label={"Produits constatés d'avance et fonds dédiés"}
                  isCalculate={computationInProgress}
                />
                <BilanCalculatedLineWithDetail
                  label={'Total passif'}
                  fieldName={'bilanCedant.totalPassif'}
                  isResult={true}
                />
              </tbody>
            </AppMetierTableBilan>
            <DifferenceActifPassif />
            <IndicateursFinanciers />
            {note.type === NoteType.StructureExistante && (
              <CommentContainer>
                <CommentTitle>
                  <span>Commentaire</span>
                </CommentTitle>
                <Editor
                  id={'bilanComment'}
                  placeHolder={'Vous pouvez saisir un commentaire'}
                  initialValue={commentBilanPasse ?? makeEmptyOutputData}
                  onChange={(v) => setCommentBilanPasse(v)}
                />
              </CommentContainer>
            )}
          </div>
        </PeriodsContext.Provider>
      ) : (
        <div>
          Vous devez saisir au moins un exercice comptable du cédant dans l’onglet{' '}
          <NavLinkGreen to={`settings`}>Paramètres.</NavLinkGreen>
        </div>
      )}
    </div>
  )
}

const BilanTitleLine: FC<{ title?: string }> = ({ title }) => {
  const { anterieurPeriods, situationIntermediaire } = useContext(PeriodsContext)
  const className = title ? 'transparent title' : ''
  return (
    <thead>
      <tr>
        <th className={className}>{title}</th>
        {anterieurPeriods.map((period, i) => (
          <th key={i}>{period.endDate.format('DD/MM/YYYY')}</th>
        ))}
        {situationIntermediaire && (
          <th key={Math.random()} className={'intermediaire'}>
            {`Situation au ${situationIntermediaire.endDate.format('DD/MM/YYYY')}`}
          </th>
        )}
        <th className={'action'} />
      </tr>
    </thead>
  )
}

const BilanLine: FC<{
  fieldName: string
  label: string
  isCalculate?: boolean
  isDetail?: boolean
  isEuro?: boolean
}> = ({ fieldName, label, isCalculate = false, isDetail = false, isEuro = true }) => {
  const {
    anterieurPeriods,
    showComputations,
    updateAnterieurPeriods,
    updateSituationIntermediaire,
    situationIntermediaire,
  } = useContext(PeriodsContext)

  const updateAnterieurField = (periodId: string, n?: NumericData) => {
    if (updateAnterieurPeriods) {
      updateAnterieurPeriods!!([{ periodId, fieldPath: fieldName, change: n }])
    }
  }

  const updateSituationIntermediaireField = (periodId: string, n?: NumericData) => {
    if (situationIntermediaire) {
      updateSituationIntermediaire!!([{ periodId, fieldPath: fieldName, change: n }])
    }
  }

  return (
    <tr className={'hoverable'}>
      <th
        className={isDetail ? 'detail' : ''}
        style={{ display: 'flex', alignItems: 'baseline', fontWeight: 'normal' }}
      >
        {isDetail && <VerticalLine />}
        <div style={{ marginLeft: isDetail ? '40px' : '20px' }}>{label}</div>
        <Dots />
      </th>
      {anterieurPeriods.map((p) => {
        return (
          <PeriodFinanciaryCell
            key={`${p.id}_${fieldName}`}
            fieldPath={fieldName}
            period={p}
            isEuro={isEuro}
            isCalculate={isCalculate}
            showComputations={showComputations}
            updateField={updateAnterieurField}
          />
        )
      })}
      {situationIntermediaire && (
        <PeriodFinanciaryCell
          key={`${situationIntermediaire.id}_${fieldName}`}
          fieldPath={fieldName}
          period={situationIntermediaire}
          isEuro={isEuro}
          isCalculate={isCalculate}
          showComputations={showComputations}
          updateField={updateSituationIntermediaireField}
        />
      )}
    </tr>
  )
}

const BilanDettesFiscalesBlock: FC<{ isCalculate?: boolean }> = ({ isCalculate = false }) => {
  const { noteSettings } = useContext(NoteContext)
  const {
    anterieurPeriods,
    situationIntermediaire,
    updateSituationIntermediaire,
    updateAnterieurPeriods,
  } = useContext(PeriodsContext)
  const periodHasDettesDetail = (p?: PeriodModel) =>
    p?.bilanCedant?.dettesFiscalesSocialesWithDetail?.hasDetail ?? false
  const [hasDetail, setHasDetail] = useState(
    anterieurPeriods.some(periodHasDettesDetail) || periodHasDettesDetail(situationIntermediaire)
  )
  const [showDetail, setShowDetail] = useSessionState('bilan_dettes_details', false)
  const [showPopupRemoveDetails, setShowPopupRemoveDetails] = useState(false)

  const toggleDetailDisplay = () => {
    setShowDetail(!showDetail)
  }

  const removeDettesDetailsFromPeriod = (p: PeriodModel) => {
    const emptyNumericData = { value: null, detail: null, kiloEuros: noteSettings.kiloEuros }
    return {
      details: produce(p, (draft) => {
        draft.bilanCedant!!.dettesFiscalesSocialesWithDetail = {
          dettesCotisationsSociales: emptyNumericData,
          dettesFiscalesIsDiverses: emptyNumericData,
          dettesFiscalesTva: emptyNumericData,
          dettesSalaires: emptyNumericData,
          total: draft.bilanCedant!!.dettesFiscalesSocialesWithDetail.total,
          hasDetail: false,
        }
      })?.bilanCedant?.dettesFiscalesSocialesWithDetail,
      periodId: p.id,
    }
  }

  const removeDetail = () => {
    const fieldPath = 'bilanCedant.dettesFiscalesSocialesWithDetail'
    updateAnterieurPeriods!!(
      anterieurPeriods.map(removeDettesDetailsFromPeriod).map((change) => {
        return {
          fieldPath,
          periodId: change!!.periodId,
          change: change!!.details,
        }
      })
    )
    if (situationIntermediaire) {
      const update = removeDettesDetailsFromPeriod(situationIntermediaire)
      updateSituationIntermediaire!!([
        {
          fieldPath,
          periodId: update!!.periodId,
          change: update!!.details,
        },
      ])
    }
    setHasDetail(false)
    setShowDetail(false)
  }

  const toggleDetail = () => {
    if (hasDetail) {
      if (showPopupRemoveDetails) {
        removeDetail()
        setShowPopupRemoveDetails(false)
      } else {
        setShowPopupRemoveDetails(true)
      }
    } else {
      setHasDetail(true)
      setShowDetail(true)
    }
  }

  return (
    <>
      <BilanDettesDetailsTotalLine
        label={'Dettes fiscales et sociales'}
        hasDetail={hasDetail}
        toggleDetailDisplay={toggleDetailDisplay}
        detailDisplay={showDetail}
        toggleDetail={toggleDetail}
        isCalculate={isCalculate}
      />
      {showDetail && hasDetail && (
        <>
          <BilanLine
            fieldName={'bilanCedant.dettesFiscalesSocialesWithDetail.dettesSalaires'}
            label={'Dettes sur salaires'}
            isDetail={true}
            isCalculate={isCalculate}
          />
          <BilanLine
            fieldName={'bilanCedant.dettesFiscalesSocialesWithDetail.dettesCotisationsSociales'}
            label={'Dettes sur cotisations sociales'}
            isDetail={true}
            isCalculate={isCalculate}
          />
          <BilanLine
            fieldName={'bilanCedant.dettesFiscalesSocialesWithDetail.dettesFiscalesTva'}
            label={'Dettes fiscales TVA'}
            isDetail={true}
            isCalculate={isCalculate}
          />
          <BilanLine
            fieldName={'bilanCedant.dettesFiscalesSocialesWithDetail.dettesFiscalesIsDiverses'}
            label={'Dettes fiscales IS et diverses'}
            isDetail={true}
            isCalculate={isCalculate}
          />
        </>
      )}
      <Dialog isOpen={showPopupRemoveDetails} title={'Attention'} isCloseButtonShown={false}>
        <div className={DIALOG_BODY}>
          <div>Etes-vous sûr de vouloir supprimer le détail des Dettes fiscales et sociales ?</div>
          <div>Cette action ne peut pas être inversée.</div>
        </div>
        <div className={DIALOG_FOOTER}>
          <Button style={{ marginRight: '0.5em' }} onClick={() => setShowPopupRemoveDetails(false)}>
            Annuler
          </Button>
          <Button intent={'primary'} onClick={toggleDetail}>
            Valider
          </Button>
        </div>
      </Dialog>
    </>
  )
}

const BlankTableLine: FC<{
  style?: CSSProperties
}> = ({ style }) => {
  const { anterieurPeriods, situationIntermediaire } = useContext(PeriodsContext)
  return (
    <tr className={'empty'} style={style}>
      <th />
      {anterieurPeriods.map((_, i) => (
        <td key={i} />
      ))}
      {situationIntermediaire && (
        <td key={Math.random()} className={style ? '' : 'intermediaire'} />
      )}
      <td />
    </tr>
  )
}

export const BilanCalculatedLineWithDetail: FC<{
  label: string
  fieldName: string
  isResult?: boolean
  noBackgroundColor?: boolean
  alignRight?: boolean
}> = ({ label, fieldName, isResult = false, noBackgroundColor = false, alignRight = false }) => {
  const { anterieurPeriods, situationIntermediaire } = useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)
  return (
    <>
      <tr
        className={noBackgroundColor ? '' : isResult ? 'result' : 'subtotal hoverable'}
        style={{ fontWeight: 600 }}
      >
        <th style={{ textAlign: 'start', paddingLeft: '20px' }}>{label}</th>
        {anterieurPeriods.map((p) => (
          <CalculatedCellWithCalculDetail
            key={`${fieldName}_${p.id}`}
            fieldName={fieldName}
            period={p}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
            isResult={isResult}
            alignRight={alignRight}
          />
        ))}
        {situationIntermediaire && (
          <CalculatedCellWithCalculDetail
            className={noBackgroundColor ? 'intermediaire' : ''}
            key={`${fieldName}_${situationIntermediaire.id}`}
            fieldName={fieldName}
            period={situationIntermediaire}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
            isResult={isResult}
            alignRight={alignRight}
          />
        )}
      </tr>
      <BlankTableLine />
    </>
  )
}

const DifferenceActifPassif: FC = () => {
  const { anterieurPeriods, situationIntermediaire } = useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)

  const diffSituationIntermediaire =
    (situationIntermediaire?.bilanCedant?.totalActif.numericData.value ?? 0.0) -
    (situationIntermediaire?.bilanCedant?.totalPassif.numericData.value ?? 0.0)
  return (
    <div>
      <div
        style={{
          padding: '0',
          textAlign: 'start',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '22px',
        }}
      >
        Différence Actif / Passif
      </div>
      <AppMetierTableBilan style={{ marginTop: '20px', marginBottom: '40px' }}>
        <BilanTitleLine />
        <tbody>
          <BilanDiffCalculatedLineWithDetail
            label={'Total actif'}
            fieldName={'bilanCedant.totalActif'}
          />
          <BilanDiffCalculatedLineWithDetail
            label={'Total passif'}
            fieldName={'bilanCedant.totalPassif'}
          />
          <tr
            style={{
              background: 'linear-gradient(var(--main-blue-2),var(--main-blue-2)) no-repeat 20px 0',
            }}
          >
            <th style={{ textAlign: 'start', paddingLeft: '40px' }}>Différence</th>
            {anterieurPeriods.map((period, i) => {
              const diff =
                (period.bilanCedant?.totalActif.numericData.value ?? 0.0) -
                (period.bilanCedant?.totalPassif.numericData.value ?? 0.0)
              return (
                <td
                  style={{
                    textAlign: 'end',
                    color: diff < 0 || diff > 0 ? 'red' : 'var(--main-black-1)',
                    boxShadow: 'none',
                    fontWeight: 600,
                    paddingRight: '40px',
                  }}
                  key={`diff_${i}`}
                >
                  <NumberText value={diff} suffix={eurosSuffix(noteSettings.kiloEuros)} />
                </td>
              )
            })}
            {situationIntermediaire && (
              <td
                style={{
                  textAlign: 'end',
                  color:
                    diffSituationIntermediaire < 0 || diffSituationIntermediaire > 0
                      ? 'red'
                      : 'var(--main-black-1)',
                  boxShadow: 'none',
                  fontWeight: 600,
                  paddingRight: '40px',
                }}
                key={`diff_${situationIntermediaire.id}`}
              >
                <NumberText
                  value={diffSituationIntermediaire}
                  suffix={eurosSuffix(noteSettings.kiloEuros)}
                />
              </td>
            )}
          </tr>
          <BlankTableLine />
        </tbody>
      </AppMetierTableBilan>
    </div>
  )
}

const BilanDettesDetailsTotalLine: FC<{
  label: string
  hasDetail: boolean
  toggleDetailDisplay: () => void
  detailDisplay: boolean
  toggleDetail: () => void
  isCalculate?: boolean
}> = ({
  label,
  hasDetail,
  toggleDetail,
  toggleDetailDisplay,
  detailDisplay,
  isCalculate = false,
}) => {
  const {
    anterieurPeriods,
    situationIntermediaire,
    showComputations,
    updateAnterieurPeriods,
    updateSituationIntermediaire,
  } = useContext(PeriodsContext)
  const updateAnterieurField = (periodId: string, n?: NumericData) => {
    if (updateAnterieurPeriods) {
      updateAnterieurPeriods!!([
        {
          periodId,
          fieldPath: 'bilanCedant.dettesFiscalesSocialesWithDetail.total',
          change: n,
        },
      ])
    }
  }

  const updateSituationIntermediaireField = (periodId: string, n?: NumericData) => {
    if (situationIntermediaire) {
      updateSituationIntermediaire!!([
        {
          periodId,
          fieldPath: 'bilanCedant.dettesFiscalesSocialesWithDetail.total',
          change: n,
        },
      ])
    }
  }

  return (
    <tr className={'hoverable'}>
      <th
        style={{
          display: 'flex',
          alignItems: 'baseline',
          fontWeight: 'normal',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ marginLeft: '20px' }}>{label}</div>
        <Dots />
        <Tooltip2
          content={
            'Vous pouvez indiquer ici le détail des dettes fiscales et sociales. Cela vous aidera à comprendre le BFR passé – et donc à calculer le BFR prévisionnel.'
          }
          position={'top-left'}
          interactionKind={'hover'}
          disabled={hasDetail}
        >
          <CreateRemoveDetails onClick={toggleDetail}>
            <u style={hasDetail ? { marginRight: '20px' } : {}}>
              {hasDetail ? 'Supprimer le détail' : 'Saisir le détail'}
            </u>
            {!hasDetail && <InlinedIcon icon={'add'} />}
          </CreateRemoveDetails>
        </Tooltip2>
        {hasDetail && (
          <Button
            style={{ alignSelf: 'center' }}
            icon={detailDisplay ? 'chevron-up' : 'chevron-down'}
            minimal={true}
            onClick={toggleDetailDisplay}
          />
        )}
      </th>
      {anterieurPeriods.map((p) => (
        <PeriodFinanciaryCell
          key={`${p.id}_dettes_total`}
          fieldPath={'bilanCedant.dettesFiscalesSocialesWithDetail.total'}
          period={p}
          isEuro={true}
          isCalculate={hasDetail || isCalculate}
          showComputations={showComputations}
          updateField={updateAnterieurField}
        />
      ))}
      {situationIntermediaire && (
        <PeriodFinanciaryCell
          key={`${situationIntermediaire.id}_dettes_total`}
          fieldPath={'bilanCedant.dettesFiscalesSocialesWithDetail.total'}
          period={situationIntermediaire}
          isEuro={true}
          isCalculate={hasDetail || isCalculate}
          showComputations={showComputations}
          updateField={updateSituationIntermediaireField}
        />
      )}
    </tr>
  )
}

const BilanCapitalLine: FC<{
  setInternalPeriods: Dispatch<SetStateAction<PeriodModel[]>>
  isCalculate?: boolean
}> = ({ setInternalPeriods, isCalculate = false }) => {
  const {
    anterieurPeriods,
    showComputations,
    updateAnterieurPeriods,
    situationIntermediaire,
    updateSituationIntermediaire,
  } = useContext(PeriodsContext)

  const fieldName = 'bilanCedant.capitalSocial.numericData'
  const capitalSocialLabelsKeys = ['CAPITAL_SOCIAL', 'FONDS_ASSOCIATIFS', 'APPORTS']

  const updateAnterieurField = (periodId: string, n?: NumericData) => {
    if (updateAnterieurPeriods) {
      updateAnterieurPeriods([{ periodId, fieldPath: fieldName, change: n }])
    }
  }

  const updateIntermediaireField = (periodId: string, n?: NumericData) => {
    if (updateSituationIntermediaire) {
      updateSituationIntermediaire([{ periodId, fieldPath: fieldName, change: n }])
    }
  }

  const updateCapitalSocialLabel = (label: string) => {
    if (anterieurPeriods && anterieurPeriods.length > 0) {
      setInternalPeriods(
        anterieurPeriods.map((p) =>
          p.bilanCedant
            ? {
                ...p,
                bilanCedant: {
                  ...p.bilanCedant,
                  capitalSocial: { ...p.bilanCedant.capitalSocial, chosenLabel: label },
                },
              }
            : p
        )
      )
    }
  }

  const chosenLabel =
    anterieurPeriods[0]?.bilanCedant?.capitalSocial?.chosenLabel ?? 'CAPITAL_SOCIAL'

  const [selectedItem, setSelectedItem] = useState(modelToSelectItem(chosenLabel, ' '))
  const items = capitalSocialLabelsKeys.map((v) => modelToSelectItem(v, ' '))

  return (
    <tr className={'hoverable'}>
      <th style={{ display: 'flex', alignItems: 'baseline', fontWeight: 'normal' }}>
        <div style={{ marginLeft: '20px' }}>
          <AppMetierSelect
            onChange={(event) => {
              if (anterieurPeriods && anterieurPeriods.length > 0) {
                updateCapitalSocialLabel(event.id)
                setSelectedItem(event)
              }
            }}
            items={items}
            value={selectedItem}
            disabled={isCalculate}
          />
        </div>
      </th>
      {anterieurPeriods.map((p) => {
        return (
          <PeriodFinanciaryCell
            key={`PeriodFinanciaryCell_${fieldName}_${p.id}`}
            fieldPath={fieldName}
            period={p}
            isEuro={true}
            isCalculate={isCalculate}
            showComputations={showComputations}
            updateField={updateAnterieurField}
          />
        )
      })}
      {situationIntermediaire && (
        <PeriodFinanciaryCell
          key={`PeriodFinanciaryCell_${fieldName}_${situationIntermediaire.id}`}
          fieldPath={fieldName}
          period={situationIntermediaire}
          isEuro={true}
          isCalculate={isCalculate}
          showComputations={showComputations}
          updateField={updateIntermediaireField}
        />
      )}
    </tr>
  )
}

const IndicateursFinanciers: FC = () => {
  const { anterieurPeriods, situationIntermediaire } = useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)
  const showEstimation =
    anterieurPeriods &&
    anterieurPeriods.length > 0 &&
    anterieurPeriods[0].bilanCedant &&
    anterieurPeriods[0].bilanCedant.capitalSocial.chosenLabel !== 'FONDS_ASSOCIATIFS'

  const estimationLabel = showEstimation
    ? anterieurPeriods!![0].bilanCedant!!.capitalSocial.chosenLabel === 'CAPITAL_SOCIAL'
      ? 'Estimation dividende distribué'
      : 'Estimation prélèvement'
    : ''

  return (
    <AppMetierTableBilan>
      <BilanTitleLine title={'Indicateurs financiers'} />
      <tbody>
        <BlankTableLine />
        <BilanCalculatedLineWithDetail
          label={'Fonds de roulement'}
          isResult={false}
          fieldName={'bilanCedant.fondsRoulement'}
          noBackgroundColor={true}
          alignRight={true}
        />
        <BilanCalculatedLineWithDetail
          label={'BFR'}
          isResult={false}
          fieldName={'bilanCedant.bfr'}
          noBackgroundColor={true}
          alignRight={true}
        />
        <BilanCalculatedLineWithDetail
          label={'Trésorerie nette'}
          isResult={false}
          noBackgroundColor={true}
          fieldName={'bilanCedant.tresorerieNette'}
          alignRight={true}
        />
        {showEstimation && (
          <>
            <BlankTableLine style={{ backgroundColor: 'aliceblue' }} />
            <BlankTableLine />
            <tr style={{ fontWeight: 600 }}>
              <th style={{ textAlign: 'start', paddingLeft: '20px' }}>{estimationLabel}</th>
              {anterieurPeriods.map((p, index) => {
                if (index > 0) {
                  const fieldName = 'bilanCedant.estimationDividendes'
                  return (
                    <CalculatedCellWithCalculDetail
                      key={`${fieldName}_${p.id}`}
                      fieldName={fieldName}
                      period={p}
                      suffix={eurosSuffix(noteSettings.kiloEuros)}
                      isResult={false}
                      alignRight={true}
                      showDescription={(value: number) => value <= 0}
                      optionalDescription={
                        'Le montant des dividendes est égal à 0 si le résultat du calcul ci-dessus est négatif'
                      }
                    />
                  )
                }

                return (
                  <td key={`calculateField-${p.id}-${Math.random()}`}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <span style={{ paddingLeft: '10px', paddingRight: '30px', fontWeight: 600 }}>
                        -
                      </span>
                    </div>
                  </td>
                )
              })}
              {situationIntermediaire && (
                <td
                  key={`calculateField-${situationIntermediaire.id}-${Math.random()}`}
                  className={'intermediaire'}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span style={{ paddingLeft: '10px', paddingRight: '30px', fontWeight: 600 }}>
                      -
                    </span>
                  </div>
                </td>
              )}
            </tr>
            <BlankTableLine />
          </>
        )}
      </tbody>
    </AppMetierTableBilan>
  )
}

export const BilanDiffCalculatedLineWithDetail: FC<{
  label: string
  fieldName: string
}> = ({ label, fieldName }) => {
  const { anterieurPeriods, situationIntermediaire } = useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)
  return (
    <>
      <tr
        style={{
          fontWeight: 600,
          background: 'linear-gradient(var(--main-blue-1),var(--main-blue-1)) no-repeat 40px 0',
          color: 'white',
        }}
      >
        <th style={{ textAlign: 'start', paddingLeft: '50px', color: 'white' }}>{label}</th>
        {anterieurPeriods.map((p) => (
          <CalculatedCellWithCalculDetail
            key={`${fieldName}_${p.id}`}
            fieldName={fieldName}
            period={p}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
            isResult={true}
            alignRight={true}
          />
        ))}
        {situationIntermediaire && (
          <CalculatedCellWithCalculDetail
            key={`${fieldName}_${situationIntermediaire.id}`}
            fieldName={fieldName}
            period={situationIntermediaire}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
            isResult={true}
            alignRight={true}
          />
        )}
      </tr>
      <BlankTableLine />
    </>
  )
}
