import React, {FC, ReactNode, useContext, useState} from 'react'
import {FinanciaryContext} from '../Financiary'
import {PeriodModel} from '../PeriodModel'
import {NumericData} from '@fa-metier/types'
import produce from 'immer'
import {Button, Classes, Dialog, Intent, Switch} from '@blueprintjs/core'
import {NumericDataInput} from '../Utils/NumericDataInput/NumericDataInput'
import {FlexDiv, PlanFinancementContext} from './PlanFinancement'
import styled from 'styled-components'
import {NoteContext} from "../../NotePage";

export interface LinePropsExtended {
  title: string
  switchLabel?: string
  dialogTitle: string
  dialogBody: string | ReactNode
  dialogAction: () => {}
  defaultSwitchValue: boolean
}

const SwitchContainer = styled.div`
  > .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: var(--main-green-1);
  }

  font-weight: normal;
`

export const PrelevementTnsLine: FC<{
  prelevementTnsInCr: boolean | undefined | null
  togglePrelevementTns: () => void
}> = ({prelevementTnsInCr, togglePrelevementTns}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const {noteSettings} = useContext(NoteContext)
  const {previsionelPeriods} = useContext(FinanciaryContext)

  const dialogTextNotInCr = (
    <>
      <p>
        Le prélèvement du TNS ne sera plus pris en compte dans le CR prévisionnel et ne sera plus
        intégré dans les calculs intermédiaires, y compris la CAF.
      </p>
      <p>
        Il sera intégré comme un besoin dans le <b>plan de financement</b> pour que le solde reste
        juste. Vous pouvez toujours modifier le montant.
      </p>

      <p>
        Cette opération est réversible : vous pourrez le réintégrer dans le CR prévisionnel à tout
        moment.
      </p>
    </>
  )

  const dialogTextInCr = (
    <>
      <p>Le prélèvement du TNS ne sera plus intégré comme un besoin du plan de financement.</p>
      <p>
        Il sera remis dans le <b>CR prévisionnel</b> et réintégré dans les calculs intermédiaires, y
        compris la CAF. Vous pouvez toujours modifier le montant.
      </p>

      <p>
        Cette opération est réversible : vous pourrez le réintégrer dans le plan de financement à
        tout moment.
      </p>
    </>
  )

  if (prelevementTnsInCr !== false) {
    return (
      <>
        <tr style={{height: '40px'}}>
          <td
            style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            className={'title'}
          >
            <div>{'Prélèvement TNS'}</div>
            <SwitchContainer>
              <Switch
                label={'Dans le PF'}
                checked={false}
                onChange={() => setOpenDialog(true)}
                readOnly={true}
              />
            </SwitchContainer>
            <Dialog isOpen={openDialog} title={'Attention'} isCloseButtonShown={false}>
              <div className={Classes.DIALOG_BODY}>
                <div>{dialogTextNotInCr}</div>
              </div>
              <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER}>
                  <Button style={{marginRight: '0.5em'}} onClick={() => setOpenDialog(false)}>
                    Annuler
                  </Button>
                  <Button
                    intent={Intent.PRIMARY}
                    onClick={() => {
                      togglePrelevementTns()
                      setOpenDialog(false)
                    }}
                  >
                    Confirmer
                  </Button>
                </div>
              </div>
            </Dialog>
          </td>
          {noteSettings.showDemarrage && <td className={'demarrage'}/>}
          <td colSpan={previsionelPeriods.length}>
            {' '}
            <div style={{display: 'flex', flexDirection: 'row'}}>
              Le prélèvement TNS est pris en compte dans les charges d'exploitation du CR
              prévisionnel.
            </div>
          </td>
        </tr>
      </>
    )
  }

  return (
    <>
      <LineWithDialog
        title={'Prélèvement TNS'}
        switchLabel={'Dans le PF'}
        dialogTitle={'Attention'}
        dialogBody={dialogTextInCr}
        dialogAction={() => {
          togglePrelevementTns()
          return true
        }}
        defaultSwitchValue={true}
      />
    </>
  )
}

export const LineWithDialog: FC<LinePropsExtended> = ({
                                                        title,
                                                        switchLabel = 'Calcul automatique',
                                                        dialogTitle = 'Attention',
                                                        dialogBody,
                                                        dialogAction = () => {
                                                        },
                                                        defaultSwitchValue = false,
                                                      }) => {
  const {noteSettings} = useContext(NoteContext)
  const {previsionelPeriods} = useContext(FinanciaryContext)
  const {showComputations, updatePeriod} = useContext(PlanFinancementContext)

  const field = (period: PeriodModel) => period.prelevementTns as NumericData

  const updateField = (period: PeriodModel, value: NumericData) => {
    return produce(period, (draft) => {
      draft.prelevementTns = value
    })
  }
  const [open, setOpen] = React.useState(false)

  return (
    <tr className={'hoverable'} style={{height: '40px'}}>
      <td
        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
        className={'title'}
      >
        <div>{title}</div>
        <SwitchContainer>
          <Switch
            label={switchLabel}
            checked={defaultSwitchValue}
            onChange={() => setOpen(true)}
            readOnly={true}
          />
        </SwitchContainer>
        <Dialog isOpen={open} title={dialogTitle} isCloseButtonShown={false}>
          <div className={Classes.DIALOG_BODY}>
            <div>{dialogBody}</div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER}>
              <Button style={{marginRight: '0.5em'}} onClick={() => setOpen(false)}>
                Annuler
              </Button>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => {
                  dialogAction()
                  setOpen(false)
                }}
              >
                Confirmer
              </Button>
            </div>
          </div>
        </Dialog>
      </td>
      {noteSettings.showDemarrage && <td className={'demarrage'}/>}
      {previsionelPeriods.map((period, i) => (
        <td key={i}>
          <FlexDiv>
            <NumericDataInput
              numericData={field(period)}
              onChange={(value) => {
                updatePeriod(updateField(period, value))
              }}
              showComputations={showComputations}
              isCalculated={false}
              isEuro={!(field(period).kiloEuros ?? false)}
            />
          </FlexDiv>
        </td>
      ))}
      <td/>
    </tr>
  )
}
