import React, { FC, useContext, useEffect, useState } from 'react'
import { Collapse, Radio } from '@blueprintjs/core'
import { NumberText } from '@fa-metier/components'
import { TabContentTitle } from '../../../utils/PageTitle'
import { SelectTva } from './TvaSelect'
import { ExpenseTvaTable, IncomeTvaTable } from './TvaTable'
import { InlinedRadioGroup, StyledRadioGroup } from './FiscaliteForm'
import { useDebounce } from 'react-use'
import { useUpdatePeriods } from '../FinanciaryQueries'
import {
  PeriodType,
  Tva,
  TvaDetail,
  TvaDetailsInput,
  TvaInput,
  TvaPortion,
  TvaPortionInput,
} from '@fa-metier/types'
import { removeTypeName } from '@fa-metier/commons'
import { PeriodModel } from '../PeriodModel'
import styled from 'styled-components'
import { AppMetierTable, BlankTableLine } from '@fa-metier/components/dist/Table/AppMetierTable'
import { useTvaQuery } from '../../../generated/graphql'
import { NoteContext } from '../../NotePage'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { DEBOUNCE_CONFIG } from '../../../Settings/debounceConfig'

const TvaSynthesisTable = styled(AppMetierTable)`
  text-align: end;

  td.linetitle {
    font-weight: var(--bold);
    text-align: start;
  }

  th {
    padding: 20px;
    width: 320px;
    max-width: 320px;
  }

  td {
    padding: 5px 20px;
  }

  th.title {
    padding: 0;
    text-align: start;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
  }
`

const TvaBlock = styled.div`
  margin: 0 0 2em;
  display: flex;
  flex-direction: column;

  .bp3-html-select {
    width: fit-content;
    margin-top: 1em;
  }
`

const PaddingTvaBlock = styled(TvaBlock)`
  padding-left: 1em;
  margin: 0 1em 2em;
`

const GreenText = styled.span`
  color: var(--main-green-1);
  font-size: 20px;
`

const AppliedTvaBlock = styled.div`
  background-color: white;
  padding: 1em;
  width: fit-content;
  min-width: 100%;
`

const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 20px;
`

interface TvaContextI {
  tvaList: number[]
  tvaType: PeriodType
}

export const TvaContext = React.createContext<TvaContextI>({} as TvaContextI)

export const TvaFormWrapper: FC<{ periods: PeriodModel[] }> = ({ periods }) => {
  const { loading: tvaListLoading, data: tvaListData } = useTvaQuery()
  if (tvaListLoading) {
    return <YodaSpinnerPage />
  }

  const tvaType = periods.every((p) => p.type === PeriodType.Previsionnel)
    ? PeriodType.Previsionnel
    : PeriodType.Anterieur

  return (
    <TvaContext.Provider
      value={{
        tvaType,
        tvaList: tvaListData?.tva!!,
      }}
    >
      <TvaForm periods={periods} />
    </TvaContext.Provider>
  )
}

export const TvaForm: FC<{ periods: PeriodModel[] }> = ({ periods }) => {
  const { noteId, setUnsavedChanges } = useContext(NoteContext)
  const { tvaList } = useContext(TvaContext)
  const [tvaFormState, setTvaFormState] = useState(() => initTvaFormState(periods[0].tva, tvaList))
  const updatePeriods = useUpdatePeriods(noteId)

  const getPeriodUpdates = () => {
    const newTva = tvaFormStateToTvaInput(tvaFormState)

    return periods
      .filter((p) => needPeriodUpdate(newTva, p))
      .map((p) => {
        return {
          financialPeriodId: p.id,
          input: { tvaDetails: newTva },
        }
      })
  }

  useDebounce(
    async () => {
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await updatePeriods({ updates })
      }
      setUnsavedChanges(false)
    },
    DEBOUNCE_CONFIG.delay.updateRequest,
    [tvaFormState]
  )

  useEffect(() => {
    const updates = getPeriodUpdates()
    if (updates.length > 0) {
      setUnsavedChanges(true)
    }
  }, [tvaFormState, setUnsavedChanges])

  return (
    <>
      <TvaSynthesis periods={periods} />
      <div style={{ marginTop: '2em' }}>
        <Title>TVA appliquée</Title>

        <AppliedTvaBlock>
          <StyledRadioGroup
            selectedValue={tvaFormState.globalType}
            onChange={(event: any) =>
              setTvaFormState((tvaFormState) => ({
                ...tvaFormState,
                globalType: event.target.value,
              }))
            }
          >
            <Radio label={'TVA unique  '} value={'UNIQUE'} style={{ fontWeight: 'bold' }} />
            <PaddingTvaBlock>
              <span>
                <i>
                  <u>Identique</u> pour le CA et les achats et charges externes
                </i>
              </span>
              <SelectTva
                value={tvaFormState.globalTva}
                onChange={(value) => {
                  setTvaFormState((tvaFormState) => ({
                    ...tvaFormState,
                    globalTva: value,
                  }))
                }}
              />
            </PaddingTvaBlock>
            <Radio label={'TVA personnalisée'} value={'VARIABLE'} style={{ fontWeight: 'bold' }} />
            <PaddingTvaBlock>
              <span>
                <i>
                  <u>Différente</u> entre le CA et les achats et charges externes
                </i>
              </span>
            </PaddingTvaBlock>
          </StyledRadioGroup>

          <IndentedCollapse isOpen={tvaFormState.globalType === 'VARIABLE'}>
            <GreenText>Chiffre d'affaires</GreenText>
            <InlinedRadioGroup
              selectedValue={tvaFormState.variableTva.detailIncome.type}
              onChange={(event: any) =>
                setTvaFormState((tvaFormState) => ({
                  ...tvaFormState,
                  variableTva: {
                    ...tvaFormState.variableTva,
                    detailIncome: {
                      ...tvaFormState.variableTva.detailIncome,
                      type: event.target.value,
                    },
                  },
                }))
              }
            >
              <Radio label={'TVA unique'} value={'UNIQUE'} />
              <Radio label={'TVA sur mesure'} value={'VARIABLE'} />
            </InlinedRadioGroup>
            <TvaBlock>
              {tvaFormState.variableTva.detailIncome.type === 'UNIQUE' && (
                <SelectTva
                  value={tvaFormState.variableTva.detailIncome.uniqueValue}
                  onChange={(value) =>
                    setTvaFormState((tvaFormState) => ({
                      ...tvaFormState,
                      variableTva: {
                        ...tvaFormState.variableTva,
                        detailIncome: {
                          ...tvaFormState.variableTva.detailIncome,
                          uniqueValue: value,
                        },
                      },
                    }))
                  }
                />
              )}
            </TvaBlock>
            <Collapse isOpen={tvaFormState.variableTva.detailIncome.type === 'VARIABLE'}>
              <IncomeTvaTable
                value={tvaFormState.variableTva.detailIncome.variableDetails}
                onChange={(value) =>
                  setTvaFormState((tvaFormState) => ({
                    ...tvaFormState,
                    variableTva: {
                      ...tvaFormState.variableTva,
                      detailIncome: {
                        ...tvaFormState.variableTva.detailIncome,
                        variableDetails: value,
                      },
                    },
                  }))
                }
              />
            </Collapse>
            <GreenText>Achats et charges externes</GreenText>
            <InlinedRadioGroup
              selectedValue={tvaFormState.variableTva.detailExpense.type}
              onChange={(event: any) =>
                setTvaFormState((tvaFormState) => ({
                  ...tvaFormState,
                  variableTva: {
                    ...tvaFormState.variableTva,
                    detailExpense: {
                      ...tvaFormState.variableTva.detailExpense,
                      type: event.target.value,
                    },
                  },
                }))
              }
            >
              <Radio label={'TVA unique'} value={'UNIQUE'} />
              <Radio label={'TVA sur mesure'} value={'VARIABLE'} />
            </InlinedRadioGroup>
            <TvaBlock>
              {tvaFormState.variableTva.detailExpense.type === 'UNIQUE' && (
                <SelectTva
                  value={tvaFormState.variableTva.detailExpense.uniqueValue}
                  onChange={(value) =>
                    setTvaFormState((tvaFormState) => ({
                      ...tvaFormState,
                      variableTva: {
                        ...tvaFormState.variableTva,
                        detailExpense: {
                          ...tvaFormState.variableTva.detailExpense,
                          uniqueValue: value,
                        },
                      },
                    }))
                  }
                />
              )}
            </TvaBlock>
            <Collapse isOpen={tvaFormState.variableTva.detailExpense.type === 'VARIABLE'}>
              <ExpenseTvaTable
                achatMarchandises={tvaFormState.variableTva.detailExpense.variableAchatMarchandises}
                autreAchat={tvaFormState.variableTva.detailExpense.variableAutreAchat}
                onChangeAchatMarchandises={(value) =>
                  setTvaFormState((tvaFormState) => ({
                    ...tvaFormState,
                    variableTva: {
                      ...tvaFormState.variableTva,
                      detailExpense: {
                        ...tvaFormState.variableTva.detailExpense,
                        variableAchatMarchandises: value,
                      },
                    },
                  }))
                }
                onChangeAutreAchat={(value) =>
                  setTvaFormState((tvaFormState) => ({
                    ...tvaFormState,
                    variableTva: {
                      ...tvaFormState.variableTva,
                      detailExpense: {
                        ...tvaFormState.variableTva.detailExpense,
                        variableAutreAchat: value,
                      },
                    },
                  }))
                }
              />
            </Collapse>
          </IndentedCollapse>
        </AppliedTvaBlock>
      </div>
    </>
  )
}

const TvaSynthesis: FC<{ periods: PeriodModel[] }> = ({ periods }) => {
  const getExerciceTitle = (period: PeriodModel, index: number) => {
    switch (period.type) {
      case PeriodType.Anterieur:
      case PeriodType.Previsionnel:
        return dictionnary.periodTitle(period, index)
      case PeriodType.Intermediaire:
        return `Situation au ${period.endDate.format('DD/MM/YYYY')}`
    }
  }
  const dictionnary = useDictionnary()

  return (
    <div style={{ width: 'fit-content' }}>
      <TabContentTitle>TVA</TabContentTitle>
      <TvaSynthesisTable className={'stripped'}>
        <thead>
          <tr>
            <th className={'transparent title'}>Synthèse</th>
            {periods.map((p, i) => (
              <th key={p.id}>{getExerciceTitle(p, i)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <BlankTableLine cellNumber={periods.length + 1} />
          <tr style={{ backgroundColor: 'var(--main-light-grey-1)' }}>
            <td className={'linetitle'}>Chiffre d'affaires</td>
            {periods.map((p) => (
              <td key={p.id}>
                <NumberText
                  value={p.tva.tva_chiffre_affaire * 100}
                  suffix={'%'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </td>
            ))}
          </tr>
          <tr>
            <td className={'linetitle'}>Achats et charges externes</td>
            {periods.map((p) => (
              <td key={p.id}>
                <NumberText
                  value={Number(p.tva.tva_achats_et_charges * 100)}
                  suffix={'%'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </td>
            ))}
          </tr>
          <BlankTableLine cellNumber={periods.length + 1} />
        </tbody>
      </TvaSynthesisTable>
    </div>
  )
}

export const EmptyTva: FC = () => {
  return (
    <div style={{ width: 'fit-content' }}>
      <TabContentTitle>TVA</TabContentTitle>
      <p>Aucun exercice comptable n'a été créé. &nbsp;</p>
    </div>
  )
}

function initTvaFormState(tva: Tva, tvaList: number[]): TvaFormState {
  const hasUniqueTvaIncome = tva.detail_tva_chiffre_affaire.details.length === 0
  const hasUniqueTvaExpense =
    tva.detail_tva_achat_marchandises.details.length === 0 &&
    tva.detail_tva_autres_achats.details.length === 0
  const hasUniqueTva =
    hasUniqueTvaIncome &&
    hasUniqueTvaExpense &&
    tva.tva_chiffre_affaire === tva.tva_achats_et_charges &&
    tvaList.find((v) => v === tva.tva_achats_et_charges) !== undefined

  return {
    globalType: hasUniqueTva ? 'UNIQUE' : 'VARIABLE',
    globalTva: hasUniqueTva ? tva.tva_chiffre_affaire : undefined,
    variableTva: {
      detailExpense: {
        type: hasUniqueTvaExpense ? 'UNIQUE' : 'VARIABLE',
        uniqueValue: hasUniqueTvaExpense ? tva.detail_tva_achat_marchandises.value : undefined,
        variableAchatMarchandises: removeTypeName(tva.detail_tva_achat_marchandises.details),
        variableAutreAchat: removeTypeName(tva.detail_tva_autres_achats.details),
      },
      detailIncome: {
        type: hasUniqueTvaIncome ? 'UNIQUE' : 'VARIABLE',
        uniqueValue: hasUniqueTvaIncome ? tva.detail_tva_chiffre_affaire.value : undefined,
        variableDetails: removeTypeName(tva.detail_tva_chiffre_affaire.details),
      },
    },
  }
}

const tvaFormStateToTvaInput = (tvaFormState: TvaFormState): TvaInput => {
  const newTva: TvaInput = {
    detail_tva_chiffre_affaire: {
      value: tvaFormState.globalTva,
      details: [],
    },
    detail_tva_achat_marchandises: {
      value: tvaFormState.globalTva,
      details: [],
    },
    detail_tva_autres_achats: {
      value: tvaFormState.globalTva,
      details: [],
    },
  }

  if (tvaFormState.globalType === 'VARIABLE') {
    newTva.detail_tva_chiffre_affaire.value = tvaFormState.variableTva.detailIncome.uniqueValue
    newTva.detail_tva_achat_marchandises.value = tvaFormState.variableTva.detailExpense.uniqueValue
    newTva.detail_tva_autres_achats.value = tvaFormState.variableTva.detailExpense.uniqueValue

    if (tvaFormState.variableTva.detailIncome.type === 'VARIABLE') {
      newTva.detail_tva_chiffre_affaire.details =
        tvaFormState.variableTva.detailIncome.variableDetails
    }
    if (tvaFormState.variableTva.detailExpense.type === 'VARIABLE') {
      newTva.detail_tva_achat_marchandises.details =
        tvaFormState.variableTva.detailExpense.variableAchatMarchandises
      newTva.detail_tva_autres_achats.details =
        tvaFormState.variableTva.detailExpense.variableAutreAchat
    }
  }

  return newTva
}

const needPeriodUpdate = (input: TvaInput, period: PeriodModel): boolean => {
  const isNotEquals = (detailsInput: TvaDetailsInput, actualDetail: TvaDetail): boolean => {
    const safeActualDetail: TvaDetailsInput = {
      value: actualDetail.value,
      details: actualDetail.details.map<TvaPortionInput>((d) => ({
        ratio: d.ratio,
        tauxTVA: d.tauxTVA,
      })),
    }
    return (
      !equal(detailsInput.details, safeActualDetail.details, { strict: true }) ||
      (detailsInput.details.length === 0 &&
        safeActualDetail.details.length === 0 &&
        detailsInput.value !== safeActualDetail.value)
    )
  }

  return (
    isNotEquals(input.detail_tva_chiffre_affaire, period.tva.detail_tva_chiffre_affaire) ||
    isNotEquals(input.detail_tva_achat_marchandises, period.tva.detail_tva_achat_marchandises) ||
    isNotEquals(input.detail_tva_autres_achats, period.tva.detail_tva_autres_achats)
  )
}

const equal = require('deep-equal')

type TvaType = 'UNIQUE' | 'VARIABLE' | string

interface TvaFormState {
  globalType: TvaType
  globalTva?: number
  variableTva: VariableTva
}

interface VariableTva {
  detailIncome: VariableTvaDetailForIncome
  detailExpense: VariableTvaDetailForExpense
}

interface VariableTvaDetailForIncome {
  type: TvaType
  uniqueValue?: number
  variableDetails: TvaPortion[]
}

interface VariableTvaDetailForExpense {
  type: TvaType
  uniqueValue?: number
  variableAchatMarchandises: TvaPortion[]
  variableAutreAchat: TvaPortion[]
}

const IndentedCollapse = styled(Collapse)`
  margin-left: 3.5em;
`
