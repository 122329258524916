import {
  Caf,
  Bfr,
  FinancialPeriod,
  IncomeStatement,
  Tva,
  PeriodPlanFinancement,
  NumericData,
  RemboursementsEmprunts,
  PeriodType,
  CalculatedData,
  Bilan,
  BfrAnterieur,
} from '@fa-metier/types'
import dayjs, { Dayjs } from 'dayjs'

export const fromFinancialPeriod = (
  period: Pick<
    FinancialPeriod,
    | 'startDate'
    | 'endDate'
    | 'id'
    | 'incomeStatement'
    | 'tva'
    | 'caf'
    | 'bfr'
    | 'remboursementsEmprunts'
    | 'besoinsFixes'
    | 'pointMort'
    | 'margeDeManoeuvre'
    | 'duration'
    | 'periodPlanFinancement'
    | 'prelevementTns'
    | 'periodType'
    | 'bilanCedant'
    | 'bfrAnterieur'
  >
): PeriodModel => {
  return {
    id: period.id,
    startDate: dayjs(period.startDate!),
    endDate: dayjs(period.endDate!),
    incomeStatement: period.incomeStatement,
    tva: period.tva,
    caf: period.caf,
    bfr: period.bfr,
    remboursementsEmprunts: period.remboursementsEmprunts,
    besoinsFixes: period.besoinsFixes,
    pointMort: period.pointMort,
    margeDeManoeuvre: period.margeDeManoeuvre,
    duration: period.duration,
    planFinancement: period.periodPlanFinancement,
    prelevementTns: period.prelevementTns,
    type: period.periodType,
    bilanCedant: period.bilanCedant ?? undefined,
    bfrAnterieur: period.bfrAnterieur ?? undefined,
  }
}

export interface PeriodModel {
  id: string
  startDate: Dayjs
  endDate: Dayjs
  incomeStatement: IncomeStatement
  tva: Tva
  caf: Caf
  bfr: Bfr
  remboursementsEmprunts: RemboursementsEmprunts
  besoinsFixes: CalculatedData
  pointMort: CalculatedData
  margeDeManoeuvre: CalculatedData
  duration: number
  planFinancement: PeriodPlanFinancement
  prelevementTns: NumericData
  type: PeriodType
  bilanCedant?: Bilan
  bfrAnterieur?: BfrAnterieur
}

export const comparePeriodByDate = (p1: PeriodModel, p2: PeriodModel) => {
  return p1.startDate.isBefore(p2.startDate) ? -1 : 1
}

export const lastPeriod = (periods: PeriodModel[]) => periods.sort(comparePeriodByDate).reverse()[0]
export const firstPeriod = (periods: PeriodModel[]) => periods.sort(comparePeriodByDate)[0]

export const periodYearTitle = (p: PeriodModel) => {
  return p.startDate.year() === p.endDate.year()
    ? p.startDate.year()
    : `${p.startDate.year()}-${p.endDate.year()}`
}
