import { DifferenceSaisies } from '../Utils/DifferencesDeSaisie'
import React, { FC, useContext } from 'react'
import { PeriodModel } from '../PeriodModel'
import { incomeStatementToInput } from '../IncomeStatementMapper'
import { useUpdatePeriods } from '../FinanciaryQueries'
import {
  FinancialPeriodsSaveRequestMultipleInput,
  MasseSalariale,
  MasseSalarialePeriod,
} from '@fa-metier/types'
import { NoteContext } from '../../NotePage'
import produce from 'immer'
import { useDictionnary } from '../../dictionnary/dictionnary'

function periodToUpdate(masseSalarialPeriod: MasseSalarialePeriod, period: PeriodModel) {
  return produce(period, (draft) => {
    if (
      masseSalarialPeriod.salairesBruts.numericData.value ||
      masseSalarialPeriod.salairesBruts.numericData.value === 0
    ) {
      draft.incomeStatement.traitementsSalaires.value =
        masseSalarialPeriod.salairesBruts.numericData.value

      draft.incomeStatement.traitementsSalaires.detail =
        masseSalarialPeriod.salairesBruts.numericData.value.toString()
    }
    if (
      masseSalarialPeriod.chargesSocialesPatronales.numericData.value ||
      masseSalarialPeriod.chargesSocialesPatronales.numericData.value === 0
    ) {
      draft.incomeStatement.cotisationsSocialesPatronales.value =
        masseSalarialPeriod.chargesSocialesPatronales.numericData.value
      draft.incomeStatement.cotisationsSocialesPatronales.detail =
        masseSalarialPeriod.chargesSocialesPatronales.numericData.value.toString()
    }
  })
}

export const DifferencesSaisiesMasseSalariale: FC<{
  masseSalarialeInput: MasseSalariale
  periodsFinanciary: PeriodModel[]
}> = ({ masseSalarialeInput, periodsFinanciary }) => {
  const { noteId } = useContext(NoteContext)
  const updateMutation = useUpdatePeriods(noteId)
  const { differencesSaisies } = useDictionnary()

  const updateCR = () => {
    const updates = masseSalarialeInput?.periods
      .filter((msp) => periodsFinanciary.some((period) => period.id === msp.periodId))
      .map((msp) => {
        const incomeStatementToUpdate = periodsFinanciary.find(
          (incomeStatementPeriod) => incomeStatementPeriod.id === msp.periodId
        )
        const incomeStatementUpdate = { ...incomeStatementToUpdate } as PeriodModel
        return periodToUpdate(msp, incomeStatementUpdate)
      })
      .map(periodToMutationModel)
    return updateMutation({ updates })
  }

  const periodToMutationModel = (period: PeriodModel): FinancialPeriodsSaveRequestMultipleInput => {
    return {
      financialPeriodId: period.id,
      input: {
        incomeStatement: incomeStatementToInput(period.incomeStatement),
      },
    }
  }

  const sourceValuesSalaires = periodsFinanciary.map(
    (_, i) => masseSalarialeInput?.periods[i]?.salairesBruts.numericData.value ?? 0
  )

  const targetValuesSalaires = periodsFinanciary.map(
    (period) => period.incomeStatement.traitementsSalaires.value ?? 0
  )

  const sourceValuesCharges = periodsFinanciary.map(
    (_, i) => masseSalarialeInput?.periods[i]?.chargesSocialesPatronales.numericData.value ?? 0
  )

  const targetValuesCharges = periodsFinanciary.map(
    (period) => period.incomeStatement.cotisationsSocialesPatronales.value ?? 0
  )

  const intermediaryTableData = [
    {
      title: 'Salaires bruts',
      sourceValues: sourceValuesSalaires,
      targetTitle: 'Données saisies dans le Compte de résultat*',
      targetValues: targetValuesSalaires,
    },
    {
      title: 'Charges sociales',
      sourceValues: sourceValuesCharges,
      targetTitle: 'Données saisies dans le Compte de résultat*',
      targetValues: targetValuesCharges,
      textNotes: '*hors prélèvements TNS',
    },
  ]

  return (
    <DifferenceSaisies
      key={'differenceSaisies'}
      updateFunction={updateCR}
      intermediaryTableData={intermediaryTableData}
      asterisk={'*hors prélèvements TNS'}
      explanation={
        <>
          {differencesSaisies.crExplanation}
          {differencesSaisies.masseSalarialeMoreInformation}
        </>
      }
      buttonText={'Remplacer les données du Compte de résultat'}
      dialogBody={
        <>
          <p>
            Etes-vous sûr de vouloir remplacer les données du <b>Compte de résultat</b> ?
          </p>
          <p>Cette action ne peut pas être inversée.</p>
        </>
      }
      successText={differencesSaisies.crSuccessText}
      hideDemarrage={true}
    />
  )
}
