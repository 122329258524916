import React, { FC, useContext, useMemo, useState } from 'react'
import { Button, ButtonProps, Dialog, Divider, FormGroup, Icon, Intent } from '@blueprintjs/core'
import { TabContentTitle } from '../../../utils/PageTitle'
import { getDayjsFormatter } from '../../../utils/DayjsFormatter'
import { Period, UpdateDateType, validatePeriod } from './Period'
import { comparePeriodByDate, firstPeriod, lastPeriod, PeriodModel } from '../PeriodModel'
import { useAddPeriod, useInitPeriods, useUpdatePeriodsDates } from '../FinanciaryQueries'
import styled from 'styled-components'
import dayjs, { Dayjs } from 'dayjs'
import { FinanciaryWrapperContext } from '../Financiary'
import { toIso } from '../IncomeStatementMapper'
import { AppMetierDateInput } from '../Utils/AppMetierInput/AppMetierDateInput'
import { DIALOG_BODY, DIALOG_FOOTER } from '@blueprintjs/core/lib/esm/common/classes'
import {
  FinancialPeriodsSaveDatesRequestMultipleInput,
  NoteType,
  PeriodType,
} from '@fa-metier/types'
import { NoteContext } from '../../NotePage'
import { useDictionnary } from '../../dictionnary/dictionnary'

export const endDateMutationModel = (
  noteId: string,
  period: PeriodModel
): FinancialPeriodsSaveDatesRequestMultipleInput => {
  return {
    financialPeriodId: period.id,
    input: {
      noteId,
      endDate: toIso(period.endDate),
    },
  }
}

const startDateMutationModel = (
  noteId: string,
  period: PeriodModel
): FinancialPeriodsSaveDatesRequestMultipleInput => {
  return {
    financialPeriodId: period.id,
    input: {
      noteId,
      startDate: toIso(period.startDate),
    },
  }
}

export const AddPeriodButtonStyled = styled(Button)`
  box-shadow: none !important;
  background-color: var(--main-light-green-1) !important;
  color: var(--main-green-1) !important;
  background-image: none !important;
  width: 450px;
  height: 140px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;

  .bp3-icon {
    color: var(--main-green-1) !important;
  }

  :hover {
    background-color: var(--main-green-1) !important;
    color: white !important;

    .bp3-icon {
      color: white !important;
    }
  }
`

const ExerciceType = styled.span`
  font-weight: var(--bold);
  text-align: center;
`

export const AddPeriodButton: FC<ButtonProps> = (props) => {
  return <AddPeriodButtonStyled {...props} />
}

const MyCard = styled.div`
  background-color: white;
  padding: 20px;
  margin: 0;
`

const BaseDatePicker: FC<{
  date?: Dayjs
  updateDate: (date: Dayjs) => void
  periodType?: PeriodType
  exNihilo?: Boolean
}> = ({ date, updateDate, periodType, exNihilo }) => {
  const {
    noteSettings: { baseDateLabel },
  } = useDictionnary()
  const { note } = useContext(NoteContext)
  const valid = validatePeriod(date)
  return (
    <FormGroup
      label={baseDateLabel(periodType)}
      intent={
        exNihilo === true ||
        periodType !== PeriodType.Anterieur ||
        valid.dateDernierExo.length === 0
          ? Intent.NONE
          : Intent.DANGER
      }
      helperText={
        exNihilo !== true && periodType === PeriodType.Anterieur && valid.dateDernierExo.join(' ')
      }
    >
      <AppMetierDateInput
        value={date && date.format('YYYY-MM-DD')}
        onChange={(date, isUserChange) =>
          isUserChange &&
          (periodType !== PeriodType.Anterieur ||
            exNihilo === true ||
            validatePeriod(dayjs(date)).all)
            ? updateDate(dayjs(date))
            : {}
        }
        maxDate={
          periodType === PeriodType.Anterieur && exNihilo === false
            ? dayjs(Date.now()).toDate()
            : undefined
        }
        closeOnSelection={true}
        disabled={note.type === NoteType.StructureExistante && !!date}
        {...getDayjsFormatter('DD/MM/YYYY')}
      />
    </FormGroup>
  )
}

const CreationOrEndingDateAlertDialog: FC<{
  open: boolean
  close: () => void
  proceed: () => void
  periodType: PeriodType
}> = ({ open, close, proceed, periodType }) => {
  const isPastPeriodForm = periodType === PeriodType.Anterieur

  return (
    <Dialog isOpen={open} title={'Attention'} isCloseButtonShown={false}>
      <div className={DIALOG_BODY}>
        <p>
          Etes-vous sûr de vouloir modifier la date de{' '}
          {isPastPeriodForm ? 'fin du dernier exercice' : "début d'activité"} ?
        </p>
        <p>
          Si vous avez changé la durée d'un exercice et que celle-ci n'est plus égale à 12 mois, la
          date de {isPastPeriodForm ? 'fin' : 'début'} déjà saisie sera conservée, et la durée
          recalculée en conséquence.
        </p>
        <p>
          Si des données ont été saisies dans les onglets financiers, celles-ci seront impactées en
          conséquence, notamment dans le BFR{isPastPeriodForm ? '' : ' prévisionnel'}.
        </p>
      </div>
      <div className={DIALOG_FOOTER}>
        <Button
          style={{ marginRight: '0.5em' }}
          onClick={() => {
            close()
          }}
        >
          Annuler
        </Button>
        <Button
          intent={'primary'}
          onClick={() => {
            proceed()
            close()
          }}
        >
          Confirmer
        </Button>
      </div>
    </Dialog>
  )
}

const PeriodsForm: FC<{ periodType?: PeriodType }> = ({ periodType }) => {
  const { note } = useContext(NoteContext)
  if (note.type === NoteType.StructureExistante) {
    return <StructureExistantePeriodsForm periodType={periodType} />
  }
  if (periodType === PeriodType.Anterieur) {
    return <CedantPeriodForm />
  }
  return <PrevisionnelPeriodsForm />
}

const PrevisionnelPeriodsForm: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { previsionelPeriods } = useContext(FinanciaryWrapperContext)
  const updateMutation = useUpdatePeriodsDates(noteId)

  const periodsOfType = previsionelPeriods.sort(comparePeriodByDate)
  const creationDate = periodsOfType.length > 0 ? periodsOfType[0].startDate : undefined
  const updateCreationDate = (date: Dayjs) => {
    periodsOfType[0].startDate = date
    const updates = [startDateMutationModel(noteId, periodsOfType[0])]
    updateMutation({ updates })
  }
  return (
    <AnyPeriodsForm
      periods={periodsOfType}
      initialBaseDate={creationDate}
      updateBaseDate={updateCreationDate}
      title={'Durée des exercices comptables'}
      addPeriodLabel={'Ajouter un exercice'}
      periodType={PeriodType.Previsionnel}
    />
  )
}

const CedantPeriodForm: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { anterieurPeriods } = useContext(FinanciaryWrapperContext)
  const updateMutation = useUpdatePeriodsDates(noteId)

  const lastAnterieurPeriod = lastPeriod(anterieurPeriods)
  const endPastPeriods = lastAnterieurPeriod?.endDate

  const updateEndPastPeriodsDate = (date: Dayjs) => {
    lastAnterieurPeriod.endDate = date
    const updates = [endDateMutationModel(noteId, lastAnterieurPeriod)]
    updateMutation({ updates })
  }
  return (
    <AnyPeriodsForm
      periods={anterieurPeriods.sort(comparePeriodByDate)}
      initialBaseDate={endPastPeriods}
      updateBaseDate={updateEndPastPeriodsDate}
      title={'Durée des exercices comptables du cédant'}
      addPeriodLabel={'Ajouter un exercice passé'}
      periodType={PeriodType.Anterieur}
    />
  )
}

const StructureExistantePeriodsForm: FC<{ periodType?: PeriodType }> = ({ periodType }) => {
  const { noteId } = useContext(NoteContext)
  const { anterieurPeriods, previsionelPeriods } = useContext(FinanciaryWrapperContext)
  const updateMutation = useUpdatePeriodsDates(noteId)

  const periods = anterieurPeriods.concat(previsionelPeriods)

  const updateFirstPeriodStartDate = (date: Dayjs) => {
    const update = { ...firstPeriod(anterieurPeriods) }
    update.startDate = date
    const updates = [startDateMutationModel(noteId, update)]
    updateMutation({ updates })
  }

  const baseDate = lastPeriod(periods.filter((p) => p.type === PeriodType.Anterieur))?.endDate

  return (
    <AnyPeriodsForm
      periods={periods}
      initialBaseDate={baseDate}
      updateBaseDate={updateFirstPeriodStartDate}
      title={'Durée des exercices comptables'}
      addPeriodLabel={'Ajouter un exercice passé'}
      periodType={periodType}
    />
  )
}

const AnyPeriodsForm: FC<{
  periods: PeriodModel[]
  initialBaseDate?: Dayjs
  updateBaseDate: (date: Dayjs) => void
  title: string
  addPeriodLabel: string
  periodType?: PeriodType
}> = ({ periods, initialBaseDate, updateBaseDate, title, addPeriodLabel, periodType }) => {
  const { noteId, note } = useContext(NoteContext)
  const { situationIntermediaire } = useContext(FinanciaryWrapperContext)

  const updateMutation = useUpdatePeriodsDates(noteId)
  const addPeriodMutation = useAddPeriod(noteId)
  const initMutation = useInitPeriods(noteId)
  const [loading, setLoading] = useState(false)
  const [warningSituationIntermediaireModified, setWarningSituationIntermediaireModified] =
    useState(false)

  const isStructureExistante = note.type === NoteType.StructureExistante

  const addPeriod = (periodType: PeriodType) => {
    setLoading(true)
    addPeriodMutation(periodType).then(() => setLoading(false))
  }

  const updatePeriods = (periodId: string, date: Dayjs, updateDateType: UpdateDateType) => {
    const periodsTemp = [...periods]
    const periodNumber = periodsTemp.findIndex((p) => p.id === periodId)
    if (updateDateType === 'endDate') {
      const currentSituationIntermediaire = situationIntermediaire?.endDate
      periodsTemp[periodNumber].endDate = date
      const updates = [endDateMutationModel(noteId, periodsTemp[periodNumber])]
      updateMutation({ updates }).then((periods) => {
        const modifiedSituationIntermediaire = periods.find(
          (p) => p.type === PeriodType.Intermediaire
        )?.endDate
        if (
          currentSituationIntermediaire &&
          !currentSituationIntermediaire.isSame(modifiedSituationIntermediaire, 'day')
        ) {
          setWarningSituationIntermediaireModified(true)
        }
      })
    } else {
      periodsTemp[periodNumber].startDate = date
      const updates = startDateMutationModel(noteId, periodsTemp[periodNumber])
      updateMutation({ updates })
    }
  }
  const [newBaseDate, setNewBaseDate] = useState<Dayjs | undefined>(undefined)

  const proceedUpdateBaseDate = (date: Dayjs) => {
    if (periods.length > 0) {
      updateBaseDate(date)
    } else {
      initMutation(date, periodType).catch((error) => console.log(error))
    }
  }

  const updateBaseDateCheck = (date: Dayjs) => {
    if (periods.length > 0) {
      setNewBaseDate(date)
    } else {
      proceedUpdateBaseDate(date)
    }
  }

  const removablePeriodIds: () => string[] = () => {
    const anterieurPeriods = periods
      .sort(comparePeriodByDate)
      .filter((p) => p.type === PeriodType.Anterieur)
      .map((it) => it.id)
    const previsionnelPeriods = periods
      .sort(comparePeriodByDate)
      .filter((p) => p.type === PeriodType.Previsionnel)
      .reverse()
      .map((it) => it.id)
    if (isStructureExistante) {
      const removableAnterieur = anterieurPeriods.length > 1 ? [anterieurPeriods[0]] : []
      const removablePrevi = previsionnelPeriods.length > 1 ? [previsionnelPeriods[0]] : []
      return removableAnterieur.concat(removablePrevi).filter((it) => !!it)
    }
    if (periodType === PeriodType.Previsionnel) {
      return previsionnelPeriods.length > 1 ? [previsionnelPeriods[0]] : []
    }
    return [anterieurPeriods[0]]
  }

  const isPeriodRemovable = (periodIndex: string) => removablePeriodIds().includes(periodIndex)

  const periodOrderer = useMemo(() => {
    if (periodType === PeriodType.Previsionnel || note.type === NoteType.StructureExistante) {
      return comparePeriodByDate
    }
    return (p1: PeriodModel, p2: PeriodModel) => -comparePeriodByDate(p1, p2)
  }, [note.type, periodType])

  const firstPreviPeriod = periods
    .sort(comparePeriodByDate)
    .filter((p) => p.type === PeriodType.Previsionnel)[0]

  return (
    <div style={{ maxWidth: '1280px' }}>
      <TabContentTitle>{title}</TabContentTitle>
      <MyCard>
        {(note.type !== NoteType.StructureExistante || !initialBaseDate) && (
          <BaseDatePicker
            date={initialBaseDate}
            updateDate={updateBaseDateCheck}
            periodType={
              note.type === NoteType.Reprise || initialBaseDate ? periodType : PeriodType.Anterieur
            }
            exNihilo={note.creationExNihilo ?? undefined}
          />
        )}
        <PeriodsContainer>
          {isStructureExistante && (
            <>
              <PeriodsDiv className={'StructureExistanteAnterieurs'}>
                <AddPeriodButton
                  className={'addPeriod'}
                  onClick={() => addPeriod(PeriodType.Anterieur)}
                  disabled={periods.length === 0 || loading}
                >
                  <Icon className={'button-icon'} icon={'add'} iconSize={40} />
                  <div>{'Ajouter un exercice passé'}</div>
                </AddPeriodButton>
                {periods
                  .filter((p) => p.type === PeriodType.Anterieur)
                  .sort(periodOrderer)
                  .slice()
                  .map((period, i) => (
                    <Period
                      key={period.id}
                      periodIndex={i}
                      period={period}
                      update={updatePeriods}
                      removable={isPeriodRemovable(period.id)}
                    />
                  ))}
                <ExerciceType>Exercices passés</ExerciceType>
              </PeriodsDiv>
              <Divider />
            </>
          )}

          <PeriodsDiv className={isStructureExistante ? 'StructureExistante' : ''}>
            {isStructureExistante && <ExerciceType>Exercices prévisionnels</ExerciceType>}
            {periods
              .filter((p) => p.type === PeriodType.Previsionnel || !isStructureExistante)
              .sort(periodOrderer)
              .slice()
              .map((period, i) => (
                <Period
                  key={period.id}
                  periodIndex={i}
                  period={period}
                  update={updatePeriods}
                  removable={isPeriodRemovable(period.id)}
                  addIntermediaire={isStructureExistante && firstPreviPeriod.id === period.id}
                />
              ))}
            <AddPeriodButton
              className={'addPeriod'}
              onClick={() => addPeriod(periodType ?? PeriodType.Previsionnel)}
              disabled={periods.length === 0 || loading}
            >
              <Icon className={'button-icon'} icon={'add'} iconSize={40} />
              <div>
                {isStructureExistante ? 'Ajouter un exercice prévisionnel' : addPeriodLabel}
              </div>
            </AddPeriodButton>
          </PeriodsDiv>
        </PeriodsContainer>
      </MyCard>
      <CreationOrEndingDateAlertDialog
        open={!!newBaseDate}
        close={() => setNewBaseDate(undefined)}
        proceed={() => proceedUpdateBaseDate(newBaseDate!!)}
        periodType={periodType ?? PeriodType.Previsionnel}
      />
      <Dialog
        isOpen={warningSituationIntermediaireModified}
        title={'Attention'}
        isCloseButtonShown={true}
        onClose={() => setWarningSituationIntermediaireModified(false)}
      >
        <div className={DIALOG_BODY}>
          <p>Attention !</p>
          <p>
            Les dates que vous venez d'indiquer ne correspondent plus à la date de situation
            intermédiaire indiquée.
          </p>
          <p>
            Nous avons donc dû changer cette date pour y mettre une date par défaut. Nous vous
            conseillons de la modifier manuellement pour avoir des formules et données cohérentes.
          </p>
        </div>
        <div className={DIALOG_FOOTER}>
          <Button
            intent={'primary'}
            onClick={() => {
              setWarningSituationIntermediaireModified(false)
            }}
          >
            Ok
          </Button>
        </div>
      </Dialog>
    </div>
  )
}

const PeriodsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const PeriodsDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.StructureExistante {
    flex-direction: column;
  }

  &.StructureExistanteAnterieurs {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .Period {
    position: relative;
    margin: 1em;
  }

  .addPeriod {
    margin: 1em;
    padding: 1em;

    .button-icon {
      margin-left: 2.5em !important;
      margin-right: 2.5em !important;
      margin-top: 1em !important;
    }
  }
`

export default PeriodsForm
