import { FrontEventsLogRequest } from '@fa-metier/types'
import { useLogFrontEventsMutation } from '../generated/graphql'

export const useLogFrontEvents = () => {
  const [mutation] = useLogFrontEventsMutation()
  return (request: FrontEventsLogRequest) => {
    return new Promise<boolean>((resolve, reject) => {
      mutation({
        variables: { request },
      })
        .then((response) => {
          const result = response.data && response.data.logging?.logFrontEvents
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}
