import React, { FC } from 'react'
import { ReactComponent as Arrow } from '../assets/arrow.svg'
import styled from 'styled-components'

export const PageTitle: FC = ({ children }) => {
  return (
    <ArrowH1>
      <Arrow style={{ color: `var(--main-green-1)` }} />
      {children}
    </ArrowH1>
  )
}

const ArrowH1 = styled.h1`
  display: flex;
  align-items: center;
  font-weight: var(--bold);
  margin-bottom: 20px;
  svg {
    min-width: 30px;
    margin-right: 0.5em;
    transform: rotate(0.25turn);
  }
`

export default PageTitle

const Title = styled.div`
  font-style: normal;
  font-weight: var(--bold);
  font-size: 20px;
  line-height: 26px;
  color: var(--main-green-1);
  margin-bottom: 20px;
  margin-top: 30px;
`

export const TabContentTitle: FC = ({ children }) => {
  return <Title>{children}</Title>
}
