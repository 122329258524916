import styled from 'styled-components'
import { Popover2 } from '@blueprintjs/popover2'

export const FormulaDescriptionPopover = styled(Popover2)`
  width: 100%;
  :hover {
    cursor: pointer;
  }
  .bp3-popover-target {
    width: 100%;
  }
`
