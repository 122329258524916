import React, { FC, useContext, useState } from 'react'
import { Button, Collapse, Divider, Icon, IconSize, Menu, MenuItem } from '@blueprintjs/core'
import styled from 'styled-components'
import { useAppContext } from '../config/context'
import { MenuElement, useMenuElements } from './ProjectMenus'
import { ProjectContext } from '../App'
import { NoteContext } from './NotePage'

const MenuDrawer = styled.div`
  background-color: var(--main-green-2);
  position: relative;
  transition: width 0.5s;
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  width: ${(props) => props.theme.width};
`
MenuDrawer.defaultProps = {
  theme: {
    width: '1.5%',
  },
}

const Overflowed = styled.div`
  overflow: hidden;
  visibility: ${(props) => (props.theme.visibility ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.theme.visibility ? 1 : 0)};
  transition: visibility 0.2s, opacity 0.5s linear;
`
Overflowed.defaultProps = {
  theme: {
    visibility: true,
  },
}

const DrawerDivider = styled(Divider)`
  border-bottom: 2px solid var(--main-grey-2);
  margin: 0 10px;
`

const DrawerTitle = styled.h3`
  margin-bottom: 5px;
  margin-left: 10px;
`

const TitleIcon = styled(Icon)`
  padding: 15px 10px 5px;
`

const ButtonToggle = styled(Button)`
  border-radius: 30px;
  position: absolute;
  right: -12px;
  top: 20px;
  background: var(--main-black-2) !important;

  & span {
    color: var(--main-grey-3) !important;
  }

  outline: none;
`

const ProjectMenu = styled(Menu)`
  background: transparent;
`

const ProjectMenuItem = styled(MenuItem)`
  background: transparent;
  padding: 0;
  height: 40px;
  display: flex;
  align-items: center;

  & .project-menu-icon {
    padding: 10px;
  }

  &:hover {
    background: var(--main-green-1);
    color: white;

    & .project-menu-icon {
      background: var(--main-black-2);
    }
  }

  &.selected-menu {
    background: var(--main-green-1);

    & .project-menu-icon {
      background: var(--main-black-2);
    }

    & * {
      color: white;
    }
  }

  & > div {
    height: 100%;
  }
`

const MenuItemContent: FC<MenuElement> = ({ text, icon }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ fontSize: '1.2em', marginLeft: '5px' }}>{text}</div>
      </div>
      {icon && (
        <div className={'project-menu-icon'}>
          <Icon icon={icon} />
        </div>
      )}
    </div>
  )
}

export const NoteMenuDrawer: FC = () => {
  const { routingStore } = useAppContext()
  const { projectName } = useContext(ProjectContext)
  const { note } = useContext(NoteContext)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState(window.location.pathname)

  routingStore.history.listen((location) => setSelectedMenu(location.pathname))

  const menus = useMenuElements()

  const isLocationASubMenu = (menuElement: MenuElement) => {
    return menuElement.subMenus && !!menuElement.subMenus!.find((sub) => sub.link === selectedMenu)
  }

  const initialOpenedMenu = menus.find((menu) => isLocationASubMenu(menu))

  const [openedMenu, setOpenedMenu] = useState<string | undefined>(
    initialOpenedMenu && initialOpenedMenu.text
  )

  const toggleSubMenu = (subMenuOf: string) => {
    if (openedMenu === subMenuOf) {
      setOpenedMenu(undefined)
    } else {
      setOpenedMenu(subMenuOf)
    }
  }

  const updateSelectedMenu = (selectedMenu: string) => {
    setSelectedMenu((previousSelectedMenu) =>
      previousSelectedMenu === window.location.pathname ? previousSelectedMenu : selectedMenu
    )
  }

  const MenuBlock: FC<{ menuElement: MenuElement }> = ({ menuElement }) => (
    <>
      <ProjectMenuItem
        className={selectedMenu === menuElement.link ? 'selected-menu' : ''}
        key={menuElement.text}
        id={menuElement.id}
        onClick={() => {
          if (menuElement.link) {
            if (selectedMenu !== menuElement.link) {
              routingStore.push(menuElement.link)
              updateSelectedMenu(menuElement.link)
            }
          } else {
            toggleSubMenu(menuElement.text)
          }
        }}
        icon={
          menuElement.subMenus && (openedMenu === menuElement.text ? 'chevron-up' : 'chevron-down')
        }
        text={<MenuItemContent text={menuElement.text} icon={menuElement.icon} />}
      />
      {menuElement.subMenus && (
        <Collapse isOpen={openedMenu !== undefined && openedMenu === menuElement.text}>
          {(menuElement.subMenus || [])
            .filter(
              (sub) =>
                sub.noteTypeConditions === undefined ||
                sub.noteTypeConditions.some((l) => l === note.type)
            )
            .map((sub) => (
              <ProjectMenuItem
                className={selectedMenu === sub.link ? 'selected-menu' : ''}
                style={{ marginLeft: '20px' }}
                key={sub.text}
                id={sub.id}
                onClick={() => {
                  if (sub.link && sub.link !== '#' && sub.link !== selectedMenu) {
                    routingStore.push(sub.link)
                    updateSelectedMenu(sub.link)
                  }
                }}
                text={<MenuItemContent text={sub.text} icon={sub.icon} />}
              />
            ))}
        </Collapse>
      )}
    </>
  )

  return (
    <MenuDrawer theme={{ width: isOpen ? '320px' : '32px' }}>
      <ButtonToggle
        icon={isOpen ? 'chevron-left' : 'chevron-right'}
        small
        onClick={() => setIsOpen(!isOpen)}
        id={isOpen ? 'NoteMenuDrawerButtonOpen' : 'NoteMenuDrawerButtonClose'}
      />
      <Overflowed theme={{ visibility: isOpen }}>
        <p />
        <div style={{ display: 'flex' }}>
          <TitleIcon icon={'folder-close'} size={IconSize.LARGE} />
          <DrawerTitle>{projectName}</DrawerTitle>
        </div>
        <DrawerDivider />
        <ProjectMenu>
          {menus
            .filter(
              (menuElement) =>
                menuElement.noteTypeConditions === undefined ||
                menuElement.noteTypeConditions.some((l) => l === note.type)
            )
            .map((menuElement) => (
              <MenuBlock menuElement={menuElement} key={menuElement.text} />
            ))}
        </ProjectMenu>
      </Overflowed>
    </MenuDrawer>
  )
}
