import { useConfigQuery, useMySettingsQuery } from '../generated/graphql'
import { Label, LabelKeys } from '@fa-metier/types'
import { useMemo } from 'react'
import { DEBOUNCE_CONFIG } from './debounceConfig'

export const useFavoriteProjects = () => {
  const { data, loading } = useMySettingsQuery()
  if (!loading && data) {
    return data.advisorSettings.settings.favoriteProject
  }
  return []
}

export const useShowInvolvementProfileHelp = () => {
  const { data, loading } = useMySettingsQuery()
  if (!loading && data) {
    return data.advisorSettings.settings.showInvolvementProfileHelp
  }
  return false
}

export const useUserDebounce: () => number = () => {
  const { data, loading } = useMySettingsQuery()
  if (!loading && data) {
    return data.advisorSettings.settings.debounce
  }
  return DEBOUNCE_CONFIG.delay.longUpdateRequest
}

export const useDictionary = () => {
  const { data, loading } = useConfigQuery()
  if (!loading && data) {
    return data.dictionary.labels
  }
  return []
}

export const useLabel = (label: LabelKeys) => {
  const dict = useDictionary()
  return useMemo(() => {
    const found = dict.find((l: Label) => l.cle === label)
    return (found && found.value) || ''
  }, [dict, label])
}

export const useImpotSocieteParams = () => {
  const { data, loading } = useConfigQuery()
  if (!loading && data) {
    return data.impotSocieteParam.impotSocieteParams
  }
  return []
}
