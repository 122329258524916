import React, { CSSProperties, FC, useContext } from 'react'
import { Divider } from '@blueprintjs/core'
import styled from 'styled-components'
import { PeriodType, Pret, PretPasse, PretTableauAmortissementLine } from '@fa-metier/types'
import { formatDayjs } from '../../../utils/DayjsFormatter'
import dayjs from 'dayjs'
import { NumberText } from '@fa-metier/components'
import { PretCard, PretSubsectionTitle } from './PretStyle'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { NoteContext } from '../../NotePage'
import { FinanciaryContext } from '../Financiary'
import { comparePeriodByDate, PeriodModel } from '../PeriodModel'
import { useDictionnary } from '../../dictionnary/dictionnary'

const TableHeader = styled.div`
  background-color: var(--main-grey-3);
  line-height: 26px;
  display: flex;
  color: var(--main-black-2);
  span {
    font-weight: var(--bold);
    width: 16%;
    padding-left: 5px;
  }
  .echeanceNumber {
    width: 10%;
  }
`

const TableLine = styled.div`
  display: flex;
  line-height: 26px;
  color: var(--main-grey-1);
  * span {
    width: 16%;
    padding-left: 5px;
  }
  .echeanceNumber {
    width: 10%;
  }
`

const ExerciseDivider = styled(Divider)`
  margin: 0;
`

const TableTitle = styled.span`
  text-decoration: underline;
  line-height: 40px;
`

interface AmortissementLineProps {
  line: PretTableauAmortissementLine
  exerciseLine?: string
  index: number
  echeanceOffset: number
  inKiloEuro: boolean
  background: CSSProperties
}

const AmortissementLine: FC<AmortissementLineProps> = ({
  line,
  index,
  exerciseLine,
  echeanceOffset,
  inKiloEuro,
  background,
}) => {
  const backgroundColor = index % 2 === 0 ? { backgroundColor: 'var(--main-blue-2)' } : {}

  return (
    <div>
      {exerciseLine && <ExerciseDivider />}
      <TableLine style={backgroundColor}>
        <div style={{ width: '100%', display: 'flex' }}>
          {exerciseLine && <span style={background}>{exerciseLine}</span>}
          {!exerciseLine && <span style={background}>&nbsp;</span>}
          <span className={'echeanceNumber'}>{index + echeanceOffset + 1}</span>
          <span>{formatDayjs(dayjs(line.echeance))}</span>
          <NumberText value={line.capitalDebut} suffix={eurosSuffix(inKiloEuro)} />
          <NumberText value={line.montantGlobal} suffix={eurosSuffix(inKiloEuro)} />
          <NumberText value={line.interets} suffix={eurosSuffix(inKiloEuro)} />{' '}
          <NumberText value={line.amortissement} suffix={eurosSuffix(inKiloEuro)} />
          <NumberText
            value={line.capitalFin < 0.01 ? 0 : line.capitalFin}
            suffix={eurosSuffix(inKiloEuro)}
          />
        </div>
      </TableLine>
    </div>
  )
}

const SubTable: FC<{
  lines: PretTableauAmortissementLine[]
  exercisesLines: (string | CSSProperties)[][]
  echeanceOffset?: number
  inKiloEuro: boolean
}> = ({ lines, exercisesLines, echeanceOffset = 0, inKiloEuro }) => {
  return (
    <>
      <TableHeader>
        <span> Exercice </span>
        <span className={'echeanceNumber'}>Echéance</span>
        <span>Date</span>
        <span>Capital début</span>
        <span>Montant global</span>
        <span>Intérêts</span>
        <span>Amortissements</span>
        <span>Capital fin</span>
      </TableHeader>
      {lines.map((line, i) => (
        <AmortissementLine
          key={`${line.echeance}${line.capitalFin}${Math.random()}`}
          line={line}
          index={i}
          exerciseLine={exercisesLines[i][0] as string}
          echeanceOffset={echeanceOffset}
          inKiloEuro={inKiloEuro}
          background={exercisesLines[i][1] as CSSProperties}
        />
      ))}
    </>
  )
}

const hasPeriodStartedBeforeEcheance = (p: PeriodModel, echeance: string) =>
  p.startDate.isBefore(echeance) || p.startDate.isSame(echeance, 'day')

const hasPeriodEndedBetweenEcheances = (
  p: PeriodModel,
  previouscheance: string,
  nextEcheance: string
) => p.endDate.isBefore(nextEcheance) && p.endDate.isAfter(previouscheance)

export const PlanAmortissementTable: FC<{ pret: Pret | PretPasse }> = ({ pret }) => {
  const lines = pret.computedPret!!.tableauAmortissement

  const { anterieurPeriods, previsionelPeriods } = useContext(FinanciaryContext)
  const { noteSettings } = useContext(NoteContext)
  const { periodTitle } = useDictionnary()

  const echeanceGap = 12 / pret.yearlyPaymentNb
  const delayDuration = pret.delayed / echeanceGap

  const backgroundWhite = {
    backgroundColor: 'white',
  } as CSSProperties

  const backgroundGreen = {
    backgroundColor: 'var(--main-green-2)',
  } as CSSProperties

  const allPeriods = anterieurPeriods.concat(previsionelPeriods)

  const exercisesLines = lines.map((l, i) => {
    const previousLine = lines[i - 1]
    if (!previousLine) {
      const d = allPeriods
        .filter((p) => hasPeriodStartedBeforeEcheance(p, l.echeance))
        .sort(comparePeriodByDate)
        .reverse()[0]
      return d
        ? [
            periodTitle(d, previsionelPeriods.indexOf(d)),
            d.type === PeriodType.Previsionnel ? backgroundWhite : backgroundGreen,
          ]
        : ['Hors exercices définis', backgroundGreen]
    }
    const lastPeriod = allPeriods[allPeriods.length - 1]
    if (hasPeriodEndedBetweenEcheances(lastPeriod, previousLine.echeance, l.echeance)) {
      return [`Hors exercices définis`, backgroundWhite]
    }
    const d = allPeriods.find(
      (p) =>
        hasPeriodStartedBeforeEcheance(p, l.echeance) && p.startDate.isAfter(previousLine.echeance)
    )
    return d
      ? [
          periodTitle(d, previsionelPeriods.indexOf(d)),
          d.type === PeriodType.Previsionnel ? backgroundWhite : backgroundGreen,
        ]
      : ['', backgroundWhite]
  })

  return (
    <>
      <PretSubsectionTitle>Détail</PretSubsectionTitle>
      <PretCard>
        {pret.delayed > 0 && (
          <>
            <TableTitle>Différé</TableTitle>
            <SubTable
              lines={lines.slice(0, delayDuration)}
              exercisesLines={exercisesLines.slice(0, delayDuration)}
              inKiloEuro={noteSettings.kiloEuros}
            />
          </>
        )}

        <TableTitle>Remboursement du capital</TableTitle>
        <SubTable
          lines={lines.slice(delayDuration)}
          exercisesLines={exercisesLines.slice(delayDuration)}
          echeanceOffset={delayDuration}
          inKiloEuro={noteSettings.kiloEuros}
        />
      </PretCard>
    </>
  )
}
