import {
  PretsDocument,
  useCreatePretPasseMutation,
  useDeletePretPasseMutation,
  usePretsQuery,
  useUpdatePretPasseMutation,
} from '../../../../generated/graphql'
import { PretPasse, PretsQuery, PretsQueryVariables, UpdatePretPasseInput } from '@fa-metier/types'
import { removeTypeName } from '@fa-metier/commons'
import produce from 'immer'
import {
  areDurationAndDelayCompatible,
  isAllowedValue,
  isCompatibleWithEcheancePerYear,
} from '../PretsQueries'

const isAllowedStartingDate = (pret: PretPasse) => {
  if (pret.startingDate) {
    return []
  }
  return ['La date de décaissement doit être définie.']
}

export const validatePretPasse = (pret: PretPasse) => {
  const fieldsValid = {
    title: [],
    capital: [],
    annualRate: [],
    yearlyPaymentNb: isAllowedValue(pret.yearlyPaymentNb),
    duration: isCompatibleWithEcheancePerYear(pret, pret.duration).concat(
      areDurationAndDelayCompatible(pret, 'La durée hors différé doit être supérieure à 0.')
    ),
    delayed: isCompatibleWithEcheancePerYear(pret, pret.delayed).concat(
      areDurationAndDelayCompatible(pret, 'La durée totale doit être supérieure au différé.')
    ),
    startingDate: isAllowedStartingDate(pret),
    inFine: [],
  }
  const all = Object.values(fieldsValid)
    .map((field) => field.length === 0)
    .reduce((previous, current) => previous && current)
  return {
    all,
    ...fieldsValid,
  }
}

export const toPretPasseInput: (pretPasse: PretPasse) => UpdatePretPasseInput = (
  pretPasse: PretPasse
) => {
  return removeTypeName({
    title: pretPasse.title,
    capital: pretPasse.capital,
    annualRate: pretPasse.annualRate,
    yearlyPaymentNb: pretPasse.yearlyPaymentNb,
    duration: pretPasse.duration,
    delayed: pretPasse.delayed,
    startingDate: pretPasse.startingDate,
    firstRepaymentDate: pretPasse.firstRepaymentDate || pretPasse.startingDate,
    inFine: pretPasse.inFine,
  })
}

export const useGetPretsPasses = (noteId: string) => {
  const { data, loading } = usePretsQuery({ variables: { noteId } })
  return {
    loading,
    data: data
      ? {
          pretsPasses: data.pretsPasses.slice().sort((a: PretPasse, b: PretPasse) => +a.id - +b.id),
        }
      : undefined,
  }
}

export const useDeletePretPasse = (noteId: string, pretId: string) => {
  const [mutation] = useDeletePretPasseMutation({
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<PretsQuery, PretsQueryVariables>({
        query: PretsDocument,
        variables: { noteId },
      })!
      cache.writeQuery<PretsQuery, PretsQueryVariables>({
        query: PretsDocument,
        variables: { noteId },
        data: produce(readQuery, (draft) => {
          if (mutationResult.data?.pretPasse?.delete) {
            draft.pretsPasses = draft.pretsPasses.filter((pretPasse) => pretPasse.id !== pretId)
          }
        }),
      })
    },
  })
  return () => {
    return new Promise<boolean>((resolve, reject) => {
      mutation({
        variables: { pretId },
      }).then((response) => {
        const result = response.data?.pretPasse.delete
        result ? resolve(result) : reject()
      })
    })
  }
}

export const useUpdatePretPasse = (noteId: string, pretId: string) => {
  const [pretPasseMutation] = useUpdatePretPasseMutation()
  return (update: UpdatePretPasseInput) => {
    return new Promise<PretPasse>((resolve, reject) => {
      pretPasseMutation({
        variables: { pretId, update },
      })
        .then((response) => {
          const result = response.data && response.data.pretPasse.patch
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const useCreatePret = (noteId: string) => {
  const [pretMutation] = useCreatePretPasseMutation({
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<PretsQuery, PretsQueryVariables>({
        query: PretsDocument,
        variables: { noteId },
      })!
      cache.writeQuery<PretsQuery, PretsQueryVariables>({
        query: PretsDocument,
        variables: { noteId },
        data: produce(readQuery, (draft) => {
          if (mutationResult.data?.pretPasse?.create) {
            draft.pretsPasses = draft.pretsPasses.concat(mutationResult.data?.pretPasse?.create)
          }
        }),
      })
    },
  })
  return (title: string) => {
    return new Promise<PretPasse>((resolve, reject) => {
      pretMutation({
        variables: {
          input: { noteId, title },
        },
      })
        .then((response) => {
          const result = response.data && response.data.pretPasse.create
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}
