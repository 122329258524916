import React, { FC, Fragment, useContext, useRef, useState } from 'react'
import { NoteContext } from '../NotePage'
import { ProjectEvaluationContext } from '../context/ProjectEvaluationContext'
import {
  useGetScoringCreationCheck,
  useGetScoringDeveloppementCheck,
  useUpdateScoringCreation,
  useUpdateScoringDeveloppement,
} from './ScoringQueries'
import { Button, Classes, Dialog, Spinner, Toaster } from '@blueprintjs/core'
import { DialogTitle } from './Presentation'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'
import {
  GarantieParameters,
  Porteur,
  PorteurParameters,
  PretParameters,
  Scoring,
  ScoringCreationParameters,
  ScoringDeveloppementParameters,
  ScoringParameter,
  YodaOrigin,
} from '@fa-metier/types'
import { NoteLabel, NoteRatio, NoteTitle } from './Analyse'
import styled from 'styled-components'
import { NavLinkGreen } from '../Financiary/CR/CRForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getScoringType, ScoringType } from './ScoringType'

const ScoringOctroi = styled.div`
  flex: 1;
  background-color: var(--main-green-4);
  color: white;
  height: 200px;

  .bp3-toast {
    background-color: var(--main-red);
    color: white;

    .bp3-icon {
      color: white;
    }
  }
`

const DisplayScoreAndRation = styled.div`
  display: flex;
  flex-direction: row;
`

const DisplayScoreRatio = styled.div`
  border-color: white;
  border-left: 1px solid;
  margin-top: 21px;
  margin-bottom: 14px;
`

const ScoringButton = styled(Button)`
  margin-left: 30px;
  margin-top: 10px;
  color: var(--main-green-1) !important;
  font-weight: var(--bold);
`

const BlueTh = styled.th`
  background-color: var(--main-blue-2);
  font-weight: normal;
  width: 30%;
  height: 50px;
`

const CustomTbody = styled.tbody`
  tr {
    height: 40px;
  }

  td {
    padding: 8px;
  }
`

const SubCategory = styled.span`
  font-style: italic;
  font-weight: var(--bold);
`

const ScoringSpinner = styled(Spinner)`
  margin-left: 80px;
  justify-content: left;
`

export const ScoringOctroiComponent: FC = () => {
  const { note } = useContext(NoteContext)
  const { scoring } = useContext(ProjectEvaluationContext)

  const [openScoringPopup, setOpenScoringPopup] = useState(false)
  const [spinning, setSpinning] = useState(false)

  const updateCreationScoring = useUpdateScoringCreation(note.noteId)
  const updateDeveloppementScoring = useUpdateScoringDeveloppement(note.noteId)
  const { data: creationCheckData, loading: creationCheckLoading } = useGetScoringCreationCheck(
    note.noteId
  )
  const { data: developpementCheckData, loading: developpementCheckLoading } =
    useGetScoringDeveloppementCheck(note.noteId)

  const scoringType = getScoringType(note)

  const toasterRef = useRef<Toaster>(null)

  return (
    <ScoringOctroi>
      <NoteTitle>Score d'octroi</NoteTitle>
      <DisplayScoreAndRation>
        <NoteLabel>{scoring?.score ?? '-'}</NoteLabel>
        <DisplayScoreRatio>
          <NoteTitle style={{ fontSize: '14px', marginTop: '0px' }}>
            Probabilité de défaut :
          </NoteTitle>
          <NoteRatio>{`${scoring?.risqueRatio ?? '-'}%`}</NoteRatio>
        </DisplayScoreRatio>
      </DisplayScoreAndRation>
      {note.onlyPrime && (
        <NoteTitle style={{ fontSize: '14px', marginTop: '0px' }}>
          Il n'y a pas de risque de crédit sur les primes
        </NoteTitle>
      )}
      {!note.onlyPrime && (
        <>
          {!spinning && (
            <ScoringButton
              disabled={
                scoringType === ScoringType.Developpement
                  ? developpementCheckLoading || developpementCheckData === undefined
                  : creationCheckLoading || developpementCheckData === undefined
              }
              onClick={async () => {
                if (
                  (scoringType === ScoringType.Creation &&
                    creationCheckData!.allRequiredConditions) ||
                  (scoringType === ScoringType.Developpement &&
                    developpementCheckData!.allRequiredConditions)
                ) {
                  const updateFonction =
                    scoringType === ScoringType.Creation
                      ? updateCreationScoring
                      : updateDeveloppementScoring
                  setSpinning(true)
                  return await updateFonction().then((newScoring) => {
                    setSpinning(false)
                    if (Object.getOwnPropertyNames(newScoring.errors).length > 0) {
                      toasterRef.current?.show({
                        message: <ToastError scoring={newScoring} />,
                        timeout: 5000,
                      })
                    }
                  })
                }
                setOpenScoringPopup(true)
              }}
            >
              {scoring?.score ? 'Mettre à jour le score' : 'Calculer le score'}
            </ScoringButton>
          )}
          {spinning && <ScoringSpinner size={30} />}
        </>
      )}

      {((scoringType === ScoringType.Creation &&
        !(creationCheckLoading || creationCheckData === undefined)) ||
        (scoringType === ScoringType.Developpement &&
          !(developpementCheckLoading || developpementCheckData === undefined))) && (
        <Dialog
          isCloseButtonShown={true}
          title={
            <DialogTitle>
              <Arrow style={{ color: `var(--main-green-1)` }} /> Scoring d'octroi
            </DialogTitle>
          }
          isOpen={openScoringPopup}
          onClose={() => setOpenScoringPopup(false)}
          className={Classes.DIALOG}
          style={{ width: '80%', backgroundColor: 'white' }}
        >
          <ScoringPopupContent
            scoringType={scoringType}
            devParameters={developpementCheckData}
            creaParameters={creationCheckData}
          />
        </Dialog>
      )}
      <Toaster
        autoFocus={false}
        canEscapeKeyClear={true}
        position={'bottom'}
        usePortal={false}
        ref={toasterRef}
      />
    </ScoringOctroi>
  )
}

const ScoringPopupContent: FC<{
  scoringType: ScoringType
  devParameters?: ScoringDeveloppementParameters
  creaParameters?: ScoringCreationParameters
}> = ({ scoringType, devParameters, creaParameters }) => {
  return (
    <div style={{ marginBottom: '30px' }}>
      <div style={{ padding: '20px', color: 'var(--main-red)', fontSize: '16px', fontWeight: 600 }}>
        Le calcul du scoring n’est pas possible tant que les données obligatoires (surlignées en
        rouge ci-dessous) à celui-ci ne sont pas renseignées.
      </div>
      <table style={{ padding: '10px', width: '100%' }}>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <BlueTh>
              Données <b>Muffin</b>
            </BlueTh>
            <BlueTh>
              Données <b>Yoda</b>
            </BlueTh>
          </tr>
        </thead>
        <CustomTbody>
          <tr>
            <td>
              <SubCategory>Projet</SubCategory>
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          {scoringType === ScoringType.Creation ? (
            <ScoringCreationPopUpContent parameters={creaParameters!!} />
          ) : (
            <ScoringDeveloppementPopUpContent parameters={devParameters!!} />
          )}
        </CustomTbody>
      </table>
      {((scoringType === ScoringType.Creation && !creaParameters!!.apeCode.conditionOk) ||
        (scoringType === ScoringType.Developpement && !devParameters!!.apeCode.conditionOk)) && (
        <div style={{ padding: '12px', fontStyle: 'italic' }}>
          * Seuls les codes NAF du type 11.11A sont pris en compte pour le calcul du scoring
        </div>
      )}
    </div>
  )
}

const ScoringDeveloppementPopUpContent: FC<{ parameters: ScoringDeveloppementParameters }> = ({
  parameters,
}) => {
  return (
    <>
      <ScoringParameterSimpleLine param={parameters.minimumPret} />
      <ScoringParameterSimpleLine param={parameters.minimumAnterieur} />
      <ScoringParameterSimpleLine param={parameters.minimumPrevisionnel} />
      <ScoringParameterSimpleLine param={parameters.planFinancement} />
      <ScoringParameterSimpleLine param={parameters.statutCooperatif} />
      <ScoringParameterSimpleLine param={parameters.statutJuridique} />
      <ScoringParameterSimpleLine param={parameters.objectProjectCode} />
      <ScoringParameterSimpleLine param={parameters.apeCode} asterisk={true} />
      <ScoringParameterSimpleLine param={parameters.bilanN1} />
      <ScoringParameterSimpleLine param={parameters.bilanN2} />
      <ScoringOutilsLines
        pretsParameters={parameters.prets}
        garantiesParameters={parameters.garanties}
      />
    </>
  )
}

const ScoringCreationPopUpContent: FC<{ parameters: ScoringCreationParameters }> = ({
  parameters,
}) => {
  return (
    <>
      <ScoringParameterSimpleLine param={parameters.minimumPorteur} />
      <ScoringParameterSimpleLine param={parameters.minimumPret} />
      <ScoringParameterSimpleLine param={parameters.planFinancement} />
      <ScoringParameterSimpleLine param={parameters.statutCooperatif} />
      <ScoringParameterSimpleLine param={parameters.statutJuridique} />
      <ScoringParameterSimpleLine param={parameters.apeCode} asterisk={true} />

      <ScoringPorteursLines porteursParams={parameters.porteurs} />

      <ScoringOutilsLines
        pretsParameters={parameters.prets}
        garantiesParameters={parameters.garanties}
      />
    </>
  )
}

const ToastError: FC<{ scoring: Scoring }> = ({ scoring }) => {
  const buildErrorMessages = (errors: { [p: string]: any }) => {
    return Object.keys(errors).map((k) => `${k}: ${errors[k]}`)
  }

  const errorMessages = scoring.errors ? buildErrorMessages(scoring.errors) : []

  const ToastTitleContainer = styled.div`
    display: flex;
    justify-content: center;
  `

  return (
    <div>
      <ToastTitleContainer>
        <h4>Erreur lors du calcul du scoring:</h4>
      </ToastTitleContainer>
      <ul>
        {errorMessages.map((m) => (
          <li key={m}>{m}</li>
        ))}
      </ul>
    </div>
  )
}

const ScoringOutilsLines: FC<{
  pretsParameters: PretParameters[]
  garantiesParameters: GarantieParameters[]
}> = ({ pretsParameters, garantiesParameters }) => {
  return (
    <>
      {pretsParameters
        .filter((p) => p && !p.duree.conditionOk)
        .map((p) => (
          <Fragment key={p.outilId}>
            <tr>
              <td>
                <SubCategory>{p.name}</SubCategory>
              </td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <ScoringParameterSimpleLine param={p.capital} key={`${p.outilId}_capital`} />
            <ScoringParameterSimpleLine param={p.duree} key={`${p.outilId}_duree`} />
          </Fragment>
        ))}
      {garantiesParameters
        .filter((g) => g && !(g.duree.conditionOk && g.quotite.conditionOk))
        .map((g) => (
          <Fragment key={g.outilId}>
            <tr>
              <td>
                <SubCategory>{g.name}</SubCategory>
              </td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <ScoringParameterSimpleLine param={g.duree} key={`${g.outilId}_duree`} />
            <ScoringParameterSimpleLine param={g.quotite} key={`${g.outilId}_quotite`} />
          </Fragment>
        ))}
    </>
  )
}

const ScoringParameterSimpleLine: FC<{
  param: ScoringParameter
  asterisk?: boolean
}> = ({ param, asterisk = false }) => {
  if (param.conditionOk) {
    return <></>
  }
  return (
    <tr style={param.required ? { backgroundColor: 'var(--main-red-lighter)' } : {}}>
      <td style={{ paddingLeft: '30px' }}>{param.label}</td>
      <td>
        {param.origin === 'MUFFIN' ? 'Donnée à compléter dans Muffin' : ''}
        {asterisk ? '*' : ''}
      </td>
      <td>
        {param.origin === 'YODA' && <YodaNavLink yodaOrigin={param.yodaOrigin ?? undefined} />}
      </td>
    </tr>
  )
}

const YodaNavLink: FC<{ yodaOrigin?: YodaOrigin }> = ({ yodaOrigin }) => {
  switch (yodaOrigin) {
    case YodaOrigin.Prets:
      return (
        <NavLinkGreen to={'financiary/prets'}>
          Prêts{' '}
          <FontAwesomeIcon icon={['fas', 'external-link-alt']} color={'var(--main-green-1)'} />
        </NavLinkGreen>
      )
    case YodaOrigin.Pf:
      return (
        <NavLinkGreen to={'financiary/planFinancement'}>
          Plan de financement{' '}
          <FontAwesomeIcon icon={['fas', 'external-link-alt']} color={'var(--main-green-1)'} />
        </NavLinkGreen>
      )
    case YodaOrigin.Bilan:
      return (
        <NavLinkGreen to={'financiary/bilanCedant'}>
          Bilan passé{' '}
          <FontAwesomeIcon icon={['fas', 'external-link-alt']} color={'var(--main-green-1)'} />
        </NavLinkGreen>
      )
    case YodaOrigin.NoteParams:
      return (
        <NavLinkGreen to={'financiary/settings'}>
          Paramètres de la note{' '}
          <FontAwesomeIcon icon={['fas', 'external-link-alt']} color={'var(--main-green-1)'} />
        </NavLinkGreen>
      )
    default:
      return <></>
  }
}

const ScoringPorteursLines: FC<{ porteursParams: PorteurParameters[] }> = ({ porteursParams }) => {
  const { porteurs } = useContext(ProjectEvaluationContext)

  return (
    <>
      {porteursParams.map((porteurParams) => {
        const porteur = porteurs.find((p) => p.muffinId === porteurParams.idMuffin)!!
        return (
          <ScoringPorteurLines
            key={porteur.muffinId}
            porteurParams={porteurParams}
            porteur={porteur}
          />
        )
      })}
    </>
  )
}

const ScoringPorteurLines: FC<{ porteurParams: PorteurParameters; porteur: Porteur }> = ({
  porteurParams,
  porteur,
}) => {
  return (
    <>
      {(!porteurParams.niveauEtude.conditionOk ||
        !porteurParams.situationProfessionnelle.conditionOk ||
        !porteurParams.typeRevenu.conditionOk) && (
        <tr>
          <td>
            <SubCategory>
              {porteur.lastname} {porteur.firstname}
            </SubCategory>
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
      )}

      <ScoringParameterSimpleLine param={porteurParams.niveauEtude} />
      <ScoringParameterSimpleLine param={porteurParams.situationProfessionnelle} />
      <ScoringParameterSimpleLine param={porteurParams.typeRevenu} />
    </>
  )
}
