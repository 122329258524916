import React from 'react'
import { CRLink, PFLink } from '../Financiary/Utils/DifferencesDeSaisie'
import { NoteDictionnary } from './dictionnary'
import { PeriodModel } from '../Financiary/PeriodModel'

export const structureExistanteDictionnary: NoteDictionnary = {
  cr: {
    ongletName: 'Compte de résultat',
    pageTitle: 'Compte de résultat',
    linkText: 'Compte de résultat',
  },
  bfr: {
    menuLabel: 'BFR',
  },
  differencesSaisies: {
    targetTitle: 'Données saisies dans le compte de résultat',
    crExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <CRLink /> par celles issues de cette
        page.
      </div>
    ),
    crSuccessText: (
      <div>
        Vous pouvez consulter le <CRLink /> mis à jour.
      </div>
    ),
    pfExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <PFLink /> par celles issues de cette
        page.
      </div>
    ),
    pfSuccessText: (
      <div>
        Vous pouvez consulter le <PFLink /> mis à jour.
      </div>
    ),
    masseSalarialeMoreInformation: (
      <p>
        Attention, les données du <CRLink /> ont peut-être été calculées plus précisément !.
      </p>
    ),
  },
  noteSettings: {
    tvaTabLink: () => 'settings#tabsexercices-tva',
    tabExerciceTitle: 'Exercices',
    baseDateLabel: () => 'Date de clôture du dernier exercice comptable disponible',
  },
  periodTitle: (p: PeriodModel) =>
    p.startDate.year() === p.endDate.year()
      ? `${p.startDate.year()}`
      : `${p.startDate.year()}-${p.endDate.year()}`,
  bilan: {
    ongletName: 'Bilan passé',
    pageTitle: 'Bilan passé',
    linkText: 'Bilan passé',
  },
  exerciceCedantLabel: 'Exercices passés',
  commentAnterieurLabel: 'Commentaire - Comptes passés',
  benchmarchAnterieurPeriodLabel: 'Exercice passé',
  prets: {
    ongletName: 'Prêts futurs',
    pageTitle: 'Prêts futurs',
    linkText: 'Prêts futurs',
  },
  pretsPasse: {
    ongletName: 'Prêts passés',
    pageTitle: 'Prêts passés',
    linkText: 'Prêts passés',
  },
  pretTVADemarrage: {
    label: 'Crédits relais TVA',
    button: 'un crédit relais TVA',
  },
  noExerciceText: "Aucune date de clôture du dernier exercice n'a été renseignée",
  noExerciceLink:
    'Vous devez saisir une date de clôture du dernier exercice pour pouvoir créer vos exercices comptables dans l’onglet',
  evolutionPercentagePassedLabel: "% d'évolution des exercices passés",
}
