import React, { Dispatch, FC, SetStateAction, useContext } from 'react'
import { Apport, Pret, PretSynthese, Prime } from '@fa-metier/types'
import { CRFormWrapper, NavLinkGreen } from './CR/CRForm'
import { Route, Switch, useRouteMatch } from 'react-router'
import { PeriodModel } from './PeriodModel'
import { useGetPeriods } from './FinanciaryQueries'
import { BreadcrumbRenderer } from '@fa-metier/components'
import { Breadcrumbs } from '@blueprintjs/core'
import { NoteContext } from '../NotePage'
import { CafFormWrapper } from './Caf/CafForm'
import { UpdatePeriodForPath } from './Utils/PeriodsContext'
import { BfrFormWrapper } from './BFR/BfrForm'
import { useGetRessources } from './Prets/PretsQueries'
import { NoteSettingsPage } from '../NoteSettingsPage'
import { PretTabsWrapper } from './Prets/PretTabs'
import { InvestmentsFormWrapper } from './Investissements/InvestmentsForm'
import { MasseSalarialeFormWrapper } from './MasseSalariale/MasseSalarialeForm'
import { BenchMarkFormWrapper } from './Benchmark/BenchMarkForm'
import { PlanFinancementContainer } from './PlanFinancement/PlanFinancementContainer'
import { AppMetierCard } from './Utils/AppMetierCard'
import { YodaSpinnerPage } from '../../utils/YodaSpinnerPage'
import { BilanFormWrapper } from './Bilan/BilanForm'
import { useDictionnary } from '../dictionnary/dictionnary'
import { PretPasseTabsWrapper } from './Prets/PretsPasses/PretPasseTabs'
import { MuffinSynchronization } from '../../MuffinSynchronization/MuffinSynchronization'
import { Header } from '../NoteContainer'
import set from 'lodash/fp/set'

interface FinanciaryContextI {
  anterieurPeriods: PeriodModel[]
  previsionelPeriods: PeriodModel[]
  prets: Pret[]
  primes: Prime[]
  apports: Apport[]
  pretSynthese: PretSynthese
}

export const FinanciaryContext = React.createContext<FinanciaryContextI>({} as FinanciaryContextI)

interface FinanciaryWrapperContextI {
  anterieurPeriods: PeriodModel[]
  previsionelPeriods: PeriodModel[]
  situationIntermediaire?: PeriodModel
}

export const FinanciaryWrapperContext = React.createContext<FinanciaryWrapperContextI>(
  {} as FinanciaryWrapperContextI
)

export const updateInternalPeriods = (setPeriods: Dispatch<SetStateAction<PeriodModel[]>>) => {
  return (updateRequests: UpdatePeriodForPath[]) => {
    setPeriods((periods) =>
      periods.map((period) => {
        const periodUpdateRequests = updateRequests.filter((r) => r.periodId === period.id)
        if (periodUpdateRequests) {
          return periodUpdateRequests.reduce((acc, r) => set(r.fieldPath, r.change, acc), period)
        }
        return period
      })
    )
  }
}

export const updateInternalSituationIntermediaire = (
  setInternalSituationIntermediaire: Dispatch<SetStateAction<PeriodModel | undefined>>
) => {
  return (updateRequests: UpdatePeriodForPath[]) => {
    setInternalSituationIntermediaire((period) => {
      if (period) {
        const periodUpdateRequests = updateRequests.filter((r) => r.periodId === period.id)
        if (periodUpdateRequests) {
          return periodUpdateRequests.reduce((acc, r) => set(r.fieldPath, r.change, acc), period)
        }
        return period
      }
    })
  }
}

export const FinanciaryPageWrapper: FC = () => {
  const { path } = useRouteMatch()
  const { note, baseNotePath, noteName } = useContext(NoteContext)
  const noteId = note.noteId
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)
  const { loading: ressourcesLoading, data: ressources } = useGetRessources(noteId)
  const { noExerciceLink, noExerciceText } = useDictionnary()

  return (
    <>
      {(periodLoading || ressourcesLoading) && <YodaSpinnerPage />}
      {!periodLoading && !ressourcesLoading && (
        <FinanciaryWrapperContext.Provider
          value={{
            anterieurPeriods: periodsData?.anterieurs!!,
            previsionelPeriods: periodsData?.previsionnelles!!,
            situationIntermediaire: periodsData?.situationIntermediaire ?? undefined,
          }}
        >
          <Switch>
            {periodsData!!.previsionnelles.length > 0 && (
              <FinanciaryContext.Provider
                value={{
                  anterieurPeriods: periodsData!!.anterieurs,
                  previsionelPeriods: periodsData!!.previsionnelles,
                  prets: ressources!!.prets,
                  primes: ressources!!.primes,
                  apports: ressources!!.apports,
                  pretSynthese: ressources!!.pretSynthese,
                }}
              >
                <FinanciaryPage />
              </FinanciaryContext.Provider>
            )}
            <Route exact path={`${path}/settings`}>
              <Breadcrumbs
                items={[
                  { href: '/', text: 'Accueil' },
                  {
                    href: `${baseNotePath}/presentation`,
                    text: noteName,
                  },
                  { text: 'Paramètres', current: true },
                ]}
                breadcrumbRenderer={BreadcrumbRenderer}
              />
              <NoteSettingsPage />
            </Route>
            <Route>
              <FinanciaryBreadcrumb text={noExerciceText} />
              <AppMetierCard>
                {noExerciceLink} <NavLinkGreen to={`settings`}>Paramètres</NavLinkGreen>
              </AppMetierCard>
            </Route>
          </Switch>
        </FinanciaryWrapperContext.Provider>
      )}
    </>
  )
}

const FinanciaryBreadcrumb: FC<{ text: string }> = ({ text }) => {
  const { baseNotePath, noteName } = useContext(NoteContext)
  return (
    <Header>
      <Breadcrumbs
        items={[
          { href: '/', text: 'Accueil' },
          {
            href: `${baseNotePath}/presentation`,
            text: noteName,
          },
          { text: 'Volet Financier' },
          { text, current: true },
        ]}
        breadcrumbRenderer={BreadcrumbRenderer}
      />
      <MuffinSynchronization />
    </Header>
  )
}

const FinanciaryPage: FC = () => {
  // @ts-ignore
  const { path } = useRouteMatch()
  const { baseNotePath, noteName } = useContext(NoteContext)

  const dictionnary = useDictionnary()

  return (
    <>
      <Route exact path={path}>
        <h3>Hummm il n'y a rien ici</h3>
      </Route>
      <Route exact path={`${path}/cr`}>
        <FinanciaryBreadcrumb text={dictionnary.cr.ongletName} />
        <CRFormWrapper />
      </Route>
      <Route exact path={`${path}/bfr`}>
        <FinanciaryBreadcrumb text={'BFR'} />
        <BfrFormWrapper />
      </Route>
      <Route exact path={`${path}/caf`}>
        <FinanciaryBreadcrumb text={'CAF'} />
        <CafFormWrapper />
      </Route>
      <Route exact path={`${path}/prets`}>
        <FinanciaryBreadcrumb text={dictionnary.prets.ongletName} />
        <PretTabsWrapper />
      </Route>
      <Route exact path={`${path}/pretspasses`}>
        <FinanciaryBreadcrumb text={dictionnary.pretsPasse.ongletName} />
        <PretPasseTabsWrapper />
      </Route>
      <Route exact path={`${path}/investissements`}>
        <FinanciaryBreadcrumb text={'Investissements'} />
        <InvestmentsFormWrapper />
      </Route>
      <Route exact path={`${path}/masseSalariale`}>
        <FinanciaryBreadcrumb text={'Masse salariale'} />
        <MasseSalarialeFormWrapper />
      </Route>
      <Route exact path={`${path}/benchmark`}>
        <FinanciaryBreadcrumb text={'Benchmark'} />
        <BenchMarkFormWrapper />
      </Route>
      <Route exact path={`${path}/planFinancement`}>
        <FinanciaryBreadcrumb text={'Plan de financement'} />
        <PlanFinancementContainer />
      </Route>
      <Route exact path={`${path}/bilanCedant`}>
        <FinanciaryBreadcrumb text={dictionnary.bilan.ongletName} />
        <BilanFormWrapper />
      </Route>
      <Route exact path={`${path}/settings`}>
        <Breadcrumbs
          items={[
            { href: '/', text: 'Accueil' },
            {
              href: `${baseNotePath}/presentation`,
              text: noteName,
            },
            { text: 'Paramètres', current: true },
          ]}
          breadcrumbRenderer={BreadcrumbRenderer}
        />
        <NoteSettingsPage />
      </Route>
    </>
  )
}

export default FinanciaryPageWrapper
