import {
  BenchMarkDocument,
  useBenchMarkQuery,
  useUpdateBenchMarkMutation,
} from '../../../generated/graphql'
import {
  BenchMark,
  BenchMarkInput,
  BenchMarkPeriod,
  BenchMarkPeriodInput,
  BenchMarkQuery,
  BenchMarkQueryVariables,
  NumericData,
  NumericDataInput,
} from '@fa-metier/types'
import produce from 'immer'

export const useGetBenchMark = (noteId: string) => {
  const { data, loading } = useBenchMarkQuery({ variables: { noteId } })
  return {
    loading,
    data: data ? data.benchMark : undefined,
  }
}

export const useUpdateBenchMark = (noteId: string) => {
  const [benchMarkMutation] = useUpdateBenchMarkMutation({
    update: (cache, mutationResult) => {
      const query = cache.readQuery<BenchMarkQuery>({
        query: BenchMarkDocument,
        variables: { noteId },
      })!
      cache.writeQuery<BenchMarkQuery, BenchMarkQueryVariables>({
        query: BenchMarkDocument,
        variables: { noteId },
        data: produce(query, (draft) => {
          if (mutationResult.data?.benchMark) {
            draft.benchMark = mutationResult.data?.benchMark.update
          }
        }),
      })
    },
  })
  return (update: BenchMarkInput) => {
    return new Promise<BenchMark>((resolve, reject) => {
      benchMarkMutation({
        variables: { update },
      })
        .then((response) => {
          const result = response.data && response.data.benchMark?.update
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const toBenchMarkInput: (benchMark: BenchMark) => BenchMarkInput = (benchMark) => {
  return {
    noteId: benchMark.noteId,
    chiffreAffaire: toNumericDataInput(benchMark.chiffreAffaire),
    chiffreAffaireParPersonne: toNumericDataInput(benchMark.chiffreAffaireParPersonne),
    margeBrut: toNumericDataInput(benchMark.margeBrut),
    valeurAjoutee: toNumericDataInput(benchMark.valeurAjoutee),
    chargePersonnel: toNumericDataInput(benchMark.chargePersonnel),
    ebe: toNumericDataInput(benchMark.ebe),
    resultatFinancier: toNumericDataInput(benchMark.resultatFinancier),
    resultatCourant: toNumericDataInput(benchMark.resultatCourant),
    pointMort: toNumericDataInput(benchMark.pointMort),
    margeManoeuvre: toNumericDataInput(benchMark.margeManoeuvre),
    rotationStock: toNumericDataInput(benchMark.rotationStock),
    joursCa: toNumericDataInput(benchMark.joursCa),
    periods: benchMark.periods?.map((value) => toPeriodsInput(value!!)),
    comment: benchMark.comment,
  }
}

const toPeriodsInput: (periods: BenchMarkPeriod) => BenchMarkPeriodInput = (periods) => {
  return {
    effectifs: periods.effectifs!!,
    periodId: periods.periodId!!,
  }
}

const toNumericDataInput: (numericData: NumericData | undefined | null) => NumericDataInput | null =
  (numericData) => {
    return numericData
      ? {
          detail: numericData.detail,
          kiloEuros: numericData.kiloEuros,
          percentageReference: numericData.percentageReference,
          value: numericData.value,
        }
      : null
  }
