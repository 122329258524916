import React, { FC, useContext, useState } from 'react'
import { Button, Dialog, FormGroup, Icon, Intent } from '@blueprintjs/core'
import { getDayjsFormatter } from '../../../utils/DayjsFormatter'
import { PeriodModel } from '../PeriodModel'
import { useDeletePeriod, useInitPeriods, useUpdatePeriodsDates } from '../FinanciaryQueries'
import styled from 'styled-components'
import dayjs, { Dayjs } from 'dayjs'
import { DIALOG_BODY, DIALOG_FOOTER } from '@blueprintjs/core/lib/esm/common/classes'
import { AppMetierDateInput } from '../Utils/AppMetierInput/AppMetierDateInput'
import { NoteType, PeriodType } from '@fa-metier/types'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { NoteContext } from '../../NotePage'
import { FinanciaryWrapperContext } from '../Financiary'
import { endDateMutationModel } from './PeriodsForm'
import produce from 'immer'

export type UpdateDateType = 'startDate' | 'endDate'

interface ExerciseProps {
  noteId?: string
  removable: boolean
  periodIndex: number
  period: PeriodModel
  update: (index: string, date: dayjs.Dayjs, dateType: UpdateDateType) => any
  addIntermediaire?: boolean
}

const MyGreyCard = styled.div`
  &.anterieur {
    background-color: var(--main-green-2);
  }

  background-color: var(--main-light-grey-1);
  padding: 20px;
  margin: 0;
  width: 450px;
  height: 160px;
`

const PeriodTitle = styled.div`
  color: var(--main-green-1);
  font-style: normal;
  font-weight: var(--bold);
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  margin-bottom: 10px;
`

const PeriodDiv = styled.div`
  display: flex;
  flex-direction: row;
`

const PeriodFormGroup = styled(FormGroup)`
  margin-right: 1em;
`

const RemoveButton = styled(Button)`
  position: absolute;
  top: 5px;
  right: 5px;
`

interface DateUpdate {
  date: Dayjs
  type: UpdateDateType
}

const isInThePast = (date?: Dayjs) => {
  if (date?.isBefore(dayjs(Date.now()).toDate())) {
    return []
  }
  return ["doit être antérieur à aujourd'hui"]
}

export const validatePeriod = (date?: Dayjs) => {
  const fieldsValid = {
    dateDernierExo: isInThePast(date),
  }
  const all = Object.values(fieldsValid)
    .map((field) => field.length === 0)
    .reduce((previous, current) => previous && current)
  return {
    all,
    ...fieldsValid,
  }
}
export const Period: FC<ExerciseProps> = ({
  periodIndex,
  period,
  update,
  removable,
  addIntermediaire = false,
}) => {
  const { note, noteId } = useContext(NoteContext)
  const deleteMutation = useDeletePeriod(noteId!, period.id)
  const dictionnary = useDictionnary()

  const changeDate = (dateUpdate: DateUpdate) => update(period.id, dateUpdate.date, dateUpdate.type)
  const { situationIntermediaire } = useContext(FinanciaryWrapperContext)

  const initMutation = useInitPeriods(noteId!)
  const updateMutation = useUpdatePeriodsDates(noteId!)

  const removePeriod = () => deleteMutation()

  const [showPopupForRemove, setShowPopupForRemove] = useState(false)
  const [showIntermediaireDate, setShowIntermediaireDate] = useState(!!situationIntermediaire)
  const [showDeleteIntermediaire, setShowDeleteIntermediaire] = useState(!!situationIntermediaire)

  const manageSituationIntermediaire = (date: Date) => {
    const dateToUpdate = dayjs(date)
    if (!situationIntermediaire) {
      initMutation(dateToUpdate, PeriodType.Intermediaire).catch((error) => console.log(error))
    } else {
      const updatedSituationIntermediaire = produce(situationIntermediaire, (draft) => {
        draft.endDate = dateToUpdate
      })
      const updates = [endDateMutationModel(noteId!, updatedSituationIntermediaire)]
      updateMutation({ updates })
    }
  }

  const startDateDisabled =
    period.type === PeriodType.Previsionnel ||
    (note.type === NoteType.StructureExistante &&
      !(period.type === PeriodType.Anterieur && periodIndex === 0))

  const validStartDate = validatePeriod(period.startDate)
  const validEndDate = validatePeriod(period.endDate)

  return (
    <MyGreyCard className={period.type === PeriodType.Anterieur ? 'Period anterieur' : 'Period'}>
      <div>
        <PeriodTitle>{dictionnary.periodTitle(period, periodIndex)}</PeriodTitle>
        <PeriodDiv>
          <PeriodFormGroup
            label={'Début'}
            intent={
              period.type !== PeriodType.Anterieur || validStartDate.dateDernierExo.length === 0
                ? Intent.NONE
                : Intent.DANGER
            }
            helperText={
              period.type === PeriodType.Anterieur && validStartDate.dateDernierExo.join(' ')
            }
          >
            <AppMetierDateInput
              value={dayjs(period.startDate).format('YYYY-MM-DD')}
              maxDate={period.endDate.subtract(1, 'day').toDate()}
              disabled={startDateDisabled}
              onChange={(date, isUserChange) => {
                const dayjsDate = dayjs(date)
                return isUserChange &&
                  (period.type !== PeriodType.Anterieur || validatePeriod(dayjsDate).all)
                  ? changeDate({
                      date: dayjsDate,
                      type: 'startDate',
                    })
                  : {}
              }}
              initialMonth={period.startDate.toDate()}
              closeOnSelection={true}
              canClearSelection={false}
              invalidDateMessage={"La date n'est pas valide"}
              {...getDayjsFormatter('DD/MM/YYYY')}
            />
          </PeriodFormGroup>
          <PeriodFormGroup
            label={'Fin'}
            intent={
              period.type !== PeriodType.Anterieur || validEndDate.dateDernierExo.length === 0
                ? Intent.NONE
                : Intent.DANGER
            }
            helperText={
              period.type === PeriodType.Anterieur && validEndDate.dateDernierExo.join(' ')
            }
          >
            <AppMetierDateInput
              minDate={period.startDate.add(1, 'day').toDate()}
              value={dayjs(period.endDate).format('YYYY-MM-DD')}
              maxDate={
                period.type === PeriodType.Anterieur ? dayjs(Date.now()).toDate() : undefined
              }
              disabled={
                period.type === PeriodType.Anterieur && note.type !== NoteType.StructureExistante
              }
              onChange={(date, isUserChange) => {
                const dayjsDate = dayjs(date)
                return isUserChange &&
                  (period.type !== PeriodType.Anterieur || validatePeriod(dayjsDate).all)
                  ? changeDate({
                      date: dayjsDate,
                      type: 'endDate',
                    })
                  : {}
              }}
              initialMonth={period.endDate.toDate()}
              closeOnSelection={true}
              canClearSelection={false}
              invalidDateMessage={"La date n'est pas valide"}
              {...getDayjsFormatter('DD/MM/YYYY')}
            />
          </PeriodFormGroup>
        </PeriodDiv>
        {addIntermediaire && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {showIntermediaireDate ? (
              <>
                <div>Situation intermédiaire :</div>
                <div style={{ width: '165px' }}>
                  <AppMetierDateInput
                    minDate={period.startDate.add(1, 'day').toDate()}
                    maxDate={period.endDate.subtract(1, 'day').toDate()}
                    value={(
                      situationIntermediaire?.endDate ?? period.startDate.add(1, 'day')
                    ).format('YYYY-MM-DD')}
                    onChange={(selectedDate) =>
                      manageSituationIntermediaire(dayjs(selectedDate).toDate())
                    }
                    closeOnSelection={true}
                    canClearSelection={true}
                    invalidDateMessage={"La date n'est pas valide"}
                    customCSS={{ height: '22px', marginLeft: '14px', width: '80%' }}
                    {...getDayjsFormatter('DD/MM/YYYY')}
                  />
                </div>
              </>
            ) : (
              <div>
                Ajouter une situation intermédiaire &nbsp;{' '}
                <Icon
                  icon={'add'}
                  color={'var(--main-green-1)'}
                  title={'Ajouter'}
                  htmlTitle={'Ajouter'}
                  onClick={() => {
                    setShowIntermediaireDate(!showIntermediaireDate)
                    setShowDeleteIntermediaire(true)
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            )}
            {showDeleteIntermediaire && situationIntermediaire && (
              <RemoveSituationIntermediaireButton
                situationIntermediaire={situationIntermediaire!!}
                afterRemoveFct={() => {
                  setShowIntermediaireDate(false)
                  setShowDeleteIntermediaire(false)
                }}
              />
            )}
          </div>
        )}
        {removable && (
          <RemoveButton
            id="removeButton"
            onClick={() => setShowPopupForRemove(!showPopupForRemove)}
            icon={'trash'}
            minimal={true}
            style={{ color: 'var(--main-grey-2)' }}
          />
        )}
      </div>

      <Dialog isOpen={showPopupForRemove} title={'Attention'} isCloseButtonShown={false}>
        <div className={DIALOG_BODY}>
          <div>
            Si vous avez déjà commencé à remplir le CR, le BFR, la TVA ou la CAF pour cet exercice,
            ces données seront perdues définitivement à la suppression de l'exercice. Etes-vous
            certain de vouloir supprimer cet exercice malgré tout ?
          </div>
          <div>Vous ne pourrez pas revenir en arrière.</div>
        </div>
        <div className={DIALOG_FOOTER}>
          <Button
            style={{ marginRight: '0.5em' }}
            onClick={() => {
              setShowPopupForRemove(false)
            }}
          >
            Annuler
          </Button>
          <Button intent={'primary'} onClick={() => removePeriod()}>
            Valider
          </Button>
        </div>
      </Dialog>
    </MyGreyCard>
  )
}

const RemoveSituationIntermediaireButton: FC<{
  situationIntermediaire: PeriodModel
  afterRemoveFct: () => any
}> = ({ situationIntermediaire, afterRemoveFct }) => {
  const { noteId } = useContext(NoteContext)
  const deleteSituationIntermediaireMutation = useDeletePeriod(noteId, situationIntermediaire.id)
  const removeSituationIntermediaire = () => deleteSituationIntermediaireMutation()

  const [showPopupForRemoveSituationIntermediaire, setShowPopupForRemoveSituationIntermediaire] =
    useState(false)

  return (
    <>
      <Icon
        icon={'trash'}
        color={'var(--main-grey-1)'}
        title={'Supprimer'}
        htmlTitle={'Supprimer'}
        onClick={() =>
          setShowPopupForRemoveSituationIntermediaire(!showPopupForRemoveSituationIntermediaire)
        }
        style={{ cursor: 'pointer', paddingTop: '3px' }}
      />
      <Dialog
        isOpen={showPopupForRemoveSituationIntermediaire}
        title={'Attention'}
        isCloseButtonShown={false}
      >
        <div className={DIALOG_BODY}>
          <div>
            Si vous avez déjà commencé à remplir le CR, la CAF, le BFR ou le bilan pour la situation
            intermédiaire ces données seront perdues définitivement à la suppression de la situation
            intermédiaire. Etes-vous certain de vouloir supprimer la situation intermédiaire malgré
            tout ?
          </div>
          <div>Vous ne pourrez pas revenir en arrière.</div>
        </div>
        <div className={DIALOG_FOOTER}>
          <Button
            style={{ marginRight: '0.5em' }}
            onClick={() => {
              setShowPopupForRemoveSituationIntermediaire(false)
            }}
          >
            Annuler
          </Button>
          <Button
            intent={'primary'}
            onClick={() => {
              setShowPopupForRemoveSituationIntermediaire(false)
              removeSituationIntermediaire()
              afterRemoveFct()
            }}
          >
            Valider
          </Button>
        </div>
      </Dialog>
    </>
  )
}
