import {
  Entrepreneur,
  HouseHold,
  PaCategoryData,
  PaEntrepreneurAnswer,
  PaSubCategory,
} from '@fa-metier/types'
import { notEmpty, notEmptyString } from '@fa-metier/commons'

export function generalInformationFilled(entrepreneur: Entrepreneur): boolean {
  const { idMuffin, houseHold } = entrepreneur

  return notEmptyString(idMuffin) && notEmpty(houseHold) && notEmpty(houseHold.childNumber)
}

export function motivationsFilled(
  responses: PaEntrepreneurAnswer[],
  categoryData: PaCategoryData
): boolean {
  return questionAnswersFilled(responses, categoryData, PaSubCategory.Motivations)
}

export function analyzeMotivationsFilled(
  responses: PaEntrepreneurAnswer[],
  categoryData: PaCategoryData
): boolean {
  return questionAnswersFilled(responses, categoryData, PaSubCategory.AnalyzeMotivations)
}

export function financesAnalyzeFilled(
  responses: PaEntrepreneurAnswer[],
  categoryData: PaCategoryData
): boolean {
  return questionAnswersFilled(responses, categoryData, PaSubCategory.AnalyzeFinances)
}

export function houseHoldFilled(houseHold: HouseHold): boolean {
  return (
    (notEmpty(houseHold.expenses) && houseHold.expenses.length > 0) ||
    (notEmpty(houseHold.incomes) && houseHold.incomes.length > 0)
  )
}

export function financesEnvironmentFilled(
  entrepreneur: Entrepreneur,
  categoryData: PaCategoryData
): boolean {
  const questionAnswers: boolean = questionAnswersFilled(
    entrepreneur.responses,
    categoryData,
    PaSubCategory.Savings
  )

  const { houseHold } = entrepreneur

  return (
    questionAnswers || notEmpty(houseHold.childInCharge) || notEmpty(houseHold.otherPersonInCharge)
  )
}

export function synthesisFilled(
  responses: PaEntrepreneurAnswer[],
  categoryData: PaCategoryData
): boolean {
  return questionAnswersFilled(responses, categoryData, PaSubCategory.Synthesis)
}

function questionAnswersFilled(
  responses: PaEntrepreneurAnswer[],
  categoryData: PaCategoryData,
  category: PaSubCategory
): boolean {
  const motivationsQuestionsId: Array<String> = categoryData.subcategories
    .find((sc) => sc.subCategory === category)!
    .questions.map((qu) => String(qu.id))

  return motivationsQuestionsId.some((questionId) => {
    const associatedResponse = responses.find((response) => response.questionId === questionId)
    return notEmpty(associatedResponse)
  })
}
