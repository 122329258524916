import { AmortissementLine, Investissements } from '@fa-metier/types'
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react'
import { InvestmentsContext } from './InvestmentsContext'
import { AiosSuggest, NumberText } from '@fa-metier/components'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import { AppMetierNumberInput } from '../Utils/AppMetierInput/AppMetierNumberInput'
import { AppMetierDateInput } from '../Utils/AppMetierInput/AppMetierDateInput'
import { formatDayjs, getDayjsFormatter } from '../../../utils/DayjsFormatter'
import dayjs from 'dayjs'
import { FormulaDescriptionPopover } from '../Formulas/FormulaDescriptionPopover'
import { FormulaPopoverContent } from '../Formulas/FormulaPopover'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { SelectTva } from '../Fiscalite/TvaSelect'
import { Button } from '@blueprintjs/core'

type InvestmentType = 'Corporel' | 'Incorporel' | 'Financier'

const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY'
const SERVER_DATE_FORMAT = 'YYYY-MM-DD'

interface InvestmentLineProps {
  suggestedLabels: string[]
  setInvestissements: Dispatch<SetStateAction<Investissements>>
  computationInProgress: boolean
  resetDebounceTimers: () => void
  type: InvestmentType
  line: AmortissementLine
  minDate: Date | undefined
  maxDate: Date | undefined
  deletable?: boolean
  showComputations: boolean
  showInKiloEuros: boolean
  unsavedChanges: boolean
}

export const InvestmentLine: FC<InvestmentLineProps> = ({
  suggestedLabels,
  setInvestissements,
  computationInProgress,
  type,
  line,
  minDate,
  maxDate,
  deletable = true,
  showComputations,
  showInKiloEuros,
  resetDebounceTimers,
  unsavedChanges = false,
}) => {
  const { previsionelPeriods } = useContext(InvestmentsContext)
  const [lineState, setLineState] = useState(line)

  const [hovered, setHovered] = useState(false)

  const updateInvestmentLine = (updatedLine: AmortissementLine) => {
    setInvestissements((investments) => ({
      ...investments,
      ...(type === 'Incorporel' && {
        investissementsIncorporels: {
          ...investments.investissementsIncorporels,
          lines: investments.investissementsIncorporels.lines.map((l) => {
            return l.id === line.id ? updatedLine : l
          }),
        },
      }),
      ...(type === 'Corporel' && {
        investissementsCorporels: {
          ...investments.investissementsCorporels,
          lines: investments.investissementsCorporels.lines.map((l) => {
            return l.id === line.id ? updatedLine : l
          }),
        },
      }),
      ...(type === 'Financier' && {
        investissementsFinanciers: {
          ...investments.investissementsFinanciers,
          lines: investments.investissementsFinanciers.lines.map((l) => {
            return l.id === line.id ? updatedLine : l
          }),
        },
      }),
    }))
  }

  const deleteInvestmentLine = () => {
    setInvestissements((investments) => ({
      ...investments,
      ...(type === 'Incorporel' && {
        investissementsIncorporels: {
          ...investments.investissementsIncorporels,
          lines: investments.investissementsIncorporels.lines.filter((l) => l.id !== line.id),
        },
      }),
      ...(type === 'Corporel' && {
        investissementsCorporels: {
          ...investments.investissementsCorporels,
          lines: investments.investissementsCorporels.lines.filter((l) => l.id !== line.id),
        },
      }),
      ...(type === 'Financier' && {
        investissementsFinanciers: {
          ...investments.investissementsFinanciers,
          lines: investments.investissementsFinanciers.lines.filter((l) => l.id !== line.id),
        },
      }),
    }))
  }

  useEffect(() => {
    if (lineState !== line) {
      updateInvestmentLine(lineState)
    }
  }, [lineState])

  return (
    <tr
      className={deletable ? 'hoverable' : ''}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <td className={'suggest'}>
        <AiosSuggest
          className={'suggest'}
          suggestions={suggestedLabels}
          onChange={(event) => {
            resetDebounceTimers()
            setLineState({ ...lineState, label: event })
          }}
          value={lineState.label}
          disabled={computationInProgress}
        />
      </td>
      <td>
        <NumericDataInput
          numericData={line.montant!!}
          onChange={(numericData) => {
            resetDebounceTimers()
            if (numericData) {
              setLineState({ ...line, montant: numericData })
            }
          }}
          showComputations={showComputations}
          isCalculated={computationInProgress}
          isEuro={true}
        />
      </td>
      <td>
        <AppMetierNumberInput
          value={lineState.dureeAmortissement}
          onChange={(value) => {
            resetDebounceTimers()
            if (value || value === 0) {
              setLineState({ ...line, dureeAmortissement: value })
            }
          }}
          suffix={
            lineState.dureeAmortissement > 1
              ? 'ans'
              : lineState.dureeAmortissement === 1
              ? 'an'
              : ''
          }
          onlyInteger={true}
          disabled={computationInProgress}
        />
      </td>
      <td>
        <AppMetierDateInput
          placeholder={DISPLAY_DATE_FORMAT}
          value={lineState.dateAchat}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(selectedDate, isUserChange) => {
            resetDebounceTimers()
            if (isUserChange) {
              const updatedDate = formatDayjs(dayjs(selectedDate), SERVER_DATE_FORMAT)
              setLineState({ ...line, dateAchat: updatedDate })
            }
          }}
          {...getDayjsFormatter(DISPLAY_DATE_FORMAT)}
          disabled={computationInProgress}
        />
      </td>
      {line.amortissementsExercices?.map((value, j) => (
        <td key={`investissements_amortissements_${line.id}_${j}`}>
          <FormulaDescriptionPopover
            content={
              <FormulaPopoverContent calculatedData={value} suffix={eurosSuffix(showInKiloEuros)} />
            }
            position={'bottom'}
          >
            <div style={{ textAlign: 'end' }}>
              <span>
                <NumberText
                  value={value.numericData.value}
                  suffix={eurosSuffix(showInKiloEuros)}
                  decimalScale={0}
                />
              </span>
            </div>
          </FormulaDescriptionPopover>
        </td>
      )) ??
        previsionelPeriods.map((_, j) => (
          <td key={`investissements_amortissements_${line.id}_${j}`} />
        ))}
      <td className={'select-cell'}>
        <SelectTva
          value={lineState.tvaRate}
          onClick={(valueAsNumber) => {
            if (unsavedChanges) {
              resetDebounceTimers()
              setLineState({ ...line, tvaRate: valueAsNumber })
            }
          }}
          onChange={(valueAsNumber) => {
            resetDebounceTimers()
            if (lineState.tvaRate !== valueAsNumber) {
              setLineState({ ...line, tvaRate: valueAsNumber })
            }
          }}
          disabled={computationInProgress}
        />
      </td>
      <td>
        <FormulaDescriptionPopover
          content={
            <FormulaPopoverContent
              calculatedData={line.montantTva}
              suffix={eurosSuffix(showInKiloEuros)}
            />
          }
          position={'bottom'}
        >
          <div style={{ textAlign: 'end' }}>
            <span>
              <NumberText
                value={line.montantTva.numericData.value}
                suffix={eurosSuffix(showInKiloEuros)}
                decimalScale={0}
              />
            </span>
          </div>
        </FormulaDescriptionPopover>
      </td>
      <td className={'ttc'}>
        <FormulaDescriptionPopover
          content={
            <FormulaPopoverContent
              calculatedData={line.montantTTC}
              suffix={eurosSuffix(showInKiloEuros)}
            />
          }
          position={'bottom'}
        >
          <div style={{ textAlign: 'end' }}>
            <span>
              <NumberText
                value={line.montantTTC.numericData.value}
                suffix={eurosSuffix(showInKiloEuros)}
                decimalScale={0}
              />
            </span>
          </div>
        </FormulaDescriptionPopover>
      </td>
      <td className={'action'}>
        {deletable && hovered && (
          <Button
            icon={'trash'}
            minimal
            large={false}
            onClick={() => {
              resetDebounceTimers()
              deleteInvestmentLine()
            }}
            disabled={computationInProgress}
          />
        )}
      </td>
    </tr>
  )
}

export const EmptyInvestmentLine: FC<{ periodsNumber: number }> = ({ periodsNumber }) => {
  return (
    <tr>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td colSpan={4 + periodsNumber} />
      <td className={'ttc'} />
      <td />
    </tr>
  )
}
