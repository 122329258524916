import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Scrollbars } from 'react-custom-scrollbars'
import { Block, Item, ItemDescription, ItemIcon, ItemTextBlock, ItemTitle, Time } from './Styled'
import { TermsOfUse } from '@fa-metier/types'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { Divider } from '@blueprintjs/core'

dayjs.extend(utc)
dayjs.extend(timezone)

const LOCALE = 'FR-fr'
const TIMEZONE = 'Europe/Paris'

const options: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  timeZone: TIMEZONE,
}

type PopoverContentProps = {
  termOfUse: TermsOfUse
  showComiteSimplifie: boolean
  comiteSimplifie: string
}

const PopoverContent: FC<PopoverContentProps> = ({
  termOfUse: { lastCheckDate, termsNotMet },
  showComiteSimplifie,
  comiteSimplifie,
}) => {
  const toDateString = () => {
    return lastCheckDate
      ? Intl.DateTimeFormat(LOCALE, options).format(dayjs.utc(lastCheckDate).tz(TIMEZONE).toDate())
      : ''
  }
  return (
    <Block>
      <Time>
        {lastCheckDate && (
          <>
            <FontAwesomeIcon icon={'clock'} /> {toDateString()}
          </>
        )}
      </Time>
      <Scrollbars style={{ width: '376px' }} autoHeight={true} autoHeightMax={600}>
        {termsNotMet?.map((it, index) => (
          <Item key={index}>
            <ItemIcon>
              <FontAwesomeIcon icon={'exclamation-triangle'} fontSize={'21'} />
            </ItemIcon>
            <ItemTextBlock>
              <ItemTitle>{it?.ressource?.label}</ItemTitle>
              {it?.messages?.map((msg, indexMsg) => (
                <ItemDescription key={indexMsg}>{msg}</ItemDescription>
              ))}
            </ItemTextBlock>
          </Item>
        ))}
        {showComiteSimplifie && (
          <>
            {termsNotMet && termsNotMet?.length > 0 && <Divider />}
            <ItemTextBlock>
              <ItemTitle>Eligibilité au comité individuel</ItemTitle>
              <div style={{ marginLeft: '30px' }}>{comiteSimplifie}</div>
            </ItemTextBlock>
          </>
        )}
      </Scrollbars>
    </Block>
  )
}

export default PopoverContent
