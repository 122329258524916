import styled from 'styled-components'

export const ParametersDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  span {
    white-space: nowrap;
  }

  .bp3-control {
    margin: 1em;
  }
`
