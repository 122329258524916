import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'

const DEFAULT_FORMAT = 'dddd DD MMMM à HH:mm'

dayjs.extend(utc)
dayjs.extend(Timezone)

export const localBrowserDayjs = (date: string, format?: string) => {
  return dayjs
    .utc(date)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format(format || DEFAULT_FORMAT)
}
