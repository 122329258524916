import React, { FC, useContext, useEffect, useState } from 'react'
import { SolidButton } from '@fa-metier/components/dist/Buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import {
  FinancialPeriodsDocument,
  NoteDocument,
  PlanFinancementDocument,
  PorteursDocument,
  ProjectDocument,
  RessourcesDocument,
  ScoringCreationCheckDocument,
  ScoringDeveloppementCheckDocument,
  useSyncMuffinDataMutation,
} from '../generated/graphql'
import { Intent } from '@blueprintjs/core'
import { AppToaster } from '../utils/AppToaster'
import { withFieldsGuard } from '../utils/utils'
import './MuffinSynchronization.scss'
import { localBrowserDayjs } from '../utils/date'
import { NoteContext } from '../Project/NotePage'
import { getScoringType, ScoringType } from '../Project/ProjectHub/ScoringType'

export const MuffinSynchronization: FC = () => {
  const { note, unsavedChanges, muffinSyncInProgress, setMuffinSyncInProgress } =
    useContext(NoteContext)
  const noteId = note.noteId
  const [syncMuffinData, { data, loading: syncInProgress }] = useSyncMuffinDataMutation({
    variables: { noteId },
    refetchQueries: [
      { query: RessourcesDocument, variables: { noteId } },
      { query: NoteDocument, variables: { noteId } },
      { query: ProjectDocument, variables: { projectId: note.structureId } },
      { query: PorteursDocument, variables: { noteId } },
      { query: FinancialPeriodsDocument, variables: { noteId } },
      { query: PlanFinancementDocument, variables: { noteId } },
      getScoringType(note) === ScoringType.Creation
        ? { query: ScoringCreationCheckDocument, variables: { noteId } }
        : { query: ScoringDeveloppementCheckDocument, variables: { noteId } },
    ],
    awaitRefetchQueries: true,
  })

  const [abandonedNote, setAbandonedNote] = useState(isAbandonStatus(note.statut))

  const [buttonSubLabel, setButtonSubLabel] = useState(
    getInitialButtonSubLabel(note.statut, note.lastSyncDate)
  )

  const onBtnClick = async () => {
    try {
      await withFieldsGuard(
        () => syncMuffinData({ variables: { noteId } }),
        setMuffinSyncInProgress
      )
    } catch (_) {
      AppToaster.show({
        message: `Une erreur est survenue lors de la synchronisation avec Muffin`,
        intent: Intent.DANGER,
      })
    }
  }

  useEffect(() => {
    const lastSyncDate = data?.note?.syncMuffinData?.lastSyncDate
    const noteStatus = data?.note?.syncMuffinData?.noteStatus
    if (lastSyncDate) {
      setButtonSubLabel(
        `Dernière synchronisation le ${localBrowserDayjs(lastSyncDate, 'DD/MM/YYYY à HH:mm')}`
      )
    }

    if (noteStatus && isAbandonStatus(noteStatus)) {
      setAbandonedNote(true)
      setButtonSubLabel('Note abandonnée')
    }
  }, [data])

  return (
    <SolidButton
      onClick={onBtnClick}
      disabled={abandonedNote || muffinSyncInProgress || unsavedChanges}
    >
      <FontAwesomeIcon
        icon={abandonedNote ? faCircleXmark : faRotateRight}
        fontSize={20}
        spin={syncInProgress}
      />
      <div className={'button-text-block'}>
        <div className={'button-title'}>Synchronisation Muffin</div>
        <div className={'button-subtitle warning'}>{buttonSubLabel}</div>
      </div>
    </SolidButton>
  )
}

const isAbandonStatus = (status: string) => status === 'ABANDON'

const getInitialButtonSubLabel = (
  noteStatus: string,
  noteLastSyncDate: string | null | undefined
) => {
  if (isAbandonStatus(noteStatus)) {
    return 'Note abandonnée'
  }
  if (noteLastSyncDate) {
    return `Dernière synchronisation le ${localBrowserDayjs(
      noteLastSyncDate,
      'DD/MM/YYYY à HH:mm'
    )}`
  }
  return ''
}
