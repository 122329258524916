import {
  BenchMarkDocument,
  FinancialPeriodsDocument,
  InvestissementsDocument,
  MasseSalarialeDocument,
  NoteDocument,
  ProjectAnalysisDocument,
  RatiosDocument,
  RessourcesDocument,
  ScoringCreationCheckDocument,
  useCreatePeriodMutation,
  useDeletePeriodMutation,
  useFinancialPeriodsQuery,
  useInitFinancialPeriodsMutation,
  useUpdatePeriodsDatesMutation,
  useUpdatePeriodsMutation,
} from '../../generated/graphql'
import produce from 'immer'
import { fromFinancialPeriod, PeriodModel } from './PeriodModel'
import { Dayjs } from 'dayjs'
import { toIso } from './IncomeStatementMapper'
import {
  AllPeriods,
  FinancialPeriod,
  FinancialPeriodsQuery,
  FinancialPeriodsQueryVariables,
  PeriodType,
  UpdatePeriodsDatesMutationVariables,
  UpdatePeriodsMutationVariables,
} from '@fa-metier/types'
import { AppToaster } from '../../utils/AppToaster'
import { Intent } from '@blueprintjs/core'

export const useGetPeriods = (noteId: string) => {
  const { data, loading, error } = useFinancialPeriodsQuery({ variables: { noteId } })

  if (error) {
    AppToaster.show({
      message: `Une erreur est survenue lors du chargement périodes financières`,
      intent: Intent.DANGER,
    })
  }

  return {
    loading,
    data:
      data !== null && data !== undefined
        ? manageAllPeriods(data.financialPeriods!!)
        : { anterieurs: [], previsionnelles: [], situationIntermediaire: undefined },
  }
}

const mapAndSortPeriods = (periods: FinancialPeriod[]) => {
  return periods
    .map((period) => fromFinancialPeriod(period))
    .slice()
    .sort((period1: PeriodModel, period2: PeriodModel) =>
      period1.endDate.isBefore(period2.endDate) ? -1 : 1
    )
}

const manageAllPeriods = (periods: AllPeriods) => {
  return {
    anterieurs: mapAndSortPeriods(periods.anterieurs),
    previsionnelles: mapAndSortPeriods(periods.previsionnelles),
    situationIntermediaire: periods.situationIntermediaire
      ? fromFinancialPeriod(periods.situationIntermediaire)
      : undefined,
  }
}

export const useUpdatePeriods = (noteId: string) => {
  const [periodsMutation] = useUpdatePeriodsMutation({
    update: (cache) => {
      cache.evictQueries([
        { query: InvestissementsDocument, variables: { noteId } },
        { query: FinancialPeriodsDocument, variables: { noteId } },
        { query: RatiosDocument, variables: { noteId } },
        { query: RessourcesDocument, variables: { noteId } },
        { query: ProjectAnalysisDocument, variables: { noteId } },
        { query: NoteDocument, variables: { noteId } },
        { query: ScoringCreationCheckDocument, variables: { noteId } },
        { query: BenchMarkDocument, variables: { noteId } },
      ])
    },
    refetchQueries: [
      { query: InvestissementsDocument, variables: { noteId } },
      { query: FinancialPeriodsDocument, variables: { noteId } },
      { query: RatiosDocument, variables: { noteId } },
      { query: RessourcesDocument, variables: { noteId } },
      { query: ProjectAnalysisDocument, variables: { noteId } },
      { query: NoteDocument, variables: { noteId } },
      { query: ScoringCreationCheckDocument, variables: { noteId } },
      { query: BenchMarkDocument, variables: { noteId } },
    ],
    awaitRefetchQueries: true,
  })
  return (periods: UpdatePeriodsMutationVariables) => {
    return new Promise<PeriodModel[]>((resolve, reject) => {
      periodsMutation({
        variables: periods,
      })
        .then((response) => {
          const result = response.data && response.data.financialPeriod.patchMultiple
          result ? resolve(result.map(fromFinancialPeriod)) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const useUpdatePeriodsDates = (noteId: string) => {
  const [periodsMutation] = useUpdatePeriodsDatesMutation({
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<FinancialPeriodsQuery, FinancialPeriodsQueryVariables>({
        query: FinancialPeriodsDocument,
        variables: { noteId },
      })!
      cache.writeQuery<FinancialPeriodsQuery, FinancialPeriodsQueryVariables>({
        query: FinancialPeriodsDocument,
        variables: { noteId },
        data: produce(readQuery, (draft) => {
          if (draft.financialPeriods && mutationResult.data) {
            const returnData = mutationResult.data!.financialPeriod.patchMultipleDates
            draft.financialPeriods.anterieurs = draft.financialPeriods.anterieurs.filter(
              (p) => !returnData.some((r) => r.id === p.id)
            )
            draft.financialPeriods.previsionnelles = draft.financialPeriods.previsionnelles.filter(
              (p) => !returnData.some((r) => r.id === p.id)
            )
            draft.financialPeriods.situationIntermediaire =
              returnData.find((p) => p.periodType === PeriodType.Intermediaire) ??
              draft.financialPeriods.situationIntermediaire
            draft.financialPeriods.anterieurs = draft.financialPeriods.anterieurs.concat(
              returnData.filter((p) => p.periodType === PeriodType.Anterieur)
            )
            draft.financialPeriods.previsionnelles = draft.financialPeriods.previsionnelles.concat(
              returnData.filter((p) => p.periodType === PeriodType.Previsionnel)
            )
          }
        }),
      })
      cache.evictQueries([
        { query: InvestissementsDocument, variables: { noteId } },
        { query: RatiosDocument, variables: { noteId } },
        { query: RessourcesDocument, variables: { noteId } },
        { query: ProjectAnalysisDocument, variables: { noteId } },
        { query: NoteDocument, variables: { noteId } },
        { query: ScoringCreationCheckDocument, variables: { noteId } },
      ])
    },
  })
  return (periods: UpdatePeriodsDatesMutationVariables) => {
    return new Promise<PeriodModel[]>((resolve, reject) => {
      periodsMutation({
        variables: periods,
      })
        .then((response) => {
          const result = response.data && response.data.financialPeriod.patchMultipleDates
          result ? resolve(result.map(fromFinancialPeriod)) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const useAddPeriod = (noteId: string) => {
  const [periodMutation] = useCreatePeriodMutation({
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<FinancialPeriodsQuery, FinancialPeriodsQueryVariables>({
        query: FinancialPeriodsDocument,
        variables: { noteId },
      })!
      cache.writeQuery<FinancialPeriodsQuery, FinancialPeriodsQueryVariables>({
        query: FinancialPeriodsDocument,
        variables: { noteId },
        data: produce(readQuery, (draft) => {
          if (draft.financialPeriods && mutationResult.data) {
            const returnData = mutationResult.data!.financialPeriod.create
            if (returnData.periodType === PeriodType.Previsionnel) {
              draft.financialPeriods.previsionnelles.push(returnData)
            } else {
              draft.financialPeriods.anterieurs.push(returnData)
            }
          }
        }),
      })
      cache.evictQueries([
        {
          query: InvestissementsDocument,
          variables: { noteId },
        },
        { query: MasseSalarialeDocument, variables: { noteId } },
        { query: BenchMarkDocument, variables: { noteId } },
        { query: RatiosDocument, variables: { noteId } },
        { query: RessourcesDocument, variables: { noteId } },
        { query: ProjectAnalysisDocument, variables: { noteId } },
        { query: NoteDocument, variables: { noteId } },
        { query: ScoringCreationCheckDocument, variables: { noteId } },
      ])
    },
  })
  return (periodType: PeriodType) =>
    new Promise<PeriodModel>((resolve, reject) => {
      periodMutation({
        variables: {
          period: {
            noteId,
            periodType,
          },
        },
      })
        .then((response) => {
          const result =
            response.data && response.data.financialPeriod && response.data.financialPeriod.create
          result ? resolve(fromFinancialPeriod(result)) : reject()
        })
        .catch((error) => reject(error))
    })
}

export const useDeletePeriod = (noteId: string, periodId: string) => {
  const [removeMutation] = useDeletePeriodMutation({
    update: (cache) => {
      const readQuery = cache.readQuery<FinancialPeriodsQuery, FinancialPeriodsQueryVariables>({
        query: FinancialPeriodsDocument,
        variables: { noteId },
      })!
      cache.writeQuery<FinancialPeriodsQuery, FinancialPeriodsQueryVariables>({
        query: FinancialPeriodsDocument,
        variables: { noteId },
        data: produce(readQuery, (draft) => {
          draft.financialPeriods = {
            anterieurs: draft.financialPeriods!.anterieurs.filter((p) => p && p.id !== periodId),
            previsionnelles: draft.financialPeriods!.previsionnelles.filter(
              (p) => p && p.id !== periodId
            ),
            situationIntermediaire: undefined,
          }
        }),
      })
      cache.evictQueries([
        {
          query: InvestissementsDocument,
          variables: { noteId },
        },
        { query: MasseSalarialeDocument, variables: { noteId } },
        { query: BenchMarkDocument, variables: { noteId } },
        { query: RatiosDocument, variables: { noteId } },
        { query: RessourcesDocument, variables: { noteId } },
        { query: ProjectAnalysisDocument, variables: { noteId } },
        { query: NoteDocument, variables: { noteId } },
        { query: ScoringCreationCheckDocument, variables: { noteId } },
      ])
    },
  })
  return () => {
    return new Promise((resolve, reject) => {
      removeMutation({
        variables: { periodId },
      })
        .then((success) => (success ? resolve(success) : reject()))
        .catch((error) => reject(error))
    })
  }
}

export const useInitPeriods = (noteId: string) => {
  const [initMutation] = useInitFinancialPeriodsMutation({
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<FinancialPeriodsQuery, FinancialPeriodsQueryVariables>({
        query: FinancialPeriodsDocument,
        variables: { noteId },
      })!
      cache.writeQuery<FinancialPeriodsQuery, FinancialPeriodsQueryVariables>({
        query: FinancialPeriodsDocument,
        variables: { noteId },
        data: produce(readQuery, (draft) => {
          if (draft.financialPeriods && mutationResult.data) {
            const newPrevisionnelles = mutationResult.data.financialPeriod.init.filter(
              (p) => p.periodType === PeriodType.Previsionnel
            )
            const newAnterieurs = mutationResult.data.financialPeriod.init.filter(
              (p) => p.periodType === PeriodType.Anterieur
            )
            if (newPrevisionnelles.length > 0) {
              draft.financialPeriods.previsionnelles = newPrevisionnelles
            }
            if (newAnterieurs.length > 0) {
              draft.financialPeriods.anterieurs = newAnterieurs
            }
            draft.financialPeriods.situationIntermediaire =
              mutationResult.data.financialPeriod.init.find(
                (p) => p.periodType === PeriodType.Intermediaire
              )
          }
        }),
      })
      cache.evictQueries([
        {
          query: InvestissementsDocument,
          variables: { noteId },
        },
        { query: MasseSalarialeDocument, variables: { noteId } },
        { query: BenchMarkDocument, variables: { noteId } },
        { query: RatiosDocument, variables: { noteId } },
        { query: ProjectAnalysisDocument, variables: { noteId } },
        { query: NoteDocument, variables: { noteId } },
      ])
    },
  })
  return (creationDate: Dayjs, periodType: PeriodType = PeriodType.Previsionnel) =>
    new Promise<PeriodModel[]>((resolve, reject) => {
      initMutation({
        variables: {
          noteId,
          periodType,
          startDate: toIso(creationDate),
        },
      })
        .then((response) => {
          const result = response.data && response.data.financialPeriod.init
          resolve(result!.map(fromFinancialPeriod))
        })
        .catch((error) => reject(error))
    })
}
