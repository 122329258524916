import React, { FC, useContext } from 'react'
import { CRLink, DifferenceSaisies, PFLink } from '../../Utils/DifferencesDeSaisie'
import { FinancialPeriodsSaveRequestMultipleInput, PretPasse } from '@fa-metier/types'
import { PeriodModel } from '../../PeriodModel'
import dayjs from 'dayjs'
import { dateIsBetween } from '../../Utils/date-utils'
import { useUpdatePeriods } from '../../FinanciaryQueries'
import { incomeStatementToInput } from '../../IncomeStatementMapper'
import { NoteContext } from '../../../NotePage'
import { PretsPassesContext } from './PretPasseTabs'
import produce from 'immer'
import { useDictionnary } from '../../../dictionnary/dictionnary'
import { removeTypeName } from '@fa-metier/commons'

export const DifferencesSaisiesPretsPasses: FC = () => {
  const { noteId, noteSettings } = useContext(NoteContext)
  const { previsionelPeriods, pretsPasses } = useContext(PretsPassesContext)
  const updateMutation = useUpdatePeriods(noteId)
  const { differencesSaisies } = useDictionnary()
  const dictionnary = useDictionnary()

  const updateCR = () => {
    const updates = previsionelPeriods
      .map((period) => {
        const periodInterets = computeInterets(period, pretsPasses).toFixed(2)
        return produce(period, (draft) => {
          draft.incomeStatement.resultatFinancier.chargesInteretsPasses = {
            value: +periodInterets,
            detail: periodInterets,
            kiloEuros: noteSettings.kiloEuros,
            percentageReference: 0,
          }
        })
      })
      .map(periodIncomeStatementToMutationModel)
    return updateMutation({ updates })
  }

  const updatePF = () => {
    const updates = previsionelPeriods
      .map((period) => {
        const periodRemboursement = computeRemboursement(period, pretsPasses).toFixed(2)
        return produce(period, (draft) => {
          draft.planFinancement.remboursementsPretsPasses = {
            value: +periodRemboursement,
            detail: periodRemboursement,
            kiloEuros: noteSettings.kiloEuros,
            percentageReference: 0,
          }
        })
      })
      .map(periodPlanFinancementToMutationModel)
    return updateMutation({ updates })
  }


  const sumEcheancesForField = (period: PeriodModel, pretsPasses: PretPasse[], field: "amortissement" | "interets") => {
    const interets = pretsPasses
      .filter((pret) => pret.computedPret)
      .flatMap((pret) => pret.computedPret!!.tableauAmortissement)
      .filter((a) => dateIsBetween(dayjs(a.echeance), period.startDate, period.endDate))
      .map((a) => a[field])
      .reduce((a, b) => a + b, 0)
      .toFixed(2)
    return +interets
  }

  const computeRemboursement = (period: PeriodModel, pretsPasses: PretPasse[]) => sumEcheancesForField(period, pretsPasses, "amortissement")

  const computeInterets = (period: PeriodModel, pretsPasses: PretPasse[]) => sumEcheancesForField(period, pretsPasses, "interets")

  const sourceChargesInterets = previsionelPeriods.map((period) =>
    computeInterets(period, pretsPasses)
  )

  const targetChargesInterets = previsionelPeriods.map(
    (period) => period.incomeStatement.resultatFinancier.chargesInteretsPasses?.value ?? 0
  )

  const intermediaryTableDataChargeInterets = [
    {
      title: "Charges d'intérêts des prêts passés",
      sourceValues: sourceChargesInterets,
      targetTitle: `${dictionnary.differencesSaisies.targetTitle} (partie prévisionnelle, ligne charges d’intérêts passés)`,
      targetValues: targetChargesInterets,
      sourceDataTitle: 'Données calculées dans cette page',
    },
  ]

  const sourceRemboursement = previsionelPeriods.map((period) =>
    computeRemboursement(period, pretsPasses)
  )

  const targetRemboursement = previsionelPeriods.map(
    (period) => period.planFinancement.remboursementsPretsPasses.value ?? 0
  )

  const intermediaryTableDataRemboursements = [
    {
      title: 'Remboursements des prêts passés',
      sourceValues: sourceRemboursement,
      targetTitle: 'Données saisies dans le Plan de financement',
      targetValues: targetRemboursement,
      sourceDataTitle: 'Données calculées dans cette page',
    },
  ]

  return (
    <>
      <DifferenceSaisies
        successText={differencesSaisies.crSuccessText}
        updateFunction={updateCR}
        buttonText={'Remplacer les données du Compte de résultat'}
        dialogBody={
          <>
            <p style={{ whiteSpace: 'nowrap' }}>
              Etes-vous sûr de vouloir remplacer les données du <b>Compte de résultat</b> ?
            </p>
            <p>Cette action ne peut pas être inversée.</p>
          </>
        }
        explanation={
          <>
            {differencesSaisies.crExplanation}
            <div>
              Cette opération effacera définitivement les Charges d'intérêts des prêts passés que vous
              avez saisies dans le <CRLink />.
            </div>
          </>
        }
        intermediaryTableData={intermediaryTableDataChargeInterets}
        hideDemarrage={true}
      />
      <DifferenceSaisies
        successText={differencesSaisies.pfSuccessText}
        updateFunction={updatePF}
        buttonText={'Remplacer les données du Plan de financement'}
        dialogBody={
          <>
            <p style={{ whiteSpace: 'nowrap' }}>
              Etes-vous sûr de vouloir remplacer les données du <b>Plan de financement</b> ?
            </p>
            <p>Cette action ne peut pas être inversée.</p>
          </>
        }
        explanation={
          <>
            {differencesSaisies.pfExplanation}
            <div>
              Cette opération effacera définitivement les Remboursements que vous avez saisies dans le{' '}
              <PFLink />.
            </div>
          </>
        }
        intermediaryTableData={intermediaryTableDataRemboursements}
        hideDemarrage={true}
      />
    </>
  )
}

const periodIncomeStatementToMutationModel = (
  period: PeriodModel
): FinancialPeriodsSaveRequestMultipleInput => {
  return {
    financialPeriodId: period.id,
    input: {
      incomeStatement: incomeStatementToInput(period.incomeStatement),
    },
  }
}

const periodPlanFinancementToMutationModel = (
  period: PeriodModel
): FinancialPeriodsSaveRequestMultipleInput => {
  return removeTypeName({
    financialPeriodId: period.id,
    input: {
      periodPlanFinancementInput: {
        investissementsCorporels: period.planFinancement.investissementsCorporels,
        investissementsIncorporels: period.planFinancement.investissementsIncorporels,
        investissementsFinanciers: period.planFinancement.investissementsFinanciers,
        dividendes: period.planFinancement.dividendes,
        editableEntries: period.planFinancement.editableEntries,
        remboursementsPretsPasses: period.planFinancement.remboursementsPretsPasses,
      },
    },
  })
}
