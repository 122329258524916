import { MySettingsDocument, useUpdateSettingMutation } from '../generated/graphql'
import {
  AdvisorSetting,
  MySettingsQuery,
  MySettingsQueryVariables,
  SettingUpdateRequestInput,
} from '@fa-metier/types'
import produce from 'immer'

export const useUpdateSettings = () => {
  const [update] = useUpdateSettingMutation({
    update: (proxy, { data }) => {
      const readQuery = proxy.readQuery<MySettingsQuery, MySettingsQueryVariables>({
        query: MySettingsDocument,
        variables: {},
      })!
      proxy.writeQuery<MySettingsQuery>({
        query: MySettingsDocument,
        data: produce(readQuery, (draft) => {
          draft.advisorSettings.settings = data!.advisorSettings.update
        }),
      })
    },
  })
  return (updatedSettings: SettingUpdateRequestInput) => {
    return new Promise<AdvisorSetting>((resolve, reject) => {
      update({
        variables: {
          value: updatedSettings,
        },
      })
        .then((response) => {
          const result = response.data && response.data.advisorSettings.update
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}
