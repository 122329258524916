import { StrokedButton } from '@fa-metier/components/dist/Buttons/Buttons'
import React, { FC, useContext } from 'react'
import { FinanciaryContext } from '../Financiary'
import { NoteContext } from '../../NotePage'
import { PlanFinancementContext } from './PlanFinancement'

export const AddNewEntryLine: FC<{ title: string; action: () => void }> = ({ action, title }) => {
  const { noteSettings } = useContext(NoteContext)
  const { previsionelPeriods } = useContext(FinanciaryContext)
  const { computationInProgress } = useContext(PlanFinancementContext)

  return (
    <tr>
      <td>
        <StrokedButton text={title} onClick={action} disabled={computationInProgress} />
      </td>
      {noteSettings.showDemarrage && <td className={'demarrage'} />}
      <td colSpan={previsionelPeriods.length + 1} />
    </tr>
  )
}
