import { Category, Stylable } from '@fa-metier/types'
import React, { FC } from 'react'
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from 'recharts'
import { CATEGORY_CONFIG } from '@fa-metier/commons'
import { RecapData } from './InvolvementRecap'
import {
  SvgDeal,
  SvgGroup5,
  SvgLeaf,
  SvgSiteMap,
  SvgYouAreHere,
} from '../assets/svgComponents/SvgComponent'

interface RenderingContextLabelCb {
  x: number
  y: number
  payload: { index: number; value: Category }
}

const renderLabel: (data: RenderingContextLabelCb) => JSX.Element = ({ x, y, payload }) => {
  const size = 60
  let xp
  let yp

  const category: Category = payload.value
  const { icon } = CATEGORY_CONFIG[category]
  const [width, height, , , path] = icon.icon

  switch (payload.index) {
    case 0:
      xp = x - size / 2
      yp = y - size
      break
    case 1:
      xp = x
      yp = y - size / 2
      break
    case 2:
      xp = x
      yp = y
      break
    case 3:
      xp = x - size
      yp = y
      break
    case 4:
      xp = x - size
      yp = y - size / 2
      break
    default:
      xp = x
      yp = y
  }

  const getIcon = (category: Category, xp: number, yp: number) => {
    switch (category) {
      case Category.Governance:
        return <SvgSiteMap size={size} x={xp} y={yp} fill={'var(--main-blue-5)'} />
      case Category.Environment:
        return <SvgLeaf size={size} x={xp} y={yp} fill={'var(--main-orange)'} />
      case Category.Social:
        return <SvgDeal size={size} x={xp} y={yp} fill={'var(--main-red-1)'} />
      case Category.Territory:
        return <SvgYouAreHere size={size} x={xp} y={yp} fill={'var(--main-blue-6)'} />
      case Category.Employment:
        return <SvgGroup5 size={size} x={xp} y={yp} fill={'var(--main-green-5'} />
      default:
        return (
          <svg
            x={xp}
            y={yp}
            width={size}
            height={size}
            viewBox={`0 0 ${width} ${height}`}
            fill={'var(--main-grey-4)'}
          >
            <path d={Array.isArray(path) ? path[0] : path} />
          </svg>
        )
    }
  }

  return <>{getIcon(payload.value, xp, yp)}</>
}

export type InvolvementChartData = { score: number; fullMark: 100; category: string | Category }[]
export type InvolvementChartScores = Record<Category, number>
type InvolvementChartProps = { scores: InvolvementChartScores; color: string; isV2: boolean }

const handleScore = (score: number, isV2: boolean) => {
  if (!isV2) {
    return score * 33
  }

  switch (score) {
    case 1:
      return 20
    case 2:
      return 50
    case 3:
      return 100
    default:
      return -10
  }
}

export const InvolvementChart: FC<InvolvementChartProps & Stylable> = ({
  isV2,
  scores,
  color,
  className,
}) => {
  const data: InvolvementChartData = [
    {
      category: Category.Employment,
      score: handleScore(scores.EMPLOYMENT, isV2),
      fullMark: 100,
    },
    { category: Category.Territory, score: handleScore(scores.TERRITORY, isV2), fullMark: 100 },
    { category: Category.Social, score: handleScore(scores.SOCIAL, isV2), fullMark: 100 },
    {
      category: Category.Environment,
      score: handleScore(scores.ENVIRONMENT, isV2),
      fullMark: 100,
    },
    {
      category: Category.Governance,
      score: handleScore(scores.GOVERNANCE, isV2),
      fullMark: 100,
    },
  ]
  return (
    <RadarChart
      cx={175}
      cy={175}
      outerRadius={125}
      width={350}
      height={350}
      data={data}
      style={{ margin: 'auto', cursor: 'pointer !important' }}
      className={className}
    >
      <PolarGrid />
      <PolarRadiusAxis domain={[-10, 100]} axisLine={false} tick={false} />
      <PolarAngleAxis dataKey="category" tick={renderLabel} />
      <Radar name="Results" dataKey="score" stroke={color} fill={color} fillOpacity={0.6} />
    </RadarChart>
  )
}
export const makeRecapData: (
  categoryResults: RecapData,
  isV2?: boolean
) => InvolvementChartScores = (categoryResults, isV2 = false) => {
  const forCategory: (c: Category) => number = (c) => {
    const find = categoryResults.find((r) => r.category === c)

    const scoreToUse = isV2 ? find && find.score : find && find.valid && find.mean
    return scoreToUse || 0
  }
  const icData: InvolvementChartScores = {
    EMPLOYMENT: forCategory(Category.Employment),
    ENVIRONMENT: forCategory(Category.Environment),
    GOVERNANCE: forCategory(Category.Governance),
    SOCIAL: forCategory(Category.Social),
    TERRITORY: forCategory(Category.Territory),
    OTHER: forCategory(Category.Other),
  }
  return icData
}

export const graphColor = (progress: number) =>
  progress <= 0.2 ? '#a1aaaf' : progress <= 0.6 ? '#de7e06' : '#008000'
