import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react'
import PageTitle from '../../../utils/PageTitle'
import { CalculatedLine, CalculatedLineWithDetail, Line } from '../Utils/FinanciaryLine'
import { PeriodsContext } from '../Utils/PeriodsContext'
import { updateInternalPeriods, updateInternalSituationIntermediaire } from '../Financiary'
import { useGetPeriods, useUpdatePeriods } from '../FinanciaryQueries'
import { formatDayjs } from '../../../utils/DayjsFormatter'
import { useDebounce } from 'react-use'
import { PeriodModel } from '../PeriodModel'
import { Caf, CafInput, FinancialPeriodsSaveRequestMultipleInput, NoteType } from '@fa-metier/types'
import { removeTypeName } from '@fa-metier/commons'
import { InputOrDisplay } from './InputOrDisplay'
import { FinancialTable, LabelSeparator } from '../FinancialTable'
import { incomeStatementToInput } from '../IncomeStatementMapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { NoteContext } from '../../NotePage'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { saveShortcutListener, withFieldsGuard } from '../../../utils/utils'
import { PageSaveButton } from '../../../utils/PageSaveButton'
import { ParametersDiv } from '../../../utils/ParametersDiv'
import _ from 'lodash'
import { useUserDebounce } from '../../../Settings/Hooks'

const cafToInput = (caf: Caf) => {
  return removeTypeName<CafInput>({
    reprises_amortissements_provisions: caf.reprises_amortissements_provisions,
    dotations_provisions_financieres: caf.dotations_provisions_financieres,
    reprises_sur_provisions_financieres: caf.reprises_sur_provisions_financieres,
    dotations_provisions_exceptionnelles: caf.dotations_provisions_exceptionnelles,
    reprises_provisions_exceptionnelles: caf.reprises_provisions_exceptionnelles,
    quote_part_subventions_resultats: caf.quote_part_subventions_resultats,
    produits_cessions_actifs: caf.produits_cessions_actifs,
    valeurs_comptables_cessions_actifs: caf.valeurs_comptables_cessions_actifs,
  })
}

const ExerciceHeader = styled.span`
  font-weight: var(--bold);
`

const Text = styled.div`
  white-space: pre-wrap;
  margin-left: 15px;
  margin-right: auto;
  font-size: 13px;
  color: var(--main-black-2);
  line-height: 26px;
`

export const TextGreen = styled(Text)`
  font-weight: var(--bold);
  color: var(--main-green-1);
`

const periodToMutationModel = (period: PeriodModel): FinancialPeriodsSaveRequestMultipleInput => {
  return {
    financialPeriodId: period.id,
    input: {
      caf: cafToInput(period.caf),
      incomeStatement: incomeStatementToInput(period.incomeStatement),
    },
  }
}

interface CafContextI {
  previsionelPeriods: PeriodModel[]
  anterieurPeriods: PeriodModel[]
  situationIntermediaire?: PeriodModel
}

export const CafContext = createContext<CafContextI>({} as CafContextI)

export const CafFormWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)
  if (periodLoading) {
    return <YodaSpinnerPage />
  }
  return (
    <CafContext.Provider
      value={{
        previsionelPeriods: periodsData.previsionnelles,
        anterieurPeriods: periodsData.anterieurs,
        situationIntermediaire: periodsData.situationIntermediaire,
      }}
    >
      <CafForm />
    </CafContext.Provider>
  )
}

const CafForm: FC = () => {
  const { note, noteId, noteSettings, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const { previsionelPeriods, anterieurPeriods, situationIntermediaire } = useContext(CafContext)
  const [internalPeriods, setInternalPeriods] = useState(previsionelPeriods)
  const [internalAnterieursPeriods, setInternalAnterieursPeriods] = useState(anterieurPeriods ?? [])
  const [internalSituationIntermediaire, setInternalSituationIntermediaire] =
    useState(situationIntermediaire)
  const updatePeriods = useUpdatePeriods(noteId)
  const [showComputations, setShowComputations] = useState(false)
  const [separatorHeight, setSeparatorHeight] = useState(500)

  const [showAnterieurs, setShowAnterieurs] = useState(
    note.type === NoteType.Reprise || note.type === NoteType.StructureExistante
  )
  const [computationInProgress, setComputationInProgress] = useState(false)

  const refs = useRef({
    previsionelPeriods,
    anterieurPeriods,
    situationIntermediaire,
    internalPeriods,
    internalAnterieursPeriods,
    internalSituationIntermediaire,
    unsavedChanges,
  })

  const getPeriodUpdates = () => {
    const updatesPrevi = refs.current.internalPeriods.filter(
      (p, i) =>
        !_.isEqual(p.caf, refs.current.previsionelPeriods[i].caf) ||
        !_.isEqual(p.incomeStatement, refs.current.previsionelPeriods[i].incomeStatement)
    )
    const updatesAnte = refs.current.internalAnterieursPeriods.filter(
      (p, i) =>
        !_.isEqual(p.caf, refs.current.anterieurPeriods[i].caf) ||
        !_.isEqual(p.incomeStatement, refs.current.anterieurPeriods[i].incomeStatement)
    )
    const updateSituation =
      refs.current.internalSituationIntermediaire !== undefined &&
      (!_.isEqual(
        refs.current.internalSituationIntermediaire?.caf,
        refs.current.situationIntermediaire?.caf
      ) ||
        !_.isEqual(
          refs.current.internalSituationIntermediaire?.incomeStatement,
          refs.current.situationIntermediaire?.incomeStatement
        ))
        ? [refs.current.internalSituationIntermediaire!]
        : []

    return updatesAnte.concat(updatesPrevi).concat(updateSituation).map(periodToMutationModel)
  }

  const userDebounce = useUserDebounce()

  const [, cancelDebounceUpdatePeriods] = useDebounce(
    async () => {
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await withFieldsGuard(() => updatePeriods({ updates }), setComputationInProgress)
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [internalPeriods, internalAnterieursPeriods, internalSituationIntermediaire]
  )

  useEffect(() => {
    refs.current.internalPeriods = internalPeriods
    refs.current.internalAnterieursPeriods = internalAnterieursPeriods
    refs.current.internalSituationIntermediaire = internalSituationIntermediaire
    const updates = getPeriodUpdates()
    if (updates.length > 0) {
      setUnsavedChanges(true)
    }
  }, [
    internalPeriods,
    internalAnterieursPeriods,
    internalSituationIntermediaire,
    setUnsavedChanges,
  ])

  useEffect(() => {
    if (!_.isEqual(refs.current.previsionelPeriods, previsionelPeriods)) {
      refs.current.previsionelPeriods = previsionelPeriods
      setInternalPeriods(previsionelPeriods)
      cancelDebounceUpdatePeriods()
    }

    if (!_.isEqual(refs.current.anterieurPeriods, anterieurPeriods)) {
      refs.current.anterieurPeriods = anterieurPeriods
      setInternalAnterieursPeriods(anterieurPeriods)
      cancelDebounceUpdatePeriods()
    }

    if (!_.isEqual(refs.current.situationIntermediaire, situationIntermediaire)) {
      refs.current.situationIntermediaire = situationIntermediaire
      setInternalSituationIntermediaire(situationIntermediaire)
      cancelDebounceUpdatePeriods()
    }
  }, [previsionelPeriods, anterieurPeriods, situationIntermediaire, cancelDebounceUpdatePeriods])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelDebounceUpdatePeriods()
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await updatePeriods({ updates })
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dictionnary = useDictionnary()
  return (
    <div style={{ paddingBottom: '3%' }}>
      <PageTitle>Calcul de la CAF</PageTitle>

      <div
        style={{
          display: 'flex',
          borderStyle: 'solid',
          padding: '10px',
          borderWidth: '1.5px',
          borderColor: 'var(--main-grey-2)',
          maxWidth: 'max-content',
          marginBottom: '25px',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            paddingRight: '5px',
          }}
        >
          <FontAwesomeIcon
            icon={['fas', 'exclamation-triangle']}
            color={'var(--main-green-1)'}
            size={'lg'}
          />
        </div>
        <div>
          <TextGreen style={{ fontSize: '16px' }}>
            Cette page peut vous servir à vérifier et compléter le calcul de la CAF, qui provient du
            Compte de résultat et que vous retrouvez dans le plan de financement.
          </TextGreen>
          <Text style={{ fontSize: '16px' }}>
            <p>Les données modifiées ici mettent automatiquement à jour le Compte de résultat.</p>
            <p>
              <u>Attention</u> : les cases{' '}
              <span style={{ backgroundColor: 'var(--main-grey-3)', padding: '5px' }}>
                en surbrillance
              </span>{' '}
              ne mettront pas à jour le Compte de résultat car vous n’avez pas affiché le détail
              concerné dans celui-ci.
            </p>
          </Text>
        </div>
      </div>

      <PeriodsContext.Provider
        value={{
          showComputations,
          showAnterieurs,
          situationIntermediaire,
          periods: internalPeriods,
          updatePeriods: updateInternalPeriods(setInternalPeriods),
          updateAnterieurPeriods: updateInternalPeriods(setInternalAnterieursPeriods),
          anterieurPeriods: anterieurPeriods ?? [],
          updateSituationIntermediaire: updateInternalSituationIntermediaire(
            setInternalSituationIntermediaire
          ),
        }}
      >
        <div>
          <ParametersDiv>
            <PageSaveButton
              buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
              buttonDisabled={computationInProgress || !unsavedChanges}
            />
            <AppMetierSwitch
              label={'Voir tous les calculs'}
              checked={showComputations}
              onChange={() => {
                setShowComputations(!showComputations)
              }}
            />
            {note.type === NoteType.Reprise && anterieurPeriods?.length !== 0 && (
              <div style={{ marginLeft: '20px' }}>
                <AppMetierSwitch
                  label={'Afficher exercices du cédant'}
                  checked={showAnterieurs}
                  onChange={() => {
                    setShowAnterieurs(!showAnterieurs)
                  }}
                />
              </div>
            )}
          </ParametersDiv>
          <div
            ref={(div) => {
              if (div && separatorHeight !== div.clientHeight) {
                setSeparatorHeight(div.clientHeight)
              }
            }}
          >
            <FinancialTable
              showAnterieur={showAnterieurs}
              lineBefore={false}
              thead={
                <tr style={{ backgroundColor: 'var(--main-blue-2' }}>
                  <th className={'section-title'} style={{ verticalAlign: 'bottom' }}>
                    CAF
                    <LabelSeparator key={separatorHeight} height={separatorHeight} />
                  </th>
                  {showAnterieurs &&
                    anterieurPeriods.map((p, i) => (
                      <td key={p.id} className={'anterieurSeparation'}>
                        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                          <ExerciceHeader>{dictionnary.periodTitle(p, i)}</ExerciceHeader>
                        </div>
                      </td>
                    ))}
                  {situationIntermediaire && (
                    <td
                      key={situationIntermediaire.id}
                      className={'intermediaireSeparation'}
                      style={{ color: 'black' }}
                    >
                      <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <ExerciceHeader>
                          Situation au {situationIntermediaire.endDate.format('DD/MM/YYYY')}
                        </ExerciceHeader>
                      </div>
                    </td>
                  )}
                  {internalPeriods.map((p, i) => (
                    <td key={p.id}>
                      <div
                        style={
                          note.type === NoteType.StructureExistante ? { textAlign: 'center' } : {}
                        }
                      >
                        <b>{dictionnary.periodTitle(p, i)}</b>
                      </div>
                      {note.type !== NoteType.StructureExistante && (
                        <div style={{ color: 'grey', fontSize: '0.8em' }}>
                          Du {formatDayjs(p.startDate)} <br />
                          au {formatDayjs(p.endDate)}
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              }
            />
            <FinancialTable showAnterieur={showAnterieurs}>
              <CalculatedLine
                outlined={false}
                dots={true}
                isNotTotal={true}
                emptyText={'champ non renseigné dans le CR'}
                label={'Résultat net'}
                fieldName={'caf.resultat_net'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                forceNoEvolution={true}
              />
              <Line
                label={'Dotations aux amortissements et provisions'}
                fieldName={'incomeStatement.dotationsAmortissementsProvisions'}
                displayPercentageReference={false}
                isCalculate={computationInProgress}
              />
              <InputOrDisplay
                label={'Reprises sur amortissements et provisions'}
                fieldName={'caf.reprises_amortissements_provisions'}
                overridingField={
                  'incomeStatement.autres_produits_exploitation.reprises_amortissement_provisions'
                }
                isCalculate={computationInProgress}
              />
              <InputOrDisplay
                label={'Dotations aux provisions financières'}
                fieldName={'caf.dotations_provisions_financieres'}
                overridingField={
                  'incomeStatement.resultatFinancier.dotationsAuxProvisionsFinancieres'
                }
                isCalculate={computationInProgress}
              />
              <InputOrDisplay
                label={'Reprises sur provisions financières'}
                fieldName={'caf.reprises_sur_provisions_financieres'}
                overridingField={
                  'incomeStatement.resultatFinancier.reprisesSurProvisionsFinancieres'
                }
                isCalculate={computationInProgress}
              />
              <InputOrDisplay
                label={'Dotations aux provisions exceptionnelles'}
                fieldName={'caf.dotations_provisions_exceptionnelles'}
                overridingField={
                  'incomeStatement.resultatExceptionnel.dotationsAuxProvisionsExceptionnelles'
                }
                isCalculate={computationInProgress}
              />
              <InputOrDisplay
                label={'Reprises sur provisions exceptionnelles'}
                fieldName={'caf.reprises_provisions_exceptionnelles'}
                overridingField={
                  'incomeStatement.resultatExceptionnel.reprisesSurProvisionsExceptionnelles'
                }
                isCalculate={computationInProgress}
              />
              <InputOrDisplay
                label={'Quote part subventions résultats'}
                fieldName={'caf.quote_part_subventions_resultats'}
                overridingField={
                  'incomeStatement.resultatExceptionnel.quotePartSubventionsVireesAuResultat'
                }
                isCalculate={computationInProgress}
              />
              <Line
                label={'Report de ressources non utilisées des exercices antérieurs'}
                fieldName={'incomeStatement.reportRessourcesNonUtilisees'}
                displayPercentageReference={false}
                isCalculate={computationInProgress}
              />
              <Line
                label={'Engagement à réaliser sur ressources affectées'}
                fieldName={'incomeStatement.engagementARealiserRessourcesAffectees'}
                displayPercentageReference={false}
                isCalculate={computationInProgress}
              />
              <InputOrDisplay
                label={"Produits des cessions d'actifs"}
                fieldName={'caf.produits_cessions_actifs'}
                overridingField={'incomeStatement.resultatExceptionnel.produitsCessionsActifs'}
                isCalculate={computationInProgress}
              />
              <InputOrDisplay
                label={"Valeurs comptables des cessions d'actifs"}
                fieldName={'caf.valeurs_comptables_cessions_actifs'}
                overridingField={
                  'incomeStatement.resultatExceptionnel.valeursComptablesDesCessionsDactifs'
                }
                isCalculate={computationInProgress}
              />
              <CalculatedLineWithDetail
                label={'CAF'}
                fieldName={'caf.caf'}
                isResult={true}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            </FinancialTable>
          </div>
        </div>
      </PeriodsContext.Provider>
    </div>
  )
}
