import React, { FC } from 'react'
import { AppMetierSelect, SelectItem } from '../../../utils/AppMetierSelect'
import { useTvaQuery } from '../../../generated/graphql'
import { Spinner } from '@blueprintjs/core'

export interface SelectTvaProps {
  value?: number
  onClick?: (value: number) => any
  onChange: (value: number) => any
  disabled?: boolean
}

const tvaToSelectItem: (value: number) => SelectItem = (value) => {
  return { id: value.toString(), text: `${(value * 100).toFixed(2)} %` }
}

export const SelectTva: FC<SelectTvaProps> = ({ value, onClick, onChange, disabled }) => {
  const { loading, data } = useTvaQuery()
  if (loading) {
    return <Spinner size={30} />
  }
  return (
    <AppMetierSelect
      value={value !== undefined ? tvaToSelectItem(value) : undefined}
      onClick={(event) => !!onClick && onClick(+event.id)}
      onChange={(event) => onChange(+event.id)}
      items={data!!.tva.map((it) => tvaToSelectItem(it))}
      disabled={disabled}
    />
  )
}
