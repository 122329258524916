import { Lifecycle, Note } from '@fa-metier/types'

export enum ScoringType {
  Creation = 'CREATION',
  Developpement = 'DEVELOPPEMENT',
}

export const getScoringType: (note: Note) => ScoringType = (note: Note) =>
  [Lifecycle.Creation, Lifecycle.Reprise].some((v) => v === note.lifecycle)
    ? ScoringType.Creation
    : ScoringType.Developpement
