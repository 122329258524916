import { DifferenceSaisies, PFLink } from '../Utils/DifferencesDeSaisie'
import React, { FC, useContext, useState } from 'react'
import { FinanciaryContext } from '../Financiary'
import {
  AmortissementLine,
  FinancialPeriodsSaveRequestMultipleInput,
  Investissements,
  InvestissementsSection,
  NoteType,
  PlanFinancement,
} from '@fa-metier/types'
import { PeriodModel } from '../PeriodModel'
import { useUpdatePeriods } from '../FinanciaryQueries'
import { removeTypeName } from '@fa-metier/commons'
import dayjs, { Dayjs } from 'dayjs'
import produce from 'immer'
import {
  useGetPlanFinancement,
  useUpdatePlanFinancement,
} from '../PlanFinancement/PlanFinancementQueries'
import { AppMetierCheckbox } from '../Utils/AppMetierCheckBox'
import { NoteContext } from '../../NotePage'
import { InvestmentsContext } from './InvestmentsContext'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'

export const DifferencesSaisiesInvestissementsPlanFinancementWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading, data } = useGetPlanFinancement(noteId)
  if (loading) {
    return <YodaSpinnerPage />
  }
  return <DifferencesSaisiesInvestissementsPlanFinancement planFinancement={data!!} />
}

const DifferencesSaisiesInvestissementsPlanFinancement: FC<{
  planFinancement: PlanFinancement
}> = ({ planFinancement }) => {
  const { noteId, noteSettings, note } = useContext(NoteContext)
  const { investments } = useContext(InvestmentsContext)
  const { previsionelPeriods } = useContext(FinanciaryContext)
  const updatePeriodsMutation = useUpdatePeriods(noteId)
  const updatePlanFinancementMutation = useUpdatePlanFinancement(noteId)

  const demarrageShown = noteSettings.showDemarrage

  const [updateTva, setUpdateTva] = useState(false)

  const noTva = InvestissementsHaveNoTva(investments)

  function updatePeriod(periodToUpdate: PeriodModel) {
    return produce(periodToUpdate, (draft) => {
      const updatePFIncorporels = draft.planFinancement.investissementsIncorporels
      if (updatePFIncorporels) {
        updatePFIncorporels.value = extractInvestissementForPeriod(
          draft,
          investments.investissementsIncorporels
        )
        updatePFIncorporels.detail = ''
      }

      const updatePFcorporels = draft.planFinancement.investissementsCorporels
      if (updatePFcorporels) {
        updatePFcorporels.value = extractInvestissementForPeriod(
          draft,
          investments.investissementsCorporels
        )
        updatePFcorporels.detail = ''
      }

      const updatePFFinanciers = draft.planFinancement.investissementsFinanciers
      if (updatePFFinanciers) {
        updatePFFinanciers.value = extractInvestissementForPeriod(
          draft,
          investments.investissementsFinanciers
        )
        updatePFFinanciers.detail = ''
      }
    })
  }

  const updatePlanFinancement = () => {
    if (!demarrageShown) {
      return new Promise((r) => r())
    }
    const periodStartDate = previsionelPeriods[0].startDate
    const update = produce(planFinancement, (draft) => {
      draft.investissementsIncorporels.value = extractDemarrageAmortissementSum(
        periodStartDate,
        investments.investissementsIncorporels
      )
      draft.investissementsIncorporels.detail = ''
      draft.investissementsCorporels.value = extractDemarrageAmortissementSum(
        periodStartDate,
        investments.investissementsCorporels
      )
      draft.investissementsCorporels.detail = ''
      draft.investissementsFinanciers.value = extractDemarrageAmortissementSum(
        periodStartDate,
        investments.investissementsFinanciers
      )
      draft.investissementsFinanciers.detail = ''
      if (updateTva) {
        draft.tvaSurInvestissements.value = extractDemarrageTva(periodStartDate, investments)
        draft.tvaSurInvestissements.detail = ''
      }
    })
    return updatePlanFinancementMutation(update)
  }

  const updatePF = () => {
    const updates = previsionelPeriods
      .filter((p) => previsionelPeriods.some((pfPeriod) => pfPeriod.id === p.id))
      .map(updatePeriod)
      .map(periodToMutationModel)
    return updatePeriodsMutation({ updates }).then(() => updatePlanFinancement())
  }

  const intermediaryTableData = getIntermediaryTableData(
    investments,
    previsionelPeriods,
    planFinancement,
    demarrageShown
  )

  return (
    <DifferenceSaisies
      title={'Différences de saisies avec le plan de financement'}
      updateFunction={updatePF}
      intermediaryTableData={intermediaryTableData}
      explanation={
        <p>
          À tout moment vous pouvez remplacer les données du <PFLink /> par celles issues de cette
          page.
        </p>
      }
      buttonText={'Remplacer les données du Plan de financement'}
      dialogBody={
        <>
          <p>
            Etes-vous sûr de vouloir remplacer les données du <b>Plan de financement</b> ? <br />{' '}
            Cette action ne peut pas être inversée.
          </p>
          {note.type !== NoteType.StructureExistante && (
            <>
              <AppMetierCheckbox
                label={'Remplacer également le montant total de la TVA sur investissements'}
                checked={updateTva}
                onChange={() => setUpdateTva(!updateTva)}
                disabled={!demarrageShown || noTva}
              />
              <p>
                NB : Le montant des investissements réalisés au démarrage et de la TVA ne se
                reportent dans la colonne « Démarrage » du plan de financement que si vous avez
                affiché cette colonne.
              </p>
            </>
          )}
        </>
      }
      successText={
        <div>
          Vous pouvez consulter le <PFLink /> mis à jour.
        </div>
      }
    />
  )
}

const InvestissementsHaveNoTva = (investissements: Investissements) => {
  const investissementsHaveNoTva = (investissementsSection: InvestissementsSection) =>
    investissementsSection.lines.every((inv) => inv.tvaRate === 0)

  return (
    investissementsHaveNoTva(investissements.investissementsIncorporels) &&
    investissementsHaveNoTva(investissements.investissementsCorporels) &&
    investissementsHaveNoTva(investissements.investissementsFinanciers)
  )
}

const isInvestissementInPeriod = (period: PeriodModel, investissement: AmortissementLine) => {
  const dateAchat = dayjs(investissement.dateAchat)
  return (
    dateAchat.isSame(period.startDate, 'day') ||
    (dateAchat.isAfter(period.startDate, 'day') && dateAchat.isBefore(period.endDate, 'day'))
  )
}

const extractInvestissementForPeriod = (
  period: PeriodModel,
  investissements: InvestissementsSection
) => {
  return investissements.lines
    .filter((inv) => isInvestissementInPeriod(period, inv))
    .map((inv) => inv.montant.value ?? 0)
    .reduce((a, b) => a + b, 0)
}

const getIntermediaryTableData = (
  investissements: Investissements,
  periods: PeriodModel[],
  planFinancement: PlanFinancement,
  showDemarrage: boolean
) => {
  const extractTotalInvestissements = (
    investissements: InvestissementsSection,
    periods: PeriodModel[]
  ) => {
    return periods.map((p) => extractInvestissementForPeriod(p, investissements))
  }

  const investissementsValuesIncorporels = extractTotalInvestissements(
    investissements.investissementsIncorporels,
    periods
  )
  const planFinancementValuesIncorporels = periods.map(
    (p) => p.planFinancement.investissementsIncorporels.value ?? 0
  )

  const investissementsValuesCorporels = extractTotalInvestissements(
    investissements.investissementsCorporels,
    periods
  )
  const planFinancementValuesCorporels = periods.map(
    (p) => p.planFinancement.investissementsCorporels.value ?? 0
  )

  const investissementsValuesFinanciers = extractTotalInvestissements(
    investissements.investissementsFinanciers,
    periods
  )
  const planFinancementValuesFinanciers = periods.map(
    (p) => p.planFinancement.investissementsFinanciers.value ?? 0
  )

  const period = periods[0]

  const base = [
    {
      title: 'Investissements incorporels',
      sourceValues: investissementsValuesIncorporels,
      targetTitle: 'Données saisies dans le Plan de financement',
      targetValues: planFinancementValuesIncorporels,
      sourceValueDemarrage: showDemarrage
        ? extractDemarrageAmortissementSum(
            period.startDate,
            investissements.investissementsIncorporels
          )
        : undefined,
      targetValueDemarrage: showDemarrage
        ? planFinancement.investissementsIncorporels.value ?? 0
        : undefined,
    },
    {
      title: 'Investissements corporels',
      sourceValues: investissementsValuesCorporels,
      targetTitle: 'Données saisies dans le Plan de financement',
      targetValues: planFinancementValuesCorporels,
      sourceValueDemarrage: showDemarrage
        ? extractDemarrageAmortissementSum(
            period.startDate,
            investissements.investissementsCorporels
          )
        : undefined,
      targetValueDemarrage: showDemarrage
        ? planFinancement.investissementsCorporels.value ?? 0
        : undefined,
    },
    {
      title: 'Investissements financiers',
      sourceValues: investissementsValuesFinanciers,
      targetTitle: 'Données saisies dans le Plan de financement',
      targetValues: planFinancementValuesFinanciers,
      sourceValueDemarrage: showDemarrage
        ? extractDemarrageAmortissementSum(
            period.startDate,
            investissements.investissementsFinanciers
          )
        : undefined,
      targetValueDemarrage: showDemarrage
        ? planFinancement.investissementsFinanciers.value ?? 0
        : undefined,
    },
  ]
  if (showDemarrage) {
    const diffTva = {
      title: 'TVA sur investissements',
      sourceValues: [],
      targetTitle: 'Données saisies dans le Plan de financement',
      targetValues: [],
      sourceValueDemarrage: extractDemarrageTva(period.startDate, investissements),
      targetValueDemarrage: planFinancement.tvaSurInvestissements.value ?? 0,
    }

    return base.concat(diffTva)
  }
  return base
}

const extractDemarrageAmortissementSum = (
  firstPeriodStartData: Dayjs,
  investissements: InvestissementsSection
) => {
  return investissements.lines
    .filter((inv) => dayjs(inv.dateAchat).isSame(firstPeriodStartData, 'day'))
    .map((inv) => inv.montant.value ?? 0)
    .reduce((a, b) => a + b, 0)
}

const extractDemarrageTvaForInvestissement = (
  firstPeriodStartData: Dayjs,
  investissements: InvestissementsSection
) => {
  return investissements.lines
    .filter((inv) => dayjs(inv.dateAchat).isSame(firstPeriodStartData, 'day'))
    .map((inv) => inv.montantTva)
    .reduce((a, b) => a + (b.numericData.value ?? 0), 0)
}

const extractDemarrageTva = (firstPeriodStartData: Dayjs, investissements: Investissements) => {
  return (
    extractDemarrageTvaForInvestissement(
      firstPeriodStartData,
      investissements.investissementsIncorporels
    ) +
    extractDemarrageTvaForInvestissement(
      firstPeriodStartData,
      investissements.investissementsFinanciers
    ) +
    extractDemarrageTvaForInvestissement(
      firstPeriodStartData,
      investissements.investissementsCorporels
    )
  )
}

const periodToMutationModel = (period: PeriodModel): FinancialPeriodsSaveRequestMultipleInput => {
  return removeTypeName({
    financialPeriodId: period.id,
    input: {
      periodPlanFinancementInput: {
        investissementsCorporels: period.planFinancement.investissementsCorporels,
        investissementsIncorporels: period.planFinancement.investissementsIncorporels,
        investissementsFinanciers: period.planFinancement.investissementsFinanciers,
        remboursementsPretsPasses: period.planFinancement.remboursementsPretsPasses,
        dividendes: period.planFinancement.dividendes,
        editableEntries: period.planFinancement.editableEntries,
      },
    },
  })
}
