import * as Types from '@fa-metier/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export const NumericDataFieldsWithPercentageFragmentDoc = gql`
    fragment numericDataFieldsWithPercentage on NumericData {
  detail
  value
  kiloEuros
  percentageReference
  percentageEvolution
}
    `;
export const BasicNumericDataFieldsFragmentDoc = gql`
    fragment basicNumericDataFields on NumericData {
  detail
  value
  kiloEuros
}
    `;
export const CalculatedFieldsFragmentDoc = gql`
    fragment CalculatedFields on CalculatedData {
  detail {
    labelDetail
    link
    unit
    valuedDetail
  }
  numericData {
    ...numericDataFieldsWithPercentage
  }
}
    ${NumericDataFieldsWithPercentageFragmentDoc}`;
export const BenchMarkFieldsFragmentDoc = gql`
    fragment BenchMarkFields on BenchMark {
  noteId
  chiffreAffaire {
    ...numericDataFieldsWithPercentage
  }
  chiffreAffaireParPersonne {
    ...basicNumericDataFields
  }
  margeBrut {
    ...basicNumericDataFields
  }
  valeurAjoutee {
    ...basicNumericDataFields
  }
  chargePersonnel {
    ...basicNumericDataFields
  }
  ebe {
    ...basicNumericDataFields
  }
  resultatFinancier {
    ...basicNumericDataFields
  }
  resultatCourant {
    ...basicNumericDataFields
  }
  pointMort {
    ...basicNumericDataFields
  }
  margeManoeuvre {
    ...basicNumericDataFields
  }
  rotationStock {
    ...basicNumericDataFields
  }
  joursCa {
    ...basicNumericDataFields
  }
  periods {
    periodId
    effectifs
    chiffreAffaire {
      ...CalculatedFields
    }
    chiffreAffaireParPersonne {
      ...CalculatedFields
    }
    margeBrut {
      ...CalculatedFields
    }
    valeurAjoutee {
      ...CalculatedFields
    }
    chargePersonnel {
      ...CalculatedFields
    }
    ebe {
      ...CalculatedFields
    }
    resultatFinancier {
      ...CalculatedFields
    }
    resultatCourant {
      ...CalculatedFields
    }
    pointMort {
      ...CalculatedFields
    }
    margeManoeuvre {
      ...CalculatedFields
    }
    rotationStock {
      ...CalculatedFields
    }
    joursCa {
      ...CalculatedFields
    }
    periodType
  }
  comment
}
    ${NumericDataFieldsWithPercentageFragmentDoc}
${BasicNumericDataFieldsFragmentDoc}
${CalculatedFieldsFragmentDoc}`;
export const InvestissementsLineFieldsFragmentDoc = gql`
    fragment InvestissementsLineFields on AmortissementLine {
  id
  label
  montant {
    value
    detail
    kiloEuros
  }
  montantTTC {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  dureeAmortissement
  dateAchat
  tvaRate
  amortissementsExercices {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  montantTva {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
}
    `;
export const TotalFieldsFragmentDoc = gql`
    fragment TotalFields on InvestissementsSectionTotal {
  sommeMontants {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  sommeMontantsTTC {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  sommesAmortissementsExercices {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  sommeMontantsTva {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
}
    `;
export const InvestissementsFieldsFragmentDoc = gql`
    fragment InvestissementsFields on Investissements {
  id
  noteId
  investissementsIncorporels {
    lines {
      ...InvestissementsLineFields
    }
    total {
      ...TotalFields
    }
  }
  investissementsCorporels {
    lines {
      ...InvestissementsLineFields
    }
    total {
      ...TotalFields
    }
  }
  investissementsFinanciers {
    lines {
      ...InvestissementsLineFields
    }
    total {
      ...TotalFields
    }
  }
  total {
    ...TotalFields
  }
}
    ${InvestissementsLineFieldsFragmentDoc}
${TotalFieldsFragmentDoc}`;
export const MasseSalarialeFieldsFragmentDoc = gql`
    fragment MasseSalarialeFields on MasseSalariale {
  noteId
  fonctions {
    id
    fonction
    periods {
      periodId
      effectifs
      moisPayes
      salairesBruts {
        numericData {
          value
          kiloEuros
        }
        detail {
          labelDetail
          valuedDetail
          unit
          link
        }
      }
      salairesNets {
        numericData {
          value
          kiloEuros
        }
        detail {
          labelDetail
          valuedDetail
          unit
          link
        }
      }
      chargesPatronales {
        numericData {
          value
          kiloEuros
        }
        detail {
          labelDetail
          valuedDetail
          unit
          link
        }
      }
    }
    salaireBrutMoyen {
      detail
      value
      kiloEuros
      percentageReference
    }
    tauxChargesSalariales
    tauxChargesPatronales
  }
  croissanceAnnuelleSalaires
  periods {
    periodId
    effectifs {
      numericData {
        value
        kiloEuros
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    salairesNets {
      numericData {
        value
        kiloEuros
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    salairesBruts {
      numericData {
        value
        kiloEuros
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    chargesSocialesSalariales {
      numericData {
        value
        kiloEuros
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    chargesSocialesPatronales {
      numericData {
        value
        kiloEuros
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    coutTotalMainOeuvre {
      numericData {
        value
        kiloEuros
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    diffSalairesBruts {
      numericData {
        value
        kiloEuros
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    diffChargesSociales {
      numericData {
        value
        kiloEuros
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
  }
}
    `;
export const PlanFinancementRatiosFieldsFragmentDoc = gql`
    fragment PlanFinancementRatiosFields on PlanFinancementRatios {
  stock {
    numericData {
      value
      kiloEuros
      percentageReference
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  tresorerieDemarrage {
    numericData {
      value
      kiloEuros
      percentageReference
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  ressourcesStablesTotalRessources {
    numericData {
      value
      kiloEuros
      percentageReference
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  dettesMtCaPrevisionnel {
    numericData {
      value
      kiloEuros
      percentageReference
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  dettesMt {
    numericData {
      value
      kiloEuros
      percentageReference
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  exercicesRatios {
    fondsPropres {
      numericData {
        value
        kiloEuros
        percentageReference
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    dettesMlt {
      numericData {
        value
        kiloEuros
        percentageReference
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    dettesMltFondsPropres {
      numericData {
        value
        kiloEuros
        percentageReference
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    dettesCafPrevisionnelle {
      numericData {
        value
        kiloEuros
        percentageReference
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    remboursementDettes {
      numericData {
        value
        kiloEuros
        percentageReference
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    tresorerieJoursChargesExploitation {
      numericData {
        value
        kiloEuros
        percentageReference
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    creancesClients {
      value
      kiloEuros
    }
    creancesFinanceursPublics {
      value
      kiloEuros
    }
    dettesFournisseurs {
      value
      kiloEuros
    }
    bfr {
      value
      kiloEuros
    }
    fondsRoulement {
      numericData {
        value
        kiloEuros
        percentageReference
      }
      detail {
        labelDetail
        valuedDetail
        unit
        link
      }
    }
    tresorerieNette {
      value
      kiloEuros
    }
  }
  moyenneEBECedant {
    numericData {
      value
      kiloEuros
      percentageReference
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
}
    `;
export const PlanFinancementFieldsFragmentDoc = gql`
    fragment PlanFinancementFields on PlanFinancement {
  noteId
  comment
  commentPrixFondsCommerce
  investissementsCorporels {
    value
    detail
    kiloEuros
  }
  investissementsIncorporels {
    value
    detail
    kiloEuros
  }
  investissementsFinanciers {
    value
    detail
    kiloEuros
  }
  tvaSurInvestissements {
    value
    detail
    kiloEuros
  }
  tvaSurStock {
    value
    detail
    kiloEuros
  }
  stockDepart {
    value
    detail
    kiloEuros
  }
  tresorerieDemarrage {
    value
    detail
    kiloEuros
  }
  editableEntries {
    entryId
    demarrage {
      value
      detail
      kiloEuros
    }
    label
    type
  }
  totalBesoins {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  totalRessources {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
  solde {
    numericData {
      value
      kiloEuros
    }
    detail {
      labelDetail
      valuedDetail
      unit
      link
    }
  }
}
    `;
export const FinancialPeriodFieldsFragmentDoc = gql`
    fragment financialPeriodFields on FinancialPeriod {
  id
  startDate
  endDate
  duration
  incomeStatement {
    chiffre_affaire {
      hasDetail
      total {
        ...numericDataFieldsWithPercentage
      }
      detailLines {
        lines {
          id
          label
          required
          value {
            ...numericDataFieldsWithPercentage
          }
          variable
        }
        total
      }
    }
    subventions_exploitation {
      ...numericDataFieldsWithPercentage
    }
    aides_postes {
      ...numericDataFieldsWithPercentage
    }
    autres_produits_exploitation {
      hasDetail
      total {
        ...numericDataFieldsWithPercentage
      }
      prodution_stockee {
        ...numericDataFieldsWithPercentage
      }
      production_immobilisee {
        ...numericDataFieldsWithPercentage
      }
      transfert_charges {
        ...numericDataFieldsWithPercentage
      }
      reprises_amortissement_provisions {
        ...numericDataFieldsWithPercentage
      }
      adhesions {
        ...numericDataFieldsWithPercentage
      }
      autres_produits_exploitation {
        ...numericDataFieldsWithPercentage
      }
    }
    achat_marchandises {
      hasDetail
      total {
        ...numericDataFieldsWithPercentage
      }
      achat_marchandises {
        ...numericDataFieldsWithPercentage
      }
      achat_matieres_premieres {
        ...numericDataFieldsWithPercentage
      }
      variation_stock {
        ...numericDataFieldsWithPercentage
      }
    }
    autres_achats {
      hasDetail
      total {
        ...numericDataFieldsWithPercentage
      }
      totalChargesVariables {
        ...numericDataFieldsWithPercentage
      }
      fournitures_consommables {
        total
        lines {
          id
          label
          value {
            ...numericDataFieldsWithPercentage
          }
          required
          variable
        }
      }
      charges_externes {
        total
        lines {
          id
          label
          value {
            ...numericDataFieldsWithPercentage
          }
          required
          variable
        }
      }
    }
    taxes {
      hasDetail
      total {
        ...numericDataFieldsWithPercentage
      }
      cfe_cvae {
        ...numericDataFieldsWithPercentage
      }
      csg_crds {
        ...numericDataFieldsWithPercentage
      }
      taxe_apprentissage {
        ...numericDataFieldsWithPercentage
      }
      taxe_formation_professionnelle {
        ...numericDataFieldsWithPercentage
      }
      taxes_diverses {
        ...numericDataFieldsWithPercentage
      }
    }
    traitementsSalaires {
      ...numericDataFieldsWithPercentage
    }
    cotisationsSocialesPatronales {
      ...numericDataFieldsWithPercentage
    }
    cotisationsSocialesDirigeants {
      ...numericDataFieldsWithPercentage
    }
    autresChargesDePersonnel {
      ...numericDataFieldsWithPercentage
    }
    dotationsAmortissementsProvisions {
      ...numericDataFieldsWithPercentage
    }
    autresChargesExploitation {
      ...numericDataFieldsWithPercentage
    }
    resultatFinancier {
      hasDetail
      total {
        ...numericDataFieldsWithPercentage
      }
      produitsFinanciers {
        ...numericDataFieldsWithPercentage
      }
      reprisesSurProvisionsFinancieres {
        ...numericDataFieldsWithPercentage
      }
      chargesFinancieres {
        ...numericDataFieldsWithPercentage
      }
      dotationsAuxProvisionsFinancieres {
        ...numericDataFieldsWithPercentage
      }
      chargeInterets {
        ...numericDataFieldsWithPercentage
      }
      chargesInteretsPasses {
        ...numericDataFieldsWithPercentage
      }
      chargesInteretsFuturs {
        ...numericDataFieldsWithPercentage
      }
    }
    resultatExceptionnel {
      hasDetail
      total {
        ...numericDataFieldsWithPercentage
      }
      produitsExceptionnels {
        ...numericDataFieldsWithPercentage
      }
      produitsCessionsActifs {
        ...numericDataFieldsWithPercentage
      }
      reprisesSurProvisionsExceptionnelles {
        ...numericDataFieldsWithPercentage
      }
      quotePartSubventionsVireesAuResultat {
        ...numericDataFieldsWithPercentage
      }
      quotePartNouvellesSubventionsVireesAuResultat {
        ...numericDataFieldsWithPercentage
      }
      chargesExceptionnelles {
        ...numericDataFieldsWithPercentage
      }
      dotationsAuxProvisionsExceptionnelles {
        ...numericDataFieldsWithPercentage
      }
      valeursComptablesDesCessionsDactifs {
        ...numericDataFieldsWithPercentage
      }
    }
    participationInteressement {
      ...numericDataFieldsWithPercentage
    }
    impotsSurBenefices {
      ...CalculatedFields
    }
    reportRessourcesNonUtilisees {
      ...numericDataFieldsWithPercentage
    }
    engagementARealiserRessourcesAffectees {
      ...numericDataFieldsWithPercentage
    }
    totalProduitsExploitation {
      ...CalculatedFields
    }
    tauxDeChargesPatronales {
      ...CalculatedFields
    }
    tauxDeChargesDirigeants {
      ...CalculatedFields
    }
    totalChargesExploitations {
      ...CalculatedFields
    }
    tauxChargesVariables {
      ...CalculatedFields
    }
    resultatsExploitation {
      ...CalculatedFields
    }
    resultatNet {
      ...CalculatedFields
    }
    productionExercice {
      ...CalculatedFields
    }
    margeGlobale {
      ...CalculatedFields
    }
    valeurAjoutee {
      ...CalculatedFields
    }
    excedentBrutExploitation {
      ...CalculatedFields
    }
    totalChargesFixes {
      ...CalculatedFields
    }
    totalChargesVariables {
      ...CalculatedFields
    }
    impotsSurBeneficesInput {
      ...numericDataFieldsWithPercentage
    }
    isEmpty
  }
  tva {
    tva_chiffre_affaire
    tva_achats_et_charges
    detail_tva_chiffre_affaire {
      value
      details {
        ratio
        tauxTVA
      }
    }
    detail_tva_achat_marchandises {
      value
      details {
        ratio
        tauxTVA
      }
    }
    detail_tva_autres_achats {
      value
      details {
        ratio
        tauxTVA
      }
    }
  }
  caf {
    caf {
      ...CalculatedFields
    }
    dotations_amortissements_provisions {
      ...numericDataFieldsWithPercentage
    }
    dotations_provisions_exceptionnelles {
      ...numericDataFieldsWithPercentage
    }
    reprises_provisions_exceptionnelles {
      ...numericDataFieldsWithPercentage
    }
    dotations_provisions_financieres {
      ...numericDataFieldsWithPercentage
    }
    reprises_sur_provisions_financieres {
      ...numericDataFieldsWithPercentage
    }
    engagement_a_realiser_ressources_affectees {
      ...numericDataFieldsWithPercentage
    }
    produits_cessions_actifs {
      ...numericDataFieldsWithPercentage
    }
    quote_part_subventions_resultats {
      ...numericDataFieldsWithPercentage
    }
    report_ressources_non_utilisees_anterieures {
      ...numericDataFieldsWithPercentage
    }
    reprises_amortissements_provisions {
      ...numericDataFieldsWithPercentage
    }
    resultat_net {
      ...numericDataFieldsWithPercentage
    }
    valeurs_comptables_cessions_actifs {
      ...numericDataFieldsWithPercentage
    }
  }
  bfr {
    bfrTotal {
      ...CalculatedFields
    }
    bfrTotalBesoinExploitation {
      ...CalculatedFields
    }
    bfrTotalRessourcesExploitation {
      ...CalculatedFields
    }
    bfrNormatif {
      stock {
        ...CalculatedFields
      }
      avances_acomptes_fournisseurs {
        ...CalculatedFields
      }
      creances_clients {
        ...CalculatedFields
      }
      creances_financeurs_publics {
        ...CalculatedFields
      }
      autres_creances {
        ...CalculatedFields
      }
      avances_acomptes_client {
        ...CalculatedFields
      }
      dettes_fournisseurs {
        ...CalculatedFields
      }
      dettes_salaires {
        ...CalculatedFields
      }
      dettes_cotisations_sociales {
        ...CalculatedFields
      }
      dettes_fiscales_tva {
        ...CalculatedFields
      }
      dettes_fiscales_is_diverses {
        ...CalculatedFields
      }
      autres_dettes {
        ...CalculatedFields
      }
      dettes_fiscales_sociales {
        ...CalculatedFields
      }
    }
    bfrReel {
      stock {
        ...CalculatedFields
      }
      avances_acomptes_fournisseurs {
        ...CalculatedFields
      }
      creances_clients {
        ...CalculatedFields
      }
      creances_financeurs_publics {
        ...CalculatedFields
      }
      autres_creances {
        ...CalculatedFields
      }
      avances_acomptes_client {
        ...CalculatedFields
      }
      dettes_fournisseurs {
        ...CalculatedFields
      }
      dettes_salaires {
        ...CalculatedFields
      }
      dettes_cotisations_sociales {
        ...CalculatedFields
      }
      dettes_fiscales_tva {
        ...CalculatedFields
      }
      dettes_fiscales_is_diverses {
        ...CalculatedFields
      }
      autres_dettes {
        ...CalculatedFields
      }
      variation {
        ...CalculatedFields
      }
    }
  }
  remboursementsEmprunts {
    remboursementsAT {
      ...CalculatedFields
    }
    remboursementsHorsAT {
      ...CalculatedFields
    }
    total {
      ...CalculatedFields
    }
  }
  besoinsFixes {
    ...CalculatedFields
  }
  pointMort {
    ...CalculatedFields
  }
  margeDeManoeuvre {
    ...CalculatedFields
  }
  periodPlanFinancement {
    investissementsCorporels {
      ...basicNumericDataFields
    }
    investissementsIncorporels {
      ...basicNumericDataFields
    }
    investissementsFinanciers {
      ...basicNumericDataFields
    }
    remboursementsPretsPasses {
      ...basicNumericDataFields
    }
    dividendes {
      ...basicNumericDataFields
    }
    editableEntries {
      entryId
      data {
        ...basicNumericDataFields
      }
    }
    prets {
      entryId
      data {
        ...basicNumericDataFields
      }
    }
    primes {
      entryId
      data {
        ...basicNumericDataFields
      }
    }
    apportsPersonnels {
      entryId
      data {
        ...basicNumericDataFields
      }
    }
    totalBesoins {
      ...CalculatedFields
    }
    totalRessources {
      ...CalculatedFields
    }
    solde {
      ...CalculatedFields
    }
    soldeCumule {
      ...CalculatedFields
    }
  }
  prelevementTns {
    ...numericDataFieldsWithPercentage
  }
  periodType
  bilanCedant {
    immobilisationsCorporelles {
      ...basicNumericDataFields
    }
    immobilisationsIncorporelles {
      ...basicNumericDataFields
    }
    immobilisationsFinancieres {
      ...basicNumericDataFields
    }
    stocksEtEncours {
      ...basicNumericDataFields
    }
    avancesAccomptesFournisseurs {
      ...basicNumericDataFields
    }
    creancesClients {
      ...basicNumericDataFields
    }
    subventionsARecevoir {
      ...basicNumericDataFields
    }
    autresCreances {
      ...basicNumericDataFields
    }
    disponibilitesEtVMP {
      ...basicNumericDataFields
    }
    chargesConstatees {
      ...basicNumericDataFields
    }
    capitalSocial {
      chosenLabel
      numericData {
        ...basicNumericDataFields
      }
    }
    subventionsInvestissement {
      ...basicNumericDataFields
    }
    reserves {
      ...basicNumericDataFields
    }
    reportANouveau {
      ...basicNumericDataFields
    }
    resultat {
      ...basicNumericDataFields
    }
    autresFondsPropres {
      ...basicNumericDataFields
    }
    provisionsRisques {
      ...basicNumericDataFields
    }
    dettesFinanciereMoyenLongTerme {
      ...basicNumericDataFields
    }
    dettesFinanciereCourtTerme {
      ...basicNumericDataFields
    }
    avancesAccomptesClients {
      ...basicNumericDataFields
    }
    dettesFournisseurs {
      ...basicNumericDataFields
    }
    dettesFiscalesSociales {
      ...basicNumericDataFields
    }
    dettesFiscalesSocialesWithDetail {
      total {
        ...basicNumericDataFields
      }
      hasDetail
      dettesCotisationsSociales {
        ...basicNumericDataFields
      }
      dettesFiscalesIsDiverses {
        ...basicNumericDataFields
      }
      dettesFiscalesTva {
        ...basicNumericDataFields
      }
      dettesSalaires {
        ...basicNumericDataFields
      }
    }
    autresDettes {
      ...basicNumericDataFields
    }
    produitsConstates {
      ...basicNumericDataFields
    }
    totalActifImmobilise {
      ...CalculatedFields
    }
    totalActifCirculant {
      ...CalculatedFields
    }
    totalActif {
      ...CalculatedFields
    }
    totalFondsPropres {
      ...CalculatedFields
    }
    totalDettesCirculantes {
      ...CalculatedFields
    }
    totalPassif {
      ...CalculatedFields
    }
    fondsRoulement {
      ...CalculatedFields
    }
    bfr {
      ...CalculatedFields
    }
    tresorerieNette {
      ...CalculatedFields
    }
    estimationDividendes {
      ...CalculatedFields
    }
    isEmpty
  }
  bfrAnterieur {
    normatif {
      stock {
        ...CalculatedFields
      }
      avances_acomptes_fournisseurs {
        ...CalculatedFields
      }
      creances_clients {
        ...CalculatedFields
      }
      creances_financeurs_publics {
        ...CalculatedFields
      }
      autres_creances {
        ...CalculatedFields
      }
      avances_acomptes_client {
        ...CalculatedFields
      }
      dettes_fournisseurs {
        ...CalculatedFields
      }
      dettes_fiscales {
        total {
          ...CalculatedFields
        }
        dettesCotisationsSociales {
          ...CalculatedFields
        }
        dettesSalaires {
          ...CalculatedFields
        }
        dettesFiscalesIsDiverses {
          ...CalculatedFields
        }
        dettesFiscalesTva {
          ...CalculatedFields
        }
        hasDetail
      }
      autres_dettes {
        ...CalculatedFields
      }
    }
    reel {
      stock {
        ...CalculatedFields
      }
      avances_acomptes_fournisseurs {
        ...CalculatedFields
      }
      creances_clients {
        ...CalculatedFields
      }
      creances_financeurs_publics {
        ...CalculatedFields
      }
      autres_creances {
        ...CalculatedFields
      }
      charges_constatees {
        ...CalculatedFields
      }
      total_besoins {
        ...CalculatedFields
      }
      avances_acomptes_client {
        ...CalculatedFields
      }
      dettes_fournisseurs {
        ...CalculatedFields
      }
      dettes_fiscales {
        total {
          ...CalculatedFields
        }
        dettesCotisationsSociales {
          ...CalculatedFields
        }
        dettesSalaires {
          ...CalculatedFields
        }
        dettesFiscalesIsDiverses {
          ...CalculatedFields
        }
        dettesFiscalesTva {
          ...CalculatedFields
        }
        hasDetail
      }
      autres_dettes {
        ...CalculatedFields
      }
      produits_constates {
        ...CalculatedFields
      }
      total_ressources {
        ...CalculatedFields
      }
      total {
        ...CalculatedFields
      }
      variation {
        ...CalculatedFields
      }
    }
  }
}
    ${NumericDataFieldsWithPercentageFragmentDoc}
${CalculatedFieldsFragmentDoc}
${BasicNumericDataFieldsFragmentDoc}`;
export const ComputedPretFieldsFragmentDoc = gql`
    fragment ComputedPretFields on ComputedPret {
  montantEcheance
  tableauAmortissement {
    amortissement
    capitalDebut
    capitalFin
    echeance
    interets
    montantGlobal
  }
  tauxEcheance
  totalEcheance
  totalDuration
}
    `;
export const PretFieldsFragmentDoc = gql`
    fragment PretFields on Pret {
  annualRate
  capital {
    ...basicNumericDataFields
  }
  computedPret {
    ...ComputedPretFields
  }
  delayed
  duration
  id
  inFine
  startingDate
  firstRepaymentDate
  noteId
  title
  yearlyPaymentNb
  demarragePlanFinancement {
    ...basicNumericDataFields
  }
  type
  guarantees {
    id
    title
    outilId
    duration
    montant {
      ...basicNumericDataFields
    }
    quotite
  }
  externalized
  isInDemarrage
  showIsInDemarrageOption
}
    ${BasicNumericDataFieldsFragmentDoc}
${ComputedPretFieldsFragmentDoc}`;
export const PretsFieldsFragmentDoc = gql`
    fragment PretsFields on Ressources {
  pretSynthese {
    pretsBancaires {
      montantTotal {
        ...CalculatedFields
      }
      nombrePrets
      nombrePretsGarantis
      totalMontantGaranti {
        ...CalculatedFields
      }
      totalPretsGarantis {
        ...CalculatedFields
      }
    }
    pretsNonBancaires {
      montantTotal {
        ...CalculatedFields
      }
      nombrePrets
      nombrePretsAT
      nombrePretsExternes
      totalPretAT {
        ...CalculatedFields
      }
      totalPretsExternes {
        ...CalculatedFields
      }
    }
  }
  prets {
    ...PretFields
  }
  primes {
    capital {
      ...basicNumericDataFields
    }
    demarragePlanFinancement {
      ...basicNumericDataFields
    }
    id
    noteId
    title
  }
  apports {
    label
    montant {
      ...basicNumericDataFields
    }
    periodId
  }
}
    ${CalculatedFieldsFragmentDoc}
${PretFieldsFragmentDoc}
${BasicNumericDataFieldsFragmentDoc}`;
export const PretPasseFieldsFragmentDoc = gql`
    fragment PretPasseFields on PretPasse {
  annualRate
  capital {
    ...basicNumericDataFields
  }
  delayed
  duration
  id
  inFine
  startingDate
  firstRepaymentDate
  noteId
  title
  yearlyPaymentNb
  computedPret {
    ...ComputedPretFields
  }
}
    ${BasicNumericDataFieldsFragmentDoc}
${ComputedPretFieldsFragmentDoc}`;
export const NoteFieldsFragmentDoc = gql`
    fragment NoteFields on Note {
  noteId
  structureId
  comment
  commentCr
  numeroVersion
  statut
  conclusion
  modelEco
  noteFinancier
  noteGlobale
  avancementBanque
  commentCrAnterieur
  creationExNihilo
  pdfPreferences {
    caDetails
    sig
    chargesDetails
    indicateurs
    rappelBFR
    benchmark
    investissements
    showCaDetails
    showChargesDetails
    showInvestissements
    allAnterieursPeriods
    showBilanCedant
    bilanCedant
    showAnterieurPeriodsOption
    revelateurEngagement
    pourcentageCR
  }
  partenaireBancaire {
    agence
    contact
    partenaire
  }
  dateComite
  advisor {
    firstName
    lastName
    email
  }
  onlyPrime
  lifecycle
  commentReason
  type
  commentRevelateur
  commentBilanPasse
  lastSyncDate
}
    `;
export const RubriqueMementoAdvisorFiedlsFragmentDoc = gql`
    fragment RubriqueMementoAdvisorFiedls on RubriqueMementoAdvisor {
  rubriqueId
  ordre
  titre
  description
  rubriqueComment
}
    `;
export const ExportMementoFieldsFragmentDoc = gql`
    fragment ExportMementoFields on ExportMemento {
  noteId
  exportDate
  showAnalyseConclusion
  showAnalyse
  showPlanFinancement
  showCr
  showCrComments
  showCrIndicateurs
  showDetailCa
  showInvestissements
  showBenchmark
  showPfAnalytique
  showRevelateur
  showBilan
  showBfr
  lstRubriqueMementoAdvisor {
    ...RubriqueMementoAdvisorFiedls
  }
}
    ${RubriqueMementoAdvisorFiedlsFragmentDoc}`;
export const MementoFieldsFragmentDoc = gql`
    fragment MementoFields on Memento {
  projectId
  showAnalyseConclusion
  showAnalyse
  showPlanFinancement
  showCr
  showCrComments
  showCrIndicateurs
  showDetailCa
  showInvestissements
  showBenchmark
  showPfAnalytique
  showRevelateur
  showBilan
  showBfr
  lstRubriqueMementoAdvisor {
    ...RubriqueMementoAdvisorFiedls
  }
  unpublishDate
  export {
    ...ExportMementoFields
  }
}
    ${RubriqueMementoAdvisorFiedlsFragmentDoc}
${ExportMementoFieldsFragmentDoc}`;
export const ScoringParameterFieldsFragmentDoc = gql`
    fragment ScoringParameterFields on ScoringParameter {
  conditionOk
  label
  origin
  required
  yodaOrigin
}
    `;
export const FullHouseholdFragmentDoc = gql`
    fragment FullHousehold on Entrepreneur {
  houseHold {
    childNumber
    childInCharge
    otherPersonInCharge
    resteAVivre
    incomes {
      id
      contactType
      label
      amount
      fromMonth
      endMonth
    }
    expenses {
      id
      label
      amount
      fromMonth
      endMonth
    }
  }
}
    `;
export const ComiteSimplifieDocument = gql`
    query ComiteSimplifie($noteId: String!) {
  comiteSimplifie(noteId: $noteId)
}
    `;

/**
 * __useComiteSimplifieQuery__
 *
 * To run a query within a React component, call `useComiteSimplifieQuery` and pass it any options that fit your needs.
 * When your component renders, `useComiteSimplifieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComiteSimplifieQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useComiteSimplifieQuery(baseOptions: Apollo.QueryHookOptions<Types.ComiteSimplifieQuery, Types.ComiteSimplifieQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ComiteSimplifieQuery, Types.ComiteSimplifieQueryVariables>(ComiteSimplifieDocument, options);
      }
export function useComiteSimplifieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ComiteSimplifieQuery, Types.ComiteSimplifieQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ComiteSimplifieQuery, Types.ComiteSimplifieQueryVariables>(ComiteSimplifieDocument, options);
        }
export type ComiteSimplifieQueryHookResult = ReturnType<typeof useComiteSimplifieQuery>;
export type ComiteSimplifieLazyQueryHookResult = ReturnType<typeof useComiteSimplifieLazyQuery>;
export type ComiteSimplifieQueryResult = Apollo.QueryResult<Types.ComiteSimplifieQuery, Types.ComiteSimplifieQueryVariables>;
export const ConditionsUtilisationAndComiteSimplifieDocument = gql`
    query ConditionsUtilisationAndComiteSimplifie($noteId: String!) {
  conditionsUtilisation(noteId: $noteId) {
    isConforme
    isChecked
    lastCheckDate
    termsNotMet {
      ressource {
        outilId
        label
      }
      messages
    }
  }
  comiteSimplifie(noteId: $noteId)
}
    `;

/**
 * __useConditionsUtilisationAndComiteSimplifieQuery__
 *
 * To run a query within a React component, call `useConditionsUtilisationAndComiteSimplifieQuery` and pass it any options that fit your needs.
 * When your component renders, `useConditionsUtilisationAndComiteSimplifieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConditionsUtilisationAndComiteSimplifieQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useConditionsUtilisationAndComiteSimplifieQuery(baseOptions: Apollo.QueryHookOptions<Types.ConditionsUtilisationAndComiteSimplifieQuery, Types.ConditionsUtilisationAndComiteSimplifieQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConditionsUtilisationAndComiteSimplifieQuery, Types.ConditionsUtilisationAndComiteSimplifieQueryVariables>(ConditionsUtilisationAndComiteSimplifieDocument, options);
      }
export function useConditionsUtilisationAndComiteSimplifieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConditionsUtilisationAndComiteSimplifieQuery, Types.ConditionsUtilisationAndComiteSimplifieQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConditionsUtilisationAndComiteSimplifieQuery, Types.ConditionsUtilisationAndComiteSimplifieQueryVariables>(ConditionsUtilisationAndComiteSimplifieDocument, options);
        }
export type ConditionsUtilisationAndComiteSimplifieQueryHookResult = ReturnType<typeof useConditionsUtilisationAndComiteSimplifieQuery>;
export type ConditionsUtilisationAndComiteSimplifieLazyQueryHookResult = ReturnType<typeof useConditionsUtilisationAndComiteSimplifieLazyQuery>;
export type ConditionsUtilisationAndComiteSimplifieQueryResult = Apollo.QueryResult<Types.ConditionsUtilisationAndComiteSimplifieQuery, Types.ConditionsUtilisationAndComiteSimplifieQueryVariables>;
export const CheckConditionsUtilisationDocument = gql`
    mutation CheckConditionsUtilisation($noteId: String!) {
  conditionsUtilisation {
    check(noteId: $noteId) {
      isConforme
      isChecked
      lastCheckDate
      termsNotMet {
        ressource {
          outilId
          label
        }
        messages
      }
    }
  }
}
    `;
export type CheckConditionsUtilisationMutationFn = Apollo.MutationFunction<Types.CheckConditionsUtilisationMutation, Types.CheckConditionsUtilisationMutationVariables>;

/**
 * __useCheckConditionsUtilisationMutation__
 *
 * To run a mutation, you first call `useCheckConditionsUtilisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckConditionsUtilisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkConditionsUtilisationMutation, { data, loading, error }] = useCheckConditionsUtilisationMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useCheckConditionsUtilisationMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheckConditionsUtilisationMutation, Types.CheckConditionsUtilisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheckConditionsUtilisationMutation, Types.CheckConditionsUtilisationMutationVariables>(CheckConditionsUtilisationDocument, options);
      }
export type CheckConditionsUtilisationMutationHookResult = ReturnType<typeof useCheckConditionsUtilisationMutation>;
export type CheckConditionsUtilisationMutationResult = Apollo.MutationResult<Types.CheckConditionsUtilisationMutation>;
export type CheckConditionsUtilisationMutationOptions = Apollo.BaseMutationOptions<Types.CheckConditionsUtilisationMutation, Types.CheckConditionsUtilisationMutationVariables>;
export const QuestionsDocument = gql`
    query Questions($noteId: ID!, $forceV2: Boolean) {
  involvementCheck {
    categories {
      category
      questions(noteId: $noteId, forceV2: $forceV2) {
        id
        description
        text
        questionVersion
        questionType
      }
    }
  }
}
    `;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      forceV2: // value for 'forceV2'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions: Apollo.QueryHookOptions<Types.QuestionsQuery, Types.QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.QuestionsQuery, Types.QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.QuestionsQuery, Types.QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.QuestionsQuery, Types.QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<Types.QuestionsQuery, Types.QuestionsQueryVariables>;
export const ProjectInvolvementResultDocument = gql`
    query ProjectInvolvementResult($noteId: ID!, $forceV2: Boolean) {
  involvementCheckResults {
    results(noteId: $noteId, forceV2: $forceV2) {
      personalInvolvement
      categoryResults {
        score
        category
        mean
        questionResults {
          score
          comment
          questionId
          noteId
          forecastImpact
        }
        valid
      }
      version
      involvementProfileV2
      cornerstoneCategories
    }
  }
}
    `;

/**
 * __useProjectInvolvementResultQuery__
 *
 * To run a query within a React component, call `useProjectInvolvementResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectInvolvementResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectInvolvementResultQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      forceV2: // value for 'forceV2'
 *   },
 * });
 */
export function useProjectInvolvementResultQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectInvolvementResultQuery, Types.ProjectInvolvementResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectInvolvementResultQuery, Types.ProjectInvolvementResultQueryVariables>(ProjectInvolvementResultDocument, options);
      }
export function useProjectInvolvementResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectInvolvementResultQuery, Types.ProjectInvolvementResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectInvolvementResultQuery, Types.ProjectInvolvementResultQueryVariables>(ProjectInvolvementResultDocument, options);
        }
export type ProjectInvolvementResultQueryHookResult = ReturnType<typeof useProjectInvolvementResultQuery>;
export type ProjectInvolvementResultLazyQueryHookResult = ReturnType<typeof useProjectInvolvementResultLazyQuery>;
export type ProjectInvolvementResultQueryResult = Apollo.QueryResult<Types.ProjectInvolvementResultQuery, Types.ProjectInvolvementResultQueryVariables>;
export const OnlyInvolvementDocument = gql`
    query OnlyInvolvement($noteId: ID!) {
  involvementCheckResults {
    results(noteId: $noteId) {
      personalInvolvement
    }
  }
}
    `;

/**
 * __useOnlyInvolvementQuery__
 *
 * To run a query within a React component, call `useOnlyInvolvementQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlyInvolvementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlyInvolvementQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useOnlyInvolvementQuery(baseOptions: Apollo.QueryHookOptions<Types.OnlyInvolvementQuery, Types.OnlyInvolvementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OnlyInvolvementQuery, Types.OnlyInvolvementQueryVariables>(OnlyInvolvementDocument, options);
      }
export function useOnlyInvolvementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OnlyInvolvementQuery, Types.OnlyInvolvementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OnlyInvolvementQuery, Types.OnlyInvolvementQueryVariables>(OnlyInvolvementDocument, options);
        }
export type OnlyInvolvementQueryHookResult = ReturnType<typeof useOnlyInvolvementQuery>;
export type OnlyInvolvementLazyQueryHookResult = ReturnType<typeof useOnlyInvolvementLazyQuery>;
export type OnlyInvolvementQueryResult = Apollo.QueryResult<Types.OnlyInvolvementQuery, Types.OnlyInvolvementQueryVariables>;
export const SaveResultDocument = gql`
    mutation SaveResult($noteId: ID!, $request: ResultSaveRequestInput!) {
  involvementCheck {
    saveResult(noteId: $noteId, request: $request) {
      personalInvolvement
      noteId
    }
  }
}
    `;
export type SaveResultMutationFn = Apollo.MutationFunction<Types.SaveResultMutation, Types.SaveResultMutationVariables>;

/**
 * __useSaveResultMutation__
 *
 * To run a mutation, you first call `useSaveResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveResultMutation, { data, loading, error }] = useSaveResultMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveResultMutation(baseOptions?: Apollo.MutationHookOptions<Types.SaveResultMutation, Types.SaveResultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SaveResultMutation, Types.SaveResultMutationVariables>(SaveResultDocument, options);
      }
export type SaveResultMutationHookResult = ReturnType<typeof useSaveResultMutation>;
export type SaveResultMutationResult = Apollo.MutationResult<Types.SaveResultMutation>;
export type SaveResultMutationOptions = Apollo.BaseMutationOptions<Types.SaveResultMutation, Types.SaveResultMutationVariables>;
export const SaveAnswerDocument = gql`
    mutation SaveAnswer($noteId: ID!, $request: QuestionResultSaveRequestInput!) {
  involvementCheck {
    saveQuestionResult(noteId: $noteId, request: $request) {
      noteId
      categoryResults {
        category
        mean
        score
        valid
        questionResults {
          id
          noteId
          questionId
          comment
          score
          forecastImpact
        }
      }
    }
  }
}
    `;
export type SaveAnswerMutationFn = Apollo.MutationFunction<Types.SaveAnswerMutation, Types.SaveAnswerMutationVariables>;

/**
 * __useSaveAnswerMutation__
 *
 * To run a mutation, you first call `useSaveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAnswerMutation, { data, loading, error }] = useSaveAnswerMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useSaveAnswerMutation(baseOptions?: Apollo.MutationHookOptions<Types.SaveAnswerMutation, Types.SaveAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SaveAnswerMutation, Types.SaveAnswerMutationVariables>(SaveAnswerDocument, options);
      }
export type SaveAnswerMutationHookResult = ReturnType<typeof useSaveAnswerMutation>;
export type SaveAnswerMutationResult = Apollo.MutationResult<Types.SaveAnswerMutation>;
export type SaveAnswerMutationOptions = Apollo.BaseMutationOptions<Types.SaveAnswerMutation, Types.SaveAnswerMutationVariables>;
export const SyncMuffinDataDocument = gql`
    mutation SyncMuffinData($noteId: String!) {
  note {
    syncMuffinData(noteId: $noteId) {
      lastSyncDate
      noteStatus
    }
  }
}
    `;
export type SyncMuffinDataMutationFn = Apollo.MutationFunction<Types.SyncMuffinDataMutation, Types.SyncMuffinDataMutationVariables>;

/**
 * __useSyncMuffinDataMutation__
 *
 * To run a mutation, you first call `useSyncMuffinDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncMuffinDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncMuffinDataMutation, { data, loading, error }] = useSyncMuffinDataMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useSyncMuffinDataMutation(baseOptions?: Apollo.MutationHookOptions<Types.SyncMuffinDataMutation, Types.SyncMuffinDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SyncMuffinDataMutation, Types.SyncMuffinDataMutationVariables>(SyncMuffinDataDocument, options);
      }
export type SyncMuffinDataMutationHookResult = ReturnType<typeof useSyncMuffinDataMutation>;
export type SyncMuffinDataMutationResult = Apollo.MutationResult<Types.SyncMuffinDataMutation>;
export type SyncMuffinDataMutationOptions = Apollo.BaseMutationOptions<Types.SyncMuffinDataMutation, Types.SyncMuffinDataMutationVariables>;
export const BenchMarkDocument = gql`
    query BenchMark($noteId: String!) {
  benchMark(noteId: $noteId) {
    ...BenchMarkFields
  }
}
    ${BenchMarkFieldsFragmentDoc}`;

/**
 * __useBenchMarkQuery__
 *
 * To run a query within a React component, call `useBenchMarkQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchMarkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchMarkQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useBenchMarkQuery(baseOptions: Apollo.QueryHookOptions<Types.BenchMarkQuery, Types.BenchMarkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BenchMarkQuery, Types.BenchMarkQueryVariables>(BenchMarkDocument, options);
      }
export function useBenchMarkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BenchMarkQuery, Types.BenchMarkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BenchMarkQuery, Types.BenchMarkQueryVariables>(BenchMarkDocument, options);
        }
export type BenchMarkQueryHookResult = ReturnType<typeof useBenchMarkQuery>;
export type BenchMarkLazyQueryHookResult = ReturnType<typeof useBenchMarkLazyQuery>;
export type BenchMarkQueryResult = Apollo.QueryResult<Types.BenchMarkQuery, Types.BenchMarkQueryVariables>;
export const UpdateBenchMarkDocument = gql`
    mutation UpdateBenchMark($update: BenchMarkInput!) {
  benchMark {
    update(input: $update) {
      ...BenchMarkFields
    }
  }
}
    ${BenchMarkFieldsFragmentDoc}`;
export type UpdateBenchMarkMutationFn = Apollo.MutationFunction<Types.UpdateBenchMarkMutation, Types.UpdateBenchMarkMutationVariables>;

/**
 * __useUpdateBenchMarkMutation__
 *
 * To run a mutation, you first call `useUpdateBenchMarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenchMarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenchMarkMutation, { data, loading, error }] = useUpdateBenchMarkMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateBenchMarkMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateBenchMarkMutation, Types.UpdateBenchMarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateBenchMarkMutation, Types.UpdateBenchMarkMutationVariables>(UpdateBenchMarkDocument, options);
      }
export type UpdateBenchMarkMutationHookResult = ReturnType<typeof useUpdateBenchMarkMutation>;
export type UpdateBenchMarkMutationResult = Apollo.MutationResult<Types.UpdateBenchMarkMutation>;
export type UpdateBenchMarkMutationOptions = Apollo.BaseMutationOptions<Types.UpdateBenchMarkMutation, Types.UpdateBenchMarkMutationVariables>;
export const InvestissementsDocument = gql`
    query Investissements($noteId: String!) {
  investissements(noteId: $noteId) {
    ...InvestissementsFields
  }
}
    ${InvestissementsFieldsFragmentDoc}`;

/**
 * __useInvestissementsQuery__
 *
 * To run a query within a React component, call `useInvestissementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestissementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestissementsQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useInvestissementsQuery(baseOptions: Apollo.QueryHookOptions<Types.InvestissementsQuery, Types.InvestissementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.InvestissementsQuery, Types.InvestissementsQueryVariables>(InvestissementsDocument, options);
      }
export function useInvestissementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.InvestissementsQuery, Types.InvestissementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.InvestissementsQuery, Types.InvestissementsQueryVariables>(InvestissementsDocument, options);
        }
export type InvestissementsQueryHookResult = ReturnType<typeof useInvestissementsQuery>;
export type InvestissementsLazyQueryHookResult = ReturnType<typeof useInvestissementsLazyQuery>;
export type InvestissementsQueryResult = Apollo.QueryResult<Types.InvestissementsQuery, Types.InvestissementsQueryVariables>;
export const UpdateInvestissementsDocument = gql`
    mutation UpdateInvestissements($noteId: String!, $update: UpdateInvestissementsInput!) {
  investissements {
    patch(input: $update, noteId: $noteId) {
      ...InvestissementsFields
    }
  }
}
    ${InvestissementsFieldsFragmentDoc}`;
export type UpdateInvestissementsMutationFn = Apollo.MutationFunction<Types.UpdateInvestissementsMutation, Types.UpdateInvestissementsMutationVariables>;

/**
 * __useUpdateInvestissementsMutation__
 *
 * To run a mutation, you first call `useUpdateInvestissementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvestissementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvestissementsMutation, { data, loading, error }] = useUpdateInvestissementsMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateInvestissementsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateInvestissementsMutation, Types.UpdateInvestissementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateInvestissementsMutation, Types.UpdateInvestissementsMutationVariables>(UpdateInvestissementsDocument, options);
      }
export type UpdateInvestissementsMutationHookResult = ReturnType<typeof useUpdateInvestissementsMutation>;
export type UpdateInvestissementsMutationResult = Apollo.MutationResult<Types.UpdateInvestissementsMutation>;
export type UpdateInvestissementsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateInvestissementsMutation, Types.UpdateInvestissementsMutationVariables>;
export const MasseSalarialeDocument = gql`
    query MasseSalariale($noteId: String!) {
  masseSalariale(noteId: $noteId) {
    ...MasseSalarialeFields
  }
}
    ${MasseSalarialeFieldsFragmentDoc}`;

/**
 * __useMasseSalarialeQuery__
 *
 * To run a query within a React component, call `useMasseSalarialeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMasseSalarialeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMasseSalarialeQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useMasseSalarialeQuery(baseOptions: Apollo.QueryHookOptions<Types.MasseSalarialeQuery, Types.MasseSalarialeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MasseSalarialeQuery, Types.MasseSalarialeQueryVariables>(MasseSalarialeDocument, options);
      }
export function useMasseSalarialeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MasseSalarialeQuery, Types.MasseSalarialeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MasseSalarialeQuery, Types.MasseSalarialeQueryVariables>(MasseSalarialeDocument, options);
        }
export type MasseSalarialeQueryHookResult = ReturnType<typeof useMasseSalarialeQuery>;
export type MasseSalarialeLazyQueryHookResult = ReturnType<typeof useMasseSalarialeLazyQuery>;
export type MasseSalarialeQueryResult = Apollo.QueryResult<Types.MasseSalarialeQuery, Types.MasseSalarialeQueryVariables>;
export const UpdateMasseSalarialeDocument = gql`
    mutation UpdateMasseSalariale($update: MasseSalarialeInput!) {
  masseSalariale {
    update(input: $update) {
      ...MasseSalarialeFields
    }
  }
}
    ${MasseSalarialeFieldsFragmentDoc}`;
export type UpdateMasseSalarialeMutationFn = Apollo.MutationFunction<Types.UpdateMasseSalarialeMutation, Types.UpdateMasseSalarialeMutationVariables>;

/**
 * __useUpdateMasseSalarialeMutation__
 *
 * To run a mutation, you first call `useUpdateMasseSalarialeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasseSalarialeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasseSalarialeMutation, { data, loading, error }] = useUpdateMasseSalarialeMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateMasseSalarialeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMasseSalarialeMutation, Types.UpdateMasseSalarialeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateMasseSalarialeMutation, Types.UpdateMasseSalarialeMutationVariables>(UpdateMasseSalarialeDocument, options);
      }
export type UpdateMasseSalarialeMutationHookResult = ReturnType<typeof useUpdateMasseSalarialeMutation>;
export type UpdateMasseSalarialeMutationResult = Apollo.MutationResult<Types.UpdateMasseSalarialeMutation>;
export type UpdateMasseSalarialeMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMasseSalarialeMutation, Types.UpdateMasseSalarialeMutationVariables>;
export const UpdateMasseSalarialeFonctionDocument = gql`
    mutation UpdateMasseSalarialeFonction($update: MasseSalarialeFonctionInput!, $noteId: String!) {
  masseSalariale {
    updateLine(input: $update, noteId: $noteId) {
      ...MasseSalarialeFields
    }
  }
}
    ${MasseSalarialeFieldsFragmentDoc}`;
export type UpdateMasseSalarialeFonctionMutationFn = Apollo.MutationFunction<Types.UpdateMasseSalarialeFonctionMutation, Types.UpdateMasseSalarialeFonctionMutationVariables>;

/**
 * __useUpdateMasseSalarialeFonctionMutation__
 *
 * To run a mutation, you first call `useUpdateMasseSalarialeFonctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMasseSalarialeFonctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMasseSalarialeFonctionMutation, { data, loading, error }] = useUpdateMasseSalarialeFonctionMutation({
 *   variables: {
 *      update: // value for 'update'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useUpdateMasseSalarialeFonctionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMasseSalarialeFonctionMutation, Types.UpdateMasseSalarialeFonctionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateMasseSalarialeFonctionMutation, Types.UpdateMasseSalarialeFonctionMutationVariables>(UpdateMasseSalarialeFonctionDocument, options);
      }
export type UpdateMasseSalarialeFonctionMutationHookResult = ReturnType<typeof useUpdateMasseSalarialeFonctionMutation>;
export type UpdateMasseSalarialeFonctionMutationResult = Apollo.MutationResult<Types.UpdateMasseSalarialeFonctionMutation>;
export type UpdateMasseSalarialeFonctionMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMasseSalarialeFonctionMutation, Types.UpdateMasseSalarialeFonctionMutationVariables>;
export const DeleteMasseSalarialeFonctionDocument = gql`
    mutation DeleteMasseSalarialeFonction($id: Int!) {
  masseSalariale {
    deleteMasseSalarialeFonction(id: $id)
  }
}
    `;
export type DeleteMasseSalarialeFonctionMutationFn = Apollo.MutationFunction<Types.DeleteMasseSalarialeFonctionMutation, Types.DeleteMasseSalarialeFonctionMutationVariables>;

/**
 * __useDeleteMasseSalarialeFonctionMutation__
 *
 * To run a mutation, you first call `useDeleteMasseSalarialeFonctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMasseSalarialeFonctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMasseSalarialeFonctionMutation, { data, loading, error }] = useDeleteMasseSalarialeFonctionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMasseSalarialeFonctionMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteMasseSalarialeFonctionMutation, Types.DeleteMasseSalarialeFonctionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteMasseSalarialeFonctionMutation, Types.DeleteMasseSalarialeFonctionMutationVariables>(DeleteMasseSalarialeFonctionDocument, options);
      }
export type DeleteMasseSalarialeFonctionMutationHookResult = ReturnType<typeof useDeleteMasseSalarialeFonctionMutation>;
export type DeleteMasseSalarialeFonctionMutationResult = Apollo.MutationResult<Types.DeleteMasseSalarialeFonctionMutation>;
export type DeleteMasseSalarialeFonctionMutationOptions = Apollo.BaseMutationOptions<Types.DeleteMasseSalarialeFonctionMutation, Types.DeleteMasseSalarialeFonctionMutationVariables>;
export const RatiosDocument = gql`
    query Ratios($noteId: ID!) {
  ratios(noteId: $noteId) {
    ...PlanFinancementRatiosFields
  }
}
    ${PlanFinancementRatiosFieldsFragmentDoc}`;

/**
 * __useRatiosQuery__
 *
 * To run a query within a React component, call `useRatiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatiosQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useRatiosQuery(baseOptions: Apollo.QueryHookOptions<Types.RatiosQuery, Types.RatiosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RatiosQuery, Types.RatiosQueryVariables>(RatiosDocument, options);
      }
export function useRatiosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RatiosQuery, Types.RatiosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RatiosQuery, Types.RatiosQueryVariables>(RatiosDocument, options);
        }
export type RatiosQueryHookResult = ReturnType<typeof useRatiosQuery>;
export type RatiosLazyQueryHookResult = ReturnType<typeof useRatiosLazyQuery>;
export type RatiosQueryResult = Apollo.QueryResult<Types.RatiosQuery, Types.RatiosQueryVariables>;
export const PlanFinancementDocument = gql`
    query PlanFinancement($noteId: String!) {
  planFinancement(noteId: $noteId) {
    ...PlanFinancementFields
  }
}
    ${PlanFinancementFieldsFragmentDoc}`;

/**
 * __usePlanFinancementQuery__
 *
 * To run a query within a React component, call `usePlanFinancementQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanFinancementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanFinancementQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function usePlanFinancementQuery(baseOptions: Apollo.QueryHookOptions<Types.PlanFinancementQuery, Types.PlanFinancementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PlanFinancementQuery, Types.PlanFinancementQueryVariables>(PlanFinancementDocument, options);
      }
export function usePlanFinancementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PlanFinancementQuery, Types.PlanFinancementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PlanFinancementQuery, Types.PlanFinancementQueryVariables>(PlanFinancementDocument, options);
        }
export type PlanFinancementQueryHookResult = ReturnType<typeof usePlanFinancementQuery>;
export type PlanFinancementLazyQueryHookResult = ReturnType<typeof usePlanFinancementLazyQuery>;
export type PlanFinancementQueryResult = Apollo.QueryResult<Types.PlanFinancementQuery, Types.PlanFinancementQueryVariables>;
export const UpdatePlanFinancementDocument = gql`
    mutation UpdatePlanFinancement($update: PlanFinancementInput!) {
  planFinancement {
    patch(input: $update) {
      ...PlanFinancementFields
    }
  }
}
    ${PlanFinancementFieldsFragmentDoc}`;
export type UpdatePlanFinancementMutationFn = Apollo.MutationFunction<Types.UpdatePlanFinancementMutation, Types.UpdatePlanFinancementMutationVariables>;

/**
 * __useUpdatePlanFinancementMutation__
 *
 * To run a mutation, you first call `useUpdatePlanFinancementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanFinancementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanFinancementMutation, { data, loading, error }] = useUpdatePlanFinancementMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdatePlanFinancementMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePlanFinancementMutation, Types.UpdatePlanFinancementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePlanFinancementMutation, Types.UpdatePlanFinancementMutationVariables>(UpdatePlanFinancementDocument, options);
      }
export type UpdatePlanFinancementMutationHookResult = ReturnType<typeof useUpdatePlanFinancementMutation>;
export type UpdatePlanFinancementMutationResult = Apollo.MutationResult<Types.UpdatePlanFinancementMutation>;
export type UpdatePlanFinancementMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePlanFinancementMutation, Types.UpdatePlanFinancementMutationVariables>;
export const AddPlanFinancementEntryDocument = gql`
    mutation AddPlanFinancementEntry($noteId: String!, $entryType: PlanFinancementEntryType!) {
  planFinancement {
    addNewEntry(noteId: $noteId, entryType: $entryType) {
      ...PlanFinancementFields
    }
  }
}
    ${PlanFinancementFieldsFragmentDoc}`;
export type AddPlanFinancementEntryMutationFn = Apollo.MutationFunction<Types.AddPlanFinancementEntryMutation, Types.AddPlanFinancementEntryMutationVariables>;

/**
 * __useAddPlanFinancementEntryMutation__
 *
 * To run a mutation, you first call `useAddPlanFinancementEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanFinancementEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanFinancementEntryMutation, { data, loading, error }] = useAddPlanFinancementEntryMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      entryType: // value for 'entryType'
 *   },
 * });
 */
export function useAddPlanFinancementEntryMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddPlanFinancementEntryMutation, Types.AddPlanFinancementEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddPlanFinancementEntryMutation, Types.AddPlanFinancementEntryMutationVariables>(AddPlanFinancementEntryDocument, options);
      }
export type AddPlanFinancementEntryMutationHookResult = ReturnType<typeof useAddPlanFinancementEntryMutation>;
export type AddPlanFinancementEntryMutationResult = Apollo.MutationResult<Types.AddPlanFinancementEntryMutation>;
export type AddPlanFinancementEntryMutationOptions = Apollo.BaseMutationOptions<Types.AddPlanFinancementEntryMutation, Types.AddPlanFinancementEntryMutationVariables>;
export const RemovePlanFinancementEntryDocument = gql`
    mutation RemovePlanFinancementEntry($noteId: String!, $entryId: ID!) {
  planFinancement {
    deleteEntry(noteId: $noteId, entryId: $entryId) {
      ...PlanFinancementFields
    }
  }
}
    ${PlanFinancementFieldsFragmentDoc}`;
export type RemovePlanFinancementEntryMutationFn = Apollo.MutationFunction<Types.RemovePlanFinancementEntryMutation, Types.RemovePlanFinancementEntryMutationVariables>;

/**
 * __useRemovePlanFinancementEntryMutation__
 *
 * To run a mutation, you first call `useRemovePlanFinancementEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlanFinancementEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlanFinancementEntryMutation, { data, loading, error }] = useRemovePlanFinancementEntryMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      entryId: // value for 'entryId'
 *   },
 * });
 */
export function useRemovePlanFinancementEntryMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemovePlanFinancementEntryMutation, Types.RemovePlanFinancementEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemovePlanFinancementEntryMutation, Types.RemovePlanFinancementEntryMutationVariables>(RemovePlanFinancementEntryDocument, options);
      }
export type RemovePlanFinancementEntryMutationHookResult = ReturnType<typeof useRemovePlanFinancementEntryMutation>;
export type RemovePlanFinancementEntryMutationResult = Apollo.MutationResult<Types.RemovePlanFinancementEntryMutation>;
export type RemovePlanFinancementEntryMutationOptions = Apollo.BaseMutationOptions<Types.RemovePlanFinancementEntryMutation, Types.RemovePlanFinancementEntryMutationVariables>;
export const FinancialPeriodsDocument = gql`
    query FinancialPeriods($noteId: ID!) {
  financialPeriods(noteId: $noteId) {
    anterieurs {
      ...financialPeriodFields
    }
    previsionnelles {
      ...financialPeriodFields
    }
    situationIntermediaire {
      ...financialPeriodFields
    }
  }
}
    ${FinancialPeriodFieldsFragmentDoc}`;

/**
 * __useFinancialPeriodsQuery__
 *
 * To run a query within a React component, call `useFinancialPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancialPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancialPeriodsQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useFinancialPeriodsQuery(baseOptions: Apollo.QueryHookOptions<Types.FinancialPeriodsQuery, Types.FinancialPeriodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.FinancialPeriodsQuery, Types.FinancialPeriodsQueryVariables>(FinancialPeriodsDocument, options);
      }
export function useFinancialPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.FinancialPeriodsQuery, Types.FinancialPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.FinancialPeriodsQuery, Types.FinancialPeriodsQueryVariables>(FinancialPeriodsDocument, options);
        }
export type FinancialPeriodsQueryHookResult = ReturnType<typeof useFinancialPeriodsQuery>;
export type FinancialPeriodsLazyQueryHookResult = ReturnType<typeof useFinancialPeriodsLazyQuery>;
export type FinancialPeriodsQueryResult = Apollo.QueryResult<Types.FinancialPeriodsQuery, Types.FinancialPeriodsQueryVariables>;
export const TvaDocument = gql`
    query Tva {
  tva
}
    `;

/**
 * __useTvaQuery__
 *
 * To run a query within a React component, call `useTvaQuery` and pass it any options that fit your needs.
 * When your component renders, `useTvaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTvaQuery({
 *   variables: {
 *   },
 * });
 */
export function useTvaQuery(baseOptions?: Apollo.QueryHookOptions<Types.TvaQuery, Types.TvaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TvaQuery, Types.TvaQueryVariables>(TvaDocument, options);
      }
export function useTvaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TvaQuery, Types.TvaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TvaQuery, Types.TvaQueryVariables>(TvaDocument, options);
        }
export type TvaQueryHookResult = ReturnType<typeof useTvaQuery>;
export type TvaLazyQueryHookResult = ReturnType<typeof useTvaLazyQuery>;
export type TvaQueryResult = Apollo.QueryResult<Types.TvaQuery, Types.TvaQueryVariables>;
export const InitFinancialPeriodsDocument = gql`
    mutation InitFinancialPeriods($noteId: ID!, $startDate: String!, $periodType: PeriodType!) {
  financialPeriod {
    init(input: {noteId: $noteId, date: $startDate, periodType: $periodType}) {
      ...financialPeriodFields
    }
  }
}
    ${FinancialPeriodFieldsFragmentDoc}`;
export type InitFinancialPeriodsMutationFn = Apollo.MutationFunction<Types.InitFinancialPeriodsMutation, Types.InitFinancialPeriodsMutationVariables>;

/**
 * __useInitFinancialPeriodsMutation__
 *
 * To run a mutation, you first call `useInitFinancialPeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitFinancialPeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initFinancialPeriodsMutation, { data, loading, error }] = useInitFinancialPeriodsMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      startDate: // value for 'startDate'
 *      periodType: // value for 'periodType'
 *   },
 * });
 */
export function useInitFinancialPeriodsMutation(baseOptions?: Apollo.MutationHookOptions<Types.InitFinancialPeriodsMutation, Types.InitFinancialPeriodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.InitFinancialPeriodsMutation, Types.InitFinancialPeriodsMutationVariables>(InitFinancialPeriodsDocument, options);
      }
export type InitFinancialPeriodsMutationHookResult = ReturnType<typeof useInitFinancialPeriodsMutation>;
export type InitFinancialPeriodsMutationResult = Apollo.MutationResult<Types.InitFinancialPeriodsMutation>;
export type InitFinancialPeriodsMutationOptions = Apollo.BaseMutationOptions<Types.InitFinancialPeriodsMutation, Types.InitFinancialPeriodsMutationVariables>;
export const UpdatePeriodsDocument = gql`
    mutation UpdatePeriods($updates: [FinancialPeriodsSaveRequestMultipleInput!]!) {
  financialPeriod {
    patchMultiple(updates: $updates) {
      ...financialPeriodFields
    }
  }
}
    ${FinancialPeriodFieldsFragmentDoc}`;
export type UpdatePeriodsMutationFn = Apollo.MutationFunction<Types.UpdatePeriodsMutation, Types.UpdatePeriodsMutationVariables>;

/**
 * __useUpdatePeriodsMutation__
 *
 * To run a mutation, you first call `useUpdatePeriodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeriodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeriodsMutation, { data, loading, error }] = useUpdatePeriodsMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdatePeriodsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePeriodsMutation, Types.UpdatePeriodsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePeriodsMutation, Types.UpdatePeriodsMutationVariables>(UpdatePeriodsDocument, options);
      }
export type UpdatePeriodsMutationHookResult = ReturnType<typeof useUpdatePeriodsMutation>;
export type UpdatePeriodsMutationResult = Apollo.MutationResult<Types.UpdatePeriodsMutation>;
export type UpdatePeriodsMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePeriodsMutation, Types.UpdatePeriodsMutationVariables>;
export const UpdatePeriodsDatesDocument = gql`
    mutation UpdatePeriodsDates($updates: [FinancialPeriodsSaveDatesRequestMultipleInput!]!) {
  financialPeriod {
    patchMultipleDates(updates: $updates) {
      ...financialPeriodFields
    }
  }
}
    ${FinancialPeriodFieldsFragmentDoc}`;
export type UpdatePeriodsDatesMutationFn = Apollo.MutationFunction<Types.UpdatePeriodsDatesMutation, Types.UpdatePeriodsDatesMutationVariables>;

/**
 * __useUpdatePeriodsDatesMutation__
 *
 * To run a mutation, you first call `useUpdatePeriodsDatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeriodsDatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeriodsDatesMutation, { data, loading, error }] = useUpdatePeriodsDatesMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdatePeriodsDatesMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePeriodsDatesMutation, Types.UpdatePeriodsDatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePeriodsDatesMutation, Types.UpdatePeriodsDatesMutationVariables>(UpdatePeriodsDatesDocument, options);
      }
export type UpdatePeriodsDatesMutationHookResult = ReturnType<typeof useUpdatePeriodsDatesMutation>;
export type UpdatePeriodsDatesMutationResult = Apollo.MutationResult<Types.UpdatePeriodsDatesMutation>;
export type UpdatePeriodsDatesMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePeriodsDatesMutation, Types.UpdatePeriodsDatesMutationVariables>;
export const CreatePeriodDocument = gql`
    mutation CreatePeriod($period: AddFinancialPeriodRequestInput!) {
  financialPeriod {
    create(input: $period) {
      ...financialPeriodFields
    }
  }
}
    ${FinancialPeriodFieldsFragmentDoc}`;
export type CreatePeriodMutationFn = Apollo.MutationFunction<Types.CreatePeriodMutation, Types.CreatePeriodMutationVariables>;

/**
 * __useCreatePeriodMutation__
 *
 * To run a mutation, you first call `useCreatePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeriodMutation, { data, loading, error }] = useCreatePeriodMutation({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useCreatePeriodMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePeriodMutation, Types.CreatePeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePeriodMutation, Types.CreatePeriodMutationVariables>(CreatePeriodDocument, options);
      }
export type CreatePeriodMutationHookResult = ReturnType<typeof useCreatePeriodMutation>;
export type CreatePeriodMutationResult = Apollo.MutationResult<Types.CreatePeriodMutation>;
export type CreatePeriodMutationOptions = Apollo.BaseMutationOptions<Types.CreatePeriodMutation, Types.CreatePeriodMutationVariables>;
export const DeletePeriodDocument = gql`
    mutation DeletePeriod($periodId: ID!) {
  financialPeriod {
    delete(financialPeriodId: $periodId)
  }
}
    `;
export type DeletePeriodMutationFn = Apollo.MutationFunction<Types.DeletePeriodMutation, Types.DeletePeriodMutationVariables>;

/**
 * __useDeletePeriodMutation__
 *
 * To run a mutation, you first call `useDeletePeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePeriodMutation, { data, loading, error }] = useDeletePeriodMutation({
 *   variables: {
 *      periodId: // value for 'periodId'
 *   },
 * });
 */
export function useDeletePeriodMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePeriodMutation, Types.DeletePeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePeriodMutation, Types.DeletePeriodMutationVariables>(DeletePeriodDocument, options);
      }
export type DeletePeriodMutationHookResult = ReturnType<typeof useDeletePeriodMutation>;
export type DeletePeriodMutationResult = Apollo.MutationResult<Types.DeletePeriodMutation>;
export type DeletePeriodMutationOptions = Apollo.BaseMutationOptions<Types.DeletePeriodMutation, Types.DeletePeriodMutationVariables>;
export const RessourcesDocument = gql`
    query Ressources($noteId: ID!) {
  ressources(noteId: $noteId) {
    ...PretsFields
  }
}
    ${PretsFieldsFragmentDoc}`;

/**
 * __useRessourcesQuery__
 *
 * To run a query within a React component, call `useRessourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRessourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRessourcesQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useRessourcesQuery(baseOptions: Apollo.QueryHookOptions<Types.RessourcesQuery, Types.RessourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RessourcesQuery, Types.RessourcesQueryVariables>(RessourcesDocument, options);
      }
export function useRessourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RessourcesQuery, Types.RessourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RessourcesQuery, Types.RessourcesQueryVariables>(RessourcesDocument, options);
        }
export type RessourcesQueryHookResult = ReturnType<typeof useRessourcesQuery>;
export type RessourcesLazyQueryHookResult = ReturnType<typeof useRessourcesLazyQuery>;
export type RessourcesQueryResult = Apollo.QueryResult<Types.RessourcesQuery, Types.RessourcesQueryVariables>;
export const UpdatePretDocument = gql`
    mutation UpdatePret($pretId: ID!, $update: UpdatePretInput!) {
  pret {
    patch(input: $update, pretId: $pretId) {
      ...PretFields
    }
  }
}
    ${PretFieldsFragmentDoc}`;
export type UpdatePretMutationFn = Apollo.MutationFunction<Types.UpdatePretMutation, Types.UpdatePretMutationVariables>;

/**
 * __useUpdatePretMutation__
 *
 * To run a mutation, you first call `useUpdatePretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePretMutation, { data, loading, error }] = useUpdatePretMutation({
 *   variables: {
 *      pretId: // value for 'pretId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdatePretMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePretMutation, Types.UpdatePretMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePretMutation, Types.UpdatePretMutationVariables>(UpdatePretDocument, options);
      }
export type UpdatePretMutationHookResult = ReturnType<typeof useUpdatePretMutation>;
export type UpdatePretMutationResult = Apollo.MutationResult<Types.UpdatePretMutation>;
export type UpdatePretMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePretMutation, Types.UpdatePretMutationVariables>;
export const PretsDocument = gql`
    query Prets($noteId: ID!) {
  pretsPasses(noteId: $noteId) {
    ...PretPasseFields
  }
}
    ${PretPasseFieldsFragmentDoc}`;

/**
 * __usePretsQuery__
 *
 * To run a query within a React component, call `usePretsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePretsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePretsQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function usePretsQuery(baseOptions: Apollo.QueryHookOptions<Types.PretsQuery, Types.PretsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PretsQuery, Types.PretsQueryVariables>(PretsDocument, options);
      }
export function usePretsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PretsQuery, Types.PretsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PretsQuery, Types.PretsQueryVariables>(PretsDocument, options);
        }
export type PretsQueryHookResult = ReturnType<typeof usePretsQuery>;
export type PretsLazyQueryHookResult = ReturnType<typeof usePretsLazyQuery>;
export type PretsQueryResult = Apollo.QueryResult<Types.PretsQuery, Types.PretsQueryVariables>;
export const CreatePretPasseDocument = gql`
    mutation CreatePretPasse($input: CreatePretPasseInput!) {
  pretPasse {
    create(input: $input) {
      ...PretPasseFields
    }
  }
}
    ${PretPasseFieldsFragmentDoc}`;
export type CreatePretPasseMutationFn = Apollo.MutationFunction<Types.CreatePretPasseMutation, Types.CreatePretPasseMutationVariables>;

/**
 * __useCreatePretPasseMutation__
 *
 * To run a mutation, you first call `useCreatePretPasseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePretPasseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPretPasseMutation, { data, loading, error }] = useCreatePretPasseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePretPasseMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePretPasseMutation, Types.CreatePretPasseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePretPasseMutation, Types.CreatePretPasseMutationVariables>(CreatePretPasseDocument, options);
      }
export type CreatePretPasseMutationHookResult = ReturnType<typeof useCreatePretPasseMutation>;
export type CreatePretPasseMutationResult = Apollo.MutationResult<Types.CreatePretPasseMutation>;
export type CreatePretPasseMutationOptions = Apollo.BaseMutationOptions<Types.CreatePretPasseMutation, Types.CreatePretPasseMutationVariables>;
export const UpdatePretPasseDocument = gql`
    mutation UpdatePretPasse($pretId: ID!, $update: UpdatePretPasseInput!) {
  pretPasse {
    patch(input: $update, pretId: $pretId) {
      ...PretPasseFields
    }
  }
}
    ${PretPasseFieldsFragmentDoc}`;
export type UpdatePretPasseMutationFn = Apollo.MutationFunction<Types.UpdatePretPasseMutation, Types.UpdatePretPasseMutationVariables>;

/**
 * __useUpdatePretPasseMutation__
 *
 * To run a mutation, you first call `useUpdatePretPasseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePretPasseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePretPasseMutation, { data, loading, error }] = useUpdatePretPasseMutation({
 *   variables: {
 *      pretId: // value for 'pretId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdatePretPasseMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePretPasseMutation, Types.UpdatePretPasseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePretPasseMutation, Types.UpdatePretPasseMutationVariables>(UpdatePretPasseDocument, options);
      }
export type UpdatePretPasseMutationHookResult = ReturnType<typeof useUpdatePretPasseMutation>;
export type UpdatePretPasseMutationResult = Apollo.MutationResult<Types.UpdatePretPasseMutation>;
export type UpdatePretPasseMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePretPasseMutation, Types.UpdatePretPasseMutationVariables>;
export const DeletePretPasseDocument = gql`
    mutation DeletePretPasse($pretId: ID!) {
  pretPasse {
    delete(pretId: $pretId)
  }
}
    `;
export type DeletePretPasseMutationFn = Apollo.MutationFunction<Types.DeletePretPasseMutation, Types.DeletePretPasseMutationVariables>;

/**
 * __useDeletePretPasseMutation__
 *
 * To run a mutation, you first call `useDeletePretPasseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePretPasseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePretPasseMutation, { data, loading, error }] = useDeletePretPasseMutation({
 *   variables: {
 *      pretId: // value for 'pretId'
 *   },
 * });
 */
export function useDeletePretPasseMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeletePretPasseMutation, Types.DeletePretPasseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeletePretPasseMutation, Types.DeletePretPasseMutationVariables>(DeletePretPasseDocument, options);
      }
export type DeletePretPasseMutationHookResult = ReturnType<typeof useDeletePretPasseMutation>;
export type DeletePretPasseMutationResult = Apollo.MutationResult<Types.DeletePretPasseMutation>;
export type DeletePretPasseMutationOptions = Apollo.BaseMutationOptions<Types.DeletePretPasseMutation, Types.DeletePretPasseMutationVariables>;
export const UpdatePrimeDocument = gql`
    mutation UpdatePrime($primeId: ID!, $update: UpdatePrimeInput!) {
  prime {
    patch(input: $update, primeId: $primeId) {
      id
      noteId
      title
      capital {
        ...basicNumericDataFields
      }
      demarragePlanFinancement {
        ...basicNumericDataFields
      }
    }
  }
}
    ${BasicNumericDataFieldsFragmentDoc}`;
export type UpdatePrimeMutationFn = Apollo.MutationFunction<Types.UpdatePrimeMutation, Types.UpdatePrimeMutationVariables>;

/**
 * __useUpdatePrimeMutation__
 *
 * To run a mutation, you first call `useUpdatePrimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrimeMutation, { data, loading, error }] = useUpdatePrimeMutation({
 *   variables: {
 *      primeId: // value for 'primeId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdatePrimeMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePrimeMutation, Types.UpdatePrimeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePrimeMutation, Types.UpdatePrimeMutationVariables>(UpdatePrimeDocument, options);
      }
export type UpdatePrimeMutationHookResult = ReturnType<typeof useUpdatePrimeMutation>;
export type UpdatePrimeMutationResult = Apollo.MutationResult<Types.UpdatePrimeMutation>;
export type UpdatePrimeMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePrimeMutation, Types.UpdatePrimeMutationVariables>;
export const NoteDocument = gql`
    query Note($noteId: String!) {
  note(noteId: $noteId) {
    ...NoteFields
  }
}
    ${NoteFieldsFragmentDoc}`;

/**
 * __useNoteQuery__
 *
 * To run a query within a React component, call `useNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useNoteQuery(baseOptions: Apollo.QueryHookOptions<Types.NoteQuery, Types.NoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NoteQuery, Types.NoteQueryVariables>(NoteDocument, options);
      }
export function useNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NoteQuery, Types.NoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NoteQuery, Types.NoteQueryVariables>(NoteDocument, options);
        }
export type NoteQueryHookResult = ReturnType<typeof useNoteQuery>;
export type NoteLazyQueryHookResult = ReturnType<typeof useNoteLazyQuery>;
export type NoteQueryResult = Apollo.QueryResult<Types.NoteQuery, Types.NoteQueryVariables>;
export const UpdateNoteDocument = gql`
    mutation UpdateNote($update: NoteInput!) {
  note {
    update(input: $update) {
      ...NoteFields
    }
  }
}
    ${NoteFieldsFragmentDoc}`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<Types.UpdateNoteMutation, Types.UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateNoteMutation, Types.UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateNoteMutation, Types.UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<Types.UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNoteMutation, Types.UpdateNoteMutationVariables>;
export const CurrentNoteDocument = gql`
    query CurrentNote($projectId: ID!) {
  currentNote(projectId: $projectId) {
    ...NoteFields
  }
}
    ${NoteFieldsFragmentDoc}`;

/**
 * __useCurrentNoteQuery__
 *
 * To run a query within a React component, call `useCurrentNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentNoteQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCurrentNoteQuery(baseOptions: Apollo.QueryHookOptions<Types.CurrentNoteQuery, Types.CurrentNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.CurrentNoteQuery, Types.CurrentNoteQueryVariables>(CurrentNoteDocument, options);
      }
export function useCurrentNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentNoteQuery, Types.CurrentNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.CurrentNoteQuery, Types.CurrentNoteQueryVariables>(CurrentNoteDocument, options);
        }
export type CurrentNoteQueryHookResult = ReturnType<typeof useCurrentNoteQuery>;
export type CurrentNoteLazyQueryHookResult = ReturnType<typeof useCurrentNoteLazyQuery>;
export type CurrentNoteQueryResult = Apollo.QueryResult<Types.CurrentNoteQuery, Types.CurrentNoteQueryVariables>;
export const GeneratePdfDocument = gql`
    mutation GeneratePdf($request: GeneratePdfInput!) {
  note {
    generatePdf(input: $request) {
      link
    }
  }
}
    `;
export type GeneratePdfMutationFn = Apollo.MutationFunction<Types.GeneratePdfMutation, Types.GeneratePdfMutationVariables>;

/**
 * __useGeneratePdfMutation__
 *
 * To run a mutation, you first call `useGeneratePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePdfMutation, { data, loading, error }] = useGeneratePdfMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGeneratePdfMutation(baseOptions?: Apollo.MutationHookOptions<Types.GeneratePdfMutation, Types.GeneratePdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.GeneratePdfMutation, Types.GeneratePdfMutationVariables>(GeneratePdfDocument, options);
      }
export type GeneratePdfMutationHookResult = ReturnType<typeof useGeneratePdfMutation>;
export type GeneratePdfMutationResult = Apollo.MutationResult<Types.GeneratePdfMutation>;
export type GeneratePdfMutationOptions = Apollo.BaseMutationOptions<Types.GeneratePdfMutation, Types.GeneratePdfMutationVariables>;
export const NoteHistoryDocument = gql`
    query NoteHistory($noteId: ID!, $structureId: String) {
  noteHistory(noteId: $noteId, structureId: $structureId) {
    id
    objectType
    objectId
    actionType
    userId
    userName
    date
    details {
      duplicatedData {
        sourceNoteId
        newNoteId
      }
      updatedData {
        updatedFields {
          fieldName
          oldValue
          newValue
        }
      }
      updatedStatus {
        oldStatus
        newStatus
      }
    }
  }
}
    `;

/**
 * __useNoteHistoryQuery__
 *
 * To run a query within a React component, call `useNoteHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteHistoryQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      structureId: // value for 'structureId'
 *   },
 * });
 */
export function useNoteHistoryQuery(baseOptions: Apollo.QueryHookOptions<Types.NoteHistoryQuery, Types.NoteHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NoteHistoryQuery, Types.NoteHistoryQueryVariables>(NoteHistoryDocument, options);
      }
export function useNoteHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NoteHistoryQuery, Types.NoteHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NoteHistoryQuery, Types.NoteHistoryQueryVariables>(NoteHistoryDocument, options);
        }
export type NoteHistoryQueryHookResult = ReturnType<typeof useNoteHistoryQuery>;
export type NoteHistoryLazyQueryHookResult = ReturnType<typeof useNoteHistoryLazyQuery>;
export type NoteHistoryQueryResult = Apollo.QueryResult<Types.NoteHistoryQuery, Types.NoteHistoryQueryVariables>;
export const MementoDocument = gql`
    query Memento($projectId: String!) {
  memento(projectId: $projectId) {
    ...MementoFields
  }
}
    ${MementoFieldsFragmentDoc}`;

/**
 * __useMementoQuery__
 *
 * To run a query within a React component, call `useMementoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMementoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMementoQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useMementoQuery(baseOptions: Apollo.QueryHookOptions<Types.MementoQuery, Types.MementoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MementoQuery, Types.MementoQueryVariables>(MementoDocument, options);
      }
export function useMementoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MementoQuery, Types.MementoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MementoQuery, Types.MementoQueryVariables>(MementoDocument, options);
        }
export type MementoQueryHookResult = ReturnType<typeof useMementoQuery>;
export type MementoLazyQueryHookResult = ReturnType<typeof useMementoLazyQuery>;
export type MementoQueryResult = Apollo.QueryResult<Types.MementoQuery, Types.MementoQueryVariables>;
export const HasEntrepreneurAccountDocument = gql`
    query HasEntrepreneurAccount($projectId: String!) {
  hasEntrepreneurAccount(projectId: $projectId)
}
    `;

/**
 * __useHasEntrepreneurAccountQuery__
 *
 * To run a query within a React component, call `useHasEntrepreneurAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasEntrepreneurAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasEntrepreneurAccountQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useHasEntrepreneurAccountQuery(baseOptions: Apollo.QueryHookOptions<Types.HasEntrepreneurAccountQuery, Types.HasEntrepreneurAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HasEntrepreneurAccountQuery, Types.HasEntrepreneurAccountQueryVariables>(HasEntrepreneurAccountDocument, options);
      }
export function useHasEntrepreneurAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HasEntrepreneurAccountQuery, Types.HasEntrepreneurAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HasEntrepreneurAccountQuery, Types.HasEntrepreneurAccountQueryVariables>(HasEntrepreneurAccountDocument, options);
        }
export type HasEntrepreneurAccountQueryHookResult = ReturnType<typeof useHasEntrepreneurAccountQuery>;
export type HasEntrepreneurAccountLazyQueryHookResult = ReturnType<typeof useHasEntrepreneurAccountLazyQuery>;
export type HasEntrepreneurAccountQueryResult = Apollo.QueryResult<Types.HasEntrepreneurAccountQuery, Types.HasEntrepreneurAccountQueryVariables>;
export const MementoPdfDocument = gql`
    mutation MementoPdf($projectId: String!, $timeZone: String!, $noteId: String) {
  memento {
    mementoPdf(projectId: $projectId, timeZone: $timeZone, noteId: $noteId) {
      link
    }
  }
}
    `;
export type MementoPdfMutationFn = Apollo.MutationFunction<Types.MementoPdfMutation, Types.MementoPdfMutationVariables>;

/**
 * __useMementoPdfMutation__
 *
 * To run a mutation, you first call `useMementoPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMementoPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mementoPdfMutation, { data, loading, error }] = useMementoPdfMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      timeZone: // value for 'timeZone'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useMementoPdfMutation(baseOptions?: Apollo.MutationHookOptions<Types.MementoPdfMutation, Types.MementoPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MementoPdfMutation, Types.MementoPdfMutationVariables>(MementoPdfDocument, options);
      }
export type MementoPdfMutationHookResult = ReturnType<typeof useMementoPdfMutation>;
export type MementoPdfMutationResult = Apollo.MutationResult<Types.MementoPdfMutation>;
export type MementoPdfMutationOptions = Apollo.BaseMutationOptions<Types.MementoPdfMutation, Types.MementoPdfMutationVariables>;
export const UpdateMementoDocument = gql`
    mutation UpdateMemento($projectId: String!, $update: MementoInput!) {
  memento {
    update(projectId: $projectId, input: $update) {
      ...MementoFields
    }
  }
}
    ${MementoFieldsFragmentDoc}`;
export type UpdateMementoMutationFn = Apollo.MutationFunction<Types.UpdateMementoMutation, Types.UpdateMementoMutationVariables>;

/**
 * __useUpdateMementoMutation__
 *
 * To run a mutation, you first call `useUpdateMementoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMementoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMementoMutation, { data, loading, error }] = useUpdateMementoMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateMementoMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateMementoMutation, Types.UpdateMementoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateMementoMutation, Types.UpdateMementoMutationVariables>(UpdateMementoDocument, options);
      }
export type UpdateMementoMutationHookResult = ReturnType<typeof useUpdateMementoMutation>;
export type UpdateMementoMutationResult = Apollo.MutationResult<Types.UpdateMementoMutation>;
export type UpdateMementoMutationOptions = Apollo.BaseMutationOptions<Types.UpdateMementoMutation, Types.UpdateMementoMutationVariables>;
export const ExportMementoDocument = gql`
    mutation ExportMemento($projectId: String!, $noteId: ID!) {
  memento {
    export(projectId: $projectId, noteId: $noteId) {
      ...MementoFields
    }
  }
}
    ${MementoFieldsFragmentDoc}`;
export type ExportMementoMutationFn = Apollo.MutationFunction<Types.ExportMementoMutation, Types.ExportMementoMutationVariables>;

/**
 * __useExportMementoMutation__
 *
 * To run a mutation, you first call `useExportMementoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportMementoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportMementoMutation, { data, loading, error }] = useExportMementoMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useExportMementoMutation(baseOptions?: Apollo.MutationHookOptions<Types.ExportMementoMutation, Types.ExportMementoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ExportMementoMutation, Types.ExportMementoMutationVariables>(ExportMementoDocument, options);
      }
export type ExportMementoMutationHookResult = ReturnType<typeof useExportMementoMutation>;
export type ExportMementoMutationResult = Apollo.MutationResult<Types.ExportMementoMutation>;
export type ExportMementoMutationOptions = Apollo.BaseMutationOptions<Types.ExportMementoMutation, Types.ExportMementoMutationVariables>;
export const UnpublishMementoDocument = gql`
    mutation UnpublishMemento($projectId: String!) {
  memento {
    unpublish(projectId: $projectId) {
      ...MementoFields
    }
  }
}
    ${MementoFieldsFragmentDoc}`;
export type UnpublishMementoMutationFn = Apollo.MutationFunction<Types.UnpublishMementoMutation, Types.UnpublishMementoMutationVariables>;

/**
 * __useUnpublishMementoMutation__
 *
 * To run a mutation, you first call `useUnpublishMementoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishMementoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishMementoMutation, { data, loading, error }] = useUnpublishMementoMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUnpublishMementoMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnpublishMementoMutation, Types.UnpublishMementoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnpublishMementoMutation, Types.UnpublishMementoMutationVariables>(UnpublishMementoDocument, options);
      }
export type UnpublishMementoMutationHookResult = ReturnType<typeof useUnpublishMementoMutation>;
export type UnpublishMementoMutationResult = Apollo.MutationResult<Types.UnpublishMementoMutation>;
export type UnpublishMementoMutationOptions = Apollo.BaseMutationOptions<Types.UnpublishMementoMutation, Types.UnpublishMementoMutationVariables>;
export const ScoringDocument = gql`
    query Scoring($noteId: ID!) {
  scoring(noteId: $noteId) {
    score
    risqueRatio
    errors
    warnings
  }
}
    `;

/**
 * __useScoringQuery__
 *
 * To run a query within a React component, call `useScoringQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoringQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useScoringQuery(baseOptions: Apollo.QueryHookOptions<Types.ScoringQuery, Types.ScoringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ScoringQuery, Types.ScoringQueryVariables>(ScoringDocument, options);
      }
export function useScoringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ScoringQuery, Types.ScoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ScoringQuery, Types.ScoringQueryVariables>(ScoringDocument, options);
        }
export type ScoringQueryHookResult = ReturnType<typeof useScoringQuery>;
export type ScoringLazyQueryHookResult = ReturnType<typeof useScoringLazyQuery>;
export type ScoringQueryResult = Apollo.QueryResult<Types.ScoringQuery, Types.ScoringQueryVariables>;
export const ScoringCreationCheckDocument = gql`
    query ScoringCreationCheck($noteId: ID!) {
  scoringCreationCheck(noteId: $noteId) {
    allRequiredConditions
    minimumPret {
      ...ScoringParameterFields
    }
    minimumPorteur {
      ...ScoringParameterFields
    }
    garanties {
      outilId
      name
      duree {
        ...ScoringParameterFields
      }
      quotite {
        ...ScoringParameterFields
      }
    }
    prets {
      outilId
      name
      capital {
        ...ScoringParameterFields
      }
      duree {
        ...ScoringParameterFields
      }
    }
    porteurs {
      idMuffin
      niveauEtude {
        ...ScoringParameterFields
      }
      situationProfessionnelle {
        ...ScoringParameterFields
      }
      typeRevenu {
        ...ScoringParameterFields
      }
    }
    planFinancement {
      ...ScoringParameterFields
    }
    statutCooperatif {
      ...ScoringParameterFields
    }
    statutJuridique {
      ...ScoringParameterFields
    }
    apeCode {
      ...ScoringParameterFields
    }
  }
}
    ${ScoringParameterFieldsFragmentDoc}`;

/**
 * __useScoringCreationCheckQuery__
 *
 * To run a query within a React component, call `useScoringCreationCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoringCreationCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoringCreationCheckQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useScoringCreationCheckQuery(baseOptions: Apollo.QueryHookOptions<Types.ScoringCreationCheckQuery, Types.ScoringCreationCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ScoringCreationCheckQuery, Types.ScoringCreationCheckQueryVariables>(ScoringCreationCheckDocument, options);
      }
export function useScoringCreationCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ScoringCreationCheckQuery, Types.ScoringCreationCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ScoringCreationCheckQuery, Types.ScoringCreationCheckQueryVariables>(ScoringCreationCheckDocument, options);
        }
export type ScoringCreationCheckQueryHookResult = ReturnType<typeof useScoringCreationCheckQuery>;
export type ScoringCreationCheckLazyQueryHookResult = ReturnType<typeof useScoringCreationCheckLazyQuery>;
export type ScoringCreationCheckQueryResult = Apollo.QueryResult<Types.ScoringCreationCheckQuery, Types.ScoringCreationCheckQueryVariables>;
export const ScoringDeveloppementCheckDocument = gql`
    query ScoringDeveloppementCheck($noteId: ID!) {
  scoringDeveloppementCheck(noteId: $noteId) {
    allRequiredConditions
    minimumPret {
      ...ScoringParameterFields
    }
    prets {
      outilId
      name
      capital {
        ...ScoringParameterFields
      }
      duree {
        ...ScoringParameterFields
      }
    }
    garanties {
      outilId
      name
      duree {
        ...ScoringParameterFields
      }
      quotite {
        ...ScoringParameterFields
      }
    }
    planFinancement {
      ...ScoringParameterFields
    }
    statutCooperatif {
      ...ScoringParameterFields
    }
    statutJuridique {
      ...ScoringParameterFields
    }
    apeCode {
      ...ScoringParameterFields
    }
    minimumAnterieur {
      ...ScoringParameterFields
    }
    minimumPrevisionnel {
      ...ScoringParameterFields
    }
    bilanN1 {
      ...ScoringParameterFields
    }
    bilanN2 {
      ...ScoringParameterFields
    }
    objectProjectCode {
      ...ScoringParameterFields
    }
  }
}
    ${ScoringParameterFieldsFragmentDoc}`;

/**
 * __useScoringDeveloppementCheckQuery__
 *
 * To run a query within a React component, call `useScoringDeveloppementCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useScoringDeveloppementCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScoringDeveloppementCheckQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useScoringDeveloppementCheckQuery(baseOptions: Apollo.QueryHookOptions<Types.ScoringDeveloppementCheckQuery, Types.ScoringDeveloppementCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ScoringDeveloppementCheckQuery, Types.ScoringDeveloppementCheckQueryVariables>(ScoringDeveloppementCheckDocument, options);
      }
export function useScoringDeveloppementCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ScoringDeveloppementCheckQuery, Types.ScoringDeveloppementCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ScoringDeveloppementCheckQuery, Types.ScoringDeveloppementCheckQueryVariables>(ScoringDeveloppementCheckDocument, options);
        }
export type ScoringDeveloppementCheckQueryHookResult = ReturnType<typeof useScoringDeveloppementCheckQuery>;
export type ScoringDeveloppementCheckLazyQueryHookResult = ReturnType<typeof useScoringDeveloppementCheckLazyQuery>;
export type ScoringDeveloppementCheckQueryResult = Apollo.QueryResult<Types.ScoringDeveloppementCheckQuery, Types.ScoringDeveloppementCheckQueryVariables>;
export const UpdateScoringCreationDocument = gql`
    mutation UpdateScoringCreation($noteId: ID!) {
  scoring {
    updateScoringCreation(noteId: $noteId) {
      score
      risqueRatio
      errors
      warnings
    }
  }
}
    `;
export type UpdateScoringCreationMutationFn = Apollo.MutationFunction<Types.UpdateScoringCreationMutation, Types.UpdateScoringCreationMutationVariables>;

/**
 * __useUpdateScoringCreationMutation__
 *
 * To run a mutation, you first call `useUpdateScoringCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScoringCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScoringCreationMutation, { data, loading, error }] = useUpdateScoringCreationMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useUpdateScoringCreationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScoringCreationMutation, Types.UpdateScoringCreationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScoringCreationMutation, Types.UpdateScoringCreationMutationVariables>(UpdateScoringCreationDocument, options);
      }
export type UpdateScoringCreationMutationHookResult = ReturnType<typeof useUpdateScoringCreationMutation>;
export type UpdateScoringCreationMutationResult = Apollo.MutationResult<Types.UpdateScoringCreationMutation>;
export type UpdateScoringCreationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScoringCreationMutation, Types.UpdateScoringCreationMutationVariables>;
export const UpdateScoringDeveloppementDocument = gql`
    mutation UpdateScoringDeveloppement($noteId: ID!) {
  scoring {
    updateScoringDeveloppement(noteId: $noteId) {
      score
      risqueRatio
      errors
      warnings
    }
  }
}
    `;
export type UpdateScoringDeveloppementMutationFn = Apollo.MutationFunction<Types.UpdateScoringDeveloppementMutation, Types.UpdateScoringDeveloppementMutationVariables>;

/**
 * __useUpdateScoringDeveloppementMutation__
 *
 * To run a mutation, you first call `useUpdateScoringDeveloppementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScoringDeveloppementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScoringDeveloppementMutation, { data, loading, error }] = useUpdateScoringDeveloppementMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useUpdateScoringDeveloppementMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateScoringDeveloppementMutation, Types.UpdateScoringDeveloppementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateScoringDeveloppementMutation, Types.UpdateScoringDeveloppementMutationVariables>(UpdateScoringDeveloppementDocument, options);
      }
export type UpdateScoringDeveloppementMutationHookResult = ReturnType<typeof useUpdateScoringDeveloppementMutation>;
export type UpdateScoringDeveloppementMutationResult = Apollo.MutationResult<Types.UpdateScoringDeveloppementMutation>;
export type UpdateScoringDeveloppementMutationOptions = Apollo.BaseMutationOptions<Types.UpdateScoringDeveloppementMutation, Types.UpdateScoringDeveloppementMutationVariables>;
export const NoteSettingsDocument = gql`
    query NoteSettings($noteId: ID!) {
  noteSettings(noteId: $noteId) {
    noteId
    kiloEuros
    referenceAmount
    assujettiImpotsSocietes
    impotsSurBeneficeComputed
    prelevementTnsInCr
    showDemarrage
    showDetailPret
    showPercentageCedant
    showPercentagePrevisionnel
    showBfrInverse
  }
}
    `;

/**
 * __useNoteSettingsQuery__
 *
 * To run a query within a React component, call `useNoteSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteSettingsQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useNoteSettingsQuery(baseOptions: Apollo.QueryHookOptions<Types.NoteSettingsQuery, Types.NoteSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NoteSettingsQuery, Types.NoteSettingsQueryVariables>(NoteSettingsDocument, options);
      }
export function useNoteSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NoteSettingsQuery, Types.NoteSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NoteSettingsQuery, Types.NoteSettingsQueryVariables>(NoteSettingsDocument, options);
        }
export type NoteSettingsQueryHookResult = ReturnType<typeof useNoteSettingsQuery>;
export type NoteSettingsLazyQueryHookResult = ReturnType<typeof useNoteSettingsLazyQuery>;
export type NoteSettingsQueryResult = Apollo.QueryResult<Types.NoteSettingsQuery, Types.NoteSettingsQueryVariables>;
export const UpdateNoteSettingsDocument = gql`
    mutation UpdateNoteSettings($noteId: ID!, $updateRequest: NoteSettingUpdateRequest) {
  noteSettings {
    update(noteId: $noteId, request: $updateRequest) {
      noteId
      kiloEuros
      referenceAmount
      assujettiImpotsSocietes
      impotsSurBeneficeComputed
      prelevementTnsInCr
      showDemarrage
      showDetailPret
      showPercentageCedant
      showPercentagePrevisionnel
      showBfrInverse
    }
  }
}
    `;
export type UpdateNoteSettingsMutationFn = Apollo.MutationFunction<Types.UpdateNoteSettingsMutation, Types.UpdateNoteSettingsMutationVariables>;

/**
 * __useUpdateNoteSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateNoteSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteSettingsMutation, { data, loading, error }] = useUpdateNoteSettingsMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      updateRequest: // value for 'updateRequest'
 *   },
 * });
 */
export function useUpdateNoteSettingsMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateNoteSettingsMutation, Types.UpdateNoteSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateNoteSettingsMutation, Types.UpdateNoteSettingsMutationVariables>(UpdateNoteSettingsDocument, options);
      }
export type UpdateNoteSettingsMutationHookResult = ReturnType<typeof useUpdateNoteSettingsMutation>;
export type UpdateNoteSettingsMutationResult = Apollo.MutationResult<Types.UpdateNoteSettingsMutation>;
export type UpdateNoteSettingsMutationOptions = Apollo.BaseMutationOptions<Types.UpdateNoteSettingsMutation, Types.UpdateNoteSettingsMutationVariables>;
export const ProjectAnalysisDocument = gql`
    query ProjectAnalysis($noteId: ID!) {
  projectAnalyzer {
    analysis(noteId: $noteId) {
      id
      answers {
        id
        questionId
        ratingAnswers {
          rid
          score
        }
        slateData
      }
      entrepreneurs {
        id
        noteId
        idMuffin
        ...FullHousehold
        responses {
          id
          questionId
          slateData
          ratingAnswers {
            rid
            score
          }
        }
      }
    }
    formData(noteId: $noteId) {
      categories {
        category
        label
        subcategories {
          subCategory
          label
          questions {
            id
            description
            text
            ratings {
              id
              possibleRatings
              text
            }
          }
        }
      }
    }
  }
}
    ${FullHouseholdFragmentDoc}`;

/**
 * __useProjectAnalysisQuery__
 *
 * To run a query within a React component, call `useProjectAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAnalysisQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useProjectAnalysisQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectAnalysisQuery, Types.ProjectAnalysisQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectAnalysisQuery, Types.ProjectAnalysisQueryVariables>(ProjectAnalysisDocument, options);
      }
export function useProjectAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectAnalysisQuery, Types.ProjectAnalysisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectAnalysisQuery, Types.ProjectAnalysisQueryVariables>(ProjectAnalysisDocument, options);
        }
export type ProjectAnalysisQueryHookResult = ReturnType<typeof useProjectAnalysisQuery>;
export type ProjectAnalysisLazyQueryHookResult = ReturnType<typeof useProjectAnalysisLazyQuery>;
export type ProjectAnalysisQueryResult = Apollo.QueryResult<Types.ProjectAnalysisQuery, Types.ProjectAnalysisQueryVariables>;
export const ProjectAnalyzerAnswerDocument = gql`
    mutation ProjectAnalyzerAnswer($noteId: ID!, $request: PAAnswerInput!) {
  projectAnalyzer {
    answer(noteId: $noteId, request: $request) {
      id
      questionId
      slateData
      ratingAnswers {
        rid
        score
      }
    }
  }
}
    `;
export type ProjectAnalyzerAnswerMutationFn = Apollo.MutationFunction<Types.ProjectAnalyzerAnswerMutation, Types.ProjectAnalyzerAnswerMutationVariables>;

/**
 * __useProjectAnalyzerAnswerMutation__
 *
 * To run a mutation, you first call `useProjectAnalyzerAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAnalyzerAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAnalyzerAnswerMutation, { data, loading, error }] = useProjectAnalyzerAnswerMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useProjectAnalyzerAnswerMutation(baseOptions?: Apollo.MutationHookOptions<Types.ProjectAnalyzerAnswerMutation, Types.ProjectAnalyzerAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ProjectAnalyzerAnswerMutation, Types.ProjectAnalyzerAnswerMutationVariables>(ProjectAnalyzerAnswerDocument, options);
      }
export type ProjectAnalyzerAnswerMutationHookResult = ReturnType<typeof useProjectAnalyzerAnswerMutation>;
export type ProjectAnalyzerAnswerMutationResult = Apollo.MutationResult<Types.ProjectAnalyzerAnswerMutation>;
export type ProjectAnalyzerAnswerMutationOptions = Apollo.BaseMutationOptions<Types.ProjectAnalyzerAnswerMutation, Types.ProjectAnalyzerAnswerMutationVariables>;
export const HouseholdUpdateDocument = gql`
    mutation HouseholdUpdate($entrepreneurId: ID!, $request: HouseholdUpdate!) {
  projectAnalyzer {
    updateHousehold(entrepreneurId: $entrepreneurId, request: $request) {
      id
      ...FullHousehold
    }
  }
}
    ${FullHouseholdFragmentDoc}`;
export type HouseholdUpdateMutationFn = Apollo.MutationFunction<Types.HouseholdUpdateMutation, Types.HouseholdUpdateMutationVariables>;

/**
 * __useHouseholdUpdateMutation__
 *
 * To run a mutation, you first call `useHouseholdUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHouseholdUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [householdUpdateMutation, { data, loading, error }] = useHouseholdUpdateMutation({
 *   variables: {
 *      entrepreneurId: // value for 'entrepreneurId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useHouseholdUpdateMutation(baseOptions?: Apollo.MutationHookOptions<Types.HouseholdUpdateMutation, Types.HouseholdUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.HouseholdUpdateMutation, Types.HouseholdUpdateMutationVariables>(HouseholdUpdateDocument, options);
      }
export type HouseholdUpdateMutationHookResult = ReturnType<typeof useHouseholdUpdateMutation>;
export type HouseholdUpdateMutationResult = Apollo.MutationResult<Types.HouseholdUpdateMutation>;
export type HouseholdUpdateMutationOptions = Apollo.BaseMutationOptions<Types.HouseholdUpdateMutation, Types.HouseholdUpdateMutationVariables>;
export const CreateEntrepreneurDocument = gql`
    mutation CreateEntrepreneur($noteId: ID!, $request: EntrepreneurCreate!) {
  projectAnalyzer {
    createEntrepreneur(noteId: $noteId, request: $request) {
      id
    }
  }
}
    `;
export type CreateEntrepreneurMutationFn = Apollo.MutationFunction<Types.CreateEntrepreneurMutation, Types.CreateEntrepreneurMutationVariables>;

/**
 * __useCreateEntrepreneurMutation__
 *
 * To run a mutation, you first call `useCreateEntrepreneurMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntrepreneurMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntrepreneurMutation, { data, loading, error }] = useCreateEntrepreneurMutation({
 *   variables: {
 *      noteId: // value for 'noteId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateEntrepreneurMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateEntrepreneurMutation, Types.CreateEntrepreneurMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateEntrepreneurMutation, Types.CreateEntrepreneurMutationVariables>(CreateEntrepreneurDocument, options);
      }
export type CreateEntrepreneurMutationHookResult = ReturnType<typeof useCreateEntrepreneurMutation>;
export type CreateEntrepreneurMutationResult = Apollo.MutationResult<Types.CreateEntrepreneurMutation>;
export type CreateEntrepreneurMutationOptions = Apollo.BaseMutationOptions<Types.CreateEntrepreneurMutation, Types.CreateEntrepreneurMutationVariables>;
export const DeleteEntrepreneurDocument = gql`
    mutation DeleteEntrepreneur($idMuffin: ID!) {
  projectAnalyzer {
    deleteEntrepreneur(muffinId: $idMuffin)
  }
}
    `;
export type DeleteEntrepreneurMutationFn = Apollo.MutationFunction<Types.DeleteEntrepreneurMutation, Types.DeleteEntrepreneurMutationVariables>;

/**
 * __useDeleteEntrepreneurMutation__
 *
 * To run a mutation, you first call `useDeleteEntrepreneurMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntrepreneurMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEntrepreneurMutation, { data, loading, error }] = useDeleteEntrepreneurMutation({
 *   variables: {
 *      idMuffin: // value for 'idMuffin'
 *   },
 * });
 */
export function useDeleteEntrepreneurMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteEntrepreneurMutation, Types.DeleteEntrepreneurMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteEntrepreneurMutation, Types.DeleteEntrepreneurMutationVariables>(DeleteEntrepreneurDocument, options);
      }
export type DeleteEntrepreneurMutationHookResult = ReturnType<typeof useDeleteEntrepreneurMutation>;
export type DeleteEntrepreneurMutationResult = Apollo.MutationResult<Types.DeleteEntrepreneurMutation>;
export type DeleteEntrepreneurMutationOptions = Apollo.BaseMutationOptions<Types.DeleteEntrepreneurMutation, Types.DeleteEntrepreneurMutationVariables>;
export const EntrepreneurResponseDocument = gql`
    mutation EntrepreneurResponse($entrepreneurId: ID!, $request: PAAnswerInput!) {
  projectAnalyzer {
    entrepreneurAnswer(entrepreneurId: $entrepreneurId, request: $request) {
      id
      questionId
      slateData
      ratingAnswers {
        rid
        score
      }
    }
  }
}
    `;
export type EntrepreneurResponseMutationFn = Apollo.MutationFunction<Types.EntrepreneurResponseMutation, Types.EntrepreneurResponseMutationVariables>;

/**
 * __useEntrepreneurResponseMutation__
 *
 * To run a mutation, you first call `useEntrepreneurResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntrepreneurResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entrepreneurResponseMutation, { data, loading, error }] = useEntrepreneurResponseMutation({
 *   variables: {
 *      entrepreneurId: // value for 'entrepreneurId'
 *      request: // value for 'request'
 *   },
 * });
 */
export function useEntrepreneurResponseMutation(baseOptions?: Apollo.MutationHookOptions<Types.EntrepreneurResponseMutation, Types.EntrepreneurResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.EntrepreneurResponseMutation, Types.EntrepreneurResponseMutationVariables>(EntrepreneurResponseDocument, options);
      }
export type EntrepreneurResponseMutationHookResult = ReturnType<typeof useEntrepreneurResponseMutation>;
export type EntrepreneurResponseMutationResult = Apollo.MutationResult<Types.EntrepreneurResponseMutation>;
export type EntrepreneurResponseMutationOptions = Apollo.BaseMutationOptions<Types.EntrepreneurResponseMutation, Types.EntrepreneurResponseMutationVariables>;
export const ProjectDocument = gql`
    query Project($projectId: ID!) {
  project(projectId: $projectId) {
    name
    referenceDossier
    revelateurEngagement
    partenaireMontage {
      agence
      contact
      partenaire
    }
    associationId
    association
    prescripteur {
      agence
      contact
      partenaire
    }
    structures {
      id
      situationEntreprise
      address {
        street
        city
        zipCode
      }
      secteurActivite
      territoirFragile
      emploisCrees
      emploisConspermanent
      emploisConsinsertion
      cooperativeStatus
      niveauEngagement
      raisonSociale
      legalStatus
      esus
      typeStructure
    }
    contacts {
      id
      label
    }
  }
  notes(projectId: $projectId) {
    id
    version
    lifecycle
  }
}
    `;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<Types.ProjectQuery, Types.ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProjectQuery, Types.ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectQuery, Types.ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProjectQuery, Types.ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<Types.ProjectQuery, Types.ProjectQueryVariables>;
export const PorteursDocument = gql`
    query Porteurs($noteId: ID!) {
  porteurs(noteId: $noteId) {
    muffinId
    gender
    firstname
    lastname
    email
    birthDate
    telephone
    address {
      city
      street
      zipCode
    }
    maritalStatus
    jobSituation
    degree
    allowance
    porteurType
  }
}
    `;

/**
 * __usePorteursQuery__
 *
 * To run a query within a React component, call `usePorteursQuery` and pass it any options that fit your needs.
 * When your component renders, `usePorteursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePorteursQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function usePorteursQuery(baseOptions: Apollo.QueryHookOptions<Types.PorteursQuery, Types.PorteursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.PorteursQuery, Types.PorteursQueryVariables>(PorteursDocument, options);
      }
export function usePorteursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.PorteursQuery, Types.PorteursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.PorteursQuery, Types.PorteursQueryVariables>(PorteursDocument, options);
        }
export type PorteursQueryHookResult = ReturnType<typeof usePorteursQuery>;
export type PorteursLazyQueryHookResult = ReturnType<typeof usePorteursLazyQuery>;
export type PorteursQueryResult = Apollo.QueryResult<Types.PorteursQuery, Types.PorteursQueryVariables>;
export const NoteOverviewDocument = gql`
    query NoteOverview($noteId: ID!) {
  involvementCheckResults {
    results(noteId: $noteId) {
      personalInvolvement
      categoryResults {
        category
        mean
        valid
      }
    }
  }
  projectAnalyzer {
    analysis(noteId: $noteId) {
      answers {
        id
        questionId
        slateData
        ratingAnswers {
          rid
          score
        }
      }
    }
    formData(noteId: $noteId) {
      categories {
        category
        subcategories {
          subCategory
          questions {
            id
            ratings {
              id
              text
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useNoteOverviewQuery__
 *
 * To run a query within a React component, call `useNoteOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteOverviewQuery({
 *   variables: {
 *      noteId: // value for 'noteId'
 *   },
 * });
 */
export function useNoteOverviewQuery(baseOptions: Apollo.QueryHookOptions<Types.NoteOverviewQuery, Types.NoteOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NoteOverviewQuery, Types.NoteOverviewQueryVariables>(NoteOverviewDocument, options);
      }
export function useNoteOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NoteOverviewQuery, Types.NoteOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NoteOverviewQuery, Types.NoteOverviewQueryVariables>(NoteOverviewDocument, options);
        }
export type NoteOverviewQueryHookResult = ReturnType<typeof useNoteOverviewQuery>;
export type NoteOverviewLazyQueryHookResult = ReturnType<typeof useNoteOverviewLazyQuery>;
export type NoteOverviewQueryResult = Apollo.QueryResult<Types.NoteOverviewQuery, Types.NoteOverviewQueryVariables>;
export const AddFavoriteDocument = gql`
    mutation AddFavorite($projectId: ID!) {
  advisorSettings {
    addFavoriteProject(projectId: $projectId) {
      favoriteProject
      showInvolvementProfileHelp
      kiloEuros
      debounce
    }
  }
}
    `;
export type AddFavoriteMutationFn = Apollo.MutationFunction<Types.AddFavoriteMutation, Types.AddFavoriteMutationVariables>;

/**
 * __useAddFavoriteMutation__
 *
 * To run a mutation, you first call `useAddFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteMutation, { data, loading, error }] = useAddFavoriteMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useAddFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.AddFavoriteMutation, Types.AddFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.AddFavoriteMutation, Types.AddFavoriteMutationVariables>(AddFavoriteDocument, options);
      }
export type AddFavoriteMutationHookResult = ReturnType<typeof useAddFavoriteMutation>;
export type AddFavoriteMutationResult = Apollo.MutationResult<Types.AddFavoriteMutation>;
export type AddFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.AddFavoriteMutation, Types.AddFavoriteMutationVariables>;
export const RemoveFavoriteDocument = gql`
    mutation RemoveFavorite($projectId: ID!) {
  advisorSettings {
    removeFavoriteProject(projectId: $projectId) {
      favoriteProject
      showInvolvementProfileHelp
      kiloEuros
      debounce
    }
  }
}
    `;
export type RemoveFavoriteMutationFn = Apollo.MutationFunction<Types.RemoveFavoriteMutation, Types.RemoveFavoriteMutationVariables>;

/**
 * __useRemoveFavoriteMutation__
 *
 * To run a mutation, you first call `useRemoveFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFavoriteMutation, { data, loading, error }] = useRemoveFavoriteMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRemoveFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<Types.RemoveFavoriteMutation, Types.RemoveFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RemoveFavoriteMutation, Types.RemoveFavoriteMutationVariables>(RemoveFavoriteDocument, options);
      }
export type RemoveFavoriteMutationHookResult = ReturnType<typeof useRemoveFavoriteMutation>;
export type RemoveFavoriteMutationResult = Apollo.MutationResult<Types.RemoveFavoriteMutation>;
export type RemoveFavoriteMutationOptions = Apollo.BaseMutationOptions<Types.RemoveFavoriteMutation, Types.RemoveFavoriteMutationVariables>;
export const MySettingsDocument = gql`
    query MySettings {
  advisorSettings {
    settings {
      favoriteProject
      showInvolvementProfileHelp
      kiloEuros
      debounce
    }
  }
}
    `;

/**
 * __useMySettingsQuery__
 *
 * To run a query within a React component, call `useMySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMySettingsQuery(baseOptions?: Apollo.QueryHookOptions<Types.MySettingsQuery, Types.MySettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MySettingsQuery, Types.MySettingsQueryVariables>(MySettingsDocument, options);
      }
export function useMySettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MySettingsQuery, Types.MySettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MySettingsQuery, Types.MySettingsQueryVariables>(MySettingsDocument, options);
        }
export type MySettingsQueryHookResult = ReturnType<typeof useMySettingsQuery>;
export type MySettingsLazyQueryHookResult = ReturnType<typeof useMySettingsLazyQuery>;
export type MySettingsQueryResult = Apollo.QueryResult<Types.MySettingsQuery, Types.MySettingsQueryVariables>;
export const UpdateSettingDocument = gql`
    mutation UpdateSetting($value: SettingUpdateRequestInput!) {
  advisorSettings {
    update(request: $value) {
      favoriteProject
      showInvolvementProfileHelp
      kiloEuros
      debounce
    }
  }
}
    `;
export type UpdateSettingMutationFn = Apollo.MutationFunction<Types.UpdateSettingMutation, Types.UpdateSettingMutationVariables>;

/**
 * __useUpdateSettingMutation__
 *
 * To run a mutation, you first call `useUpdateSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingMutation, { data, loading, error }] = useUpdateSettingMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSettingMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateSettingMutation, Types.UpdateSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateSettingMutation, Types.UpdateSettingMutationVariables>(UpdateSettingDocument, options);
      }
export type UpdateSettingMutationHookResult = ReturnType<typeof useUpdateSettingMutation>;
export type UpdateSettingMutationResult = Apollo.MutationResult<Types.UpdateSettingMutation>;
export type UpdateSettingMutationOptions = Apollo.BaseMutationOptions<Types.UpdateSettingMutation, Types.UpdateSettingMutationVariables>;
export const MementoClickEventDocument = gql`
    mutation MementoClickEvent($event: MementoClickEventInput!) {
  analytics {
    mementoClickEvent(event: $event)
  }
}
    `;
export type MementoClickEventMutationFn = Apollo.MutationFunction<Types.MementoClickEventMutation, Types.MementoClickEventMutationVariables>;

/**
 * __useMementoClickEventMutation__
 *
 * To run a mutation, you first call `useMementoClickEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMementoClickEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mementoClickEventMutation, { data, loading, error }] = useMementoClickEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useMementoClickEventMutation(baseOptions?: Apollo.MutationHookOptions<Types.MementoClickEventMutation, Types.MementoClickEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MementoClickEventMutation, Types.MementoClickEventMutationVariables>(MementoClickEventDocument, options);
      }
export type MementoClickEventMutationHookResult = ReturnType<typeof useMementoClickEventMutation>;
export type MementoClickEventMutationResult = Apollo.MutationResult<Types.MementoClickEventMutation>;
export type MementoClickEventMutationOptions = Apollo.BaseMutationOptions<Types.MementoClickEventMutation, Types.MementoClickEventMutationVariables>;
export const ConfigDocument = gql`
    query Config {
  involvementCheck {
    profiles {
      label
      profile
    }
  }
  dictionary {
    labels {
      cle
      value
    }
  }
  impotSocieteParam {
    impotSocieteParams {
      year
      taux1
      taux2
      seuil
    }
  }
}
    `;

/**
 * __useConfigQuery__
 *
 * To run a query within a React component, call `useConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigQuery(baseOptions?: Apollo.QueryHookOptions<Types.ConfigQuery, Types.ConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ConfigQuery, Types.ConfigQueryVariables>(ConfigDocument, options);
      }
export function useConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ConfigQuery, Types.ConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ConfigQuery, Types.ConfigQueryVariables>(ConfigDocument, options);
        }
export type ConfigQueryHookResult = ReturnType<typeof useConfigQuery>;
export type ConfigLazyQueryHookResult = ReturnType<typeof useConfigLazyQuery>;
export type ConfigQueryResult = Apollo.QueryResult<Types.ConfigQuery, Types.ConfigQueryVariables>;
export const LogFrontEventsDocument = gql`
    mutation LogFrontEvents($request: FrontEventsLogRequest!) {
  logging {
    logFrontEvents(request: $request)
  }
}
    `;
export type LogFrontEventsMutationFn = Apollo.MutationFunction<Types.LogFrontEventsMutation, Types.LogFrontEventsMutationVariables>;

/**
 * __useLogFrontEventsMutation__
 *
 * To run a mutation, you first call `useLogFrontEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogFrontEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logFrontEventsMutation, { data, loading, error }] = useLogFrontEventsMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useLogFrontEventsMutation(baseOptions?: Apollo.MutationHookOptions<Types.LogFrontEventsMutation, Types.LogFrontEventsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LogFrontEventsMutation, Types.LogFrontEventsMutationVariables>(LogFrontEventsDocument, options);
      }
export type LogFrontEventsMutationHookResult = ReturnType<typeof useLogFrontEventsMutation>;
export type LogFrontEventsMutationResult = Apollo.MutationResult<Types.LogFrontEventsMutation>;
export type LogFrontEventsMutationOptions = Apollo.BaseMutationOptions<Types.LogFrontEventsMutation, Types.LogFrontEventsMutationVariables>;