import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { LigneDetail, NoteType, NumericData, PeriodType } from '@fa-metier/types'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import produce from 'immer'
import { PeriodsContext } from '../Utils/PeriodsContext'
import { getField } from '@fa-metier/commons'
import { Button, FormGroup } from '@blueprintjs/core'
import { Dots, EvolutionValue, VerticalLine } from '../FinancialTable'
import { Intent } from '@blueprintjs/core/lib/esm/common/intent'
import { AppMetierCheckbox } from '../Utils/AppMetierCheckBox'
import { AppMetierTextInput } from '../Utils/AppMetierInput/AppMetierTextInput'
import { NoteContext } from '../../NotePage'
import { FinanciaryContext } from '../Financiary'
import { StrokedButton } from '@fa-metier/components/dist/Buttons/Buttons'
import { CRContext } from './CRForm'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

interface DataByLabel {
  label: string
  periodsData: PeriodData[]
  required: boolean
  variable: boolean
}

interface PeriodData {
  periodType: PeriodType
  data: NumericData
}

interface VariableLineProps {
  inputData: DataByLabel
  dataFromContext: DataByLabel
  onUpdate: (newData: DataByLabel) => any
  onDelete?: () => any
  showComputations: boolean
  isInvalid: (label: string) => boolean
  useChargesVariables: boolean
  showAnterieurs?: boolean
  isCalculate?: boolean
}

const VariableLine: FC<VariableLineProps> = ({
  inputData,
  dataFromContext,
  onUpdate,
  onDelete,
  showComputations,
  isInvalid,
  useChargesVariables,
  showAnterieurs = false,
  isCalculate = false,
}) => {
  const [label, setLabel] = useState(inputData.label)
  const [invalid, setInvalid] = useState(false)
  const { noteSettings, note } = useContext(NoteContext)
  const { showEvolutionColumn } = useContext(PeriodsContext)
  const updateCurrentData = (newData: DataByLabel) => {
    if (!inputData?.label && !newData.label) {
      return
    }
    if (newData.label !== '') {
      if (newData.label !== label) {
        if (isInvalid(newData.label)) {
          setInvalid(true)
        } else {
          setInvalid(false)
        }
      }
      onUpdate(newData)
    }
  }

  return (
    <tr className={'hoverable'}>
      <th
        className={'detail'}
        style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}
      >
        <VerticalLine style={{ height: '85px', top: '-20px' }} />
        {inputData?.required ?? false ? (
          <span style={{ marginLeft: '20px' }}>{inputData.label}</span>
        ) : (
          <FormGroup
            intent={invalid ? Intent.DANGER : Intent.NONE}
            helperText={invalid ? 'Vous avez déjà créé une catégorie avec ce même nom' : ''}
            style={{ marginLeft: '20px', margin: '0 0 0 15px' }}
          >
            <AppMetierTextInput
              intent={invalid ? Intent.DANGER : Intent.NONE}
              value={inputData?.label}
              onChange={(event: string) => {
                setLabel(event)
                updateCurrentData(
                  produce(inputData, (draft) => {
                    draft.label = event
                  })
                )
              }}
              disabled={isCalculate}
            />
          </FormGroup>
        )}
        {!inputData.required && onDelete && (
          <Button icon={'trash'} minimal large={false} onClick={onDelete} />
        )}
        <Dots />

        {useChargesVariables && (
          <AppMetierCheckbox
            checked={inputData.variable ? inputData.variable : false}
            onChange={() => {
              updateCurrentData(
                produce(inputData, (draft) => {
                  draft.variable = !inputData.variable
                })
              )
            }}
            disabled={isCalculate}
          />
        )}
      </th>
      {inputData.periodsData.map((d, index) => {
        if (d.periodType === PeriodType.Anterieur && !showAnterieurs) {
          return <></>
        }
        const previousPeriodType = inputData.periodsData[index - 1]?.periodType
        const showEvolution =
          (d.periodType === PeriodType.Anterieur &&
            noteSettings.showPercentageCedant &&
            index > 0) ||
          (noteSettings.showPercentagePrevisionnel &&
            ((previousPeriodType && previousPeriodType !== PeriodType.Anterieur) ||
              note.type === NoteType.StructureExistante))
        return (
          <React.Fragment key={`vl_period_${index}`}>
            {d.periodType === PeriodType.Previsionnel &&
              inputData.periodsData[index - 1]?.periodType === PeriodType.Anterieur &&
              showEvolutionColumn && (
                <td className={'evolution'} style={{ lineHeight: '32px', alignItems: 'flex-end' }}>
                  <EvolutionValue
                    value={dataFromContext?.periodsData[index].data.percentageEvolution}
                  />
                </td>
              )}
            <td
              key={`${inputData?.label}_${index}`}
              className={
                d.periodType === PeriodType.Anterieur
                  ? 'anterieur'
                  : d.periodType === PeriodType.Intermediaire
                  ? 'intermediaire'
                  : ''
              }
            >
              <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <NumericDataInput
                  style={{ flexBasis: '50%' }}
                  numericData={d.data}
                  isCalculated={onDelete === undefined || isCalculate}
                  onChange={(n) =>
                    updateCurrentData(
                      produce(inputData, (draft) => {
                        draft.periodsData[index] = { data: n, periodType: d.periodType } || {
                          periodType: draft.periodsData[index].periodType,
                          data: {
                            kiloEuros: draft.periodsData[index].data.kiloEuros,
                            value: 0,
                          },
                        }
                      })
                    )
                  }
                  showComputations={showComputations}
                />
                {showEvolution && d.periodType !== PeriodType.Intermediaire && (
                  <div
                    style={{
                      display: 'inline-flex',
                      justifyContent: 'flex-end',
                      flexGrow: 1,
                      fontSize: '12px',
                      marginRight: '5px',
                      width: '60px',
                      alignItems: 'flex-end',
                      lineHeight: '32px',
                    }}
                  >
                    <EvolutionValue
                      value={dataFromContext?.periodsData[index]?.data.percentageEvolution}
                    />
                  </div>
                )}
                {noteSettings.referenceAmount !== 'NONE' && (
                  <div
                    style={{
                      width: '30px',
                      display: 'inline-flex',
                      alignItems: 'center',
                      fontSize: '12px',
                      flexGrow: 1,
                      justifyContent: 'flex-end',
                    }}
                  >
                    {dataFromContext?.periodsData[index]?.data?.percentageReference !== null &&
                      dataFromContext?.periodsData[index]?.data?.percentageReference !==
                        undefined && (
                        <>{dataFromContext?.periodsData[index]?.data?.percentageReference}&nbsp;%</>
                      )}
                  </div>
                )}
              </div>
            </td>
          </React.Fragment>
        )
      })}
    </tr>
  )
}

export const VariableLineGroup: FC<{
  fieldPath: string
  label?: string
  useChargesVariables: boolean
}> = ({ fieldPath, label, useChargesVariables = false }) => {
  const {
    periods,
    anterieurPeriods,
    updatePeriods,
    updateAnterieurPeriods,
    showComputations,
    showAnterieurs,
    showEvolutionColumn,
    situationIntermediaire,
    updateSituationIntermediaire,
  } = useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)
  const { computationInProgress } = useContext(CRContext)
  const fieldPathLines = `${fieldPath}.lines`

  const allPeriods = (anterieurPeriods ?? [])
    .concat(situationIntermediaire ? situationIntermediaire : [])
    .concat(periods)

  const newDataByLabel: () => DataByLabel = () => ({
    label: '',
    periodsData: allPeriods.map((p) => ({
      data: { kiloEuros: noteSettings.kiloEuros },
      periodType: p.type,
    })),
    required: false,
    variable: false,
  })

  const groupByLabel = (): DataByLabel[] => {
    const variableFieldsByPeriod = allPeriods.map((p) => getField<LigneDetail[]>(fieldPathLines, p))
    const allLabels = variableFieldsByPeriod
      .flatMap((details) => details.map((d) => d.label))
      .reduce(
        (uniques, item) => (uniques.includes(item) ? uniques : [...uniques, item]),
        [] as string[]
      )
    return allLabels.map((l) => ({
      label: l,
      periodsData: variableFieldsByPeriod.map((details, index) => ({
        data: details.find((d) => d.label === l)?.value ?? {
          value: null,
          kiloEuros: noteSettings.kiloEuros,
        },
        periodType: allPeriods[index].type,
      })),
      required: variableFieldsByPeriod.some(
        (details) => details.find((d) => d.label === l)?.required ?? false
      ),
      variable: variableFieldsByPeriod.some(
        (details) => details.find((d) => d.label === l)?.variable ?? false
      ),
    }))
  }
  const [currentGroupByLabel, setCurrentGroupByLabel] = useState(groupByLabel())
  const currentGroupByLabelRef = useRef(currentGroupByLabel)

  const isLabelAlreadyPresent = (label: string) => {
    return (
      label !== '' &&
      currentGroupByLabel.filter((d) => d.label.toLowerCase() === label.toLowerCase()).length > 0
    )
  }

  const removeLine = (label: string) =>
    setCurrentGroupByLabel((currentGroupByLabel) => [
      ...currentGroupByLabel.filter((d) => d.label !== label),
    ])

  const addLine = () =>
    setCurrentGroupByLabel((currentGroupByLabel) => [...currentGroupByLabel, newDataByLabel()])

  const updateLine = (updatedData: DataByLabel, index: number) =>
    setCurrentGroupByLabel((dataByLabel) =>
      dataByLabel.map((d, i) =>
        i === index
          ? {
              label: updatedData.label,
              periodsData: updatedData.periodsData,
              required: updatedData.required,
              variable: updatedData.variable,
            }
          : d
      )
    )

  useEffect(() => {
    if (!_.isEqual(currentGroupByLabel, currentGroupByLabelRef.current)) {
      currentGroupByLabelRef.current = currentGroupByLabel
      const updateRequest = allPeriods.map((period, index) => {
        const newLineDetails = currentGroupByLabel.map<LigneDetail>((dbl) => ({
          id: uuidv4(),
          label: dbl.label,
          value: dbl.periodsData[index].data,
          required: dbl.required,
          variable: dbl.variable,
        }))
        return {
          periodId: period.id,
          periodType: period.type,
          fieldPath: fieldPathLines,
          change: newLineDetails,
        }
      })
      updatePeriods(updateRequest.filter((o) => o.periodType === PeriodType.Previsionnel))
      if (anterieurPeriods && updateAnterieurPeriods) {
        updateAnterieurPeriods(updateRequest.filter((o) => o.periodType === PeriodType.Anterieur))
      }
      if (situationIntermediaire && updateSituationIntermediaire) {
        updateSituationIntermediaire(
          updateRequest.filter((o) => o.periodType === PeriodType.Intermediaire)
        )
      }
    }
  }, [currentGroupByLabel])

  const LineAddNewLine: FC<{
    additionalColumns?: number
  }> = ({ additionalColumns }) => {
    const { previsionelPeriods, anterieurPeriods } = useContext(FinanciaryContext)
    const { showEvolutionColumn, showAnterieurs, situationIntermediaire } =
      useContext(PeriodsContext)
    const { computationInProgress } = useContext(CRContext)

    return (
      <tr>
        <th style={{ paddingLeft: '20px' }}>
          <VerticalLine style={{ height: '85px', top: '-20px' }} />
          <FormGroup style={{ marginLeft: '20px' }}>
            <StrokedButton
              style={{ width: '220px' }}
              key={'AjouterLigne'}
              onClick={() => addLine()}
              disabled={computationInProgress}
            >
              <div style={{ margin: '0' }}>+ Ajouter une ligne de détail</div>
            </StrokedButton>
          </FormGroup>
        </th>
        {showAnterieurs &&
          (anterieurPeriods ?? []).map((p, i) => {
            return <td key={i} style={{ height: 'inherit' }} className={'anterieur smallHeight'} />
          })}
        {showEvolutionColumn && <td className={'evolution smallHeight'}>&nbsp;</td>}
        {situationIntermediaire && <td className={'intermediaire smallHeight'}>&nbsp;</td>}
        {previsionelPeriods.map((p, i) => (
          <td key={i} style={{ height: 'inherit' }} className={'smallHeight'} />
        ))}
        {[...Array(additionalColumns ?? 0).keys()].map((i) => (
          <td key={i} style={{ height: 'inherit' }} className={'smallHeight'} />
        ))}
      </tr>
    )
  }

  return (
    <>
      {!!label && (
        <tr>
          <th className={'detail'} style={{ fontStyle: 'italic', textDecoration: 'underline' }}>
            {label}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {useChargesVariables && <span>Variable</span>}
            </div>
          </th>
          {showAnterieurs &&
            (anterieurPeriods ?? []).map((p) => <td key={p.id} className={'anterieur'} />)}
          {showEvolutionColumn && <td className={'evolution'}>&nbsp;</td>}
          {situationIntermediaire && <td className={'intermediaire'}>&nbsp;</td>}
          {periods.map((p) => (
            <td key={p.id} />
          ))}
        </tr>
      )}
      {currentGroupByLabel.map((d, index) => {
        return (
          <VariableLine
            isInvalid={isLabelAlreadyPresent}
            key={`vl_${index}`}
            inputData={d}
            dataFromContext={groupByLabel()[index]}
            onUpdate={(v) => updateLine(v, index)}
            onDelete={() => removeLine(d.label)}
            showComputations={showComputations}
            useChargesVariables={useChargesVariables}
            showAnterieurs={showAnterieurs}
            isCalculate={computationInProgress}
          />
        )
      })}
      <LineAddNewLine />
    </>
  )
}
