import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import {
  CalculatedData,
  NoteSettings,
  NoteType,
  NumericData,
  PeriodType,
  Stylable,
} from '@fa-metier/types'
import { getField } from '@fa-metier/commons'
import { NumericDataInput } from './NumericDataInput/NumericDataInput'
import { PeriodsContext } from './PeriodsContext'
import { NumberText } from '@fa-metier/components'
import styled from 'styled-components'
import { Dots, EvolutionValue, VerticalLine } from '../FinancialTable'
import { Button, Classes, Dialog, Intent, Switch } from '@blueprintjs/core'
import { PeriodModel } from '../PeriodModel'
import { FormulaPopoverContent } from '../Formulas/FormulaPopover'
import { eurosSuffix, Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { FormulaDescriptionPopover } from '../Formulas/FormulaDescriptionPopover'
import { NoteContext } from '../../NotePage'

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5em;

  div:first-child {
    flex: 2 1 0;
  }

  div {
    flex: 1 1 0;
  }
`

const PercentageDiv = styled.div`
  width: 30px;
  flex-grow: 1;
  justify-content: flex-end;
  display: inline-flex;
  align-items: center;
`

interface FinanciaryLineProps {
  fieldPath: string
  isCalculate?: boolean
  cellClassName?: string
  isEuro?: boolean
  displayPercentageReference?: boolean
  noteSettings?: NoteSettings
  forceAnterieurHide?: boolean
  showOnPeriodTypes?: PeriodType[]
  forceSituationIntermediaireHide?: boolean
  isSubtotal?: boolean
  isCalculatedData?: boolean
  isCalculatedTotal?: boolean
}

export function getNumericData(
  numData: CalculatedData | NumericData | undefined
): NumericData | undefined {
  if (numData?.__typename === 'CalculatedData') {
    return numData.numericData
  }
  if (numData?.__typename === 'NumericData') {
    return numData
  }
  return numData as unknown as NumericData
}

export const FinanciaryLine: FC<FinanciaryLineProps & Stylable> = ({
  fieldPath,
  isCalculate,
  cellClassName,
  isEuro = true,
  displayPercentageReference = true,
  forceAnterieurHide = false,
  showOnPeriodTypes = [],
  forceSituationIntermediaireHide = false,
  isSubtotal = false,
  isCalculatedData = false,
  isCalculatedTotal = false,
}) => {
  const {
    showAnterieurs,
    periods,
    anterieurPeriods,
    updatePeriods,
    updateAnterieurPeriods,
    showComputations,
    showEvolutionColumn,
    situationIntermediaire,
    updateSituationIntermediaire,
  } = useContext(PeriodsContext)

  const { noteSettings, note } = useContext(NoteContext)

  const updateField = (periodId: string, n?: NumericData) => {
    updatePeriods([{ periodId, fieldPath, change: n }])
  }

  const updateAnterieurField = (periodId: string, n?: NumericData) => {
    if (updateAnterieurPeriods !== undefined) {
      updateAnterieurPeriods!!([{ periodId, fieldPath, change: n }])
    }
  }

  const updateSituationField = (periodId: string, n?: NumericData) => {
    if (updateSituationIntermediaire && situationIntermediaire) {
      updateSituationIntermediaire([{ periodId, fieldPath, change: n }])
    }
  }

  const numData = getField<NumericData>(fieldPath, periods[0])

  return (
    <>
      {!forceAnterieurHide &&
        showAnterieurs &&
        (anterieurPeriods ?? []).map((p, index) => {
          return (
            <PeriodFinanciaryCell
              key={`PeriodFinanciaryCell_${fieldPath}_${p.id}`}
              fieldPath={fieldPath}
              period={p}
              isEuro={isEuro}
              displayPercentageReference={displayPercentageReference}
              isCalculate={isCalculate}
              showComputations={showComputations}
              updateField={updateAnterieurField}
              showPercentageEvolution={noteSettings.showPercentageCedant && index > 0}
              showOnPeriodTypes={showOnPeriodTypes}
              isSubtotal={isSubtotal}
              isCalculatedTotal={isCalculatedTotal}
            />
          )
        })}
      {showEvolutionColumn && (
        <td className={'evolution'}>
          <EvolutionValue value={numData !== undefined ? numData.percentageEvolution : undefined} />
        </td>
      )}
      {!forceSituationIntermediaireHide &&
        situationIntermediaire &&
        updateSituationIntermediaire && (
          <PeriodFinanciaryCell
            fieldPath={fieldPath}
            period={situationIntermediaire}
            isEuro={isEuro}
            displayPercentageReference={displayPercentageReference}
            isCalculate={isCalculate}
            showComputations={showComputations}
            updateField={updateSituationField!}
            showPercentageEvolution={false}
            showOnPeriodTypes={showOnPeriodTypes}
            isSubtotal={isSubtotal}
            isCalculatedTotal={isCalculatedTotal}
          />
        )}
      {periods.map((p, index) => {
        return (
          <PeriodFinanciaryCell
            key={`PeriodFinanciaryCell_${fieldPath}_${p.id}`}
            fieldPath={fieldPath}
            period={p}
            isEuro={isEuro}
            displayPercentageReference={displayPercentageReference}
            isCalculate={isCalculate}
            showComputations={showComputations}
            updateField={updateField}
            cellClassName={cellClassName}
            showPercentageEvolution={
              noteSettings.showPercentagePrevisionnel &&
              (index > 0 || note.type === NoteType.StructureExistante)
            }
            showOnPeriodTypes={showOnPeriodTypes}
            isCalculatedData={isCalculatedData}
            isCalculatedTotal={isCalculatedTotal}
          />
        )
      })}
    </>
  )
}

export const PeriodFinanciaryCell: FC<
  FinanciaryLineProps &
    Stylable & {
      period: PeriodModel
      showComputations: boolean
      updateField: (periodId: string, n?: NumericData) => any
      showPercentageEvolution?: boolean
      isCalculatedData?: boolean
    }
> = ({
  fieldPath,
  isCalculate = false,
  cellClassName = '',
  isEuro = true,
  period,
  showComputations,
  updateField,
  showPercentageEvolution = false,
  showOnPeriodTypes = [],
  isSubtotal = false,
  isCalculatedData = false,
  isCalculatedTotal = false,
}) => {
  const { noteSettings } = useContext(NoteContext)
  const { hidePercentages, resetDebounceTimers } = useContext(PeriodsContext)

  const calculateData = () =>
    isCalculatedData
      ? getNumericData(getField<CalculatedData>(fieldPath, period))
      : getField<NumericData>(fieldPath, period)

  const [numData, setNumData] = useState(calculateData())

  useEffect(() => {
    setNumData(calculateData())
  }, [period, isCalculatedData])

  const allowPercentages = hidePercentages !== undefined && !hidePercentages

  const className = () => {
    if (isSubtotal) {
      return 'subtotal'
    }
    if (period.type === PeriodType.Intermediaire) {
      return 'intermediaire'
    }
    const periodTypeClassName = period.type.toString().toLowerCase()
    const isCalculateClassName = isCalculate ? '' : cellClassName
    return `${periodTypeClassName} ${isCalculateClassName}`
  }

  return (
    <>
      {isCalculatedTotal ? (
        <CalculatedCell
          fieldName={fieldPath}
          period={period}
          suffix={eurosSuffix(noteSettings.kiloEuros)}
          showPercentageEvolution={showPercentageEvolution}
          percentageFieldName={`${fieldPath}.percentageReference`}
        />
      ) : (
        <td id={period.id} className={className()} key={`calculate-${period.id}`}>
          {showOnPeriodTypes.length === 0 || showOnPeriodTypes.includes(period.type) ? (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <NumericDataInput
                numericData={numData}
                onChange={(n) => {
                  !!resetDebounceTimers && resetDebounceTimers()
                  updateField(period.id, n)
                }}
                showComputations={showComputations}
                isCalculated={isCalculate}
                isEuro={isEuro}
                id={`${fieldPath}_${period.id}`}
              />
              {allowPercentages && (
                <>
                  {showPercentageEvolution && (
                    <PercentageDiv style={{ width: '60px' }}>
                      <span
                        style={{
                          flexGrow: 1,
                          display: 'inline-flex',
                          fontSize: '11px',
                          justifyContent: 'flex-end',
                          marginRight: '5px',
                          color: 'var(--main-violet)',
                          fontWeight: 600,
                          alignItems: 'flex-end',
                          lineHeight: '32px',
                        }}
                      >
                        <EvolutionValue value={numData?.percentageEvolution} />
                      </span>
                    </PercentageDiv>
                  )}
                  {noteSettings.referenceAmount !== 'NONE' && (
                    <PercentageDiv>
                      {!!numData?.percentageReference && (
                        <span
                          style={{
                            fontSize: '11px',
                          }}
                        >
                          {numData.percentageReference}&nbsp;%
                        </span>
                      )}
                    </PercentageDiv>
                  )}
                </>
              )}
            </div>
          ) : (
            <span>&nbsp;</span>
          )}
        </td>
      )}
    </>
  )
}

export interface SubLineProps {
  label: string
  fieldName: string
  isNotEditable: boolean
}

export const SubLine: FC<SubLineProps> = ({ label, fieldName, isNotEditable }) => {
  return (
    <tr className={'hoverable'}>
      <th
        style={{
          display: 'flex',
          alignItems: 'baseline',
          paddingLeft: '40px',
          fontStyle: 'italic',
        }}
      >
        <div>{label}</div>
        <Dots />
      </th>
      <FinanciaryLine fieldPath={fieldName} isCalculate={isNotEditable} />
    </tr>
  )
}

export interface LineProps {
  label?: string
  fieldName: string
  isCalculate?: boolean
  isDetail?: boolean
  isEuro?: boolean
  displayPercentageReference?: boolean
  showOnPeriodTypes?: PeriodType[]
  isSubtotal?: boolean
}

export const Line: FC<LineProps> = ({
  label,
  fieldName,
  isCalculate = false,
  isDetail = false,
  isEuro = true,
  displayPercentageReference,
  showOnPeriodTypes = [],
}) => {
  return (
    <tr className={'hoverable'}>
      <th className={isDetail ? 'detail' : ''} style={{ display: 'flex', alignItems: 'baseline' }}>
        {isDetail && <VerticalLine />}
        <div style={{ marginLeft: isDetail ? '20px' : '' }}>{label}</div>
        <Dots />
      </th>
      <FinanciaryLine
        fieldPath={fieldName}
        isCalculate={isCalculate}
        isEuro={isEuro}
        displayPercentageReference={displayPercentageReference}
        showOnPeriodTypes={showOnPeriodTypes}
      />
    </tr>
  )
}

export const TotalLine: FC<LineProps> = ({ fieldName, isSubtotal = false }) => {
  const { periods, anterieurPeriods, showAnterieurs, showEvolutionColumn, situationIntermediaire } =
    useContext(PeriodsContext)
  return (
    <>
      <tr style={{ backgroundColor: 'var(--main-blue-2)', paddingLeft: '20px' }}>
        <th className={'detail-total'}>
          <div>
            <b>Total</b>
          </div>
        </th>
        <FinanciaryLine
          fieldPath={fieldName}
          isCalculate={true}
          isSubtotal={isSubtotal}
          isCalculatedTotal={true}
        />
      </tr>
      <tr>
        <th style={{ height: 'inherit' }} />
        {showAnterieurs &&
          (anterieurPeriods ?? []).map((p) => (
            <td key={p.id} className={'anterieur smallHeight'} />
          ))}
        {showEvolutionColumn && <td className={'evolution smallHeight'} />}
        {situationIntermediaire && <td className={'intermediaire smallHeight'} />}
        {periods.map((p) => (
          <td key={p.id} style={{ height: 'inherit' }} />
        ))}
      </tr>
    </>
  )
}

interface CalculatedLineProps {
  label: string | ReactNode
  fieldName: string
  isNotTotal?: boolean
  emptyText?: string
  defaultValue?: number
  isResult?: boolean
  suffix?: string
  dots?: boolean
  outlined?: boolean
  percentageFieldName?: string
  isSubLine?: boolean
  forceNoEvolution?: boolean
  noBackground?: boolean
}

interface CalculatedLineWithDetailProps {
  label: string | ReactNode
  fieldName: string
  isNotTotal?: boolean
  isResult?: boolean
  suffix?: string
  isSubLine?: boolean
  showPourcentageReference?: boolean
}

export const CalculatedLineWithDetail: FC<CalculatedLineWithDetailProps & Stylable> = ({
  label,
  fieldName,
  isNotTotal,
  isResult = false,
  suffix = '',
  isSubLine = false,
  showPourcentageReference = true,
}) => {
  const { periods, anterieurPeriods, showAnterieurs, showEvolutionColumn, situationIntermediaire } =
    useContext(PeriodsContext)
  const { noteSettings, note } = useContext(NoteContext)
  const numData = getField<CalculatedData>(fieldName, periods[0]).numericData
  return (
    <tr
      className={isResult ? 'result' : 'subtotal hoverable'}
      style={{ fontWeight: isNotTotal ? 'normal' : 600 }}
    >
      <th className={'outlined'}>
        <div style={{ marginLeft: isSubLine ? '20px' : 'Opx' }}>
          <span>{label}</span>
        </div>
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p, index) => (
          <CalculatedCellWithCalculDetail
            key={`${fieldName}_${p.id}`}
            fieldName={fieldName}
            period={p}
            suffix={suffix}
            showEvolution={noteSettings.showPercentageCedant && index > 0}
            showPourcentage={showPourcentageReference}
            isResult={isResult}
          />
        ))}
      {showEvolutionColumn && (
        <td className={'evolutionSize'}>
          <EvolutionValue
            value={numData?.percentageEvolution}
            color={isResult ? 'white' : 'violet'}
          />
        </td>
      )}
      {situationIntermediaire && (
        <CalculatedCellWithCalculDetail
          key={`${fieldName}_${situationIntermediaire.id}`}
          fieldName={fieldName}
          period={situationIntermediaire}
          suffix={suffix}
          showEvolution={false}
          showPourcentage={showPourcentageReference}
          isResult={isResult}
        />
      )}
      {periods.map((p, index) => (
        <CalculatedCellWithCalculDetail
          key={`${fieldName}_${p.id}`}
          fieldName={fieldName}
          period={p}
          suffix={suffix}
          showEvolution={
            noteSettings.showPercentagePrevisionnel &&
            (index > 0 || note.type === NoteType.StructureExistante)
          }
          showPourcentage={showPourcentageReference}
          isResult={isResult}
        />
      ))}
    </tr>
  )
}

export const CalculatedLine: FC<CalculatedLineProps & Stylable> = ({
  label,
  fieldName,
  isNotTotal,
  emptyText,
  isResult = false,
  suffix = '',
  dots = false,
  outlined = true,
  percentageFieldName,
  isSubLine = false,
  forceNoEvolution = false,
  noBackground = false,
}) => {
  const { periods, anterieurPeriods, showAnterieurs, showEvolutionColumn, situationIntermediaire } =
    useContext(PeriodsContext)
  const { noteSettings, note } = useContext(NoteContext)
  const numData = getField<NumericData>(fieldName, periods[0])

  return (
    <tr
      className={isResult ? 'result' : outlined ? 'subtotal hoverable' : 'calculated hoverable'}
      style={{ fontWeight: isNotTotal ? 'normal' : 600 }}
    >
      <th className={'outlined'}>
        <div style={{ marginLeft: isSubLine ? '20px' : 'Opx' }}>{label}</div>
        {dots && <Dots />}
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p, index) => (
          <CalculatedCell
            key={`${fieldName}_${p.id}`}
            fieldName={fieldName}
            period={p}
            percentageFieldName={percentageFieldName}
            suffix={suffix}
            emptyText={emptyText}
            showPercentageEvolution={
              noteSettings.showPercentageCedant && index > 0 && !forceNoEvolution
            }
            noBackground={noBackground}
          />
        ))}
      {situationIntermediaire && (
        <CalculatedCell
          key={`${fieldName}_${situationIntermediaire.id}`}
          fieldName={fieldName}
          period={situationIntermediaire}
          percentageFieldName={percentageFieldName}
          suffix={suffix}
          emptyText={emptyText}
          showPercentageEvolution={noteSettings.showPercentageCedant && !forceNoEvolution}
          noBackground={noBackground}
        />
      )}
      {showEvolutionColumn && (
        <td className={'evolutionSize'}>
          <EvolutionValue value={numData?.percentageEvolution} />
        </td>
      )}
      {periods.map((p, index) => (
        <CalculatedCell
          key={`${fieldName}_${p.id}`}
          fieldName={fieldName}
          period={p}
          percentageFieldName={percentageFieldName}
          suffix={suffix}
          emptyText={emptyText}
          showPercentageEvolution={
            noteSettings.showPercentagePrevisionnel &&
            (index > 0 || note.type === NoteType.StructureExistante) &&
            !forceNoEvolution
          }
        />
      ))}
    </tr>
  )
}

export const CalculatedCellWithCalculDetail: FC<{
  fieldName: string
  period: PeriodModel
  suffix: string
  showEvolution?: boolean
  showPourcentage?: boolean
  isResult?: boolean
  alignRight?: boolean
  showDescription?: (value: number) => boolean
  optionalDescription?: string
  className?: string
}> = ({
  fieldName,
  period,
  suffix,
  showEvolution = false,
  showPourcentage = true,
  isResult = false,
  alignRight = false,
  showDescription = () => false,
  optionalDescription = '',
  className = '',
}) => {
  const calculatedData = getField<CalculatedData>(fieldName, period)

  const [hovered, setHovered] = useState(false)

  const target = `${fieldName}_${period.id}`

  const getNumData = () => {
    const base = calculatedData?.numericData?.value ?? 0
    if (suffix === Suffixes.POURCENTAGE) {
      return (base * 100).toFixed(2)
    }
    return base.toFixed(2)
  }
  const numData = getNumData()

  const percentagedata = calculatedData?.numericData?.percentageReference
  const percentageEvolution = calculatedData?.numericData?.percentageEvolution
  return (
    <>
      <td
        className={hovered ? `hovered ${className}` : className}
        key={`calculateField-${period.id}-${numData}`}
        id={target}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {numData && (
          <FormulaDescriptionPopover
            content={
              <FormulaPopoverContent
                calculatedData={calculatedData}
                suffix={suffix}
                showDescription={showDescription}
                optionalDescription={optionalDescription}
                periodType={period.type}
              />
            }
            position={'bottom'}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: alignRight ? 'flex-end' : 'space-between',
                alignItems: 'baseline',
              }}
            >
              <span style={{ paddingLeft: '10px', paddingRight: '30px', minWidth: '100px' }}>
                <NumberText value={numData} suffix={suffix} />
              </span>
              {showEvolution && (percentageEvolution || percentageEvolution === 0) && (
                <span style={{ fontSize: '11px', alignItems: 'center' }}>
                  <EvolutionValue
                    value={percentageEvolution}
                    color={isResult ? 'white' : 'violet'}
                  />
                </span>
              )}
              {showPourcentage && percentagedata && (
                <span style={{ alignItems: 'center', fontSize: '11px' }}>{percentagedata} %</span>
              )}
            </div>
          </FormulaDescriptionPopover>
        )}
      </td>
    </>
  )
}

export const CalculatedCell: FC<{
  fieldName: string
  period: PeriodModel
  percentageFieldName?: string
  suffix: string
  emptyText?: string
  showPercentageEvolution?: boolean
  noBackground?: boolean
}> = ({
  fieldName,
  period,
  percentageFieldName,
  suffix,
  emptyText,
  showPercentageEvolution = false,
  noBackground = true,
}) => {
  const numData = getField<CalculatedData | NumericData>(fieldName, period)

  const target = `${fieldName}_${period.id}`

  const displayValue = Number((getNumericData(numData)?.value ?? 0).toFixed(2))

  const percentageData = percentageFieldName
    ? getField<number>(percentageFieldName, period, undefined)
    : undefined

  const percentageEvolution = getNumericData(numData)?.percentageEvolution

  const className = computeClassName(period, noBackground)

  return (
    <>
      <td className={className} key={`calculateField-${period.id}-${numData}`} id={target}>
        {(emptyText === undefined || numData) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              verticalAlign: 'middle',
              alignItems: 'center',
            }}
          >
            <span style={{ paddingLeft: '10px', paddingRight: '30px', minWidth: '100px' }}>
              <NumberText value={displayValue} suffix={suffix} />
            </span>
            {showPercentageEvolution && (
              <span style={{ fontSize: '11px', alignItems: 'center' }}>
                <EvolutionValue value={percentageEvolution} />
              </span>
            )}
            {percentageData && (
              <span style={{ fontSize: '11px', alignItems: 'center' }}>{percentageData} %</span>
            )}
          </div>
        )}
      </td>
    </>
  )
}

const computeClassName = (period: PeriodModel, noBackground: boolean) => {
  if (noBackground) {
    return ''
  }
  if (period.type === PeriodType.Intermediaire) {
    return 'intermediaire'
  }
  if (period.type === PeriodType.Anterieur) {
    return 'anterieur'
  }
  return ''
}

export interface LinePropsExtended {
  label: string
  fieldName: string
  isCalculate?: boolean
  isDetail?: boolean
  isEuro?: boolean
  switchLabel?: string
  dialogTitle: string
  dialogBody: string | ReactNode
  dialogAction: () => {}
  defaultSwitchValue?: boolean
}

const SwitchContainer = styled.div`
  > .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: var(--main-green-1);
  }

  font-weight: normal;
`

export const LineWithDialog: FC<LinePropsExtended> = ({
  label,
  fieldName,
  isCalculate = false,
  isDetail = false,
  isEuro = true,
  switchLabel = 'Calcul automatique',
  dialogTitle = 'Attention',
  dialogBody,
  dialogAction = () => {},
  defaultSwitchValue = false,
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <tr className={'hoverable'}>
      <th style={{ display: 'flex', alignItems: 'baseline' }}>
        {isDetail && <VerticalLine />}
        <div style={{ marginLeft: isDetail ? '20px' : '' }}>{label}</div>
        <Dots />
        <SwitchContainer>
          <Switch
            label={switchLabel}
            defaultChecked={defaultSwitchValue}
            onChange={() => setOpen(true)}
          />
        </SwitchContainer>
        <Dialog isOpen={open} title={dialogTitle} isCloseButtonShown={false}>
          <div className={Classes.DIALOG_BODY}>
            <div>{dialogBody}</div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER}>
              <Button style={{ marginRight: '0.5em' }} onClick={() => setOpen(false)}>
                Annuler
              </Button>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => {
                  dialogAction()
                  setOpen(false)
                }}
              >
                Confirmer
              </Button>
            </div>
          </div>
        </Dialog>
      </th>
      <FinanciaryLine fieldPath={fieldName} isCalculate={isCalculate} isEuro={isEuro} />
    </tr>
  )
}

interface CalculatedLineWithDialogProps {
  label: string | ReactNode
  fieldName: string
  suffix?: string
  dialogTitle: string
  dialogBody: string | ReactNode
  dialogAction: () => {}
}

export const CalculatedLineWithDialog: FC<CalculatedLineWithDialogProps & Stylable> = ({
  label,
  fieldName,
  suffix = '',
  dialogTitle = 'Attention',
  dialogBody,
  dialogAction = () => {},
}) => {
  const { periods, anterieurPeriods, showAnterieurs } = useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)
  const [open, setOpen] = React.useState(false)
  const switchLabel = 'Calcul automatique'

  const percentageFieldName = `${fieldName}.numericData.percentageReference`

  return (
    <tr className={'subtotal'} style={{ fontWeight: 600 }}>
      <th className={'outlined'}>
        <div>{label}</div>
        <SwitchContainer>
          <Switch label={switchLabel} checked={true} onChange={() => setOpen(true)} />
        </SwitchContainer>
        <Dialog isOpen={open} title={dialogTitle} isCloseButtonShown={false}>
          <div className={Classes.DIALOG_BODY}>
            <div>{dialogBody}</div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER}>
              <Button style={{ marginRight: '0.5em' }} onClick={() => setOpen(false)}>
                Annuler
              </Button>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => {
                  dialogAction()
                  setOpen(false)
                }}
              >
                Confirmer
              </Button>
            </div>
          </div>
        </Dialog>
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p, index) => (
          <CalculatedCell
            key={`${fieldName}_${p.id}`}
            fieldName={fieldName}
            period={p}
            percentageFieldName={percentageFieldName}
            suffix={suffix}
            showPercentageEvolution={noteSettings.showPercentageCedant && index > 0}
          />
        ))}
      {periods.map((p, index) => (
        <CalculatedCell
          key={`${fieldName}_${p.id}`}
          fieldName={`${fieldName}.numericData.value`}
          period={p}
          percentageFieldName={percentageFieldName}
          suffix={suffix}
          showPercentageEvolution={noteSettings.showPercentagePrevisionnel && index > 0}
        />
      ))}
    </tr>
  )
}
