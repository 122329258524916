import React, { FC, useCallback } from 'react'
import { ProjectAnalysisDocument, useDeleteEntrepreneurMutation } from '../../generated/graphql'
import { Button, Classes, Intent } from '@blueprintjs/core'
import { Entrepreneur, ProjectAnalysisQuery, ProjectAnalysisQueryVariables } from '@fa-metier/types'
import produce from 'immer'

type EntrepreneurModalProps = { onClose: () => any; entrepreneur: Entrepreneur }
export const DeleteEntrepreneurModal: FC<EntrepreneurModalProps> = ({ onClose, entrepreneur }) => {
  const [deleteFn] = useDeleteEntrepreneurMutation({
    update: (proxy) => {
      const { noteId } = entrepreneur
      const readQuery = proxy.readQuery<ProjectAnalysisQuery, ProjectAnalysisQueryVariables>({
        query: ProjectAnalysisDocument,
        variables: {
          noteId,
        },
      })!
      proxy.writeQuery<ProjectAnalysisQuery, ProjectAnalysisQueryVariables>({
        query: ProjectAnalysisDocument,
        variables: {
          noteId,
        },
        data: produce(readQuery, (draft) => {
          draft.projectAnalyzer.analysis!.entrepreneurs = draft.projectAnalyzer.analysis!.entrepreneurs.filter(
            (e) => e.id !== entrepreneur.id
          )
        }),
      })
    },
  })

  const deleteEntrepreneur = useCallback(
    async (idMuffin: string) => {
      await deleteFn({
        variables: {
          idMuffin,
        },
      })
    },
    [deleteFn]
  )

  return (
    <>
      <div className={Classes.DIALOG_BODY}>Supprimer l'entrepreneur ?</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose}>Fermer</Button>
          <Button
            onClick={(e: any) => {
              e.stopPropagation()
              return deleteEntrepreneur(entrepreneur.idMuffin)
            }}
            intent={Intent.DANGER}
          >
            Supprimer
          </Button>
        </div>
      </div>
    </>
  )
}
