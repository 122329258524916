import React, { Dispatch, SetStateAction, useContext, useMemo, useState, useEffect } from 'react'
import { NoteContainer } from './NoteContainer'
import { Note, NoteSettings, ProjectAnalysisQuery } from '@fa-metier/types'
import styled from 'styled-components'
import { NoteMenuDrawer } from './NoteMenuDrawer'
import { observer } from 'mobx-react-lite'
import { useGetNote } from './Financiary/NoteQueries'
import { useProjectAnalysis } from './ProjectAnalysisQueries'
import { ProjectContext } from '../App'
import { buildProjectName } from '../Projects/Projects'
import { YodaSpinnerPage } from '../utils/YodaSpinnerPage'
import { useNoteSettingsQuery } from '../generated/graphql'

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
`

const ProjectContainerWithMargin = styled.div`
  padding: 0 20px 10px 2%;
  width: 100%;
  overflow-y: scroll;
  height: 95vh;
`

interface NoteContextI {
  projectAnalysis: ProjectAnalysisQuery
  note: Note
  baseNotePath: string
  noteName: string
  noteId: string
  noteSettings: NoteSettings
  unsavedChanges: boolean
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>
  muffinSyncInProgress: boolean
  setMuffinSyncInProgress: Dispatch<SetStateAction<boolean>>
}

export const NoteContext = React.createContext<NoteContextI>({} as NoteContextI)

export const NotePage: React.FunctionComponent<{ readonly noteId?: any }> = observer(
  ({ noteId }) => {
    const { projectId, projectName, notesQuantity } = useContext(ProjectContext)
    const { loading: noteLoading, data: note } = useGetNote(noteId)
    const { loading: projectAnalysisLoading, data: projectAnalysis } = useProjectAnalysis(noteId)
    const { loading: settingsLoading, data: noteSettings } = useNoteSettingsQuery({
      variables: { noteId },
    })

    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [muffinSyncInProgress, setMuffinSyncInProgress] = useState(false)

    const loading = noteLoading || projectAnalysisLoading || settingsLoading

    const baseNotePath = `/project/${projectId}/note/${noteId}`
    const noteName = useMemo(
      () => buildProjectName(projectName, note?.numeroVersion ?? 0, notesQuantity),
      [note, notesQuantity, projectName]
    )

    const setMetaTitle = () => {
      if (window.location.href.match('\\/project\\/.*\\/note')) {
        document.title = projectName ?? 'France Active - YODA'
      } else {
        document.title = 'France Active - YODA'
      }
    }

    useEffect(() => {
      setMetaTitle()
      return () => {
        setMetaTitle()
      }
    }, [])

    return (
      <>
        {loading && <YodaSpinnerPage />}
        {!loading && projectAnalysis && note && noteSettings && (
          <FlexContainer>
            <NoteContext.Provider
              value={{
                projectAnalysis,
                note,
                baseNotePath,
                noteName,
                unsavedChanges,
                setUnsavedChanges,
                muffinSyncInProgress,
                setMuffinSyncInProgress,
                noteSettings: noteSettings.noteSettings,
                noteId: note.noteId,
              }}
            >
              <NoteMenuDrawer />
              <ProjectContainerWithMargin>
                <NoteContainer />
              </ProjectContainerWithMargin>
            </NoteContext.Provider>
          </FlexContainer>
        )}
      </>
    )
  }
)
