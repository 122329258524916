import React, { FC, useContext } from 'react'
import { DifferencesSaisiesPretsPasses } from './DifferencesSaisiesPretsPasses'
import {
  BankSafeIcon,
  BlackNumber,
  Container,
  GreenTitle,
  GreyText,
  HeaderCell,
  Synthese,
  SyntheseCard,
} from '../PretsSynthese'
import { PretsPassesContext } from './PretPasseTabs'
import { NoteContext } from '../../../NotePage'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'

const CellMontantTotal: FC<{ value: number }> = ({ value }) => {
  const { noteSettings } = useContext(NoteContext)
  return (
    <td>
      <div>
        <BlackNumber value={value?.toFixed(0)} suffix={eurosSuffix(noteSettings.kiloEuros)} />{' '}
        <GreyText>Total</GreyText>
      </div>
    </td>
  )
}

export const PretsPassesSynthese: FC = () => {
  const { pretsPasses } = useContext(PretsPassesContext)

  const montantTotal = pretsPasses
    .map((pretPasse) => (pretPasse.capital.value ? pretPasse.capital.value : 0))
    .reduce((prev, curr) => prev + curr, 0)

  return (
    <Container>
      <Container>
        <GreenTitle>Synthèse</GreenTitle>
      </Container>
      <SyntheseCard>
        <Synthese title={'Prêts passés'} icon={<BankSafeIcon />}>
          <tbody>
            <tr>
              <HeaderCell label={'Prêts'} data={pretsPasses.length} />
            </tr>
            <tr>
              <CellMontantTotal value={montantTotal} />
            </tr>
          </tbody>
        </Synthese>
      </SyntheseCard>
      <DifferencesSaisiesPretsPasses />
    </Container>
  )
}
