import React, { FC, useContext } from 'react'
import { NoteSettings, NoteType, NumericData } from '@fa-metier/types'
import { PeriodsContext } from '../Utils/PeriodsContext'
import { Dots } from '../FinancialTable'
import { Button, Classes, Dialog, Intent, Switch } from '@blueprintjs/core'
import { CalculatedCell, PeriodFinanciaryCell } from '../Utils/FinanciaryLine'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { PeriodModel } from '../PeriodModel'
import { CRContext, NavLinkGreen, SwitchContainer } from './CRForm'
import styled from 'styled-components'
import { NoteContext } from '../../NotePage'

const TitleTh = styled.th`
  display: flex;
  align-items: baseline;
`

export const ImpotsBeneficesLine: FC<{
  noteSettings: NoteSettings
  isEuro?: boolean
  displayPercentageReference?: boolean
  handleImpotsBenefices: () => any
}> = ({
  noteSettings,
  isEuro = true,
  displayPercentageReference = true,
  handleImpotsBenefices,
}) => {
  const { note } = useContext(NoteContext)
  const {
    showAnterieurs,
    periods,
    anterieurPeriods,
    updateAnterieurPeriods,
    updatePeriods,
    showComputations,
    showEvolutionColumn,
    situationIntermediaire,
    updateSituationIntermediaire,
  } = useContext(PeriodsContext)
  const { computationInProgress } = useContext(CRContext)
  const [openDialog, setOpenDialog] = React.useState(false)

  const updateAnterieurField = (periodId: string, n?: NumericData) => {
    if (updateAnterieurPeriods !== undefined) {
      updateAnterieurPeriods([
        { periodId, fieldPath: 'incomeStatement.impotsSurBeneficesInput', change: n },
      ])
    }
  }

  const updateSituationField = (periodId: string, n?: NumericData) => {
    if (updateSituationIntermediaire !== undefined) {
      updateSituationIntermediaire([
        { periodId, fieldPath: 'incomeStatement.impotsSurBeneficesInput', change: n },
      ])
    }
  }

  const updateField = (periodId: string, n?: NumericData) => {
    updatePeriods([{ periodId, fieldPath, change: n }])
  }

  const fieldPath = noteSettings.impotsSurBeneficeComputed
    ? 'incomeStatement.impotsSurBenefices.numericData'
    : 'incomeStatement.impotsSurBeneficesInput'

  const label = 'Impôts sur les bénéfices'
  const switchLabel = 'Calcul auto dans le CR prévi.'

  const navLink = (
    <NavLinkGreen to={'settings#tabsexercices-isTab'}>{"l'onglet Paramètres > IS"}</NavLinkGreen>
  )

  const isAnterieurPeriodsLine = (
    <ISAnterieurPeriodsLine
      showAnterieurs={showAnterieurs}
      anterieurPeriods={anterieurPeriods}
      situationIntermediaire={situationIntermediaire}
      isEuro={isEuro}
      displayPercentageReference={displayPercentageReference}
      showComputations={showComputations}
      updateAnterieurField={updateAnterieurField}
      updateSituationIntermediaireField={updateSituationField}
      isCalculate={computationInProgress}
    />
  )

  const warningDialog = (body: JSX.Element) => (
    <Dialog isOpen={openDialog} title={'Attention'} isCloseButtonShown={false}>
      <div className={Classes.DIALOG_BODY}>{body}</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER}>
          <Button style={{ marginRight: '0.5em' }} onClick={() => setOpenDialog(false)}>
            Annuler
          </Button>
          <Button
            intent={Intent.PRIMARY}
            onClick={() => {
              handleImpotsBenefices()
              setOpenDialog(false)
              return true
            }}
          >
            Confirmer
          </Button>
        </div>
      </div>
    </Dialog>
  )

  return (
    <>
      {noteSettings.assujettiImpotsSocietes === null ? (
        <tr>
          <TitleTh>
            {label}
            <Dots />
          </TitleTh>
          {isAnterieurPeriodsLine}
          {showEvolutionColumn && <td className={'evolution'}>&nbsp;</td>}
          <td colSpan={periods.length}>
            {' '}
            <div>
              Pour voir ou non le calcul de l'IS, veuillez paramétrer si l'entrepreneur est
              assujetti à l'IS dans &nbsp;
              {navLink}.
            </div>
          </td>
        </tr>
      ) : noteSettings.assujettiImpotsSocietes ? (
        !noteSettings.impotsSurBeneficeComputed ? (
          <tr className={'hoverable'}>
            <TitleTh>
              {label}
              <Dots />
              <SwitchContainer>
                <Switch
                  label={switchLabel}
                  defaultChecked={false}
                  onChange={() => setOpenDialog(true)}
                  disabled={computationInProgress}
                />
              </SwitchContainer>
              {warningDialog(
                <div>
                  <p>
                    Les données renseignées manuellement vont être supprimées, et l’
                    <b>impôt sur les bénéfices</b> va être calculé automatiquement.
                  </p>
                  <p>Vous pourrez toujours revenir à la saisie manuelle ultérieurement.</p>
                </div>
              )}
            </TitleTh>
            {isAnterieurPeriodsLine}
            {showEvolutionColumn && <td className={'evolution'}>&nbsp;</td>}
            {periods.map((p, index) => {
              return (
                <PeriodFinanciaryCell
                  key={`PeriodFinanciaryCell_${fieldPath}_${p.id}`}
                  fieldPath={fieldPath}
                  period={p}
                  isEuro={isEuro}
                  displayPercentageReference={displayPercentageReference}
                  isCalculate={computationInProgress}
                  showComputations={showComputations}
                  updateField={updateField}
                  showPercentageEvolution={
                    noteSettings.showPercentagePrevisionnel &&
                    (index > 0 || note.type === NoteType.StructureExistante)
                  }
                />
              )
            })}
          </tr>
        ) : (
          <tr className={'subtotal hoverable'}>
            <th className={'outlined'}>
              <div>{label}</div>
              <SwitchContainer>
                <Switch
                  label={switchLabel}
                  checked={true}
                  onChange={() => setOpenDialog(true)}
                  disabled={computationInProgress}
                />
              </SwitchContainer>
              {warningDialog(
                <div>
                  <p>
                    Les données renseignées automatiquement vont être supprimées afin de vous
                    permettre de saisir manuellement l’<b>impôt sur les bénéfices</b>.
                  </p>
                  <p>Vous pourrez toujours revenir au calcul automatique ultérieurement.</p>
                </div>
              )}
            </th>
            {isAnterieurPeriodsLine}
            {showEvolutionColumn && <td className={'evolution'}>&nbsp;</td>}
            {periods.map((p) => (
              <CalculatedCell
                key={`${fieldPath}_${p.id}`}
                fieldName={fieldPath}
                period={p}
                percentageFieldName={'incomeStatement.impotsSurBenefices.percentageReference'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            ))}
          </tr>
        )
      ) : (
        <tr>
          <TitleTh>
            {label}
            <Dots />
          </TitleTh>
          {isAnterieurPeriodsLine}
          {showEvolutionColumn && <td className={'evolution'}>&nbsp;</td>}
          <td colSpan={periods.length + (situationIntermediaire ? 1 : 0)}>
            {' '}
            <div>
              Non assujetti à l'IS dans &nbsp;
              {navLink}.
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

const ISAnterieurPeriodsLine: FC<{
  showAnterieurs?: boolean
  anterieurPeriods?: PeriodModel[]
  situationIntermediaire?: PeriodModel
  isEuro: boolean
  displayPercentageReference: boolean
  showComputations: boolean
  updateAnterieurField: (periodId: string, n?: NumericData) => any
  updateSituationIntermediaireField: (periodId: string, n?: NumericData) => any
  isCalculate?: boolean
}> = ({
  showAnterieurs = true,
  anterieurPeriods = [],
  situationIntermediaire,
  isEuro,
  displayPercentageReference,
  showComputations,
  updateAnterieurField,
  updateSituationIntermediaireField,
  isCalculate = false,
}) => {
  const { noteSettings } = useContext(NoteContext)
  return (
    <>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p, index) => {
          return (
            <PeriodFinanciaryCell
              key={`PeriodFinanciaryCell_impotsSurBeneficesInput_${p.id}`}
              fieldPath={'incomeStatement.impotsSurBeneficesInput'}
              period={p}
              isEuro={isEuro}
              displayPercentageReference={displayPercentageReference}
              isCalculate={isCalculate}
              showComputations={showComputations}
              updateField={updateAnterieurField}
              cellClassName={'anterieur'}
              showPercentageEvolution={noteSettings.showPercentageCedant && index > 0}
            />
          )
        })}
      {situationIntermediaire && (
        <PeriodFinanciaryCell
          key={`PeriodFinanciaryCell_impotsSurBeneficesInput_intermediaire_${situationIntermediaire.id}`}
          fieldPath={'incomeStatement.impotsSurBeneficesInput'}
          period={situationIntermediaire}
          isEuro={isEuro}
          displayPercentageReference={displayPercentageReference}
          isCalculate={isCalculate}
          showComputations={showComputations}
          updateField={updateSituationIntermediaireField}
          cellClassName={'intermediaire'}
          showPercentageEvolution={false}
        />
      )}
    </>
  )
}
