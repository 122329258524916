import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import {
  PlanFinancementEntryKey,
  PlanFinancementEntryType,
  Prime,
  UpdatePrimeInput,
} from '@fa-metier/types'
import { FlexDiv, PlanFinancementContext } from './PlanFinancement'
import { InputGroup } from '@blueprintjs/core'
import { NumberText } from '@fa-metier/components'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { PeriodModel } from '../PeriodModel'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import {
  FinancialPeriodsDocument,
  PlanFinancementDocument,
  RatiosDocument,
  RessourcesDocument,
  useUpdatePrimeMutation,
} from '../../../generated/graphql'
import { removeTypeName } from '@fa-metier/commons'
import { useDebounce } from 'react-use'
import { AppMetierCheckbox } from '../Utils/AppMetierCheckBox'
import styled from 'styled-components'
import { NoteContext } from '../../NotePage'
import { withFieldsGuard } from '../../../utils/utils'
import { useUserDebounce } from '../../../Settings/Hooks'
import _ from 'lodash'

export const PrimeLine: FC<{ prime: Prime; triggerCompute: number }> = ({
  prime,
  triggerCompute,
}) => {
  const { noteId, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const { setComputationInProgress } = useContext(PlanFinancementContext)
  const updatePrime = useUpdatePrime(noteId, prime.id)

  const [primeState, setPrimeState] = useState(prime)

  const refs = useRef({
    prime,
    primeState,
    unsavedChanges,
  })

  const userDebounce = useUserDebounce()

  const [, cancelDebounce] = useDebounce(
    async () => {
      if (!_.isEqual(primeState, prime)) {
        await withFieldsGuard(() => updatePrime(toPrimeInput(primeState)), setComputationInProgress)
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [primeState, toPrimeInput]
  )

  useEffect(() => {
    refs.current.primeState = primeState
    if (!_.isEqual(primeState, prime)) {
      setUnsavedChanges(true)
    }
  }, [primeState, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.prime, prime)) {
      refs.current.prime = prime
      setPrimeState(prime)
      cancelDebounce()
    }
  }, [prime, cancelDebounce])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: (force: boolean) => any = async (force: boolean) => {
    if (refs.current.unsavedChanges || force) {
      cancelDebounce()
      if (!_.isEqual(refs.current.primeState, refs.current.prime)) {
        await updatePrime(toPrimeInput(refs.current.primeState))
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    return () => {
      compute(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (triggerCompute) {
      compute(true)
    }
  }, [triggerCompute])

  const entry = {
    entryId: prime.id,
    label: prime.title,
    type: PlanFinancementEntryType.PretsAt,
    demarrage: prime.demarragePlanFinancement,
  }

  return <PrimeLineEntry entry={entry} prime={primeState} updatePrime={setPrimeState} />
}

const PrimeLineEntry: FC<{
  entry: PlanFinancementEntryKey
  prime: Prime
  updatePrime: Dispatch<SetStateAction<Prime>>
}> = ({ entry, prime, updatePrime }) => {
  const { noteSettings } = useContext(NoteContext)
  const { editablePrevisionnelPeriods, computationInProgress } = useContext(PlanFinancementContext)

  return (
    <tr className={'hoverable'}>
      <td>
        <InputGroup
          value={entry.label}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updatePrime((prime) => ({ ...prime, title: event.target.value }))
          }}
          disabled={computationInProgress}
        />
      </td>
      {noteSettings.showDemarrage && (
        <td className={'demarrage'}>
          <CellDiv>
            <NumberText
              value={entry.demarrage.value ?? 0}
              suffix={eurosSuffix(entry.demarrage.kiloEuros)}
              disabled={computationInProgress}
            />
            <PrimeCheckbox
              checked={!!prime.demarragePlanFinancement.value}
              onChange={() =>
                updatePrime((prime) => {
                  if (prime.demarragePlanFinancement.value) {
                    return {
                      ...prime,
                      demarragePlanFinancement: {
                        value: null,
                        detail: null,
                        kiloEuros: noteSettings.kiloEuros,
                      },
                    }
                  }
                  return { ...prime, demarragePlanFinancement: prime.capital }
                })
              }
              disabled={computationInProgress}
            />
          </CellDiv>
        </td>
      )}
      {editablePrevisionnelPeriods.map((period, index) => (
        <td key={`${entry.entryId}-${period.id}`}>
          {index === 0 && (
            <PrimePeriodValue period={period} prime={prime} updatePrime={updatePrime} />
          )}
        </td>
      ))}
    </tr>
  )
}

const PrimePeriodValue: FC<{
  period: PeriodModel
  prime: Prime
  updatePrime: Dispatch<SetStateAction<Prime>>
}> = ({ period, prime, updatePrime }) => {
  const { showComputations, computationInProgress } = useContext(PlanFinancementContext)
  const data = period.planFinancement.primes.find((p) => p.entryId === prime.id)?.data
  return (
    <FlexDiv>
      <NumericDataInput
        numericData={data}
        onChange={(value) => {
          updatePrime((prime) => ({ ...prime, capital: value }))
        }}
        showComputations={showComputations}
        isCalculated={computationInProgress}
      />
    </FlexDiv>
  )
}

export const PrimeBlock: FC<{ primes: Prime[]; triggerCompute: number }> = ({
  primes,
  triggerCompute,
}) => {
  const { noteSettings } = useContext(NoteContext)
  const { editablePrevisionnelPeriods } = useContext(PlanFinancementContext)

  return (
    <>
      <tr>
        <td className={'title'}>Primes</td>
        {noteSettings.showDemarrage && <td className={'demarrage'} />}
        <td colSpan={editablePrevisionnelPeriods.length} />
        <td className={'action'} />
      </tr>
      {primes.map((prime, index) => (
        <PrimeLine prime={prime} key={`prime-${index}`} triggerCompute={triggerCompute} />
      ))}
    </>
  )
}

const toPrimeInput: (prime: Prime) => UpdatePrimeInput = (prime: Prime) => {
  return removeTypeName({
    title: prime.title,
    capital: prime.capital,
    demarragePlanFinancement: prime.demarragePlanFinancement,
  })
}

const useUpdatePrime = (noteId: string, primeId: string) => {
  const [pretMutation] = useUpdatePrimeMutation({
    update: (cache) => {
      cache.evictQueries([
        {
          query: RessourcesDocument,
          variables: { noteId },
        },
        {
          query: FinancialPeriodsDocument,
          variables: { noteId },
        },
        {
          query: PlanFinancementDocument,
          variables: { noteId },
        },
        { query: RatiosDocument, variables: { noteId } },
      ])
    },
  })
  return (update: UpdatePrimeInput) => {
    return new Promise<Prime>((resolve, reject) => {
      pretMutation({
        variables: { primeId, update },
      })
        .then((response) => {
          const result = response.data && response.data.prime.patch
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const CellDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    padding-left: 12px;
  }
`

const PrimeCheckbox = styled(AppMetierCheckbox)`
  .bp3-control-indicator {
    padding-left: 0;
  }

  margin-bottom: 0;
  padding-left: 0;
`
