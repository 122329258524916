import $ from 'jquery'

const arrow = {
  left: 'ArrowLeft',
  up: 'ArrowUp',
  right: 'ArrowRight',
  down: 'ArrowDown',
  tab: 'Tab',
  enter: 'Enter',
}

export const handleUserKeyPress = (event: KeyboardEvent) => {
  if (
    $.inArray(event.key, [
      arrow.left,
      arrow.up,
      arrow.right,
      arrow.down,
      arrow.tab,
      arrow.enter,
    ]) !== -1
  ) {
    const input = event.target as HTMLInputElement
    // @ts-ignore
    const td = $(event.target).closest('td')
    let moveTo = null

    if (td.length > 0) {
      switch (event.key) {
        case arrow.up:
        case arrow.down:
        case arrow.enter:
          const tr = td.closest('tr')
          const pos = td[0].cellIndex
          let moveToRow = null
          if (event.key === arrow.down || event.key === arrow.enter) {
            moveToRow = tr.next('tr')
          } else if (event.key === arrow.up) {
            moveToRow = tr.prev('tr')
          }
          if (moveToRow && moveToRow.length) {
            moveTo = $(moveToRow[0].cells[pos])
          }
          break
        case arrow.left:
          // @ts-ignore
          if (input.selectionStart === 0) {
            moveTo = td.prev('td:has(input,textarea)')
          }
          break
        case arrow.right:
          if (input.selectionEnd === input.value.length) {
            moveTo = td.next('td:has(input,textearea)')
          }
          break
      }

      if (moveTo && moveTo.length) {
        event.preventDefault()
        moveTo.find('input,textarea').each((i: number, input: HTMLInputElement) => {
          input.focus()
        })
      }
    }
  }
}
