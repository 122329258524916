import styled from 'styled-components'
import React, { createContext, FC, useContext, useMemo, useState } from 'react'
import { H4, Icon, IconName, Tab, Tabs } from '@blueprintjs/core'
import { Pret, PretPasse, PretSynthese } from '@fa-metier/types'
import PageTitle from '../../../utils/PageTitle'
import { PretForm } from './PretForm'
import { NumberText } from '@fa-metier/components'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { PretsSynthese } from './PretsSynthese'
import { NoteContext } from '../../NotePage'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { useGetRessources } from './PretsQueries'
import { PeriodModel } from '../PeriodModel'
import { useGetPeriods } from '../FinanciaryQueries'
import { useDictionnary } from '../../dictionnary/dictionnary'

export const DEFAULT_MAX_TABS = 3
export const SYNTHESE = 'synthese'

export const PretTabsContainer = styled.div`
  .bp3-tab-list {
    justify-content: center;
    border-bottom: none;
  }

  .bp3-tab-list > *:not(:last-child) {
    margin-right: 0;
  }

  .bp3-tab-indicator-wrapper {
    transition: none;
  }

  * .bp3-tab {
    flex: 1;
    box-shadow: 1px 1px 9px var(--main-grey-2-lighter) !important;
  }

  .bp3-tab-list .bp3-tab[aria-selected='true'].synthese-tab {
    background-color: var(--main-blue-1) !important;
  }
`

export const ActionContent = styled.div`
  height: 61px;
  width: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: var(--main-green-1);

  :hover {
    cursor: pointer;
    background-color: var(--main-green-1);
    color: white;
  }
`

export const DisabledActionContent = styled.div`
  height: 61px;
  width: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-grey-3);
  color: var(--main-grey-1);
`

export const TabTitleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  * {
    margin-bottom: -5px;
  }
`

const FlexVerticalDiv = styled.div`
  height: 41px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
`

export const TabAction: FC<{ icon: IconName; onClick: () => any; disabled?: boolean }> = ({
  icon,
  onClick,
  disabled,
}) => {
  return (
    <>
      {!disabled && (
        <ActionContent onClick={onClick}>
          <Icon icon={icon} iconSize={28} />
        </ActionContent>
      )}
      {disabled && (
        <DisabledActionContent>
          <Icon icon={icon} iconSize={28} />
        </DisabledActionContent>
      )}
    </>
  )
}

const titleColor = (selected: boolean) => {
  return selected ? 'white' : 'var(--main-grey-1)'
}

export const TabTitle: FC<{ pret: Pret | PretPasse; selected: boolean }> = ({ selected, pret }) => {
  return (
    <TabTitleContent>
      <H4 style={{ color: titleColor(selected) }}>{pret.title}</H4>
      <span style={{ color: titleColor(selected) }}>
        {(pret.capital.value ?? 0) > 0 && pret.duration > 0 && (
          <>
            <NumberText
              value={pret.capital.value ?? 0}
              suffix={eurosSuffix(pret.capital.kiloEuros)}
            />{' '}
            ({durationToString(pret.duration + pret.delayed)})
          </>
        )}
        {((pret.capital.value ?? 0) <= 0 || pret.duration <= 0) && <span>À renseigner</span>}
      </span>
    </TabTitleContent>
  )
}

export const TabSyntheseTitle: FC<{ selected: boolean }> = ({ selected }) => {
  return (
    <TabTitleContent>
      <FlexVerticalDiv>
        <H4 style={{ color: titleColor(selected) }}>Synthèse</H4>
      </FlexVerticalDiv>
    </TabTitleContent>
  )
}

export interface PretListProps {
  maxTabs?: number
}

const durationToString = (duration: number) => {
  const yearsToString = (years: number) => {
    if (years > 1) {
      return `${years} ans`
    }
    return '1 an'
  }
  const month = duration % 12
  const year = (duration - month) / 12
  const yearString = year === 0 ? undefined : yearsToString(year)
  const monthString = month === 0 ? undefined : `${month} mois`
  if (yearString && monthString) {
    return `${yearString} et ${monthString}`
  }
  if (yearString) {
    return yearString
  }
  return monthString
}

interface PretsContextI {
  prets: Pret[]
  pretSynthese: PretSynthese
  previsionelPeriods: PeriodModel[]
}

export const PretsContext = createContext<PretsContextI>({} as PretsContextI)

export const PretTabsWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading: ressourcesLoading, data: ressources } = useGetRessources(noteId)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)

  if (ressourcesLoading || periodLoading) {
    return <YodaSpinnerPage />
  }
  return (
    <PretsContext.Provider
      value={{
        prets: ressources!!.prets,
        pretSynthese: ressources!!.pretSynthese,
        previsionelPeriods: periodsData.previsionnelles,
      }}
    >
      <PretTabs />
    </PretsContext.Provider>
  )
}

const PretTabs: FC<PretListProps> = ({ maxTabs = DEFAULT_MAX_TABS }) => {
  const { prets } = useContext(PretsContext)
  const [selectedPretId, setSelectedPretId] = useState<string | undefined>(SYNTHESE)
  const [offset, setOffset] = useState(0)

  if (prets.length === 0 && selectedPretId !== undefined && selectedPretId !== SYNTHESE) {
    setSelectedPretId(SYNTHESE)
  }

  const tabsNumber = useMemo(() => prets.length + 1, [prets])

  const dictionnary = useDictionnary()

  return (
    <PretTabsContainer>
      <PageTitle>{dictionnary.prets.pageTitle}</PageTitle>

      <Tabs
        selectedTabId={selectedPretId}
        renderActiveTabPanelOnly={true}
        onChange={(newTabId) => setSelectedPretId(newTabId.toString())}
      >
        {tabsNumber > maxTabs && (
          <TabAction
            icon={'chevron-left'}
            onClick={() => setOffset(offset - 1)}
            disabled={offset === 0}
          />
        )}
        <Tab
          className={'synthese-tab'}
          style={{ minWidth: prets.length > maxTabs ? '300px' : '340px' }}
          hidden={offset > 0}
          key={SYNTHESE}
          id={SYNTHESE}
          panel={<PretsSynthese />}
          title={<TabSyntheseTitle selected={selectedPretId === SYNTHESE} />}
        />
        {prets.map((pret, index) => (
          <Tab
            style={{ minWidth: prets.length > maxTabs ? '300px' : '340px' }}
            hidden={index + 1 < offset || index + 2 > offset + maxTabs}
            key={pret.id}
            id={pret.id}
            panel={<PretForm key={pret.id} inputPret={pret} />}
            title={<TabTitle pret={pret} selected={pret.id === selectedPretId} />}
          />
        ))}
        {tabsNumber > maxTabs && (
          <TabAction
            icon={'chevron-right'}
            onClick={() => setOffset(offset + 1)}
            disabled={offset + maxTabs === tabsNumber}
          />
        )}
      </Tabs>
    </PretTabsContainer>
  )
}
