import React, { FC, useContext, useState } from 'react'
import { Button, Dialog, Tab, Tabs } from '@blueprintjs/core'
import styled from 'styled-components'
import { useUpdateNoteSettings } from './NoteSettingsQueries'
import { DIALOG_BODY, DIALOG_FOOTER } from '@blueprintjs/core/lib/esm/common/classes'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import PageTitle, { TabContentTitle } from '../utils/PageTitle'
import PeriodsForm from './Financiary/Period/PeriodsForm'
import { EmptyTva, TvaFormWrapper } from './Financiary/Fiscalite/TvaForm'
import { FiscaliteForm } from './Financiary/Fiscalite/FiscaliteForm'
import { FinanciaryWrapperContext } from './Financiary/Financiary'
import { useLocation } from 'react-router'
import { NoteType, PeriodType } from '@fa-metier/types'
import { NoteContext } from './NotePage'
import { useDictionnary } from './dictionnary/dictionnary'

const Params = styled.div`
  margin: 1vw;
  width: fit-content;
`

const impotSocieteTab = 'isTab'

export const setSelectedTab = (tabId: string) => {
  window.history.pushState('object or string', 'Title', `#tabs${tabId}`)
}

const getSelectedTabs = () => {
  const hash = window.location.hash
  const start = hash.indexOf('#tabs')
  if (start === -1) {
    return []
  }
  const slicedStart = hash.slice(start + 5)
  const conditionalEnd = slicedStart.indexOf('&')
  const end = conditionalEnd === -1 ? slicedStart.length : conditionalEnd
  return slicedStart.slice(0, end).split('-')
}

export const NoteSettingsPage: FC = () => {
  const { note } = useContext(NoteContext)
  const location = useLocation()
  const forceImpotSocieteTab = new URLSearchParams(location.search).get('tabId') === impotSocieteTab
  const selectedTabs = getSelectedTabs()
  const dictionnary = useDictionnary()

  const defaultTab = () => {
    if (selectedTabs.length > 0) {
      return selectedTabs[0]
    }
    if (note.type === NoteType.Creation || note.type === NoteType.StructureExistante) {
      return 'exercices'
    }
    return forceImpotSocieteTab ? 'exercices' : 'cedant'
  }
  const [currentTab, setCurrentTab] = useState(defaultTab())

  const selectTab = (tabId: string) => {
    setSelectedTab(tabId)
    setCurrentTab(tabId)
  }

  return (
    <Params>
      <PageTitle>Paramètres de la note</PageTitle>
      <Tabs
        defaultSelectedTabId={defaultTab()}
        onChange={(newTabId) => selectTab(newTabId.toString())}
      >
        {note.type === NoteType.Reprise && (
          <Tab
            key={'cedant'}
            id={'cedant'}
            title={'Cédant'}
            panel={
              <CedantTab
                defaultTab={selectedTabs[1]}
                onChange={(newTabId: string) => selectTab(`${currentTab}-${newTabId}`)}
              />
            }
          />
        )}
        {note.type !== NoteType.StructureExistante && (
          <Tab
            key={'exercices'}
            id={'exercices'}
            title={dictionnary.noteSettings.tabExerciceTitle}
            panel={
              <PrevisionnelTab
                forceImpotSociete={forceImpotSocieteTab}
                onChange={(newTabId: string) => setSelectedTab(`${currentTab}-${newTabId}`)}
                defaultTab={selectedTabs[1]}
              />
            }
          />
        )}
        {note.type === NoteType.StructureExistante && (
          <Tab
            key={'exercices'}
            id={'exercices'}
            title={'Exercices'}
            panel={
              <StructureExistanteTab
                forceImpotSociete={forceImpotSocieteTab}
                onChange={(newTabId: string) => setSelectedTab(`${currentTab}-${newTabId}`)}
                defaultTab={selectedTabs[1]}
              />
            }
          />
        )}
        <Tab key={'unit'} id={'unit'} title={"Unité d'affichage"} panel={<UnitTab />} />
      </Tabs>
    </Params>
  )
}

const PrevisionnelTab: FC<{
  forceImpotSociete: boolean
  onChange: (newTabId: string) => void
  defaultTab?: string
}> = ({ forceImpotSociete, onChange, defaultTab }) => {
  const { previsionelPeriods } = useContext(FinanciaryWrapperContext)
  return (
    <Tabs
      defaultSelectedTabId={forceImpotSociete ? impotSocieteTab : defaultTab ?? 'exercices'}
      onChange={(newTabId) => onChange(newTabId.toString())}
    >
      <Tab
        key={'exercices'}
        id={'exercices'}
        title={'Durée exercices comptables'}
        panel={<PeriodsForm periodType={PeriodType.Previsionnel} />}
      />
      <Tab
        key={'tva'}
        id={'tva'}
        title={'TVA'}
        panel={
          previsionelPeriods!!.length === 0 ? (
            <EmptyTva />
          ) : (
            <TvaFormWrapper periods={previsionelPeriods} />
          )
        }
      />
      <Tab key={impotSocieteTab} id={impotSocieteTab} title={'IS'} panel={<FiscaliteForm />} />
    </Tabs>
  )
}

const StructureExistanteTab: FC<{
  forceImpotSociete: boolean
  onChange: (newTabId: string) => void
  defaultTab?: string
}> = ({ forceImpotSociete, onChange, defaultTab }) => {
  const { previsionelPeriods, anterieurPeriods, situationIntermediaire } =
    useContext(FinanciaryWrapperContext)
  return (
    <Tabs
      defaultSelectedTabId={forceImpotSociete ? impotSocieteTab : defaultTab ?? 'exercices'}
      onChange={(newTabId) => onChange(newTabId.toString())}
    >
      <Tab
        key={'exercices'}
        id={'exercices'}
        title={'Durée exercices comptables'}
        panel={<PeriodsForm periodType={PeriodType.Previsionnel} />}
      />
      <Tab
        key={'tva'}
        id={'tva'}
        title={'TVA'}
        panel={
          previsionelPeriods!!.length === 0 ? (
            <EmptyTva />
          ) : (
            <TvaFormWrapper
              periods={(anterieurPeriods ?? [])
                .concat(situationIntermediaire ?? [])
                .concat(previsionelPeriods)}
            />
          )
        }
      />
      <Tab key={impotSocieteTab} id={impotSocieteTab} title={'IS'} panel={<FiscaliteForm />} />
    </Tabs>
  )
}

const CedantTab: FC<{ defaultTab: string; onChange: (newTabId: string) => void }> = ({
  onChange,
  defaultTab,
}) => {
  const { anterieurPeriods } = useContext(FinanciaryWrapperContext)
  return (
    <Tabs defaultSelectedTabId={defaultTab} onChange={onChange}>
      <Tab
        key={'exercices'}
        id={'exercices'}
        title={'Durée exercices comptables cédant'}
        panel={<PeriodsForm periodType={PeriodType.Anterieur} />}
      />
      <Tab
        key={'tvaTab'}
        id={'tvaTab'}
        title={'TVA cédant'}
        panel={
          (anterieurPeriods ?? []).length === 0 ? (
            <EmptyTva />
          ) : (
            <TvaFormWrapper periods={anterieurPeriods} />
          )
        }
      />
    </Tabs>
  )
}

export const UnitTab: FC = () => {
  const { noteId, noteSettings } = useContext(NoteContext)
  const updateSettings = useUpdateNoteSettings(noteId)
  const [showPopup, setShowPopup] = useState(false)
  const toggleParamKiloEuros = () => {
    setShowPopup(!showPopup)
  }
  const kiloEuros = noteSettings.kiloEuros

  return (
    <div>
      <TabContentTitle>Unité d'affichage</TabContentTitle>
      <Params style={{ backgroundColor: 'white', padding: '20px', margin: '0', width: '100%' }}>
        <AppMetierSwitch
          checked={kiloEuros}
          label={'Afficher les données en K€'}
          onChange={() => {
            toggleParamKiloEuros()
          }}
        />
      </Params>
      <Dialog isOpen={showPopup} title={'Attention'} isCloseButtonShown={false}>
        <div className={DIALOG_BODY}>
          <div>
            Si vous avez fait des calculs dans les onglets du volet financier, le détail sera
            supprimé définitivement. Seul le résultat sera conservé.
            <br />
            <br />
            Par exemple : si vous avez saisi dans une case « 45+12 », vous ne retrouverez que « 57 »
            après la conversion.
            <br />
            <br />
            Etes-vous sûr de vouloir changer ce paramètre ?
          </div>
        </div>
        <div className={DIALOG_FOOTER}>
          <Button style={{ marginRight: '0.5em' }} onClick={() => setShowPopup(false)}>
            Annuler
          </Button>
          <Button
            intent={'primary'}
            onClick={async () => {
              updateSettings({
                kiloEuros: !kiloEuros,
              }).then(() => setShowPopup(false))
            }}
          >
            Valider
          </Button>
        </div>
      </Dialog>
    </div>
  )
}
