import React, { FC, useContext, useState } from 'react'
import { NumericData, PlanFinancementEntryKey } from '@fa-metier/types'
import { useRemovePlanFinancementEntry } from './PlanFinancementQueries'
import { PeriodModel } from '../PeriodModel'
import produce from 'immer'
import { Button, InputGroup } from '@blueprintjs/core'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import { FlexDiv, PlanFinancementContext } from './PlanFinancement'
import { NoteContext } from '../../NotePage'
import { PFContext } from './PlanFinancementContainer'

export const EditableEntryLine: FC<{ entry: PlanFinancementEntryKey; onlyDemarrage?: boolean }> = ({
  entry,
  onlyDemarrage = false,
}) => {
  const { noteId, noteSettings } = useContext(NoteContext)
  const { planFinancement } = useContext(PFContext)
  const {
    showComputations,
    updateEntryLabel,
    updateEntryDemarrage,
    updatePeriod,
    editablePrevisionnelPeriods,
    resetDebounceTimers,
    computationInProgress,
  } = useContext(PlanFinancementContext)
  const removeEntry = useRemovePlanFinancementEntry(noteId)

  const [hovered, setHovered] = useState(false)

  const updateField = (period: PeriodModel, value: NumericData) => {
    return produce(period, (draft) => {
      if (draft.planFinancement.editableEntries.find((e) => e.entryId === entry.entryId)) {
        draft.planFinancement.editableEntries.find((e) => e.entryId === entry.entryId)!!.data =
          value
      } else {
        draft.planFinancement.editableEntries.push({
          entryId: entry.entryId,
          data: value,
        })
      }
    })
  }

  const demarrageField = planFinancement.editableEntries.find(
    (it) => it.entryId === entry.entryId
  )?.demarrage

  return (
    <tr
      className={'hoverable'}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <td>
        <InputGroup
          value={entry.label}
          asyncControl={true}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updateEntryLabel(entry.entryId, event.target.value)
            resetDebounceTimers()
          }}
          onFocus={() => resetDebounceTimers()}
          disabled={computationInProgress}
        />
      </td>
      {noteSettings.showDemarrage && (
        <td className={'demarrage'}>
          <FlexDiv>
            <NumericDataInput
              numericData={demarrageField}
              onChange={(value) => {
                resetDebounceTimers()
                updateEntryDemarrage(entry.entryId, value)
              }}
              showComputations={showComputations}
              isCalculated={computationInProgress}
            />
          </FlexDiv>
        </td>
      )}
      {!onlyDemarrage &&
        editablePrevisionnelPeriods.map((period) => (
          <td key={`${entry.entryId}-${period.id}`}>
            <FlexDiv>
              <NumericDataInput
                numericData={
                  period.planFinancement.editableEntries.find((it) => it.entryId === entry.entryId)
                    ?.data
                }
                onChange={(value) => {
                  resetDebounceTimers()
                  updatePeriod(updateField(period, value))
                }}
                showComputations={showComputations}
                isCalculated={computationInProgress}
              />
            </FlexDiv>
          </td>
        ))}
      {onlyDemarrage && <td colSpan={editablePrevisionnelPeriods.length} />}
      <td className={'action'}>
        {hovered && (
          <Button icon={'trash'} minimal large={false} onClick={() => removeEntry(entry.entryId)} />
        )}
      </td>
    </tr>
  )
}
