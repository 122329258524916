import React, { ChangeEvent, FC, useLayoutEffect, useState } from 'react'
import { Button, ButtonGroup, ControlGroup, InputGroup, Intent } from '@blueprintjs/core'
import {
  computeValue,
  formatAsNumber,
  normalizeDecimalSeparator,
} from '@fa-metier/components/dist/formatNumber/FormatNumber'
import styled from 'styled-components'
import { handleUserKeyPress } from '../keyboard-navigation'

interface AppMetierNumberInputProps {
  value: number | undefined
  inKiloEuros?: boolean
  suffix?: string
  customCSS?: React.CSSProperties
  textAlignEnd?: boolean
  onChange: (value: number | undefined) => any
  disabled?: boolean
  intent?: Intent
  small?: boolean
  allowedValues?: number[]
  defaultValue?: number
  step?: number
  onlyInteger?: boolean
  onInput?: () => void
}

const ControlGroupNumberInput = styled(ControlGroup)`
  display: flex;
  flex-direction: row;
`

const InputGroupNumberInput = styled(InputGroup)`
  flex-grow: 1;
`

const ButtonGroupNumberInput = styled(ButtonGroup)`
  flex-grow: 0;
`

const InputContainer = styled.div`
  width: fit-content;
  border: var(--main-grey-2-lighter) 1px solid;
  border-radius: 3px;
  .action {
    background-color: white;
    border: none !important;
    box-shadow: none !important;
    .bp3-icon {
      color: var(--main-grey-2);
    }
  }
  .bp3-input-group {
    display: contents;
  }
  .bp3-input {
    text-align: center;
    padding-right: 0;
  }
  input {
    box-shadow: none !important;
  }
`

export const AppMetierNumberInput: FC<AppMetierNumberInputProps> = ({
  value,
  inKiloEuros,
  suffix,
  onChange,
  intent,
  disabled,
  allowedValues,
  defaultValue,
  step = 1,
  onlyInteger = false,
  onInput,
}) => {
  const displaySuffix = suffix ? (inKiloEuros ? ' K'.concat(suffix) : ' '.concat(suffix)) : ''
  const [focused, setFocused] = useState(false)
  const [display, setDisplay] = useState<string>(
    normalizeDecimalSeparator(value?.toString().concat(displaySuffix) ?? '')
  )

  if (
    (value || value === 0) &&
    normalizeDecimalSeparator(formatAsNumber(value?.toString()).concat(displaySuffix)) !==
      display &&
    allowedValues === undefined &&
    !focused
  ) {
    setDisplay(
      normalizeDecimalSeparator(formatAsNumber(value?.toString()).concat(displaySuffix) ?? '')
    )
  } else if (
    allowedValues === undefined &&
    defaultValue !== undefined &&
    normalizeDecimalSeparator(formatAsNumber(defaultValue?.toString()).concat(displaySuffix)) !==
      display &&
    !focused
  ) {
    setDisplay(
      normalizeDecimalSeparator(formatAsNumber(defaultValue.toString()).concat(displaySuffix) ?? '')
    )
  }

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
  })

  const onLeaveFocus = () => {
    const displayValue = normalizeDecimalSeparator(formatAsNumber(value?.toString()))
    setDisplay(displayValue !== '' ? displayValue.concat(displaySuffix) : '')
    setFocused(false)
  }

  const onFocus = () => {
    !!onInput && onInput()
    setDisplay(normalizeDecimalSeparator(formatAsNumber(value?.toString()).replace(/\s/g, '')))
    setFocused(true)
  }

  const updateValue = (value: string) => {
    let sanitizedValue = value
      .replace(/\s/g, '')
      .replace(/,/g, '.')
      .replace(/[a-zA-Z]+/g, '')

    sanitizedValue = limitToTwoDigits(sanitizedValue)

    if (onlyInteger) {
      sanitizedValue = sanitizedValue.replace(/\./g, '')
    }

    if (suffix !== undefined) {
      sanitizedValue = sanitizedValue.replace(/K/g, '').replace(/€/g, '').replace(/%/g, '')
    }
    let returnComputeValue = computeValue(sanitizedValue)

    if (returnComputeValue !== undefined && returnComputeValue < 0) {
      returnComputeValue = 0
      sanitizedValue = '0'
    }
    setDisplay(normalizeDecimalSeparator(formatAsNumber(sanitizedValue)))
    onChange(returnComputeValue)
  }

  const limitToTwoDigits = (value: string) => {
    if (value.includes('.')) {
      const splits = value.split('.')
      const decimals = splits[1].slice(0, 2)
      return `${splits[0]}.${decimals}`
    }
    return value
  }

  const getValue = () => {
    let sanitizedValue = display
      .replace(/\s/g, '')
      .replace(/,/g, '.')
      .replace(/[a-zA-Z]+/g, '')
    if (suffix !== undefined && sanitizedValue !== undefined) {
      sanitizedValue = sanitizedValue.replace(/K/g, '').replace(/€/g, '').replace(/%/g, '')
    }
    return computeValue(sanitizedValue)
  }

  const increment = () => {
    const currentValue = getValue() ?? 0
    let updatedValue = currentValue + step
    if (allowedValues !== undefined) {
      const currentIndex = allowedValues.indexOf(currentValue)
      if (currentIndex === -1) {
        updatedValue = defaultValue !== undefined ? defaultValue : allowedValues[0]
      } else if (currentIndex === allowedValues.length - 1) {
        updatedValue = allowedValues[allowedValues.length - 1]
      } else {
        updatedValue = allowedValues[currentIndex + 1]
      }
    }

    const returnComputeValue = computeValue(`${updatedValue}`)
    if (returnComputeValue !== undefined) {
      onChange(returnComputeValue)
    }
    setDisplay(
      normalizeDecimalSeparator(formatAsNumber(updatedValue.toString())).concat(displaySuffix)
    )
  }

  const decrement = () => {
    const currentValue = getValue() ?? 0
    let updatedValue = +Math.max(0, currentValue - step).toFixed(2)
    if (allowedValues !== undefined) {
      const currentIndex = allowedValues.indexOf(currentValue)
      if (currentIndex === -1) {
        updatedValue = defaultValue !== undefined ? defaultValue : allowedValues[0]
      } else if (currentIndex === 0) {
        updatedValue = allowedValues[0]
      } else {
        updatedValue = allowedValues[currentIndex - 1]
      }
    }
    const returnComputeValue = computeValue(`${updatedValue}`)
    if (returnComputeValue !== undefined) {
      onChange(returnComputeValue)
    }
    setDisplay(normalizeDecimalSeparator(updatedValue.toString()).concat(displaySuffix))
  }

  return (
    <InputContainer>
      <ControlGroupNumberInput>
        <ButtonGroupNumberInput>
          <Button icon="minus" className={'action'} onClick={decrement} disabled={disabled} />
        </ButtonGroupNumberInput>
        <InputGroupNumberInput
          value={display}
          onFocus={onFocus}
          onBlur={onLeaveFocus}
          onChange={(event: ChangeEvent<HTMLInputElement>) => updateValue(event.target.value)}
          disabled={disabled}
          intent={intent}
          className={'inputfield'}
        />
        <ButtonGroup style={{ marginRight: 0 }}>
          <Button icon="plus" className={'action'} onClick={increment} disabled={disabled} />
        </ButtonGroup>
      </ControlGroupNumberInput>
    </InputContainer>
  )
}
