import styled from 'styled-components'

export const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 22px 32px 22px;
  max-width: 420px;
`

export const Time = styled.div`
  color: var(--main-blue-1);
  text-align: right;
  font-size: 12px;
  margin-bottom: 10px;
`

export const Item = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-start;
`

export const ItemIcon = styled.div`
  color: var(--main-yellow-1);
  padding: 10px 10px 10px 0;
`

export const ItemTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  padding: 0;
`

export const ItemTitle = styled.h1`
  color: var(--main-black-2);
  font-size: 18px;
  line-height: 44px;
  font-weight: bold;
  margin: 0;
`

export const ItemDescription = styled.p`
  font-family: 'Montserrat';
  margin-top: 10px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin: 0;
  padding: 4px 0;
`
