import React, { FC, useContext } from 'react'
import { PretCard, PretSubsectionTitle } from './PretStyle'
import { NumberText } from '@fa-metier/components'
import { Pret, PretPasse } from '@fa-metier/types'
import styled from 'styled-components'
import { Divider } from '@blueprintjs/core'
import dayjs from 'dayjs'
import { formatDayjs } from '../../../utils/DayjsFormatter'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { NoteContext } from '../../NotePage'

const SyntheseContent = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 26px;
`

const SyntheseSubsection = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;

  span {
    width: 100%;
  }
`

const SyntheseSubtitle = styled.span`
  text-decoration: underline;
`

const GreyText = styled.span`
  color: var(--main-grey-1);
`

const GreenText = styled.span`
  color: var(--main-green-1);
`

const Months = styled.span`
  color: var(--main-black-1);
  font-weight: var(--bold);
`

const Amount = styled.span`
  font-size: 20px;
  line-height: 26px;
  color: var(--main-black-2);
  font-weight: 400;
`

const TotalDuration = styled.span`
  font-size: 27px;
  line-height: 32px;
  color: var(--main-black-2);
  font-weight: 400;
`

const TotalCost = styled.span`
  font-size: 27px;
  line-height: 32px;
  color: var(--main-green-1);
  font-weight: 400;
`

const everyOtherMonth = (yearlyPaymentNumber: number) => {
  const result = yearlyPaymentNumber !== 0 ? 12 / yearlyPaymentNumber : 1
  if (result === 1) {
    return (
      <GreyText>
        Tous les <Months>mois</Months>
      </GreyText>
    )
  }
  return (
    <GreyText>
      Tous les <Months>{result} mois</Months>
    </GreyText>
  )
}

const totalEcheances = (echeanceNumber: number) => {
  if (echeanceNumber === 1) {
    return (
      <>
        <TotalDuration>{echeanceNumber}</TotalDuration> <br /> échéance au total
      </>
    )
  }
  return (
    <>
      <TotalDuration>{echeanceNumber}</TotalDuration> <br /> échéances au total
    </>
  )
}

export const PretSynthese: FC<{ pret: Pret | PretPasse }> = ({ pret }) => {
  const { noteSettings } = useContext(NoteContext)
  const computedPret = pret.computedPret!!
  const echeanceGap = pret.yearlyPaymentNb === 0 ? 0 : 12 / pret.yearlyPaymentNb

  const startingDate = dayjs(pret.startingDate!!)
  const endDelayDate = pret.inFine
    ? startingDate?.add(pret.duration - 2 * echeanceGap, 'month')
    : startingDate?.add(pret.delayed - echeanceGap, 'month')
  const afterDelayStartingDate =
    pret.delayed > 0 ? endDelayDate?.add(echeanceGap, 'month') : startingDate
  const endDate = pret.inFine
    ? startingDate?.add(pret.duration + pret.delayed, 'month')
    : startingDate?.add(pret.duration + pret.delayed - echeanceGap, 'month')

  const delayInterest =
    computedPret.tableauAmortissement.length > 0 ? computedPret.tableauAmortissement[0].interets : 0
  const globalAmount =
    computedPret.tableauAmortissement[computedPret.tableauAmortissement.length - 1]
      ?.montantGlobal ?? 0
  const totalCost = computedPret.tableauAmortissement.reduce(
    (cost, echeance) => cost + echeance.interets,
    0
  )

  return (
    <>
      <PretSubsectionTitle>Synthèse</PretSubsectionTitle>
      <PretCard style={{ marginBottom: '10px' }}>
        <SyntheseContent>
          {(pret.delayed > 0 || pret.inFine) && (
            <>
              <SyntheseSubtitle>Différé</SyntheseSubtitle>
              <SyntheseSubsection>
                <GreyText>
                  Du {formatDayjs(startingDate)} <br />
                  Au {formatDayjs(endDelayDate)}
                </GreyText>
                {delayInterest > 0 && (
                  <>
                    <span>{everyOtherMonth(pret.yearlyPaymentNb)}</span>
                    <Amount>
                      <NumberText
                        value={delayInterest}
                        suffix={eurosSuffix(noteSettings.kiloEuros)}
                      />
                    </Amount>
                  </>
                )}
                {delayInterest === 0 && (
                  <>
                    <GreyText>Aucun remboursement</GreyText>
                    <span />
                  </>
                )}
              </SyntheseSubsection>
              <br />
            </>
          )}
          <SyntheseSubtitle>Remboursement du capital</SyntheseSubtitle>
          <SyntheseSubsection>
            <GreyText>
              {!pret.inFine && (
                <>
                  Du {formatDayjs(afterDelayStartingDate)} <br />
                </>
              )}
              Au {formatDayjs(endDate)}
            </GreyText>
            {!pret.inFine && everyOtherMonth(pret.yearlyPaymentNb)}
            {pret.inFine && <GreyText>En une fois</GreyText>}
            <Amount>
              <NumberText value={globalAmount} suffix={eurosSuffix(noteSettings.kiloEuros)} />
            </Amount>
          </SyntheseSubsection>
          <br />
          <Divider />
          <br />
          <SyntheseSubsection>
            <GreyText>
              <TotalDuration>{pret.duration + pret.delayed}</TotalDuration> <br /> mois au total
            </GreyText>
            <GreyText>
              {totalEcheances(
                pret.inFine && pret.annualRate === 0 ? 1 : computedPret.totalEcheance
              )}
            </GreyText>
            <GreenText>
              <TotalCost>
                <NumberText value={totalCost} suffix={eurosSuffix(noteSettings.kiloEuros)} />
              </TotalCost>{' '}
              <br /> Coût total du prêt
            </GreenText>
          </SyntheseSubsection>
        </SyntheseContent>
      </PretCard>
    </>
  )
}
