import { FinanciaryLine, Line, LineProps, SubLine, TotalLine } from '../Utils/FinanciaryLine'
import React, { FC, useContext, useState } from 'react'
import { getField } from '@fa-metier/commons'
import { Button, Dialog, Icon } from '@blueprintjs/core'
import { DIALOG_BODY, DIALOG_FOOTER } from '@blueprintjs/core/lib/esm/common/classes'
import { PeriodsContext, UpdatePeriodForPath } from '../Utils/PeriodsContext'
import styled from 'styled-components'
import { VariableLineGroup } from './VariableLineGroup'
import { NumericDataWithDetail } from '../IncomeStatementMapper'
import { Dots } from '../FinancialTable'
import { useSessionState } from '../../../utils/SessionStorageHook'
import { PeriodModel } from '../PeriodModel'
import { PeriodType } from '@fa-metier/types'

const OptionalLine = styled(Line)`
  animation: slideIn 0.3s;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translate3d(0, -4rem, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  td:first-child {
    padding-left: 50px;
  }
  background-color: #e6fffb;
`

export interface DetailLine {
  label?: string
  key: string
  showOnPeriodTypes?: PeriodType[]
}

export interface TotalLineProps extends LineProps {
  detailLines: DetailLine[]
  isVariableDetailLines?: boolean
  onChangeRowsNb?: () => any
  className?: string
  hasChargesVariables?: boolean
  isSubtotal?: boolean
  isCalculate?: boolean
}

const CreateRemoveDetails = styled.span`
  margin-right: 0.5em;
  color: var(--main-green-1);
  :hover {
    cursor: pointer;
  }
  width: fit-content;
  text-align: right;
`

const InlinedIcon = styled(Icon)`
  margin-left: 5px;
`

export const TotalLineGroup: FC<TotalLineProps> = ({
  label,
  fieldName,
  detailLines,
  isVariableDetailLines = false,
  className,
  onChangeRowsNb = () => {},
  hasChargesVariables = false,
  isSubtotal = false,
  isCalculate = false,
}) => {
  const {
    periods,
    anterieurPeriods,
    updatePeriods,
    updateAnterieurPeriods,
    upToDate,
    showAnterieurs,
    situationIntermediaire,
    updateSituationIntermediaire,
  } = useContext(PeriodsContext)
  const { showEvolutionColumn } = useContext(PeriodsContext)
  const allPeriods = (showAnterieurs && anterieurPeriods ? anterieurPeriods : [])
    .concat(situationIntermediaire ? [situationIntermediaire] : [])
    .concat(periods)
  const initHasDetail: boolean = allPeriods
    .map((p) => getField<NumericDataWithDetail>(fieldName, p))
    .reduce((p, c) => p || c.hasDetail, false)
    .valueOf()

  const [hasDetail, setHasDetail] = useState(initHasDetail)
  const [collapsedDetail, setCollapsedDetail] = useSessionState(fieldName, true)
  const [showPopup, setShowPopup] = useState(false)

  const toggleDetail = () => {
    if (hasDetail) {
      setShowPopup(true)
    } else {
      setHasDetail(true)
      setCollapsedDetail(false)
      onChangeRowsNb()
    }
  }

  const deleteDetail = () => {
    const buildUpdateRequest = (periodsToUpdate: PeriodModel[]) => {
      return periodsToUpdate.flatMap((p) => {
        return detailLines
          .map((d) => d.key)
          .map((path) => ({
            periodId: p.id,
            fieldPath: path,
            change: isVariableDetailLines ? { lines: [] } : null,
          }))
      })
    }
    const buildUpdateTotal = (periodsToUpdate: PeriodModel[]) => {
      return periodsToUpdate.map((p) => {
        const totalValue = getField(`${fieldName}.total`, p, { value: 0, detail: 0 })
        return {
          periodId: p.id,
          fieldPath: `${fieldName}.total`,
          change: totalValue,
        }
      })
    }

    const updateRequest: UpdatePeriodForPath[] = buildUpdateRequest(periods)
    const updateRequestAnterieur: UpdatePeriodForPath[] = buildUpdateRequest(anterieurPeriods ?? [])
    const updateRequestSituation: UpdatePeriodForPath[] = buildUpdateRequest(
      situationIntermediaire ? [situationIntermediaire] : []
    )

    const updateTotal = buildUpdateTotal(periods)
    const updateTotalAnterieur = buildUpdateTotal(anterieurPeriods ?? [])
    const updateTotalSituation = buildUpdateTotal(
      situationIntermediaire ? [situationIntermediaire] : []
    )

    updatePeriods(updateRequest.concat(updateTotal))
    if (showAnterieurs && anterieurPeriods !== undefined && updateAnterieurPeriods !== undefined) {
      updateAnterieurPeriods(updateRequestAnterieur.concat(updateTotalAnterieur))
    }

    if (situationIntermediaire && updateSituationIntermediaire !== undefined) {
      updateSituationIntermediaire(updateRequestSituation.concat(updateTotalSituation))
    }

    setShowPopup(false)
    setHasDetail(false)
    onChangeRowsNb()
  }
  return (
    <>
      <tr className={isSubtotal ? `subtotal ${className}` : className}>
        <th className={className ? 'outlined' : ''}>
          {label}
          <Dots />
          <CreateRemoveDetails onClick={toggleDetail}>
            <u style={hasDetail ? { marginRight: '20px' } : {}}>
              {hasDetail ? 'Supprimer le détail' : 'Saisir le détail'}
            </u>
            {!hasDetail && <InlinedIcon icon={'add'} />}
          </CreateRemoveDetails>
          {hasDetail && (
            <Button
              icon={collapsedDetail ? 'chevron-down' : 'chevron-up'}
              minimal={true}
              disabled={!upToDate}
              onClick={() => {
                setCollapsedDetail(!collapsedDetail)
                onChangeRowsNb()
              }}
            />
          )}
        </th>
        {(!hasDetail || collapsedDetail) && (
          <FinanciaryLine
            fieldPath={`${fieldName}.total`}
            isCalculate={hasDetail || isCalculate}
            isSubtotal={isSubtotal}
          />
        )}
        {hasDetail &&
          !collapsedDetail &&
          showAnterieurs &&
          (anterieurPeriods ?? []).map((p) => (
            <td key={p.id + fieldName} className={isSubtotal ? 'subtotal' : 'anterieur'} />
          ))}
        {hasDetail && !collapsedDetail && situationIntermediaire && (
          <td className={isSubtotal ? 'subtotal' : 'intermediaire'}>&nbsp;</td>
        )}
        {hasDetail && !collapsedDetail && showEvolutionColumn && (
          <td className={isSubtotal ? 'subtotal evolution' : 'evolution'}>&nbsp;</td>
        )}
        {hasDetail && !collapsedDetail && periods.map((p) => <td key={p.id + fieldName} />)}
      </tr>
      {hasDetail &&
        !collapsedDetail &&
        detailLines.map((detail) =>
          isVariableDetailLines ? (
            <VariableLineGroup
              key={detail.key}
              fieldPath={detail.key}
              label={detail.label}
              useChargesVariables={hasChargesVariables}
            />
          ) : (
            <OptionalLine
              key={detail.key}
              fieldName={detail.key}
              label={detail.label}
              isDetail={true}
              showOnPeriodTypes={detail.showOnPeriodTypes}
              isCalculate={isCalculate}
            />
          )
        )}
      {hasDetail && !collapsedDetail && (
        <TotalLine fieldName={`${fieldName}.total`} label={'Total'} />
      )}
      {hasChargesVariables && (
        <SubLine
          label={'Dont charges variables'}
          fieldName={`${fieldName}.totalChargesVariables`}
          isNotEditable={hasDetail || isCalculate}
        />
      )}
      <Dialog isOpen={showPopup} title={'Attention'} isCloseButtonShown={false}>
        <div className={DIALOG_BODY}>
          <div>
            Etes-vous sûr de vouloir supprimer le détail de <b>{label}</b> ?
          </div>
          <div>Cette action ne peut pas être inversée.</div>
        </div>
        <div className={DIALOG_FOOTER}>
          <Button style={{ marginRight: '0.5em' }} onClick={() => setShowPopup(false)}>
            Annuler
          </Button>
          <Button intent={'primary'} onClick={deleteDetail}>
            Valider
          </Button>
        </div>
      </Dialog>
    </>
  )
}
