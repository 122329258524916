import { ReactNode, useContext } from 'react'
import { NoteContext } from '../NotePage'
import { NoteType, PeriodType } from '@fa-metier/types'
import { creationDictionnary } from './creation-dictionnary'
import { repriseDictionnary } from './reprise-dictionnary'
import { PeriodModel } from '../Financiary/PeriodModel'
import { structureExistanteDictionnary } from './structure-existante-dictionnary'

export interface NoteDictionnary {
  cr: NavigationDetails
  bfr: {
    menuLabel: string
  }
  differencesSaisies: {
    targetTitle: string
    crSuccessText: ReactNode
    crExplanation: ReactNode
    pfSuccessText: ReactNode
    pfExplanation: ReactNode
    masseSalarialeMoreInformation: ReactNode
  }
  noteSettings: {
    tabExerciceTitle: string
    baseDateLabel: (periodType?: PeriodType) => string
    tvaTabLink: (periodType?: PeriodType) => string
  }
  periodTitle: (p: PeriodModel, index: number) => string
  bilan: NavigationDetails
  exerciceCedantLabel: string
  commentAnterieurLabel: string
  benchmarchAnterieurPeriodLabel: string
  prets: NavigationDetails
  pretTVADemarrage: PretTVADemarrageDetails
  pretsPasse: NavigationDetails
  noExerciceText: string
  noExerciceLink: string
  evolutionPercentagePassedLabel: string
}

export interface PretTVADemarrageDetails {
  label: string
  button: string
}

export interface NavigationDetails {
  ongletName: string
  pageTitle: string
  linkText: string
}

export const useDictionnary: () => NoteDictionnary = () => {
  const { note } = useContext(NoteContext)
  switch (note.type) {
    case NoteType.Reprise:
      return repriseDictionnary
    case NoteType.StructureExistante:
      return structureExistanteDictionnary
    case NoteType.Creation:
    case NoteType.UndefinedCreation:
      return creationDictionnary
  }
}
