import React, { FC } from 'react'
import { Spinner } from '@blueprintjs/core'
import styled from 'styled-components'

const SpinnerContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

const ImgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const YodaImg = styled.img`
  max-width: 100px;
  animation: pulse 3s linear infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      opacity: 0;
    }
    10% {
      transform: scale(1);
      opacity: 0.2;
    }
    20% {
      transform: scale(1.05);
      opacity: 0.4;
    }
    30% {
      transform: scale(1.1);
      opacity: 0.6;
    }
    40% {
      transform: scale(1.15);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.2);
      opacity: 1;
    }
    60% {
      transform: scale(1.15);
      opacity: 0.8;
    }
    70% {
      transform: scale(1.1);
      opacity: 0.6;
    }
    80% {
      transform: scale(1.05);
      opacity: 0.4;
    }
    90% {
      transform: scale(1);
      opacity: 0.2;
    }
    100% {
      transform: scale(0.95);
      opacity: 0;
    }
  }
`

export const YodaSpinnerPage: FC<{ size?: number }> = ({ size = 150 }) => {
  return (
    <SpinnerContainer>
      <Spinner size={size} />
      {size === 150 && (
        <ImgContainer>
          <YodaImg alt={''} src={require('../assets/yoda-icon.png')} />
        </ImgContainer>
      )}
    </SpinnerContainer>
  )
}
