import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useAppContext } from './config/context'
import { Alignment, Icon, Navbar } from '@blueprintjs/core'
import React from 'react'
import { Link, NavLink, useRouteMatch } from 'react-router-dom'
import environment from './config/environment'
import NoteAdditionalInfo from './ConditionsUtilisation/NoteAdditionalInfo'
import { StrokedButton } from "@fa-metier/components/dist/Buttons/Buttons"

const Logo = () => (
  <span style={{ fontSize: '28px', fontWeight: 600, textTransform: 'uppercase' }}>
    <span style={{ color: 'var(--main-green-1)' }}>yoda</span>
  </span>
)
const UserBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:first-child {
    margin-right: 1em;
    color: black;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
const FakeLink = styled.span`
  color: var(--main-green-1);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
const PATH_TO_MATCH = '/project/:projectId/note/:noteId'

export const AppliNavBar = observer(() => {
  const { authStore } = useAppContext()
  const { user } = authStore
  const match = useRouteMatch(PATH_TO_MATCH)
  // @ts-ignore
  const getNoteId = () => match?.params.noteId
  const showTermsOfUseBtn = (): boolean =>
    environment.TERMS_OF_USE_ENABLED && match != null && user != null

  return (
    <Navbar style={{ height: '64px' }}>
      <NavbarGroup align={Alignment.LEFT}>
        <Navbar.Heading>
          <Link to={'/'} style={{ textDecoration: 'none' }}>
            <Logo /> <span>version {environment.APP_VERSION}</span>
          </Link>
        </Navbar.Heading>
      </NavbarGroup>
      <NavbarGroup align={Alignment.RIGHT}>
        <StrokedButton onClick={() =>
          window.open(
            "https://forms.office.com/pages/responsepage.aspx?id=q-rlhneUQkWRhVESvUCoeX_YUOxCWwdJnb1OoGdSnLdUNU4ySTVONzhGRjhIOEo3TjhFVlpQMFgyUyQlQCN0PWcu",
            "_blank"
          )
        }
        >
          Aide
        </StrokedButton>
        {showTermsOfUseBtn() && (
          <NoteAdditionalInfo
            noteId={getNoteId()}
            withComiteSimplifie={environment.COMITE_SIMPLIFIE_ENABLED}
          />
        )}
        {user && (
          <UserBlock>
            <NavLink to={'/settings'}>
              <Icon icon={'user'} iconSize={32} />
            </NavLink>
            <div>
              <div>
                {user.firstName} {user.lastName}
              </div>
              {environment.LOGOUT_ENABLED && (
                <FakeLink onClick={() => authStore.logout()}>Déconnexion</FakeLink>
              )}
            </div>
          </UserBlock>
        )}
      </NavbarGroup>
    </Navbar>
  )
})

const NavbarGroup = styled(Navbar.Group)`
  height: 64px;
`
