import React, { FC, useEffect, useState } from 'react'
import {
  MySettingsDocument,
  useAddFavoriteMutation,
  useNoteOverviewQuery,
  useProjectQuery,
  useRemoveFavoriteMutation,
} from '../generated/graphql'
import { AdvisorSetting, Lifecycle, LightNote, MySettingsQuery, ProjectId } from '@fa-metier/types'
import styled from 'styled-components'
import { Card, Classes, Icon, Intent } from '@blueprintjs/core'
import { useAppContext } from '../config/context'
import { useFavoriteProjects } from '../Settings/Hooks'
import { NoteId } from '../Project/Financiary/NoteQueries'
import { DataProxy } from '@apollo/client'
import { AppToaster } from '../utils/AppToaster'
import { getLifecycle } from '../Project/ProjectHub/Presentation'

export const Projects: FC = () => {
  const [pids, setPids] = useState<string[]>([])
  const favProjectsInfos = useFavoriteProjects()

  useEffect(() => {
    if (favProjectsInfos.length > 0) {
      setPids(favProjectsInfos)
    }
  }, [favProjectsInfos])

  return (
    <div style={{ marginLeft: '10px' }}>
      <div style={{ display: 'flex', marginTop: '20px' }}>
        <h1>Projets</h1>
        <div style={{ flexGrow: 1 }} />
      </div>
      <ProjectCardContainer>
        {pids.map((pid) => (
          <ProjectCard key={pid} projectId={pid} />
        ))}
      </ProjectCardContainer>
    </div>
  )
}

export const buildProjectName: (
  projectName: string,
  noteVersion: number,
  notesQuantity: number
) => string = (projectName, noteVersion, notesQuantity) => {
  const version = notesQuantity > 1 ? ` - v${noteVersion}` : ''
  return `${projectName}${version}`
}

const ProjectCard: FC<{ projectId: ProjectId }> = ({ projectId }) => {
  const { data, loading, error } = useProjectQuery({ variables: { projectId } })

  if (error) {
    AppToaster.show({
      message: `Une erreur est survenue lors du chargement du projet ${projectId}`,
      intent: Intent.DANGER,
    })
  }

  if (loading || !data) {
    return <></>
  }
  const displayVersion = data.notes.length > 1

  return (
    <>
      {data.notes.map((note: LightNote) => (
        <NoteCard
          key={note.id}
          noteId={note.id}
          projectId={projectId}
          projectName={data!!.project.name}
          lifecycle={note.lifecycle}
          version={note.version}
          displayVersion={displayVersion}
        />
      ))}
    </>
  )
}

const NoteCard: FC<{
  noteId: NoteId
  projectName: string
  lifecycle: Lifecycle
  projectId: ProjectId
  version: number
  displayVersion: boolean
}> = ({ noteId, projectName, lifecycle, projectId, version, displayVersion }) => {
  const { routingStore } = useAppContext()
  const { data, loading, error } = useNoteOverviewQuery({ variables: { noteId } })

  if (error) {
    AppToaster.show({
      message: `Une erreur est survenue lors du chargement de la note ${noteId}`,
      intent: Intent.DANGER,
    })
  }

  return (
    <Card
      interactive={true}
      onClick={() => routingStore.history.push(`/project/${projectId}/note/${noteId}/presentation`)}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <em className={loading ? Classes.SKELETON : ''}>Pacte: {getLifecycle(lifecycle)}</em>
        <FavoriteIcon projectId={projectId} />
      </div>
      <span>
        <ProjectName className={loading ? Classes.SKELETON : ''}>{projectName}</ProjectName>
        {displayVersion && <ProjectVersion> &nbsp;V{version}</ProjectVersion>}
      </span>
      <em className={loading ? Classes.SKELETON : ''}>Ile de France*</em>
      <Empty height={15} />
      <em className={loading ? Classes.SKELETON : ''}>Tags: chats, corgis*</em>
      {!loading && data && (
        <ProjectProgressBar means={data!.involvementCheckResults.results.categoryResults} />
      )}
    </Card>
  )
}

const FavoriteIcon: FC<{ projectId: ProjectId }> = ({ projectId }) => {
  const isFavorite = useFavoriteProjects().includes(projectId)

  const updateSettingStore = (proxy: DataProxy, data: AdvisorSetting) => {
    proxy.writeQuery<MySettingsQuery>({
      query: MySettingsDocument,
      data: { advisorSettings: { settings: data } },
    })
  }

  const [addFavoriteMutation] = useAddFavoriteMutation({
    variables: { projectId },
    update: (proxy, { data }) =>
      updateSettingStore(proxy, data!.advisorSettings.addFavoriteProject),
  })
  const [removeFavoriteMutation] = useRemoveFavoriteMutation({
    variables: { projectId },
    update: (proxy, { data }) =>
      updateSettingStore(proxy, data!.advisorSettings.removeFavoriteProject),
  })

  return (
    <Icon
      icon={isFavorite ? 'star' : 'star-empty'}
      onClick={async (e) => {
        e.stopPropagation()
        if (isFavorite) {
          await removeFavoriteMutation()
        } else {
          await addFavoriteMutation()
        }
      }}
    />
  )
}

const ProjectProgressBar: FC<{ means: { mean: number; valid: boolean }[] }> = ({ means }) => {
  const okay = means.filter((m) => m.mean >= 2 && m.valid).length
  const average = means.filter((m) => m.mean < 2 && m.valid).length
  const rest = 5 - okay - average
  return (
    <ProjectProgressBarContainer>
      {Array(okay)
        .fill(1)
        .map((value, index) => (
          <ProjectProgressBarTick bgColor={'var(--main-green-1)'} key={`ok-${index}`} />
        ))}
      {Array(average)
        .fill(1)
        .map((value, index) => (
          <ProjectProgressBarTick bgColor={'var(--main-yellow)'} key={`average-${index}`} />
        ))}
      {Array(rest)
        .fill(1)
        .map((value, index) => (
          <ProjectProgressBarTick bgColor={'white'} key={`invalid-${index}`} />
        ))}
    </ProjectProgressBarContainer>
  )
}

const ProjectProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  > div:first-child {
    border-bottom-left-radius: 3px;
  }

  > div:last-child {
    border-bottom-right-radius: 3px;
  }

  > div:not(:last-child) {
    border-right: 1px solid white;
  }
`
const ProjectProgressBarTick = styled.div<{ bgColor: string }>`
  flex: auto;
  height: 15px;
  background: ${({ bgColor }) => bgColor};
`

const ProjectCardContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  > div {
    position: relative;
    margin: 5px;
    min-width: 300px;
    display: flex;
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 5px;
    }
  }
`
const Empty = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
`

const ProjectName = styled.span`
  font-size: larger;
  font-weight: var(--bold);
`

const ProjectVersion = styled.span`
  color: var(--main-blue);
`
