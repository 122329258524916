import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBell,
  faLeaf,
  faHandshake,
  faSitemap,
  faMapMarkerAlt,
  faUsers,
  faStar,
  faEdit,
  faExclamationTriangle,
  faExternalLinkAlt,
  faCheck,
  faTimes,
  faChevronDown,
  faEuroSign,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faMapMarkedAlt,
  faCopy,
  faTrash,
  faCheckCircle,
  faInfoCircle,
  faClock,
  faRotateRight,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'

import { faFolder, faLightbulb, faFileAlt, faFilePdf } from '@fortawesome/free-regular-svg-icons'

library.add(
  faBell,
  faLeaf,
  faHandshake,
  faSitemap,
  faMapMarkerAlt,
  faUsers,
  faFolder,
  faLightbulb,
  faFileAlt,
  faStar,
  faEdit,
  faExclamationTriangle,
  faTimes,
  faExternalLinkAlt,
  faCheck,
  faChevronDown,
  faEuroSign,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faCopy,
  faTrash,
  faMapMarkedAlt,
  faFilePdf,
  faCheckCircle,
  faInfoCircle,
  faClock,
  faRotateRight,
  faTriangleExclamation
)
