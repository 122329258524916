import React, { FC, useContext, useEffect, useState } from 'react'
import { NumberText } from '@fa-metier/components'
import { NumericData, PeriodType, TvaPortion } from '@fa-metier/types'
import styled from 'styled-components'
import { useDebounce } from 'react-use'
import { FinanciaryContext } from '../Financiary'
import { AppMetierTable } from '@fa-metier/components/dist/Table/AppMetierTable'
import { AppMetierNumberInput } from '../Utils/AppMetierInput/AppMetierNumberInput'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { TvaContext } from './TvaForm'
import { NoteContext } from '../../NotePage'
import { DEBOUNCE_CONFIG } from '../../../Settings/debounceConfig'

interface RatioByTva {
  [key: number]: number | undefined
}

function initRatioByTva(value: TvaPortion[], tvaList: number[]): RatioByTva {
  const tmp: { [key: number]: number | undefined } = {}
  tvaList.forEach((tva) => {
    const tauxTva = value.find((v) => v.tauxTVA === tva)
    tmp[tva] = tauxTva ? tauxTva.ratio * 100 : undefined
  })
  return tmp
}

function montantCaByTva(ca: NumericData, tvaPortions: TvaPortion[], tva: number): number | string {
  const tvaPortion = tvaPortions.find((value) => value.tauxTVA === tva)
  return tvaPortion && ca?.value ? ca.value * tvaPortion.ratio : ''
}

const SmallNumericInput = styled(AppMetierNumberInput)`
  max-width: 5em;
`

const StyledIncomeTvaTable = styled(AppMetierTable)`
  font-size: 14px;

  tr *:first-child {
    text-align: left;
  }

  .error {
    text-align: right !important;
    color: red;
  }

  th {
    font-size: 0.9em;
  }

  td.title {
    white-space: normal !important;
    min-width: 210px !important;
  }

  * td {
    padding: 5px;
  }

  th.ttc {
    min-width: 6em;
  }
`

const RatioByCA: FC<{
  lineTitle: string
  tvaPortion: TvaPortion[]
}> = ({ lineTitle, tvaPortion }) => {
  const { noteSettings } = useContext(NoteContext)
  const { previsionelPeriods, anterieurPeriods } = useContext(FinanciaryContext)
  const { tvaList, tvaType } = useContext(TvaContext)

  const isPrevisionnel = tvaType === PeriodType.Previsionnel

  const examplePeriod = isPrevisionnel
    ? previsionelPeriods &&
      previsionelPeriods.sort((a, b) => (a.startDate.isAfter(b.startDate) ? 1 : -1))[0]
    : anterieurPeriods &&
      anterieurPeriods.sort((a, b) => (a.startDate.isAfter(b.startDate) ? 1 : -1)).reverse()[0]

  const hint = isPrevisionnel
    ? 'Le montant est calculé à partir du premier exercice.'
    : 'Le montant est calculé à partir du dernier exercice du cédant'
  return (
    <>
      <tr>
        <td className={'title'}>{lineTitle}</td>
        {tvaList.map((tva) => (
          <td key={tva}>
            <NumberText
              value={montantCaByTva(
                examplePeriod?.incomeStatement.chiffre_affaire.total,
                tvaPortion,
                tva
              )}
              decimalScale={2}
              allowNegative={false}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />
          </td>
        ))}
        <td />
      </tr>
      <tr className={'empty'}>
        <th />
        {tvaPortion.length > 0 && <td colSpan={tvaList.length}>{hint}</td>}
      </tr>
    </>
  )
}

const RatioByTvaTr: FC<{
  lineTitle: string
  value: TvaPortion[]
  onChange: (value: TvaPortion[]) => any
}> = ({ value, onChange, lineTitle }) => {
  const { tvaList } = useContext(TvaContext)
  const [ratioByTva, setRatioByTva] = useState(() => initRatioByTva(value, tvaList))
  const [totalPercent, setTotalPercent] = useState<number>(0)

  useEffect(() => {
    const total = tvaList.reduce((previous, current) => {
      const percent = ratioByTva[current] || 0
      return previous + percent
    }, 0)
    setTotalPercent(total)
  }, [ratioByTva, setTotalPercent, tvaList])

  useDebounce(
    () => {
      if (totalPercent === 100) {
        onChange(
          Object.entries(ratioByTva)
            .filter(([, value]) => value !== undefined)
            .map(([key, value]) => ({ ratio: value! / 100, tauxTVA: Number(key) }))
        )
      }
    },
    DEBOUNCE_CONFIG.delay.updateRequest,
    [ratioByTva]
  )

  return (
    <>
      <tr>
        <td className={'title'}>{lineTitle}</td>
        {tvaList.map((tva) => (
          <td key={tva}>
            <SmallNumericInput
              value={ratioByTva[tva]}
              onChange={(v: any) => setRatioByTva((ratioByTva) => ({ ...ratioByTva, [tva]: v }))}
              suffix={'%'}
              customCSS={{ maxWidth: '5em' }}
              small={true}
            />
          </td>
        ))}
        <td
          className={'ttc'}
          style={totalPercent !== 100 ? { color: 'red' } : {}}
        >{`${totalPercent.toFixed(2)}%`}</td>
      </tr>
      {totalPercent !== 100 && (
        <tr className={'empty'}>
          <td colSpan={tvaList.length + 2} className={'error'}>
            La somme des pourcentages doit être égale à 100
          </td>
        </tr>
      )}
    </>
  )
}

export const IncomeTvaTable: FC<{
  value: TvaPortion[]
  onChange: (value: TvaPortion[]) => any
}> = ({ value, onChange }) => {
  const { tvaList } = useContext(TvaContext)

  return (
    <StyledIncomeTvaTable>
      <thead>
        <tr>
          <td className={'title'}>Taux de TVA</td>
          {tvaList.map((tva) => (
            <th key={tva}>{Number(tva * 100).toFixed(2)}%</th>
          ))}
          <th className={'ttc'}>Total</th>
        </tr>
      </thead>
      <tbody>
        <RatioByTvaTr lineTitle={"Part du chiffre d'affaires"} value={value} onChange={onChange} />
        <RatioByCA lineTitle={"Montant du chiffre d'affaires"} tvaPortion={value} />
      </tbody>
    </StyledIncomeTvaTable>
  )
}

export const ExpenseTvaTable: FC<{
  achatMarchandises: TvaPortion[]
  autreAchat: TvaPortion[]
  onChangeAchatMarchandises: (value: TvaPortion[]) => any
  onChangeAutreAchat: (value: TvaPortion[]) => any
}> = ({ achatMarchandises, autreAchat, onChangeAchatMarchandises, onChangeAutreAchat }) => {
  const { tvaList } = useContext(TvaContext)
  return (
    <StyledIncomeTvaTable>
      <thead>
        <tr>
          <td className={'title'}>Taux de TVA</td>
          {tvaList.map((tva) => (
            <th key={tva}>{Number(tva * 100).toFixed(2)}%</th>
          ))}
          <th className={'ttc'}>Total</th>
        </tr>
      </thead>
      <tbody>
        <RatioByTvaTr
          lineTitle={'Part achat de marchandises'}
          value={achatMarchandises}
          onChange={onChangeAchatMarchandises}
        />
        <RatioByTvaTr
          lineTitle={'Part autres achats et charges externes'}
          value={autreAchat}
          onChange={onChangeAutreAchat}
        />
      </tbody>
    </StyledIncomeTvaTable>
  )
}
