export interface Environment {
  production: boolean
  AUTH_URL: string
  API_URL: string
  SESAM_URL: string
  APP_VERSION: string
  TERMS_OF_USE_ENABLED: boolean
  COMITE_SIMPLIFIE_ENABLED: boolean
  MEMENTO_ENABLED: boolean
  LOGOUT_ENABLED: boolean
}

const getEnv = (): Environment => {
  if (process.env.REACT_APP_ENV === 'dev') {
    return {
      production: false,
      AUTH_URL: `${process.env.REACT_APP_AUTH_URL}`,
      API_URL: `${process.env.REACT_APP_API_URL}`,
      SESAM_URL: `${process.env.REACT_APP_SESAM_URL}`,
      APP_VERSION: `${process.env.REACT_APP_VERSION ?? 'locale'}`,
      MEMENTO_ENABLED: true,
      LOGOUT_ENABLED: true,
      TERMS_OF_USE_ENABLED: process.env.REACT_APP_TERMS_OF_USE_ENABLED === 'true',
      COMITE_SIMPLIFIE_ENABLED: process.env.REACT_APP_COMITE_SIMPLIFIE_ENABLED === 'true',
    }
  }
  // @ts-ignore
  const env = window.__env || {}
  return {
    production: true,
    AUTH_URL: env.AUTH_URL,
    API_URL: env.API_URL,
    SESAM_URL: env.SESAM_URL,
    APP_VERSION: env.APP_VERSION,
    MEMENTO_ENABLED: env.MEMENTO_ENABLED,
    LOGOUT_ENABLED: env.LOGOUT_ENABLED,
    TERMS_OF_USE_ENABLED: env.TERMS_OF_USE_ENABLED,
    COMITE_SIMPLIFIE_ENABLED: env.COMITE_SIMPLIFIE_ENABLED,
  }
}

export default getEnv()
