import { SelectItem } from './AppMetierSelect'
import { Dispatch, SetStateAction } from 'react'

export const arraysEqual = (_arr1: any[], _arr2: any[]) => {
  if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
    return false
  }

  const arr1 = _arr1.slice().sort()
  const arr2 = _arr2.slice().sort()

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()
}

export const modelToSelectItem: (model: string, replaceValue: string) => SelectItem = (
  model,
  replaceValue
) => {
  return {
    id: model,
    text:
      model.charAt(0).toUpperCase() + model.substring(1).toLowerCase().replace(/_/g, replaceValue),
  }
}

/**
 * Wrap a function (usually a mutation) in a way of protecting it from other mutations and not losing the focus
 * @param fn function to wrap
 * @param setComputationInProgress state modification to apply before and after the wrapped function
 */
export const withFieldsGuard = async (
  fn: () => Promise<any>,
  setComputationInProgress: Dispatch<SetStateAction<boolean>>
): Promise<void> => {
  const lastActiveElement = document.activeElement
  const lastActiveElementId = lastActiveElement?.id

  setComputationInProgress(true)
  await fn()
  setComputationInProgress(false)

  if (lastActiveElement instanceof HTMLInputElement) {
    if (lastActiveElementId) {
      document.getElementById(lastActiveElementId)?.focus()
    } else {
      lastActiveElement.focus()
    }
  }
}

/**
 * Trigger a function when Ctrl/⌘ + S keyboard event occur
 * @param e keyboard event
 * @param fn function to trigger
 * @param setComputationInProgress state modification to apply before and after the given function
 */
export const saveShortcutListener = async (
  e: KeyboardEvent,
  fn: () => Promise<any>,
  setComputationInProgress: Dispatch<SetStateAction<boolean>>
) => {
  if ((e.ctrlKey || e.metaKey) && e.key === 's') {
    e.preventDefault()
    await withFieldsGuard(fn, setComputationInProgress)
  }
}

export const truncateString = (str: string, lengthLimit: number) =>
  str.length <= lengthLimit ? str : `${str.slice(0, lengthLimit)}...`
