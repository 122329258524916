import React, { FC } from 'react'
import { ItemListRenderer, ItemRenderer, Select } from '@blueprintjs/select'
import { Button, Menu, MenuItem } from '@blueprintjs/core'
import styled from 'styled-components'

export interface SelectItem {
  id: string
  text: string
}

interface AppMetierSelectProps {
  value?: SelectItem
  onClick?: (item: SelectItem) => any
  onChange: (item: SelectItem) => any
  items: SelectItem[]
  disabled?: boolean
}

const CustomSelectButton = styled(Button)`
  min-width: 180px;
  justify-content: space-between;
  background-image: none !important;
  background-color: white !important;
  box-shadow: none !important;
  border: var(--main-grey-2-lighter) 1px solid;

  :focus {
    outline: none;
  }
`

export const AppMetierSelect: FC<AppMetierSelectProps> = ({
  value,
  items,
  onClick,
  onChange,
  disabled = false,
}) => {
  const StringSelect = Select.ofType<SelectItem>()

  return (
    <StringSelect
      activeItem={value}
      items={items}
      itemRenderer={renderItem}
      itemListRenderer={renderListItem}
      onItemSelect={onChange}
      popoverProps={{ minimal: true }}
      filterable={false}
      disabled={disabled}
    >
      <CustomSelectButton
        id={value?.text}
        text={value?.text ?? ''}
        rightIcon={'chevron-down'}
        disabled={disabled}
        onClick={() => {
          !!onClick && value && onClick(value)
        }}
      />
    </StringSelect>
  )
}

const CustomMenuItem = styled(MenuItem)`
  color: var(--main-grey-1);

  :hover {
    background-color: var(--main-green-1) !important;
    color: white !important;
  }
`

const renderItem: ItemRenderer<SelectItem> = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <CustomMenuItem
      active={modifiers.active}
      key={item.id}
      onClick={handleClick}
      text={item.text}
    />
  )
}

const CustomMenu = styled(Menu)`
  padding: 0;

  .bp3-active {
    background-color: var(--main-green-1) !important;
    color: white !important;
  }
`

const renderListItem: ItemListRenderer<SelectItem> = ({
  items,
  itemsParentRef,
  renderItem,
  activeItem,
}) => {
  const isActive = (key?: string | number | null) => {
    try {
      return key === (activeItem as SelectItem).id
    } catch {
      return false
    }
  }
  const renderedItems = items.map(renderItem).map((item) => {
    if (item) {
      return { ...item, props: { ...item?.props, active: isActive(item?.key) } }
    }
    return item
  })
  return <CustomMenu ulRef={itemsParentRef}>{renderedItems}</CustomMenu>
}
