import React, { FC, Suspense, useMemo } from 'react'
import './config/fontAwesome'
import styled from 'styled-components'
import { Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router-dom'
import { RouteDefinition, RouteWithSubRoutes } from './RouteWithSubRoutes'
import AppContext, { createContext, useAppContext } from './config/context'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { useMySettingsLazyQuery, useProjectQuery } from './generated/graphql'
import { AppliNavBar } from './Navbar'
import { AdvisorSettings } from './Settings/AdvisorSettings'
import { useEffectOnce } from 'react-use'
import { Projects } from './Projects/Projects'
import { NotePage } from './Project/NotePage'
import { NoteId } from './Project/Financiary/NoteQueries'
import {
  FrontEvent,
  FrontEventType,
  Maybe,
  PartenaireMontage,
  Prescripteur,
  ProjectId,
  Structure,
} from '@fa-metier/types'
import { YodaSpinnerPage } from './utils/YodaSpinnerPage'
import { ApolloProvider } from '@apollo/client'
import { AppToaster } from './utils/AppToaster'
import { Intent } from '@blueprintjs/core'
import environment from './config/environment'
import { truncateString } from './utils/utils'
import { useLogFrontEvents } from './logging/LoggingQueries'

dayjs.locale('fr')

export const Container = styled.div`
  flex: 1;
  height: auto;
  min-height: 95vh;
`

interface ProjectContextI {
  projectName: string
  projectId: ProjectId
  notesQuantity: number
  structure: Structure
  partenaireMontage?: Maybe<PartenaireMontage>
  referenceDossier?: Maybe<string>
  prescripteur?: Maybe<Prescripteur>
  associationId?: Maybe<string>
  associationName?: Maybe<string>
}

export const ProjectContext = React.createContext<ProjectContextI>({} as ProjectContextI)

export const useGetProject = (projectId: string) => {
  const { data, loading, error } = useProjectQuery({ variables: { projectId } })
  return {
    loading,
    data,
    error,
  }
}

const ProjectWrapper: FC<RouteComponentProps<{ projectId: ProjectId; noteId: NoteId }>> = ({
  match,
}) => {
  const { loading, data, error } = useGetProject(match.params.projectId)

  const isDefaultNote = match.params.noteId === 'default'

  const defaultNote = useMemo(() => data?.notes?.slice()?.sort((it) => it.version)[0], [data])

  if (error) {
    AppToaster.show({
      message: `Une erreur est survenue lors du chargement du projet ${match.params.projectId}`,
      intent: Intent.DANGER,
    })
  }

  return (
    <Suspense fallback={<YodaSpinnerPage />}>
      {loading && <YodaSpinnerPage />}
      {!loading && data && defaultNote && (
        <ProjectContext.Provider
          value={{
            projectId: match.params.projectId,
            projectName: data!!.project.name,
            notesQuantity: data!!.notes.length,
            structure: data!!.project.structures[0],
            partenaireMontage: data!!.project.partenaireMontage,
            referenceDossier: data!!.project.referenceDossier,
            prescripteur: data!!.project.prescripteur,
            associationId: data!!.project.associationId,
            associationName: data!!.project.association,
          }}
        >
          <NotePage noteId={isDefaultNote ? defaultNote.id : match.params.noteId} />
        </ProjectContext.Provider>
      )}
    </Suspense>
  )
}

const routes: RouteDefinition[] = [
  {
    path: '/selector',
    Component: Projects,
    secured: true,
  },
  {
    path: '/project/:projectId/note/:noteId',
    Component: ProjectWrapper,
    secured: true,
  },
  {
    path: '/settings',
    Component: AdvisorSettings,
    secured: true,
  },
  {
    path: '/unauthorized',
    Component: () => {
      const { authStore } = useAppContext()
      return (
        <div>
          {authStore.authStatus !== 'UNAUTHORIZED' && <Redirect to={'/'} />}
          PAS LE DROIT <button onClick={() => authStore.logout()}>Logout</button>
        </div>
      )
    },
  },
]
const serviceAndStore = createContext()

export const App = () => {
  return (
    <AppContext.Provider value={serviceAndStore}>
      <ApolloProvider client={serviceAndStore.apolloClient}>
        <Router history={serviceAndStore.routingStore.history}>
          <Content />
        </Router>
      </ApolloProvider>
    </AppContext.Provider>
  )
}

const errorEvents: FrontEvent[] = []

const buildFrontEvent = (message: string): FrontEvent => ({
    eventType: FrontEventType.Error,
    datetime: dayjs().utc().format(),
    pathName: window.location.pathname,
    appVersion: environment.APP_VERSION ?? "UNDEFINED",
    message: truncateString(message, 1000),
    userAgent: navigator.userAgent,
  })

export const Content = () => {
  const { authStore } = useAppContext()
  const [loadSettings] = useMySettingsLazyQuery()
  const logFrontEvents = useLogFrontEvents()

  useEffectOnce(() => {
    authStore.onLoggedIn(() => {
      loadSettings()
    })
  })

  useEffectOnce(() => {
    const stdConsoleError: (message?: any, ...optionalParams: any[]) => void = console.error
    console.error = (msg: string, ...optionalParams: any[]) => {
      if (optionalParams.length) {
        stdConsoleError(msg, optionalParams)
      } else {
        stdConsoleError(msg)
      }
      const errorEvent = buildFrontEvent(msg + optionalParams.join(', '))
      errorEvents.push(errorEvent)
    }

    window.onerror = (
      event: Event | string,
      source?: string,
      lineno?: number,
      colno?: number,
      error?: Error
    ) => {
      const message = error?.stack ?? event.toString()

      if (message) {
        const errorEvent = buildFrontEvent(message)
        errorEvents.push(errorEvent)
      }
    }

    const interval = setInterval(() => {
      const errorEventsBatch = errorEvents.splice(0, 10)

      if (errorEventsBatch.length > 0) {
        logFrontEvents({ events: errorEventsBatch })
      }
    }, 10000)

    return () => {
      if (errorEvents.length > 0) {
        logFrontEvents({ events: errorEvents })
      }
      clearInterval(interval)
    }
  })

  return (
    <>
      <AppliNavBar />
      <div style={{ display: 'flex', height: 'auto', minHeight: '95vh', width: '100%' }}>
        <Container>
          <Switch>
            {routes.map((route) => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
            <Route render={() => <Redirect to={'/selector'} />} />
          </Switch>
        </Container>
      </div>
    </>
  )
}
