import React, { FC, useContext, useEffect, useState } from 'react'
import { NoteType, PaCategory } from '@fa-metier/types'
import { useProjectInvolvementResultQuery } from '../../generated/graphql'
import styled from 'styled-components'
import { useAppContext } from '../../config/context'
import { InvolvementChart, makeRecapData } from '../../Involvement/InvolvementRecapChart'
import PageTitle from '../../utils/PageTitle'
import AnalyzeCategoryResume, { FinanciaryResume } from './utils/AnalyzeCategoryResume'
import {
  ProjectEvaluationContext,
  useProjectEvaluationContextData,
} from '../context/ProjectEvaluationContext'
import { EntrepreneursHub } from '../team/EntrepreneurHub'
import { NoteContext } from '../NotePage'
import { useUpdateNote } from '../Financiary/NoteQueries'
import { ProjectContext } from '../../App'
import { RatingScore, ScoreButtonSet } from '../../utils/ScoreButton'
import { AppMetierSelect } from '../../utils/AppMetierSelect'
import { modelToSelectItem, withFieldsGuard } from '../../utils/utils'
import { ScoringOctroiComponent } from './ScoringOctroiComponent'
import { YodaSpinnerPage } from '../../utils/YodaSpinnerPage'
import { DEBOUNCE_CONFIG } from '../../Settings/debounceConfig'
import { Editor, makeOutputData } from '@fa-metier/components'
import { useDebounce } from 'react-use'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0.5em;

  > div {
    margin: 0.5em;
    flex: 1 1 0;
  }
`
const CardHead = styled.div`
  color: var(--main-black-2);
  margin-bottom: 25px;
`

const CardHeadTitle = styled.div`
  font-style: normal;
  font-weight: var(--bold);
  font-size: 27px;
  line-height: 32px;
  color: var(--main-green-1);
`

const Title = styled.div`
  height: 22px;
  font-style: normal;
  font-weight: var(--bold);
  font-size: 20px;
  line-height: 22px;
  color: var(--main-green-1);
`

const SubTitle = styled.div`
  font-style: normal;
  font-weight: var(--bold);
  font-size: 16px;
  line-height: 22px;
  color: var(--main-black-2);
  height: 22px;
  margin-bottom: 9px;
`

const MyCard = styled.div`
  background-color: white;
  padding: 20px;
  margin: 0;

  .revelateurLink {
    text-decoration: underline;
    text-align: center;
    color: var(--main-green-1);

    &:hover {
      cursor: pointer;
    }
  }

  .noteGlobaleButtonSet {
    button {
      color: var(--main-grey-1);
    }

    button.bp3-active {
      background-color: var(--main-green-4);
      box-shadow: none;
    }
  }

  .modelEcoSelect {
    color: var(--main-grey-1);
    width: 190px;
  }
`

const MyCardHover = styled(MyCard)`
  &:hover {
    cursor: pointer;
    background-color: var(--main-green-1);
  }
`

export const NoteTitle = styled.div`
  margin-top: 21px;
  margin-left: 19px;
  font-style: normal;
  font-weight: var(--bold);
  font-size: 16px;
  line-height: 22px;
`

export const NoteLabel = styled.div`
  margin-top: 10px;
  margin-left: 19px;
  margin-right: 19px;
  font-style: normal;
  font-weight: var(--bold);
  font-size: 64px;
  line-height: 78px;
`

export const NoteRatio = styled.div`
  margin-top: 10px;
  margin-left: 19px;
  font-style: normal;
  font-weight: var(--bold);
  font-size: 18px;
  line-height: 22px;
`

const NoteGlobale = styled.div`
  flex: 1;
  background-color: var(--main-green-1);
  color: white;
  height: 200px;

  #noteGlobaleScoreSet {
    margin-left: 19px;
  }
`

export const NoteAnalyse: FC = () => {
  const { projectId } = useContext(ProjectContext)
  const { note, baseNotePath, setUnsavedChanges } = useContext(NoteContext)
  const { data: projectContext, loading: projectContextLoading } = useProjectEvaluationContextData(
    projectId,
    note.noteId
  )

  const noteId = note.noteId
  const updateNote = useUpdateNote(noteId)

  const [comment, setComment] = useState(makeOutputData(note.comment))
  const [commentReason, setCommentReason] = useState(makeOutputData(note.commentReason))
  const [conclusion, setConclusion] = useState(makeOutputData(note.conclusion))

  const models = ['MARCHAND', 'NON_MARCHAND', 'HYBRIDE']
  const [modelEco, setModelEco] = useState<string>(note.modelEco ? note.modelEco : models[0])

  const [noteGlobale, setNoteGlobale] = useState<number | null | undefined>(note.noteGlobale)
  const [computationInProgress, setComputationInProgress] = useState(false)

  const { data: projectInvolvementData, loading: projectInvolvementLoading } =
    useProjectInvolvementResultQuery({ variables: { noteId } })

  const recap =
    projectInvolvementData &&
    projectInvolvementData.involvementCheckResults &&
    makeRecapData(projectInvolvementData.involvementCheckResults.results.categoryResults)

  const { routingStore } = useAppContext()

  useDebounce(
    async () => {
      if (
        note.comment !== comment ||
        note.conclusion !== conclusion ||
        note.modelEco !== modelEco ||
        note.noteGlobale !== noteGlobale ||
        note.commentReason !== commentReason
      ) {
        await withFieldsGuard(
          () => updateNote({ noteId, comment, conclusion, modelEco, noteGlobale, commentReason }),
          setComputationInProgress
        )
      }
      setUnsavedChanges(false)
    },
    DEBOUNCE_CONFIG.delay.updateRequest,
    [comment, conclusion, modelEco, noteGlobale, commentReason]
  )

  useEffect(() => {
    if (
      note.comment !== comment ||
      note.conclusion !== conclusion ||
      note.modelEco !== modelEco ||
      note.noteGlobale !== noteGlobale ||
      note.commentReason !== commentReason
    ) {
      setUnsavedChanges(true)
    }
  }, [comment, conclusion, modelEco, noteGlobale, commentReason, setUnsavedChanges])

  const globalScore = RatingScore.find((r) => noteGlobale !== undefined && r.score === noteGlobale)

  return (
    <>
      {projectContextLoading && projectInvolvementLoading && <YodaSpinnerPage />}
      {!projectContextLoading &&
        !projectInvolvementLoading &&
        projectContext &&
        projectInvolvementData && (
          <ProjectEvaluationContext.Provider value={projectContext}>
            <div>
              <PageTitle>Analyse</PageTitle>
              <CardContainer>
                <MyCard style={{ flex: '4 1' }}>
                  <CardHead>
                    <CardHeadTitle>{projectContext.projectName}</CardHeadTitle>
                  </CardHead>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', width: '99%', height: '75%' }}
                  >
                    <div style={{ width: '200px' }}>
                      <SubTitle>Modèle économique</SubTitle>
                      <div>
                        <AppMetierSelect
                          value={modelToSelectItem(modelEco, '-')}
                          items={models.map((v) => modelToSelectItem(v, '-'))}
                          onChange={(event) => {
                            setModelEco(event.id)
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                      <div style={{ width: '100%', marginLeft: '25px' }}>
                        <SubTitle>Description</SubTitle>
                        <Editor
                          id={'description'}
                          placeHolder={'Saisir le descriptif du projet'}
                          initialValue={comment}
                          onChange={(v) => setComment(v)}
                        />
                      </div>
                      {note.type === NoteType.Reprise && (
                        <div style={{ width: '100%', marginLeft: '25px', marginTop: '20px' }}>
                          <SubTitle>
                            Raison pour laquelle le cédant vend le fonds de commerce
                          </SubTitle>
                          <Editor
                            id={'commentReason'}
                            placeHolder={'Saisir la raison'}
                            initialValue={commentReason}
                            onChange={(v) => setCommentReason(v)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </MyCard>
                <MyCardHover>
                  <div
                    onClick={() => routingStore.history.push(`${baseNotePath}/involvement`)}
                    style={{ backgroundColor: 'white', padding: '-10px' }}
                  >
                    <SubTitle>Révélateur d'engagement</SubTitle>
                    {recap && (
                      <>
                        <InvolvementChart
                          isV2={
                            projectInvolvementData?.involvementCheckResults.results.version === 2
                          }
                          scores={recap}
                          color={'var(--main-green-4)'}
                        />
                        <div
                          className={'revelateurLink'}
                          onClick={() => routingStore.history.push(`${baseNotePath}/involvement`)}
                        >
                          <span>Voir le détail</span>
                        </div>
                      </>
                    )}
                  </div>
                </MyCardHover>
              </CardContainer>
              <CardContainer>
                <MyCard>
                  <Title>Projet</Title>
                  <CardContainer
                    style={{
                      padding: '0px',
                      marginLeft: '0px',
                      position: 'relative',
                      left: '-16px',
                    }}
                  >
                    <AnalyzeCategoryResume
                      category={PaCategory.Environment}
                      path={`${baseNotePath}/evaluation/${PaCategory.Environment}`}
                    />
                    <AnalyzeCategoryResume
                      category={PaCategory.Position}
                      path={`${baseNotePath}/evaluation/${PaCategory.Position}`}
                    />
                    <AnalyzeCategoryResume
                      category={PaCategory.Means}
                      path={`${baseNotePath}/evaluation/${PaCategory.Means}`}
                    />
                    <FinanciaryResume note={note} />
                  </CardContainer>

                  <Title style={{ marginTop: '30px' }}>Equipe</Title>
                  <SubTitle style={{ marginTop: '10px' }}>Entrepreneurs du projet</SubTitle>
                  <EntrepreneursHub />
                </MyCard>
              </CardContainer>

              <CardContainer>
                <MyCard
                  style={{ display: 'flex', margin: '0px', padding: '0px', marginLeft: '7px' }}
                >
                  <NoteGlobale>
                    <NoteTitle>Note globale (Projet + Equipe)</NoteTitle>
                    <NoteLabel className={'noteLabel'}>
                      {globalScore !== undefined ? globalScore.label : '-'}
                    </NoteLabel>
                    <div id={'noteGlobaleScoreSet'} style={{ marginTop: '10px' }}>
                      <ScoreButtonSet
                        score={
                          globalScore !== null && globalScore !== undefined
                            ? globalScore.score!!.toString()
                            : null
                        }
                        onChange={(value) => {
                          setNoteGlobale(value)
                        }}
                        className={'noteGlobaleButtonSet'}
                        computationInProgress={computationInProgress}
                      />
                    </div>
                  </NoteGlobale>

                  <ScoringOctroiComponent />

                  <div style={{ flex: '2', marginLeft: '10px' }}>
                    <div style={{ width: '98%', paddingTop: '21px', paddingBottom: '10px' }}>
                      <SubTitle>Conclusion</SubTitle>
                      <Editor
                        id={'conclusion'}
                        placeHolder={'Saisir la conclusion'}
                        initialValue={conclusion}
                        onChange={(v) => setConclusion(v)}
                      />
                    </div>
                  </div>
                </MyCard>
              </CardContainer>
            </div>
          </ProjectEvaluationContext.Provider>
        )}
    </>
  )
}
