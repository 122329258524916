import {
  NoteDocument,
  useCurrentNoteQuery,
  useGeneratePdfMutation,
  useNoteQuery,
  useQuestionsQuery,
  useUpdateNoteMutation,
} from '../../generated/graphql'
import { GeneratePdfInput, Note, NoteInput, NotePdfLink } from '@fa-metier/types'

export type NoteId = string

export const useGetNote = (noteId: string) => {
  const { data, loading } = useNoteQuery({
    variables: { noteId },
  })
  return {
    loading,
    data: data ? data.note : undefined,
  }
}

export const useUpdateNote = (noteId: string) => {
  const [noteMutation] = useUpdateNoteMutation({
    update: (cache) => {
      cache.evictQueries([
        {
          query: NoteDocument,
          variables: { noteId },
        },
      ])
    },
  })
  return (update: NoteInput) => {
    return new Promise<Note>((resolve, reject) => {
      noteMutation({
        variables: { update },
      })
        .then((response) => {
          const result = response.data && response.data.note?.update
          result ? resolve(result) : reject('Note not updated')
        })
        .catch((error) => reject(error))
    })
  }
}

export const useGetCurrentNote = (projectId: string) => {
  const { data, loading } = useCurrentNoteQuery({
    variables: { projectId },
  })
  return {
    loading,
    data: data ? data.currentNote : undefined,
  }
}

export const useNoteGeneratePdf = () => {
  const [generatePdfMutation] = useGeneratePdfMutation({})
  return (request: GeneratePdfInput) => {
    return new Promise<NotePdfLink>((resolve, reject) => {
      generatePdfMutation({
        variables: { request },
      })
        .then((response) => {
          const result =
            response.data && response.data.note?.generatePdf ? response.data.note.generatePdf : null
          result !== null ? resolve(result) : reject('Error in Pdf generation')
        })
        .catch((error) => reject(error))
    })
  }
}
