import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Button, Classes, Dialog } from '@blueprintjs/core'
import styled from 'styled-components'
import { ProjectEvaluationContext, useAnswer } from '../context/ProjectEvaluationContext'
import {
  Entrepreneur,
  Maybe,
  PaCategory,
  PaCategoryData,
  PaSubCategory,
  Porteur,
  Stylable,
} from '@fa-metier/types'
import { NewEntrepreneur } from './NewEntrepreneur'
import { DeleteEntrepreneurModal } from './DeleteEntrepreneur'
import {
  analyzeMotivationsFilled,
  financesAnalyzeFilled,
  financesEnvironmentFilled,
  houseHoldFilled,
  motivationsFilled,
  synthesisFilled,
} from './EntrepreneurService'
import './EntrepreneurHub.scss'
import { RatingScore } from '../../utils/ScoreButton'
import { PROJECT_ANALYZER_CATEGORY_CONFIG } from '@fa-metier/commons'
import { NoteContext } from '../NotePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppContext } from '../../config/context'

const CardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 1em;
  margin-left: -15px;

  > div {
    max-width: 25em;
    margin-left: 15px;
    flex: 1 1 0;
  }
`

const EntrepreneurCategory = styled.span`
  vertical-align: middle;
  margin-left: 10px;
`

export const EntrepreneursHub: FC = () => {
  const { note, baseNotePath } = useContext(NoteContext)
  const {
    analysis: { entrepreneurs },
    categories,
    porteurs,
  } = useContext(ProjectEvaluationContext)

  const categoryEntrepreneur: PaCategoryData = {
    category: PaCategory.Entrepreneur,
    label: PROJECT_ANALYZER_CATEGORY_CONFIG[PaCategory.Entrepreneur].name,
    subcategories: categories[PaCategory.Entrepreneur],
  }

  const { routingStore } = useAppContext()

  const [selected, setSelected] = useState<Maybe<Entrepreneur> | undefined>()

  useEffect(() => {
    if (selected) {
      setSelected(entrepreneurs.filter((e) => e.id === selected.id)[0])
    }
  }, [entrepreneurs, selected])

  return (
    <div>
      <NewEntrepreneur noteId={note.noteId} />

      <CardsContainer>
        {entrepreneurs.map((e) => (
          <EntrepreneurCard
            entrepreneur={e}
            porteurs={porteurs}
            key={e.id}
            categoryData={categoryEntrepreneur}
            onClick={() => {
              routingStore.history.push(`${baseNotePath}/evaluation/${PaCategory.Entrepreneur}`)
            }}
          />
        ))}
      </CardsContainer>
    </div>
  )
}

type EntrepreneurDetailProps = {
  entrepreneur: Entrepreneur
  porteurs: Porteur[]
  categoryData: PaCategoryData
  onClick: () => any
}

const EntrepreneurDetail: FC<EntrepreneurDetailProps & Stylable> = ({
  entrepreneur,
  porteurs,
  className,
  categoryData,
  onClick,
}) => {
  const porteur = porteurs.find((p) => p.muffinId === entrepreneur.idMuffin)!
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)

  return (
    <div className={className} onClick={() => onClick()} style={{ cursor: 'pointer' }}>
      <div className={'entrepreneurName'}>
        {porteur?.firstname ?? ''} {porteur?.lastname ?? ''}
      </div>
      <Synthesis className="synthesis" entrepreneur={entrepreneur} />
      <ScoreBoard
        entrepreneur={entrepreneur}
        categoryData={categoryData}
        style={{ paddingLeft: '1em' }}
      />
      <div className={'entrepreneurFooter'}>
        <div className={'entrepreneurLink'} onClick={() => onClick()}>
          <span>Voir le détail</span>
        </div>
        {porteur.porteurType === 'AUTRE' && (
          <div className="delete" style={{ marginLeft: 'auto' }}>
            <Button
              icon={'trash'}
              minimal
              large
              onClick={(e: any) => {
                e.stopPropagation()
                setDeleteOpen(true)
              }}
            />
          </div>
        )}
      </div>

      <Dialog isOpen={deleteOpen} onClose={() => setDeleteOpen(false)} className={Classes.DIALOG}>
        <DeleteEntrepreneurModal onClose={() => setDeleteOpen(false)} entrepreneur={entrepreneur} />
      </Dialog>
    </div>
  )
}

const EntrepreneurCard = styled(EntrepreneurDetail)`
  display: flex;
  background-color: var(--main-blue-2);
  flex-direction: column;
  position: relative;
  width: 268px;

  .entrepreneurName {
    font-style: normal;
    font-weight: var(--bold);
    font-size: 20px;
    line-height: 24px;
    color: var(--main-black-2);
    margin-bottom: 20px;
    padding-top: 1em;
    padding-left: 1em;
  }

  > div {
    flex: 1 1 0;
  }

  .synthesis {
    flex: 2 1 0;
  }

  .entrepreneurFooter {
    display: flex;
    padding-left: 1em;

    .entrepreneurLink {
      text-decoration: underline;
      text-align: left;
      color: var(--main-green-1);
      padding-top: 12px;
      padding-bottom: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    div:first-of-type {
      flex: 1;
    }
  }

  .entrepreneurRating {
    display: flex;
    background-color: white;
    vertical-align: super;
    height: 48px;
    margin-bottom: 10px;

    span:first-of-type {
      flex: 1;
    }

    .questionName {
      font-size: 14px;
      line-height: 22px;
      color: var(--main-grey-1);
      text-align: left;
      padding-left: 20px;
      padding-top: 12px;
    }

    .ratingValue {
      font-style: normal;
      font-weight: var(--bold);
      font-size: 30px;
      color: var(--main-black-2);
      padding-top: 3px;
      padding-right: 20px;
    }
  }

  :hover {
    background-color: var(--main-green-1);
    color: white;

    div.entrepreneurName {
      color: white;
    }

    .ratingValue {
      color: var(--main-green-1);
    }

    .entrepreneurLink {
      color: white;
    }

    svg.isCheck,
    .bp3-icon-trash {
      color: white;
    }
  }
`

const ScoreBoard: FC<{ entrepreneur: Entrepreneur; categoryData: PaCategoryData } & Stylable> = ({
  entrepreneur,
  categoryData,
  className,
  style,
}) => {
  const houseHold = useMemo(() => entrepreneur.houseHold, [entrepreneur])

  const motivationsChecked = useMemo(
    () => motivationsFilled(entrepreneur.responses, categoryData),
    [entrepreneur.responses, categoryData]
  )
  const analyzeMotivationsChecked = useMemo(
    () => analyzeMotivationsFilled(entrepreneur.responses, categoryData),
    [entrepreneur.responses, categoryData]
  )
  const houseHoldChecked = useMemo(() => houseHoldFilled(houseHold), [houseHold])
  const financesAnalyzeChecked = useMemo(
    () => financesAnalyzeFilled(entrepreneur.responses, categoryData),
    [entrepreneur.responses, categoryData]
  )
  const financesEnvironmentChecked = useMemo(
    () => financesEnvironmentFilled(entrepreneur, categoryData),
    [entrepreneur, categoryData]
  )
  const synthesisChecked = useMemo(() => synthesisFilled(entrepreneur.responses, categoryData), [
    entrepreneur.responses,
    categoryData,
  ])

  return (
    <div className={className} style={style}>
      <MyCategory check={true} label={'Infos générales'} />
      <MyCategory check={motivationsChecked} label={'Motivations'} />
      <MyCategory check={analyzeMotivationsChecked} label={'Analyses des motivations'} />
      <MyCategory check={houseHoldChecked} label={'Revenu du ménage'} />
      <MyCategory check={financesAnalyzeChecked} label={'Analyse sur les comptes'} />
      <MyCategory check={financesEnvironmentChecked} label={'Environnement financiers'} />
      <MyCategory check={synthesisChecked} label={'Avis synthétique'} />
    </div>
  )
}

const MyCategory: FC<{
  check: boolean
  label: string
}> = ({ check, label }) => {
  return (
    <div style={{ height: '30px' }}>
      <FontAwesomeIcon
        icon={['fas', check ? 'check' : 'times']}
        color={check ? 'var(--main-green-1)' : 'var(--main-grey-2)'}
        style={{
          textAlign: 'left',
          fontSize: '18px',
          verticalAlign: 'middle',
          marginRight: check ? '0' : '6px',
        }}
        className={check ? 'isCheck' : 'isNotChecked'}
      />
      <EntrepreneurCategory>{label}</EntrepreneurCategory>
    </div>
  )
}

const Synthesis: FC<
  {
    entrepreneur: Entrepreneur
  } & Stylable
> = ({ entrepreneur, className, style }) => {
  const { categories } = useContext(ProjectEvaluationContext)
  const syntheseQuestion =
    categories &&
    categories.ENTREPRENEUR.find((sc) => sc.subCategory === PaSubCategory.Synthesis)!.questions[0]
  const { answer } = useAnswer(syntheseQuestion.id, entrepreneur.id)
  let rating: Maybe<string> = null
  if (answer && answer.ratingAnswers.length) {
    rating = answer.ratingAnswers[0].score
  }
  const ratingValue = RatingScore.find(
    (r) => rating !== null && rating !== undefined && r.score === Number(rating)
  )
  return (
    <div className={'entrepreneurRating'}>
      <span className={'questionName'}>{syntheseQuestion.ratings[0].text}</span>
      <span className={'ratingValue'}>{ratingValue ? ratingValue.label : '-'}</span>
    </div>
  )
}
