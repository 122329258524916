import { NoteDictionnary } from './dictionnary'
import { CRLink, PFLink } from '../Financiary/Utils/DifferencesDeSaisie'
import React from 'react'
import { PeriodModel } from '../Financiary/PeriodModel'
import { PeriodType } from '@fa-metier/types'

export const repriseDictionnary: NoteDictionnary = {
  cr: {
    pageTitle: 'Compte de résultat',
    ongletName: 'Compte de résultat',
    linkText: 'la partie prévisionnelle du Compte de résultat',
  },
  bfr: {
    menuLabel: 'BFR',
  },
  differencesSaisies: {
    targetTitle: 'Données saisies dans le Compte de résultat',
    crExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données de <CRLink /> par celles issues de cette
        page.
      </div>
    ),
    crSuccessText: (
      <div>
        Vous pouvez consulter <CRLink /> mise à jour.
      </div>
    ),
    pfExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <PFLink /> par celles issues de cette
        page.
      </div>
    ),
    pfSuccessText: (
      <div>
        Vous pouvez consulter le <PFLink /> mis à jour.
      </div>
    ),
    masseSalarialeMoreInformation: (
      <p>
        Attention, les données de <CRLink /> ont peut-être été calculées plus précisément !.
      </p>
    ),
  },
  noteSettings: {
    tvaTabLink: (periodType) =>
      !periodType || periodType === PeriodType.Previsionnel
        ? 'settings#tabsexercices-tva'
        : 'settings#tabscedant-tvaTab',
    tabExerciceTitle: 'Prévisionnel',
    baseDateLabel: (periodType) =>
      periodType === PeriodType.Previsionnel
        ? "Date de début d'activité"
        : 'Date de clôture du dernier exercice disponible',
  },
  periodTitle: (p: PeriodModel, index: number) => {
    if (p.type === PeriodType.Previsionnel) {
      return `Exercice n° ${index + 1}`
    }
    return p.startDate.year() === p.endDate.year()
      ? `${p.startDate.year()}`
      : `${p.startDate.year()}-${p.endDate.year()}`
  },
  bilan: {
    ongletName: 'Bilan du cédant',
    pageTitle: 'Bilan du cédant',
    linkText: 'Bilan du cédant',
  },
  exerciceCedantLabel: 'Exercices du cédant',
  commentAnterieurLabel: 'Commentaire - Comptes du cédant',
  benchmarchAnterieurPeriodLabel: 'Cédant',
  prets: {
    ongletName: 'Prêts',
    pageTitle: 'Prêts',
    linkText: 'Prêts',
  },
  pretsPasse: {
    ongletName: '',
    pageTitle: '',
    linkText: '',
  },
  pretTVADemarrage: {
    label: 'Prêt CT démarrage',
    button: 'un prêt CT démarrage',
  },
  noExerciceText: "Aucune date de début d'activité n'a été renseignée",
  noExerciceLink:
    'Vous devez saisir une date de début d’activité pour pouvoir créer vos exercices comptables dans l’onglet',
  evolutionPercentagePassedLabel: "% d'évolution cédant",
}
