import React, { FC, useContext, useEffect, useState } from 'react'
import { isSynthesis, Note, PaAnswer, PaCategory, PaRating } from '@fa-metier/types'
import { PROJECT_ANALYZER_CATEGORY_CONFIG } from '@fa-metier/commons'
import styled from 'styled-components'
import { RatingScore, ScoreButtonSet } from '../../../utils/ScoreButton'
import { ProjectEvaluationContext } from '../../context/ProjectEvaluationContext'
import { Link } from 'react-router-dom'
import { useDebounce } from 'react-use'
import { useUpdateNote } from '../../Financiary/NoteQueries'
import { useAppContext } from '../../../config/context'
import { DEBOUNCE_CONFIG } from '../../../Settings/debounceConfig'
import { NoteContext } from '../../NotePage'
import { withFieldsGuard } from '../../../utils/utils'

const QuestionName = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: var(--main-grey-1);
  text-align: left;
  padding-left: 20px;
`

const ProjectCategory = styled.div`
  padding: 5px 11px;
  margin-left: 0;

  .categoryResumeHeader {
    display: flex;
    flex-direction: column;
    height: 150px;
    margin-bottom: 15px;

    .financierScoreSet {
      button {
        color: var(--main-grey-1);
      }

      button.bp3-active {
        background-color: var(--main-green-4);
        box-shadow: none;
      }
    }
  }

  .categoryResumeTitle {
    font-style: normal;
    font-weight: var(--bold);
    font-size: 16px;
    line-height: 22px;
    color: var(--main-black-2);
    height: 22px;
    margin-bottom: 9px;
  }

  .questionResult {
    display: flex;
    background-color: var(--main-blue-2);
    vertical-align: super;
    height: 48px;
    margin-bottom: 2px;

    span:first-of-type {
      flex: 1;
    }

    span:nth-child(1) {
      padding-top: 10px;
    }

    span:nth-child(2) {
      font-style: normal;
      font-weight: var(--bold);
      font-size: 30px;
      color: var(--main-black-2);
      padding-top: 5px;
      padding-right: 20px;
    }
  }

  .seeDetails {
    text-decoration-line: underline;
    text-align: right;

    > a {
      color: var(--main-green-1);
    }
  }

  :hover {
    background-color: var(--main-green-1);

    .categoryResumeTitle {
      color: white;
    }

    .questionResult {
      background-color: white;
      color: var(--main-grey-1);

      > span.ratingAnswer {
        color: var(--main-green-1);
      }
    }

    .seeDetails {
      > a {
        color: white;
      }
    }
  }
`

export const RatingAnswer: FC<{ rating: PaRating; synthesis: PaAnswer | undefined }> = ({
  rating,
  synthesis,
}) => {
  const ratingAnswer = synthesis ? synthesis.ratingAnswers.find((a) => a.rid === rating.id) : null
  const score = ratingAnswer ? ratingAnswer.score : null
  const scoreNumber = score ? parseInt(score, 10) : null
  const ratingValue = RatingScore.find((r) => r.score === scoreNumber)
  return <span className={'ratingAnswer'}>{ratingValue ? ratingValue.label : '-'}</span>
}

export const AnalyzeCategoryResume: FC<{
  category: PaCategory
  path: string
}> = ({ category, path }) => {
  const {
    analysis: { answers },
    categories,
  } = useContext(ProjectEvaluationContext)

  const { routingStore } = useAppContext()
  const questionCategories = categories[category]
  const questionSubCategories = questionCategories.find((s) => isSynthesis(s.subCategory))
  const synthesisQuestion = questionSubCategories && questionSubCategories.questions[0]

  const synthesis = synthesisQuestion && answers.find((a) => a.questionId === synthesisQuestion.id)

  return (
    <>
      <ProjectCategory>
        <div
          className={'categoryResumeHeader'}
          onClick={() => routingStore.history.push(path)}
          style={{ cursor: 'pointer' }}
        >
          <div className={'categoryResumeTitle'}>
            {PROJECT_ANALYZER_CATEGORY_CONFIG[category].name}
          </div>
          {synthesisQuestion &&
            synthesisQuestion.ratings.map((r) => (
              <div className={'questionResult'} key={'result_'.concat(r.id)}>
                <QuestionName>{r.text}</QuestionName>
                <RatingAnswer key={r.id} rating={r} synthesis={synthesis} />
              </div>
            ))}
        </div>
        <div className={'seeDetails'}>
          <Link to={path}>Voir le détail</Link>
        </div>
      </ProjectCategory>
    </>
  )
}
export default AnalyzeCategoryResume

export const FinanciaryResume: FC<{ note: Note }> = ({ note }) => {
  const noteId = note.noteId
  const updateNote = useUpdateNote(noteId)
  const { setUnsavedChanges } = useContext(NoteContext)

  const [noteFinancier, setNoteFinancier] = useState<number | null | undefined>(note.noteFinancier)
  const [computationInProgress, setComputationInProgress] = useState(false)
  const financierScore = RatingScore.find(
    (r) =>
      noteFinancier !== null && noteFinancier !== undefined && r.score === Number(noteFinancier)
  )

  useDebounce(
    async () => {
      if (noteFinancier !== note.noteFinancier) {
        await withFieldsGuard(() => updateNote({ noteId, noteFinancier }), setComputationInProgress)
      }
      setUnsavedChanges(false)
    },
    DEBOUNCE_CONFIG.delay.updateRequest,
    [noteFinancier]
  )

  useEffect(() => {
    if (noteFinancier !== note.noteFinancier) {
      setUnsavedChanges(true)
    }
  }, [noteFinancier, setUnsavedChanges])

  return (
    <ProjectCategory>
      <div className={'categoryResumeHeader'}>
        <div className={'categoryResumeTitle'}>Financier</div>

        <div className={'questionResult'}>
          <QuestionName>Volet financier</QuestionName>
          <span>{financierScore !== undefined ? financierScore.label : '-'}</span>
        </div>

        <div style={{ marginTop: '10px', paddingLeft: '30px' }}>
          <ScoreButtonSet
            score={
              financierScore !== null && financierScore !== undefined
                ? financierScore.score!!.toString()
                : null
            }
            onChange={(value) => {
              setNoteFinancier(value)
            }}
            className={'financierScoreSet'}
            computationInProgress={computationInProgress}
          />
        </div>
      </div>
    </ProjectCategory>
  )
}
