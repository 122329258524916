import { IDateFormatProps } from '@blueprintjs/datetime'
import { Dayjs } from 'dayjs'
import moment from 'moment'

export type DaysjsFormat = 'DD/MM/YYYY' | 'YYYY-MM-DD' | 'YYYY'

export const getDayjsFormatter = (format: DaysjsFormat): IDateFormatProps => {
  // note that locale argument comes from locale prop and may be undefined
  return {
    formatDate: (date) => moment(date).locale('fr').format(format),
    parseDate: (str) => {
      if (str === null || !new RegExp('^[0-9]{2}[/-][0-9]{2}[/-][0-9]{4}$').test(str)) {
        return null
      }
      return moment(str, format).locale('fr').toDate()
    },
    placeholder: format,
  }
}

export const formatDayjs = (date: Dayjs, format: DaysjsFormat = 'DD/MM/YYYY') =>
  date.locale('fr').format(format)

export const stringToDate = (str: string, format: DaysjsFormat = 'DD/MM/YYYY') => {
  return moment(str, format).locale('fr').toDate()
}
