import React, { createContext, FC, ReactNode, useContext } from 'react'
import styled from 'styled-components'
import { PlanFinancementTable } from './PlanFinancement'
import { NumberText } from '@fa-metier/components'
import {
  CalculatedData,
  PlanFinancementExerciceRatios,
  PlanFinancementRatios,
} from '@fa-metier/types'
import { Tooltip2 } from '@blueprintjs/popover2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLinkGreen } from '../CR/CRForm'
import { anneeSuffix, eurosSuffix, Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { FormulaPopoverContent } from '../Formulas/FormulaPopover'
import { FormulaDescriptionPopover } from '../Formulas/FormulaDescriptionPopover'
import { NoteContext } from '../../NotePage'
import { useGetPlanFinancementRatios } from './PlanFinancementQueries'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { PeriodModel } from '../PeriodModel'
import { useGetPeriods } from '../FinanciaryQueries'

const PlanFinancementRatiosTable = styled(PlanFinancementTable)`
  tr td:first-child {
    width: 27em;
  }

  tr th:not(:first-child) {
    width: 310px;
  }

  tr {
    height: 4em;
  }

  td,
  th {
    padding-right: 4em;
    text-align: right;
  }

  td.title {
    padding-right: 0;
    text-align: start;
  }
`

const UnitDetails = styled.span`
  font-size: 0.7em;
`

interface RatiosContextI {
  ratios: PlanFinancementRatios
  previsionelPeriods: PeriodModel[]
}

const RatiosContext = createContext<RatiosContextI>({} as RatiosContextI)

export const PlanFinancementRatiosPageWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading, data } = useGetPlanFinancementRatios(noteId)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)
  if (loading || periodLoading) {
    return <YodaSpinnerPage />
  }
  return (
    <RatiosContext.Provider
      value={{
        ratios: data!!,
        previsionelPeriods: periodsData.previsionnelles,
      }}
    >
      <PlanFinancementRatiosPage />
    </RatiosContext.Provider>
  )
}

export const PlanFinancementRatiosPage: FC = () => {
  const { noteSettings } = useContext(NoteContext)
  const { ratios: planFinancementRatios, previsionelPeriods } = useContext(RatiosContext)
  const bfrTooltipContent = (
    <span>
      Valeur issue de l'onglet{' '}
      <NavLinkGreen to={'bfr'}>
        BFR prévisionnel{' '}
        <FontAwesomeIcon icon={['fas', 'external-link-alt']} color={'var(--main-green-1)'} />{' '}
      </NavLinkGreen>
    </span>
  )
  const dictionnary = useDictionnary()

  return (
    <div style={{ marginTop: '3em' }}>
      {noteSettings.showDemarrage && (
        <PlanFinancementRatiosTable>
          <thead>
            <tr>
              <th className={'transparent title'}>Hypothèses démarrage</th>
              <th>Démarrage</th>
            </tr>
          </thead>
          <tbody>
            <RatioLineOneColumn
              label={
                <>
                  Stock de démarrage <UnitDetails>(en jours d'achats)</UnitDetails>
                </>
              }
              data={planFinancementRatios.stock}
              suffix={Suffixes.JOURS}
            />
            <RatioLineOneColumn
              label={
                <>
                  Trésorerie de démarrage <UnitDetails>(en jours de charges hors DAP)</UnitDetails>
                </>
              }
              data={planFinancementRatios.tresorerieDemarrage}
              suffix={Suffixes.JOURS}
            />
          </tbody>
        </PlanFinancementRatiosTable>
      )}
      <PlanFinancementRatiosTable>
        <thead>
          <tr className={'hoverable'}>
            <th className={'transparent title'}>Taux d'endettement en année 1</th>
            <th>{dictionnary.periodTitle(previsionelPeriods[0], 0)}</th>
          </tr>
        </thead>
        <tbody>
          <RatioLineOneColumn
            label={
              <>
                Ressources stables / total ressources <UnitDetails>(année en cours)</UnitDetails>
              </>
            }
            data={planFinancementRatios.ressourcesStablesTotalRessources}
            suffix={Suffixes.POURCENTAGE}
          />
          <RatioLineOneColumn
            label={<>Dettes MT / CA prévisionnel</>}
            data={planFinancementRatios.dettesMtCaPrevisionnel}
            suffix={Suffixes.POURCENTAGE}
          />
          <RatioLineOneColumn
            label={
              <>
                Dettes MT <UnitDetails>(en années de CAF)</UnitDetails>
              </>
            }
            data={planFinancementRatios.dettesMt}
            suffix={anneeSuffix(planFinancementRatios.dettesMt.numericData.value ?? 0)}
          />
        </tbody>
      </PlanFinancementRatiosTable>

      <PlanFinancementRatiosTable>
        <thead>
          <tr>
            <th className={'transparent title'}>Indicateurs financiers</th>
            {previsionelPeriods.map((p, index) => (
              <th key={index}>{dictionnary.periodTitle(p, index)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <RatioLine
            fieldName={'fondsPropres'}
            label={'Fonds propres après intervention'}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
          />
          <RatioLine
            fieldName={'dettesMlt'}
            label={'Dettes MLT après intervention'}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
          />
          <RatioLine
            fieldName={'dettesMltFondsPropres'}
            label={'Dettes MLT / Fonds propres'}
            suffix={Suffixes.POURCENTAGE}
          />
          <RatioLine
            fieldName={'dettesCafPrevisionnelle'}
            label={
              <>
                Dettes / CAF Prév <UnitDetails>(en nombre d'années)</UnitDetails>
              </>
            }
            suffix={Suffixes.ANS}
          />
          <RatioLine
            fieldName={'remboursementDettes'}
            label={'Remboursement dettes / CAF prévisionnelle'}
            suffix={Suffixes.POURCENTAGE}
          />
          <RatioLine
            fieldName={'tresorerieJoursChargesExploitation'}
            label={
              <>
                Trésorerie <UnitDetails>(en jours de charges d'exploitation hors DAP)</UnitDetails>
              </>
            }
            suffix={Suffixes.JOURS}
          />
        </tbody>
      </PlanFinancementRatiosTable>
      <PlanFinancementRatiosTable>
        <thead>
          <tr>
            <th className={'transparent title'}>Rappel BFR</th>
            {previsionelPeriods.map((p, index) => (
              <th key={index}>{dictionnary.periodTitle(p, index)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <RatioLine
            fieldName={'creancesClients'}
            label={'Créances clients'}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
          />
          <RatioLine
            fieldName={'creancesFinanceursPublics'}
            label={'Créances financeurs publics'}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
          />
          <RatioLine
            fieldName={'dettesFournisseurs'}
            label={'Dettes fournisseurs'}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
          />
          <RatioLine
            fieldName={'bfr'}
            label={'BFR'}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
            tooltipContent={bfrTooltipContent}
          />
          <RatioLine
            fieldName={'fondsRoulement'}
            label={'Fonds de roulement'}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
          />
          <RatioLine
            fieldName={'tresorerieNette'}
            label={'Trésorerie nette'}
            suffix={eurosSuffix(noteSettings.kiloEuros)}
          />
        </tbody>
      </PlanFinancementRatiosTable>
    </div>
  )
}

const RatioLineOneColumn: FC<{
  label: ReactNode
  data: CalculatedData
  suffix: string
}> = ({ label, data, suffix }) => {
  const value = () => {
    const baseValue = data.numericData.value ?? 0
    if (suffix === Suffixes.POURCENTAGE) {
      return baseValue * 100
    }
    return baseValue
  }
  return (
    <tr className={'hoverable'}>
      <td className={'title'}>{label}</td>
      <td>
        <FormulaDescriptionPopover
          content={<FormulaPopoverContent calculatedData={data} suffix={suffix} />}
          position={'bottom'}
        >
          <NumberText value={value()} suffix={suffix} />
        </FormulaDescriptionPopover>
      </td>
    </tr>
  )
}

const RatioLine: FC<{
  fieldName: string
  label: ReactNode
  suffix: string
  tooltipContent?: JSX.Element
}> = ({ fieldName, label, suffix, tooltipContent }) => {
  const { ratios: planFinancementRatios } = useContext(RatiosContext)

  const exerciceField = (exercice: PlanFinancementExerciceRatios) => {
    // @ts-ignore
    return exercice[fieldName]
  }

  const isCalculatedData = (exercice: PlanFinancementExerciceRatios) => {
    const field = exerciceField(exercice)
    return field.hasOwnProperty('numericData') && field.hasOwnProperty('detail')
  }

  const scaler = suffix === Suffixes.POURCENTAGE ? 100 : 1

  return (
    <tr className={'hoverable'}>
      <td className={'title'}>
        {tooltipContent && (
          <Tooltip2
            content={tooltipContent}
            position={'top-left'}
            hoverCloseDelay={500}
            interactionKind={'hover'}
          >
            {label}
          </Tooltip2>
        )}
        {!tooltipContent && label}
      </td>
      {planFinancementRatios.exercicesRatios.map((exercice, i) => (
        <td key={`${fieldName}-${i}`}>
          {isCalculatedData(exercice) && (
            <FormulaDescriptionPopover
              content={
                <FormulaPopoverContent calculatedData={exerciceField(exercice)} suffix={suffix} />
              }
              position={'bottom'}
            >
              <NumberText
                value={(exerciceField(exercice).numericData.value ?? 0) * scaler}
                suffix={suffix}
              />
            </FormulaDescriptionPopover>
          )}
          {!isCalculatedData(exercice) && (
            <NumberText value={(exerciceField(exercice).value ?? 0) * scaler} suffix={suffix} />
          )}
        </td>
      ))}
    </tr>
  )
}
