import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import {
  Guarantee,
  PlanFinancementEntryKey,
  PlanFinancementEntryType,
  Pret,
} from '@fa-metier/types'
import { FinanciaryContext } from '../Financiary'
import { BlankPlanFinancementTableLine, FlexDiv, PlanFinancementContext } from './PlanFinancement'
import styled from 'styled-components'
import { Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import { AppMetierNumberInput } from '../Utils/AppMetierInput/AppMetierNumberInput'
import { getDayjsFormatter } from '../../../utils/DayjsFormatter'
import { AppMetierDateInput } from '../Utils/AppMetierInput/AppMetierDateInput'
import dayjs from 'dayjs'
import { DISPLAY_DATE_FORMAT } from '../Prets/PretForm'
import { useDebounce } from 'react-use'
import { toPretInput, useUpdatePret, validatePret } from '../Prets/PretsQueries'
import { eurosSuffix, Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { PeriodModel } from '../PeriodModel'
import { NumberText } from '@fa-metier/components'
import { useUpdateNoteSettings } from '../../NoteSettingsQueries'
import { AppMetierCheckbox } from '../Utils/AppMetierCheckBox'
import { NoteContext } from '../../NotePage'
import { Tooltip2 } from '@blueprintjs/popover2'
import { withFieldsGuard } from '../../../utils/utils'
import _ from 'lodash'
import { useUserDebounce } from '../../../Settings/Hooks'

const FormDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

const DetailContainer = styled.div`
  background-color: var(--main-blue-3);
  padding: 0.5em;
`

const LineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--main-grey-3);
  padding: 0.5em;
`

const LabelDiv = styled.div`
  width: 24em;
`

const NumberDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 22em;

  span {
    padding-left: 12px;
  }
`

const PretDetailTd = styled.td`
  position: relative;
  z-index: 1;
  padding: 0 10px 0 20px !important;
`

const Container = styled.div`
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
`

const ClickableSpan = styled.span`
  text-decoration: underline;
  width: 100%;
  margin-right: 0.5em;
  color: var(--main-green-1);

  :hover {
    cursor: pointer;
  }

  text-align: right;
`

export const PretBlock: FC<{ prets: Pret[]; title: string; triggerCompute: number }> = ({
  prets,
  title,
  triggerCompute,
}) => {
  const { note, noteSettings } = useContext(NoteContext)
  const { previsionelPeriods } = useContext(FinanciaryContext)
  const updateSettings = useUpdateNoteSettings(note.noteId)
  const updateShowDetailPret = async (state: boolean) => {
    await updateSettings({ showDetailPret: state })
  }
  return (
    <>
      <tr>
        <td className={'title'}>{title}</td>
        {noteSettings.showDemarrage && <td className={'demarrage'} />}
        <td colSpan={previsionelPeriods.length - 1} />
        <td>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {prets.length > 0 && (
              <ClickableSpan onClick={() => updateShowDetailPret(!noteSettings.showDetailPret)}>
                {noteSettings.showDetailPret
                  ? 'Masquer le détail des prêts'
                  : 'Modifier le détail des prêts'}
              </ClickableSpan>
            )}
          </div>
        </td>
        <td className={'action'} />
      </tr>
      {prets.map((pret, index) => (
        <PretLine pret={pret} key={`pret-${index}`} triggerCompute={triggerCompute} />
      ))}
    </>
  )
}

export const PretLine: FC<{ pret: Pret; triggerCompute: number }> = ({ pret, triggerCompute }) => {
  const { noteSettings, noteId, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const { previsionelPeriods } = useContext(FinanciaryContext)
  const { showComputations, computationInProgress, setComputationInProgress } =
    useContext(PlanFinancementContext)
  const updatePret = useUpdatePret(noteId, pret.id)

  const [pretState, setPretState] = useState(pret)
  const [totalDuration, setTotalDuration] = useState(pret.duration + pret.delayed)

  const refs = useRef({
    pret,
    pretState,
    unsavedChanges,
  })

  const userDebounce = useUserDebounce()

  const valid = () => validatePret(refs.current.pretState)

  const [, cancelDebounce] = useDebounce(
    async () => {
      if (valid().all && !_.isEqual(pretState, refs.current.pret)) {
        await withFieldsGuard(() => updatePret(toPretInput(pretState)), setComputationInProgress)
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [pretState, toPretInput]
  )

  useEffect(() => {
    refs.current.pretState = pretState
    if (valid().all && !_.isEqual(pretState, refs.current.pret)) {
      setUnsavedChanges(true)
    }
  }, [pretState, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.pret, pret)) {
      refs.current.pret = pret
      setPretState(pret)
      cancelDebounce()
    }
  }, [pret, cancelDebounce])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: (force: boolean) => any = async (force: boolean) => {
    if (refs.current.unsavedChanges || force) {
      cancelDebounce()
      if (valid().all && !_.isEqual(refs.current.pretState, refs.current.pret)) {
        await updatePret(toPretInput(refs.current.pretState))
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    return () => {
      compute(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (triggerCompute) {
      compute(true)
    }
  }, [triggerCompute])

  useEffect(() => {
    setTotalDuration(pretState.inFine ? pretState.duration : pretState.duration + pretState.delayed)
  }, [pretState.inFine, pretState.delayed, pretState.duration])

  const entry = {
    entryId: pret.id,
    label: pret.title,
    type: PlanFinancementEntryType.PretsAt,
    demarrage: pret.demarragePlanFinancement,
  }

  const minDate =
    previsionelPeriods.length > 0 ? dayjs(previsionelPeriods[0].startDate).toDate() : undefined
  const maxDate =
    previsionelPeriods.length > 0
      ? dayjs(previsionelPeriods[previsionelPeriods.length - 1].endDate)
          .startOf('day')
          .toDate()
      : undefined

  const lineWidth = previsionelPeriods.length + 2 + (noteSettings.showDemarrage ? 1 : 0)

  return (
    <>
      {!noteSettings.showDetailPret && (
        <PretLineEntry
          entry={entry}
          pret={pretState}
          updatePret={setPretState}
          showIsInDemarrageOption={pret.showIsInDemarrageOption}
        />
      )}
      {noteSettings.showDetailPret && (
        <>
          <tr>
            <PretDetailTd colSpan={lineWidth} className={'pret-detail'}>
              <Container>
                <LineContainer>
                  <LabelDiv>
                    <InputGroup
                      value={pretState.title}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPretState((pret) => ({ ...pret, title: event.target.value }))
                      }}
                      disabled={computationInProgress}
                    />
                  </LabelDiv>
                  {noteSettings.showDemarrage && (
                    <NumberDiv style={{ paddingLeft: '10px' }}>
                      <NumberText
                        value={entry.demarrage.value ?? 0}
                        suffix={eurosSuffix(entry.demarrage.kiloEuros)}
                        disabled={computationInProgress}
                      />
                      {pret.showIsInDemarrageOption && (
                        <Tooltip2 content="Intégrer le capital du prêt dans la colonne démarrage">
                          <PretCheckbox
                            checked={pretState.isInDemarrage}
                            onChange={() =>
                              setPretState((pret) => ({
                                ...pret,
                                isInDemarrage: !pret.isInDemarrage,
                              }))
                            }
                            disabled={computationInProgress}
                          />
                        </Tooltip2>
                      )}
                    </NumberDiv>
                  )}
                  {previsionelPeriods.map((period, index) => (
                    <NumberDiv key={index}>
                      <PretPeriodValue period={period} pret={pret} />
                    </NumberDiv>
                  ))}
                </LineContainer>
                <DetailContainer>
                  <FormDiv>
                    <FormGroup label={'In fine'} className={'pretfield'}>
                      <AppMetierCheckbox
                        checked={pretState.inFine}
                        onChange={() => {
                          setPretState((pretState) => ({
                            ...pretState,
                            inFine: !pretState.inFine,
                            delayed: !pretState.inFine ? 0 : pretState.delayed,
                          }))
                        }}
                        disabled={computationInProgress}
                      />
                    </FormGroup>
                    <FormGroup label={'Montant initial'} className={'pretfield'}>
                      <NumericDataInput
                        numericData={pretState.capital}
                        onChange={(value) => {
                          if (value?.value) {
                            setPretState((pret) => ({ ...pret, capital: value }))
                          }
                        }}
                        showComputations={showComputations}
                        isCalculated={computationInProgress}
                        isEuro={true}
                      />
                    </FormGroup>
                    <FormGroup
                      label={'Nbre remb./an'}
                      intent={valid().yearlyPaymentNb.length === 0 ? Intent.NONE : Intent.DANGER}
                      helperText={valid().yearlyPaymentNb.join(' ')}
                      className={'pretfield'}
                    >
                      <AppMetierNumberInput
                        value={pretState.yearlyPaymentNb}
                        onChange={(valueAsNumber) => {
                          if (valueAsNumber) {
                            setPretState((pret) => ({ ...pret, yearlyPaymentNb: valueAsNumber }))
                          }
                        }}
                        defaultValue={12}
                        intent={valid().yearlyPaymentNb ? Intent.NONE : Intent.DANGER}
                        allowedValues={[0, 1, 2, 4, 12]}
                        disabled={computationInProgress}
                      />
                    </FormGroup>
                    <FormGroup
                      className={'pretfield'}
                      label={'Différé'}
                      intent={valid().delayed.length === 0 ? Intent.NONE : Intent.DANGER}
                      helperText={valid().delayed.join(' ')}
                    >
                      <AppMetierNumberInput
                        value={
                          pretState.delayed !== undefined && pretState.delayed >= 0
                            ? pretState.inFine
                              ? 0
                              : pretState.delayed
                            : 0
                        }
                        onChange={(ev) => {
                          const delayed = ev ?? 0
                          setPretState((pret) => ({ ...pret, delayed }))
                          setTotalDuration(pret.duration + delayed)
                        }}
                        suffix={Suffixes.MOIS}
                        disabled={pretState.inFine || computationInProgress}
                      />
                    </FormGroup>
                    <FormGroup label={'Date de décaissement'}>
                      <AppMetierDateInput
                        placeholder={DISPLAY_DATE_FORMAT}
                        value={pretState.startingDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(selectedDate, isUserChange) => {
                          if (isUserChange) {
                            setPretState((pret) => ({ ...pret, startingDate: selectedDate }))
                          }
                        }}
                        {...getDayjsFormatter(DISPLAY_DATE_FORMAT)}
                        disabled={computationInProgress}
                      />
                    </FormGroup>
                    <FormGroup
                      className={'pretfield'}
                      label={'Durée totale'}
                      intent={valid().duration.length === 0 ? Intent.NONE : Intent.DANGER}
                      helperText={valid().duration.join(' ')}
                    >
                      <AppMetierNumberInput
                        value={totalDuration || 0}
                        onChange={(ev) => {
                          if (ev) {
                            if (pret.duration + pret.delayed !== ev) {
                              setTotalDuration(ev)
                              setPretState((pret) => {
                                if (pret.delayed !== undefined) {
                                  return { ...pret, duration: ev - pret.delayed }
                                }
                                if (pret.duration !== undefined) {
                                  return { ...pret, delayed: ev - pret.duration }
                                }
                                return { ...pret, delayed: 0, duration: ev }
                              })
                            }
                          }
                        }}
                        suffix={Suffixes.MOIS}
                        disabled={computationInProgress}
                      />
                    </FormGroup>
                    <FormGroup label={'Taux'} className={'pretfield'}>
                      <AppMetierNumberInput
                        value={pretState.annualRate ? +(pretState.annualRate * 100).toFixed(2) : 0}
                        onChange={(value) => {
                          if (value || value === 0) {
                            setPretState((pret) => ({ ...pret, annualRate: value / 100 }))
                          }
                        }}
                        suffix={Suffixes.POURCENTAGE}
                        step={0.1}
                        disabled={computationInProgress}
                      />
                    </FormGroup>
                  </FormDiv>
                  {pretState.guarantees.map((g) => (
                    <GuaranteeForm
                      key={`guarantee_${g.id}`}
                      pretState={pretState}
                      setPretState={setPretState}
                      guarantee={g}
                      computationInProgress={computationInProgress}
                    />
                  ))}
                </DetailContainer>
              </Container>
            </PretDetailTd>
          </tr>
          <BlankPlanFinancementTableLine periodNumber={previsionelPeriods.length} />
        </>
      )}
    </>
  )
}

const LineTitle = styled.span`
  font-weight: var(--bold);
  color: var(--main-black-2);
`

const GuaranteeForm: FC<{
  pretState: Pret
  setPretState: Dispatch<SetStateAction<Pret>>
  guarantee: Guarantee
  computationInProgress?: boolean
}> = ({ pretState, setPretState, guarantee, computationInProgress = false }) => {
  const { noteSettings } = useContext(NoteContext)

  const durationIsInvalid = guarantee.duration > pretState.duration + pretState.delayed

  const updateGuarantee = (ev: number, fieldName: 'duration' | 'quotite') => {
    setPretState((pret) => ({
      ...pret,
      guarantees: pret.guarantees.map((g) =>
        g.id === guarantee.id ? { ...g, [fieldName]: ev } : g
      ),
    }))
  }
  return (
    <>
      <FormDiv>
        <LineTitle>{guarantee.title}</LineTitle>
      </FormDiv>
      <FormDiv style={{ justifyContent: 'start' }}>
        <FormGroup label={'Quotité'} className={'pretfield'}>
          <AppMetierNumberInput
            value={guarantee.quotite ? +(guarantee.quotite * 100).toFixed(2) : 0}
            onChange={(value) => {
              updateGuarantee((value ?? 0) / 100, 'quotite')
            }}
            suffix={Suffixes.POURCENTAGE}
            disabled={computationInProgress}
          />
        </FormGroup>
        <FormGroup
          label={'Montant'}
          className={'pretfield'}
          style={{ padding: '0 1em', minWidth: '16%' }}
        >
          <div style={{ paddingTop: '7px' }}>
            <NumberText
              value={guarantee.quotite * (pretState.capital.value ?? 0)}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />
          </div>
        </FormGroup>
        <FormGroup
          label={'Durée'}
          className={'pretfield'}
          intent={durationIsInvalid ? 'danger' : 'none'}
          helperText={
            durationIsInvalid
              ? 'La durée de la garantie ne peut excéder la durée du prêt'
              : undefined
          }
        >
          <AppMetierNumberInput
            value={guarantee.duration}
            onChange={(value) => {
              updateGuarantee(value ?? 0, 'duration')
            }}
            suffix={Suffixes.MOIS}
            disabled={computationInProgress}
          />
        </FormGroup>
      </FormDiv>
    </>
  )
}

const PretLineEntry: FC<{
  entry: PlanFinancementEntryKey
  pret: Pret
  updatePret: (pret: Pret) => void
  showIsInDemarrageOption: boolean
}> = ({ entry, pret, updatePret, showIsInDemarrageOption }) => {
  const { noteSettings, noteId } = useContext(NoteContext)
  const { previsionelPeriods } = useContext(FinanciaryContext)
  const updateSettings = useUpdateNoteSettings(noteId)

  const [hovered, setHovered] = useState(false)

  return (
    <tr
      className={'hoverable'}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <td>
        <InputGroup
          value={entry.label}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            updatePret({ ...pret, title: event.target.value })
          }}
        />
      </td>
      {noteSettings.showDemarrage && (
        <td className={'demarrage'}>
          <PretCellDiv>
            <NumberText
              value={entry.demarrage.value ?? 0}
              suffix={eurosSuffix(entry.demarrage.kiloEuros)}
            />
            {showIsInDemarrageOption && (
              <Tooltip2 content="Intégrer le capital du prêt dans la colonne démarrage">
                <PretCheckbox
                  checked={pret.isInDemarrage}
                  onChange={() => updatePret({ ...pret, isInDemarrage: !pret.isInDemarrage })}
                />
              </Tooltip2>
            )}
          </PretCellDiv>
        </td>
      )}
      {previsionelPeriods.map((period) => (
        <td key={`${entry.entryId}-${period.id}`}>
          <PretPeriodValue period={period} pret={pret} />
        </td>
      ))}
      <td className={'action'}>
        {hovered && (
          <Button
            icon={'edit'}
            minimal
            large={false}
            onClick={() => updateSettings({ showDetailPret: !noteSettings.showDetailPret })}
          />
        )}
      </td>
    </tr>
  )
}

const PretPeriodValue: FC<{ period: PeriodModel; pret: Pret }> = ({ period, pret }) => {
  const data = period.planFinancement.prets.find((p) => p.entryId === pret.id)?.data
  return (
    <FlexDiv>
      <NumberText value={data?.value ?? 0} suffix={eurosSuffix(data?.kiloEuros ?? false)} />
    </FlexDiv>
  )
}

const PretCellDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    padding-left: 12px;
  }
`

const PretCheckbox = styled(AppMetierCheckbox)`
  .bp3-control-indicator {
    padding-left: 0;
  }

  margin-bottom: 0;
  padding-left: 0;
`
