import React from 'react'
import { PeriodModel } from '../PeriodModel'

export interface UpdatePeriodForPath {
  periodId: string
  fieldPath: string
  change: any
}

export type AmountReference = 'NONE' | 'CHIFFRE_AFFAIRE' | 'PRODUITS_EXPLOITATION'

export const PeriodsContext = React.createContext<{
  periods: PeriodModel[]
  anterieurPeriods: PeriodModel[]
  updatePeriods: (request: UpdatePeriodForPath[]) => any
  updateAnterieurPeriods?: (request: UpdatePeriodForPath[]) => any
  showComputations: boolean
  showAnterieurs?: boolean
  showEvolutionColumn?: boolean
  reference?: AmountReference
  upToDate?: boolean
  hidePercentages?: boolean
  situationIntermediaire?: PeriodModel
  updateSituationIntermediaire?: (request: UpdatePeriodForPath[]) => any
  resetDebounceTimers?: () => void
}>({
  periods: [],
  anterieurPeriods: [],
  updatePeriods: () => {},
  updateAnterieurPeriods: () => {},
  showComputations: false,
  showAnterieurs: true,
  showEvolutionColumn: false,
  reference: 'NONE',
  upToDate: true,
  hidePercentages: true,
  situationIntermediaire: undefined,
  updateSituationIntermediaire: undefined,
})
