import React, { CSSProperties, FC, useState } from 'react'
import { Maybe, Stylable } from '@fa-metier/types'
import { Button, ButtonGroup } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'

export const RatingScore: { score: number | null; label: string }[] = [
  { score: 0, label: '-' },
  { score: 5, label: 'A' },
  { score: 4, label: 'B' },
  { score: 3, label: 'C' },
  { score: 2, label: 'D' },
  { score: 1, label: 'E' },
]

export const ScoreButton: FC<{
  currentScore: Maybe<number>
  score: Maybe<number>
  onClick: (s: Maybe<number>) => any
  label?: Maybe<string>
  style?: CSSProperties
  styleActive?: CSSProperties
  disabled?: boolean
  tooltipMessageV2?: boolean
}> = ({
  score,
  onClick,
  label,
  currentScore,
  style,
  styleActive,
  disabled = false,
  tooltipMessageV2,
}) =>
  tooltipMessageV2 && label ? (
    <Tooltip2 content={tooltipData.find((t) => t.key === label)?.content}>
      <Button
        active={score === currentScore}
        intent={score === currentScore ? 'primary' : 'none'}
        onClick={() => (score !== currentScore ? onClick(score) : {})}
        style={score === currentScore ? styleActive : style}
        disabled={disabled}
      >
        {label || score}
      </Button>
    </Tooltip2>
  ) : (
    <Button
      active={score === currentScore}
      intent={score === currentScore ? 'primary' : 'none'}
      onClick={() => (score !== currentScore ? onClick(score) : {})}
      style={score === currentScore ? styleActive : style}
      disabled={disabled}
    >
      {label || score}
    </Button>
  )

export const ScoreButtonForecastImpact: FC<{
  currentScore: Maybe<string>
  score: Maybe<string>
  onClick: (s: Maybe<string>) => any
  label?: Maybe<string>
  style?: CSSProperties
  styleActive?: CSSProperties
  disabled?: boolean
  tooltipMessageV2?: boolean
}> = ({
  score,
  onClick,
  label,
  currentScore,
  style,
  styleActive,
  disabled = false,
  tooltipMessageV2,
}) =>
  tooltipMessageV2 && label ? (
    <Tooltip2 content={tooltipData.find((t) => t.key === label)?.content}>
      <Button
        active={score === currentScore}
        intent={score === currentScore ? 'primary' : 'none'}
        onClick={() => (score !== currentScore ? onClick(score) : {})}
        style={score === currentScore ? styleActive : style}
        disabled={disabled}
      >
        {label || score}
      </Button>
    </Tooltip2>
  ) : (
    <Button
      active={score === currentScore}
      intent={score === currentScore ? 'primary' : 'none'}
      onClick={() => (score !== currentScore ? onClick(score) : {})}
      style={score === currentScore ? styleActive : style}
      disabled={disabled}
    >
      {label || score}
    </Button>
  )

export const ScoreButtonSet: FC<
  {
    text?: Maybe<string>
    score: Maybe<string>
    onChange: (s: Maybe<number>) => any
    computationInProgress?: boolean
  } & Stylable
> = ({ text, score, onChange, style, className, computationInProgress = false }) => {
  const [internalScore, setInternalScore] = useState<Maybe<number>>(
    score ? parseInt(score, 10) : null
  )

  const updateScore = (s: number | null) => {
    setInternalScore(s)
    onChange(s)
  }

  return (
    <div style={style} className={className}>
      <span>{text}</span>
      <ButtonGroup>
        {RatingScore.map(({ score, label }) => (
          <ScoreButton
            key={label}
            currentScore={internalScore}
            score={score}
            onClick={() => updateScore(score)}
            label={label}
            styleActive={{ backgroundColor: 'var(--main-green-4)' }}
            style={{ backgroundColor: 'white' }}
            disabled={computationInProgress}
          />
        ))}
      </ButtonGroup>
    </div>
  )
}

export const tooltipData: { key: string; content: string }[] = [
  { key: '0', content: 'Impact négatif significatif sur le critère' },
  {
    key: '1',
    content: 'Impact inexistant ou faible : rien n’est fait ou quelques actions non structurées',
  },
  { key: '2', content: 'Impact moyen : actions structurées ou plan d’actions formalisé' },
  { key: '3', content: 'Impact fort : l’engagement est au cœur de la finalité de l’entreprise' },
  { key: '=', content: "l’intervention maintient l'impact existant sur le critère" },
  { key: '+', content: "l’intervention renforce l'impact sur le critère" },
  { key: ' ', content: 'l’intervention n’a pas d’impact sur le critère' },
]
