import {
  FinancialPeriodsDocument,
  PlanFinancementDocument,
  RatiosDocument,
  ScoringCreationCheckDocument,
  useAddPlanFinancementEntryMutation,
  usePlanFinancementQuery,
  useRatiosQuery,
  useRemovePlanFinancementEntryMutation,
  useUpdatePlanFinancementMutation,
} from '../../../generated/graphql'
import {
  PlanFinancement,
  PlanFinancementEntryType,
  PlanFinancementFieldsFragment,
  PlanFinancementInput,
  PlanFinancementQuery,
} from '@fa-metier/types'
import { removeTypeName } from '@fa-metier/commons'
import produce from 'immer'
import { ApolloCache } from '@apollo/client'

const planFinancementToInput = (planFinancement: PlanFinancement) => {
  return removeTypeName({
    noteId: planFinancement.noteId,
    comment: planFinancement.comment,
    commentPrixFondsCommerce: planFinancement.commentPrixFondsCommerce,
    investissementsCorporels: planFinancement.investissementsCorporels,
    investissementsIncorporels: planFinancement.investissementsIncorporels,
    investissementsFinanciers: planFinancement.investissementsFinanciers,
    tvaSurInvestissements: planFinancement.tvaSurInvestissements,
    tvaSurStock: planFinancement.tvaSurStock,
    stockDepart: planFinancement.stockDepart,
    tresorerieDemarrage: planFinancement.tresorerieDemarrage,
    editableEntries: planFinancement.editableEntries,
  }) as PlanFinancementInput
}

export const useGetPlanFinancement = (noteId: string) => {
  const { data, loading } = usePlanFinancementQuery({ variables: { noteId } })
  return {
    loading,
    data: data ? data.planFinancement : undefined,
  }
}

export const useGetPlanFinancementRatios = (noteId: string) => {
  const { data, loading } = useRatiosQuery({ variables: { noteId } })
  return {
    loading,
    data: data?.ratios,
  }
}

export const useAddPlanFinancementEntry = (noteId: string) => {
  const [mutation] = useAddPlanFinancementEntryMutation({
    update: (cache, mutationResult) => {
      updatePlanFinancementCache(cache, noteId, mutationResult.data?.planFinancement?.addNewEntry)
      cache.evictQueries([
        { query: RatiosDocument, variables: { noteId } },
        { query: ScoringCreationCheckDocument, variables: { noteId } },
      ])
    },
  })
  return (entryType: PlanFinancementEntryType) => {
    return new Promise<PlanFinancement>((resolve, reject) => {
      mutation({
        variables: { noteId, entryType },
      })
        .then((response) => {
          const result = response.data?.planFinancement?.addNewEntry
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const useRemovePlanFinancementEntry = (noteId: string) => {
  const [mutation] = useRemovePlanFinancementEntryMutation({
    update: (cache, mutationResult) => {
      updatePlanFinancementCache(cache, noteId, mutationResult.data?.planFinancement?.deleteEntry)
      cache.evictQueries([
        {
          query: FinancialPeriodsDocument,
          variables: { noteId },
        },
        { query: RatiosDocument, variables: { noteId } },
        { query: ScoringCreationCheckDocument, variables: { noteId } },
      ])
    },
  })
  return (entryId: string) => {
    return new Promise<PlanFinancement>((resolve, reject) => {
      mutation({
        variables: { noteId, entryId },
      })
        .then((response) => {
          const result = response.data?.planFinancement?.deleteEntry
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

const updatePlanFinancementCache = (
  cache: ApolloCache<any>,
  noteId: string,
  mutationResult: ({ __typename?: 'PlanFinancement' } & PlanFinancementFieldsFragment) | undefined
) => {
  if (mutationResult) {
    const query = cache.readQuery<PlanFinancementQuery>({
      query: PlanFinancementDocument,
      variables: { noteId },
    })!
    cache.writeQuery<PlanFinancementQuery>({
      query: PlanFinancementDocument,
      variables: { noteId },
      data: query
        ? produce(query, (draft) => {
            draft.planFinancement = mutationResult
          })
        : {
            planFinancement: mutationResult,
          },
    })
  }
}

export const useUpdatePlanFinancement = (noteId: string) => {
  const [mutation] = useUpdatePlanFinancementMutation({
    update: (cache, mutationResult) => {
      updatePlanFinancementCache(cache, noteId, mutationResult.data?.planFinancement?.patch)
      cache.evictQueries([
        { query: RatiosDocument, variables: { noteId } },
        { query: ScoringCreationCheckDocument, variables: { noteId } },
        { query: FinancialPeriodsDocument, variables: { noteId } },
      ])
    },
    refetchQueries: [
      { query: RatiosDocument, variables: { noteId } },
      { query: ScoringCreationCheckDocument, variables: { noteId } },
      { query: FinancialPeriodsDocument, variables: { noteId } },
    ],
    awaitRefetchQueries: true,
  })
  return (update: PlanFinancement) => {
    return new Promise<PlanFinancement>((resolve, reject) => {
      mutation({
        variables: { update: planFinancementToInput(update) },
      })
        .then((response) => {
          const result = response.data?.planFinancement?.patch
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}
