import React, { FC, useEffect, useState } from 'react'
import { SolidButton } from '@fa-metier/components/dist/Buttons/Buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover2 } from '@blueprintjs/popover2'
import { PopoverInteractionKind } from '@blueprintjs/core/lib/esm/components/popover/popover'
import PopoverContent from './components/PopoverContent/PopoverContent'
import { ButtonSubTitle, ButtonTextBlock, ButtonTitle, ConditionUtilisationBlock } from './Styled'
import {
  useCheckConditionsUtilisationMutation,
  useConditionsUtilisationAndComiteSimplifieQuery,
} from '../generated/graphql'
import debounce from 'leaflet-geosearch/lib/lib/debounce'
import { Intent } from '@blueprintjs/core'
import { AppToaster } from '../utils/AppToaster'
import { FORM } from '@blueprintjs/icons/lib/esm/generated/iconNames'
import { ApolloError } from '@apollo/client'
import ToastErrorMessage from './components/ToastErrorMessage'

const plural = (word: string, count: number = 0) => `${word}${count > 1 ? 's' : ''}`

const SUBTITLE_DEFAULT_MAX_HEIGHT = '0px'
const BUTTON_DEFAULT_MAX_HEIGHT = '37px'
const MAX_HEIGHT_200 = '200px'

const NoteAdditionalInfo: FC<{ noteId: string; withComiteSimplifie: boolean }> = ({
  noteId,
  withComiteSimplifie,
}) => {
  // Queries
  const [checkCU, { loading: isCUCheckLoading }] = useCheckConditionsUtilisationMutation()
  const {
    data: cuData,
    loading: isCULoading,
    refetch,
  } = useConditionsUtilisationAndComiteSimplifieQuery({
    variables: { noteId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  })

  // States
  const [subtitleMaxHeight, setSubtitleMaxHeight] = useState(SUBTITLE_DEFAULT_MAX_HEIGHT)
  const [btnMaxHeight, setBtnMaxHeight] = useState(BUTTON_DEFAULT_MAX_HEIGHT)

  // Utils
  const isConforme = () => cuData?.conditionsUtilisation.isConforme
  const isChecked = () => cuData?.conditionsUtilisation.isChecked
  const isNotVerifiedYet = () => cuData?.conditionsUtilisation.lastCheckDate == null
  const termsCount = () => cuData?.conditionsUtilisation.termsNotMet?.length ?? 0
  const showPopupWhenComiteSimplifieOrWhenWrongCu = () =>
    withComiteSimplifie || (isChecked() && !isConforme())

  const subTitle = () => {
    const count = termsCount()
    return `${count} ${plural('produit', count)} non ${plural('conforme', count)}`
  }

  // Event handlers
  const onBtnClick = () => {
    checkCU({ variables: { noteId } })
      .then(() => refetch({ noteId }))
      .catch((err) => {
        const error = err as ApolloError
        AppToaster.show({
          message: (
            <ToastErrorMessage
              message={error.message}
              code={error.graphQLErrors[0]?.extensions?.code ?? 'ERROR'}
            />
          ),
          intent: Intent.DANGER,
          icon: FORM,
        })
      })
  }

  useEffect(() => {
    if (!isNotVerifiedYet()) {
      debounce(() => setSubtitleMaxHeight(MAX_HEIGHT_200), 500)()
      debounce(() => setBtnMaxHeight(MAX_HEIGHT_200), 500)()
    } else {
      debounce(() => setBtnMaxHeight(BUTTON_DEFAULT_MAX_HEIGHT), 500)()
    }
    return
  }, [cuData])

  return (
    <ConditionUtilisationBlock>
      <Popover2
        content={
          <>
            {!isNotVerifiedYet() && !isCULoading && showPopupWhenComiteSimplifieOrWhenWrongCu() && (
              <PopoverContent
                termOfUse={cuData!!.conditionsUtilisation}
                showComiteSimplifie={withComiteSimplifie}
                comiteSimplifie={cuData!!.comiteSimplifie}
              />
            )}
          </>
        }
        interactionKind={PopoverInteractionKind.HOVER}
        position={'bottom'}
        minimal={true}
      >
        <SolidButton onClick={onBtnClick} style={{ maxHeight: btnMaxHeight }}>
          <FontAwesomeIcon
            icon={'rotate-right'}
            fontSize={'16'}
            className={isCULoading || isCUCheckLoading ? 'rotating' : ''}
          />
          <ButtonTextBlock>
            <ButtonTitle>Vérifier mes produits</ButtonTitle>
            {!isNotVerifiedYet() && (
              <ButtonSubTitle
                style={{ maxHeight: subtitleMaxHeight }}
                className={isChecked() && !isConforme() ? 'warning' : ''}
              >
                {getButtonSubtitle(isConforme(), isChecked(), subTitle)}
              </ButtonSubTitle>
            )}
          </ButtonTextBlock>
        </SolidButton>
      </Popover2>
    </ConditionUtilisationBlock>
  )
}

const getButtonSubtitle = (
  isConform: boolean | null | undefined,
  isChecked: boolean | null | undefined,
  subTitle: () => string
) => {
  if (!isChecked) {
    return (
      <>
        <FontAwesomeIcon icon={'info-circle'} fontSize={'10'} /> Aucun produit concerné
      </>
    )
  }
  if (isConform) {
    return (
      <>
        <FontAwesomeIcon icon={'check-circle'} fontSize={'10'} /> Produits conformes
      </>
    )
  }
  return (
    <>
      <FontAwesomeIcon icon={'exclamation-triangle'} fontSize={'10'} /> {subTitle()}
    </>
  )
}

export default NoteAdditionalInfo
