import React from 'react'

type ErrorCode = 'Not Found' | 'Conflict' | string

const DEFAULT_ERROR_MESSAGE = 'Une erreur est survenue, contactez le support technique'
const isKnownError = (code: ErrorCode): boolean => code === 'Not Found'

const ToastErrorMessage = ({ message, code }: { message: string; code: ErrorCode }) => {
  return (
    <>
      <b>Conditions d'utilisation</b>
      <br />
      {isKnownError(code) ? message : DEFAULT_ERROR_MESSAGE}
    </>
  )
}

export default ToastErrorMessage
