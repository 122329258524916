import React, { FC, ReactNode, useContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import { NumberText } from '@fa-metier/components'
import { AppMetierTable, BlankTableLine } from '@fa-metier/components/dist/Table/AppMetierTable'
import { NavLinkGreen } from '../CR/CRForm'
import { NoteContext } from '../../NotePage'
import { Button, Classes, Dialog } from '@blueprintjs/core'
import { Intent } from '@blueprintjs/core/lib/esm/common/intent'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { FinanciaryContext } from '../Financiary'
import { useDictionnary } from '../../dictionnary/dictionnary'

const Container = styled.div`
  margin-top: 1em;
`
const NumberTextDiff = styled(NumberText)`
  display: grid;
`

const TitleRubrique = styled.div`
  font-size: 20px;
  font-weight: var(--bold);
  line-height: 22px;
  border: none;
  background-color: var(--main-gray-3);
  padding: 10px 10px 20px 0;
  color: var(--main-black-1);
`

const Text = styled.div`
  white-space: pre-wrap;
  margin-left: 15px;
  margin-right: auto;
  font-size: 13px;
  color: var(--main-black-2);
  line-height: 26px;
`

const TextExercice = styled(Text)`
  white-space: nowrap;
  padding: 0;
`
const TextExerciceRight = styled(TextExercice)`
  text-align: end;
`

const DiffAppMetierTable = styled(AppMetierTable)`
  max-width: 1280px;
  width: 1280px;

  tr th:not(:first-child):not(:last-child) {
    min-width: unset;
    max-width: unset;
    width: 50px;
  }

  thead {
    tr th:first-child {
      width: 100px;
      flex-grow: 2;
    }

    tr:last-child {
      border-bottom: none;
    }

    th {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      color: var(--main-black-2);
      text-align: left;
      padding-top: 25px;
      width: 50px;
      flex-grow: 1;
    }
  }

  tr {
    display: flex;
  }

  td {
    box-shadow: none !important;
    width: 50px;
    flex-grow: 1;
  }

  tr.hoverable {
    td:first-child {
      border-left: var(--main-blue-2) solid 3px;
      padding-left: 0;
      position: relative;
      left: 18px;
      width: 100px;
      flex-grow: 2;
    }
  }

  tr.subtotal {
    background: linear-gradient(var(--main-blue-2), var(--main-blue-2)) no-repeat 17px 0;
    font-weight: var(--bold);

    td:first-child {
      width: 100px;
      flex-grow: 2;
    }
  }
`

const ExerciceTitle = styled.th``

interface IntermediaryTableData {
  title: string
  sourceDataTitle?: string
  sourceValues: number[]
  targetTitle: string
  targetValues: number[]
  textNotes?: string
  targetValueDemarrage?: number
  sourceValueDemarrage?: number
}

interface DifferenceSaisiesProps {
  updateFunction: () => Promise<any>
  intermediaryTableData: IntermediaryTableData[]
  explanation: ReactNode
  buttonText: string
  dialogBody: ReactNode | string
  successText: ReactNode
  asterisk?: string
  hideDemarrage?: boolean
  title?: string
}

export const CRLink: FC = () => {
  const {
    cr: { linkText },
  } = useDictionnary()
  return <NavLinkGreen to={'cr'}>{linkText}</NavLinkGreen>
}

export const PFLink = () => <NavLinkGreen to={'planFinancement'}>Plan de financement</NavLinkGreen>

export const DifferenceSaisies: FC<DifferenceSaisiesProps> = ({
  updateFunction,
  intermediaryTableData,
  explanation,
  buttonText,
  dialogBody,
  successText,
  asterisk,
  hideDemarrage = false,
  title = 'Différences de saisies',
}) => {
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

  const udpate = () => {
    updateFunction().then(() => {
      setOpenUpdateDialog(false)
      setOpenConfirmationDialog(true)
    })
  }

  return (
    <Container>
      <TitleRubrique>{title}</TitleRubrique>
      <div style={{ width: 'fit-content', backgroundColor: 'white', paddingLeft: '10px' }}>
        <div>
          {intermediaryTableData.map((data, index) => (
            <div key={index}>
              <Diff data={data} hideDemarrage={hideDemarrage} />
            </div>
          ))}
          {asterisk && (
            <div>
              <Text style={{ textAlign: 'end', marginTop: '-20px', marginRight: '10px' }}>
                {asterisk}
              </Text>
            </div>
          )}
        </div>

        <div
          style={{
            fontSize: '16px',
            paddingLeft: '30px',
            paddingBottom: '30px',
            lineHeight: '24px',
          }}
        >
          <div>
            <div style={{ fontWeight: 600, color: 'var(--main-black-2)' }}>Bon à savoir :</div>
            <TextExercice style={{ fontSize: '14px', marginLeft: '0' }}>{explanation}</TextExercice>
          </div>
          <Button
            style={{ margin: '10px', marginLeft: '0' }}
            onClick={() => setOpenUpdateDialog(true)}
          >
            {buttonText}
          </Button>
          <UpdateDialog
            isOpen={openUpdateDialog}
            cancelClick={() => setOpenUpdateDialog(false)}
            dialogBody={dialogBody}
            updateFunction={udpate}
          />
          <ConfirmationDialog
            isOpen={openConfirmationDialog}
            onClose={() => setOpenConfirmationDialog(false)}
            successText={successText}
          />
        </div>
      </div>
    </Container>
  )
}

const UpdateDialog: FC<{
  isOpen: boolean
  dialogBody: ReactNode
  cancelClick: () => void
  updateFunction: () => void
}> = ({ isOpen, dialogBody, cancelClick, updateFunction }) => {
  return (
    <Dialog
      style={{ width: 'fit-content' }}
      isOpen={isOpen}
      title={'Attention'}
      isCloseButtonShown={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <Text>{dialogBody}</Text>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER}>
          <Button style={{ marginRight: '0.5em' }} onClick={cancelClick}>
            Annuler
          </Button>
          <Button intent={Intent.PRIMARY} onClick={updateFunction}>
            Confirmer
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

const ConfirmationDialog: FC<{ isOpen: boolean; onClose: () => void; successText: ReactNode }> = ({
  isOpen,
  onClose,
  successText,
}) => {
  return (
    <Dialog isOpen={isOpen} title={''} onClose={onClose}>
      <div className={Classes.DIALOG_BODY}>
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text>Les données ont bien été remplacées.</Text>
          <Text>{successText}</Text>
        </div>
      </div>
    </Dialog>
  )
}

const Diff: FC<{
  data: IntermediaryTableData
  hideDemarrage: boolean
}> = ({ data, hideDemarrage }) => {
  const { noteSettings } = useContext(NoteContext)
  const { previsionelPeriods } = useContext(FinanciaryContext)

  const onlyDemarrage = data.sourceValues.length === 0 && data.targetValues.length === 0

  const showDemarrage = useMemo(
    () => noteSettings.showDemarrage && !hideDemarrage,
    [noteSettings, hideDemarrage]
  )

  return (
    <DiffAppMetierTable>
      <thead>
        <tr>
          <th style={{ paddingLeft: '15px' }}>{data.title}</th>
          <LineExercice onlyDemarrage={onlyDemarrage} showDemarrage={showDemarrage} />
        </tr>
      </thead>
      <tbody>
        <BlankTableLine cellNumber={previsionelPeriods.length + 1} />
        <tr className={'hoverable'}>
          <td>
            <Text>
              {data.sourceDataTitle ? data.sourceDataTitle : 'Données saisies dans cette page'}
            </Text>
          </td>
          {showDemarrage && (
            <td>
              <NumberTextDiff
                style={{
                  margin: '0 2px 0 2px',
                  textAlign: 'end',
                }}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                value={data.sourceValueDemarrage ?? 0}
              />
            </td>
          )}
          {!onlyDemarrage &&
            data.sourceValues.map((v, i) => (
              <td key={`source_${i}`}>
                <NumberTextDiff
                  style={{
                    margin: '0 2px 0 2px',
                    textAlign: 'end',
                  }}
                  suffix={eurosSuffix(noteSettings.kiloEuros)}
                  value={v}
                />
              </td>
            ))}
          {onlyDemarrage && previsionelPeriods.map((v, i) => <td key={`source_${i}`} />)}
          <td />
        </tr>
        <tr className={'hoverable'}>
          <td>
            <Text>{data.targetTitle}</Text>
          </td>
          {showDemarrage && (
            <td>
              <NumberTextDiff
                style={{
                  margin: '0 2px 0 2px',
                  textAlign: 'end',
                }}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                value={data.targetValueDemarrage ?? 0}
              />
            </td>
          )}
          {!onlyDemarrage &&
            data.targetValues.map((v, i) => (
              <td key={`target_${i}`}>
                <NumberTextDiff
                  style={{
                    margin: '0 2px 0 2px',
                    textAlign: 'end',
                  }}
                  suffix={eurosSuffix(noteSettings.kiloEuros)}
                  value={v}
                />
              </td>
            ))}
          {onlyDemarrage && previsionelPeriods.map((v, i) => <td key={`target_${i}`} />)}
          <td />
        </tr>
        <tr className={'subtotal'}>
          <td>
            <Text>Différences de saisies</Text>
          </td>
          {showDemarrage && (
            <td>
              <NumberTextDiff
                style={{
                  color:
                    Math.round(data.targetValueDemarrage!! - data.sourceValueDemarrage!!) !== 0
                      ? 'var(--main-red)'
                      : '',
                  margin: '0 2px 0 2px',
                  textAlign: 'end',
                }}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                value={(data.targetValueDemarrage!! - data.sourceValueDemarrage!!).toFixed(2)}
              />
            </td>
          )}
          {!onlyDemarrage &&
            data.targetValues.map((targetValue, i) => (
              <td key={`diff_${i}`}>
                <NumberTextDiff
                  style={{
                    color:
                      Math.round(targetValue - data.sourceValues[i]) !== 0 ? 'var(--main-red)' : '',
                    margin: '0 2px 0 2px',
                    textAlign: 'end',
                  }}
                  suffix={eurosSuffix(noteSettings.kiloEuros)}
                  value={(targetValue - data.sourceValues[i]).toFixed(2)}
                />
              </td>
            ))}
          {onlyDemarrage && previsionelPeriods.map((targetValue, i) => <td key={`diff_${i}`} />)}
          <td />
        </tr>
        <BlankTableLine cellNumber={previsionelPeriods.length + 1} />
      </tbody>
    </DiffAppMetierTable>
  )
}

const LineExercice: FC<{ onlyDemarrage: boolean; showDemarrage: boolean }> = ({
  onlyDemarrage,
  showDemarrage,
}) => {
  const { previsionelPeriods } = useContext(FinanciaryContext)
  const dictionnary = useDictionnary()
  return (
    <>
      {showDemarrage && (
        <ExerciceTitle>
          <TextExerciceRight>Démarrage</TextExerciceRight>
        </ExerciceTitle>
      )}
      {previsionelPeriods &&
        !onlyDemarrage &&
        previsionelPeriods.map((p, i) => (
          <ExerciceTitle key={i}>
            <TextExerciceRight>{dictionnary.periodTitle(p, i)}</TextExerciceRight>
          </ExerciceTitle>
        ))}
      {previsionelPeriods &&
        onlyDemarrage &&
        previsionelPeriods.map((value, i) => <ExerciceTitle key={i} />)}
      <th />
    </>
  )
}
