import styled from 'styled-components'

export const ConditionUtilisationBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  margin-right: 40px;
  * {
    box-sizing: border-box;
  }
`

export const ButtonTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`

export const ButtonTitle = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
`

export const ButtonSubTitle = styled.div`
  overflow: hidden;
  font-size: 10px;
  line-height: 10px;
  font-weight: 400;

  transition: max-height 0.5s ease-in-out 0.5s;

  &.warning {
    color: var(--main-yellow-1);
  }
`
