import React, { FC, Suspense, useContext, useState } from 'react'
import { Redirect, Route, RouteComponentProps, Switch, useRouteMatch } from 'react-router'
import { NoteType, PaCategory } from '@fa-metier/types'
import { NoteAnalyse } from './ProjectHub/Analyse'
import { RouteWithSubRoutes } from '../RouteWithSubRoutes'
import { BreadcrumbRenderer } from '@fa-metier/components'
import { Breadcrumbs, Button, Classes, Dialog } from '@blueprintjs/core'
import { NoteContext } from './NotePage'
import { Presentation } from './ProjectHub/Presentation'
import { YodaSpinnerPage } from '../utils/YodaSpinnerPage'
import { useUpdateNote } from './Financiary/NoteQueries'
import { MuffinSynchronization } from '../MuffinSynchronization/MuffinSynchronization'
import styled from 'styled-components'

const InvolvementResult = React.lazy(() => import('../Involvement/ProjectInvolvementForm'))
const ProjectEvaluation = React.lazy(() => import('./Evaluation/ProjectEvaluation'))
const FinanciaryPageWrapper = React.lazy(() => import('./Financiary/Financiary'))
const HistoryListWrapper = React.lazy(() => import('./History/HistoryList'))
const MementoPageWrapper = React.lazy(() => import('./Memento/Memento'))

const InvolvementWrapper: FC = () => (
  <Suspense fallback={<YodaSpinnerPage />}>
    <InvolvementResult />
  </Suspense>
)

const EvaluationWrapper: FC<RouteComponentProps<{ tab: PaCategory }>> = ({ match }) => {
  return (
    <Suspense fallback={<YodaSpinnerPage />}>
      <ProjectEvaluation tab={match.params.tab} />
    </Suspense>
  )
}

const FinanciaryWrapper: FC = () => {
  return (
    <Suspense fallback={<YodaSpinnerPage />}>
      <FinanciaryPageWrapper />
    </Suspense>
  )
}

const ProjectAnalyse: FC = () => <NoteAnalyse />

const ProjectHubWrapper: FC = () => <Presentation />

const HistoryWrapper: FC = () => {
  return (
    <Suspense fallback={<YodaSpinnerPage />}>
      <HistoryListWrapper />
    </Suspense>
  )
}

const MementoWrapper: FC = () => {
  return (
    <Suspense fallback={<YodaSpinnerPage />}>
      <MementoPageWrapper />
    </Suspense>
  )
}

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const NoteContainer: FC = () => {
  const { path } = useRouteMatch()
  const { baseNotePath, noteName } = useContext(NoteContext)

  return (
    <div style={{ marginTop: '20px', height: '100%' }}>
      <CreationExNihiloDialog />
      <Switch>
        <Redirect exact from={path} to={`${path}/presentation`} />
        <Route exact path={`${path}/presentation`}>
          <Header>
            <Breadcrumbs
              items={[
                { href: '/', text: 'Accueil' },
                { text: noteName, current: false },
                { text: 'Présentation', current: true },
              ]}
              breadcrumbRenderer={BreadcrumbRenderer}
            />
            <MuffinSynchronization />
          </Header>
          <ProjectHubWrapper />
        </Route>
        <Route exact path={`${path}/analyse`}>
          <Header>
            <Breadcrumbs
              items={[
                { href: '/', text: 'Accueil' },
                {
                  href: `${baseNotePath}/presentation`,
                  text: noteName,
                },
                { text: 'Analyse', current: true },
              ]}
              breadcrumbRenderer={BreadcrumbRenderer}
            />
            <MuffinSynchronization />
          </Header>
          <ProjectAnalyse />
        </Route>
        <Route exact path={`${path}/involvement`}>
          <Header>
            <Breadcrumbs
              items={[
                { href: '/', text: 'Accueil' },
                {
                  href: `${baseNotePath}/presentation`,
                  text: noteName,
                },
                { text: "Révélateur d'engagement", current: true },
              ]}
              breadcrumbRenderer={BreadcrumbRenderer}
            />
            <MuffinSynchronization />
          </Header>
          <InvolvementWrapper />
        </Route>
        <Route path={`${path}/financiary`}>
          <FinanciaryWrapper />
        </Route>
        <Route exact path={`${path}/evaluation/:tab?`}>
          <Header>
            <Breadcrumbs
              items={[
                { href: '/', text: 'Accueil' },
                {
                  href: `${baseNotePath}/presentation`,
                  text: noteName,
                },
                { text: 'Analyse', current: true },
              ]}
              breadcrumbRenderer={BreadcrumbRenderer}
            />
            <MuffinSynchronization />
          </Header>
          <RouteWithSubRoutes path={`${path}/evaluation/:tab?`} Component={EvaluationWrapper} />
        </Route>
        <Route exact path={`${path}/memento`}>
          <Header>
            <Breadcrumbs
              items={[
                { href: '/', text: 'Accueil' },
                {
                  href: `${baseNotePath}/presentation`,
                  text: noteName,
                },
                { text: 'Memento', current: true },
              ]}
              breadcrumbRenderer={BreadcrumbRenderer}
            />
            <MuffinSynchronization />
          </Header>
          <MementoWrapper />
        </Route>
        <Route exact path={`${path}/history`}>
          <Header>
            <Breadcrumbs
              items={[
                { href: '/', text: 'Accueil' },
                {
                  href: `${baseNotePath}/presentation`,
                  text: noteName,
                },
                { text: 'Historique', current: true },
              ]}
              breadcrumbRenderer={BreadcrumbRenderer}
            />
            <MuffinSynchronization />
          </Header>
          <HistoryWrapper />
        </Route>
      </Switch>
    </div>
  )
}

const CreationExNihiloDialog: FC = () => {
  const { note, noteId } = useContext(NoteContext)
  const updateMutation = useUpdateNote(noteId)
  const [updating, setUpdating] = useState(false)

  const updateNote = (creationExNihilo: boolean) => {
    setUpdating(true)
    updateMutation({ noteId, creationExNihilo }).then(() => setUpdating(false))
  }

  return (
    <Dialog
      isOpen={note.type === NoteType.UndefinedCreation}
      title={"Note en phase de vie 'Création'"}
      isCloseButtonShown={false}
    >
      <div className={Classes.DIALOG_BODY}>
        <div>Souhaitez-vous saisir des données comptables liées à des exercices antérieurs ?</div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        {!updating && (
          <div className={Classes.DIALOG_FOOTER}>
            <Button style={{ marginRight: '0.5em' }} onClick={() => updateNote(false)}>
              Oui
            </Button>
            <Button onClick={() => updateNote(true)}>Non</Button>
          </div>
        )}
        {updating && <YodaSpinnerPage size={30} />}
      </div>
    </Dialog>
  )
}
