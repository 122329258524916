import React, { FC } from 'react'
import { Alert } from '@blueprintjs/core'
import styled from 'styled-components'

export const CustomAlert: FC<{
  content: string | JSX.Element
  isOpen: boolean
  onClose: () => any
  confirmButtonText?: string
}> = ({ content, isOpen, onClose, confirmButtonText = 'OK' }) => {
  return (
    <Alert
      canOutsideClickCancel={false}
      isOpen={isOpen}
      confirmButtonText={confirmButtonText}
      onClose={onClose}
    >
      {content}
    </Alert>
  )
}

const ItalicP = styled.p`
  font-style: italic;
`

export const pdfErrorContent = (
  <>
    <p>Une erreur s'est produite lors de la génération du PDF. Veuillez réessayer.</p>
    <br />
    <ItalicP>Si le problème persiste, les Jedis de YODA tu contacteras.</ItalicP>
  </>
)
