import React, { FC, ReactNode, useContext } from 'react'
import styled from 'styled-components'
import { Card } from '@blueprintjs/core'
import { ReactComponent as HandEuros } from '../../../assets/hand-holding-eur-solid.svg'
import { ReactComponent as Money } from '../../../assets/money.svg'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { NumberText } from '@fa-metier/components'
import { DifferencesSaisiesPrets } from './DifferencesSaisiesPrets'
import { NoteContext } from '../../NotePage'
import { PretsContext } from './PretTabs'
import { FormulaDescriptionPopover } from '../Formulas/FormulaDescriptionPopover'
import { FormulaPopoverContent } from '../Formulas/FormulaPopover'
import { CalculatedData } from '@fa-metier/types'

export const GreenTitle = styled.span`
  color: var(--main-green-1);
  font-size: 27px;
  line-height: 32px;
  font-weight: 400;
`

export const Container = styled.div`
  margin: 2em 0;
  .flex {
    display: flex;
    &.row {
      flex-direction: row;
    }
    &.column {
      flex-direction: column;
    }
    &.justifyContentCenter {
      justify-content: center;
    }
  }

  .synthese + .synthese {
    margin-left: 1em;
  }

  .bp3-card {
    padding: 20px 20px 20px 0;
  }
`

export const SyntheseTable = styled.table`
  border-collapse: collapse;
  tr:first-child td {
    border-bottom: 2px var(--main-grey-2) solid;
  }
  tr td:nth-child(2) {
    border-left: 2px var(--main-grey-2) solid;
  }
  td {
    padding: 0.5em;
    text-align: start;
    vertical-align: bottom;
    min-width: 150px;
  }
`

export const SyntheseCard = styled(Card)`
  display: flex;
  width: fit-content;
  box-shadow: none;
`

export const GreyText = styled.span`
  color: var(--main-grey-1);
`

export const BlackNumber = styled(NumberText)`
  color: var(--main-black-1);
  font-size: 27px;
  padding-right: 0.5em;
`

export const GreenNumber = styled(NumberText)`
  color: var(--main-green-1);
  font-size: 36px;
  padding-right: 0.5em;
  line-height: 36px;
`

export const HandEurosIcon = styled(HandEuros)`
  color: var(--main-green-4);
  width: 100px;
  height: 100px;
`
export const BankSafeIcon = styled(Money)`
  color: var(--main-green-4);
  width: 80px;
  height: 80px;
`

export const Synthese: FC<{ title: string; icon: ReactNode }> = ({ title, icon, children }) => {
  return (
    <div className={'flex row synthese'}>
      <div className={'flex column justifyContentCenter'} style={{ padding: '1em' }}>
        {icon}
      </div>
      <div>
        <span style={{ fontWeight: 600 }}>{title}</span>
        <SyntheseTable>{children}</SyntheseTable>
      </div>
    </div>
  )
}

export const HeaderCell: FC<{ data?: number; label: string }> = ({ label, data }) => {
  return (
    <td>
      {data !== undefined && <GreenNumber value={data} />}
      <GreyText>{label}</GreyText>
    </td>
  )
}

export const Cell: FC<{ data: CalculatedData }> = ({ data }) => {
  const { noteSettings } = useContext(NoteContext)
  return (
    <td>
      <div>
        <FormulaDescriptionPopover
          content={
            <FormulaPopoverContent
              calculatedData={data}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />
          }
          position={'bottom'}
        >
          <div>
            <BlackNumber
              value={data.numericData.value?.toFixed(0)}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />{' '}
            <GreyText>Total</GreyText>
          </div>
        </FormulaDescriptionPopover>
      </div>
    </td>
  )
}

export const PretsSynthese: FC = () => {
  const { pretSynthese } = useContext(PretsContext)
  return (
    <Container>
      <Container>
        <GreenTitle>Synthèse</GreenTitle>
      </Container>
      <SyntheseCard>
        <Synthese title={'Prêts bancaires'} icon={<BankSafeIcon />}>
          <tbody>
            <tr>
              <HeaderCell label={'Prêts'} data={pretSynthese.pretsBancaires.nombrePrets} />
              <HeaderCell
                label={'Prêts garantis'}
                data={pretSynthese.pretsBancaires.nombrePretsGarantis}
              />
              <HeaderCell label={'Montant garanti'} />
            </tr>
            <tr>
              <Cell data={pretSynthese.pretsBancaires.montantTotal} />
              <Cell data={pretSynthese.pretsBancaires.totalPretsGarantis} />
              <Cell data={pretSynthese.pretsBancaires.totalMontantGaranti} />
            </tr>
          </tbody>
        </Synthese>
        <Synthese title={'Prêts non bancaires'} icon={<HandEurosIcon />}>
          <tbody>
            <tr>
              <HeaderCell label={'Prêts'} data={pretSynthese.pretsNonBancaires.nombrePrets} />
              <HeaderCell
                label={"Prêts gérés par l'AT"}
                data={pretSynthese.pretsNonBancaires.nombrePretsAT}
              />
              <HeaderCell
                label={'Prêts externalisés'}
                data={pretSynthese.pretsNonBancaires.nombrePretsExternes}
              />
            </tr>
            <tr>
              <Cell data={pretSynthese.pretsNonBancaires.montantTotal} />
              <Cell data={pretSynthese.pretsNonBancaires.totalPretAT} />
              <Cell data={pretSynthese.pretsNonBancaires.totalPretsExternes} />
            </tr>
          </tbody>
        </Synthese>
      </SyntheseCard>
      <DifferencesSaisiesPrets />
    </Container>
  )
}
