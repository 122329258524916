import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import PageTitle from '../../../utils/PageTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import {
  toMasseSalarialeInput,
  useDeleteMasseSalarialeFonction,
  useGetMasseSalariale,
  useUpdateFonctionMasseSalariale,
  useUpdateMasseSalariale,
} from './MasseSalarialeQueries'
import { useDebounce } from 'react-use'
import { NumberText } from '@fa-metier/components'
import {
  CalculatedData,
  MasseSalariale,
  MasseSalarialeFonction,
  MasseSalarialeFonctionPeriod,
  NumericData,
} from '@fa-metier/types'
import { AppMetierNumberInput } from '../Utils/AppMetierInput/AppMetierNumberInput'
import { AppMetierTextInput } from '../Utils/AppMetierInput/AppMetierTextInput'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import { Button } from '@blueprintjs/core'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { DifferencesSaisiesMasseSalariale } from './DifferencesSaisiesMasseSalariale'
import { eurosSuffix, Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { StrokedButton } from '@fa-metier/components/dist/Buttons/Buttons'
import { NoteContext } from '../../NotePage'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { PeriodModel } from '../PeriodModel'
import { useGetPeriods } from '../FinanciaryQueries'
import { FormulaDescriptionPopover } from '../Formulas/FormulaDescriptionPopover'
import { FormulaPopoverContent } from '../Formulas/FormulaPopover'
import { getField } from '@fa-metier/commons'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { DEBOUNCE_CONFIG } from '../../../Settings/debounceConfig'
import { saveShortcutListener, withFieldsGuard } from '../../../utils/utils'
import { ParametersDiv } from '../../../utils/ParametersDiv'
import { PageSaveButton } from '../../../utils/PageSaveButton'
import _ from 'lodash'
import { useUserDebounce } from '../../../Settings/Hooks'

const NumberTextMasseSalariale = styled(NumberText)`
  display: grid;
  margin: 0 2px 0 2px;
  text-align: end;
`

const Text = styled.div`
  white-space: pre-wrap;
  margin-left: 15px;
  margin-right: auto;
  font-size: 13px;
  color: var(--main-black-2);
  line-height: 26px;
`

const TextExercice = styled(Text)`
  white-space: nowrap;
  padding: 0;
  font-size: 12px;
  color: var(--main-grey-1);
  text-align: center;
`

const Table = styled.table`
  margin-top: 30px;
  border-spacing: 0;
  border-collapse: collapse;

  * tr.hoverable {
    :hover {
      background: linear-gradient(var(--main-green-2), var(--main-green-2)) no-repeat 15px 0;
    }
  }
`

const InputTable = styled(Table)`
  * td.deleteButton {
    min-width: 30px;
    padding: 0;
    text-align: center;
  }

  tr td:not(:first-child):not(.action) {
    width: 110px;
  }

  tr td.action {
    width: 40px;
  }

  tr td:first-child {
    width: 250px;
  }
`

const SummaryTable = styled(Table)`
  tr td:not(:first-child) {
    width: 120px;
  }

  tr td:first-child {
    width: 200px;
  }
`

const TotalTable = styled(Table)`
  tr td:not(:first-child) {
    width: 110px;
  }

  tr td:first-child {
    width: 250px;
  }
`

const Title = styled.td`
  border: none;
  background-color: var(--main-gray-3);
  margin-left: 35px;
  padding: 10px 10px 20px 10px;
  text-align: center;
`

const TitleRubrique = styled(Title)`
  font-size: 20px;
  line-height: 22px;
  font-weight: var(--bold);
`

const Line = styled.tr`
  background-color: var(--main-gray-3);
  font-weight: var(--bold);

  .hoverable {
    :hover {
      background: linear-gradient(var(--main-green-2), var(--main-green-2)) no-repeat 15px 0;
    }
  }
`

const TdMasseSalariale = styled.td`
  white-space: nowrap;
  color: var(--main-black-2);

  .bp3-input-group {
    display: contents;
  }
`

const TdTitreMasseSalariale = styled.td`
  padding: 5px 10px 5px 10px;
`

const TitleInnerLineForm = styled(Line)`
  background-color: white;
`

const TitleInnerLineSalaire = styled(Line)`
  background-color: white;
`

const TextBold = styled(Text)`
  font-weight: var(--bold);
`

const TextGreen = styled(Text)`
  font-weight: var(--bold);
  color: var(--main-green-1);
`

const LineFonction: FC<{
  fonction: MasseSalarialeFonction
  index: number
  showComputations: boolean
  updateFonction: (
    id: string,
    name: string,
    value: number | string | NumericData | undefined
  ) => any
  updatePeriodFonction: (pId: string, fId: string, name: string, value: number | undefined) => any
  deleteFonction: (ip: number) => any
  computationInProgress: boolean
}> = ({
  fonction,
  index,
  showComputations,
  updateFonction,
  updatePeriodFonction,
  deleteFonction,
  computationInProgress,
}) => {
  const { previsionelPeriods: periodsFinanciary } = useContext(MasseSalarialeContext)

  const [hovered, setHovered] = useState(false)

  const nbreExercice = periodsFinanciary?.length ?? 1
  const widthColumn: string = (100 / nbreExercice - 3).toString().concat('%')

  return (
    <TitleInnerLineForm
      key={`SB${fonction.id}`}
      id={`ligne${index}`}
      className={'hoverable'}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <td style={{ padding: '5px 10px 5px 5px' }}>
        <AppMetierTextInput
          value={fonction.fonction ?? ''}
          onChange={(value) => {
            updateFonction(fonction.id, 'fonction', value.toString())
          }}
          disabled={computationInProgress}
        />
      </td>
      {periodsFinanciary &&
        periodsFinanciary.map((period) => (
          <TdMasseSalariale key={`effectifs${period.id}`}>
            <AppMetierNumberInput
              customCSS={{ marginTop: '10px' }}
              value={fonction.periods.find((p) => p.periodId === period.id)?.effectifs ?? 0}
              onChange={(value) => {
                updatePeriodFonction(fonction.id, period.id, 'effectifs', value)
              }}
              disabled={computationInProgress}
            />
          </TdMasseSalariale>
        ))}
      <td>
        <NumericDataInput
          numericData={fonction.salaireBrutMoyen ?? undefined}
          isEuro={true}
          isCalculated={computationInProgress}
          showComputations={showComputations}
          onChange={(value) => updateFonction(fonction.id, 'salaireBrutMoyen', value)}
        />
      </td>
      {periodsFinanciary &&
        periodsFinanciary.map((period) => (
          <TdMasseSalariale key={`moispayes${period.id}`}>
            <AppMetierNumberInput
              customCSS={{ width: widthColumn, marginTop: '10px' }}
              value={fonction.periods.find((p) => p.periodId === period.id)?.moisPayes ?? 0}
              onChange={(value) => updatePeriodFonction(fonction.id, period.id, 'moisPayes', value)}
              onlyInteger={true}
              disabled={computationInProgress}
            />
          </TdMasseSalariale>
        ))}
      <td>
        <AppMetierNumberInput
          value={(fonction.tauxChargesSalariales ?? 0) * 100}
          suffix={Suffixes.POURCENTAGE}
          onChange={(value) => {
            if (value !== undefined) {
              updateFonction(fonction.id, 'tauxChargesSalariales', value / 100)
            }
          }}
          disabled={computationInProgress}
        />
      </td>
      <td>
        <AppMetierNumberInput
          value={(fonction.tauxChargesPatronales ?? 0) * 100}
          suffix={Suffixes.POURCENTAGE}
          onChange={(value) => {
            if (value !== undefined) {
              updateFonction(fonction.id, 'tauxChargesPatronales', value / 100)
            }
          }}
          disabled={computationInProgress}
        />
      </td>
      <td className={'deleteButton action'}>
        {hovered && (
          <Button
            key={'delete'.concat(fonction.id)}
            icon={'trash'}
            minimal
            large={false}
            onClick={() => deleteFonction(+fonction.id)}
            disabled={computationInProgress}
          />
        )}
      </td>
    </TitleInnerLineForm>
  )
}

interface MasseSalarialeContextI {
  masseSalariale: MasseSalariale
  previsionelPeriods: PeriodModel[]
}

export const MasseSalarialeContext = React.createContext<MasseSalarialeContextI>(
  {} as MasseSalarialeContextI
)

export const MasseSalarialeFormWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading: masseSalarialeLoading, data: masseSalarialeData } = useGetMasseSalariale(noteId)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)
  if (masseSalarialeLoading || periodLoading) {
    return <YodaSpinnerPage />
  }
  return (
    <MasseSalarialeContext.Provider
      value={{
        masseSalariale: masseSalarialeData!!,
        previsionelPeriods: periodsData.previsionnelles,
      }}
    >
      <MasseSalarialeForm />
    </MasseSalarialeContext.Provider>
  )
}

export const MasseSalarialeForm: FC = () => {
  const { noteId, noteSettings, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const { masseSalariale: masseSalarialeInput, previsionelPeriods: periodsFinanciary } =
    useContext(MasseSalarialeContext)
  const [masseSalariale, setMasseSalariale] = useState(masseSalarialeInput)
  const updateMasseSalariale = useUpdateMasseSalariale(noteId)
  const deleteMasseSalarialeFonction = useDeleteMasseSalarialeFonction(noteId)
  const [showComputations, setShowComputations] = useState(false)
  const [computationInProgress, setComputationInProgress] = useState(false)

  const refs = useRef({
    masseSalarialeInput,
    masseSalariale,
    unsavedChanges,
  })

  const updateMasseSalarialeFonction = useUpdateFonctionMasseSalariale(noteId)

  const nbreExercice = periodsFinanciary?.length ?? 1

  const updateFonction = (
    idFonction: string,
    nameFields: string,
    value: string | number | undefined | NumericData
  ) => {
    if (masseSalariale.fonctions) {
      const func = masseSalariale.fonctions.find((f) => f.id === idFonction)
      if (func) {
        const updatedFunc: MasseSalarialeFonction = { ...func, [nameFields]: value }
        setMasseSalariale({
          ...masseSalariale,
          fonctions: masseSalariale.fonctions.map((f) =>
            f.id === updatedFunc.id ? updatedFunc : f
          ),
        })
      }
    }
  }

  const updatePeriodFonction = (
    idFonction: string,
    idPeriod: string,
    nameFields: string,
    value: string | number | undefined
  ) => {
    if (value || value === 0) {
      if (masseSalariale.fonctions) {
        const func = masseSalariale.fonctions.find((f) => f.id === idFonction)
        if (func) {
          const period = func.periods.find((p) => p.periodId === idPeriod)
          if (period) {
            const updatedPeriod: MasseSalarialeFonctionPeriod = { ...period, [nameFields]: value }
            const updatedFonction: MasseSalarialeFonction = {
              ...func,
              periods: func.periods.map((p) => (p.periodId === idPeriod ? updatedPeriod : p)),
            }

            setMasseSalariale({
              ...masseSalariale,
              fonctions: masseSalariale.fonctions.map((fonction) =>
                fonction.id === updatedFonction.id ? updatedFonction : fonction
              ),
            })
          }
        }
      }
    }
  }

  const deleteFonction = (id: number) => {
    deleteMasseSalarialeFonction(id).then(() => {
      setMasseSalariale({
        ...masseSalariale,
        fonctions: masseSalariale.fonctions.filter((f) => +f.id !== id),
      })
    })
  }

  const userDebounce = useUserDebounce()

  const [, cancelDebounce] = useDebounce(
    async () => {
      if (!_.isEqual(masseSalariale, refs.current.masseSalarialeInput)) {
        await withFieldsGuard(
          () => updateMasseSalariale(toMasseSalarialeInput(masseSalariale)),
          setComputationInProgress
        )
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [masseSalariale]
  )

  useEffect(() => {
    refs.current.masseSalariale = masseSalariale
    if (!_.isEqual(masseSalariale, refs.current.masseSalarialeInput)) {
      setUnsavedChanges(true)
    }
  }, [masseSalariale, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.masseSalarialeInput, masseSalarialeInput)) {
      refs.current.masseSalarialeInput = masseSalarialeInput
      setMasseSalariale(masseSalarialeInput)
      cancelDebounce()
    }
  }, [masseSalarialeInput, cancelDebounce])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelDebounce()
      if (!_.isEqual(refs.current.masseSalariale, refs.current.masseSalarialeInput)) {
        await updateMasseSalariale(toMasseSalarialeInput(refs.current.masseSalariale))
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dictionary = useDictionnary()

  const lineExercice = (origin: string, paddingRight: string | undefined) => {
    return (
      <>
        {periodsFinanciary.map((p, i) => (
          <TdMasseSalariale key={'ex'.concat(origin).concat(p.id)}>
            <TextExercice style={{ paddingRight: paddingRight ? paddingRight : '' }}>
              {dictionary.periodTitle(p, i)}
            </TextExercice>
          </TdMasseSalariale>
        ))}
      </>
    )
  }

  const buildEmptyFunction = () => {
    updateMasseSalarialeFonction({ periods: [] }).then((result) => {
      setMasseSalariale({
        ...masseSalariale,
        fonctions: result.fonctions,
      })
    })
  }

  return (
    <div style={{ paddingBottom: '3%' }}>
      <PageTitle>Masse salariale</PageTitle>

      <div
        style={{
          display: 'flex',
          borderStyle: 'solid',
          padding: '10px',
          borderWidth: '1.5px',
          borderColor: 'var(--main-grey-2)',
          maxWidth: 'max-content',
          marginBottom: '25px',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            paddingRight: '5px',
          }}
        >
          <FontAwesomeIcon
            icon={['fas', 'exclamation-triangle']}
            color={'var(--main-green-1)'}
            size={'lg'}
          />
        </div>
        <div>
          <TextGreen style={{ fontSize: '16px' }}>
            Cette page peut vous servir à calculer une approximation de la masse salariale pour
            valider les données transmises par l'entrepreneur.
          </TextGreen>
          <Text style={{ fontSize: '16px' }}>
            Attention : le calcul d'une masse salariale prend en compte un ensemble de facteurs
            complexes,
            <br />
            l'ensemble de cette page ne sert qu'à donner des approximations et ne doit pas être
            comprise autrement.
          </Text>
        </div>
      </div>

      <ParametersDiv>
        <PageSaveButton
          buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
          buttonDisabled={computationInProgress || !unsavedChanges}
        />
        <AppMetierSwitch
          label={'Voir tous les calculs'}
          checked={showComputations}
          onChange={() => {
            setShowComputations(!showComputations)
          }}
        />
      </ParametersDiv>

      <div style={{ display: 'flex' }}>
        <TextBold style={{ marginRight: '22px', fontSize: '16px' }}>
          Croissance annuelle des salaires
        </TextBold>
        <AppMetierNumberInput
          customCSS={{ width: '55px' }}
          value={(masseSalariale?.croissanceAnnuelleSalaires ?? 0) * 100}
          suffix={Suffixes.POURCENTAGE}
          onChange={(value) => {
            if (value || value === 0) {
              setMasseSalariale({
                ...masseSalariale,
                croissanceAnnuelleSalaires: value / 100,
              })
            }
          }}
          disabled={computationInProgress}
        />
      </div>

      <InputTable>
        <tbody>
          <Line>
            <TitleRubrique />
            <Title colSpan={nbreExercice}>Effectif salarié en Equivalent Temps Plein (ETP)</Title>
            <Title>Salaire brut mensuel moy temps plein</Title>
            <Title colSpan={nbreExercice}>Nombre de mois payés par exercice</Title>
            <Title>Taux de charges salariales</Title>
            <Title>Taux de charges patronales</Title>
          </Line>
          <TitleInnerLineForm>
            <td style={{ minWidth: '108px' }} />
            {lineExercice('ETP', '15px')}
            <td />
            {lineExercice('NMPE', '15px')}
            <td colSpan={3}>
              <TextExercice />
            </td>
          </TitleInnerLineForm>
          {masseSalariale &&
            masseSalariale.fonctions
              .slice()
              .sort((a, b) => +a.id - +b.id)
              .map((fonction, index) => (
                <LineFonction
                  key={`fonction-${index}`}
                  showComputations={showComputations}
                  index={index}
                  fonction={fonction}
                  deleteFonction={deleteFonction}
                  updateFonction={updateFonction}
                  updatePeriodFonction={updatePeriodFonction}
                  computationInProgress={computationInProgress}
                />
              ))}
          <TitleInnerLineForm>
            <td style={{ padding: '5px 20px 5px 10px' }}>
              <StrokedButton
                text={'+ Ajouter une fonction'}
                key={'AjouterFonction'}
                onClick={() => buildEmptyFunction()}
                style={{ width: '100%' }}
                disabled={computationInProgress}
              />
            </td>
            <td colSpan={2 * nbreExercice + 5} />
          </TitleInnerLineForm>
          <TitleInnerLineForm style={{ backgroundColor: 'var(--main-grey-3)' }}>
            <TdTitreMasseSalariale>
              <Text style={{ color: 'var(--main-black-2)' }}>Total</Text>
            </TdTitreMasseSalariale>
            {masseSalarialeInput.periods.map((period) => (
              <TdMasseSalariale
                style={{ color: 'var(--main-black-2)' }}
                key={`totalEx${period.periodId}`}
              >
                <FormulaDescriptionPopover
                  content={<FormulaPopoverContent calculatedData={period.effectifs} suffix={''} />}
                  position={'bottom'}
                >
                  <div style={{ textAlign: 'end' }}>
                    <span>
                      <NumberText value={period.effectifs.numericData.value} suffix={''} />
                    </span>
                  </div>
                </FormulaDescriptionPopover>
              </TdMasseSalariale>
            ))}
            <td colSpan={2 * nbreExercice + 5} />
          </TitleInnerLineForm>
        </tbody>
      </InputTable>

      <SummaryTable>
        <tbody>
          <Line>
            <TitleRubrique />
            <Title colSpan={nbreExercice}>Salaires bruts</Title>
            <Title colSpan={nbreExercice}>Salaires nets</Title>
            <Title colSpan={nbreExercice}>Montant des charges patronales</Title>
          </Line>
          <TitleInnerLineSalaire>
            <td />
            {lineExercice('SB', undefined)}
            {lineExercice('SN', undefined)}
            {lineExercice('MCP', undefined)}
          </TitleInnerLineSalaire>
          {masseSalarialeInput &&
            masseSalarialeInput.fonctions
              .slice()
              .sort((a, b) => +a.id - +b.id)
              .map((fonction) => (
                <TitleInnerLineForm className={'hoverable'} key={'SN'.concat(fonction.id)}>
                  <td>
                    <Text>{fonction.fonction}</Text>
                  </td>
                  <TotalFonctionLineParts
                    fonction={fonction}
                    fieldName={'salairesBruts'}
                    suffix={eurosSuffix(noteSettings.kiloEuros)}
                  />
                  <TotalFonctionLineParts
                    fonction={fonction}
                    fieldName={'salairesNets'}
                    suffix={eurosSuffix(noteSettings.kiloEuros)}
                  />
                  <TotalFonctionLineParts
                    fonction={fonction}
                    fieldName={'chargesPatronales'}
                    suffix={eurosSuffix(noteSettings.kiloEuros)}
                  />
                </TitleInnerLineForm>
              ))}
          <TitleInnerLineForm style={{ backgroundColor: 'var(--main-grey-3)' }}>
            <TdTitreMasseSalariale>
              <Text style={{ color: 'var(--main-black-2)' }}>Total</Text>
            </TdTitreMasseSalariale>
            {masseSalarialeInput.periods.length === 0 && <td colSpan={3 * nbreExercice} />}
            <TotalLinePart
              fieldName={'salairesBruts'}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />
            <TotalLinePart
              fieldName={'salairesNets'}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />
            <TotalLinePart
              fieldName={'chargesSocialesPatronales'}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />
          </TitleInnerLineForm>
        </tbody>
      </SummaryTable>

      <TotalTable>
        <thead>
          <tr>
            <TitleRubrique>Totaux</TitleRubrique>
          </tr>
        </thead>
        <tbody>
          <Line style={{ backgroundColor: 'white' }}>
            <td style={{ width: '35%' }} />
            {lineExercice('T', undefined)}
          </Line>
          <TotalLineWithLabel
            suffix={eurosSuffix(noteSettings.kiloEuros)}
            fieldName={'salairesNets'}
            label={'Salaires nets'}
          />
          <TotalLineWithLabel
            suffix={eurosSuffix(noteSettings.kiloEuros)}
            fieldName={'chargesSocialesSalariales'}
            label={'Charges sociales pour le salarié'}
          />
          <TotalLineWithLabel
            suffix={eurosSuffix(noteSettings.kiloEuros)}
            fieldName={'chargesSocialesPatronales'}
            label={'Charges sociales patronales'}
          />
          <TotalLineWithLabel
            suffix={eurosSuffix(noteSettings.kiloEuros)}
            fieldName={'coutTotalMainOeuvre'}
            label={"Coût total de la main d'œuvre"}
            isResult={true}
          />
        </tbody>
      </TotalTable>

      <DifferencesSaisiesMasseSalariale
        masseSalarialeInput={masseSalarialeInput}
        periodsFinanciary={periodsFinanciary}
      />
    </div>
  )
}

const TotalFonctionLineParts: FC<{
  fonction: MasseSalarialeFonction
  fieldName: string
  suffix: string
}> = ({ fonction, fieldName, suffix }) => {
  const { previsionelPeriods: periodsFinanciary } = useContext(MasseSalarialeContext)
  if (
    periodsFinanciary === undefined ||
    periodsFinanciary === null ||
    periodsFinanciary.length === 0
  ) {
    return <></>
  }
  return (
    <>
      {periodsFinanciary.map((period) => {
        const fonctionPeriod = fonction.periods.find((p) => p.periodId === period.id)
        const defaultReturn = (
          <TdMasseSalariale key={`${fieldName}_Financiary_${period.id}`}>
            <NumberTextMasseSalariale suffix={suffix} value={0} decimalScale={0} />
          </TdMasseSalariale>
        )

        if (fonctionPeriod === undefined || fonctionPeriod === null) {
          return defaultReturn
        }
        const data = getField<CalculatedData>(fieldName, fonctionPeriod)
        if (data === undefined || null) {
          return defaultReturn
        }
        return (
          <TdMasseSalariale key={`${fieldName}_Financiary_${period.id}`}>
            {data.numericData.value !== undefined ? (
              <FormulaDescriptionPopover
                content={<FormulaPopoverContent calculatedData={data} suffix={suffix} />}
                position={'bottom'}
              >
                <div style={{ textAlign: 'end' }}>
                  <span>
                    <NumberText value={data.numericData.value} suffix={suffix} decimalScale={0} />
                  </span>
                </div>
              </FormulaDescriptionPopover>
            ) : (
              defaultReturn
            )}
          </TdMasseSalariale>
        )
      })}
    </>
  )
}

const TotalLinePart: FC<{ fieldName: string; suffix: string }> = ({ fieldName, suffix }) => {
  const { masseSalariale: masseSalarialeInput } = useContext(MasseSalarialeContext)

  return (
    <>
      {masseSalarialeInput.periods.map((period) => {
        const data = getField<CalculatedData>(fieldName, period)
        const defaultReturn = (
          <TdMasseSalariale
            style={{ color: 'var(--main-black-2)' }}
            key={`total_${fieldName}_${period.periodId}`}
          >
            <NumberTextMasseSalariale suffix={suffix} value={0} decimalScale={0} />
          </TdMasseSalariale>
        )

        if (
          data === undefined ||
          data === null ||
          data.numericData === undefined ||
          data.numericData.value === undefined
        ) {
          return defaultReturn
        }

        return (
          <TdMasseSalariale
            style={{ color: 'var(--main-black-2)' }}
            key={`total_${fieldName}_${period.periodId}`}
          >
            <FormulaDescriptionPopover
              content={<FormulaPopoverContent calculatedData={data} suffix={suffix} />}
              position={'bottom'}
            >
              <div style={{ textAlign: 'end' }}>
                <span>
                  <NumberText value={data.numericData.value} suffix={suffix} decimalScale={0} />
                </span>
              </div>
            </FormulaDescriptionPopover>
          </TdMasseSalariale>
        )
      })}
    </>
  )
}

const TotalLineWithLabel: FC<{
  fieldName: string
  suffix: string
  label: string
  isResult?: boolean
}> = ({ fieldName, suffix, label, isResult }) => {
  return (
    <TitleInnerLineSalaire
      className={isResult ? '' : 'hoverable'}
      style={isResult ? { backgroundColor: 'var(--main-blue-1)' } : {}}
    >
      <TdTitreMasseSalariale>
        <Text style={isResult ? { color: 'white' } : {}}>{label}</Text>
      </TdTitreMasseSalariale>
      <TotalExercicesLine suffix={suffix} fieldName={fieldName} isResult={isResult} />
    </TitleInnerLineSalaire>
  )
}

const TotalExercicesLine: FC<{ fieldName: string; suffix: string; isResult?: boolean }> = ({
  fieldName,
  suffix,
  isResult = false,
}) => {
  const { previsionelPeriods: periodsFinanciary, masseSalariale: masseSalarialeInput } =
    useContext(MasseSalarialeContext)

  if (
    periodsFinanciary === undefined ||
    periodsFinanciary === null ||
    periodsFinanciary.length === 0
  ) {
    return <></>
  }
  const defaultReturn = (id: string) => (
    <TdMasseSalariale key={`total_fin_${fieldName}_${id}`}>
      <NumberTextMasseSalariale
        suffix={suffix}
        value={0}
        decimalScale={0}
        style={isResult ? { color: 'white' } : {}}
      />
    </TdMasseSalariale>
  )
  return (
    <>
      {periodsFinanciary.map((period) => {
        const masseSalarialePeriod = masseSalarialeInput?.periods.find(
          (p) => p.periodId === period.id
        )
        if (!masseSalarialePeriod) {
          return defaultReturn(period.id)
        }
        const data = getField<CalculatedData>(fieldName, masseSalarialePeriod)
        if (data === undefined || null) {
          return defaultReturn(period.id)
        }
        return (
          <TdMasseSalariale key={`total_fin_${fieldName}_${period.id}`}>
            <FormulaDescriptionPopover
              content={<FormulaPopoverContent calculatedData={data} suffix={suffix} />}
              position={'bottom'}
            >
              <div style={{ textAlign: 'end' }}>
                <span>
                  <NumberText
                    value={data.numericData.value}
                    suffix={suffix}
                    decimalScale={0}
                    style={isResult ? { color: 'white' } : {}}
                  />
                </span>
              </div>
            </FormulaDescriptionPopover>
          </TdMasseSalariale>
        )
      })}
    </>
  )
}
