import React, { FC, FormEvent, useContext, useState } from 'react'
import {
  ProjectAnalysisDocument,
  useCreateEntrepreneurMutation,
  useDeleteEntrepreneurMutation,
} from '../../generated/graphql'
import { PopoverInteractionKind, Spinner } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { NoteId } from '../Financiary/NoteQueries'
import { StrokedButton } from '@fa-metier/components/dist/Buttons/Buttons'
import { AppMetierCheckbox } from '../Financiary/Utils/AppMetierCheckBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { ProjectEvaluationContext } from '../context/ProjectEvaluationContext'

export const NewEntrepreneur: FC<{ noteId: NoteId }> = ({ noteId }) => {
  return (
    <div style={{ marginBottom: '10px' }}>
      <Popover2
        content={<NewEntrepreneurPopover noteId={noteId} />}
        interactionKind={PopoverInteractionKind.CLICK}
        position={'bottom-left'}
        minimal={true}
        key={'new_entrepreneur_popover'}
      >
        <div style={{ cursor: 'pointer' }}>
          <StrokedButton
            text={
              <>
                {' '}
                + Ajouter un entrepreneur <FontAwesomeIcon icon={'chevron-down'} />
              </>
            }
          />
        </div>
      </Popover2>
    </div>
  )
}

const NewEntrepreneurPopover: FC<{ noteId: string }> = ({ noteId }) => {
  const { porteurs, analysis } = useContext(ProjectEvaluationContext)
  const [spinnerDisplayed, setSpinnerDisplayed] = useState(false)
  const [createEntrepreneurFn] = useCreateEntrepreneurMutation({
    refetchQueries: [
      {
        query: ProjectAnalysisDocument,
        variables: { noteId },
      },
    ],
  })

  const [deleteEntrepreneurFn] = useDeleteEntrepreneurMutation({
    refetchQueries: [
      {
        query: ProjectAnalysisDocument,
        variables: { noteId },
      },
    ],
  })

  const handleChange = (e: FormEvent<HTMLInputElement>, key: string) => {
    setSpinnerDisplayed(true)
    // @ts-ignore
    if (e.target.checked) {
      createEntrepreneurFn({
        variables: {
          noteId,
          request: {
            idMuffin: key,
          },
        },
      }).then(() => setSpinnerDisplayed(false))
    } else {
      deleteEntrepreneurFn({
        variables: {
          idMuffin: key,
        },
      }).then(() => setSpinnerDisplayed(false))
    }
  }

  return (
    <NewEntrepreneurPopoverContent className={'porteursList'} key={'entrepreneurPopOver'}>
      {spinnerDisplayed && <Spinner size={30} />}
      {!spinnerDisplayed &&
        porteurs.map((p) => {
          return (
            p.porteurType === 'AUTRE' && (
              <div
                key={`popover_content_div_${p.muffinId}`}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <AppMetierCheckbox
                  checked={
                    analysis.entrepreneurs.find((e) => e.idMuffin === p.muffinId) !== undefined
                  }
                  onChange={(e) => handleChange(e, p.muffinId ?? ' ')}
                  key={`checkbox_${p.muffinId}`}
                >
                  {' '}
                  {p.firstname} {p.lastname}
                </AppMetierCheckbox>
              </div>
            )
          )
        })}
    </NewEntrepreneurPopoverContent>
  )
}

const NewEntrepreneurPopoverContent = styled.div`
  width: 370px;
  height: 170px;
  cursor: pointer;
  border-radius: 2px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: var(--main-black-2);
  padding: 20px;
`
