import React, { FC, useState } from 'react'
import { formatDayjs, getDayjsFormatter, stringToDate } from '../../../../utils/DayjsFormatter'
import { DateInput } from '@blueprintjs/datetime'
import { Classes, IInputGroupProps, IPopoverProps } from '@blueprintjs/core'
import dayjs from 'dayjs'
import { HTMLInputProps } from '@blueprintjs/core/src/common/props'
import 'moment/locale/fr'
import MomentLocaleUtils from 'react-day-picker/moment'
import styled from 'styled-components'

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD'
export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY'

const DOWN = 'ArrowDown'
const UP = 'ArrowUp'
const ENTER = 'Enter'
const TAB = 'Tab'
const LEFT = 'ArrowLeft'
const RIGHT = 'ArrowRight'

const DateInputContainer = styled.div`
  min-width: 100px;
`

interface AppMetierDateInputProps {
  value: string | null | undefined
  customCSS?: React.CSSProperties
  onChange?: (selectedDate: string, isUserChange: boolean) => any
  disabled?: boolean
  minDate?: Date
  maxDate?: Date
  initialMonth?: Date
  closeOnSelection?: boolean
  canClearSelection?: boolean
  onInput?: () => void
}

export const AppMetierDateInput: FC<AppMetierDateInputProps> = ({
  value,
  customCSS,
  onChange,
  disabled,
  minDate,
  maxDate = new Date('2150-12-17T03:24:00'),
  initialMonth,
  closeOnSelection,
  canClearSelection,
  onInput,
}) => {
  const [leaveFocus, setLeaveFocus] = useState(true)

  const handleKeyboardNavigation = (eventKey: string) => {
    switch (eventKey) {
      case DOWN:
      case UP:
      case ENTER:
      case TAB:
      case LEFT:
      case RIGHT:
        setLeaveFocus(true)
    }
  }

  const onFocus = () => {
    !!onInput && onInput()
    setLeaveFocus(false)
  }

  const defaultStyle = {
    borderRadius: '5px',
    outline: 'none',
    height: '32px',
    border: 'solid 1px var(--main-grey-2-lighter)',
    boxShadow: 'none',
    ...customCSS,
  } as React.CSSProperties

  const operationStyle = {
    focus: {
      ...defaultStyle,
      border: 'solid 1px var(--main-green-1)',
      color: 'var(--main-green-1)',
      boxShadow: 'none',
    } as React.CSSProperties,
    none: { ...defaultStyle } as React.CSSProperties,
  }

  const inputPropsNone = {
    style: { ...operationStyle.none },
  } as IInputGroupProps & HTMLInputProps

  const inputPropsFocus = {
    style: { ...operationStyle.focus },
    onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) =>
      handleKeyboardNavigation(event.key),
  } as IInputGroupProps & HTMLInputProps

  const popoverPropsClose = {
    isOpen: false,
  } as IPopoverProps

  const popoverProps = {
    onClose: () => {
      setLeaveFocus(true)
    },
  } as IPopoverProps

  return (
    <DateInputContainer className={`${Classes.INPUT_GROUP}`} onFocus={onFocus}>
      <DateInput
        placeholder={DISPLAY_DATE_FORMAT}
        value={
          value !== null && value !== undefined ? stringToDate(value, SERVER_DATE_FORMAT) : null
        }
        inputProps={!leaveFocus ? inputPropsFocus : inputPropsNone}
        popoverProps={!leaveFocus ? popoverProps : popoverPropsClose}
        onChange={(selectedDate, isUserChange) => {
          if (onChange && isUserChange) {
            if (minDate && maxDate && selectedDate !== null) {
              if (
                minDate.getTime() <= selectedDate.getTime() &&
                selectedDate.getTime() <= maxDate.getTime()
              ) {
                onChange(formatDayjs(dayjs(selectedDate), SERVER_DATE_FORMAT), isUserChange)
              }
            } else if (selectedDate !== null) {
              onChange(formatDayjs(dayjs(selectedDate), SERVER_DATE_FORMAT), isUserChange)
            }
          }
        }}
        {...getDayjsFormatter(DISPLAY_DATE_FORMAT)}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        initialMonth={initialMonth}
        closeOnSelection={closeOnSelection}
        canClearSelection={canClearSelection}
        localeUtils={MomentLocaleUtils}
        locale="fr"
        outOfRangeMessage={'Date invalide'}
      />
    </DateInputContainer>
  )
}
