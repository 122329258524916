import React, { FC, useContext } from 'react'
import { NumericData } from '@fa-metier/types'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import { FlexDiv, PlanFinancementContext } from './PlanFinancement'
import { NoteContext } from '../../NotePage'
import { PFContext } from './PlanFinancementContainer'

export const DemarrageLine: FC<{ fieldName: string; title: string }> = ({ fieldName, title }) => {
  const { noteSettings } = useContext(NoteContext)
  const {
    showComputations,
    updateDemarrage,
    resetDebounceTimers,
    editablePrevisionnelPeriods,
    computationInProgress,
  } = useContext(PlanFinancementContext)
  const { planFinancement } = useContext(PFContext)
  // @ts-ignore
  const planFinancementField = planFinancement[fieldName] as NumericData

  return (
    <>
      {noteSettings.showDemarrage && (
        <tr className={'hoverable demarrage'}>
          <td className={'title'}>{title}</td>
          {noteSettings.showDemarrage && (
            <td className={'demarrage'}>
              <FlexDiv>
                <NumericDataInput
                  numericData={planFinancementField}
                  onChange={(value) => {
                    resetDebounceTimers()
                    updateDemarrage(fieldName, value)
                  }}
                  showComputations={showComputations}
                  isCalculated={computationInProgress}
                />
              </FlexDiv>
            </td>
          )}
          {editablePrevisionnelPeriods.map((period, i) => (
            <td key={i} />
          ))}
          <td />
        </tr>
      )}
    </>
  )
}
