import {
  FinancialPeriodsDocument,
  PlanFinancementDocument,
  RatiosDocument,
  RessourcesDocument,
  ScoringCreationCheckDocument,
  useRessourcesQuery,
  useUpdatePretMutation,
} from '../../../generated/graphql'
import { Pret, PretPasse, UpdatePretInput } from '@fa-metier/types'
import { removeTypeName } from '@fa-metier/commons'
import dayjs from 'dayjs'

type Loan = Pret | PretPasse

export const isCompatibleWithEcheancePerYear = (loan: Loan, d: number) => {
  if (loan.inFine || d === 0 || d === undefined || d % (12 / loan.yearlyPaymentNb) === 0) {
    return []
  }
  return ['Incompatible avec le Nbre de remb./an.']
}

export const isAllowedValue = (d: number) => {
  const allowedValues = [0, 1, 2, 4, 12]
  if (allowedValues.includes(d)) {
    return []
  }
  return ['Doit être égal à 0, 1, 2, 4 ou 12.']
}

export const areDurationAndDelayCompatible = (loan: Loan, message: string) => {
  if (loan.duration > 0 && (loan.delayed === undefined || loan.delayed >= 0)) {
    return []
  }
  return [message]
}

const areStartingAndFirstRepaymentDateCompatible = (pret: Pret) => {
  const startDate = dayjs(pret.startingDate)
  const firstRepaymentDate = dayjs(pret.firstRepaymentDate)
  if (firstRepaymentDate < startDate) {
    return [
      'La date de premier remboursement ne peut pas être antérieure à la date de décaissement.',
    ]
  }
  if (firstRepaymentDate > startDate.add(12 / pret.yearlyPaymentNb, 'month')) {
    return [
      'La date du premier remboursement ne peut pas dépasser '
        .concat((12 / pret.yearlyPaymentNb).toString())
        .concat(' mois après le décaissement.'),
    ]
  }

  return []
}

export const validatePret = (pret: Pret) => {
  const fieldsValid = {
    title: [],
    capital: [],
    annualRate: [],
    yearlyPaymentNb: isAllowedValue(pret.yearlyPaymentNb),
    duration: isCompatibleWithEcheancePerYear(pret, pret.duration).concat(
      areDurationAndDelayCompatible(pret, 'La durée hors différé doit être supérieure à 0.')
    ),
    delayed: isCompatibleWithEcheancePerYear(pret, pret.delayed).concat(
      areDurationAndDelayCompatible(pret, 'La durée totale doit être supérieure au différé.')
    ),
    startingDate: [],
    firstRepaymentDate: areStartingAndFirstRepaymentDateCompatible(pret),
    inFine: [],
    computedPret: [],
  }
  const all = Object.values(fieldsValid)
    .map((field) => field.length === 0)
    .reduce((previous, current) => previous && current)
  return {
    all,
    ...fieldsValid,
  }
}

export const toPretInput: (pret: Pret) => UpdatePretInput = (pret: Pret) => {
  return removeTypeName({
    title: pret.title,
    capital: pret.capital,
    annualRate: pret.annualRate,
    yearlyPaymentNb: pret.yearlyPaymentNb,
    duration: pret.duration,
    delayed: pret.delayed,
    startingDate: pret.startingDate,
    firstRepaymentDate: pret.firstRepaymentDate,
    inFine: pret.inFine,
    demarragePlanFinancement: pret.demarragePlanFinancement,
    guarantees: pret.guarantees,
    externalized: pret.externalized,
    isInDemarrage: pret.isInDemarrage,
  })
}

export const useGetRessources = (noteId: string) => {
  const { data, loading } = useRessourcesQuery({ variables: { noteId } })
  return {
    loading,
    data: data
      ? {
          pretSynthese: data.ressources.pretSynthese,
          primes: data.ressources.primes,
          apports: data.ressources.apports,
          prets: data.ressources.prets.slice().sort((a: Pret, b: Pret) => +a.id - +b.id),
        }
      : undefined,
  }
}

export const useUpdatePret = (noteId: string, pretId: string) => {
  const [pretMutation] = useUpdatePretMutation({
    update: (cache) => {
      cache.evictQueries([
        {
          query: RessourcesDocument,
          variables: { noteId },
        },
        {
          query: FinancialPeriodsDocument,
          variables: { noteId },
        },
        {
          query: PlanFinancementDocument,
          variables: { noteId },
        },
        { query: RatiosDocument, variables: { noteId } },
        { query: ScoringCreationCheckDocument, variables: { noteId } },
      ])
    },
  })
  return (update: UpdatePretInput) => {
    return new Promise<Pret>((resolve, reject) => {
      pretMutation({
        variables: { pretId, update },
      })
        .then((response) => {
          const result = response.data && response.data.pret.patch
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}
