import React, {FC, useContext} from 'react'
import {CellDiv} from './PrimeLine'
import {FinanciaryContext} from '../Financiary'
import {Apport} from '@fa-metier/types'
import {NumberText} from '@fa-metier/components'
import {eurosSuffix} from '@fa-metier/components/dist/form/suffixes'
import {PeriodModel} from '../PeriodModel'
import {FlexDiv} from './PlanFinancement'
import {NoteContext} from "../../NotePage";

export const ApportsPersonnelsBlock: FC = () => {
  const {noteSettings} = useContext(NoteContext)
  const {previsionelPeriods, apports} = useContext(FinanciaryContext)
  return (
    <>
      <tr>
        <td className={'title'}>Apports</td>
        {noteSettings.showDemarrage && <td className={'demarrage'}/>}
        <td colSpan={previsionelPeriods.length}/>
        <td className={'action'}/>
      </tr>
      {apports.map((apport, index) => (
        <ApportLineEntry apport={apport} key={`apport-${index}`}/>
      ))}
    </>
  )
}

const ApportLineEntry: FC<{
  apport: Apport
}> = ({apport}) => {
  const {noteSettings} = useContext(NoteContext)
  const {previsionelPeriods} = useContext(FinanciaryContext)

  return (
    <tr className={'hoverable'}>
      <td>
        <span>{apport.label}</span>
      </td>
      {noteSettings.showDemarrage && (
        <td className={'demarrage'}>
          <CellDiv>
            <NumberText
              value={apport.montant.value ?? 0}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />
          </CellDiv>
        </td>
      )}
      {previsionelPeriods.map((period, index) => (
        <td key={`${apport.label}-${period.id}`}>
          {index === 0 && <ApportPeriodValue period={period} apport={apport}/>}
        </td>
      ))}
    </tr>
  )
}

const ApportPeriodValue: FC<{
  period: PeriodModel
  apport: Apport
}> = ({period, apport}) => {
  const {noteSettings} = useContext(NoteContext)
  const data = period.planFinancement.apportsPersonnels.find((p) => p.entryId === apport.label)
    ?.data
  return (
    <FlexDiv>
      <NumberText value={data?.value ?? 0} suffix={eurosSuffix(noteSettings.kiloEuros)}/>
    </FlexDiv>
  )
}
