import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { Guarantee, Pret } from '@fa-metier/types'
import { toPretInput, useUpdatePret, validatePret } from './PretsQueries'
import { useDebounce } from 'react-use'
import { Button, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core'
import { DIALOG_BODY, DIALOG_FOOTER } from '@blueprintjs/core/lib/esm/common/classes'
import styled from 'styled-components'
import { PlanAmortissementTable } from './PretPage'
import { PretCard } from './PretStyle'
import { PretSynthese } from './PretSynthese'
import { AppMetierNumberInput } from '../Utils/AppMetierInput/AppMetierNumberInput'
import { AppMetierDateInput, SERVER_DATE_FORMAT } from '../Utils/AppMetierInput/AppMetierDateInput'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import dayjs from 'dayjs'
import { getDayjsFormatter, stringToDate } from '../../../utils/DayjsFormatter'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { eurosSuffix, Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { AppMetierCheckbox } from '../Utils/AppMetierCheckBox'
import { EditIcon, NumberText } from '@fa-metier/components'
import { NoteContext } from '../../NotePage'
import { PretsContext } from './PretTabs'
import { saveShortcutListener, withFieldsGuard } from '../../../utils/utils'
import { PageSaveButton } from '../../../utils/PageSaveButton'
import { ParametersDiv } from '../../../utils/ParametersDiv'
import _ from 'lodash'
import { useUserDebounce } from '../../../Settings/Hooks'

export const DISPLAY_DATE_FORMAT = 'DD/MM/YYYY'

export const FormGroup20 = styled(FormGroup)`
  width: 21%;
  margin-right: 20px;

  .bp3-label {
    font-weight: var(--bold);
  }

  * .bp3-input,
  .bp3-popover-target,
  .bp3-input-group {
    flex-grow: 1;
  }
`

const PretFormDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const CustomComputedField = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 20em;

  div.label {
    font-weight: var(--bold);
  }

  div.content {
    line-height: 38px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      padding-left: 5px;
    }
  }
`

interface PretContextI {
  pret: Pret
  showComputations: boolean
  setPret: Dispatch<SetStateAction<Pret>>
}

const PretContext = React.createContext({} as PretContextI)

export const PretForm: FC<{ inputPret: Pret }> = ({ inputPret }) => {
  const { noteId, unsavedChanges, setUnsavedChanges, muffinSyncInProgress } =
    useContext(NoteContext)
  const { previsionelPeriods } = useContext(PretsContext)
  const [pret, setPret] = useState(inputPret)
  const [showEditTitlePopup, setShowEditTitlePopup] = useState<string | undefined>(undefined)
  const [totalDuration, setTotalDuration] = useState(pret.duration + pret.delayed)
  const updatePret = useUpdatePret(noteId, pret.id)

  const [showComputations, setShowComputations] = useState(false)
  const [computationInProgress, setComputationInProgress] = useState(false)

  const refs = useRef({
    inputPret,
    pret,
    unsavedChanges,
  })

  const valid = () => validatePret(refs.current.pret)

  const minDate =
    previsionelPeriods.length > 0 ? dayjs(previsionelPeriods[0].startDate).toDate() : undefined

  const minFirstRepaymentDate = pret.startingDate
    ? stringToDate(pret.startingDate, SERVER_DATE_FORMAT)
    : undefined

  const maxFirstRepaymentDate =
    minFirstRepaymentDate && pret.yearlyPaymentNb > 0
      ? dayjs(minFirstRepaymentDate)
          .add(12 / pret.yearlyPaymentNb, 'month')
          .toDate()
      : undefined

  const maxDate =
    previsionelPeriods.length > 0
      ? dayjs(previsionelPeriods[previsionelPeriods.length - 1].endDate).toDate()
      : undefined

  const userDebounce = useUserDebounce()

  const [, cancelDebounce] = useDebounce(
    async () => {
      if (valid().all && !_.isEqual(pret, refs.current.inputPret)) {
        await withFieldsGuard(() => updatePret(toPretInput(pret)), setComputationInProgress)
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [pret, toPretInput]
  )

  useEffect(() => {
    refs.current.pret = pret
    if (valid().all && !_.isEqual(pret, refs.current.inputPret)) {
      setUnsavedChanges(true)
    }
  }, [pret, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.inputPret, inputPret)) {
      refs.current.inputPret = inputPret
      setPret(inputPret)
      cancelDebounce()
    }
  }, [inputPret, cancelDebounce])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelDebounce()
      if (valid().all && !_.isEqual(refs.current.pret, refs.current.inputPret)) {
        await updatePret(toPretInput(refs.current.pret))
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setTotalDuration(pret.inFine ? pret.duration : pret.duration + pret.delayed)
  }, [pret.inFine, pret.delayed, pret.duration])

  return (
    <div>
      {pret && (
        <PretContext.Provider
          value={{
            pret,
            setPret,
            showComputations,
          }}
        >
          <ParametersDiv>
            <PageSaveButton
              buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
              buttonDisabled={computationInProgress || !unsavedChanges}
            />
            <AppMetierSwitch
              label={'Voir tous les calculs'}
              checked={showComputations}
              onChange={() => {
                setShowComputations(!showComputations)
              }}
            />
          </ParametersDiv>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ color: 'var(--main-green-1)' }}>{pret.title}</h2>
            <Button
              style={{ width: '24px', height: '24px', marginLeft: '10px' }}
              minimal={true}
              text={<EditIcon width={18} height={18} color={'var(--main-green-1)'} />}
              onClick={() => setShowEditTitlePopup(pret.title)}
            />
            <Dialog
              isOpen={!!showEditTitlePopup}
              title={`Modifier le titre de ${pret.title}`}
              isCloseButtonShown={false}
            >
              <div className={DIALOG_BODY}>
                <InputGroup
                  value={showEditTitlePopup}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                    setShowEditTitlePopup(ev.target.value)
                  }
                />
              </div>
              <div className={DIALOG_FOOTER}>
                <Button
                  style={{ marginRight: '0.5em' }}
                  onClick={() => setShowEditTitlePopup(undefined)}
                >
                  Annuler
                </Button>
                <Button
                  intent={'primary'}
                  onClick={() => {
                    setPret((pret) => ({ ...pret, title: showEditTitlePopup!! }))
                    setShowEditTitlePopup(undefined)
                  }}
                >
                  Valider
                </Button>
              </div>
            </Dialog>
          </div>
          <PretCard>
            <AppMetierCheckbox
              label={'In fine'}
              checked={pret.inFine}
              onChange={() => {
                setPret((pret) => ({
                  ...pret,
                  inFine: !pret.inFine,
                  delayed: !pret.inFine ? 0 : pret.delayed,
                }))
              }}
              style={{ width: 'fit-content' }}
              disabled={computationInProgress || muffinSyncInProgress}
            />
            <PretFormDiv>
              <div
                style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                key={'firstLineTr'}
              >
                <FormGroup20 label={'Montant du prêt'}>
                  <div>
                    <NumericDataInput
                      style={{ width: '100%' }}
                      numericData={pret.capital}
                      onChange={(value) => {
                        if (value?.value) {
                          setPret((pret) => ({ ...pret, capital: value }))
                        }
                      }}
                      showComputations={showComputations}
                      isCalculated={computationInProgress || muffinSyncInProgress}
                      isEuro={true}
                    />
                  </div>
                </FormGroup20>
                <FormGroup20 label={"Taux d'intérêt annuel"}>
                  <AppMetierNumberInput
                    value={pret.annualRate ? +(pret.annualRate * 100).toFixed(2) : 0}
                    onChange={(value) => {
                      if (value || value === 0) {
                        setPret((pret) => ({ ...pret, annualRate: value / 100 }))
                      }
                    }}
                    suffix={Suffixes.POURCENTAGE}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
                <FormGroup20
                  label={"Nombre d'échéances par an"}
                  intent={valid().yearlyPaymentNb.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().yearlyPaymentNb.join(' ')}
                >
                  <AppMetierNumberInput
                    value={pret.yearlyPaymentNb}
                    onChange={(valueAsNumber) => {
                      if (valueAsNumber || valueAsNumber === 0) {
                        setPret((pret) => ({ ...pret, yearlyPaymentNb: valueAsNumber }))
                      }
                    }}
                    intent={valid().yearlyPaymentNb ? Intent.NONE : Intent.DANGER}
                    defaultValue={12}
                    allowedValues={[0, 1, 2, 4, 12]}
                    onlyInteger={true}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
                <FormGroup20 label={'Date de décaissement'}>
                  <AppMetierDateInput
                    placeholder={DISPLAY_DATE_FORMAT}
                    value={pret.startingDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(selectedDate, isUserChange) => {
                      if (isUserChange) {
                        setPret((pret) => ({ ...pret, startingDate: selectedDate }))
                      }
                    }}
                    {...getDayjsFormatter(DISPLAY_DATE_FORMAT)}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                key={'secondLineTr'}
              >
                <FormGroup20
                  label={'Durée totale'}
                  intent={valid().duration.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().duration.join(' ')}
                >
                  <AppMetierNumberInput
                    value={totalDuration || 0}
                    onChange={(ev) => {
                      if (ev) {
                        if (pret.duration + pret.delayed !== ev) {
                          setTotalDuration(ev)
                          setPret((pret) => {
                            if (pret.delayed !== undefined) {
                              return { ...pret, duration: ev - pret.delayed }
                            }
                            if (pret.duration !== undefined) {
                              return { ...pret, delayed: ev - pret.duration }
                            }
                            return { ...pret, delayed: 0, duration: ev }
                          })
                        }
                      }
                    }}
                    suffix={Suffixes.MOIS}
                    onlyInteger={true}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
                <FormGroup20
                  label={'Différé'}
                  intent={valid().delayed.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().delayed.join(' ')}
                >
                  <AppMetierNumberInput
                    key={`delay${pret.inFine}${pret.id}`}
                    value={
                      pret.delayed !== undefined && pret.delayed >= 0
                        ? pret.inFine
                          ? 0
                          : pret.delayed
                        : 0
                    }
                    onChange={(ev) => {
                      const delayed = ev ?? 0
                      setPret((pret) => ({ ...pret, delayed }))
                      setTotalDuration(pret.duration + delayed)
                    }}
                    suffix={Suffixes.MOIS}
                    disabled={pret.inFine || computationInProgress || muffinSyncInProgress}
                    onlyInteger={true}
                  />
                </FormGroup20>
                <FormGroup20
                  label={'Durée du prêt hors différé'}
                  intent={valid().duration.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().duration.join(' ')}
                >
                  <AppMetierNumberInput
                    value={pret.duration || 0}
                    onChange={(ev) => {
                      if (ev) {
                        setPret((pret) => ({ ...pret, duration: ev }))
                        setTotalDuration(ev + pret.delayed)
                      }
                    }}
                    suffix={Suffixes.MOIS}
                    onlyInteger={true}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
                <FormGroup20
                  label={'Date de 1er remboursement'}
                  intent={valid().firstRepaymentDate.length === 0 ? Intent.NONE : Intent.DANGER}
                  helperText={valid().firstRepaymentDate.join(' ')}
                >
                  <AppMetierDateInput
                    placeholder={DISPLAY_DATE_FORMAT}
                    value={pret.firstRepaymentDate}
                    minDate={minFirstRepaymentDate}
                    maxDate={maxFirstRepaymentDate}
                    onChange={(selectedDate, isUserChange) => {
                      if (isUserChange) {
                        setPret((pret) => ({ ...pret, firstRepaymentDate: selectedDate }))
                      }
                    }}
                    {...getDayjsFormatter(DISPLAY_DATE_FORMAT)}
                    disabled={computationInProgress || muffinSyncInProgress}
                  />
                </FormGroup20>
              </div>
            </PretFormDiv>
            {pret.guarantees.map((g) => (
              <GuaranteeForm
                key={`guarantee-${g.id}`}
                guarantee={g}
                computationInProgress={computationInProgress}
                muffinSyncInProgress={muffinSyncInProgress}
              />
            ))}
          </PretCard>
          <br />
          {valid().all &&
            inputPret.computedPret &&
            inputPret.yearlyPaymentNb !== undefined &&
            inputPret.computedPret.tableauAmortissement.length >= 0 && (
              <PretSynthese pret={inputPret} />
            )}
          <br />
          {valid().all &&
            inputPret.computedPret &&
            inputPret.computedPret.tableauAmortissement.length > 0 && (
              <PlanAmortissementTable pret={inputPret} />
            )}
        </PretContext.Provider>
      )}
    </div>
  )
}

const GuaranteeForm: FC<{
  guarantee: Guarantee
  computationInProgress?: boolean
  muffinSyncInProgress?: boolean
}> = ({ guarantee, computationInProgress = false, muffinSyncInProgress = false }) => {
  const { pret, setPret } = useContext(PretContext)
  const { noteSettings } = useContext(NoteContext)

  const updateGuarantee = (ev: number, fieldName: 'duration' | 'quotite') => {
    setPret((pret) => ({
      ...pret,
      guarantees: pret.guarantees.map((g) =>
        g.id === guarantee.id ? { ...g, [fieldName]: ev } : g
      ),
    }))
  }

  const durationIsInvalid = guarantee.duration > pret.duration + pret.delayed

  return (
    <div>
      <span>{guarantee.title}</span>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }} key={'secondLineTr'}>
        <FormGroup20 label={'Quotité'}>
          <AppMetierNumberInput
            value={guarantee.quotite ? +(guarantee.quotite * 100).toFixed(2) : 0}
            onChange={(ev) => {
              updateGuarantee((ev ?? 0) / 100, 'quotite')
            }}
            suffix={Suffixes.POURCENTAGE}
            disabled={computationInProgress || muffinSyncInProgress}
          />
        </FormGroup20>
        <CustomComputedField>
          <div className={'label'}>Montant</div>
          <div className={'content'}>
            <div>
              <NumberText
                value={guarantee.quotite * (pret.capital.value ?? 0)}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            </div>
          </div>
        </CustomComputedField>
        <FormGroup20
          label={'Durée'}
          intent={durationIsInvalid ? 'danger' : 'none'}
          helperText={
            durationIsInvalid
              ? 'La durée de la garantie ne peut excéder la durée du prêt'
              : undefined
          }
        >
          <AppMetierNumberInput
            value={guarantee.duration}
            onChange={(ev) => {
              updateGuarantee(ev ?? 0, 'duration')
            }}
            suffix={Suffixes.MOIS}
            disabled={computationInProgress || muffinSyncInProgress}
          />
        </FormGroup20>
      </div>
    </div>
  )
}
