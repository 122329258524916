import styled from 'styled-components'
import { Checkbox } from '@blueprintjs/core'

export const AppMetierCheckbox = styled(Checkbox)`
  label.bp3-checkbox {
    width: fit-content;
  }
  input:checked ~ .bp3-control-indicator {
    background-color: var(--main-green-1) !important;
    box-shadow: none !important;
    :hover {
      background-color: var(--main-green-1);
      box-shadow: none;
    }
  }
  .bp3-control-indicator {
    background-image: none;
    background-color: white !important;
    box-shadow: 0 0 0 1px var(--main-grey-2), 0 -1px 0 var(--main-grey-2);
    :hover {
      background-image: none;
      background-color: white;
    }
  }
`
