import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FormGroup, Radio } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { PeriodModel } from '../PeriodModel'
import { updateInternalPeriods, updateInternalSituationIntermediaire } from '../Financiary'
import { useDebounce } from 'react-use'
import {
  CalculatedCellWithCalculDetail,
  CalculatedLine,
  CalculatedLineWithDetail,
  Line,
} from '../Utils/FinanciaryLine'
import { TotalLineGroup } from './TotalLineGroup'
import { incomeStatementToInput } from '../IncomeStatementMapper'
import { AmountReference, PeriodsContext, UpdatePeriodForPath } from '../Utils/PeriodsContext'
import {
  EmptyLine,
  EvolutionOrIntermediaireCell,
  EvolutionValue,
  FinancialTable,
  LabelSeparator,
  TableSeparation,
} from '../FinancialTable'
import { useGetPeriods, useUpdatePeriods } from '../FinanciaryQueries'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useUpdateNoteSettings } from '../../NoteSettingsQueries'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { useUpdateNote } from '../NoteQueries'
import { getField, removeTypeName } from '@fa-metier/commons'
import { eurosSuffix, Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PopoverInteractionKind } from '@blueprintjs/core/lib/esm/components/popover/popover'
import { FARadioGroup } from '@fa-metier/components/dist/form/FARadio'
import {
  CalculatedData,
  FinancialPeriodsSaveRequestMultipleInput,
  NoteType,
  PeriodType,
  Pret,
} from '@fa-metier/types'
import { NoteContext } from '../../NotePage'
import { ImpotsBeneficesLine } from './ImpotsBeneficesLine'
import { PrelevementTnsLine } from './PrelevementTnsLine'
import { AppMetierCheckbox } from '../Utils/AppMetierCheckBox'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { useGetRessources } from '../Prets/PretsQueries'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { CommentContainer, CommentTitle, Editor, makeOutputData } from '@fa-metier/components'
import { DEBOUNCE_CONFIG } from '../../../Settings/debounceConfig'
import { saveShortcutListener, withFieldsGuard } from '../../../utils/utils'
import { PageSaveButton } from '../../../utils/PageSaveButton'
import _ from 'lodash'
import PageTitle from '../../../utils/PageTitle'
import { ParametersDiv } from '../../../utils/ParametersDiv'
import { useUserDebounce } from '../../../Settings/Hooks'

export const NavLinkGreen = styled(NavLink)`
  color: var(--main-green-1);
`

const Bold = styled.span`
  font-weight: var(--bold);
`

export const SwitchContainer = styled.div`
  > .bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: var(--main-green-1);
  }

  font-weight: normal;
`

export const retrievePercentageText = (referenceAmount: string) => {
  if (referenceAmount === 'CHIFFRE_AFFAIRE') {
    return '% du CA'
  }
  return "% du total des produits d'exploitation"
}

const ExerciceHeader = styled.span`
  font-weight: var(--bold);
`

const EnteteTd = styled.td`
  font-style: normal;
  font-weight: var(--bold);
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--main-black-2);
  border-color: white;
  border-style: solid none;
`

const periodToMutationModel = (period: PeriodModel): FinancialPeriodsSaveRequestMultipleInput => ({
  financialPeriodId: period.id,
  input: {
    incomeStatement: incomeStatementToInput(period.incomeStatement),
    prelevementTns: removeTypeName(period.prelevementTns),
  },
})

const updateProfitTaxes = (periods: PeriodModel[]) =>
  periods.map((p) => ({
    ...p,
    incomeStatement: {
      ...p.incomeStatement,
      impotsSurBeneficesInput: {
        ...p.incomeStatement.impotsSurBeneficesInput,
        value: null,
        detail: null,
      },
    },
  }))

interface CRContextI {
  previsionelPeriods: PeriodModel[]
  anterieurPeriods: PeriodModel[]
  prets: Pret[]
  situationIntermediaire?: PeriodModel
  computationInProgress: boolean
  setComputationInProgress: Dispatch<SetStateAction<boolean>>
}

export const CRContext = createContext<CRContextI>({} as CRContextI)

export const CRFormWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)
  const { loading: ressourcesLoading, data: ressources } = useGetRessources(noteId)
  const [computationInProgress, setComputationInProgress] = useState(false)

  if (periodLoading || ressourcesLoading) {
    return <YodaSpinnerPage />
  }
  return (
    <CRContext.Provider
      value={{
        computationInProgress,
        setComputationInProgress,
        previsionelPeriods: periodsData.previsionnelles,
        anterieurPeriods: periodsData.anterieurs,
        prets: ressources!!.prets,
        situationIntermediaire: periodsData.situationIntermediaire,
      }}
    >
      <CRForm />
    </CRContext.Provider>
  )
}

const CRForm: FC = () => {
  const { noteId, noteSettings, note, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const {
    previsionelPeriods,
    anterieurPeriods,
    prets,
    situationIntermediaire,
    computationInProgress,
    setComputationInProgress,
  } = useContext(CRContext)
  const [internalPeriods, setInternalPeriods] = useState(previsionelPeriods)
  const [internalAnterieursPeriods, setInternalAnterieursPeriods] = useState(anterieurPeriods ?? [])
  const [internalSituationIntermediaire, setInternalSituationIntermediaire] =
    useState(situationIntermediaire)
  const updatePeriods = useUpdatePeriods(noteId)
  const updateSettings = useUpdateNoteSettings(noteId)
  const [showComputations, setShowComputations] = useState(false)
  const dictionnary = useDictionnary()

  const [showAnterieurs, setShowAnterieurs] = useState(
    note.type === NoteType.Reprise || note.type === NoteType.StructureExistante
  )
  const [tableDiv, setTableDiv] = useState<HTMLDivElement | undefined>(undefined)
  const [exercicesEntete, setExercicesEntete] = useState<HTMLDivElement | undefined>(undefined)
  const [separatorHeight, setSeparatorHeight] = useState(500)
  const [updateState, setUpdateState] = useState(0)
  const forceUpdate = () => setUpdateState(updateState + 1)

  const updateNote = useUpdateNote(noteId)

  const [commentCr, setCommentCr] = useState(makeOutputData(note.commentCr))
  const [commentCrAnterieur, setCommentCrAnterieur] = useState(
    makeOutputData(note.commentCrAnterieur)
  )

  const refs = useRef({
    previsionelPeriods,
    anterieurPeriods,
    situationIntermediaire,
    internalPeriods,
    internalAnterieursPeriods,
    internalSituationIntermediaire,
    unsavedChanges,
  })

  const showCommentCrAnterieur =
    (note.type === NoteType.Reprise || note.type === NoteType.StructureExistante) &&
    anterieurPeriods.length > 0

  const [upToDate, setUpToDate] = useState(true)
  const showEvolutionColumn =
    note.type === NoteType.Reprise && anterieurPeriods && anterieurPeriods.length > 0

  useDebounce(
    async () => {
      if (commentCr !== note.commentCr || commentCrAnterieur !== note.commentCrAnterieur) {
        await withFieldsGuard(
          () => updateNote({ noteId, commentCr, commentCrAnterieur }),
          setComputationInProgress
        )
      }
      setUnsavedChanges(false)
    },
    DEBOUNCE_CONFIG.delay.updateRequest,
    [commentCr, commentCrAnterieur]
  )

  const getPeriodUpdates = () => {
    const updatesPrevi = refs.current.internalPeriods.filter(
      (p, i) =>
        !_.isEqual(p.incomeStatement, refs.current.previsionelPeriods[i].incomeStatement) ||
        !_.isEqual(p.prelevementTns, refs.current.previsionelPeriods[i].prelevementTns)
    )
    const updatesAnte = refs.current.internalAnterieursPeriods.filter(
      (p, i) =>
        !_.isEqual(p.incomeStatement, refs.current.anterieurPeriods[i].incomeStatement) ||
        !_.isEqual(p.prelevementTns, refs.current.anterieurPeriods[i].prelevementTns)
    )
    const updateSituation =
      refs.current.internalSituationIntermediaire &&
      (!_.isEqual(
        refs.current.internalSituationIntermediaire?.caf,
        refs.current.situationIntermediaire?.caf
      ) ||
        !_.isEqual(
          refs.current.internalSituationIntermediaire?.incomeStatement,
          refs.current.situationIntermediaire?.incomeStatement
        ))
        ? [refs.current.internalSituationIntermediaire]
        : []

    return updatesAnte.concat(updatesPrevi).concat(updateSituation).map(periodToMutationModel)
  }

  const userDebounce = useUserDebounce()

  const [, cancelDebounceUpdatePeriods] = useDebounce(
    async () => {
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await withFieldsGuard(() => updatePeriods({ updates }), setComputationInProgress)
      }
      setUpToDate(true)
      setUnsavedChanges(false)
    },
    userDebounce,
    [internalPeriods, internalAnterieursPeriods, internalSituationIntermediaire]
  )

  useEffect(() => {
    if (commentCr !== note.commentCr || commentCrAnterieur !== note.commentCrAnterieur) {
      setUnsavedChanges(true)
    }
  }, [commentCr, commentCrAnterieur, setUnsavedChanges])

  useEffect(() => {
    refs.current.internalPeriods = internalPeriods
    refs.current.internalAnterieursPeriods = internalAnterieursPeriods
    refs.current.internalSituationIntermediaire = internalSituationIntermediaire
    const updates = getPeriodUpdates()
    if (updates.length > 0) {
      setUnsavedChanges(true)
    }
  }, [
    internalPeriods,
    internalAnterieursPeriods,
    internalSituationIntermediaire,
    setUnsavedChanges,
  ])

  useEffect(() => {
    if (!_.isEqual(refs.current.previsionelPeriods, previsionelPeriods)) {
      refs.current.previsionelPeriods = previsionelPeriods
      setInternalPeriods(previsionelPeriods)
      cancelDebounceUpdatePeriods()
    }

    if (!_.isEqual(refs.current.anterieurPeriods, anterieurPeriods)) {
      refs.current.anterieurPeriods = anterieurPeriods
      setInternalAnterieursPeriods(anterieurPeriods)
      cancelDebounceUpdatePeriods()
    }

    if (!_.isEqual(refs.current.situationIntermediaire, situationIntermediaire)) {
      refs.current.situationIntermediaire = situationIntermediaire
      setInternalSituationIntermediaire(situationIntermediaire)
      cancelDebounceUpdatePeriods()
    }
  }, [previsionelPeriods, anterieurPeriods, situationIntermediaire, cancelDebounceUpdatePeriods])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelDebounceUpdatePeriods()
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await updatePeriods({ updates })
      }
      setUpToDate(true)
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const computeHeight = () => {
    if (
      tableDiv?.clientHeight &&
      separatorHeight !== tableDiv.clientHeight - (exercicesEntete?.clientHeight ?? 0)
    ) {
      setSeparatorHeight(tableDiv.clientHeight - (exercicesEntete?.clientHeight ?? 0))
    }
  }

  const handleImpotsBenefices = () => {
    updateSettings({ impotsSurBeneficeComputed: !noteSettings.impotsSurBeneficeComputed })
    if (!noteSettings.impotsSurBeneficeComputed) {
      const newPeriods = updateProfitTaxes(internalPeriods)
      const updates = newPeriods.map(periodToMutationModel)
      updatePeriods({ updates }).then(() => setUpToDate(true))

      setInternalPeriods((internalPeriods) => updateProfitTaxes(internalPeriods))
    }
  }

  const handlePrelevementTns = () => {
    updateSettings({ prelevementTnsInCr: !noteSettings.prelevementTnsInCr })
  }

  useEffect(() => computeHeight)
  computeHeight()

  const referencePercentageIsDisplayed = () => {
    return (noteSettings.referenceAmount ?? 'NONE') !== 'NONE'
  }

  const showEvolutionCedant = () => noteSettings.showPercentageCedant ?? false

  const showEvolutionPrevisionnel = () => noteSettings.showPercentagePrevisionnel ?? false

  const [showMontantRefPopUp, setShowMontantRefPopUp] = useState(false)

  return (
    <div style={{ paddingBottom: '3%' }}>
      <PageTitle>{dictionnary.cr.pageTitle}</PageTitle>
      <PeriodsContext.Provider
        value={{
          showComputations,
          upToDate,
          anterieurPeriods,
          showAnterieurs,
          showEvolutionColumn,
          situationIntermediaire,
          periods: previsionelPeriods,
          updatePeriods: (updateRequests: UpdatePeriodForPath[]) => {
            setUpToDate(false)
            updateInternalPeriods(setInternalPeriods)(updateRequests)
          },
          updateAnterieurPeriods: (updateRequests: UpdatePeriodForPath[]) => {
            setUpToDate(false)
            updateInternalPeriods(setInternalAnterieursPeriods)(updateRequests)
          },
          reference: (noteSettings.referenceAmount ?? 'NONE') as AmountReference,
          hidePercentages: false,
          updateSituationIntermediaire: (updateRequests: UpdatePeriodForPath[]) => {
            setUpToDate(false)
            updateInternalSituationIntermediaire(setInternalSituationIntermediaire)(updateRequests)
          },
        }}
      >
        <div style={{ width: 'fit-content' }}>
          <ParametersDiv>
            <PageSaveButton
              buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
              buttonDisabled={computationInProgress || !unsavedChanges}
            />
            <AppMetierSwitch
              label={'Voir tous les calculs'}
              checked={showComputations}
              onChange={() => {
                setShowComputations(!showComputations)
              }}
            />
            <div style={{ width: 'fit-content', marginLeft: '20px' }}>
              <Popover2
                content={<MontantRefPopover />}
                interactionKind={PopoverInteractionKind.CLICK}
                position={'bottom'}
                minimal={true}
              >
                <div
                  onClick={() => setShowMontantRefPopUp(!showMontantRefPopUp)}
                  style={{ cursor: 'pointer' }}
                >
                  Affichage des % <FontAwesomeIcon icon={'chevron-down'} />
                </div>
              </Popover2>
            </div>
            {(note.type === NoteType.Reprise || note.type === NoteType.StructureExistante) &&
              anterieurPeriods?.length !== 0 && (
                <div
                  style={{
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '20px',
                  }}
                >
                  <AppMetierSwitch
                    label={`Afficher ${dictionnary.exerciceCedantLabel.toLowerCase()}`}
                    checked={showAnterieurs}
                    onChange={() => {
                      setShowAnterieurs(!showAnterieurs)
                    }}
                  />
                </div>
              )}
          </ParametersDiv>

          <div
            ref={(div) => {
              if (div) {
                setTableDiv(div)
              }
            }}
          >
            <FinancialTable
              lineBefore={false}
              showAnterieur={showAnterieurs}
              thead={
                <>
                  {(note.type === NoteType.Reprise ||
                    note.type === NoteType.StructureExistante) && (
                    <tr
                      ref={(tr) => {
                        if (tr) {
                          setExercicesEntete(tr)
                        }
                      }}
                    >
                      <th className={'section-title'} style={{ verticalAlign: 'bottom' }}>
                        &nbsp;
                      </th>
                      {showAnterieurs && (anterieurPeriods ?? []).length > 0 && (
                        <EnteteTd colSpan={anterieurPeriods!!.length} className={'anterieur'}>
                          {dictionnary.exerciceCedantLabel}
                        </EnteteTd>
                      )}
                      {showEvolutionColumn && (
                        <EnteteTd
                          colSpan={1}
                          className={'evolution noViolet'}
                          style={{ textAlign: 'center', padding: '5px 0' }}
                        >
                          Évolution
                        </EnteteTd>
                      )}
                      {situationIntermediaire && (
                        <EnteteTd className={'intermediaire noViolet'}>
                          Situation intermédiaire
                        </EnteteTd>
                      )}
                      <EnteteTd
                        colSpan={previsionelPeriods.length}
                        style={{ backgroundColor: 'white', borderRight: 'none' }}
                      >
                        Exercices prévisionnels
                      </EnteteTd>
                    </tr>
                  )}
                  <tr style={{ backgroundColor: 'var(--main-blue-2' }}>
                    <th className={'section-title'} style={{ verticalAlign: 'bottom' }}>
                      Produits d'exploitation
                      <LabelSeparator key={separatorHeight} height={separatorHeight} />
                    </th>

                    {showAnterieurs &&
                      anterieurPeriods.map((p, index) => (
                        <td key={p.id} className={'anterieurSeparation'}>
                          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                            <ExerciceHeader>{dictionnary.periodTitle(p, index)}</ExerciceHeader>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              color: 'grey',
                              fontSize: '0.7em',
                            }}
                          >
                            <div
                              style={{
                                flexBasis: '50%',
                              }}
                            >
                              Montant
                            </div>

                            {showEvolutionCedant() && index > 0 && (
                              <div
                                style={{
                                  alignItems: 'flex-end',
                                }}
                              >
                                {showEvolutionCedant() ? '% évolution' : ''}
                              </div>
                            )}
                            {referencePercentageIsDisplayed() && (
                              <div
                                style={{
                                  flexBasis: '20%',
                                }}
                              >
                                {retrievePercentageText(noteSettings.referenceAmount ?? 'NONE')}
                              </div>
                            )}
                          </div>
                        </td>
                      ))}
                    {showEvolutionColumn && (
                      <td
                        className={'evolution noViolet'}
                        style={{
                          backgroundColor: 'var(--main-blue-4)',
                          padding: '5px 15px',
                          fontSize: '11px',
                          textAlign: 'center',
                        }}
                      >
                        Évolution entre
                        <br />
                        <span style={{ fontWeight: 600 }}>
                          dernier exe cédant et exe n°1 prévi.
                        </span>
                      </td>
                    )}
                    {situationIntermediaire && (
                      <td key={situationIntermediaire.id} className={'intermediaire noViolet'}>
                        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                          <ExerciceHeader>
                            {situationIntermediaire.endDate.format('DD/MM/YYYY')}
                          </ExerciceHeader>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'start',
                            color: 'grey',
                            fontSize: '0.7em',
                            textAlign: 'start',
                          }}
                        >
                          <div
                            style={{
                              flexBasis: '50%',
                            }}
                          >
                            Montant
                          </div>
                        </div>
                      </td>
                    )}
                    {previsionelPeriods.map((p, i) => (
                      <td key={p.id}>
                        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                          <ExerciceHeader>{dictionnary.periodTitle(p, i)}</ExerciceHeader>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            color: 'grey',
                            fontSize: '0.7em',
                          }}
                        >
                          <div
                            style={{
                              flexBasis: '50%',
                            }}
                          >
                            Montant
                          </div>

                          {showEvolutionPrevisionnel() && i > 0 && (
                            <div
                              style={{
                                alignItems: 'flex-end',
                              }}
                            >
                              {showEvolutionPrevisionnel() ? '% évolution' : ''}
                            </div>
                          )}
                          {referencePercentageIsDisplayed() && (
                            <div
                              style={{
                                flexBasis: '20%',
                              }}
                            >
                              {retrievePercentageText(noteSettings.referenceAmount ?? 'NONE')}
                            </div>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                </>
              }
            >
              <EmptyLine />
              <TotalLineGroup
                className={'hoverable'}
                onChangeRowsNb={forceUpdate}
                label={"Chiffre d'affaires"}
                fieldName={'incomeStatement.chiffre_affaire'}
                isVariableDetailLines={true}
                detailLines={[
                  {
                    key: 'incomeStatement.chiffre_affaire.detailLines',
                  },
                ]}
                isCalculate={computationInProgress}
              />
              <Line
                label={"Subventions d'exploitation (hors aides aux postes)"}
                fieldName={'incomeStatement.subventions_exploitation'}
                isCalculate={computationInProgress}
              />
              <Line
                label={'Aides aux postes'}
                fieldName={'incomeStatement.aides_postes'}
                isCalculate={computationInProgress}
              />
              <TotalLineGroup
                className={'hoverable'}
                onChangeRowsNb={forceUpdate}
                label={"Autres produits d'exploitation"}
                fieldName={'incomeStatement.autres_produits_exploitation'}
                detailLines={[
                  {
                    label: 'Production stockée',
                    key: 'incomeStatement.autres_produits_exploitation.prodution_stockee',
                  },
                  {
                    label: 'Production immobilisée',
                    key: 'incomeStatement.autres_produits_exploitation.production_immobilisee',
                  },
                  {
                    label: 'Transfert de charges',
                    key: 'incomeStatement.autres_produits_exploitation.transfert_charges',
                  },
                  {
                    label: 'Reprises sur amortissements et provisions',
                    key: 'incomeStatement.autres_produits_exploitation.reprises_amortissement_provisions',
                  },
                  {
                    label: 'Adhésions',
                    key: 'incomeStatement.autres_produits_exploitation.adhesions',
                  },
                  {
                    label: "Autres produits d'exploitation",
                    key: 'incomeStatement.autres_produits_exploitation.autres_produits_exploitation',
                  },
                ]}
                isCalculate={computationInProgress}
              />
              <CalculatedLineWithDetail
                label={"Total produits d'exploitation"}
                fieldName={'incomeStatement.totalProduitsExploitation'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            </FinancialTable>

            <TableSeparation>
              <th className={'section-title'}>Charges d'exploitation</th>
              {showAnterieurs &&
                (anterieurPeriods ?? []).map((p) => <td key={`${p.id}charges-exploitation`} />)}
              <EvolutionOrIntermediaireCell
                showEvolutionColumn={showEvolutionColumn}
                situationIntermediaire={!!situationIntermediaire}
              />
              {previsionelPeriods.map((p) => (
                <td key={`${p.id}charges-exploitation`} />
              ))}
            </TableSeparation>

            <FinancialTable showAnterieur={showAnterieurs}>
              <TotalLineGroup
                className={'hoverable'}
                onChangeRowsNb={forceUpdate}
                label={'Achat marchandises et matières premières'}
                fieldName={'incomeStatement.achat_marchandises'}
                detailLines={[
                  {
                    label: 'Achat marchandises',
                    key: 'incomeStatement.achat_marchandises.achat_marchandises',
                  },
                  {
                    label: 'Achat matières premières',
                    key: 'incomeStatement.achat_marchandises.achat_matieres_premieres',
                  },
                  {
                    label: 'Variation de stock',
                    key: 'incomeStatement.achat_marchandises.variation_stock',
                  },
                ]}
                isCalculate={computationInProgress}
              />
              <TotalLineGroup
                className={'hoverable'}
                onChangeRowsNb={forceUpdate}
                label={'Autres achats et charges externes'}
                fieldName={'incomeStatement.autres_achats'}
                isVariableDetailLines={true}
                detailLines={[
                  {
                    label: 'Fournitures consommables',
                    key: 'incomeStatement.autres_achats.fournitures_consommables',
                  },
                  {
                    label: 'Charges externes',
                    key: 'incomeStatement.autres_achats.charges_externes',
                  },
                ]}
                hasChargesVariables={true}
                isCalculate={computationInProgress}
              />

              <TotalLineGroup
                className={'hoverable'}
                onChangeRowsNb={forceUpdate}
                label={'Impôts et taxes'}
                fieldName={'incomeStatement.taxes'}
                detailLines={[
                  {
                    label: 'Contribution économique territoriale (CFE & CVAE)',
                    key: 'incomeStatement.taxes.cfe_cvae',
                  },
                  { label: 'CSG CRDS non déductible', key: 'incomeStatement.taxes.csg_crds' },
                  {
                    label: "Taxe d'apprentissage",
                    key: 'incomeStatement.taxes.taxe_apprentissage',
                  },
                  {
                    label: 'Taxe formation professionnelle',
                    key: 'incomeStatement.taxes.taxe_formation_professionnelle',
                  },
                  { label: 'Taxes diverses', key: 'incomeStatement.taxes.taxes_diverses' },
                ]}
                isCalculate={computationInProgress}
              />
              <Line
                label={'Traitements et salaires bruts'}
                fieldName={'incomeStatement.traitementsSalaires'}
                isCalculate={computationInProgress}
              />
              <Line
                label={'Cotisations sociales patronales'}
                fieldName={'incomeStatement.cotisationsSocialesPatronales'}
                isCalculate={computationInProgress}
              />
              <CalculatedLineWithDetail
                label={'Taux de charges %'}
                fieldName={'incomeStatement.tauxDeChargesPatronales'}
                suffix={Suffixes.POURCENTAGE}
                showPourcentageReference={false}
              />
              <PrelevementTnsLine
                fieldPath={'prelevementTns'}
                noteSettings={noteSettings}
                handlePrelevementTns={handlePrelevementTns}
                isCalculate={computationInProgress}
              />
              <Line
                label={'Cotisations sociales des dirigeants'}
                fieldName={'incomeStatement.cotisationsSocialesDirigeants'}
                isCalculate={computationInProgress}
              />
              {noteSettings.prelevementTnsInCr && (
                <CalculatedLineWithDetail
                  label={'Taux de charges %'}
                  fieldName={'incomeStatement.tauxDeChargesDirigeants'}
                  suffix={Suffixes.POURCENTAGE}
                  showPourcentageReference={false}
                />
              )}
              <Line
                label={'Autres charges de personnel'}
                fieldName={'incomeStatement.autresChargesDePersonnel'}
                isCalculate={computationInProgress}
              />
              <Line
                label={'Dotations aux amortissements et provisions'}
                fieldName={'incomeStatement.dotationsAmortissementsProvisions'}
                isCalculate={computationInProgress}
              />
              <Line
                label={"Autres charges d'exploitation"}
                fieldName={'incomeStatement.autresChargesExploitation'}
                isCalculate={computationInProgress}
              />
              <CalculatedLineWithDetail
                label={"Total charges d'exploitation"}
                fieldName={'incomeStatement.totalChargesExploitations'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            </FinancialTable>

            <TableSeparation>
              <th className={'section-title'}>Résultat d'exploitation</th>
              {showAnterieurs &&
                anterieurPeriods.map((p) => <td key={`${p.id}resultat-exploitation`} />)}
              <EvolutionOrIntermediaireCell
                showEvolutionColumn={showEvolutionColumn}
                situationIntermediaire={!!situationIntermediaire}
              />
              {previsionelPeriods.map((p) => (
                <td key={`${p.id}resultat-exploitation`} />
              ))}
            </TableSeparation>
            <FinancialTable showAnterieur={showAnterieurs}>
              <CalculatedLineWithDetail
                label={"Résultat d'exploitation"}
                fieldName={'incomeStatement.resultatsExploitation'}
                isResult={true}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            </FinancialTable>

            <TableSeparation>
              <th className={'section-title'}>Résultat financier</th>
              {showAnterieurs &&
                anterieurPeriods.map((p) => <td key={`${p.id}resultats-financier`} />)}
              <EvolutionOrIntermediaireCell
                showEvolutionColumn={showEvolutionColumn}
                situationIntermediaire={!!situationIntermediaire}
              />
              {previsionelPeriods.map((p) => (
                <td key={`${p.id}resultats-financier`} />
              ))}
            </TableSeparation>

            <FinancialTable showAnterieur={showAnterieurs}>
              <TotalLineGroup
                onChangeRowsNb={forceUpdate}
                label={'Résultat financier'}
                fieldName={'incomeStatement.resultatFinancier'}
                className={'hoverable'}
                isSubtotal={true}
                detailLines={
                  note.type === NoteType.StructureExistante
                    ? [
                        {
                          label: 'Produits financiers (hors 786)',
                          key: 'incomeStatement.resultatFinancier.produitsFinanciers',
                        },
                        {
                          label: 'Reprises sur provisions financières (786)',
                          key: 'incomeStatement.resultatFinancier.reprisesSurProvisionsFinancieres',
                        },
                        {
                          label: 'Charges financières (hors 661 / 686)',
                          key: 'incomeStatement.resultatFinancier.chargesFinancieres',
                        },
                        {
                          label: 'Dotations aux provisions financières (686)',
                          key: 'incomeStatement.resultatFinancier.dotationsAuxProvisionsFinancieres',
                        },
                        {
                          label: "Charges d'intérêts passés (661)",
                          key: 'incomeStatement.resultatFinancier.chargesInteretsPasses',
                        },
                        {
                          label: "Charges d'intérêts futurs (661)",
                          key: 'incomeStatement.resultatFinancier.chargesInteretsFuturs',
                          showOnPeriodTypes: [PeriodType.Previsionnel],
                        },
                      ]
                    : [
                        {
                          label: 'Produits financiers (hors 786)',
                          key: 'incomeStatement.resultatFinancier.produitsFinanciers',
                        },
                        {
                          label: 'Reprises sur provisions financières (786)',
                          key: 'incomeStatement.resultatFinancier.reprisesSurProvisionsFinancieres',
                        },
                        {
                          label: 'Charges financières (hors 661 / 686)',
                          key: 'incomeStatement.resultatFinancier.chargesFinancieres',
                        },
                        {
                          label: 'Dotations aux provisions financières (686)',
                          key: 'incomeStatement.resultatFinancier.dotationsAuxProvisionsFinancieres',
                        },
                        {
                          label: "Charges d'intérêts (661)",
                          key: 'incomeStatement.resultatFinancier.chargeInterets',
                        },
                      ]
                }
                isCalculate={computationInProgress}
              />
            </FinancialTable>

            <TableSeparation>
              <th className={'section-title'}> Résultat exceptionnel</th>
              {showAnterieurs &&
                anterieurPeriods.map((p) => <td key={`${p.id}resultat-exceptionnel`} />)}
              <EvolutionOrIntermediaireCell
                showEvolutionColumn={showEvolutionColumn}
                situationIntermediaire={!!situationIntermediaire}
              />
              {previsionelPeriods.map((p) => (
                <td key={`${p.id}resultat-exceptionnel`} />
              ))}
            </TableSeparation>

            <FinancialTable showAnterieur={showAnterieurs}>
              <TotalLineGroup
                onChangeRowsNb={forceUpdate}
                label={'Résultat exceptionnel'}
                fieldName={'incomeStatement.resultatExceptionnel'}
                className={'subtotal hoverable'}
                isSubtotal={true}
                detailLines={[
                  {
                    label: 'Produits exceptionnels (hors 775 / 787 / 777)',
                    key: 'incomeStatement.resultatExceptionnel.produitsExceptionnels',
                  },
                  {
                    label: "Produits des cessions d'actifs (775)",
                    key: 'incomeStatement.resultatExceptionnel.produitsCessionsActifs',
                  },
                  {
                    label: 'Reprises sur provisions exceptionnelles (787)',
                    key: 'incomeStatement.resultatExceptionnel.reprisesSurProvisionsExceptionnelles',
                  },
                  {
                    label: 'Quote-part de subventions virée au résultat (777)',
                    key: 'incomeStatement.resultatExceptionnel.quotePartSubventionsVireesAuResultat',
                  },
                  {
                    label: 'Charges exceptionnelles (hors 675 / 687)',
                    key: 'incomeStatement.resultatExceptionnel.chargesExceptionnelles',
                  },
                  {
                    label: 'Dotations aux provisions exceptionnelles (687)',
                    key: 'incomeStatement.resultatExceptionnel.dotationsAuxProvisionsExceptionnelles',
                  },
                  {
                    label: "Valeurs comptables des cessions d'actifs (675)",
                    key: 'incomeStatement.resultatExceptionnel.valeursComptablesDesCessionsDactifs',
                  },
                ]}
                isCalculate={computationInProgress}
              />
            </FinancialTable>

            <TableSeparation>
              <th />
              {showAnterieurs && anterieurPeriods.map((p) => <td key={`${p.id}autres`} />)}
              <EvolutionOrIntermediaireCell
                showEvolutionColumn={showEvolutionColumn}
                situationIntermediaire={!!situationIntermediaire}
              />
              {previsionelPeriods.map((p) => (
                <td key={`${p.id}autres`} />
              ))}
            </TableSeparation>

            <FinancialTable showAnterieur={showAnterieurs}>
              <Line
                label={'Participation et intéressement'}
                fieldName={'incomeStatement.participationInteressement'}
                isCalculate={computationInProgress}
              />
              <Line
                label={'Report de ressources non utilisées des exercices antérieurs'}
                isCalculate={computationInProgress}
                fieldName={'incomeStatement.reportRessourcesNonUtilisees'}
              />
              <Line
                label={'Engagement à réaliser sur ressources affectées'}
                fieldName={'incomeStatement.engagementARealiserRessourcesAffectees'}
                isCalculate={computationInProgress}
              />
              <ImpotsBeneficesLine
                noteSettings={noteSettings}
                handleImpotsBenefices={handleImpotsBenefices}
              />
            </FinancialTable>

            <TableSeparation>
              <th className={'section-title'}>Résultat net</th>
              {showAnterieurs && anterieurPeriods.map((p) => <td key={`${p.id}resultat-net`} />)}
              <EvolutionOrIntermediaireCell
                showEvolutionColumn={showEvolutionColumn}
                situationIntermediaire={!!situationIntermediaire}
              />
              {previsionelPeriods.map((p) => (
                <td key={`${p.id}resultat-net`} />
              ))}
            </TableSeparation>

            <FinancialTable showAnterieur={showAnterieurs}>
              <CalculatedLineWithDetail
                label={'Résultat net'}
                fieldName={'incomeStatement.resultatNet'}
                isResult={true}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
              <CalculatedLineWithDetail
                label={
                  <>
                    <Bold>Charges fixes</Bold>{' '}
                  </>
                }
                fieldName={'incomeStatement.totalChargesFixes'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
              <RemboursementsEmpruntsLine />

              <CalculatedLineWithDetail
                label={
                  <>
                    <Bold>Besoins fixes</Bold>{' '}
                  </>
                }
                fieldName={'besoinsFixes'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />

              <CalculatedLineWithDetail
                label={
                  <>
                    <Bold>Charges variables</Bold>{' '}
                  </>
                }
                fieldName={'incomeStatement.totalChargesVariables'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />

              <CalculatedLineWithDetail
                label={
                  <>
                    <Bold>Taux de charges variables</Bold>{' '}
                  </>
                }
                fieldName={'incomeStatement.tauxChargesVariables'}
                suffix={Suffixes.POURCENTAGE}
              />

              <CalculatedLineWithDetail
                label={
                  <>
                    <Bold>Point mort</Bold>{' '}
                    <span style={{ fontSize: '0.8em' }}>
                      {prets.length === 0
                        ? '(Attention, ce calcul du point mort n’inclut aucun prêt pour l’instant.)'
                        : ''}
                    </span>
                  </>
                }
                fieldName={'pointMort'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />

              <CalculatedLineWithDetail
                label={'Marge de manœuvre'}
                fieldName={'margeDeManoeuvre'}
                suffix={Suffixes.POURCENTAGE}
              />
            </FinancialTable>

            <TableSeparation>
              <th className={'section-title'}>SIG</th>
              {showAnterieurs && anterieurPeriods.map((p) => <td key={`${p.id}_SIG`} />)}
              <EvolutionOrIntermediaireCell
                showEvolutionColumn={showEvolutionColumn}
                situationIntermediaire={!!situationIntermediaire}
              />
              {previsionelPeriods.map((p) => (
                <td key={`${p.id}_SIG`} />
              ))}
            </TableSeparation>

            <FinancialTable showAnterieur={showAnterieurs}>
              <CalculatedLine
                label={"Chiffre d'affaires"}
                fieldName={'incomeStatement.chiffre_affaire.total'}
                percentageFieldName={'incomeStatement.chiffre_affaire.total.percentageReference'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                noBackground={true}
              />
              <CalculatedLineWithDetail
                label={"Production de l'exercice"}
                fieldName={'incomeStatement.productionExercice'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
              <CalculatedLineWithDetail
                label={'Marge globale'}
                fieldName={'incomeStatement.margeGlobale'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
              <CalculatedLineWithDetail
                label={'Valeur ajoutée'}
                fieldName={'incomeStatement.valeurAjoutee'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
              <CalculatedLineWithDetail
                label={"Excédent brut d'exploitation"}
                fieldName={'incomeStatement.excedentBrutExploitation'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
              <CalculatedLineWithDetail
                label={'Résultat d’exploitation'}
                fieldName={'incomeStatement.resultatsExploitation'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
              <CalculatedLine
                label={'Résultat financier'}
                fieldName={'incomeStatement.resultatFinancier.total'}
                percentageFieldName={'incomeStatement.resultatFinancier.total.percentageReference'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                noBackground={true}
              />
              <CalculatedLine
                label={'Résultat exceptionnel'}
                fieldName={'incomeStatement.resultatExceptionnel.total'}
                percentageFieldName={
                  'incomeStatement.resultatExceptionnel.total.percentageReference'
                }
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                noBackground={true}
              />
              <CalculatedLine
                label={'Résultat net'}
                fieldName={'incomeStatement.resultatNet.numericData'}
                percentageFieldName={'incomeStatement.resultatNet.numericData.percentageReference'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                noBackground={true}
              />
            </FinancialTable>
          </div>
          <CommentContainer>
            {showCommentCrAnterieur && (
              <>
                <CommentTitle>
                  <span>{dictionnary.commentAnterieurLabel}</span>
                </CommentTitle>
                <Editor
                  id={'commentCrAnterieur'}
                  placeHolder={'Vous pouvez saisir un commentaire'}
                  initialValue={commentCrAnterieur}
                  onChange={(v) => setCommentCrAnterieur(v)}
                />
              </>
            )}
            <CommentTitle>
              <span>Commentaire - Compte de résultat prévisionnel</span>
            </CommentTitle>
            <Editor
              id={'commentCr'}
              placeHolder={'Vous pouvez saisir un commentaire'}
              initialValue={commentCr}
              onChange={(v) => setCommentCr(v)}
            />
          </CommentContainer>
        </div>
      </PeriodsContext.Provider>
    </div>
  )
}

const MontantRefPopover: FC = () => {
  const { note, noteId, noteSettings } = useContext(NoteContext)
  const updateSettings = useUpdateNoteSettings(noteId)
  const { evolutionPercentagePassedLabel } = useDictionnary()
  const referenceAmount = [
    {
      id: 'NONE',
      label: 'Aucun',
    },
    {
      id: 'CHIFFRE_AFFAIRE',
      label: "En pourcentage du Chiffre d'affaires",
    },
    {
      id: 'PRODUITS_EXPLOITATION',
      label: "En pourcentage du Total des produits d'exploitation",
    },
  ]

  const refAmount = noteSettings.referenceAmount

  return (
    <MontantRefPopoverContent>
      <div>
        <FARadioGroup
          label="Montant de référence"
          onChange={async (e) => {
            await updateSettings({
              referenceAmount: (e.target as HTMLInputElement).value,
            })
          }}
          selectedValue={refAmount}
        >
          {referenceAmount.map((p) => (
            <Radio key={p.id} label={p.label} value={p.id} />
          ))}
        </FARadioGroup>
        <FormGroup label={'Évolution'}>
          {note.type !== NoteType.Creation && (
            <AppMetierCheckbox
              checked={noteSettings.showPercentageCedant}
              label={evolutionPercentagePassedLabel}
              onChange={async (e) => {
                await updateSettings({
                  showPercentageCedant: (e.target as HTMLInputElement).checked,
                })
              }}
            />
          )}
          <AppMetierCheckbox
            checked={noteSettings.showPercentagePrevisionnel}
            label={"% d'évolution prévisionnel"}
            onChange={async (e) => {
              await updateSettings({
                showPercentagePrevisionnel: (e.target as HTMLInputElement).checked,
              })
            }}
          />
        </FormGroup>
      </div>
    </MontantRefPopoverContent>
  )
}

const MontantRefPopoverContent = styled.div`
  width: 370px;
  padding: 20px;
`

const RemboursementsEmpruntsLine: FC = () => {
  const { periods, anterieurPeriods, showAnterieurs, showEvolutionColumn, situationIntermediaire } =
    useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)

  const label = 'Remboursements des emprunts'
  const fieldName = 'remboursementsEmprunts.total'
  const suffix = eurosSuffix(noteSettings.kiloEuros)

  const numData = getField<CalculatedData>(fieldName, periods[0]).numericData
  return (
    <tr className={'subtotal hoverable'} style={{ fontWeight: 600 }}>
      <th className={'outlined'}>
        <div style={{ marginLeft: 'Opx', fontWeight: 'normal' }}>{label}</div>
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p, index) => {
          if (index === 0) {
            return (
              <td key={`calculateField-${p.id}-${Math.random()}`}>
                <div>
                  <span style={{ paddingLeft: '10px', paddingRight: '30px', minWidth: '100px' }}>
                    -
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  &nbsp;
                </div>
              </td>
            )
          }

          return (
            <CalculatedCellWithCalculDetail
              key={`${fieldName}_${p.id}`}
              fieldName={fieldName}
              period={p}
              suffix={suffix}
              showEvolution={noteSettings.showPercentageCedant && index > 0}
              isResult={false}
              showDescription={(value: number) => value <= 0}
              optionalDescription={
                'Le montant des dividendes est égal à 0 si le résultat du calcul ci-dessus est négatif'
              }
            />
          )
        })}
      {showEvolutionColumn && (
        <td className={'evolutionSize'}>
          <EvolutionValue
            value={numData !== undefined ? numData.percentageEvolution : undefined}
            color={'violet'}
          />
        </td>
      )}
      {situationIntermediaire && (
        <CalculatedCellWithCalculDetail
          key={`${fieldName}_${situationIntermediaire.id}`}
          fieldName={fieldName}
          period={situationIntermediaire}
          suffix={suffix}
          showEvolution={noteSettings.showPercentagePrevisionnel}
          isResult={false}
          showDescription={(value: number) => value <= 0}
          optionalDescription={
            'Le montant des dividendes est égal à 0 si le résultat du calcul ci-dessus est négatif'
          }
        />
      )}
      {periods.map((p, index) => (
        <CalculatedCellWithCalculDetail
          key={`${fieldName}_${p.id}`}
          fieldName={fieldName}
          period={p}
          suffix={suffix}
          showEvolution={noteSettings.showPercentagePrevisionnel && index > 0}
          isResult={false}
        />
      ))}
    </tr>
  )
}
