import React, { FC, useContext } from 'react'
import { NoteSettings, NoteType, NumericData } from '@fa-metier/types'
import { PeriodsContext } from '../Utils/PeriodsContext'
import { Dots } from '../FinancialTable'
import { Button, Classes, Dialog, Intent, Switch } from '@blueprintjs/core'
import { PeriodFinanciaryCell } from '../Utils/FinanciaryLine'
import { SwitchContainer } from './CRForm'
import { NoteContext } from '../../NotePage'

export const PrelevementTnsLine: FC<{
  fieldPath: string
  isCalculate?: boolean
  cellClassName?: string
  isEuro?: boolean
  displayPercentageReference?: boolean
  noteSettings: NoteSettings
  handlePrelevementTns: () => any
}> = ({
  fieldPath,
  isCalculate = false,
  cellClassName = '',
  isEuro = true,
  displayPercentageReference = true,
  noteSettings,
  handlePrelevementTns,
}) => {
  const {
    showAnterieurs,
    periods,
    anterieurPeriods,
    updatePeriods,
    updateAnterieurPeriods,
    showComputations,
    showEvolutionColumn,
    situationIntermediaire,
    updateSituationIntermediaire,
  } = useContext(PeriodsContext)
  const { note } = useContext(NoteContext)

  const [openDialog, setOpenDialog] = React.useState(false)

  const updateField = (periodId: string, n?: NumericData) => {
    updatePeriods([{ periodId, fieldPath, change: n }])
  }

  const updateAnterieurField = (periodId: string, n?: NumericData) => {
    if (updateAnterieurPeriods !== undefined) {
      updateAnterieurPeriods!!([{ periodId, fieldPath, change: n }])
    }
  }

  const updateSituationField = (periodId: string, n?: NumericData) => {
    if (updateSituationIntermediaire) {
      updateSituationIntermediaire([{ periodId, fieldPath, change: n }])
    }
  }

  const dialogTextNotInCr = (
    <>
      <p>
        Le prélèvement du TNS ne sera plus pris en compte dans le CR prévisionnel et ne sera plus
        intégré dans les calculs intermédiaires, y compris la CAF.
      </p>
      <p>
        Il sera intégré comme un besoin dans le <b>plan de financement</b> pour que le solde reste
        juste. Vous pouvez toujours modifier le montant.
      </p>

      <p>
        Cette opération est réversible : vous pourrez le réintégrer dans le CR prévisionnel à tout
        moment.
      </p>
    </>
  )

  const dialogTextInCr = (
    <>
      <p>Le prélèvement du TNS ne sera plus intégré comme un besoin du plan de financement.</p>
      <p>
        Il sera remis dans le <b>CR prévisionnel</b> et réintégré dans les calculs intermédiaires, y
        compris la CAF. Vous pouvez toujours modifier le montant.
      </p>

      <p>
        Cette opération est réversible : vous pourrez le réintégrer dans le plan de financement à
        tout moment.
      </p>
    </>
  )

  return (
    <tr className={'hoverable'}>
      <th style={{ display: 'flex', alignItems: 'baseline' }}>
        <div>{'Prélèvement TNS'}</div>
        <Dots />
        <SwitchContainer>
          <Switch
            label={'Dans le CR prévisionnel'}
            defaultChecked={noteSettings.prelevementTnsInCr ?? true}
            onChange={() => setOpenDialog(true)}
            disabled={isCalculate}
          />
        </SwitchContainer>
        <Dialog isOpen={openDialog} title={'Attention'} isCloseButtonShown={false}>
          <div className={Classes.DIALOG_BODY}>
            <div>{noteSettings.prelevementTnsInCr ? dialogTextNotInCr : dialogTextInCr}</div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER}>
              <Button style={{ marginRight: '0.5em' }} onClick={() => setOpenDialog(false)}>
                Annuler
              </Button>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => {
                  handlePrelevementTns()
                  setOpenDialog(false)
                }}
              >
                Confirmer
              </Button>
            </div>
          </div>
        </Dialog>
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p, index) => {
          return (
            <PeriodFinanciaryCell
              key={`PeriodFinanciaryCell_${fieldPath}_${p.id}`}
              fieldPath={fieldPath}
              period={p}
              isEuro={isEuro}
              displayPercentageReference={displayPercentageReference}
              isCalculate={isCalculate}
              showComputations={showComputations}
              updateField={updateAnterieurField}
              cellClassName={'anterieur'}
              showPercentageEvolution={noteSettings.showPercentageCedant && index > 0}
            />
          )
        })}
      {showEvolutionColumn && <td className={'evolution'}>&nbsp;</td>}
      {noteSettings.prelevementTnsInCr !== undefined && !noteSettings.prelevementTnsInCr ? (
        <td colSpan={periods.length}>
          {' '}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Le prélèvement TNS est pris en compte comme un besoin du plan de financement.
          </div>
        </td>
      ) : (
        <>
          {situationIntermediaire && (
            <PeriodFinanciaryCell
              key={`PeriodFinanciaryCell_${fieldPath}_${situationIntermediaire.id}`}
              fieldPath={fieldPath}
              period={situationIntermediaire}
              isEuro={isEuro}
              displayPercentageReference={displayPercentageReference}
              isCalculate={isCalculate}
              showComputations={showComputations}
              updateField={updateSituationField}
              cellClassName={cellClassName}
              showPercentageEvolution={false}
            />
          )}
          {periods.map((p, index) => {
            return (
              <PeriodFinanciaryCell
                key={`PeriodFinanciaryCell_${fieldPath}_${p.id}`}
                fieldPath={fieldPath}
                period={p}
                isEuro={isEuro}
                displayPercentageReference={displayPercentageReference}
                isCalculate={isCalculate}
                showComputations={showComputations}
                updateField={updateField}
                cellClassName={cellClassName}
                showPercentageEvolution={
                  noteSettings.showPercentagePrevisionnel &&
                  (index > 0 || note.type === NoteType.StructureExistante)
                }
              />
            )
          })}
        </>
      )}
    </tr>
  )
}
