import React, { FC, useContext } from 'react'
import { Divider } from '@blueprintjs/core'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { NumberText } from '@fa-metier/components'
import { NoteContext } from '../../NotePage'
import { CalculatedData, CalculLink, PeriodType, Units } from '@fa-metier/types'
import { eurosSuffix, Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { NoteDictionnary, useDictionnary } from '../../dictionnary/dictionnary'

const ScrollableContainer = styled.div`
  max-height: 800px;
  overflow-y: auto;
`

const Container = styled.table`
  padding: 20px;
  margin: 20px;
  border-collapse: collapse;
`

const CustomDivider = styled(Divider)`
  margin-right: -5px;
`

const TitleCell = styled.th`
  color: var(--main-black-2);
  font-weight: var(--bold);
`

const TotalCell = styled.td`
  font-size: 14px;
  line-height: 22px;
  color: white;
  font-weight: var(--bold);
  padding: 10px;
  background-color: var(--main-blue-1);
  height: 100%;
  margin-top: 20px;
`

const IconCell = styled.td`
  line-height: 36px;
  text-align: end;
  padding: 0 10px;
`
const IconLink = styled(Link)`
  color: var(--main-grey-1);

  :hover {
    color: var(--main-grey-1);
  }
`

const Cell = styled.td`
  padding: 5px 10px;
  font-size: 16px;
  line-height: 26px;
  color: var(--main-grey-1);
  font-weight: 400;
  min-height: 36px;
`

const DescriptionCell = styled(Cell)`
  font-size: 12px;
  font-style: italic;
`

const LabelCell = styled(Cell)`
  flex-grow: 8;
`

export interface CalculLine {
  label: string
  value: JSX.Element
  unit?: string
  link?: string
}

const linkToString = (link: CalculLink, dictionnary: NoteDictionnary, periodType?: PeriodType) => {
  switch (link) {
    case CalculLink.Cr:
      return 'cr'
    case CalculLink.Bfr:
      return 'bfr'
    case CalculLink.Caf:
      return 'caf'
    case CalculLink.Pf:
      return 'planFinancement'
    case CalculLink.Tva:
      return dictionnary.noteSettings.tvaTabLink(periodType)
    case CalculLink.Prets:
      return 'prets'
    case CalculLink.None:
      return null
    case CalculLink.Bilancedant:
      return 'bilanCedant'
    case CalculLink.PretsPasses:
      return 'pretspasses'
  }
}

const unitToString = (unit: Units, isKiloEuros: boolean) => {
  switch (unit) {
    case Units.Euros:
    case Units.KEuros:
      return eurosSuffix(isKiloEuros)
    case Units.Jours:
      return Suffixes.JOURS
    case Units.Month:
      return Suffixes.MOIS
    case Units.None:
      return ''
  }
}

export const FormulaPopoverContent: FC<{
  calculatedData: CalculatedData
  suffix: string
  showDescription?: (value: number) => boolean
  optionalDescription?: string
  periodType?: PeriodType
}> = ({
  calculatedData,
  suffix,
  showDescription = () => false,
  optionalDescription = '',
  periodType,
}) => {
  const { baseNotePath, noteSettings } = useContext(NoteContext)
  const dictionnary = useDictionnary()
  const description = calculatedData.detail.map((d) => {
    return {
      label: d.labelDetail,
      value: d.valuedDetail,
      unit: unitToString(d.unit, noteSettings.kiloEuros),
      link: linkToString(d.link, dictionnary, periodType),
    }
  })

  const hasLinks = description.filter((desc) => desc.link).length > 0

  const colspan = 3 + (hasLinks ? 1 : 0)

  const value = calculatedData.numericData?.value ?? 0.0
  const totalValue = suffix === Suffixes.POURCENTAGE ? value * 100 : value

  return (
    <ScrollableContainer>
      <Container>
        <thead>
          <tr>
            <TitleCell style={{ flexGrow: 8 }}>Le calcul</TitleCell>
            <TitleCell style={{ minWidth: '150px' }}>Votre saisie</TitleCell>
            <TitleCell style={{ minWidth: '45px' }}>Unité</TitleCell>
            {hasLinks && (
              <TitleCell style={{ minWidth: '75px', textAlign: 'end' }}>Modifier</TitleCell>
            )}
          </tr>
          <tr>
            <Cell colSpan={3 + (hasLinks ? 1 : 0)}>
              <CustomDivider />
            </Cell>
          </tr>
        </thead>

        <tbody>
          {description.map((desc) => (
            <tr key={desc.label}>
              <LabelCell key={`${desc.label}_${desc.value}_label`}>{desc.label}</LabelCell>
              <Cell style={{ flexGrow: 2 }} key={`${desc.label}_${desc.value}_value`}>
                {desc.value}
              </Cell>
              <Cell key={`${desc.label}_${desc.value}_unit`} style={{ textAlign: 'end' }}>
                {desc.unit}
              </Cell>
              {hasLinks && (
                <>
                  {desc.link && (
                    <IconCell key={`${desc.label}_${desc.value}_link`}>
                      <IconLink to={`${baseNotePath}/financiary/${desc.link}`}>
                        <FontAwesomeIcon icon={['fas', 'edit']} />
                      </IconLink>
                    </IconCell>
                  )}
                  {!desc.link && <IconCell style={{ minHeight: '36px' }} />}
                </>
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {showDescription(calculatedData.numericData.value ?? 0) && (
            <tr>
              <DescriptionCell colSpan={colspan}>{optionalDescription}</DescriptionCell>
            </tr>
          )}
          <tr>
            <TotalCell>Total</TotalCell>
            <TotalCell>
              = <NumberText value={totalValue} />
            </TotalCell>
            <TotalCell style={{ textAlign: 'end' }}>{suffix}</TotalCell>
            {hasLinks && <TotalCell />}
          </tr>
        </tfoot>
      </Container>
    </ScrollableContainer>
  )
}
