import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { AppMetierTable } from '@fa-metier/components/dist/Table/AppMetierTable'
import { NumericDataInput } from '../Utils/NumericDataInput/NumericDataInput'
import {
  CommentContainer,
  CommentTitle,
  Editor,
  makeOutputData,
  NumberText,
} from '@fa-metier/components'
import {
  CalculatedData,
  FinancialPeriodsSaveRequestMultipleInput,
  NoteType,
  NumericData,
  PeriodPlanFinancement,
  PlanFinancement,
  PlanFinancementEntryType,
  Pret,
  PretType,
} from '@fa-metier/types'
import styled from 'styled-components'
import { PeriodModel } from '../PeriodModel'
import { useDebounce } from 'react-use'
import { useUpdatePeriods } from '../FinanciaryQueries'
import { arraysEqual, saveShortcutListener, withFieldsGuard } from '../../../utils/utils'
import { useAddPlanFinancementEntry, useUpdatePlanFinancement } from './PlanFinancementQueries'
import { Button, FormGroup, Intent } from '@blueprintjs/core'
import { getField, removeTypeName } from '@fa-metier/commons'
import _ from 'lodash'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { useUpdateNoteSettings } from '../../NoteSettingsQueries'
import { PrelevementTnsLine } from './PrelevementTnsLines'
import { DemarrageLine } from './DemarrageLine'
import { EditableEntryLine } from './EditableEntryLine'
import { RessourceLines } from './RessourceLines'
import { AddNewEntryLine } from './AddNewEntryLine'
import { PretBlock } from './PretLine'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './PlanFinancement.scss'
import { eurosSuffix } from '@fa-metier/components/dist/form/suffixes'
import { PrimeBlock } from './PrimeLine'
import { ApportsPersonnelsBlock } from './ApportsPersonnelsBlock'
import { NoteContext } from '../../NotePage'
import { PFContext } from './PlanFinancementContainer'
import { FormulaDescriptionPopover } from '../Formulas/FormulaDescriptionPopover'
import { FormulaPopoverContent } from '../Formulas/FormulaPopover'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { Tooltip2 } from '@blueprintjs/popover2'
import { PageSaveButton } from '../../../utils/PageSaveButton'
import { ParametersDiv } from '../../../utils/ParametersDiv'
import { useUserDebounce } from '../../../Settings/Hooks'

interface PlanFinancementContextI {
  showComputations: boolean
  updateEntryLabel: (entryId: string, label: string) => void
  updateEntryDemarrage: (entryId: string, demarrage: NumericData) => void
  updateDemarrage: (fieldName: string, demarrage: NumericData) => void
  updatePeriod: (period: PeriodModel) => void
  editablePrevisionnelPeriods: PeriodModel[]
  resetDebounceTimers: () => void
  computationInProgress: boolean
  setComputationInProgress: Dispatch<SetStateAction<boolean>>
}

export const PlanFinancementContext = React.createContext<PlanFinancementContextI>(
  {} as PlanFinancementContextI
)

export const PlanFinancementTable = styled(AppMetierTable)`
  th {
    position: relative;
  }

  tr.demarrage td {
    height: 40px;
  }

  tr th:not(:first-child):not(:last-child) {
    min-width: 310px;
  }

  tr td:not(:first-child):not(:last-child) {
    min-width: 12em;
  }

  .bp3-form-group {
    width: auto;
  }

  .pretfield {
    max-width: 16%;
  }

  input {
    min-width: 5em;
    max-width: 12em;
  }

  td.calculated * {
    padding-left: 5px;
  }

  td.horsPF * {
    padding-left: 12px;
  }

  td {
    button {
      justify-content: flex-start;
    }
  }

  tr.bottom-border {
    border-bottom: 1px solid white;
  }

  th.demarrage {
    background-color: var(--main-green-2);
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  }

  tr.hoverable:hover td.demarrage {
    background-color: var(--main-green-3);
  }

  td:not(.pret-detail) {
    input {
      min-width: 100%;
    }
  }

  td.demarrage {
    background-color: var(--main-green-2);
    position: relative;
    color: var(--main-black-2);

    :after {
      content: ' ';
      height: 100%;
      position: absolute;
      top: 0;
      width: 15px;
    }

    :after {
      box-shadow: -15px 0 7px -15px inset rgba(0, 0, 0, 0.4);
      right: 0;
    }
  }

  tr.subtotal td.demarrage {
    background-color: inherit;
  }

  tr.total td.demarrage {
    color: white;
    background-color: inherit;
  }
`

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  span {
    padding-left: 12px;
  }
`

const ButtonToggle = styled(Button)`
  position: absolute;
  left: -9px;
  border-radius: 30px;
  top: 25%;
  background: var(--main-grey-2) !important;
  width: 18px !important;
  min-width: 18px !important;
  min-height: 18px !important;
  box-shadow: none !important;

  & span {
    color: white !important;
  }

  outline: none;
`

const CustomFormGroup = styled(FormGroup)`
  margin: 0;
  width: 100% !important;
`

const NavLinkGreen = styled(NavLink)`
  color: var(--main-green-1);
`

const EBESubtitles = styled.div`
  color: var(--main-grey-1);
  white-space: pre-wrap;
  margin-left: 20px;
`

export const PlanFinancementPage: FC = () => {
  const { noteId, noteSettings, note, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const { planFinancement, previsionelPeriods, prets, primes, anterieurPeriods } =
    useContext(PFContext)

  const [internalPeriods, setInternalPeriods] = useState(previsionelPeriods)
  const [pfState, setPfState] = useState(planFinancement)
  const [showComputations, setShowComputations] = useState(false)
  const [computationInProgress, setComputationInProgress] = useState(false)
  const [triggerChildCompute, setTriggerChildCompute] = useState(0)

  const updatePeriodMutation = useUpdatePeriods(noteId)
  const addNewEntry = useAddPlanFinancementEntry(noteId)
  const updatePlanFinancement = useUpdatePlanFinancement(noteId)
  const updateSettings = useUpdateNoteSettings(noteId)

  const refs = useRef({
    previsionelPeriods,
    planFinancement,
    internalPeriods,
    pfState,
    unsavedChanges,
  })

  if (
    !arraysEqual(
      pfState.editableEntries.map((it) => it.entryId),
      planFinancement.editableEntries.map((it) => it.entryId)
    )
  ) {
    setPfState(planFinancement)
  }

  const updateShowDetailPret = async (state: boolean) => {
    await updateSettings({ showDetailPret: state })
  }

  const updateEntryLabel = (entryId: string, label: string) => {
    setPfState((pfState) => ({
      ...pfState,
      editableEntries: pfState.editableEntries.map((it) => {
        if (it.entryId === entryId) {
          return { ...it, label }
        }
        return it
      }),
    }))
  }

  const updateComment = (comment: any) => {
    setPfState((pfState) => ({
      ...pfState,
      comment,
    }))
  }

  const updateCommentPrixFondsCommerce = (comment: any) => {
    setPfState((pfState) => ({
      ...pfState,
      commentPrixFondsCommerce: comment,
    }))
  }

  const updateEntryDemarrage = (entryId: string, value: NumericData) => {
    setPfState((pfState) => {
      const entry = pfState.editableEntries.find((it) => it.entryId === entryId)!!
      const updatedEntry = { ...entry, demarrage: value }

      return {
        ...pfState,
        editableEntries: pfState.editableEntries
          .filter((it) => it.entryId !== entryId)
          .concat(updatedEntry),
      }
    })
  }

  const updateDemarrage = (fieldName: string, value: NumericData) =>
    setPfState((pfState) => ({ ...pfState, [fieldName]: value }))

  const updatePeriod = (period: PeriodModel) => {
    setInternalPeriods((internalPeriods) =>
      internalPeriods.map((p) => (p.id === period.id ? period : p))
    )
  }

  const periodToInput = (period: PeriodModel): FinancialPeriodsSaveRequestMultipleInput => {
    return removeTypeName({
      financialPeriodId: period.id,
      input: {
        periodPlanFinancementInput: {
          investissementsCorporels: period.planFinancement.investissementsCorporels,
          investissementsIncorporels: period.planFinancement.investissementsIncorporels,
          investissementsFinanciers: period.planFinancement.investissementsFinanciers,
          dividendes: period.planFinancement.dividendes,
          editableEntries: period.planFinancement.editableEntries,
          remboursementsPretsPasses: period.planFinancement.remboursementsPretsPasses,
        },
        prelevementTns: period.prelevementTns,
      },
    })
  }

  const isPlanFinancementEdited = (editable: PeriodModel, base: PeriodModel) => {
    return (
      !_.isEqual(
        periodToInput(editable).input.periodPlanFinancementInput,
        periodToInput(base).input.periodPlanFinancementInput
      ) ||
      !_.isEqual(
        periodToInput(editable).input.prelevementTns,
        periodToInput(base).input.prelevementTns
      )
    )
  }

  const togglePrelevementTns = async () => {
    await updateSettings({ prelevementTnsInCr: !noteSettings.prelevementTnsInCr })
    await updatePlanFinancement(pfState)
    if (!noteSettings.prelevementTnsInCr) {
      internalPeriods.forEach((internalPeriod) => {
        updatePeriod(internalPeriod)
      })
    }
  }
  const userDebounce = useUserDebounce()

  const [, cancelDebounceUpdatePlanFinancement] = useDebounce(
    async () => {
      if (!_.isEqual(pfState, refs.current.planFinancement)) {
        await withFieldsGuard(() => updatePlanFinancement(pfState), setComputationInProgress)
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [pfState]
  )

  const getPeriodUpdates = () =>
    refs.current.internalPeriods
      .filter((p, index) => isPlanFinancementEdited(p, refs.current.previsionelPeriods[index]))
      .map((p) => periodToInput(p))

  const [, cancelDebounceUpdatePeriods] = useDebounce(
    async () => {
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await withFieldsGuard(() => updatePeriodMutation({ updates }), setComputationInProgress)
      }
      setUnsavedChanges(false)
    },
    userDebounce,
    [internalPeriods]
  )

  useEffect(() => {
    refs.current.pfState = pfState
    if (!_.isEqual(pfState, refs.current.planFinancement)) {
      setUnsavedChanges(true)
    }
  }, [pfState, setUnsavedChanges])

  useEffect(() => {
    refs.current.internalPeriods = internalPeriods
    const updates = getPeriodUpdates()
    if (updates.length > 0) {
      setUnsavedChanges(true)
    }
  }, [internalPeriods, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.previsionelPeriods, previsionelPeriods)) {
      refs.current.previsionelPeriods = previsionelPeriods
      setInternalPeriods(previsionelPeriods)
      cancelDebounceUpdatePeriods()
    }

    if (!_.isEqual(refs.current.planFinancement, planFinancement)) {
      refs.current.planFinancement = planFinancement
      setPfState(planFinancement)
      cancelDebounceUpdatePlanFinancement()
    }
  }, [
    previsionelPeriods,
    planFinancement,
    cancelDebounceUpdatePeriods,
    cancelDebounceUpdatePlanFinancement,
  ])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelAllDebounces()
      if (refs.current.pfState !== refs.current.planFinancement) {
        await updatePlanFinancement(refs.current.pfState)
      }
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await updatePeriodMutation({ updates })
      }
      setUnsavedChanges(false)
    }
    setTriggerChildCompute((triggerChildCompute) => triggerChildCompute + 1)
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const cancelAllDebounces = () => {
    cancelDebounceUpdatePeriods()
    cancelDebounceUpdatePlanFinancement()
  }

  const cafToolTipText = (
    <span>
      Valeur issue de l'onglet{' '}
      <NavLinkGreen to={'caf'}>
        CAF <FontAwesomeIcon icon={['fas', 'external-link-alt']} color={'var(--main-green-1)'} />
      </NavLinkGreen>
    </span>
  )
  const cafTitle = noteSettings.prelevementTnsInCr
    ? 'CAF après prélèvement'
    : 'CAF avant prélèvement'

  const cafValues: number[] = []
  previsionelPeriods.forEach((p) => {
    cafValues.push(p.caf.caf.numericData.value ?? 0)
  })

  const bfrToolTipText = (
    <span>
      Valeur issue de l'onglet{' '}
      <NavLinkGreen to={'bfr'}>
        BFR prévisionnel{' '}
        <FontAwesomeIcon icon={['fas', 'external-link-alt']} color={'var(--main-green-1)'} />
      </NavLinkGreen>
    </span>
  )

  const bfrValues: number[] = []
  previsionelPeriods.forEach((p) => {
    bfrValues.push(p.bfr.bfrReel?.variation?.numericData?.value ?? 0)
  })

  const isPretAT = (pret: Pret) => pret.type === PretType.NonBancaire && !pret.externalized
  const isPretHorsATNonGaranti = (pret: Pret) =>
    (pret.type === PretType.Bancaire || pret.externalized) && pret.guarantees.length === 0
  const isPretHorsATGaranti = (pret: Pret) =>
    (pret.type === PretType.Bancaire || pret.externalized) && pret.guarantees.length > 0

  const dictionnary = useDictionnary()

  const rappelTresorieNette = anterieurPeriods[anterieurPeriods.length - 1]?.bilanCedant
    ?.tresorerieNette
    ? getField<CalculatedData>(
        'tresorerieNette',
        anterieurPeriods[anterieurPeriods.length - 1]!!.bilanCedant!!
      ).numericData
    : undefined
  const val = rappelTresorieNette?.value ?? 0

  return (
    <>
      <div style={{ width: 'fit-content', paddingBottom: '3%' }}>
        <ParametersDiv>
          <PageSaveButton
            buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
            buttonDisabled={computationInProgress || !unsavedChanges}
          />
          <AppMetierSwitch
            label={'Voir tous les calculs'}
            checked={showComputations}
            onChange={() => {
              setShowComputations(!showComputations)
            }}
          />
          <div style={{ display: 'flex', marginLeft: 'auto' }}>
            {note.type !== NoteType.StructureExistante && (
              <AppMetierSwitch
                label={'Afficher la colonne Démarrage'}
                checked={noteSettings.showDemarrage}
                onChange={async () => {
                  await updateSettings({ showDemarrage: !noteSettings.showDemarrage })
                }}
              />
            )}
            {prets.length > 0 && (
              <AppMetierSwitch
                label={'Afficher Détail des Prêts'}
                checked={noteSettings.showDetailPret}
                onChange={() => updateShowDetailPret(!noteSettings.showDetailPret)}
              />
            )}
          </div>
        </ParametersDiv>
        <PlanFinancementContext.Provider
          value={{
            showComputations,
            updateEntryLabel,
            updateEntryDemarrage,
            updateDemarrage,
            updatePeriod,
            computationInProgress,
            setComputationInProgress,
            editablePrevisionnelPeriods: internalPeriods,
            resetDebounceTimers: cancelAllDebounces,
          }}
        >
          <PlanFinancementTable>
            <thead>
              <tr>
                <th className={'transparent title'}>Besoins</th>
                {noteSettings.showDemarrage && <th className={'demarrage'}>Démarrage</th>}
                {internalPeriods.map((period, i) => (
                  <th key={i}>
                    {i === 0 && note.type !== NoteType.StructureExistante && (
                      <ButtonToggle
                        icon={noteSettings.showDemarrage ? 'chevron-left' : 'chevron-right'}
                        small
                        onClick={() =>
                          updateSettings({ showDemarrage: !noteSettings.showDemarrage })
                        }
                      />
                    )}
                    {dictionnary.periodTitle(period, i)}
                  </th>
                ))}
                <th className={'action'} />
              </tr>
            </thead>
            <tbody>
              <BlankPlanFinancementTableLine periodNumber={internalPeriods.length} />
              <Line fieldName={'investissementsIncorporels'} title={'Investissement incorporels'} />
              <Line fieldName={'investissementsCorporels'} title={'Investissement corporels'} />
              <Line fieldName={'investissementsFinanciers'} title={'Investissement financiers'} />
              <DemarrageLine
                fieldName={'tvaSurInvestissements'}
                title={'TVA sur investissements'}
              />
              <DemarrageLine fieldName={'tvaSurStock'} title={'TVA sur stock'} />
              <Line fieldName={'dividendes'} title={'Dividendes'} />
              <DemarrageLine fieldName={'stockDepart'} title={'Stock de démarrage'} />
              <DemarrageLine fieldName={'tresorerieDemarrage'} title={'Trésorerie de démarrage'} />
              <PrelevementTnsLine
                key={'prelevementTns'}
                prelevementTnsInCr={noteSettings.prelevementTnsInCr}
                togglePrelevementTns={togglePrelevementTns}
              />
              <LineWithToolTip
                toolTipText={bfrToolTipText}
                toolTipChildren={'Variation de BFR'}
                showDemarrage={noteSettings.showDemarrage}
                periodsValues={bfrValues}
                kiloEuros={noteSettings.kiloEuros}
              />
              <tr className={'hoverable'} style={{ height: '40px' }}>
                <td className={'title'}>Remboursement des prêts AT</td>
                {noteSettings.showDemarrage && <td className={'demarrage'} />}
                {internalPeriods.map((val, i) => (
                  <td key={i} className={'horsPF'}>
                    <NumberText
                      value={val.remboursementsEmprunts.remboursementsAT.numericData.value}
                      suffix={eurosSuffix(noteSettings.kiloEuros)}
                    />
                  </td>
                ))}
                <td />
              </tr>
              <tr className={'hoverable'} style={{ height: '40px' }}>
                <td className={'title'}>Remboursement des prêts Hors AT</td>
                {noteSettings.showDemarrage && <td className={'demarrage'} />}
                {internalPeriods.map((val, i) => (
                  <td key={i} className={'horsPF'}>
                    <NumberText
                      value={val.remboursementsEmprunts.remboursementsHorsAT.numericData.value}
                      suffix={eurosSuffix(noteSettings.kiloEuros)}
                    />
                  </td>
                ))}
                <td />
              </tr>
              {note.type === NoteType.StructureExistante && (
                <Line
                  fieldName={'remboursementsPretsPasses'}
                  title={'Remboursements des prêts passés'}
                />
              )}
              {pfState?.editableEntries
                ?.filter((it) => it.type === PlanFinancementEntryType.Besoins)
                ?.map((entry) => (
                  <EditableEntryLine key={entry.entryId} entry={entry} />
                ))}
              <AddNewEntryLine
                title={`+ Ajouter un autre besoin`}
                action={() => addNewEntry(PlanFinancementEntryType.Besoins)}
              />
              <PFCSubTotalLineWithDetails label={'Total besoins'} fieldName={'totalBesoins'} />

              <BlankPlanFinancementTableLine periodNumber={internalPeriods.length} />
            </tbody>
          </PlanFinancementTable>

          <PlanFinancementTable>
            <thead>
              <tr>
                <th className={'transparent title'}>Ressources</th>
                {noteSettings.showDemarrage && <th className={'demarrage'}>Démarrage</th>}
                {internalPeriods.map((period, i) => (
                  <th key={i}>
                    {i === 0 && note.type !== NoteType.StructureExistante && (
                      <ButtonToggle
                        icon={noteSettings.showDemarrage ? 'chevron-left' : 'chevron-right'}
                        small
                        onClick={() =>
                          updateSettings({ showDemarrage: !noteSettings.showDemarrage })
                        }
                      />
                    )}
                    {dictionnary.periodTitle(period, i)}
                  </th>
                ))}
                <th className={'action'} />
              </tr>
            </thead>
            <tbody>
              <ApportsPersonnelsBlock />
              <RessourceLines type={PlanFinancementEntryType.Apports} showBlockTitle={false} />
              <PrimeBlock primes={primes} triggerCompute={triggerChildCompute} />
              <RessourceLines type={PlanFinancementEntryType.SubventionsInvestissements} />
              <PretBlock
                prets={prets.filter(isPretAT)}
                title={"Prêts gérés par l'AT"}
                triggerCompute={triggerChildCompute}
              />
              <PretBlock
                prets={prets.filter(isPretHorsATGaranti)}
                title={'Prêts externalisés garantis'}
                triggerCompute={triggerChildCompute}
              />
              <PretBlock
                prets={prets.filter(isPretHorsATNonGaranti)}
                title={'Prêts externalisés non garantis'}
                triggerCompute={triggerChildCompute}
              />
              <RessourceLines
                type={PlanFinancementEntryType.CreditRelaisTva}
                onlyDemarrage={true}
              />
              <LineWithToolTip
                toolTipText={cafToolTipText}
                toolTipChildren={cafTitle}
                showDemarrage={noteSettings.showDemarrage}
                periodsValues={cafValues}
                kiloEuros={noteSettings.kiloEuros}
              />
              <PFCSubTotalLineWithDetails
                label={'Total ressources'}
                fieldName={'totalRessources'}
              />
              <BlankPlanFinancementTableLine periodNumber={internalPeriods.length} />
            </tbody>
          </PlanFinancementTable>

          <PlanFinancementTable>
            <thead>
              <tr>
                <th className={'transparent title'}>{}</th>
                {noteSettings.showDemarrage && <th className={'demarrage'}>Démarrage</th>}
                {internalPeriods.map((period, i) => (
                  <th key={i}>
                    {i === 0 && note.type !== NoteType.StructureExistante && (
                      <ButtonToggle
                        icon={noteSettings.showDemarrage ? 'chevron-left' : 'chevron-right'}
                        small
                        onClick={() =>
                          updateSettings({ showDemarrage: !noteSettings.showDemarrage })
                        }
                      />
                    )}
                    {dictionnary.periodTitle(period, i)}
                  </th>
                ))}
                <th className={'action'} />
              </tr>
            </thead>
            <tbody>
              <BlankPlanFinancementTableLine periodNumber={internalPeriods.length} />
              <PFCSubTotalLineWithDetails
                label={'Total Besoins'}
                fieldName={'totalBesoins'}
                bottomBorder={true}
              />
              <PFCSubTotalLineWithDetails
                label={'Total Ressources'}
                fieldName={'totalRessources'}
                bottomBorder={true}
              />
              <PFCSubTotalLineWithDetails
                label={'Solde'}
                fieldName={'solde'}
                bottomBorder={true}
                isTotal={true}
              />
              <PFCSubTotalLineWithDetails
                label={'Solde cumulé'}
                fieldName={'soldeCumule'}
                bottomBorder={true}
                isTotal={true}
              />
              {note.type === NoteType.StructureExistante && (
                <OnlyLabelTableLine
                  periodNumber={internalPeriods.length}
                  label={`Trésorerie N-1`}
                  value={val}
                />
              )}
              <BlankPlanFinancementTableLine periodNumber={internalPeriods.length} />
            </tbody>
          </PlanFinancementTable>
        </PlanFinancementContext.Provider>
        <CommentContainer>
          <CommentTitle>
            <span>Commentaire</span>
          </CommentTitle>
          <Editor
            id={'commentPf'}
            placeHolder={'Vous pouvez saisir un commentaire'}
            initialValue={makeOutputData(pfState.comment)}
            onChange={updateComment}
          />
        </CommentContainer>

        {note.type === NoteType.Reprise && (
          <CommentContainer>
            <CommentTitle>
              <span>Prix du fonds de commerce</span>
            </CommentTitle>
            {anterieurPeriods.length > 0 && (
              <div
                style={{
                  paddingLeft: '20px',
                  marginTop: '10px',
                  fontWeight: 600,
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '10px',
                }}
              >
                Excédent brut d'exploitation du cédant <EBECedant />
              </div>
            )}
            <EBESubtitles>
              {' '}
              &gt; La valorisation de la part incorporelle du fonds de commerce paraît-elle en ligne
              avec l'activité passée et/ou le potentiel de CA ? Etait-il utile pour l'entrepreneur
              de payer ce fonds de commerce plutôt que de créer de 0 ?
            </EBESubtitles>
            <EBESubtitles style={{ marginBottom: '10px' }}>
              {' '}
              &gt; La valorisation de la part corporelle du fonds de commerce paraît-elle adaptée au
              regard de l'état de vétusté du matériel ?
            </EBESubtitles>
            <Editor
              id={'commentPfPrixFondsCommerce'}
              placeHolder={'Vous pouvez saisir un commentaire'}
              initialValue={makeOutputData(pfState.commentPrixFondsCommerce)}
              onChange={updateCommentPrixFondsCommerce}
            />
          </CommentContainer>
        )}
      </div>
    </>
  )
}

const Line: FC<{ fieldName: string; title: string }> = ({ fieldName, title }) => {
  const { noteSettings } = useContext(NoteContext)
  const {
    showComputations,
    updatePeriod,
    updateDemarrage,
    editablePrevisionnelPeriods,
    resetDebounceTimers,
    computationInProgress,
  } = useContext(PlanFinancementContext)
  const { planFinancement } = useContext(PFContext)

  const periodField = (period: PeriodModel) =>
    period.planFinancement[fieldName as keyof PeriodPlanFinancement] as NumericData

  const planFinancementField = planFinancement[fieldName as keyof PlanFinancement] as NumericData

  const updateField = (period: PeriodModel, value: NumericData) => ({
    ...period,
    planFinancement: { ...period.planFinancement, [fieldName]: value },
  })

  const checkDemarrageValue = () => {
    const demarrageValue = planFinancementField.value
    if (!demarrageValue && demarrageValue !== 0) {
      return true
    }
    const periodValue =
      (editablePrevisionnelPeriods[0] && periodField(editablePrevisionnelPeriods[0]).value) ?? 0
    return periodValue >= demarrageValue
  }

  const checkHasDemarrageValue = () => {
    return planFinancementField !== undefined
  }

  return (
    <tr className={'hoverable'}>
      <td className={'title'}>{title}</td>
      {noteSettings.showDemarrage &&
        (checkHasDemarrageValue() ? (
          <td className={'demarrage'}>
            <FlexDiv>
              <CustomFormGroup
                helperText={!checkDemarrageValue() && 'Doit être ≤ à l’exercice 1'}
                intent={!checkDemarrageValue() ? Intent.DANGER : Intent.NONE}
              >
                <NumericDataInput
                  numericData={planFinancementField}
                  onChange={(value) => {
                    resetDebounceTimers()
                    updateDemarrage(fieldName, value)
                  }}
                  showComputations={showComputations}
                  isCalculated={computationInProgress}
                />
              </CustomFormGroup>
            </FlexDiv>
          </td>
        ) : (
          <td className={'demarrage'} />
        ))}
      {editablePrevisionnelPeriods.map((period, i) => (
        <td key={i}>
          <FlexDiv>
            <NumericDataInput
              numericData={periodField(period)}
              onChange={(value) => {
                resetDebounceTimers()
                updatePeriod(updateField(period, value))
              }}
              showComputations={showComputations}
              isCalculated={computationInProgress}
            />
          </FlexDiv>
        </td>
      ))}
      <td />
    </tr>
  )
}

export const BlankPlanFinancementTableLine: FC<{ periodNumber: number }> = ({ periodNumber }) => {
  return <OnlyLabelTableLine periodNumber={periodNumber} />
}

export const OnlyLabelTableLine: FC<{ periodNumber: number; label?: string; value?: number }> = ({
  periodNumber,
  label,
  value,
}) => {
  const { noteSettings } = useContext(NoteContext)
  return (
    <tr className={'empty'}>
      <td style={{ fontStyle: 'italic', fontWeight: 'bold', textAlign: 'right' }}>{label}</td>
      {noteSettings.showDemarrage && <td className={'demarrage'} />}
      {[...Array(periodNumber)].map((_, i) => (
        <td
          key={i}
          style={
            i === 0 && label
              ? {
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                }
              : {}
          }
        >
          <div style={{ paddingLeft: '56px' }}>
            {i === 0 && label && (
              <NumberText value={value} suffix={eurosSuffix(noteSettings.kiloEuros)} />
            )}
          </div>
        </td>
      ))}
      <td />
    </tr>
  )
}

interface LineWithToolTipProps {
  toolTipText: string | JSX.Element
  toolTipChildren: string | JSX.Element
  showDemarrage: boolean
  periodsValues: number[]
  kiloEuros: boolean | undefined
}

const LineWithToolTip: FC<LineWithToolTipProps> = ({
  toolTipText,
  toolTipChildren,
  showDemarrage,
  periodsValues,
  kiloEuros = false,
}) => {
  return (
    <tr className={'hoverable'} style={{ height: '40px' }}>
      <td className={'title'}>
        <Tooltip2
          content={toolTipText}
          position={'top-left'}
          hoverCloseDelay={500}
          interactionKind={'hover'}
        >
          {toolTipChildren}
        </Tooltip2>
      </td>
      {showDemarrage && <td className={'demarrage'} />}
      {periodsValues.map((val, i) => (
        <td key={i} className={'horsPF'}>
          <NumberText value={val} suffix={eurosSuffix(kiloEuros)} />
        </td>
      ))}
      <td />
    </tr>
  )
}

export const EBECedant: FC = () => {
  const { ratios, anterieurPeriods } = useContext(PFContext)
  const { noteSettings } = useContext(NoteContext)

  const numData = ratios.moyenneEBECedant.numericData.value?.toFixed(2)

  const suffix = eurosSuffix(noteSettings.kiloEuros)
  return (
    <>
      <div id={'moyenne_ebe_cedant'}>
        {numData && (
          <FormulaDescriptionPopover
            content={
              <FormulaPopoverContent calculatedData={ratios.moyenneEBECedant} suffix={suffix} />
            }
            position={'bottom'}
          >
            <div>
              <span style={{ paddingLeft: '10px', paddingRight: '30px', minWidth: '100px' }}>
                <span style={{ color: 'var(--main-green-1)' }}>
                  (moyenne sur les {anterieurPeriods.length} années)
                </span>{' '}
                : <NumberText value={numData} suffix={suffix} />
              </span>
            </div>
          </FormulaDescriptionPopover>
        )}
      </div>
    </>
  )
}

const PFCSubTotalLineWithDetails: FC<{
  label: string
  fieldName: string
  bottomBorder?: boolean
  isTotal?: boolean
}> = ({ label, fieldName, bottomBorder = false, isTotal = false }) => {
  const { noteSettings } = useContext(NoteContext)

  const { planFinancement, previsionelPeriods } = useContext(PFContext)
  const { editablePrevisionnelPeriods } = useContext(PlanFinancementContext)

  const suffix = eurosSuffix(noteSettings.kiloEuros)
  const calculatedDataDemarrage = getField<CalculatedData>(fieldName, planFinancement)

  return (
    <tr
      className={
        isTotal
          ? bottomBorder
            ? 'total bottom-border'
            : 'total'
          : bottomBorder
          ? 'subtotal bottom-border'
          : 'subtotal'
      }
    >
      <td>{label}</td>
      {noteSettings.showDemarrage && (
        <td className={'demarrage'}>
          <div>
            {noteSettings.showDemarrage && calculatedDataDemarrage !== undefined && (
              <FormulaDescriptionPopover
                content={
                  <FormulaPopoverContent calculatedData={calculatedDataDemarrage} suffix={suffix} />
                }
                position={'bottom'}
              >
                <div>
                  <span style={{ paddingLeft: '10px', paddingRight: '30px', minWidth: '100px' }}>
                    <NumberText
                      value={calculatedDataDemarrage?.numericData.value ?? 0.0}
                      suffix={suffix}
                    />
                  </span>
                </div>
              </FormulaDescriptionPopover>
            )}
          </div>
        </td>
      )}
      {editablePrevisionnelPeriods.map((period, i) => {
        const calculatedData = getField<CalculatedData>(
          `planFinancement.${fieldName}`,
          previsionelPeriods[i]
        )
        return (
          <td key={i}>
            <div>
              <FormulaDescriptionPopover
                content={<FormulaPopoverContent calculatedData={calculatedData} suffix={suffix} />}
                position={'bottom'}
              >
                <div>
                  <span style={{ paddingLeft: '10px', paddingRight: '30px', minWidth: '100px' }}>
                    <NumberText value={calculatedData.numericData.value} suffix={suffix} />
                  </span>
                </div>
              </FormulaDescriptionPopover>
            </div>
          </td>
        )
      })}
      <td />
    </tr>
  )
}
