import React, { useContext, useMemo } from 'react'
import {
  PaAnswer,
  PaCategory,
  PaCategoryData,
  PaEntrepreneurAnswer,
  PaSubCategoryData,
  Porteur,
  ProjectAnalysis,
  ProjectId,
  Scoring,
} from '@fa-metier/types'
import { useProjectAnalysisQuery, usePorteursQuery } from '../../generated/graphql'
import { NoteId } from '../Financiary/NoteQueries'
import { useGetProject } from '../../App'
import { useGetScoring } from '../ProjectHub/ScoringQueries'
import {ApolloError} from "@apollo/client";

export interface LoadingData<T = any> {
  data: T
  loading: boolean,
  error: ApolloError | undefined
}

export type ProjectEvaluationContextData = {
  projectId: ProjectId
  projectName: string
  analysis: ProjectAnalysis
  categories: Record<PaCategory, PaSubCategoryData[]>
  porteurs: Porteur[]
  scoring: Scoring | undefined
}
export const ProjectEvaluationContext = React.createContext<ProjectEvaluationContextData>({
  projectId: '',
  projectName: '',
  analysis: { answers: [], id: '', entrepreneurs: [] },
  categories: {
    MEANS: [],
    POSITION: [],
    ENVIRONMENT: [],
    ENTREPRENEUR: [],
  },
  porteurs: [],
  scoring: undefined,
})

export const useProjectEvaluationContextData: (
  projectId: ProjectId,
  noteId: NoteId
) => LoadingData<ProjectEvaluationContextData> = (projectId, noteId) => {
  const { data, loading, error } = useProjectAnalysisQuery({ variables: { noteId } })
  const { data: projectData, loading: projectLoading, error: projectError } = useGetProject(projectId)
  const { data: porteursData, loading: porteursLoading, error: porteursError } = usePorteursQuery({
    variables: { noteId },
  })
  const { data: scoringData, loading: scoringLoading, error: scoringError } = useGetScoring(noteId)

  const projectName = projectData && projectData.project && projectData.project.name

  const analysis = data && data.projectAnalyzer.analysis!

  const categories =
    data &&
    data.projectAnalyzer.formData!.categories.reduce<Partial<Record<PaCategory, PaCategoryData>>>(
      (acc, val) => {
        const { category } = val
        acc[category] = val
        return acc
      },
      {}
    )
  // @ts-ignore
  const contextData: Record<PaCategory, PaSubCategoryData[]> = useMemo(() => {
    return Object.values(categories || {}).reduce<Record<PaCategory, PaSubCategoryData[]>>(
      // @ts-ignore
      (acc, val: PaCategoryData) => {
        acc[val.category] = val.subcategories
        return acc
      },
      {
        [PaCategory.Means]: [],
        [PaCategory.Position]: [],
        [PaCategory.Environment]: [],
        [PaCategory.Entrepreneur]: [],
      }
    )
  }, [categories])

  return {
    loading: loading || projectLoading || porteursLoading || scoringLoading,
    data: {
      projectId: noteId,
      projectName: projectName!,
      analysis: analysis ? analysis : { answers: [], id: '', entrepreneurs: [] },
      categories: contextData,
      porteurs: porteursData?.porteurs ?? [],
      scoring: scoringData,
    },
    error: error || projectError || porteursError || scoringError
  }
}

export const useAnswer: (
  questionId: string,
  entrepreneurId?: string
) => { answer?: PaAnswer | PaEntrepreneurAnswer; projectId: ProjectId } = (
  questionId,
  entrepreneurId
) => {
  const { analysis, projectId } = useContext(ProjectEvaluationContext)
  let answer: PaAnswer | PaEntrepreneurAnswer | undefined
  if (entrepreneurId != null) {
    const ent = analysis.entrepreneurs.find((it) => it.id === entrepreneurId)
    answer = ent && ent.responses.find((a) => a.questionId === questionId)
  } else {
    answer = analysis.answers.find((a) => a.questionId === questionId)
  }
  return {
    projectId,
    answer,
  }
}
