import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react'
import { updateInternalPeriods, updateInternalSituationIntermediaire } from '../Financiary'
import { useGetPeriods, useUpdatePeriods } from '../FinanciaryQueries'
import { PeriodModel } from '../PeriodModel'
import { useDebounce } from 'react-use'
import {
  BfrDettesFiscalesSociales,
  BfrInput,
  BfrNormatif,
  BfrReel,
  CalculatedData,
  FinancialPeriodsSaveRequestMultipleInput,
  NoteSettingUpdateRequest,
  NoteType,
} from '@fa-metier/types'
import { getField, removeTypeName } from '@fa-metier/commons'
import { formatDayjs } from '../../../utils/DayjsFormatter'
import {
  CalculatedCell,
  CalculatedCellWithCalculDetail,
  FinanciaryLine,
  getNumericData,
} from '../Utils/FinanciaryLine'
import { PeriodsContext } from '../Utils/PeriodsContext'
import { Button, Dialog, H2 } from '@blueprintjs/core'
import {
  Dots,
  EmptyLine,
  FinancialTable,
  LabelSeparator,
  TableSeparation,
  VerticalLine,
} from '../FinancialTable'
import { AppMetierSwitch } from '@fa-metier/components/dist/form/Switch'
import { eurosSuffix, Suffixes } from '@fa-metier/components/dist/form/suffixes'
import { NoteContext } from '../../NotePage'
import { YodaSpinnerPage } from '../../../utils/YodaSpinnerPage'
import { NavLinkGreen } from '../CR/CRForm'
import { useDictionnary } from '../../dictionnary/dictionnary'
import { DIALOG_BODY, DIALOG_FOOTER } from '@blueprintjs/core/lib/esm/common/classes'
import { useUpdateNoteSettings } from '../../NoteSettingsQueries'
import { DEBOUNCE_CONFIG } from '../../../Settings/debounceConfig'
import { saveShortcutListener, withFieldsGuard } from '../../../utils/utils'
import { PageSaveButton } from '../../../utils/PageSaveButton'
import PageTitle from '../../../utils/PageTitle'
import { ParametersDiv } from '../../../utils/ParametersDiv'
import _ from 'lodash'

export function bfrToInput(bfrNormatif: BfrNormatif): BfrInput {
  return removeTypeName({
    stock: getNumericData(bfrNormatif.stock),
    avancesAcomptesFournisseurs: getNumericData(bfrNormatif.avances_acomptes_fournisseurs),
    creancesClients: getNumericData(bfrNormatif.creances_clients),
    creancesFinanceursPublics: getNumericData(bfrNormatif.creances_financeurs_publics),
    autresCreances: getNumericData(bfrNormatif.autres_creances),
    avancesAcomptesClient: getNumericData(bfrNormatif.avances_acomptes_client),
    dettesFournisseurs: getNumericData(bfrNormatif.dettes_fournisseurs),
    dettesSalaires: getNumericData(bfrNormatif.dettes_salaires),
    dettesCotisationsSociales: getNumericData(bfrNormatif.dettes_cotisations_sociales),
    dettesFiscalesTva: getNumericData(bfrNormatif.dettes_fiscales_tva),
    dettesFiscalesIsDiverses: getNumericData(bfrNormatif.dettes_fiscales_is_diverses),
    autresDettes: getNumericData(bfrNormatif.autres_dettes),
    showBfrInverse: false,
  })
}

export function bfrInverseToInput(bfrReel: BfrReel): BfrInput {
  return removeTypeName({
    stock: getNumericData(bfrReel.stock),
    avancesAcomptesFournisseurs: getNumericData(bfrReel.avances_acomptes_fournisseurs),
    avancesAcomptesClient: getNumericData(bfrReel.avances_acomptes_client),
    creancesClients: getNumericData(bfrReel.creances_clients),
    creancesFinanceursPublics: getNumericData(bfrReel.creances_financeurs_publics),
    autresCreances: getNumericData(bfrReel.autres_creances),
    dettesFournisseurs: getNumericData(bfrReel.dettes_fournisseurs),
    dettesSalaires: getNumericData(bfrReel.dettes_salaires),
    dettesCotisationsSociales: getNumericData(bfrReel.dettes_cotisations_sociales),
    dettesFiscalesTva: getNumericData(bfrReel.dettes_fiscales_tva),
    dettesFiscalesIsDiverses: getNumericData(bfrReel.dettes_fiscales_is_diverses),
    autresDettes: getNumericData(bfrReel.autres_dettes),
    showBfrInverse: true,
  })
}

const periodToMutationModel = (
  period: PeriodModel,
  showBfrInverse: boolean
): FinancialPeriodsSaveRequestMultipleInput => {
  if (showBfrInverse) {
    return {
      financialPeriodId: period.id,
      input: {
        bfrInput: bfrInverseToInput(period.bfr.bfrReel),
      },
    }
  }
  return {
    financialPeriodId: period.id,
    input: {
      bfrInput: bfrToInput(period.bfr.bfrNormatif),
    },
  }
}

interface BFRContextI {
  previsionelPeriods: PeriodModel[]
  anterieurPeriods: PeriodModel[]
  situationIntermediaire?: PeriodModel
}

export const BFRContext = createContext<BFRContextI>({} as BFRContextI)

export const BfrFormWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)
  const updateSettings = useUpdateNoteSettings(noteId)
  const [updatingSettings, setUpdatingSettings] = useState(false)
  const doUpdateSettings = (updateRequest: NoteSettingUpdateRequest) => {
    setUpdatingSettings(true)
    return updateSettings(updateRequest).then(() =>
      setTimeout(() => setUpdatingSettings(false), 500)
    )
  }
  if (periodLoading || updatingSettings) {
    return <YodaSpinnerPage />
  }
  return (
    <BFRContext.Provider
      value={{
        previsionelPeriods: periodsData.previsionnelles,
        anterieurPeriods: periodsData.anterieurs,
        situationIntermediaire: periodsData.situationIntermediaire ?? undefined,
      }}
    >
      <BfrForm updateSettings={doUpdateSettings} />
    </BFRContext.Provider>
  )
}

const BfrForm: FC<{
  updateSettings: (updateRequest: NoteSettingUpdateRequest) => Promise<any>
}> = ({ updateSettings }) => {
  const { note, noteId, noteSettings, unsavedChanges, setUnsavedChanges } = useContext(NoteContext)
  const { previsionelPeriods, anterieurPeriods, situationIntermediaire } = useContext(BFRContext)
  const [internalPeriods, setInternalPeriods] = useState(previsionelPeriods)
  const [internalSituationIntermediaire, setInternalSituationIntermediaire] =
    useState(situationIntermediaire)
  const updatePeriods = useUpdatePeriods(noteId)
  const [showComputations, setShowComputations] = useState(false)
  const [showAnterieurs, setShowAnterieurs] = useState(
    (note.type === NoteType.Reprise || note.type === NoteType.StructureExistante) &&
      anterieurPeriods !== undefined &&
      anterieurPeriods.length > 0
  )
  const [showPopUpBfrInverse, setShowPopUpBfrInverse] = useState(false)
  const [computationInProgress, setComputationInProgress] = useState(false)

  const refs = useRef({
    previsionelPeriods,
    situationIntermediaire,
    internalPeriods,
    internalSituationIntermediaire,
    unsavedChanges,
  })

  const emptyBilan =
    anterieurPeriods && anterieurPeriods.every((p) => p.bilanCedant?.isEmpty ?? true)
  const emptyAnterieurCr =
    anterieurPeriods && anterieurPeriods.every((p) => p.incomeStatement.isEmpty ?? true)

  const getPeriodUpdates = () => {
    const updatesPrevi = refs.current.internalPeriods.filter(
      (p, index) => !_.isEqual(p.bfr, refs.current.previsionelPeriods[index].bfr)
    )
    const updateSituation =
      refs.current.internalSituationIntermediaire &&
      !_.isEqual(
        refs.current.internalSituationIntermediaire?.bfr,
        refs.current.situationIntermediaire?.bfr
      )
        ? [refs.current.internalSituationIntermediaire]
        : []
    return updatesPrevi
      .concat(updateSituation)
      .map((it) => periodToMutationModel(it, noteSettings.showBfrInverse))
  }

  const [, cancelDebounceUpdatePeriods] = useDebounce(
    async () => {
      if (internalPeriods) {
        const updates = getPeriodUpdates()
        if (updates.length > 0) {
          await withFieldsGuard(() => updatePeriods({ updates }), setComputationInProgress)
        }
        setUnsavedChanges(false)
      }
    },
    DEBOUNCE_CONFIG.delay.longUpdateRequest,
    [internalPeriods, internalSituationIntermediaire]
  )

  useEffect(() => {
    refs.current.internalPeriods = internalPeriods
    refs.current.internalSituationIntermediaire = internalSituationIntermediaire
    const updates = getPeriodUpdates()
    if (updates.length > 0) {
      setUnsavedChanges(true)
    }
  }, [internalPeriods, internalSituationIntermediaire, setUnsavedChanges])

  useEffect(() => {
    if (!_.isEqual(refs.current.previsionelPeriods, previsionelPeriods)) {
      refs.current.previsionelPeriods = previsionelPeriods
      setInternalPeriods(previsionelPeriods)
      cancelDebounceUpdatePeriods()
    }

    if (!_.isEqual(refs.current.situationIntermediaire, situationIntermediaire)) {
      refs.current.situationIntermediaire = situationIntermediaire
      setInternalSituationIntermediaire(situationIntermediaire)
      cancelDebounceUpdatePeriods()
    }
  }, [previsionelPeriods, situationIntermediaire, cancelDebounceUpdatePeriods])

  useEffect(() => {
    if (refs.current.unsavedChanges !== unsavedChanges) {
      refs.current.unsavedChanges = unsavedChanges
    }
  }, [unsavedChanges])

  const compute: () => any = async () => {
    if (refs.current.unsavedChanges) {
      cancelDebounceUpdatePeriods()
      const updates = getPeriodUpdates()
      if (updates.length > 0) {
        await updatePeriods({ updates })
      }
      setUnsavedChanges(false)
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) =>
      saveShortcutListener(e, compute, setComputationInProgress)
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
      compute()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dictionnary = useDictionnary()

  return (
    <div>
      <PageTitle>BFR</PageTitle>

      <PeriodsContext.Provider
        value={{
          showComputations,
          showAnterieurs,
          anterieurPeriods,
          situationIntermediaire,
          periods: previsionelPeriods,
          updatePeriods: updateInternalPeriods(setInternalPeriods),
          updateSituationIntermediaire: updateInternalSituationIntermediaire(
            setInternalSituationIntermediaire
          ),
        }}
      >
        <div style={{ width: 'fit-content' }}>
          <ParametersDiv>
            <PageSaveButton
              buttonOnClick={() => withFieldsGuard(compute, setComputationInProgress)}
              buttonDisabled={computationInProgress || !unsavedChanges}
            />
            <AppMetierSwitch
              label={'Voir tous les calculs'}
              checked={showComputations}
              onChange={() => {
                setShowComputations(!showComputations)
              }}
            />
            <Button style={{ marginLeft: '10px' }} onClick={() => setShowPopUpBfrInverse(true)}>
              {noteSettings.showBfrInverse
                ? 'Remplir le BFR normatif'
                : 'Remplir directement le BFR réel'}
            </Button>
            <Dialog isOpen={showPopUpBfrInverse} title={'Attention'} isCloseButtonShown={false}>
              <div className={DIALOG_BODY}>
                {noteSettings.showBfrInverse ? (
                  <>
                    <div>Etes-vous sûr de vouloir saisir le BFR normatif ?</div>
                  </>
                ) : (
                  <>
                    <div>Etes-vous sûr de vouloir saisir directement le BFR réel ?</div>
                  </>
                )}
              </div>
              <div className={DIALOG_FOOTER}>
                <Button
                  style={{ marginRight: '0.5em' }}
                  onClick={() => setShowPopUpBfrInverse(false)}
                >
                  Annuler
                </Button>
                <Button
                  intent={'primary'}
                  onClick={() => {
                    updateSettings({
                      showBfrInverse: !noteSettings.showBfrInverse,
                    }).then(() => {
                      setShowPopUpBfrInverse(false)
                    })
                  }}
                >
                  Valider
                </Button>
              </div>
            </Dialog>
            {note.type === NoteType.Reprise && anterieurPeriods?.length !== 0 && (
              <div style={{ marginLeft: '20px' }}>
                <AppMetierSwitch
                  label={'Afficher exercices du cédant'}
                  checked={showAnterieurs}
                  onChange={() => {
                    setShowAnterieurs(!showAnterieurs)
                  }}
                />
              </div>
            )}
          </ParametersDiv>
          {note.type === NoteType.StructureExistante &&
            (emptyAnterieurCr || emptyBilan) &&
            anterieurPeriods?.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  borderStyle: 'solid',
                  padding: '10px',
                  borderWidth: '1.5px',
                  borderColor: 'var(--main-grey-2)',
                  maxWidth: 'max-content',
                  marginBottom: '25px',
                  backgroundColor: 'white',
                  color: 'var(--main-black)2)',
                }}
              >
                {emptyAnterieurCr && emptyBilan && (
                  <div style={{ fontSize: '16px' }}>
                    <span>
                      Vous devez saisir les comptes passés pour calculer les indicateurs du BFR
                      passé ( <NavLinkGreen to={'bilanCedant'}>Bilan</NavLinkGreen> et{' '}
                      <NavLinkGreen to={'cr'}>Compte de résultat passé</NavLinkGreen>).
                    </span>
                  </div>
                )}
                {!emptyBilan && emptyAnterieurCr && (
                  <div style={{ fontSize: '16px' }}>
                    <span>
                      Si vous souhaitez avoir les évaluations des délais passés, vous devez saisir
                      les données du <NavLinkGreen to={'cr'}>compte de résultat passé</NavLinkGreen>
                      .
                    </span>
                  </div>
                )}
                {!emptyAnterieurCr && emptyBilan && (
                  <div style={{ fontSize: '16px' }}>
                    <span>
                      Si vous souhaitez avoir les évaluations des délais passés, vous devez saisir
                      les données du <NavLinkGreen to={'bilanCedant'}>bilan</NavLinkGreen>.
                    </span>
                  </div>
                )}
              </div>
            )}
          {note.type === NoteType.Reprise && anterieurPeriods?.length > 0 && (
            <div
              style={{
                display: 'flex',
                borderStyle: 'solid',
                padding: '10px',
                borderWidth: '1.5px',
                borderColor: 'var(--main-grey-2)',
                maxWidth: 'max-content',
                marginBottom: '25px',
                backgroundColor: 'white',
                color: 'var(--main-black)2)',
              }}
            >
              <div>
                <div style={{ fontSize: '16px' }}>
                  Les données du BFR normatif passé proviennent de ce que vous avez saisi dans le{' '}
                  <NavLinkGreen to={'bilanCedant'}>bilan</NavLinkGreen> et le{' '}
                  <NavLinkGreen to={'cr'}>compte de résultat passé</NavLinkGreen>. <br />
                  <br /> Elles peuvent vous servir de repère pour définir les hypothèses du BFR
                  prévisionnel
                </div>
              </div>
            </div>
          )}

          <FinancialTable
            lineBefore={false}
            className={'bfr'}
            thead={
              <tr style={{ backgroundColor: 'var(--main-blue-2' }}>
                <th className={'section-title'} style={{ verticalAlign: 'bottom' }}>
                  <div>
                    <H2 style={{ marginTop: '15px' }}>BFR Normatif</H2>
                    Besoins d'exploitation
                  </div>
                  <LabelSeparator height={1728} />
                </th>
                {showAnterieurs &&
                  anterieurPeriods!!.map((p, i) => (
                    <td key={p.id} className={'anterieurSeparation'}>
                      <div style={{ textAlign: 'center' }}>
                        <b>{dictionnary.periodTitle(p, i)}</b>
                      </div>
                    </td>
                  ))}
                {situationIntermediaire && (
                  <td key={situationIntermediaire.id} className={'intermediaire'}>
                    <div style={{ textAlign: 'center', color: 'black' }}>
                      <b>Situation au {situationIntermediaire.endDate.format('DD/MM/YYYY')}</b>
                    </div>
                  </td>
                )}
                {previsionelPeriods.map((p, i) => (
                  <td key={p.id}>
                    <div
                      style={
                        note.type === NoteType.StructureExistante ? { textAlign: 'center' } : {}
                      }
                    >
                      <b>{dictionnary.periodTitle(p, i)}</b>
                    </div>
                    {note.type !== NoteType.StructureExistante && (
                      <div style={{ color: 'grey', fontSize: '0.8em' }}>
                        Du {formatDayjs(p.startDate)} <br />
                        au {formatDayjs(p.endDate)}
                      </div>
                    )}
                  </td>
                ))}
              </tr>
            }
          >
            <EmptyLine />
            <EmptyLine />
            {noteSettings.showBfrInverse ? (
              <BfrCalculatedLineWithDetails
                label={'Stock (en jours de rotation)'}
                fieldName={'bfr.bfrNormatif.stock'}
                anterieurFieldName={'bfrAnterieur.normatif.stock'}
                suffix={Suffixes.JOURS}
              />
            ) : (
              <BfrLine
                label={'Stock (en jours de rotation)'}
                fieldName={'bfr.bfrNormatif.stock'}
                anterieurFieldName={'bfrAnterieur.normatif.stock'}
                suffix={Suffixes.JOURS}
                isEuro={false}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrCalculatedLineWithDetails
                label={"Avances et acomptes fournisseurs (en jours d'achats et charges externes)"}
                fieldName={'bfr.bfrNormatif.avances_acomptes_fournisseurs'}
                anterieurFieldName={'bfrAnterieur.normatif.avances_acomptes_fournisseurs'}
                suffix={Suffixes.JOURS}
              />
            ) : (
              <BfrLine
                label={"Avances et acomptes fournisseurs (en jours d'achats et charges externes)"}
                fieldName={'bfr.bfrNormatif.avances_acomptes_fournisseurs'}
                anterieurFieldName={'bfrAnterieur.normatif.avances_acomptes_fournisseurs'}
                suffix={Suffixes.JOURS}
                isEuro={false}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrCalculatedLineWithDetails
                label={"Créances clients (en jours de chiffre d'affaires)"}
                fieldName={'bfr.bfrNormatif.creances_clients'}
                anterieurFieldName={'bfrAnterieur.normatif.creances_clients'}
                suffix={Suffixes.JOURS}
              />
            ) : (
              <BfrLine
                label={"Créances clients (en jours de chiffre d'affaires)"}
                fieldName={'bfr.bfrNormatif.creances_clients'}
                anterieurFieldName={'bfrAnterieur.normatif.creances_clients'}
                suffix={Suffixes.JOURS}
                isEuro={false}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrCalculatedLineWithDetails
                label={'Créances financeurs publics (en jours de subventions + aides aux postes)'}
                fieldName={'bfr.bfrNormatif.creances_financeurs_publics'}
                anterieurFieldName={'bfrAnterieur.normatif.creances_financeurs_publics'}
                suffix={Suffixes.JOURS}
              />
            ) : (
              <BfrLine
                label={'Créances financeurs publics (en jours de subventions + aides aux postes)'}
                fieldName={'bfr.bfrNormatif.creances_financeurs_publics'}
                anterieurFieldName={'bfrAnterieur.normatif.creances_financeurs_publics'}
                suffix={Suffixes.JOURS}
                isEuro={false}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrCalculatedLineWithDetails
                label={'Autres créances'}
                fieldName={'bfr.bfrNormatif.autres_creances'}
                anterieurFieldName={'bfrAnterieur.normatif.autres_creances'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            ) : (
              <BfrLine
                label={'Autres créances'}
                fieldName={'bfr.bfrNormatif.autres_creances'}
                anterieurFieldName={'bfrAnterieur.normatif.autres_creances'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            )}
          </FinancialTable>

          <TableSeparation extraClassName={'bfr'}>
            <th className={'section-title'}>Ressources d'exploitation</th>
            {previsionelPeriods.map((p) => (
              <td key={`${p.id}ressources_exploitation`} />
            ))}
          </TableSeparation>

          <FinancialTable className={'bfr'}>
            {noteSettings.showBfrInverse ? (
              <BfrCalculatedLineWithDetails
                label={"Avances et acomptes clients (en jours de chiffre d'affaires)"}
                fieldName={'bfr.bfrNormatif.avances_acomptes_client'}
                anterieurFieldName={'bfrAnterieur.normatif.avances_acomptes_client'}
                suffix={Suffixes.JOURS}
              />
            ) : (
              <BfrLine
                label={"Avances et acomptes clients (en jours de chiffre d'affaires)"}
                fieldName={'bfr.bfrNormatif.avances_acomptes_client'}
                anterieurFieldName={'bfrAnterieur.normatif.avances_acomptes_client'}
                suffix={Suffixes.JOURS}
                isEuro={false}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrCalculatedLineWithDetails
                label={"Dettes fournisseurs (en jours d'achats + charges externes)"}
                fieldName={'bfr.bfrNormatif.dettes_fournisseurs'}
                anterieurFieldName={'bfrAnterieur.normatif.dettes_fournisseurs'}
                suffix={Suffixes.JOURS}
              />
            ) : (
              <BfrLine
                label={"Dettes fournisseurs (en jours d'achats + charges externes)"}
                fieldName={'bfr.bfrNormatif.dettes_fournisseurs'}
                anterieurFieldName={'bfrAnterieur.normatif.dettes_fournisseurs'}
                suffix={Suffixes.JOURS}
                isEuro={false}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            )}
            <DettesFiscalesLines
              inNormatif={true}
              isSubtotal={noteSettings.showBfrInverse}
              isCalculate={computationInProgress}
            />
            {noteSettings.showBfrInverse ? (
              <BfrCalculatedLineWithDetails
                label={'Autres dettes'}
                fieldName={'bfr.bfrNormatif.autres_dettes'}
                anterieurFieldName={'bfrAnterieur.normatif.autres_dettes'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            ) : (
              <BfrLine
                label={'Autres dettes'}
                fieldName={'bfr.bfrNormatif.autres_dettes'}
                anterieurFieldName={'bfrAnterieur.normatif.autres_dettes'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            )}
          </FinancialTable>

          <TableSeparation extraClassName={'bfr'}>
            <th className={'section-title'}>
              <div>
                <H2 style={{ marginTop: '15px' }}>BFR Réel</H2>
                Besoins d'exploitation
              </div>
            </th>
            {previsionelPeriods.map((p) => (
              <td key={`${p.id}besoins_exploitation`} />
            ))}
          </TableSeparation>

          <FinancialTable className={'bfr'}>
            {noteSettings.showBfrInverse ? (
              <BfrLine
                label={'Stock'}
                fieldName={'bfr.bfrReel.stock'}
                anterieurFieldName={'bfrAnterieur.reel.stock'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            ) : (
              <BfrCalculatedLineWithDetails
                label={'Stock'}
                fieldName={'bfr.bfrReel.stock'}
                anterieurFieldName={'bfrAnterieur.reel.stock'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrLine
                label={'Avances et acomptes fournisseurs'}
                fieldName={'bfr.bfrReel.avances_acomptes_fournisseurs'}
                anterieurFieldName={'bfrAnterieur.reel.avances_acomptes_fournisseurs'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            ) : (
              <BfrCalculatedLineWithDetails
                label={'Avances et acomptes fournisseurs'}
                fieldName={'bfr.bfrReel.avances_acomptes_fournisseurs'}
                anterieurFieldName={'bfrAnterieur.reel.avances_acomptes_fournisseurs'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrLine
                label={'Créances clients'}
                fieldName={'bfr.bfrReel.creances_clients'}
                anterieurFieldName={'bfrAnterieur.reel.creances_clients'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            ) : (
              <BfrCalculatedLineWithDetails
                label={'Créances clients'}
                fieldName={'bfr.bfrReel.creances_clients'}
                anterieurFieldName={'bfrAnterieur.reel.creances_clients'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrLine
                label={'Créances financeurs publics'}
                fieldName={'bfr.bfrReel.creances_financeurs_publics'}
                anterieurFieldName={'bfrAnterieur.reel.creances_financeurs_publics'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            ) : (
              <BfrCalculatedLineWithDetails
                label={'Créances financeurs publics'}
                fieldName={'bfr.bfrReel.creances_financeurs_publics'}
                anterieurFieldName={'bfrAnterieur.reel.creances_financeurs_publics'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrLine
                label={'Autres créances'}
                fieldName={'bfr.bfrReel.autres_creances'}
                anterieurFieldName={'bfrAnterieur.reel.autres_creances'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            ) : (
              <BfrCalculatedLineWithDetails
                label={'Autres créances'}
                fieldName={'bfr.bfrReel.autres_creances'}
                anterieurFieldName={'bfrAnterieur.reel.autres_creances'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            )}
            <BfrAnterieurOnlyLine
              label={'Charges constatées d’avance et à répartir'}
              anterieurFieldName={'bfrAnterieur.reel.charges_constatees'}
            />
            <BfrCalculatedLineWithDetails
              label={"Total besoins d'exploitation"}
              fieldName={'bfr.bfrTotalBesoinExploitation'}
              anterieurFieldName={'bfrAnterieur.reel.total_besoins'}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
              isTotal={true}
            />
          </FinancialTable>

          <TableSeparation extraClassName={'bfr'}>
            <th className={'section-title'}>Ressources d'exploitation</th>
            {previsionelPeriods.map((_) => (
              <td key={`${Math.random()}`} />
            ))}
          </TableSeparation>

          <FinancialTable className={'bfr'}>
            {noteSettings.showBfrInverse ? (
              <BfrLine
                label={'Avances et acomptes clients'}
                fieldName={'bfr.bfrReel.avances_acomptes_client'}
                anterieurFieldName={'bfrAnterieur.reel.avances_acomptes_client'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            ) : (
              <BfrCalculatedLineWithDetails
                label={'Avances et acomptes clients'}
                fieldName={'bfr.bfrReel.avances_acomptes_client'}
                anterieurFieldName={'bfrAnterieur.reel.avances_acomptes_client'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            )}
            {noteSettings.showBfrInverse ? (
              <BfrLine
                label={'Dettes fournisseurs'}
                fieldName={'bfr.bfrReel.dettes_fournisseurs'}
                anterieurFieldName={'bfrAnterieur.reel.dettes_fournisseurs'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            ) : (
              <BfrCalculatedLineWithDetails
                label={'Dettes fournisseurs'}
                fieldName={'bfr.bfrReel.dettes_fournisseurs'}
                anterieurFieldName={'bfrAnterieur.reel.dettes_fournisseurs'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            )}
            <DettesFiscalesLines
              inNormatif={false}
              isSubtotal={!noteSettings.showBfrInverse}
              isCalculate={computationInProgress}
            />
            {noteSettings.showBfrInverse ? (
              <BfrLine
                label={'Autres dettes'}
                fieldName={'bfr.bfrReel.autres_dettes'}
                anterieurFieldName={'bfrAnterieur.reel.autres_dettes'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isEuro={true}
                isDetail={false}
                isCalculate={computationInProgress}
              />
            ) : (
              <BfrCalculatedLineWithDetails
                label={'Autres dettes'}
                fieldName={'bfr.bfrReel.autres_dettes'}
                anterieurFieldName={'bfrAnterieur.reel.autres_dettes'}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
              />
            )}
            <BfrAnterieurOnlyLine
              label={"Produits constatés d'avance et fonds dédiés"}
              anterieurFieldName={'bfrAnterieur.reel.produits_constates'}
            />
            <BfrCalculatedLineWithDetails
              label={"Total ressources d'exploitation"}
              fieldName={'bfr.bfrTotalRessourcesExploitation'}
              anterieurFieldName={'bfrAnterieur.reel.total_ressources'}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
              isTotal={true}
            />
          </FinancialTable>
          <FinancialTable className={'bfr'}>
            <BfrCalculatedLineWithDetails
              label={'BFR'}
              fieldName={'bfr.bfrTotal'}
              anterieurFieldName={'bfrAnterieur.reel.total'}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
              isTotal={true}
              isResult={true}
            />
            <BfrVariationLine />
          </FinancialTable>
        </div>
      </PeriodsContext.Provider>
    </div>
  )
}

const BfrLine: FC<{
  label: string
  fieldName: string
  anterieurFieldName?: string
  isEuro: boolean
  isDetail: boolean
  suffix: string
  isCalculate: boolean
}> = ({
  label,
  fieldName,
  anterieurFieldName = undefined,
  isEuro,
  isDetail,
  suffix,
  isCalculate,
}) => {
  const { showAnterieurs, anterieurPeriods, situationIntermediaire } = useContext(PeriodsContext)

  return (
    <tr className={'hoverable'}>
      <th className={isDetail ? 'detail' : ''} style={{ display: 'flex', alignItems: 'baseline' }}>
        {isDetail && <VerticalLine />}
        <div style={{ marginLeft: isDetail ? '20px' : '' }}>{label}</div>
        <Dots />
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p) => {
          if (anterieurFieldName === undefined) {
            return <EmptyCell isAnterieur={true} key={Math.random()} />
          }
          return (
            <CalculatedCellWithCalculDetail
              key={`${anterieurFieldName}_${p.id}`}
              fieldName={anterieurFieldName!!}
              period={p}
              suffix={suffix}
              className={'anterieur'}
            />
          )
        })}
      {situationIntermediaire && anterieurFieldName === undefined && (
        <EmptyCell isSituationIntermediaire={true} key={Math.random()} />
      )}
      {situationIntermediaire && anterieurFieldName && (
        <CalculatedCellWithCalculDetail
          key={`${anterieurFieldName}_${situationIntermediaire.id}`}
          fieldName={anterieurFieldName!!}
          period={situationIntermediaire}
          suffix={suffix}
          className={'intermediaire'}
        />
      )}
      <FinanciaryLine
        fieldPath={fieldName}
        isEuro={isEuro}
        forceAnterieurHide={true}
        forceSituationIntermediaireHide={true}
        isCalculatedData={true}
        isCalculate={isCalculate}
      />
    </tr>
  )
}

const EmptyCell: FC<{ isAnterieur?: boolean; isSituationIntermediaire?: boolean }> = ({
  isAnterieur = false,
  isSituationIntermediaire = false,
}) => {
  return (
    <td className={isAnterieur ? 'anterieur' : isSituationIntermediaire ? 'intermediaire' : ''}>
      &nbsp;
    </td>
  )
}

const DettesFiscalesLine: FC<{
  showInverse?: boolean
  isSubtotal?: boolean
  inNormatif?: boolean
}> = ({ showInverse = false, isSubtotal = false, inNormatif = true }) => {
  const { showAnterieurs, anterieurPeriods, periods, situationIntermediaire } =
    useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)
  const anterieurFieldName = inNormatif
    ? 'bfrAnterieur.normatif.dettes_fiscales.total'
    : 'bfrAnterieur.reel.dettes_fiscales.total'

  return (
    <tr className={isSubtotal ? 'outlined subtotal hoverable' : 'hoverable'}>
      <th
        style={{ display: 'flex', alignItems: 'baseline' }}
        className={isSubtotal ? 'outlined' : ''}
      >
        <div>Dettes fiscales et sociales</div>
        <Dots />
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p) => {
          return (
            <CalculatedCellWithCalculDetail
              key={`${anterieurFieldName}_${p.id}`}
              fieldName={anterieurFieldName!!}
              period={p}
              suffix={inNormatif ? Suffixes.JOURS : eurosSuffix(noteSettings.kiloEuros)}
              className={inNormatif ? 'anterieur' : showInverse ? 'anterieur' : ''}
            />
          )
        })}
      {situationIntermediaire && (
        <CalculatedCellWithCalculDetail
          key={`${anterieurFieldName}_${situationIntermediaire.id}`}
          fieldName={anterieurFieldName!!}
          period={situationIntermediaire}
          suffix={inNormatif ? Suffixes.JOURS : eurosSuffix(noteSettings.kiloEuros)}
          className={inNormatif ? 'intermediaire' : ''}
        />
      )}
      {inNormatif &&
        periods.map((p) => {
          return (
            <CalculatedCellWithCalculDetail
              key={`bfr.bfrNormatif.dettes_fiscales_sociales_${p.id}`}
              fieldName={'bfr.bfrNormatif.dettes_fiscales_sociales'}
              period={p}
              suffix={Suffixes.JOURS}
            />
          )
        })}
      {!inNormatif && periods.map((p) => <EmptyCell key={`dettes_fiscales_${p.id}`} />)}
    </tr>
  )
}

const BfrAnterieurOnlyLine: FC<{ anterieurFieldName: string; label: string }> = ({
  anterieurFieldName,
  label,
}) => {
  const { showAnterieurs, anterieurPeriods, periods, situationIntermediaire } =
    useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)

  return (
    <tr className={'outlined subtotal hoverable'}>
      <th style={{ display: 'flex', alignItems: 'baseline' }} className={'outlined'}>
        <div>{label}</div>
        <Dots />
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p) => {
          return (
            <CalculatedCellWithCalculDetail
              key={`${anterieurFieldName}_${p.id}`}
              fieldName={anterieurFieldName!!}
              period={p}
              suffix={eurosSuffix(noteSettings.kiloEuros)}
            />
          )
        })}
      {situationIntermediaire && (
        <CalculatedCellWithCalculDetail
          key={`${anterieurFieldName}_${situationIntermediaire.id}`}
          fieldName={anterieurFieldName!!}
          period={situationIntermediaire}
          suffix={eurosSuffix(noteSettings.kiloEuros)}
        />
      )}
      {periods.map((p) => {
        return <EmptyCell key={`${anterieurFieldName}_${p.id}`} />
      })}
    </tr>
  )
}

const BfrCalculatedLineWithDetails: FC<{
  label: string
  fieldName: string
  anterieurFieldName?: string
  suffix: string
  isSubLine?: boolean
  isResult?: boolean
  isTotal?: boolean
  noBackground?: boolean
}> = ({
  label,
  fieldName,
  anterieurFieldName,
  suffix,
  isSubLine = false,
  isResult = false,
  isTotal = false,
  noBackground = true,
}) => {
  const { periods, anterieurPeriods, showAnterieurs, situationIntermediaire } =
    useContext(PeriodsContext)

  return (
    <tr
      className={isResult ? 'result' : 'subtotal hoverable'}
      style={{ fontWeight: isTotal ? 600 : 'normal' }}
    >
      <th className={'outlined'}>
        <div style={{ marginLeft: isSubLine ? '20px' : 'Opx' }}>{label}</div>
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p) => {
          if (anterieurFieldName === undefined) {
            return <EmptyCell key={`${fieldName}_${p.id}`} />
          }
          if (getField<CalculatedData>(anterieurFieldName, p)?.numericData) {
            return (
              <CalculatedCellWithCalculDetail
                key={`${anterieurFieldName}_${p.id}`}
                fieldName={anterieurFieldName}
                period={p}
                suffix={suffix}
                isResult={isResult}
              />
            )
          }
          return (
            <CalculatedCell
              key={`${anterieurFieldName}_${p.id}`}
              fieldName={anterieurFieldName}
              period={p}
              suffix={suffix}
            />
          )
        })}
      {situationIntermediaire && anterieurFieldName === undefined && (
        <EmptyCell key={`${fieldName}_${situationIntermediaire.id}`} />
      )}
      {situationIntermediaire && anterieurFieldName && (
        <>
          {getField<CalculatedData>(anterieurFieldName, situationIntermediaire)?.numericData ? (
            <CalculatedCellWithCalculDetail
              key={`${anterieurFieldName}_${situationIntermediaire.id}`}
              fieldName={anterieurFieldName}
              period={situationIntermediaire}
              suffix={suffix}
              isResult={isResult}
            />
          ) : (
            <CalculatedCell
              key={`${anterieurFieldName}_${situationIntermediaire.id}`}
              fieldName={anterieurFieldName}
              period={situationIntermediaire}
              suffix={suffix}
              noBackground={noBackground}
            />
          )}
        </>
      )}
      {periods.map((p) => {
        if (getField<CalculatedData>(fieldName, p)?.numericData) {
          return (
            <CalculatedCellWithCalculDetail
              key={`${fieldName}_${p.id}`}
              fieldName={fieldName}
              period={p}
              suffix={suffix}
              isResult={isResult}
            />
          )
        }
        return (
          <CalculatedCell
            key={`${fieldName}_${p.id}`}
            fieldName={fieldName}
            period={p}
            suffix={suffix}
          />
        )
      })}
    </tr>
  )
}

const BfrVariationLine: FC = () => {
  const { periods, anterieurPeriods, showAnterieurs, situationIntermediaire } =
    useContext(PeriodsContext)
  const { noteSettings } = useContext(NoteContext)
  const anterieurFieldName = 'bfrAnterieur.reel.variation'
  const fieldName = 'bfr.bfrReel.variation'
  return (
    <tr className={'result'} style={{ fontWeight: 600 }}>
      <th className={'outlined'}>
        <div>Variation de BFR</div>
      </th>
      {showAnterieurs &&
        (anterieurPeriods ?? []).map((p, index) => {
          if (index > 0) {
            return (
              <CalculatedCellWithCalculDetail
                key={`${anterieurFieldName}_${p.id}`}
                fieldName={anterieurFieldName}
                period={p}
                suffix={eurosSuffix(noteSettings.kiloEuros)}
                isResult={true}
              />
            )
          }
          return (
            <td key={`calculateField-${Math.random()}`}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  verticalAlign: 'middle',
                }}
              >
                <span style={{ paddingLeft: '10px' }}>-</span>
              </div>
            </td>
          )
        })}
      {situationIntermediaire && (
        <td key={`calculateField-${Math.random()}`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              verticalAlign: 'middle',
            }}
          >
            <span style={{ paddingLeft: '10px' }}>&nbsp;</span>
          </div>
        </td>
      )}
      {periods.map((p) => (
        <CalculatedCellWithCalculDetail
          key={`${fieldName}_${p.id}`}
          fieldName={fieldName}
          period={p}
          suffix={eurosSuffix(noteSettings.kiloEuros)}
          isResult={true}
        />
      ))}
    </tr>
  )
}

const DettesFiscalesLines: FC<{
  inNormatif?: boolean
  isSubtotal?: boolean
  isCalculate: boolean
}> = ({ inNormatif = false, isSubtotal = false, isCalculate }) => {
  const { anterieurPeriods } = useContext(PeriodsContext)
  const { note, noteSettings } = useContext(NoteContext)
  const fieldName = `bfrAnterieur.${inNormatif ? 'normatif' : 'reel'}.dettes_fiscales`

  const notEmptyAnterieursPeriods = anterieurPeriods.find((p) => {
    const result = getField(fieldName, p) as BfrDettesFiscalesSociales
    return result !== null && result.hasDetail
  })

  return (
    <>
      {noteSettings.showBfrInverse ? (
        <>
          {inNormatif ? (
            <BfrCalculatedLineWithDetails
              label={'Dettes fiscales et sociales'}
              fieldName={'bfr.bfrNormatif.dettes_fiscales_sociales'}
              anterieurFieldName={'bfrAnterieur.normatif.dettes_fiscales.total'}
              suffix={Suffixes.JOURS}
              isTotal={true}
            />
          ) : (
            <DettesFiscalesLine
              showInverse={noteSettings.showBfrInverse}
              inNormatif={inNormatif}
              isSubtotal={isSubtotal}
            />
          )}
        </>
      ) : (
        <DettesFiscalesLine inNormatif={inNormatif} isSubtotal={isSubtotal} />
      )}
      {isSubtotal ? (
        <>
          <BfrCalculatedLineWithDetails
            label={'Dettes sur salaires'}
            fieldName={
              inNormatif ? 'bfr.bfrNormatif.dettes_salaires' : 'bfr.bfrReel.dettes_salaires'
            }
            suffix={inNormatif ? Suffixes.JOURS : eurosSuffix(noteSettings.kiloEuros)}
            isSubLine={true}
            anterieurFieldName={
              note.type === NoteType.StructureExistante && notEmptyAnterieursPeriods
                ? `bfrAnterieur.${inNormatif ? 'normatif' : 'reel'}.dettes_fiscales.dettesSalaires`
                : undefined
            }
          />
          <BfrCalculatedLineWithDetails
            label={'Dettes sur cotisations sociales'}
            fieldName={
              inNormatif
                ? 'bfr.bfrNormatif.dettes_cotisations_sociales'
                : 'bfr.bfrReel.dettes_cotisations_sociales'
            }
            suffix={inNormatif ? Suffixes.JOURS : eurosSuffix(noteSettings.kiloEuros)}
            isSubLine={true}
            anterieurFieldName={
              note.type === NoteType.StructureExistante && notEmptyAnterieursPeriods
                ? `bfrAnterieur.${
                    inNormatif ? 'normatif' : 'reel'
                  }.dettes_fiscales.dettesCotisationsSociales`
                : undefined
            }
          />
          <BfrCalculatedLineWithDetails
            label={'Dettes fiscales TVA'}
            fieldName={
              inNormatif ? 'bfr.bfrNormatif.dettes_fiscales_tva' : 'bfr.bfrReel.dettes_fiscales_tva'
            }
            suffix={inNormatif ? Suffixes.JOURS : eurosSuffix(noteSettings.kiloEuros)}
            isSubLine={true}
            anterieurFieldName={
              note.type === NoteType.StructureExistante && notEmptyAnterieursPeriods
                ? `bfrAnterieur.${
                    inNormatif ? 'normatif' : 'reel'
                  }.dettes_fiscales.dettesFiscalesTva`
                : undefined
            }
          />
          <BfrCalculatedLineWithDetails
            label={'Dettes fiscales IS et diverses'}
            fieldName={
              inNormatif
                ? 'bfr.bfrNormatif.dettes_fiscales_is_diverses'
                : 'bfr.bfrReel.dettes_fiscales_is_diverses'
            }
            suffix={inNormatif ? Suffixes.JOURS : eurosSuffix(noteSettings.kiloEuros)}
            isSubLine={true}
            anterieurFieldName={
              note.type === NoteType.StructureExistante && notEmptyAnterieursPeriods
                ? `bfrAnterieur.${
                    inNormatif ? 'normatif' : 'reel'
                  }.dettes_fiscales.dettesFiscalesIsDiverses`
                : undefined
            }
          />
        </>
      ) : (
        <>
          <BfrLine
            label={'Dettes sur salaires'}
            fieldName={
              inNormatif ? 'bfr.bfrNormatif.dettes_salaires' : 'bfr.bfrReel.dettes_salaires'
            }
            anterieurFieldName={
              note.type === NoteType.StructureExistante && notEmptyAnterieursPeriods
                ? `bfrAnterieur.${inNormatif ? 'normatif' : 'reel'}.dettes_fiscales.dettesSalaires`
                : undefined
            }
            suffix={
              noteSettings.showBfrInverse ? eurosSuffix(noteSettings.kiloEuros) : Suffixes.JOURS
            }
            isEuro={noteSettings.showBfrInverse}
            isDetail={true}
            isCalculate={isCalculate}
          />
          <BfrLine
            label={'Dettes sur cotisations sociales'}
            fieldName={
              inNormatif
                ? 'bfr.bfrNormatif.dettes_cotisations_sociales'
                : 'bfr.bfrReel.dettes_cotisations_sociales'
            }
            anterieurFieldName={
              note.type === NoteType.StructureExistante && notEmptyAnterieursPeriods
                ? `bfrAnterieur.${
                    inNormatif ? 'normatif' : 'reel'
                  }.dettes_fiscales.dettesCotisationsSociales`
                : undefined
            }
            suffix={
              noteSettings.showBfrInverse ? eurosSuffix(noteSettings.kiloEuros) : Suffixes.JOURS
            }
            isEuro={noteSettings.showBfrInverse}
            isDetail={true}
            isCalculate={isCalculate}
          />
          <BfrLine
            label={'Dettes fiscales TVA'}
            fieldName={
              inNormatif ? 'bfr.bfrNormatif.dettes_fiscales_tva' : 'bfr.bfrReel.dettes_fiscales_tva'
            }
            anterieurFieldName={
              note.type === NoteType.StructureExistante && notEmptyAnterieursPeriods
                ? `bfrAnterieur.${
                    inNormatif ? 'normatif' : 'reel'
                  }.dettes_fiscales.dettesFiscalesTva`
                : undefined
            }
            suffix={
              noteSettings.showBfrInverse ? eurosSuffix(noteSettings.kiloEuros) : Suffixes.JOURS
            }
            isEuro={noteSettings.showBfrInverse}
            isDetail={true}
            isCalculate={isCalculate}
          />
          <BfrLine
            label={'Dettes fiscales IS et diverses'}
            fieldName={
              inNormatif
                ? 'bfr.bfrNormatif.dettes_fiscales_is_diverses'
                : 'bfr.bfrReel.dettes_fiscales_is_diverses'
            }
            anterieurFieldName={
              note.type === NoteType.StructureExistante && notEmptyAnterieursPeriods
                ? `bfrAnterieur.${
                    inNormatif ? 'normatif' : 'reel'
                  }.dettes_fiscales.dettesFiscalesIsDiverses`
                : undefined
            }
            suffix={
              noteSettings.showBfrInverse ? eurosSuffix(noteSettings.kiloEuros) : Suffixes.JOURS
            }
            isEuro={noteSettings.showBfrInverse}
            isDetail={true}
            isCalculate={isCalculate}
          />
        </>
      )}
    </>
  )
}
