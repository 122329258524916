import {
  BenchMarkDocument,
  FinancialPeriodsDocument,
  MasseSalarialeDocument,
  InvestissementsDocument,
  PlanFinancementDocument,
  RessourcesDocument,
  NoteSettingsDocument,
  useUpdateNoteSettingsMutation,
  RatiosDocument,
} from '../generated/graphql'
import produce from 'immer'
import {
  NoteSettings,
  NoteSettingsQuery,
  NoteSettingsQueryVariables,
  NoteSettingUpdateRequest,
} from '@fa-metier/types'

export const useUpdateNoteSettings = (noteId: string) => {
  const [update] = useUpdateNoteSettingsMutation({
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<NoteSettingsQuery, NoteSettingsQueryVariables>({
        query: NoteSettingsDocument,
        variables: { noteId },
      })!
      cache.writeQuery<NoteSettingsQuery, NoteSettingsQueryVariables>({
        query: NoteSettingsDocument,
        variables: { noteId },
        data: produce(readQuery, (draft) => {
          const mutationResultData = mutationResult.data!.noteSettings.update
          if (draft.noteSettings) {
            draft.noteSettings = mutationResultData
          }
        }),
      })
      cache.evictQueries([
        { query: FinancialPeriodsDocument, variables: { noteId } },
        { query: RessourcesDocument, variables: { noteId } },
        { query: MasseSalarialeDocument, variables: { noteId } },
        { query: BenchMarkDocument, variables: { noteId } },
        { query: InvestissementsDocument, variables: { noteId } },
        { query: PlanFinancementDocument, variables: { noteId } },
        { query: RatiosDocument, variables: { noteId } },
      ])
    },
    refetchQueries: [
      { query: FinancialPeriodsDocument, variables: { noteId } },
      { query: RessourcesDocument, variables: { noteId } },
      { query: MasseSalarialeDocument, variables: { noteId } },
      { query: BenchMarkDocument, variables: { noteId } },
      { query: InvestissementsDocument, variables: { noteId } },
      { query: PlanFinancementDocument, variables: { noteId } },
      { query: RatiosDocument, variables: { noteId } },
    ],
    awaitRefetchQueries: true,
  })
  return (updateRequest: NoteSettingUpdateRequest) => {
    return new Promise<NoteSettings>((resolve, reject) => {
      update({ variables: { noteId, updateRequest } })
        .then((response) => {
          const result = response.data && response.data.noteSettings.update
          // @ts-ignore
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}
