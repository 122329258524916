import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { createBrowserHistory } from 'history'
import env from './environment'

import React, { useContext } from 'react'
import { AuthStore } from '@fa-metier/commons'
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache'
import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { AppToaster } from '../utils/AppToaster'
import { Intent } from '@blueprintjs/core'

export interface Stores {
  authStore: AuthStore
  routingStore: RouterStore
}

export interface Services {
  apolloClient: ApolloClient<NormalizedCacheObject>
}

export type ServiceAndStore = Services & Stores

export const createContext = (): ServiceAndStore => {
  const routingStore = new RouterStore()

  const browserHistory = createBrowserHistory()
  syncHistoryWithStore(browserHistory, routingStore)

  const authStore = new AuthStore(env.AUTH_URL, 'front-yoda', ['advisor'])

  const httpLink = createHttpLink({
    uri: `${env.API_URL}/graphql`,
  })

  const getAssociationID = () => {
    const matcher = window.location.pathname.match(/\/project\/\w*-\d+/)
    if (matcher) {
      const parts = matcher[0].replace('/project/', '').split('-')
      if (parts) {
        return parts[0]
      }
    }
    return 'NOT_DEFINED'
  }

  const authLink = setContext(async (_, { headers }) => {
    const token = authStore.authHeader
    return {
      headers: {
        ...headers,
        authorization: token,
        'x-request-resource-for-at-id-x': getAssociationID(),
      },
    }
  })

  const ERROR_403_MESSAGE = 'Request failed with status code 403'
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (message === ERROR_403_MESSAGE) {
          AppToaster.show({
            message: `Vous n'avez pas accès à la ressource demandée, merci de contacter le gestionnaire des permissions.`,
            intent: Intent.DANGER,
          })
        }
        if (!!extensions && extensions['code'] === 'UNAUTHENTICATED') {
          authStore.login()
        }
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      })
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  })

  const keepIncomingMergeFn = {
    merge(existing: any[], incoming: any[]) {
      return incoming
    },
  }

  const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        FinancialPeriodMutations: keepIncomingMergeFn,
        NoteSettingsMutation: keepIncomingMergeFn,
        InvestissementsMutations: keepIncomingMergeFn,
      },
    }),
    headers: {
      authorization: authStore.authHeader,
      'x-request-resource-for-at-id-x': getAssociationID(),
    },
  })

  const stores: Stores = {
    authStore,
    routingStore,
  }

  const services: Services = {
    apolloClient,
  }

  return { ...stores, ...services }
}

const AppContext: React.Context<ServiceAndStore | null> =
  React.createContext<ServiceAndStore | null>(null)

export default AppContext

export const useAppContext: () => Services & Stores = () => {
  const context = useContext(AppContext)
  if (context == null) {
    throw new Error('No provider used')
  }
  return context
}
