import {
  InvestissementsDocument,
  useInvestissementsQuery,
  useUpdateInvestissementsMutation,
} from '../../../generated/graphql'
import {
  AmortissementLineInput,
  Investissements,
  InvestissementsQuery,
  InvestissementsQueryVariables,
  InvestissementsSection,
  UpdateInvestissementsInput,
} from '@fa-metier/types'
import produce from 'immer'
import { removeTypeName } from '@fa-metier/commons'

export const useGetInvestissements = (noteId: string) => {
  const { data, loading } = useInvestissementsQuery({ variables: { noteId } })
  return {
    loading,
    data: data ? data.investissements : undefined,
  }
}

export const useUpdateInvestissements = (noteId: string) => {
  const [investissementsMutation] = useUpdateInvestissementsMutation({
    update: (cache, mutationResult) => {
      const readQuery = cache.readQuery<InvestissementsQuery, InvestissementsQueryVariables>({
        query: InvestissementsDocument,
        variables: { noteId },
      })!
      cache.writeQuery<InvestissementsQuery, InvestissementsQueryVariables>({
        query: InvestissementsDocument,
        variables: { noteId },
        data: produce(readQuery, (draft) => {
          if (draft.investissements && mutationResult.data) {
            const updatedInvestissements = mutationResult.data.investissements.patch
            draft.investissements =
              draft.investissements.id === updatedInvestissements.id
                ? updatedInvestissements
                : draft.investissements
          }
        }),
      })
      cache.evictQueries([
        {
          query: InvestissementsDocument,
          variables: { noteId },
        },
      ])
    },
    refetchQueries: [{ query: InvestissementsDocument, variables: { noteId } }],
    awaitRefetchQueries: true,
  })
  return (update: UpdateInvestissementsInput) => {
    return new Promise<Investissements>((resolve, reject) => {
      investissementsMutation({
        variables: { noteId, update },
      })
        .then((response) => {
          if (response.data && response.data.investissements.patch === null) {
            reject()
          }
          const result = response.data && response.data.investissements.patch
          result ? resolve(result) : reject()
        })
        .catch((error) => reject(error))
    })
  }
}

export const toInvestissementsInput: (
  investissements: Investissements
) => UpdateInvestissementsInput = (investissements: Investissements) => {
  return removeTypeName({
    investissementsIncorporels: mapAmortissementLinesToInput(
      investissements.investissementsIncorporels
    ),
    investissementsCorporels: mapAmortissementLinesToInput(
      investissements.investissementsCorporels
    ),
    investissementsFinanciers: mapAmortissementLinesToInput(
      investissements.investissementsFinanciers
    ),
  })
}

export const mapAmortissementLinesToInput: (
  investissementsSection: InvestissementsSection
) => AmortissementLineInput[] = (investissementsSection: InvestissementsSection) => {
  const investissementsLines: AmortissementLineInput[] = investissementsSection.lines.map(
    (value) => {
      const line: AmortissementLineInput = {
        id: value.id,
        label: value.label,
        montant: value.montant,
        dureeAmortissement: value.dureeAmortissement,
        dateAchat: value.dateAchat,
        tvaRate: value.tvaRate,
      }
      return line
    }
  )
  return investissementsLines
}
