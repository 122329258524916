import React, { FC } from 'react'
import { useMySettingsQuery } from '../generated/graphql'
import { BreadcrumbRenderer } from '@fa-metier/components'
import { Breadcrumbs, H2, Radio, RadioGroup, Switch } from '@blueprintjs/core'
import { useUpdateSettings } from './SettingsQueries'
import styled from 'styled-components'
import { DEBOUNCE_CONFIG } from './debounceConfig'

const Params = styled.div`
  margin: 1vw;
`

export const AdvisorSettings: FC = () => {
  const { data, loading } = useMySettingsQuery()
  const updateSettings = useUpdateSettings()

  return (
    <Params>
      <Breadcrumbs
        items={[
          { href: '/', text: 'Accueil' },
          { text: 'Mon compte', current: true },
        ]}
        breadcrumbRenderer={BreadcrumbRenderer}
      />
      <H2>Paramètres</H2>
      {!loading && data && (
        <Params>
          <Switch
            checked={data.advisorSettings.settings.showInvolvementProfileHelp}
            label={"Afficher l'aide avancée pour les notes du révélateur d'engagement"}
            onChange={() =>
              updateSettings({
                showInvolvementProfileHelp:
                  !data.advisorSettings.settings.showInvolvementProfileHelp,
              })
            }
          />
          <Switch
            checked={data.advisorSettings.settings.kiloEuros}
            label={'Afficher les données en K€'}
            onChange={() =>
              updateSettings({
                kiloEuros: !data.advisorSettings.settings.kiloEuros,
              })
            }
          />
          <div style={{ marginTop: 20 }}>
            <RadioGroup
              inline={true}
              label="Délai entre deux synchronisations automatiques (en secondes)"
              onChange={(event) =>
                updateSettings({
                  debounce: parseInt(event.currentTarget.value, 10),
                })
              }
              selectedValue={
                data.advisorSettings.settings.debounce ?? DEBOUNCE_CONFIG.delay.longUpdateRequest
              }
            >
              <Radio label="1" value={1000} />
              <Radio label="2,5" value={2500} />
              <Radio label="5" value={5000} />
              <Radio label="10" value={10000} />
              <Radio label="15" value={15000} />
              <Radio label="30" value={30000} />
              <Radio label="60" value={60000} />
              <Radio label="120" value={120000} />
            </RadioGroup>
          </div>
        </Params>
      )}
    </Params>
  )
}
