import React, {FC, useContext, useState} from 'react'
import {TabContentTitle} from '../../../utils/PageTitle'
import {Radio} from '@blueprintjs/core'
import styled from 'styled-components'
import {useUpdateNoteSettings} from '../../NoteSettingsQueries'
import {FARadioGroup} from '@fa-metier/components/dist/form/FARadio'
import {NoteContext} from "../../NotePage";

export const FiscaliteForm: FC = () => {
  const { noteId, noteSettings } = useContext(NoteContext)
  const updateSettings = useUpdateNoteSettings(noteId)
  const assujetti = noteSettings.assujettiImpotsSocietes
  const [value, setValue] = useState(
    noteSettings.assujettiImpotsSocietes !== undefined && assujetti !== null
      ? assujetti
        ? 'TRUE'
        : 'FALSE'
      : 'EMPTY'
  )
  return (
    <div>
      <TabContentTitle>Impôt sur les sociétés</TabContentTitle>
      <div style={{ backgroundColor: 'white', padding: '20px', margin: '0' }}>
        <div>Assujetti à l’impôt sur les sociétés :</div>
        <StyledRadioGroup
          selectedValue={value}
          onChange={(event: any) => {
            const updated = event.target.value === 'TRUE'
            setValue(updated ? 'TRUE' : 'FALSE')
            updateSettings({ assujettiImpotsSocietes: updated })
          }}
        >
          <Radio label={'oui'} value={'TRUE'} />
          <Radio label={'non'} value={'FALSE'} />
        </StyledRadioGroup>
      </div>
    </div>
  )
}

export const StyledRadioGroup = styled(FARadioGroup)`
  width: max-content;
  margin-top: 1em;
`
export const InlinedRadioGroup = styled(StyledRadioGroup)`
  display: flex;
  flex-direction: row;
  label {
    margin-right: 2em;
  }
`
