import React, { ChangeEvent, FC, useLayoutEffect, useState } from 'react'
import { Classes, InputGroup, Intent } from '@blueprintjs/core'
import { handleUserKeyPress } from '../keyboard-navigation'

interface AppMetierTextInputProps {
  value: string | undefined
  inKiloEuros?: boolean
  suffix?: string
  customCSS?: React.CSSProperties
  textAlignEnd?: boolean
  onChange: (value: string) => any
  disabled?: boolean
  intent?: Intent
}

export const AppMetierTextInput: FC<AppMetierTextInputProps> = ({
  value,
  customCSS,
  textAlignEnd,
  onChange,
  intent,
  disabled,
}) => {
  const [leaveFocus, setLeaveFocus] = useState(true)

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
  })

  const alignText = () => {
    return textAlignEnd ? 'end' : ''
  }

  const onLeaveFocus = () => {
    setLeaveFocus(true)
  }

  const onFocus = () => {
    setLeaveFocus(false)
  }

  const updateValue = (value: string) => {
    onChange(value || '')
  }

  const defaultStyle = {
    width: 'fit-content',
    borderRadius: '5px',
    outline: 'none',
    height: '28px',
    margin: '5px',
    textAlign: alignText(),
    ...customCSS,
  } as React.CSSProperties

  const operationStyle = {
    focus: {
      ...defaultStyle,
      border: 'solid 1px var(--main-green-1)',
      color: 'var(--main-green-1)',
      boxShadow: 'none',
    } as React.CSSProperties,
    none: { ...defaultStyle } as React.CSSProperties,
  }

  return (
    <div className={`${Classes.INPUT_GROUP}`}>
      <InputGroup
        value={value}
        style={!leaveFocus ? operationStyle.focus : operationStyle.none}
        onFocus={onFocus}
        onBlur={onLeaveFocus}
        onChange={(event: ChangeEvent<HTMLInputElement>) => updateValue(event.target.value)}
        disabled={disabled}
        intent={intent}
      />
    </div>
  )
}
