import React, { CSSProperties, FC, useContext, useEffect, useState } from 'react'
import {
  ProjectEvaluationContext,
  useProjectEvaluationContextData,
} from '../context/ProjectEvaluationContext'
import { ProjectContext } from '../../App'
import { NoteContext } from '../NotePage'
import styled from 'styled-components'
import { ReactComponent as AnalyseIcon } from '../../assets/Creativity-pana.svg'
import { ReactComponent as RevelateurIcon } from '../../assets/High-five-pana.svg'
import { ReactComponent as FinancierIcon } from '../../assets/Analytics-pana-4.svg'
import { ReactComponent as PdfSuccess } from '../../assets/Winners-pana.svg'
import PageTitle from '../../utils/PageTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppContext } from '../../config/context'
import 'leaflet/dist/leaflet.css'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import { OpenStreetMapProvider } from 'leaflet-geosearch/lib'
import { AppMetierSelect, SelectItem } from '../../utils/AppMetierSelect'
import { useNoteGeneratePdf, useUpdateNote } from '../Financiary/NoteQueries'
import { Classes, Dialog, Intent } from '@blueprintjs/core'
import './Presentation.scss'
import { Lifecycle, NoteType, PdfPreferences } from '@fa-metier/types'
import { ReactComponent as Arrow } from '../../assets/arrow.svg'
import { CustomAlert, pdfErrorContent } from '../../utils/CustomAlert'
import dayjs from 'dayjs'
import { YodaSpinnerPage } from '../../utils/YodaSpinnerPage'
import { PdfButton, PdfDialogContent } from './PdfDialogContent'
import { AppToaster } from '../../utils/AppToaster'
import { DEBOUNCE_CONFIG } from '../../Settings/debounceConfig'
import { useDebounce } from 'react-use'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--main-green-4);
  color: white;
  padding: 15px;
`

const HeaderProjet = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-style: normal;
  font-weight: var(--bold);
  font-size: 16px;
  max-width: 33%;
  min-height: 150px;
`

const InnerHeaderProjet = styled.div`
  margin-top: 5px;
  padding: 15px;
  font-weight: var(--normal);
  background-color: var(--main-blue-2);
  color: var(--main-grey-1);
  min-height: 130px;
`

const HeaderParcours = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-style: normal;
  font-weight: var(--bold);
  font-size: 16px;
  max-width: 33%;
  min-height: 150px;
`

const InnerHeaderParcours = styled.div`
  margin-top: 5px;
  padding: 15px;
  flex-direction: column;
  font-weight: var(--normal);
  background-color: var(--main-blue-3);
  color: var(--main-grey-1);
  min-height: 130px;
`

const HeaderSuivi = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-style: normal;
  font-weight: var(--bold);
  font-size: 16px;
  max-width: 33%;
  min-height: 150px;
`

const InnerHeaderSuivi = styled.div`
  margin-top: 5px;
  padding: 15px;
  flex-direction: column;
  font-weight: var(--normal);
  background-color: var(--main-green-1);
  min-height: 130px;
`

const InnerBody = styled.div`
  margin: 15px -10px -10px;
  flex-direction: column;
  font-weight: var(--normal);
  color: var(--main-grey-1);
`

const Line = styled.div`
  display: flex;
`

const ItemLine = styled.div`
  width: 50%;
  margin: 10px;
`

const ItemLineBold = styled(ItemLine)`
  font-weight: var(--bold);
`

const Body = styled.div`
  margin-top: 30px;
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 45px;
`

const BodyRow = styled.div`
  display: flex;
`

const BodyColumn = styled(BodyRow)`
  flex-direction: column;
`

const BodyColumnInner = styled(BodyColumn)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-style: normal;
  font-weight: var(--bold);
  font-size: 16px;
  color: var(--main-green-1);
  padding-left: 80px;
`

const BodyCard = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 50%;
`

const ButtonNav = styled.div`
  display: flex;
  flex-direction: row;
  background-color: var(--main-green-1);
  color: white;
  padding: 15px;

  &:hover {
    cursor: pointer;
  }
`

const BackgroundGrey = styled.div`
  background-color: rgba(232, 232, 232, 0.2);
  margin-left: -75px;
  padding: 15px;
  margin-top: 60px;
`

const DivMap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -65px;
`

const HeaderDivMap = styled.div`
  background-color: rgba(0, 204, 170, 0.5);
  color: white;
  margin-left: auto;
  width: 55%;
  margin-bottom: 40px;
  margin-right: -70px;
`

const TitleHeaderMap = styled.div`
  font-weight: var(--bold);
  padding: 30px 30px 5px;
  font-size: 20px;
`

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
})

type CardDescriptionProps = {
  title: string
  texte: string
  to: string
  buttonTexte: string
  style?: CSSProperties
}

const CardDescription: FC<CardDescriptionProps> = ({ title, texte, to, buttonTexte, style }) => {
  const { baseNotePath } = useContext(NoteContext)
  const { routingStore } = useAppContext()

  return (
    <BodyCard style={style}>
      <ItemLineBold style={{ color: 'var(--main-green-1)', fontSize: '20px' }}>
        {title}
      </ItemLineBold>
      <ItemLine style={{ color: 'var(--main-grey-1)', fontSize: '16px' }}>{texte}</ItemLine>
      <ItemLine>
        <ButtonNav
          id={title}
          style={{ width: '80%' }}
          onClick={() => routingStore.history.push(`${baseNotePath}${to}`)}
        >
          <div style={{ margin: '5px' }}>
            <FontAwesomeIcon icon={['fas', 'arrow-right']} color={'white'} size={'lg'} />
          </div>
          <div style={{ margin: '6px' }}>{buttonTexte}</div>
        </ButtonNav>
      </ItemLine>
    </BodyCard>
  )
}

const Map: FC = () => {
  const { structure } = useContext(ProjectContext)

  const [longitude, setLongitude] = useState<number>()
  const [latitude, setLatitude] = useState<number>()

  const providerAdress = new OpenStreetMapProvider()

  if (structure.address) {
    const result = providerAdress.search({
      query: `${structure.address?.street} ${structure.address?.zipCode} ${structure.address?.city}`,
    })
    result.then((value) => {
      if (value[0]) {
        setLongitude(value[0].x)
        setLatitude(value[0].y)
      }
    })
  }

  return (
    <>
      {longitude && latitude && structure.address !== null && (
        <MapContainer
          center={[latitude, longitude]}
          zoom={14}
          scrollWheelZoom={false}
          style={{ height: '30vh', marginTop: '-40vh', marginLeft: '75px', zIndex: 0 }}
        >
          <TileLayer
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[latitude, longitude]} icon={DefaultIcon} />
        </MapContainer>
      )}
    </>
  )
}

const FlyingPdfButton = styled(PdfButton)`
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;

  &:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(
      center,
      ellipse,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    );
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    ); /* W3C */
  }

  &:hover {
    transform: translateY(-5px);

    &:before {
      opacity: 1;
      transform: translateY(5px);
    }
  }
`

const models = [
  '',
  'PAS_DE_CONTACT_OU_DEBUT_DE_LA_DEMANDE_EN_COURS',
  'EN_ATTENTE_REPONSE_DE_LA_BANQUE',
  'ACCORD_DE_PRINCIPE_ORAL',
  'ACCORD_ECRIT',
  'REFUS_DE_LA_BANQUE',
  'NON_CONCERNE',
]

const avancementBanqueModelToSelectItem: (model: string) => SelectItem = (model) => {
  return {
    id: model,
    text: getAvancementBanqueLabel(model),
  }
}

const getAvancementBanqueLabel = (avancementBanque: String) => {
  switch (avancementBanque) {
    case 'PAS_DE_CONTACT_OU_DEBUT_DE_LA_DEMANDE_EN_COURS':
      return 'Pas de contact ou début de la demande en cours'
    case 'EN_ATTENTE_REPONSE_DE_LA_BANQUE':
      return 'En attente de réponse de la banque'
    case 'ACCORD_DE_PRINCIPE_ORAL':
      return 'Accord de principe oral'
    case 'ACCORD_ECRIT':
      return 'Accord écrit'
    case 'REFUS_DE_LA_BANQUE':
      return 'Refus de la banque'
    case 'NON_CONCERNE':
      return 'Non concerné'
    case '':
    default:
      return ''
  }
}

export const Presentation: FC = () => {
  const {
    projectId,
    structure,
    referenceDossier,
    partenaireMontage,
    prescripteur,
    associationName,
  } = useContext(ProjectContext)
  const { note, setUnsavedChanges } = useContext(NoteContext)
  const { data: projectContext, loading: projectContextLoading } = useProjectEvaluationContextData(
    projectId,
    note.noteId
  )

  const advisor = note.advisor
  const noteId = note.noteId
  const updateNote = useUpdateNote(noteId)

  const [avancementBanque, setAvancementBanque] = useState<string>(
    note.avancementBanque ? note.avancementBanque : models[0]
  )

  useDebounce(
    async () => {
      if (avancementBanque !== models[0] && avancementBanque !== note.avancementBanque) {
        await updateNote({ noteId, avancementBanque })
      }
      setUnsavedChanges(false)
    },
    DEBOUNCE_CONFIG.delay.updateRequest,
    [avancementBanque]
  )

  useEffect(() => {
    if (avancementBanque !== models[0] && avancementBanque !== note.avancementBanque) {
      setUnsavedChanges(true)
    }
  }, [avancementBanque, setUnsavedChanges])

  const [openPdf, setOpenPdf] = useState(false)
  const [pdfSuccess, setPdfSuccess] = useState(false)
  const [linkPdf, setLinkPdf] = useState<string>('')
  const generatePdfMutation = useNoteGeneratePdf()
  const [pdfErrorAlertOpen, setPdfErrorAlertOpen] = useState(false)

  const generatePdf = (pdfOptions: PdfPreferences) => {
    const showBilan = note.type === NoteType.StructureExistante ? true : pdfOptions.bilanCedant

    const request = {
      noteId,
      projectId,
      caDetails: pdfOptions.caDetails,
      sig: pdfOptions.sig,
      chargesDetails: pdfOptions.chargesDetails,
      indicateurs: pdfOptions.indicateurs,
      rappelBFR: pdfOptions.rappelBFR,
      benchmark: pdfOptions.benchmark,
      investissements: pdfOptions.investissements,
      allAnterieursPeriods: pdfOptions.allAnterieursPeriods,
      bilanCedant: showBilan,
      revelateurEngagement: pdfOptions.revelateurEngagement,
      pourcentageCR: pdfOptions.pourcentageCR,
    }
    generatePdfMutation(request)
      .then((res) => {
        setOpenPdf(false)
        setPdfSuccess(true)
        setLinkPdf(res.link)
        window.open(res.link, '_blank')
      })
      .catch((error) => {
        console.log(error)
        setOpenPdf(false)
        // setPdfErrorAlertOpen(true)
        AppToaster.show({
          message: `Une erreur est survenue lors de la génération du PDF`,
          intent: Intent.DANGER,
        })
      })
  }

  const onCloseFct = () => {
    setPdfErrorAlertOpen(false)
  }

  return (
    <>
      {projectContextLoading && <YodaSpinnerPage />}
      {!projectContextLoading && (
        <ProjectEvaluationContext.Provider value={projectContext}>
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <PageTitle>{projectContext.projectName}</PageTitle>
              <div style={{ verticalAlign: 'middle', marginTop: '20px' }}>
                <FlyingPdfButton onClick={() => setOpenPdf(true)} large={true}>
                  <FontAwesomeIcon icon={['far', 'file-pdf']} /> Générer la note pdf
                </FlyingPdfButton>
                <Dialog
                  isCloseButtonShown={true}
                  title={
                    <DialogTitle>
                      <Arrow style={{ color: `var(--main-green-1)` }} /> Paramétrage de la note pdf
                    </DialogTitle>
                  }
                  isOpen={openPdf}
                  onClose={() => setOpenPdf(false)}
                  className={Classes.DIALOG}
                  style={{ width: '800px', backgroundColor: 'white' }}
                >
                  <PdfDialogContent generateFct={generatePdf} />
                </Dialog>
                <Dialog
                  isCloseButtonShown={true}
                  title=""
                  isOpen={pdfSuccess}
                  onClose={() => setPdfSuccess(false)}
                  className={Classes.DIALOG}
                  style={{ width: '800px', backgroundColor: 'white' }}
                >
                  <PdfSuccessDialogContent
                    closeButtonFct={() => setPdfSuccess(false)}
                    linkPdf={linkPdf}
                  />
                </Dialog>
                <CustomAlert
                  content={pdfErrorContent}
                  isOpen={pdfErrorAlertOpen}
                  onClose={onCloseFct}
                />
              </div>
            </div>

            <Header>
              <HeaderProjet>
                Projet
                <InnerHeaderProjet>
                  <Line>
                    <ItemLine style={{ width: '35%' }}>Phase de vie</ItemLine>
                    <ItemLineBold>{getLifecycle(note.lifecycle)}</ItemLineBold>
                  </Line>
                  <Line>
                    <ItemLine style={{ width: '35%' }}>Avancement banque</ItemLine>
                    <ItemLine style={{ margin: '0px' }}>
                      <AppMetierSelect
                        value={
                          avancementBanque
                            ? avancementBanqueModelToSelectItem(avancementBanque)
                            : undefined
                        }
                        onChange={(event) => setAvancementBanque(event.id)}
                        items={models.map((v) => avancementBanqueModelToSelectItem(v))}
                      />
                    </ItemLine>
                  </Line>
                </InnerHeaderProjet>
              </HeaderProjet>
              <HeaderParcours>
                Parcours
                <InnerHeaderParcours>
                  <Line>
                    <ItemLine style={{ width: '35%' }}>Référence dossier</ItemLine>
                    <ItemLineBold>{referenceDossier ?? '-'}</ItemLineBold>
                  </Line>
                  <Line>
                    <ItemLine style={{ width: '35%' }}>Date comité</ItemLine>
                    <ItemLineBold>
                      {note.dateComite ? dayjs(note.dateComite).format('DD/MM/YYYY') : '-'}
                    </ItemLineBold>
                  </Line>
                </InnerHeaderParcours>
              </HeaderParcours>
              <HeaderSuivi>
                Suivi
                <InnerHeaderSuivi>
                  <Line>
                    <ItemLine style={{ width: '35%' }}>Conseiller</ItemLine>
                    <ItemLineBold>
                      {advisor?.firstName} {advisor?.lastName ?? '-'}
                    </ItemLineBold>
                  </Line>
                  <Line>
                    <ItemLine style={{ width: '35%' }}>Asso. territoriale</ItemLine>
                    <ItemLineBold>{associationName ?? '-'}</ItemLineBold>
                  </Line>
                </InnerHeaderSuivi>
              </HeaderSuivi>
            </Header>
            <Body>
              <BodyRow>
                <BodyColumnInner>
                  Structure
                  <InnerBody>
                    <Line>
                      <ItemLine>Raison sociale</ItemLine>
                      <ItemLineBold>{structure.raisonSociale ?? '-'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Statut juridique</ItemLine>
                      <ItemLineBold>{structure.legalStatus ?? '-'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Secteur d'activité</ItemLine>
                      <ItemLineBold>{structure.secteurActivite ?? '-'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Statut coopératif</ItemLine>
                      <ItemLineBold>{structure.cooperativeStatus ?? '-'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Agrément ESUS</ItemLine>
                      <ItemLineBold>{structure.esus ? 'Oui' : 'Non'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Territoire fragile</ItemLine>
                      <ItemLineBold>{structure.territoirFragile ? 'Oui' : 'Non'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Type de structure</ItemLine>
                      <ItemLineBold>{structure.typeStructure ?? '-'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Niveau d'engagement</ItemLine>
                      <ItemLineBold>{structure.niveauEngagement ?? '-'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Emplois créés</ItemLine>
                      <ItemLineBold>{structure.emploisCrees ?? '-'}</ItemLineBold>
                    </Line>
                  </InnerBody>
                </BodyColumnInner>
                <BodyColumnInner>
                  Partenaires
                  <InnerBody>
                    <Line>
                      <ItemLine>Prescripteur</ItemLine>
                      <ItemLineBold>{prescripteur?.partenaire ?? '-'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Accompagnateur / réseau</ItemLine>
                      <ItemLineBold>{partenaireMontage?.partenaire ?? '-'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Partenaire bancaire</ItemLine>
                      <ItemLineBold>{note.partenaireBancaire?.partenaire ?? '-'}</ItemLineBold>
                    </Line>
                    <Line>
                      <ItemLine>Nom agence banque</ItemLine>
                      <ItemLineBold>{note.partenaireBancaire?.agence ?? '-'}</ItemLineBold>
                    </Line>
                  </InnerBody>
                </BodyColumnInner>
              </BodyRow>
              <DivMap>
                <HeaderDivMap style={{ height: structure.address ? '50vh' : '20vh' }}>
                  <TitleHeaderMap>Localisation de la structure</TitleHeaderMap>
                  <Line>
                    <ItemLine style={{ marginLeft: '30px', fontWeight: 'bold' }}>Adresse</ItemLine>
                    <ItemLine>
                      {structure.address?.street ?? '-'}
                      <br />
                      {`${structure.address?.zipCode ?? '-'} ${structure.address?.city ?? '-'}`}
                    </ItemLine>
                  </Line>
                </HeaderDivMap>
                <Map />
              </DivMap>
              <BodyColumn>
                <BackgroundGrey>
                  <BodyColumnInner>
                    <Line style={{ marginLeft: '-105px' }}>
                      <ItemLine>
                        <AnalyseIcon style={{ backgroundColor: 'white' }} />
                      </ItemLine>
                      <CardDescription
                        style={{ marginLeft: '-75px' }}
                        title={'Analyse du projet'}
                        texte={
                          'Analysez la cohérence du projet en évaluant l’environnement, la pertinence du positionnement par rapport à celui-ci, l’adéquation des moyens et la capacité du ou des entrepreneurs à les mettre en œuvre.'
                        }
                        to={'/analyse'}
                        buttonTexte={"Accéder à l'Analyse"}
                      />
                    </Line>
                  </BodyColumnInner>
                  <BodyColumnInner>
                    <Line style={{ marginRight: '-105px' }}>
                      <CardDescription
                        title={"Révélateur d'engagement"}
                        texte={
                          "Révélez l’engagement du projet sur 5 dimensions : l'emploi, le territoire, le social, l'écologie et la gouvernance. Evaluez, commentez, remplissez la « toile d’araignée »."
                        }
                        to={'/involvement'}
                        buttonTexte={'Accéder au Révélateur'}
                      />
                      <ItemLine>
                        <RevelateurIcon
                          style={{
                            maxHeight: '360px',
                            backgroundColor: 'white',
                            marginLeft: '-90px',
                          }}
                        />
                      </ItemLine>
                    </Line>
                  </BodyColumnInner>
                  <BodyColumnInner>
                    <Line style={{ marginLeft: '-105px' }}>
                      <ItemLine>
                        <FinancierIcon style={{ backgroundColor: 'white' }} />
                      </ItemLine>
                      <CardDescription
                        style={{ marginLeft: '-75px' }}
                        title={'Volet financier'}
                        texte={
                          'Saisissez les différentes données financières du projet qui serviront au calcul des indicateurs et des ratios pour réaliser l’analyse, évaluez la pertinence des hypothèses et proposez un plan de financement.'
                        }
                        to={'/financiary/planFinancement'}
                        buttonTexte={'Accéder au Volet financier'}
                      />
                    </Line>
                  </BodyColumnInner>
                </BackgroundGrey>
              </BodyColumn>
            </Body>
          </div>
        </ProjectEvaluationContext.Provider>
      )}
    </>
  )
}

const PdfSuccessDialogContent: FC<{ closeButtonFct: () => any; linkPdf: string }> = ({
  closeButtonFct,
  linkPdf,
}) => {
  return (
    <div>
      <div>
        <PdfSuccess style={{ backgroundColor: 'var(--main-grey-3)' }} />
        <div
          style={{
            fontWeight: 600,
            fontSize: '27px',
            lineHeight: '32px',
            textAlign: 'center',
            marginTop: '30px',
          }}
        >
          Félicitations !
        </div>
        <div
          style={{
            color: 'var(--main-grey-1)',
            fontSize: '16px',
            lineHeight: '26px',
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          Votre note pdf a bien été générée.
          <br />
          (Si la note pdf ne s'ouvre pas automatiquement, retrouvez là{' '}
          <a href={linkPdf} target={'_blank imply noopener; support opener'}>
            ici
          </a>
          )
        </div>
        <div style={{ textAlign: 'center', marginTop: '25px', marginBottom: '60px' }}>
          <PdfButton onClick={() => closeButtonFct()} large={true}>
            Retourner à l'accueil
          </PdfButton>
        </div>
      </div>
    </div>
  )
}

export const DialogTitle = styled.div`
  font-weight: var(--bold);
  font-size: 27px;
  line-height: 32px;
  color: var(--main-black-2);
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  svg {
    min-width: 30px;
    margin-right: 0.5em;
    transform: rotate(0.25turn);
  }
`

export const getLifecycle = (lifecycle: Lifecycle) => {
  switch (lifecycle) {
    case Lifecycle.Emergence:
      return 'Émergence'
    case Lifecycle.Reprise:
      return 'Reprise'
    case Lifecycle.Development:
      return 'Développement'
    case Lifecycle.Rebound:
      return 'Rebond'
    case Lifecycle.Scale:
      return "Changement d'échelle"
    case Lifecycle.Creation:
    default:
      return 'Création'
  }
}
