import React from 'react'
import { NoteDictionnary } from './dictionnary'
import { CRLink, PFLink } from '../Financiary/Utils/DifferencesDeSaisie'
import { PeriodModel } from '../Financiary/PeriodModel'

export const creationDictionnary: NoteDictionnary = {
  cr: {
    ongletName: 'CR prévisionnel',
    pageTitle: 'Compte de résultat prévisionnel',
    linkText: 'CR prévisionnel',
  },
  bfr: {
    menuLabel: 'BFR prévisionnel',
  },
  differencesSaisies: {
    targetTitle: 'Données saisies dans le Compte de résultat',
    crExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <CRLink /> par celles issues de cette
        page.
      </div>
    ),
    crSuccessText: (
      <div>
        Vous pouvez consulter le <CRLink /> mis à jour.
      </div>
    ),
    pfExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <PFLink /> par celles issues de cette
        page.
      </div>
    ),
    pfSuccessText: (
      <div>
        Vous pouvez consulter le <PFLink /> mis à jour.
      </div>
    ),
    masseSalarialeMoreInformation: (
      <p>
        Attention, les données du <CRLink /> ont peut-être été calculées plus précisément !.
      </p>
    ),
  },
  noteSettings: {
    tvaTabLink: () => 'settings#tabsexercices-tva',
    tabExerciceTitle: 'Prévisionnel',
    baseDateLabel: () => "Date de début d'activité",
  },
  periodTitle: (p: PeriodModel, index: number) => `Exercice n° ${index + 1}`,
  bilan: {
    ongletName: '',
    pageTitle: '',
    linkText: '',
  },
  exerciceCedantLabel: '',
  commentAnterieurLabel: '',
  benchmarchAnterieurPeriodLabel: '',
  prets: {
    ongletName: 'Prêts',
    pageTitle: 'Prêts',
    linkText: 'Prêts',
  },
  pretsPasse: {
    ongletName: '',
    pageTitle: '',
    linkText: '',
  },
  pretTVADemarrage: {
    label: 'Prêt CT démarrage',
    button: 'un prêt CT démarrage',
  },
  noExerciceText: "Aucune date de début d'activité n'a été renseignée",
  noExerciceLink:
    'Vous devez saisir une date de début d’activité pour pouvoir créer vos exercices comptables dans l’onglet',
  evolutionPercentagePassedLabel: '',
}
