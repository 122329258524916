import React, { createContext, FC, useContext, useMemo, useState } from 'react'
import { PretPasse } from '@fa-metier/types'
import { NoteContext } from '../../../NotePage'
import { YodaSpinnerPage } from '../../../../utils/YodaSpinnerPage'
import { useCreatePret, useGetPretsPasses } from './PretsPassesQueries'
import { PeriodModel } from '../../PeriodModel'
import { useGetPeriods } from '../../FinanciaryQueries'
import {
  DEFAULT_MAX_TABS,
  PretListProps,
  PretTabsContainer,
  SYNTHESE,
  TabAction,
  TabSyntheseTitle,
  TabTitle,
} from '../PretTabs'
import { useDictionnary } from '../../../dictionnary/dictionnary'
import PageTitle from '../../../../utils/PageTitle'
import { Button, Dialog, InputGroup, Tab, Tabs } from '@blueprintjs/core'
import { FormGroup20, PretPasseForm } from './PretPasseForm'
import { PretsPassesSynthese } from './PretsPassesSynthese'
import { DIALOG_BODY, DIALOG_FOOTER } from '@blueprintjs/core/lib/esnext/common/classes'
import { ENTER } from '@blueprintjs/core/lib/esnext/common/keys'

interface PretsPassesContextI {
  noteId: string
  pretsPasses: PretPasse[]
  previsionelPeriods: PeriodModel[]
}

export const PretsPassesContext = createContext<PretsPassesContextI>({} as PretsPassesContextI)

export const PretPasseTabsWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const { loading: ressourcesLoading, data: prets } = useGetPretsPasses(noteId)
  const { loading: periodLoading, data: periodsData } = useGetPeriods(noteId)

  if (ressourcesLoading || periodLoading) {
    return <YodaSpinnerPage />
  }
  return (
    <PretsPassesContext.Provider
      value={{
        noteId,
        pretsPasses: prets ? prets.pretsPasses : [],
        previsionelPeriods: periodsData.previsionnelles,
      }}
    >
      <PretPasseTabs />
    </PretsPassesContext.Provider>
  )
}

const PretPasseTabs: FC<PretListProps> = ({ maxTabs = DEFAULT_MAX_TABS }) => {
  const { pretsPasses, noteId } = useContext(PretsPassesContext)
  const [offset, setOffset] = useState(0)
  const [showPopup, setShowPopup] = useState(false)
  const [title, setTitle] = useState<string | undefined>(undefined)
  const createPret = useCreatePret(noteId)
  const [selectedPretId, setSelectedPretId] = useState<string | undefined>(SYNTHESE)

  if (pretsPasses.length === 0 && selectedPretId !== undefined && selectedPretId !== SYNTHESE) {
    setSelectedPretId(SYNTHESE)
  }

  const tabsNumber = useMemo(() => pretsPasses.length + 1, [pretsPasses])

  const dictionnary = useDictionnary()

  const createNewPret = () => {
    if (title !== undefined) {
      createPret(title).then((p) => {
        setShowPopup(false)
        setTitle(undefined)
        setSelectedPretId(p.id)
        if (pretsPasses.length >= 4) {
          setOffset(offset + 1)
        }
      })
    }
  }

  const onRemovePret = () => {
    setSelectedPretId(SYNTHESE)
  }

  return (
    <PretTabsContainer>
      <PageTitle>{dictionnary.pretsPasse.pageTitle}</PageTitle>

      <Tabs
        selectedTabId={selectedPretId}
        renderActiveTabPanelOnly={true}
        onChange={(newTabId) => setSelectedPretId(newTabId.toString())}
      >
        {tabsNumber > maxTabs && (
          <TabAction
            icon={'chevron-left'}
            onClick={() => setOffset(offset - 1)}
            disabled={offset === 0}
          />
        )}
        <Tab
          className={'synthese-tab'}
          style={{ minWidth: pretsPasses.length > maxTabs ? '300px' : '340px' }}
          hidden={offset > 0}
          key={SYNTHESE}
          id={SYNTHESE}
          panel={<PretsPassesSynthese />}
          title={<TabSyntheseTitle selected={selectedPretId === SYNTHESE} />}
        />
        {pretsPasses.map((pretPasse, index) => (
          <Tab
            style={{ minWidth: pretsPasses.length > maxTabs ? '300px' : '340px' }}
            hidden={index + 1 < offset || index + 2 > offset + maxTabs}
            key={pretPasse.id}
            id={pretPasse.id}
            panel={
              <PretPasseForm
                key={pretPasse.id}
                inputPretPasse={pretPasse}
                onRemovePret={onRemovePret}
              />
            }
            title={<TabTitle pret={pretPasse} selected={pretPasse.id === selectedPretId} />}
          />
        ))}
        {tabsNumber > maxTabs && (
          <TabAction
            icon={'chevron-right'}
            onClick={() => setOffset(offset + 1)}
            disabled={offset + maxTabs === tabsNumber}
          />
        )}
        <TabAction icon={'plus'} onClick={() => setShowPopup(true)} />
      </Tabs>
      <Dialog isOpen={showPopup} title={'Nouveau prêt'} isCloseButtonShown={false}>
        <div className={DIALOG_BODY}>
          <FormGroup20 label={'Saisir un prêt'}>
            <InputGroup
              style={{ minWidth: '250px' }}
              placeholder={'Titre'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(event.target.value)
              }
              onKeyDown={(e: React.KeyboardEvent) => (e.keyCode === ENTER ? createNewPret() : {})}
            />
          </FormGroup20>
        </div>
        <div className={DIALOG_FOOTER}>
          <Button style={{ marginRight: '0.5em' }} onClick={() => setShowPopup(false)}>
            Annuler
          </Button>
          <Button intent={'primary'} onClick={() => createNewPret()}>
            Continuer
          </Button>
        </div>
      </Dialog>
    </PretTabsContainer>
  )
}
